<template>
  <dl>
    <v-chip v-for="item in tags" tag="dd" small class="mr-1">
      {{ item }}
    </v-chip>
  </dl>
</template>

<script>
export default {
  name: 'TagsCell',
  props: {
    tags: Array,
  },
}
</script>
