<template>
  <vs-wrapper>
    <vs-alert type="error">
      {{ message }}
    </vs-alert>
  </vs-wrapper>
</template>

<script>
export default {
  name: 'ErrorBanner',

  props: {
    message: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped></style>
