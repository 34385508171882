"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileSelectorDefinition = void 0;
const json_schema_keys_1 = require("../json_schema_keys");
const file_selector_ui_schema_1 = require("../models/field_ui_schema/file_selector_ui_schema");
const form_field_definition_1 = require("./form_field_definition");
class FileSelectorDefinition extends form_field_definition_1.FormattedValueField {
    constructor() {
        super(...arguments);
        this.format = json_schema_keys_1.JsonSchemaKeys.DATA_FORMAT_DATA_URL;
        this.dataType = json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_STRING;
    }
    uiSchema() {
        return new file_selector_ui_schema_1.FileSelectorUiSchema();
    }
}
exports.FileSelectorDefinition = FileSelectorDefinition;
