"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeadingFieldDefinition = void 0;
const json_schema_keys_1 = require("../json_schema_keys");
const heading_ui_schema_1 = require("../models/field_ui_schema/heading_ui_schema");
const form_field_definition_1 = require("./form_field_definition");
class HeadingFieldDefinition extends form_field_definition_1.PrimitiveValueField {
    constructor() {
        super(...arguments);
        this.dataType = json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_NULL;
    }
    uiSchema() {
        return new heading_ui_schema_1.HeadingUiSchema();
    }
}
exports.HeadingFieldDefinition = HeadingFieldDefinition;
