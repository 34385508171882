import axios from 'axios'
import { get, post } from './base'

let getServicesource = axios.CancelToken.source()
let createServicesource = axios.CancelToken.source()

const servicesEndPoint = '/api/v2/services'

export function getServices() {
  getServicesource.cancel('Cancel old get services request')
  getServicesource = axios.CancelToken.source()

  return get(servicesEndPoint, { cancelToken: getServicesource.token })
}

export function createService(service) {
  createServicesource.cancel('Cancel old get resource types request')
  createServicesource = axios.CancelToken.source()

  return post(servicesEndPoint, {
    data: {
      name: service.name,
    },
    cancelToken: createServicesource.token,
  })
}
