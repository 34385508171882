<template>
  <vs-wrapper space="small">
    <vs-card accent="var(--color-blue--lighter)">
      <div class="d-flex flex-row align-center justify-space-between">
        <vs-text>
          If field is "<b class="emphasis">{{ dto.requiredValue }}</b
          >" show:
          <b>{{ getFieldsStringList }}</b>
        </vs-text>
        <vs-button
          destructive
          type="tertiary"
          icon="delete"
          @click="deleteDependency"
        />
      </div>
    </vs-card>
  </vs-wrapper>
</template>

<script>
import VsWrapper from '@/components/vision/VsWrapper'
import VsButton from '@/components/vision/VsButton'
import VsText from '@/components/vision/VsText'
import VsAutocomplete from '@/components/vision/Inputs/VsAutocomplete/VsAutocomplete'
import VsCard from '@/components/vision/VsCard'
import { MarkdownFieldDefinition } from 'ironsight-form-builder'
import formFieldTitle from '@/utils/forms/form-field-title'
export default {
  name: 'DependencyItem',
  components: { VsCard, VsAutocomplete, VsText, VsButton, VsWrapper },

  props: {
    field: {
      type: Object,
      default: () => {},
    },
    dto: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getFieldsStringList() {
      return this.dto.dependentFields
        .map((field) => this.getFieldLabel(field))
        .join(', ')
    },
  },

  methods: {
    getFieldLabel(field) {
      return formFieldTitle(field)
    },
    deleteDependency() {
      this.$emit('delete-dependency')
    },
  },
}
</script>

<style scoped>
.emphasis {
  color: var(--color-blue);
}
</style>
