var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vs-wrapper',{attrs:{"space":"small"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('vs-text-input',{attrs:{"label":"Title","value":_vm.field.titleOverride,"disabled":_vm.titleDisabled},on:{"input":_vm.updateTitle},model:{value:(
          _vm.titleDisabled ? _vm.field.reference.title : _vm.field.titleOverride
        ),callback:function ($$v) {_vm.$set(_vm.titleDisabled ? _vm.field.reference.title : _vm.field, "titleOverride", $$v)},expression:"\n          titleDisabled ? field.reference.title : field.titleOverride\n        "}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('vs-wrapper',{attrs:{"space":"small","direction":"horizontal"}},[_c('vs-button',{attrs:{"type":"secondary","label":"Edit title"},on:{"click":function($event){return _vm.updateTitle('')}}}),_c('vs-button',{attrs:{"type":"secondary","label":"Use default","disabled":_vm.titleDisabled},on:{"click":function($event){return _vm.updateTitle(null)}}})],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('vs-text-input',{attrs:{"label":"Description","value":_vm.field.descriptionOverride,"disabled":_vm.descriptionDisabled},on:{"input":_vm.updateDescription},model:{value:(
          _vm.descriptionDisabled
            ? _vm.field.reference.description
            : _vm.field.descriptionOverride
        ),callback:function ($$v) {_vm.$set(_vm.descriptionDisabled
            ? _vm.field.reference.description
            : _vm.field, "descriptionOverride", $$v)},expression:"\n          descriptionDisabled\n            ? field.reference.description\n            : field.descriptionOverride\n        "}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('vs-wrapper',{attrs:{"space":"small","direction":"horizontal"}},[_c('vs-button',{attrs:{"type":"secondary","label":"Edit description"},on:{"click":function($event){return _vm.updateDescription('')}}}),_c('vs-button',{attrs:{"type":"secondary","label":"Use default","disabled":_vm.descriptionDisabled},on:{"click":function($event){return _vm.updateDescription(null)}}})],1)],1)],1),_c('v-switch',{attrs:{"inset":"","label":"Required","input-value":_vm.field.isRequired,"value":_vm.field.isRequired},on:{"change":_vm.updateFieldIsRequired}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }