export class AttachmentMetadata {
  createdBy: string
  tenant: string
  timestamp: Date
  extension: string
  contentType: string

  constructor(json) {
    this.createdBy = json.createdBy
    this.tenant = json.tenant
    this.timestamp = json.timestamp
    this.extension = json.extension
    this.contentType = json.contentType
  }
}
