import { defineComponent, PropType } from 'vue'
import { Banner } from './bannerStore'

export default defineComponent({
  functional: true,
  props: {
    content: {
      type: Object as PropType<Banner | null>,
      default: null,
    },
  },
  render(createElement, { props }) {
    return createElement(props.content?.component(), {
      props: { content: props.content },
    })
  },
})
