import { Component, computed, ref } from 'vue'
import { defineStore } from 'pinia'
import CurrentUserContext from '@/models/current-user-context'
import { useRoute } from 'vue-router/composables'
import { Route } from 'vue-router'
import { useCurrentUser } from '@/common/useCurrentUser'
import { allLocalBanners } from './content/index'

interface TargetContext {
  route: Route
  user: CurrentUserContext
}

export interface Banner {
  id: string
  created: Date
  expiry: Date
  target: (ctx: TargetContext) => boolean
  component: () => Component
}

type SavedBannerInfo = {
  [id: string]: {
    dismissed?: boolean
  }
}

export const useBannerStore = defineStore(
  'banners',
  () => {
    const savedState = ref<SavedBannerInfo>({})
    const currentUser = useCurrentUser()
    const route = useRoute()

    const eligibleBanners = computed(() => {
      if (!currentUser.value) return []

      return allLocalBanners.filter(
        (b) =>
          b.target({ route, user: currentUser.value }) &&
          bannerNotExpired(b) &&
          bannerNotDismissed(savedState.value, b)
      )
    })

    const firstEligibleBanner = computed(() => {
      return eligibleBanners.value[0]
    })

    const bannerNotExpired = (banner: Banner) => {
      const now = new Date()
      return banner.created < now && now < banner.expiry
    }

    const bannerNotDismissed = (state: SavedBannerInfo, banner: Banner) => {
      return state[banner.id]?.dismissed !== true
    }

    const dismissBanner = (bannerId: string) => {
      savedState.value = {
        ...savedState.value,
        [bannerId]: { dismissed: true },
      }
    }

    return {
      savedState,
      eligibleBanners,
      firstEligibleBanner,
      dismissBanner,
    }
  },
  {
    persist: true,
  }
)
