import { orderBy } from 'lodash'
import moment from 'moment'
import JobActivityLogEntry from './job_activity_log_entry'
import {
  TASK_ACTIVE,
  TASK_CANCELLED,
  TASK_NOT_STARTED,
  TASK_PAUSED,
} from './unit_number'

export default class JobActivityLog {
  constructor(json) {
    this.activeDuration = json.elapsed_active_duration
    this.entries = orderBy(
      json.entries.map((entry) => new JobActivityLogEntry(entry)),
      (e) => e.occurredAt
    )
  }

  get segments() {
    const splitByDay = ([start, end]) => {
      if (end === undefined) {
        return [[start, end]]
      }
      const segments = []
      const startTime = moment(start.occurredAt)
      const endTime = moment(end.occurredAt)
      const numDays = endTime
        .startOf('day')
        .diff(startTime.startOf('day'), 'days')
      for (let i = 0; i <= numDays; i++) {
        const endOfDay = moment(startTime).add(i, 'days').endOf('day')
        const endEvent = endOfDay.isAfter(endTime)
          ? end
          : { ...start, occurredAt: moment(endOfDay) }
        segments.push([start, endEvent])
        start = endOfDay.isAfter(endTime)
          ? endEvent
          : {
              ...endEvent,
              occurredAt: moment(endOfDay).add(1, 'day').startOf('day'),
            }
      }
      return segments
    }

    const segments = this.entries
      .map((e) => ({
        ...e,
        occurredAt: moment(e.occurredAt),
      }))
      .map((s, i, entries) => [s, entries[i + 1]])
      .reduce((segments, pair) => [...segments, ...splitByDay(pair)], [])
      .map((pair) =>
        pair[1] === undefined
          ? [pair[0], { ...pair[0], occurredAt: moment() }]
          : pair
      )
      .reduce(
        (segments, [start, end]) => [
          ...segments,
          {
            duration: moment.duration(
              Math.max(end.occurredAt.diff(start.occurredAt), 0)
            ),
            start,
            end,
          },
        ],
        []
      )

    return segments
  }

  get scheduleSegments() {
    // partition entries on each "not started" event
    // e.g [[not started, active, cancelled], [not started, active]]
    return this.entries.reduce((s, e) => {
      let segment = s[s.length - 1]
      if (e.status === TASK_NOT_STARTED || segment === undefined) {
        segment = []
        s.push(segment)
      }
      segment.push(e)
      return s
    }, [])
  }

  get currentActiveTime() {
    const latestActivity =
      this.scheduleSegments[this.scheduleSegments.length - 1] || []
    const terminalEvent = latestActivity[latestActivity.length - 1]
    if (terminalEvent && terminalEvent.status === TASK_CANCELLED) {
      return null
    }
    const currentActiveEvent = latestActivity.find(
      (e) => e.status === TASK_ACTIVE
    )

    return currentActiveEvent && currentActiveEvent.occurredAt
  }

  get durationForActiveState() {
    return this.segments
      .filter((s) => s.start.status === TASK_ACTIVE)
      .reduce((t, i) => t.add(i.duration), moment.duration(0))
  }

  get durationForPausedState() {
    return this.segments
      .filter((s) => s.start.status === TASK_PAUSED)
      .reduce((t, i) => t.add(i.duration), moment.duration(0))
  }
}
