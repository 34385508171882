<template>
  <vs-wrapper>
    <vs-heading v-if="title" type="subhead">{{ title }}</vs-heading>
    <div v-if="description" class="hint">
      <vs-text type="subdued">{{ description }}</vs-text>
    </div>
    <v-row v-for="(item, index) in formData" :key="index" no-gutters>
      <v-col>
        <schema-field
          :data-test="arrayItemsAttribute(index)"
          :error-schema="itemErrorSchema(index)"
          :form-data="item"
          :business-unit-id="businessUnitId"
          :params="buildItemParams(index)"
          :root-schema="rootSchema"
          :schema="itemSchema"
          :ui-schema="itemUiSchema"
          :form-resolver="formResolver"
          :disabled="disabled"
          @change="onItemChange(index, $event)"
        />
      </v-col>
      <v-col cols="1">
        <v-row align="center" class="pl-1 typical-field-height" no-gutters>
          <vs-button
            v-if="canRemoveItems && !disabled"
            :data-test="deleteAttribute(index)"
            icon="delete"
            type="tertiary"
            small
            destructive
            @click="removeItem(index)"
          />
        </v-row>
      </v-col>
    </v-row>
    <vs-button
      v-if="canAddItems && !disabled"
      :data-test="addButtonAttribute"
      class="float-right"
      :label="addButtonText"
      type="secondary"
      small
      @click="addItem"
    />
  </vs-wrapper>
</template>

<script>
import { ContextualizedFormResolver } from '@/utils/forms/contextualized-form-resolver'

export default {
  name: 'ArrayField',
  props: {
    description: String,
    errorSchema: Object,
    formData: Array,
    businessUnitId: String,
    params: Object,
    rootSchema: Object,
    schema: Object,
    title: String,
    uiSchema: Object,
    formResolver: {
      type: ContextualizedFormResolver,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formDataLength() {
      return this.formData?.length ?? 0
    },
    addButtonAttribute() {
      return `Add-${this.title}`
    },
    addButtonText() {
      return `Add ${this.itemTitle ? ` ${this.itemTitle}` : ''}`
    },
    canAddItems() {
      return !this.schema.maxItems || this.formDataLength < this.schema.maxItems
    },
    canRemoveItems() {
      return !this.schema.minItems || this.formDataLength > this.schema.minItems
    },
    itemSchema() {
      return this.schema.items
    },
    itemTitle() {
      return this.itemUiSchema.title || this.itemSchema.title
    },
    itemUiSchema() {
      return this.uiSchema.items || {}
    },
    /**
     * Computes the default value of this array
     * Returns the default from the schema or undefined if there is no default
     */
    defaultValue() {
      return this.formResolver.getDefaultFormData(
        this.itemSchema,
        this.rootSchema,
        undefined
      )
    },
  },
  methods: {
    addItem() {
      const formData = this.cloneFormData()
      formData.push(this.defaultValue)
      this.$emit('change', formData)
    },
    buildItemParams(index) {
      return {
        instanceNumber: index + 1,
      }
    },
    cloneFormData() {
      return this.formData ? [...this.formData] : []
    },
    onItemChange(index, value) {
      const formData = this.cloneFormData()
      formData[index] = value
      this.$emit('change', formData)
    },
    removeItem(index) {
      const formData = this.cloneFormData()
      formData.splice(index, 1)
      this.$emit('change', formData)
    },
    itemErrorSchema(index) {
      return this.errorSchema ? this.errorSchema[index] : {}
    },

    arrayItemsAttribute(index) {
      return `items-${this.title}-${index}`
    },
    deleteAttribute(index) {
      return `Delete-${this.title}-${index}`
    },
  },
}
</script>

<style scoped></style>
