"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormBuilder = void 0;
const always_enabled_edge_1 = require("../edges/always_enabled_edge");
const const_dependency_edge_1 = require("../edges/const_dependency_edge");
const const_go_to_flow_edge_1 = require("../edges/const_go_to_flow_edge");
const edge_1 = require("../edges/edge");
const object_field_definition_1 = require("../form_fields/object_field_definition");
const reference_field_1 = require("../form_fields/reference_field");
const json_schema_keys_1 = require("../json_schema_keys");
const conditionals_model_1 = require("../models/conditionals/conditionals_model");
const const_go_to_flow_dto_1 = require("../models/conditionals/const_go_to_flow_dto");
const flows_model_1 = require("../models/conditionals/flows_model");
const flow_model_1 = require("../models/conditionals/flow_model");
const go_to_flow_model_1 = require("../models/conditionals/go_to_flow_model");
const if_condition_model_1 = require("../models/conditionals/if_condition_model");
const if_then_model_1 = require("../models/conditionals/if_then_model");
const on_change_model_1 = require("../models/conditionals/on_change_model");
const conditions_1 = require("../models/dependencies/conditions");
const const_dependency_dto_1 = require("../models/dependencies/const_dependency_dto");
const dependencies_model_1 = require("../models/dependencies/dependencies_model");
const definitions_model_1 = require("../models/definitions_model");
const form_model_1 = require("../models/form_model");
const page_model_1 = require("../models/page_model");
const schema_object_1 = require("../models/schema_object");
const flow_entry_node_1 = require("../nodes/flow_entry_node");
const page_node_1 = require("../nodes/page_node");
const schema_node_1 = require("../nodes/schema_node");
const required_dependency_dto_1 = require("../models/dependencies/required_dependency_dto");
const required_dependency_edge_1 = require("../edges/required_dependency_edge");
class FormBuilder {
    constructor() {
        this.nodes = [];
        this.edges = [];
        this.definitions = [];
        this.sourceIsCustomFieldSchema = false;
    }
    get formEntryPoint() {
        const node = this.nodes.find((n) => n instanceof flow_entry_node_1.FlowEntryNode && n.flowKey === json_schema_keys_1.JsonSchemaKeys.ROOT_SCHEMA_KEY);
        if (node instanceof flow_entry_node_1.FlowEntryNode) {
            return node;
        }
        throw new Error("Form entry point not found");
    }
    static newForm() {
        const builder = new FormBuilder();
        builder.addEmptyFlow(json_schema_keys_1.JsonSchemaKeys.ROOT_SCHEMA_KEY);
        return builder;
    }
    immediateConstDependenciesOfField(field) {
        const edgesFromField = this.edges
            .filter((e) => e instanceof const_dependency_edge_1.ConstDependencyEdge && e.dependentOn == field)
            .map((e) => e);
        const dtos = new Array();
        for (const edge of edgesFromField) {
            const dto = new const_dependency_dto_1.ConstDependencyDTO(field, edge.requiredValue, edge.target.fields);
            dtos.push(dto);
        }
        return dtos;
    }
    immediateReqDependenciesOfField(field) {
        const edgesFromField = this.edges
            .filter((e) => e instanceof required_dependency_edge_1.RequiredDependencyEdge && e.dependentOn == field)
            .map((e) => e);
        const dtos = new Array();
        for (const edge of edgesFromField) {
            const dto = new required_dependency_dto_1.RequiredDependencyDTO(field, edge.requiredValue, edge.requiredFields);
            dtos.push(dto);
        }
        return dtos;
    }
    immediateParentsOfField(field) {
        const schemaNodeOfField = this.schemaNodeOfField(field);
        if (!(schemaNodeOfField instanceof schema_node_1.SchemaNode)) {
            throw new Error("Field is orphaned and does not live on a schema node");
        }
        var fields = new Array();
        const fieldLists = this.edges
            .filter((edge) => edge.source instanceof schema_node_1.SchemaNode)
            .filter((edge) => edge.target == schemaNodeOfField)
            .map((e) => e.source.fields);
        for (const list of fieldLists) {
            fields.push(...list);
        }
        return fields;
    }
    fieldsSelectableDependencies(field) {
        const pageNumber = this.pageNumberOfField(field);
        const flowKey = this.flowOfField(field);
        return this.fieldsOfPage(pageNumber, flowKey).filter((f) => f !== field);
    }
    fieldHasDependencies(field) {
        return (this.immediateConstDependenciesOfField(field).length > 0 ||
            this.immediateReqDependenciesOfField(field).length > 0);
    }
    fieldIsDependency(field) {
        return this.immediateParentsOfField(field).length > 0;
    }
    walkForm(callback) {
        this.walkNode(this.formEntryPoint, -1, null, json_schema_keys_1.JsonSchemaKeys.ROOT_SCHEMA_KEY, callback);
    }
    walkFlow(flowKey, callback) {
        this.walkNode(this.flowEntryPoint(flowKey), -1, null, flowKey, (n, p, e, f) => {
            if (f === flowKey) {
                callback(n, p, e, f);
            }
        });
    }
    fieldsOfPage(pageNumber, flowKey) {
        return this.page(pageNumber, flowKey).fieldOrder;
    }
    walkPage(pageNumber, flowKey, callback) {
        this.walkFlow(flowKey, (n, p, e, f) => {
            if (pageNumber === p) {
                callback(n, p, e, f);
            }
        });
    }
    walkNode(node, pageNumber, previousEdge, flowKey, callback) {
        let currentPageNumber = pageNumber;
        let currentFlowKey = flowKey;
        if (node instanceof flow_entry_node_1.FlowEntryNode) {
            currentFlowKey = node.flowKey;
            currentPageNumber = -1; // this will be incremented to zero upon hitting the first page node
        }
        else if (node instanceof page_node_1.PageNode) {
            currentPageNumber++;
        }
        callback(node, currentPageNumber, previousEdge, flowKey);
        const edgesOfNode = this.edges.filter((e) => e.source == node);
        for (const edge of edgesOfNode) {
            this.walkNode(edge.target, currentPageNumber, edge, currentFlowKey, callback);
        }
    }
    get schemaNodes() {
        return this.nodes.filter((node) => node instanceof schema_node_1.SchemaNode);
    }
    orphanFields(fields) {
        for (const field of fields) {
            for (const node of this.schemaNodes) {
                node.removeField(field);
            }
        }
    }
    validateConstDependencyDTO(dto, isEditing) {
        const errors = [];
        if (!isEditing && this.constDependencyExists(dto)) {
            errors.push("A dependency already exists with the same required value and parent field. It should be edited instead");
        }
        if (this.dependencyHasOnChangeDuplicates(dto)) {
            errors.push("This field and required value already has an on-change conditional or dependency");
        }
        if (dto.requiredValue === null || dto.requiredValue === undefined) {
            errors.push("Required value must be defined for dependencies");
        }
        if (dto.dependentFields.length === 0) {
            errors.push("At least one dependent field must be selected");
        }
        if (dto.parentField instanceof object_field_definition_1.ObjectFieldDefinition) {
            errors.push("Object fields cannot parent dependencies");
        }
        return errors;
    }
    validateReqDependencyDTO(dto, isEditing) {
        const errors = [];
        if (!isEditing && this.reqDependencyExists(dto)) {
            errors.push("A dependency already exists with the same required value and parent field. It should be edited instead");
        }
        if (this.reqDependencyHasOnChangeDuplicates(dto)) {
            errors.push("This field and required value already has an on-change conditional or dependency");
        }
        if (dto.requiredValue === null || dto.requiredValue === undefined) {
            errors.push("Required value must be defined for dependencies");
        }
        if (dto.requiredFields.length === 0) {
            errors.push("At least one required field must be selected");
        }
        if (dto.parentField instanceof object_field_definition_1.ObjectFieldDefinition) {
            errors.push("Object fields cannot parent dependencies");
        }
        return errors;
    }
    validateConstGoToFlowDTO(dto, isEditing) {
        const errors = [];
        if (!isEditing && this.constGoToFlowExists(dto)) {
            errors.push("A go to flow condition already exists with the same required value and parent field. It should be edited instead");
        }
        if (this.goToFlowHasOnChangeDuplicates(dto)) {
            errors.push("This field and required value already has an on-change conditional or dependency");
        }
        if (dto.requiredValue === null || dto.requiredValue === undefined) {
            errors.push("Required value must be defined");
        }
        if (!dto.flowKey) {
            errors.push("At flow key must be provided");
        }
        if (dto.parentField instanceof object_field_definition_1.ObjectFieldDefinition) {
            errors.push("Object fields cannot go to flows");
        }
        return errors;
    }
    addConstDependencyToField(dto) {
        const errors = this.validateConstDependencyDTO(dto, false);
        if (errors.length !== 0) {
            throw new Error(errors[0]);
        }
        const parentField = dto.parentField;
        const dependentFields = dto.dependentFields;
        const requiredValue = dto.requiredValue;
        // If a node already exists with exactly the dependent fields specified,
        // we can simply create a new edge to it. Otherwise, a new node must be created
        const existingNode = this.schemaNodes.find((n) => n.fields.length === dto.dependentFields.length && n.fields.every((f) => dto.dependentFields.includes(f)));
        if (!existingNode) {
            this.orphanFields(dependentFields);
        }
        const targetNode = existingNode !== null && existingNode !== void 0 ? existingNode : new schema_node_1.SchemaNode(dependentFields);
        this.nodes.push(targetNode);
        this.edges.push(new const_dependency_edge_1.ConstDependencyEdge(this.schemaNodeOfField(parentField), targetNode, parentField, requiredValue));
        const page = this.pageOfField(dto.parentField);
        for (const field of dto.dependentFields) {
            if (!page.fieldOrder.includes(field)) {
                page.fieldOrder.push(field);
            }
        }
    }
    addReqDependencyToField(dto) {
        const errors = this.validateReqDependencyDTO(dto, false);
        if (errors.length !== 0) {
            throw new Error(errors[0]);
        }
        const parentField = dto.parentField;
        const requiredValue = dto.requiredValue;
        const requiredFields = dto.requiredFields;
        this.edges.push(new required_dependency_edge_1.RequiredDependencyEdge(this.schemaNodeOfField(parentField), parentField, requiredValue, requiredFields));
    }
    // Takes an existing dependency and changes its required value and/or dependent fields
    modifyConstDependencyOfField(original, dto) {
        const errors = this.validateConstDependencyDTO(dto, true);
        if (errors.length !== 0) {
            throw new Error(errors[0]);
        }
        const dependentFields = dto.dependentFields;
        const requiredValue = dto.requiredValue;
        const existingEdge = this.edges.find((edge) => edge instanceof const_dependency_edge_1.ConstDependencyEdge &&
            edge.dependentOn === original.parentField &&
            edge.requiredValue === original.requiredValue);
        if (!(existingEdge instanceof const_dependency_edge_1.ConstDependencyEdge)) {
            throw new Error("Edge not found for dependency modification");
        }
        const existingTargetNode = existingEdge.target;
        const existingFieldsCopy = [...existingTargetNode.fields];
        existingTargetNode.fields = dependentFields;
        existingEdge.requiredValue = requiredValue;
        for (const orphan of existingFieldsCopy.filter((field) => this.fieldIsOrphaned(field))) {
            existingEdge.source.addField(orphan);
        }
    }
    modifyReqDependencyOfField(original, dto) {
        const errors = this.validateReqDependencyDTO(dto, true);
        if (errors.length !== 0) {
            throw new Error(errors[0]);
        }
        const requiredFields = dto.requiredFields;
        const requiredValue = dto.requiredValue;
        const existingEdge = this.edges.find((edge) => edge instanceof required_dependency_edge_1.RequiredDependencyEdge &&
            edge.dependentOn === original.parentField &&
            edge.requiredValue === original.requiredValue);
        if (!(existingEdge instanceof required_dependency_edge_1.RequiredDependencyEdge)) {
            throw new Error("Edge not found for dependency modification");
        }
        existingEdge.requiredFields = requiredFields;
        existingEdge.requiredValue = requiredValue;
    }
    deleteConstDependencyOfField(dto) {
        const parentField = dto.parentField;
        const sourceNode = this.schemaNodeOfField(parentField);
        const edgeForDeletion = this.edges
            .filter((edge) => edge instanceof const_dependency_edge_1.ConstDependencyEdge && edge.dependentOn == parentField)
            .find((edge) => edge.requiredValue === dto.requiredValue);
        if (!(edgeForDeletion instanceof const_dependency_edge_1.ConstDependencyEdge)) {
            throw new Error("Edge with specified values was not found");
        }
        const nodeForDeletion = edgeForDeletion.target;
        const shouldDeleteNode = this.edges.filter((e) => e.target === nodeForDeletion).length === 1;
        if (shouldDeleteNode) {
            sourceNode.addFields(nodeForDeletion.fields);
            // Repair all the forward edges such that they
            // now lead off of the previous node
            for (const edge of this.forwardEdgesOfNode(nodeForDeletion)) {
                edge.source = sourceNode;
            }
            this.deleteNode(nodeForDeletion);
        }
        this.deleteEdge(edgeForDeletion);
    }
    deleteReqDependencyOfField(dto) {
        const parentField = dto.parentField;
        const edgeForDeletion = this.edges
            .filter((edge) => edge instanceof required_dependency_edge_1.RequiredDependencyEdge && edge.dependentOn === parentField)
            .find((edge) => edge.requiredValue === dto.requiredValue);
        if (!(edgeForDeletion instanceof required_dependency_edge_1.RequiredDependencyEdge)) {
            throw new Error("Edge with specified values was not found");
        }
        this.deleteEdge(edgeForDeletion);
    }
    // A field is orphaned if it belongs to no node
    fieldIsOrphaned(field) {
        return !this.schemaNodes.some((node) => node.fields.includes(field));
    }
    /**
     * To avoid shotgun surgery where everything calling `build()` expects a
     * FormModel (and to meet a deadline), sprouted a new method.
     * @param baseDefinitionsSchema Predefined fields from `custom_fields_schema` table.
     * @returns Built custom fields schema
     */
    buildCustomFieldSchema(baseDefinitionsSchema) {
        const rootSchema = new page_model_1.PageModel("", [], []);
        this.walkForm((node, pageNumber, prevEdge, flowKey) => {
            var _a, _b;
            var _c, _d;
            if (node instanceof page_node_1.PageNode) {
                const definitionUniqueKeys = this.fieldsOfPage(pageNumber, flowKey)
                    .filter((field) => field instanceof reference_field_1.ReferenceField)
                    .map((field) => field.reference.uniqueKey());
                const schemaSpecificDefinitions = this.definitions.filter((def) => 
                // To preserve the global nature of the
                // `custom_fields_schema` database table; prevent
                // confusion; and prevent unintended side-effects, we
                // don't include anything sharing the same key with a
                // base definition.
                baseDefinitionsSchema.schema.definitions.hasOwnProperty(def.uniqueKey()) === false &&
                    definitionUniqueKeys.includes(def.uniqueKey()));
                rootSchema.schema.title = node.pageTitle;
                rootSchema.uiSchema.uiOrder = node.uiOrder;
                rootSchema.schema.definitions = new definitions_model_1.DefinitionsModel(schemaSpecificDefinitions);
            }
            else if (node instanceof schema_node_1.SchemaNode) {
                rootSchema.uiSchema.addFields(node.fields);
                if (prevEdge instanceof const_dependency_edge_1.ConstDependencyEdge) {
                    (_a = (_c = rootSchema.schema).dependencies) !== null && _a !== void 0 ? _a : (_c.dependencies = new dependencies_model_1.DependenciesModel());
                    rootSchema.schema.dependencies.addConstCondition(prevEdge, node.fields);
                }
                else if (prevEdge instanceof required_dependency_edge_1.RequiredDependencyEdge) {
                    (_b = (_d = rootSchema.schema).dependencies) !== null && _b !== void 0 ? _b : (_d.dependencies = new dependencies_model_1.DependenciesModel());
                    rootSchema.schema.dependencies.addRequiredCondition(prevEdge);
                }
                else {
                    rootSchema.schema.required.push(...node.requiredFields());
                    rootSchema.schema.properties.addFields(node.fields);
                }
            }
        });
        return rootSchema;
    }
    build() {
        const form = new form_model_1.FormModel();
        this.walkForm((node, pageNumber, previousEdge, flowKey) => {
            var _a, _b, _c, _d, _e, _f;
            var _g, _h, _j, _k;
            const isInFlow = flowKey !== json_schema_keys_1.JsonSchemaKeys.ROOT_SCHEMA_KEY;
            if (isInFlow) {
                (_a = form.flows) !== null && _a !== void 0 ? _a : (form.flows = new flows_model_1.FlowsModel());
                form.flows.addFlowIfNotExists(new flow_model_1.FlowModel(), flowKey);
            }
            if (node instanceof page_node_1.PageNode) {
                const definitionUniqueKeys = this.fieldsOfPage(pageNumber, flowKey)
                    .filter((field) => field instanceof reference_field_1.ReferenceField)
                    .map((field) => field.reference.uniqueKey());
                const definitionsOfPage = this.definitions.filter((def) => definitionUniqueKeys.includes(def.uniqueKey()));
                const newPage = new page_model_1.PageModel(node.pageTitle, node.uiOrder, definitionsOfPage);
                isInFlow ? form.flows.getFlow(flowKey).pages.push(newPage) : form.pages.push(newPage);
            }
            if (previousEdge instanceof const_go_to_flow_edge_1.ConstGoToFlowEdge) {
                const pageNumber = this.pageNumberOfField(previousEdge.dependentOn);
                const page = isInFlow ? form.flows.getFlow(flowKey).pages[pageNumber] : form.pages[pageNumber];
                const props = new schema_object_1.SchemaObject();
                props[previousEdge.dependentOn.uniqueKey()] = previousEdge.condition();
                const ifThenModel = new if_then_model_1.IfThenModel(new if_condition_model_1.IfConditionModel(props), new go_to_flow_model_1.GoToFlowModel(new go_to_flow_model_1.GoToFlowArgs(previousEdge.flowKey, 0)));
                (_b = page.conditionals) !== null && _b !== void 0 ? _b : (page.conditionals = new conditionals_model_1.ConditionalsModel());
                if (previousEdge.conditionalType === conditions_1.ConditionalType.onChange) {
                    (_c = (_g = page.conditionals).onChange) !== null && _c !== void 0 ? _c : (_g.onChange = new on_change_model_1.OnChangeModel());
                    page.conditionals.onChange.addIfThenModel(previousEdge.dependentOn.uniqueKey(), ifThenModel);
                }
                else {
                    (_d = (_h = page.conditionals).onSubmit) !== null && _d !== void 0 ? _d : (_h.onSubmit = []);
                    page.conditionals.onSubmit.push(ifThenModel);
                }
            }
            if (node instanceof schema_node_1.SchemaNode) {
                const page = isInFlow ? form.flows.getFlow(flowKey).pages[pageNumber] : form.pages[pageNumber];
                page.uiSchema.addFields(node.fields);
                if (previousEdge instanceof const_dependency_edge_1.ConstDependencyEdge) {
                    (_e = (_j = page.schema).dependencies) !== null && _e !== void 0 ? _e : (_j.dependencies = new dependencies_model_1.DependenciesModel());
                    page.schema.dependencies.addConstCondition(previousEdge, node.fields);
                }
                else if (previousEdge instanceof required_dependency_edge_1.RequiredDependencyEdge) {
                    (_f = (_k = page.schema).dependencies) !== null && _f !== void 0 ? _f : (_k.dependencies = new dependencies_model_1.DependenciesModel());
                    page.schema.dependencies.addRequiredCondition(previousEdge);
                }
                else {
                    page.schema.required.push(...node.requiredFields());
                    page.schema.properties.addFields(node.fields);
                }
            }
        });
        return form;
    }
    // Returns a list of strings with validation errors.
    // If the list is empty, there are no errors.
    validate() {
        const errors = new Array();
        const keys = this.allFields().map((f) => f.uniqueKey());
        const keyCount = keys.length;
        const uniqueKeyCount = new Set(keys).size;
        if (uniqueKeyCount < keyCount) {
            errors.push("All fields must have a unique key");
        }
        const flows = this.getFlows();
        const flowsInOutput = new Array();
        this.walkForm((n, p, e, f) => {
            if (!flowsInOutput.includes(f)) {
                flowsInOutput.push(f);
            }
        });
        const sameLength = flows.length === flowsInOutput.length;
        const flowsMissing = flows.map((flowKey) => !flowsInOutput.includes(flowKey));
        if (!sameLength) {
            errors.push(...flowsMissing.map((flowKey) => `The flow named "${flowKey}" is inaccessible`));
        }
        return errors;
    }
    isValidForm() {
        return this.validate().length === 0;
    }
    allFields() {
        const fields = new Array();
        for (const node of this.nodes) {
            if (node instanceof schema_node_1.SchemaNode) {
                fields.push(...node.fields);
            }
        }
        return fields;
    }
    deleteNode(node) {
        this.nodes.splice(this.nodes.indexOf(node), 1);
    }
    deleteEdge(edge) {
        this.edges.splice(this.edges.indexOf(edge), 1);
    }
    forwardEdgesOfNode(node) {
        return this.edges.filter((edge) => edge.source === node);
    }
    schemaNodeOfField(field) {
        const node = this.schemaNodes.find((node) => node.fields.includes(field));
        if (!(node instanceof schema_node_1.SchemaNode)) {
            throw new Error(`Field ${field.uniqueKey()} is orphaned and does not live on a schema node`);
        }
        return node;
    }
    addFieldToPage(field, pageNumber, flowKey) {
        var hasAddedField = false;
        this.walkPage(pageNumber, flowKey, (n, p, e, f) => {
            if (n instanceof page_node_1.PageNode) {
                n.fieldOrder.push(field);
            }
            else if (n instanceof schema_node_1.SchemaNode && e instanceof always_enabled_edge_1.AlwaysEnabledEdge && !hasAddedField) {
                n.addField(field);
                hasAddedField = true;
            }
        });
        if (!hasAddedField) {
            throw new Error(`Field was not added to page ${pageNumber}, the page may not exist`);
        }
    }
    addFieldsToPage(fields, pageNumber, flowKey) {
        for (const field of fields) {
            this.addFieldToPage(field, pageNumber, flowKey);
        }
    }
    removeField(field) {
        const schema = this.schemaNodes.find((n) => n.fields.includes(field));
        const page = this.pageOfField(field);
        if (!(schema instanceof schema_node_1.SchemaNode)) {
            throw new Error("No node was found which owns the field to be deleted");
        }
        schema.removeField(field);
        page.fieldOrder.splice(page.fieldOrder.indexOf(field), 1);
    }
    page(pageNumber, flowKey) {
        var pageNode;
        this.walkFlow(flowKey, (n, p, e, f) => {
            if (n instanceof page_node_1.PageNode && p === pageNumber) {
                pageNode = n;
            }
        });
        if (pageNode instanceof page_node_1.PageNode) {
            return pageNode;
        }
        throw new Error(`Page ${pageNumber} not found of flow ${flowKey}`);
    }
    rootSchemaOfPage(pageNumber, flowKey) {
        var _a;
        const page = this.page(pageNumber, flowKey);
        const schema = (_a = this.edges.find((e) => e.source === page)) === null || _a === void 0 ? void 0 : _a.target;
        if (!(schema instanceof schema_node_1.SchemaNode)) {
            throw new Error(`Schema of page ${pageNumber} not found of flow ${flowKey}`);
        }
        return schema;
    }
    flowEntryPoint(flowKey) {
        const node = this.nodes.find((n) => n instanceof flow_entry_node_1.FlowEntryNode && n.flowKey === flowKey);
        if (node instanceof flow_entry_node_1.FlowEntryNode) {
            return node;
        }
        throw new Error(`Flow ${flowKey} not found`);
    }
    pageOfField(field) {
        var pageNode;
        this.walkFlow(this.flowOfField(field), (n, p, e, f) => {
            if (n instanceof schema_node_1.SchemaNode && n.fields.includes(field)) {
                pageNode = this.page(p, f);
            }
        });
        if (!(pageNode instanceof page_node_1.PageNode)) {
            throw new Error(`Page which has field ${field.uniqueKey()} not found`);
        }
        return pageNode;
    }
    pageNumberOfField(field) {
        var pageNumber;
        this.walkFlow(this.flowOfField(field), (n, p) => {
            if (n instanceof schema_node_1.SchemaNode && n.fields.includes(field)) {
                pageNumber = p;
            }
        });
        if (typeof pageNumber !== "number") {
            throw new Error(`Page which has field ${field.uniqueKey()} not found`);
        }
        return pageNumber;
    }
    flowOfField(field) {
        var flowKeyOfField;
        for (const flowKey of this.getFlows()) {
            this.walkFlow(flowKey, (n, p, e, f) => {
                if (n instanceof schema_node_1.SchemaNode && n.fields.includes(field)) {
                    flowKeyOfField = f;
                }
            });
        }
        if (!flowKeyOfField) {
            throw new Error(`Page which has field ${field.uniqueKey()} not found`);
        }
        return flowKeyOfField;
    }
    reorderField(field, newIndex) {
        const page = this.pageOfField(field);
        page.reorderField(field, newIndex);
    }
    deletePage(pageNumber, flowKey) {
        if (this.pageCount(flowKey) === 1) {
            throw new Error(`Flow ${flowKey} must have multiple pages to delete one`);
        }
        if (pageNumber === 0) {
            this.severFirstPage(flowKey);
        }
        else if (pageNumber === this.pageCount(flowKey) - 1) {
            this.severLastPage(flowKey);
        }
        else {
            this.severPage(pageNumber, flowKey);
        }
    }
    severPage(pageNumber, flowKey) {
        const schemaOfPreviousPage = this.rootSchemaOfPage(pageNumber - 1, flowKey);
        const existingEdge = this.edges.find((e) => e instanceof always_enabled_edge_1.AlwaysEnabledEdge && e.source === schemaOfPreviousPage);
        if (!(existingEdge instanceof always_enabled_edge_1.AlwaysEnabledEdge)) {
            throw new Error("Edge between page and schema not found");
        }
        existingEdge.target = this.page(pageNumber + 1, flowKey);
    }
    severFirstPage(flowKey) {
        const existingEdge = this.edges.find((e) => e instanceof always_enabled_edge_1.AlwaysEnabledEdge && e.target === this.page(0, flowKey));
        if (!(existingEdge instanceof always_enabled_edge_1.AlwaysEnabledEdge)) {
            throw new Error("Edge between page and schema not found");
        }
        existingEdge.target = this.page(1, flowKey);
    }
    severLastPage(flowKey) {
        const existingEdge = this.edges.find((e) => e instanceof always_enabled_edge_1.AlwaysEnabledEdge && e.target === this.page(this.pageCount(flowKey) - 1, flowKey));
        if (!(existingEdge instanceof always_enabled_edge_1.AlwaysEnabledEdge)) {
            throw new Error("Edge between page and schema not found");
        }
        this.deleteEdge(existingEdge);
    }
    insertBlankPageAfter(pageNumber, flowKey) {
        const pageSchema = this.rootSchemaOfPage(pageNumber, flowKey);
        if (!(pageSchema instanceof schema_node_1.SchemaNode)) {
            throw new Error(`Schema not found for page ${pageNumber}`);
        }
        const newPage = new page_node_1.PageNode();
        const newSchema = new schema_node_1.SchemaNode();
        // If this is not the last page, we must join this page to
        // the page in front and sever the edge which used to be there
        if (pageNumber !== this.pageCount(flowKey) - 1) {
            const nextPage = this.page(pageNumber + 1, flowKey);
            this.edges.push(new always_enabled_edge_1.AlwaysEnabledEdge(newSchema, nextPage));
            const existingEdge = this.edges.find((e) => e instanceof always_enabled_edge_1.AlwaysEnabledEdge && e.target === nextPage);
            if (!(existingEdge instanceof always_enabled_edge_1.AlwaysEnabledEdge)) {
                throw new Error(`Edge not found connecting to page ${pageNumber}`);
            }
            this.deleteEdge(existingEdge);
        }
        this.nodes.push(newPage);
        this.edges.push(new always_enabled_edge_1.AlwaysEnabledEdge(pageSchema, newPage));
        this.nodes.push(newSchema);
        this.edges.push(new always_enabled_edge_1.AlwaysEnabledEdge(newPage, newSchema));
    }
    pageCount(flowKey) {
        var maxPageIndex = 0;
        this.walkFlow(flowKey, (n, p, e) => {
            if (p > maxPageIndex) {
                maxPageIndex = p;
            }
        });
        return maxPageIndex + 1;
    }
    dependsOnExactValuesEdges() {
        return this.edges.filter((e) => e instanceof const_dependency_edge_1.ConstDependencyEdge);
    }
    dependsOnExactReqValuesEdges() {
        return this.edges.filter((e) => e instanceof required_dependency_edge_1.RequiredDependencyEdge);
    }
    goToFlowEdges() {
        return this.edges.filter((e) => e instanceof const_go_to_flow_edge_1.ConstGoToFlowEdge);
    }
    constDependencyExists(dto) {
        return this.dependsOnExactValuesEdges().some((e) => e.requiredValue === dto.requiredValue && e.dependentOn === dto.parentField);
    }
    reqDependencyExists(dto) {
        return this.dependsOnExactReqValuesEdges().some((e) => e.requiredValue === dto.requiredValue && e.dependentOn === dto.parentField);
    }
    constGoToFlowExists(dto) {
        try {
            this.findGoToFlowEdge(dto);
            return true;
        }
        catch (e) {
            return false;
        }
    }
    goToFlowHasOnChangeDuplicates(dto) {
        const goToFlowEdgeExists = this.goToFlowEdges().find((e) => e.dependentOn === dto.parentField &&
            e.conditionalType === dto.conditionalType &&
            e.conditionalType === conditions_1.ConditionalType.onChange &&
            e.requiredValue === dto.requiredValue) instanceof const_go_to_flow_edge_1.ConstGoToFlowEdge;
        const dependencyEdgeExists = this.dependsOnExactValuesEdges().find((e) => e.dependentOn === dto.parentField && e.requiredValue === dto.requiredValue) instanceof const_dependency_edge_1.ConstDependencyEdge;
        return goToFlowEdgeExists || dependencyEdgeExists;
    }
    dependencyHasOnChangeDuplicates(dto) {
        const goToFlowEdgeExists = this.goToFlowEdges().find((e) => e.dependentOn === dto.parentField &&
            e.conditionalType === conditions_1.ConditionalType.onChange &&
            e.requiredValue === dto.requiredValue) instanceof const_go_to_flow_edge_1.ConstGoToFlowEdge;
        const dependencyEdgeExists = this.dependsOnExactValuesEdges().find((e) => e.dependentOn === dto.parentField && e.requiredValue === dto.requiredValue) instanceof const_dependency_edge_1.ConstDependencyEdge;
        return goToFlowEdgeExists || dependencyEdgeExists;
    }
    reqDependencyHasOnChangeDuplicates(dto) {
        const goToFlowEdgeExists = this.goToFlowEdges().find((e) => e.dependentOn === dto.parentField &&
            e.conditionalType === conditions_1.ConditionalType.onChange &&
            e.requiredValue === dto.requiredValue) instanceof required_dependency_edge_1.RequiredDependencyEdge;
        const dependencyEdgeExists = this.dependsOnExactReqValuesEdges().find((e) => e.dependentOn === dto.parentField && e.requiredValue === dto.requiredValue) instanceof required_dependency_edge_1.RequiredDependencyEdge;
        return goToFlowEdgeExists || dependencyEdgeExists;
    }
    editPageTitle(title, pageNumber, flowKey) {
        const page = this.page(pageNumber, flowKey);
        page.pageTitle = title;
    }
    pageTitles(flowKey) {
        return Array.from({ length: this.pageCount(flowKey) }, (_, i) => this.page(i, flowKey).pageTitle);
    }
    fieldKeyExists(uniqueKey) {
        return this.schemaNodes.some((n) => n.fields.some((f) => f.uniqueKey() === uniqueKey));
    }
    setOrderOfPage(uiOrder, pageNumber, flowKey) {
        this.page(pageNumber, flowKey).fieldOrder = uiOrder;
    }
    addEmptyFlow(flowKey) {
        if (!this.validateFlowName(flowKey)) {
            throw new Error(`Flow name of ${flowKey} is invalid`);
        }
        if (this.getFlows().includes(flowKey)) {
            return;
        }
        const flowEntry = new flow_entry_node_1.FlowEntryNode(flowKey);
        const page = new page_node_1.PageNode();
        const schema = new schema_node_1.SchemaNode();
        this.nodes.push(flowEntry, page, schema);
        this.edges.push(new always_enabled_edge_1.AlwaysEnabledEdge(flowEntry, page), new always_enabled_edge_1.AlwaysEnabledEdge(page, schema));
    }
    addConstGoToFlowCondition(dto) {
        const errors = this.validateConstGoToFlowDTO(dto, false);
        if (errors.length !== 0) {
            throw new Error(errors[0]);
        }
        const existingFlows = this.getFlows();
        if (!existingFlows.includes(dto.flowKey)) {
            this.addEmptyFlow(dto.flowKey);
        }
        const source = this.schemaNodeOfField(dto.parentField);
        const target = this.flowEntryPoint(dto.flowKey);
        const edge = new const_go_to_flow_edge_1.ConstGoToFlowEdge(source, target, dto.parentField, dto.requiredValue, dto.flowKey, dto.conditionalType);
        this.edges.push(edge);
    }
    modifyConstGoToFlowCondition(original, dto) {
        const errors = this.validateConstGoToFlowDTO(dto, true);
        if (errors.length !== 0) {
            throw new Error(errors[0]);
        }
        const existingEdge = this.findGoToFlowEdge(original);
        const index = this.edges.indexOf(existingEdge);
        const edge = new const_go_to_flow_edge_1.ConstGoToFlowEdge(existingEdge.source, existingEdge.target, original.parentField, dto.requiredValue, dto.flowKey, dto.conditionalType);
        this.edges[index] = edge;
    }
    deleteConstGoToFlowCondition(dto) {
        this.deleteEdge(this.findGoToFlowEdge(dto));
    }
    findGoToFlowEdge(dto) {
        const edge = this.goToFlowEdges().find((e) => e.dependentOn === dto.parentField &&
            e.conditionalType === dto.conditionalType &&
            e.flowKey === dto.flowKey &&
            e.requiredValue === dto.requiredValue);
        if (edge instanceof const_go_to_flow_edge_1.ConstGoToFlowEdge) {
            return edge;
        }
        throw new Error(`GoToFlowEdge leading off ${dto.parentField.uniqueKey()} was not found`);
    }
    getFlows() {
        return this.nodes.filter((n) => n instanceof flow_entry_node_1.FlowEntryNode).map((n) => n.flowKey);
    }
    validateFlowName(flowKey) {
        const regEx = new RegExp(/^[\w\-\s\#\-\_\.]+$/);
        return regEx.test(flowKey) && flowKey.trim().length > 0 && !this.getFlows().includes(flowKey);
    }
    deleteFlow(flowKey) {
        const nodesToDelete = new Array();
        const edgesToDelete = this.edges.filter((e) => e instanceof const_go_to_flow_edge_1.ConstGoToFlowEdge && e.flowKey === flowKey);
        this.walkFlow(flowKey, (n, p, e, f) => {
            nodesToDelete.push(n);
            if (e instanceof edge_1.EdgeBase) {
                edgesToDelete.push(e);
            }
        });
        for (const e of edgesToDelete) {
            this.deleteEdge(e);
        }
        for (const n of nodesToDelete) {
            this.deleteNode(n);
        }
    }
    constGoToFlowConditionsOfField(field) {
        const node = this.schemaNodeOfField(field);
        const edges = this.goToFlowEdges().filter((e) => e.source === node && e.dependentOn.uniqueKey() === field.uniqueKey());
        return edges.map((e) => new const_go_to_flow_dto_1.ConstGoToFlowDTO(field, e.requiredValue, e.flowKey, e.conditionalType));
    }
    fieldDefinitions() {
        return this.definitions;
    }
    addFieldDefinition(field) {
        this.definitions.push(field);
    }
    deleteFieldDefinition(field) {
        this.definitions.splice(this.definitions.indexOf(field), 1);
        for (const node of this.schemaNodes) {
            for (const schemaNodeField of node.fields) {
                if (schemaNodeField instanceof reference_field_1.ReferenceField &&
                    schemaNodeField.reference.uniqueKey() === field.uniqueKey()) {
                    this.removeField(schemaNodeField);
                }
            }
        }
    }
}
exports.FormBuilder = FormBuilder;
