import { Timestamp } from 'firebase/firestore'

type UserSid = string
type ChannelId = string

export class Message {
  id?: string
  from: UserSid
  timestamp: Timestamp
  version: number
  message: string
  source: any

  constructor(json: any, id: string | undefined = undefined) {
    this.id = id
    this.from = json.from
    this.timestamp = json.timestamp
    this.version = json.version
    this.message = json.message
    this.source = json.source
  }
}

export type Messages = { [key: ChannelId]: Message[] }

export interface RecentMessage extends Message {
  id: never
  fromName: string
}

export class Channel {
  documentId?: ChannelId
  version: number
  public: boolean
  participants: string[]
  subdomain: string
  read: { [key: UserSid]: Timestamp }
  recent: RecentMessage | null
  unreadCount?: number

  constructor(json: any, documentId: ChannelId | undefined = undefined) {
    this.documentId = documentId
    this.public = json.public
    this.participants = json.participants
    this.version = json.version
    this.recent = json.recent
    this.subdomain = json.subdomain
    this.read = json.read
  }
}

export type Channels = { [key: ChannelId]: Channel }
