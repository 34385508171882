"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ObjectArrayUiSchema = void 0;
const json_schema_keys_1 = require("../../json_schema_keys");
const schema_object_1 = require("../schema_object");
const field_ui_schema_1 = require("./field_ui_schema");
class ObjectArrayUiSchema extends field_ui_schema_1.FieldUiSchema {
    constructor(objectField) {
        super();
        const items = new schema_object_1.SchemaObject();
        items[json_schema_keys_1.JsonSchemaKeys.UI_ORDER_KEY] = objectField.fields.map((f) => f.uniqueKey());
        for (const field of objectField.fields) {
            const schema = field.uiSchema();
            if (!(schema instanceof field_ui_schema_1.EmptyUiSchema)) {
                items[field.uniqueKey()] = field.uiSchema();
            }
        }
        this[json_schema_keys_1.JsonSchemaKeys.ITEMS_KEY] = items;
    }
}
exports.ObjectArrayUiSchema = ObjectArrayUiSchema;
