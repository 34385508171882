import { render, staticRenderFns } from "./UnitMinimap.vue?vue&type=template&id=3234ebbc&scoped=true&"
import script from "./UnitMinimap.vue?vue&type=script&setup=true&lang=ts&"
export * from "./UnitMinimap.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./UnitMinimap.vue?vue&type=style&index=0&id=3234ebbc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3234ebbc",
  null
  
)

export default component.exports