export default class Division {
  id: number | null = null
  uuid: string | null = null
  name: string | null = null
  hexCode: string | null = null
  jobCustomFieldsSchema: { [key: string]: object } | null = null
  ownerId: string | null = null

  constructor(json) {
    if (!json) return

    this.id = json.id
    this.uuid = json.uuid
    this.name = json.name
    this.hexCode = json.hex_code
    this.jobCustomFieldsSchema = json.job_custom_fields_schema
    this.ownerId = json.owner_id
  }
}
