"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchemaObject = void 0;
/**
 * A class which accepts any property added with a string key,
 * and has an iterator to loop over its keys. This is
 * a useful class for modelling schema where anything could exist,
 * but we still want strongly typed setters to control access a bit
 */
class SchemaObject {
    *[Symbol.iterator]() {
        const properties = Object.keys(this);
        for (const i of properties) {
            yield [i, this[i]];
        }
    }
    get(key) {
        return this[key];
    }
    get size() {
        return Object.keys(this).length;
    }
}
exports.SchemaObject = SchemaObject;
