<template>
  <v-row dense class="py-2">
    <v-col v-if="icon" class="shrink" align-self="center">
      <v-icon>{{ icon }}</v-icon>
    </v-col>
    <v-col align-self="center">
      <vs-text>{{ primaryText }}</vs-text>
      <vs-text type="caption" truncate>{{ secondaryText }}</vs-text>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'IconTwoLineCell',
  props: {
    icon: {
      type: String,
      required: false,
      default: null,
    },
    primaryText: {
      type: String,
      required: true,
    },
    secondaryText: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>
.vs-caption {
  font-size: 12px;
  line-height: normal;
}

.vs-paragraph {
  line-height: 16px;
}
</style>
