<template>
  <vs-bottom-sheet is-visible>
    <form-layout
      :title="title"
      :hide-bottom-bar="hideBottomBar"
      @on-dismiss="dismiss"
    >
      <vs-wrapper>
        <vs-form ref="bulk-update-form">
          <slot />
        </vs-form>

        <vs-text v-if="showHint" type="subdued">
          Selected jobs have different values
        </vs-text>

        <v-spacer />
      </vs-wrapper>

      <template #bulk-update-save-button>
        <div class="bulk-update-save-button">
          <vs-button
            v-if="showSaveButton"
            data-test="bulk-update-save"
            :label="buttonLabel"
            :loading="saving"
            :disabled="!hasChanges"
            full-width
            @click="save"
          />
        </div>
      </template>

      <template #snack-bar-wrapper>
        <slot name="error-snackbar" />
      </template>
    </form-layout>
  </vs-bottom-sheet>
</template>

<script>
import FormLayout from '../common/FormLayout.vue'

export default {
  components: { FormLayout },

  props: {
    buttonLabel: {
      type: String,
      default: 'Update',
    },
    title: {
      type: String,
      default: 'Update',
    },
    saving: {
      type: Boolean,
      default: false,
    },
    hasChanges: {
      type: Boolean,
      default: false,
    },
    showHint: {
      type: Boolean,
      default: false,
    },
    showSaveButton: {
      type: Boolean,
      default: true,
    },
    hideBottomBar: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    async save() {
      if (this.$refs['bulk-update-form'].isValid()) {
        this.$emit('save')
      }
    },
    dismiss() {
      this.$emit('dismiss')
    },
  },
}
</script>

<style scoped>
.bulk-update-save-button {
  padding-left: var(--space-large);
  padding-right: var(--space-large);
}
</style>
