import { isEqual, orderBy } from 'lodash'
import { getResourceTypeActivities } from '../../api/resource_type_activities'
import Activity from '../../models/activity'
import { errorMutationHandler } from '../utils'

const state = () => {
  return {
    all: {},
    loading: false,
    error: null,
  }
}

export const getters = {
  getById: (state) => (id) => state.all[id] || null,
  getLoading: (state) => state.loading,

  getVisibleActivities: (state) =>
    Object.values(state.all).filter((rta) => rta.isActive),

  getSelectableActivities: (_state, getters, _rootState, rootGetters) =>
    orderBy(
      getters.getVisibleActivities.filter(
        (rta) => rootGetters['tasks/isItemVisible'](rta.taskId),
        (rta) => rta.taskName.toLowerCase()
      )
    ),

  getActivitiesForBusinessUnit: (_state, getters) => (businessUnitId) => {
    return getters.getSelectableActivities.filter(
      (activity) => activity.ownerId === businessUnitId
    )
  },

  getActivitiesForBusinessUnitAndResourceType:
    (_state, getters) => (businessUnitId, resourceTypeId) => {
      return getters
        .getActivitiesForBusinessUnit(businessUnitId)
        .filter((activity) => activity.resourceTypeId === resourceTypeId)
    },

  getAnyActivityRequiresApproval(state) {
    return Object.values(state.all).some(
      (activity) =>
        activity.approvalRequirement === 'Manual' ||
        activity.approvalRequirement === 'Automatic'
    )
  },
}

export const actions = {
  fetchResourceTypeActivities({ commit }) {
    commit('LOADING_ACTIVITIES')

    return getResourceTypeActivities()
      .then((res) => commit('RECEIVE_ACTIVITIES', res))
      .catch((err) => commit('ERROR_ACTIVITIES', err))
  },
}

export const mutations = {
  LOADING_ACTIVITIES(state) {
    state.loading = true
  },
  RECEIVE_ACTIVITIES(state, { data }) {
    const receivedActivities = {}

    data.forEach((json) => {
      receivedActivities[json.id] = new Activity(json)
    })

    if (!isEqual(receivedActivities, state.all)) {
      state.all = receivedActivities
    }

    state.loading = false
    state.error = null
  },
  ERROR_ACTIVITIES(state, error) {
    errorMutationHandler(state, error)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
