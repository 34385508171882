<template>
  <vs-wrapper padding="large">
    <template v-if="!enabled">
      <v-icon :size="64" color="grey lighten-1">notifications_off</v-icon>
      <vs-heading>Notifications are disabled</vs-heading>
      <vs-text v-if="disabled"
        >Your browser may not support this capability, or you have dismissed the
        permission request
      </vs-text>
      <template v-else>
        <vs-text
          >Click enable to start receiving updates about jobs you created,
          requested, or follow
        </vs-text>
        <vs-button full-width label="Enable" @click="enable" />
      </template>
    </template>

    <template v-else>
      <v-icon :size="64" color="grey lighten-1">notifications_on</v-icon>
      <vs-heading>Notifications are enabled</vs-heading>
      <vs-text
        >You'll receive updates about jobs you created, requested, or follow
      </vs-text>
    </template>
  </vs-wrapper>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import VsText from '../vision/VsText.vue'

export default {
  name: 'JobUpdates',
  components: { VsText },

  computed: {
    ...mapState('notifications', ['notifications', 'permission']),

    enabled() {
      return this.permission === 'granted'
    },

    disabled() {
      return [null, 'denied'].includes(this.permission)
    },
  },

  methods: {
    ...mapActions('notifications', ['enableNotifications', 'initialize']),

    async enable() {
      await this.enableNotifications()
      await this.initialize()
    },
  },
}
</script>

<style></style>
