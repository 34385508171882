
import { defineComponent } from 'vue'
import { Prop } from 'vue/types/options'

export default defineComponent({
  name: 'JobTagsDisplay',

  props: {
    tags: Array as Prop<string[]>,
  },
})
