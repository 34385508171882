var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"close-on-content-click":true,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('vs-button',_vm._g(_vm._b({staticClass:"btn",attrs:{"rounded":"","icon":"add","label":"Add a field","full-width":""}},'vs-button',attrs,false),on))]}}]),model:{value:(_vm.addFieldMenu),callback:function ($$v) {_vm.addFieldMenu=$$v},expression:"addFieldMenu"}},[_c('v-card',{staticClass:"overflow-y-auto",attrs:{"max-height":"40em"}},[_c('v-list-item-group',_vm._l((_vm.fieldItems),function(type){return _c('v-list',{key:type.name},[_c('v-subheader',[_vm._v(_vm._s(type.name))]),_vm._l((type.items),function(fieldType){return [(
              _vm.fieldItems.unique &&
              fieldType.name === _vm.fieldItems.unique.items.subjectLocation.name
            )?_c('vs-list-field-item',{key:fieldType.name,attrs:{"disabled":_vm.subjectLocationFieldExists,"item-key":fieldType.name,"icon":_vm.getFieldIcon(fieldType.build()),"label":fieldType.name,"description":fieldType.description},on:{"add-field":function($event){_vm.onFieldClicked(fieldType.build(), fieldType.name)}}}):(
              _vm.fieldItems.unique &&
              fieldType.name === _vm.fieldItems.unique.items.subjectUnit.name
            )?_c('vs-list-field-item',{key:fieldType.name,attrs:{"disabled":_vm.subjectFieldExists,"item-key":fieldType.name,"icon":_vm.getFieldIcon(fieldType.build()),"label":fieldType.name,"description":fieldType.description},on:{"add-field":function($event){_vm.onFieldClicked(fieldType.build(), fieldType.name)}}}):_c('vs-list-field-item',{key:fieldType.name,attrs:{"item-key":fieldType.name,"icon":_vm.getFieldIcon(fieldType.build()),"label":fieldType.name,"description":fieldType.description},on:{"add-field":function($event){_vm.onFieldClicked(fieldType.build(), fieldType.name)}}})]}),_c('v-divider')],2)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }