"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BooleanFieldDefinition = void 0;
const json_schema_keys_1 = require("../json_schema_keys");
const field_ui_schema_1 = require("../models/field_ui_schema/field_ui_schema");
const radio_ui_schema_1 = require("../models/field_ui_schema/radio_ui_schema");
const form_field_definition_1 = require("./form_field_definition");
class BooleanFieldDefinition extends form_field_definition_1.PrimitiveValueField {
    constructor() {
        super(...arguments);
        this.dataType = json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_BOOLEAN;
        this.isRadio = false;
    }
    uiSchema() {
        if (this.isRadio) {
            return new radio_ui_schema_1.RadioUiSchema();
        }
        return new field_ui_schema_1.EmptyUiSchema();
    }
}
exports.BooleanFieldDefinition = BooleanFieldDefinition;
