
import { computed, defineComponent, PropType } from 'vue'
import { Availability } from '@/api/pools'
import { sortBy } from 'lodash'

export default defineComponent({
  name: 'PoolStageAvailability',

  props: {
    candidates: {
      type: Array as PropType<Availability[]>,
    },
  },

  setup(props) {
    const sortedCandidates = computed<Availability[]>(() => {
      return sortBy(props.candidates, [
        'recipient_name',
        (c) => c.status === 'NotAvailable',
      ])
    })

    return {
      sortedCandidates,
    }
  },
})
