import MIMEType from 'whatwg-mimetype'

/**
 * Parses the mime type and additional parameters from a data url string
 * @param dataUrlString
 * @return MIMEType MIME type of the data url with parameters
 */
export function parseDataUrlMimeType(dataUrlString) {
  const dataUrlMimePart = dataUrlString.split(',')[0]
  if (!dataUrlMimePart || !dataUrlMimePart.startsWith('data:')) return null
  return new MIMEType(dataUrlMimePart.substring('data:'.length))
}
