import { render, staticRenderFns } from "./JobInventoryGroupEdit.vue?vue&type=template&id=64dd5323&scoped=true&"
import script from "./JobInventoryGroupEdit.vue?vue&type=script&setup=true&lang=ts&"
export * from "./JobInventoryGroupEdit.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./JobInventoryGroupEdit.vue?vue&type=style&index=0&id=64dd5323&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64dd5323",
  null
  
)

export default component.exports