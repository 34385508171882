export default class Task {
  id = null
  name = null
  isActive = true
  resourceTypes = []
  jobCustomFieldsSchema = null
  ownerId = null
  ownerName = null
  version = null

  constructor(json) {
    if (!json) return

    this.id = json.id
    this.name = json.name
    this.isActive = json.is_active
    this.resourceTypes = json.resource_types
    this.jobCustomFieldsSchema = json.job_custom_fields_schema
    this.ownerId = json.owner_id
    this.ownerName = json.owner_name
    this.version = json.version
  }

  get isArchived() {
    return !this.isActive
  }
}
