<template>
  <v-container>
    <h1>Hello!</h1>
    <div v-for="org in organizations" :key="org.id">
      <a v-if="org.hubs.length === 0" :href="org.url">
        {{ org.name }}
      </a>
      <div v-else>
        {{ org.name }}
        <ul>
          <li v-for="hub in org.hubs" :key="hub.id">
            <a :href="hub.url">{{ hub.name }}</a>
          </li>
        </ul>
      </div>
    </div>
  </v-container>
</template>

<script>
import jwtDecode from 'jwt-decode'
import { getInstanceAsync } from '@/auth'

export default {
  name: 'Portal',

  data: function () {
    const urlParams = new URLSearchParams(window.location.search)
    const sessionToken = urlParams.get('session_token')
    const decodedToken = jwtDecode(sessionToken)
    return {
      sessionToken,
      decodedToken,
      env: decodedToken.env,
      state: urlParams.get('state'),
      organizations: [],
    }
  },

  async created() {
    const auth = await getInstanceAsync()
    const domain = auth.auth0Client.domainUrl
    const url =
      `/api/v2/tenants/${this.decodedToken.env}/organizations?` +
      new URLSearchParams({
        session_token: this.sessionToken,
      })
    const payload = await fetch(url).then((r) => r.json())

    this.organizations = payload
      .map((o) => {
        return {
          ...o,
          url:
            `${domain}/continue?` +
            new URLSearchParams({
              state: this.state,
              org_id: o.id,
              env: this.env,
            }),
          hubs: o.hubs.map((h) => {
            return {
              ...h,
              url:
                `${domain}/continue?` +
                new URLSearchParams({
                  state: this.state,
                  org_id: o.id,
                  hub_id: h.id,
                  env: this.env,
                }),
            }
          }),
        }
      })
      .sort((a, b) => {
        if (a.hubs.length < b.hubs.length) {
          return 1
        }
        if (a.hubs.length > b.hubs.length) {
          return -1
        }
        if (a.name.toUpperCase() < b.name.toUpperCase()) {
          return -1
        }
        if (a.name.toUpperCase() > b.name.toUpperCase()) {
          return 1
        }
        return 0
      })

    if (this.organizations.length <= 1) {
      const [org] = this.organizations
      const [hub] = org?.hubs || []
      window.location.replace(
        `${domain}/continue?` +
          new URLSearchParams({
            state: this.state,
            env: this.env,
            org_id: org?.id,
            hub_id: hub?.id,
          })
      )
    }
  },
}
</script>

<style scoped></style>
