"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.arrayFieldMatcher = void 0;
const array_field_definition_1 = require("../../form_fields/array_field_definition");
const json_schema_keys_1 = require("../../json_schema_keys");
// NOT including object arrays
const arrayFieldMatcher = (uniqueKey, isRequired, properties, uiSchema, fieldDefinitions, next) => {
    var _a;
    const type = properties[json_schema_keys_1.JsonSchemaKeys.TYPE_KEY];
    if (type === json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_ARRAY) {
        var itemDataType = null;
        const itemWidgetType = ((_a = uiSchema[json_schema_keys_1.JsonSchemaKeys.ITEMS_KEY]) !== null && _a !== void 0 ? _a : {})[json_schema_keys_1.JsonSchemaKeys.UI_WIDGET_KEY];
        switch (properties.items.type) {
            case "string":
                switch (itemWidgetType) {
                    case json_schema_keys_1.JsonSchemaKeys.WIDGET_TYPE_USER_SELECTOR:
                        itemDataType = array_field_definition_1.ArrayItemDataType.user;
                        break;
                    case json_schema_keys_1.JsonSchemaKeys.WIDGET_TYPE_BUSINESS_UNIT_SELECTOR:
                        itemDataType = array_field_definition_1.ArrayItemDataType.businessUnit;
                        break;
                    default:
                        itemDataType = array_field_definition_1.ArrayItemDataType.string;
                }
                break;
            case "number":
                switch (itemWidgetType) {
                    case json_schema_keys_1.JsonSchemaKeys.WIDGET_TYPE_LOCATION_SELECTOR:
                        itemDataType = array_field_definition_1.ArrayItemDataType.location;
                        break;
                    case json_schema_keys_1.JsonSchemaKeys.WIDGET_TYPE_COMPANY_SELECTOR:
                        itemDataType = array_field_definition_1.ArrayItemDataType.company;
                        break;
                    default:
                        itemDataType = array_field_definition_1.ArrayItemDataType.decimal;
                }
                break;
            case "integer":
                itemDataType = array_field_definition_1.ArrayItemDataType.integer;
                break;
        }
        if (itemDataType !== null) {
            const field = new array_field_definition_1.ArrayFieldDefinition(itemDataType);
            field.maximumItems = properties[json_schema_keys_1.JsonSchemaKeys.MAX_ITEMS_KEY];
            field.minimumItems = properties[json_schema_keys_1.JsonSchemaKeys.MIN_ITEMS_KEY];
            field.title = properties.title;
            field.description = properties.description;
            field.defaultValue = properties.default;
            return field;
        }
    }
    return next(uniqueKey, isRequired, properties, uiSchema, fieldDefinitions);
};
exports.arrayFieldMatcher = arrayFieldMatcher;
