export default class FollowUpAction {
  constructor(json) {
    this.id = json.id
    this.title = json.title
    this.description = json.description
    this.completedBy = json.completed_by
    this.completedOn = json.completed_on
  }

  id: string
  title: string
  description: string
  completedBy: string | null
  completedOn: string | null

  get isCompleted(): boolean {
    return !!this.completedBy
  }
}
