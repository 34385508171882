import JobHistory from '@/models/job-history'
import { groupBy, orderBy } from 'lodash'
import { computed } from 'vue'
import JobComment from '@/models/job-comment'
import { useFormattedJobEvents } from './useFormattedJobEvents'

export function useActivityLog(props: {
  jobId: string
  jobHubId: string
  startTime?: moment.Moment | null
  endTime?: moment.Moment | null
  loadHistoryError?: boolean
  history?: JobHistory[] | null
  comments?: JobComment[] | null
}) {
  const { formattedJobEvents } = useFormattedJobEvents(props.jobId)

  const jobCommentEntries = computed<JobHistory[]>(() => {
    if (!props.comments) return []

    return props.comments.map((comment) => {
      return new JobHistory({
        type: 'comment',
        modified: comment.createdOn,
        modified_by: comment.createdById,
        fields_changed: [
          {
            column_name: 'comment',
            original_value: comment.comment,
            new_value: null,
            is_important: true,
          },
        ],
      })
    })
  })

  const jobHistory = computed(() => {
    const allHistory = [
      ...formattedJobEvents.value,
      ...jobCommentEntries.value,
      ...(props.history ?? []),
    ]
    return groupBy(
      orderBy(allHistory, [(history) => history?.modified.unix()], ['desc']),
      (history) => history?.modified.format('MMM DD, YYYY')
    )
  })

  return {
    jobHistory,
  }
}
