var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vs-toggle-buttons',{attrs:{"small":"","items":[
    {
      text: 'Day',
      to: { name: 'day-view' },
      active: _vm.$route.path.includes('day'),
    },
    {
      text: 'Week',
      to: { name: 'week-view' },
      active: !_vm.$route.path.includes('day'),
    },
  ]}})
}
var staticRenderFns = []

export { render, staticRenderFns }