<template>
  <vs-wrapper space="small">
    <vs-card no-content-padding>
      <v-date-picker
        class="date-picker"
        :events="setDateButtonClass"
        full-width
        no-title
        show-adjacent-months
        @click:date="handleDateClick"
      />
    </vs-card>
    <v-menu
      v-model="showMenu"
      data-test="bulk-create-qty-menu"
      top
      nudge-top="25px"
      :nudge-left="qtyMenuLeftNudge"
      :attach="attachTo"
    >
      <v-list>
        <v-list-item>
          <v-row align="center" no-gutters>
            <v-col>
              <v-select
                v-model="quantity"
                class="justify-center"
                append-icon="mdi-chevron-down"
                dense
                :items="quantityOptions"
                append
                @click.stop
                @change="updateTimeSlotDates"
              >
                <template #selection="{ item }">
                  <span class="d-flex justify-center" style="width: 100%">
                    {{ item }}
                  </span>
                </template>
              </v-select>
            </v-col>
            <v-col>
              <vs-text class="menu-text">Jobs will be created</vs-text>
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-row class="clear-jobs-row" align="center" justify="center" no-gutters>
      <v-col class="shrink mr-1">
        <vs-text
          class="text-align: center"
          data-test="bulk_create_label"
          type="subdued"
        >
          {{ bulkCreateText }}
        </vs-text>
      </v-col>
      <v-col class="shrink ml-1">
        <vs-button
          data-test="bulk-create-clear"
          destructive
          shrink
          small
          type="secondary"
          label="Clear"
          @click="clearBulkCreate"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="pa-1">
        <vs-autocomplete
          data-test="bulk-create-start"
          label="Start time"
          :value="timeSlotStart"
          :items="startTimes"
          item-value="value"
          item-text="value"
          required
          @input="updateTimeSlotStart"
        >
        </vs-autocomplete>
      </v-col>
      <v-col class="pa-1">
        <vs-autocomplete
          data-test="bulk-create-end"
          label="End time"
          :value="timeSlotDuration"
          :items="endTimes"
          item-value="value"
          item-text="text"
          required
          @input="updateTimeSlotDuration"
        >
        </vs-autocomplete>
      </v-col>
    </v-row>
  </vs-wrapper>
</template>

<script>
import moment from 'moment'

export default {
  name: 'TicketFormMultiDayScheduler',

  props: {
    startTime: {
      type: Object,
      default: null,
    },
    endTime: {
      type: Object,
      default: null,
    },
    timeSlotDates: {
      type: Array[String],
      default: [],
    },
    timeSlotStart: {
      type: String,
      default: '',
    },
    timeSlotDuration: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    attachTo: null,
    showMenu: false,
    quantity: 1,
    quantityOptions: Array.from({ length: 26 }, (v, i) => i),
    selectedDate: null,
    DAYS_OF_WEEK: {
      SATURDAY: 5,
      SUNDAY: 6,
    },
    BULK_JOB_LIMIT: 100,
  }),
  computed: {
    qtyMenuLeftNudge() {
      if (this.dayOfWeek === this.DAYS_OF_WEEK.SUNDAY) {
        return '16px'
      }
      if (this.dayOfWeek === this.DAYS_OF_WEEK.SATURDAY) {
        return '112px'
      }
      return '64px'
    },
    dayOfWeek() {
      return new Date(this.selectedDate).getDay()
    },
    bulkCreateText() {
      if (this.timeSlotDates.length === 1) {
        return '1 job will be created'
      } else if (this.timeSlotDates.length > 1) {
        return `${this.timeSlotDates.length} jobs will be created (Max: ${this.BULK_JOB_LIMIT})`
      } else {
        return 'Select a date to begin creating jobs'
      }
    },
    startTimes() {
      const times = []
      for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
          times.push({ value: moment({ hour, minute }).format('H:mm') })
        }
      }

      return this.startOptionsAtCurrentValue(times, this.timeSlotStart)
    },
    endTimes() {
      const times = []
      this.startTimes.map((time) => {
        const duration = this.getDurationHours(time.value)
        times.push({
          value: duration,
          text: `${time.value} ${this.getDurationString(duration)}`,
        })
      })

      return this.startOptionsAtCurrentValue(times, this.timeSlotDuration)
    },
  },
  created() {
    this.updateTimeSlotStart(this.startTime.format('H:mm'))
    this.updateTimeSlotDuration(
      moment.duration(this.endTime.diff(this.startTime)).asHours()
    )
  },
  methods: {
    getDurationHours(endTime) {
      const startMoment = moment(this.timeSlotStart, ['H:mm'])
      const endMoment = moment(endTime, ['H:mm'])

      let duration = endMoment.diff(startMoment, 'hours', true)

      if (duration < 0) {
        duration = 24 + duration
      }

      return duration
    },
    getDurationString(duration) {
      if (duration < 1) {
        return `(${duration * 60} minutes)`
      } else if (duration === 1) {
        return '(1 hour)'
      }

      return `(${duration} hours)`
    },
    startOptionsAtCurrentValue(options, currentValue) {
      const currentOption = options.map((t) => t.value).indexOf(currentValue)

      return [
        ...options.slice(currentOption, options.length),
        ...options.slice(0, currentOption),
      ]
    },
    setDateButtonClass(dateString) {
      const isSelectedDate = this.selectedDate === dateString
      const dateHasJobs = this.timeSlotDates.includes(dateString)

      if (isSelectedDate || dateHasJobs) {
        return dateHasJobs ? `d${dateString} blue` : `d${dateString}`
      }

      return []
    },
    handleDateClick(dateString) {
      this.showMenu = false
      this.selectedDate = dateString

      const scheduledJobsOnSelectedDate = this.timeSlotDates.filter(
        (date) => date === this.selectedDate
      )

      this.quantity = scheduledJobsOnSelectedDate.length || 1

      if (scheduledJobsOnSelectedDate.length === 0) {
        this.updateTimeSlotDates(1)
      }

      setTimeout(() => {
        this.attachTo = `.d${dateString}`
        this.showMenu = true
      }, 50)
    },

    updateTimeSlotDates(value) {
      const remainingDates = this.timeSlotDates.filter(
        (date) => date !== this.selectedDate
      )
      const updatedDates = Array(value).fill(this.selectedDate)
      const newDates = remainingDates.concat(updatedDates)

      this.showMenu = false
      this.$emit('update-time-slot-dates', newDates)
    },
    updateTimeSlotStart(value) {
      this.$emit('update-time-slot-start', value)
    },
    updateTimeSlotDuration(value) {
      this.$emit('update-time-slot-duration', value)
    },
    clearBulkCreate() {
      this.$emit('update-time-slot-dates', [])
    },
  },
}
</script>

<style scoped>
.date-picker {
  padding: var(--space-small) 0;
  border-radius: var(--space-base) !important;
}
::v-deep .date-picker .v-btn--rounded:hover {
  background-color: var(--color-blue--lightest) !important;
  color: var(--color-blue--light) !important;
}
::v-deep .date-picker .v-btn--rounded:focus {
  background-color: var(--color-blue) !important;
  color: var(--color-white) !important;
}
::v-deep .date-picker .v-menu__content {
  background-color: var(--color-white) !important;
  min-width: 160px !important;
  max-height: 90px !important;
  border-radius: 10px !important;
  padding-top: 8px !important;
  padding-bottom: 0 !important;
}
::v-deep .date-picker .v-list {
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 10px !important;
}
::v-deep .date-picker .v-input__slot {
  margin: 0 !important;
  border: 1px solid var(--color-blue) !important;
  border-radius: 15px !important;
  min-width: fit-content !important;
}
::v-deep .date-picker .v-input__slot::before {
  border-style: none !important;
}
::v-deep .date-picker .v-input__slot::after {
  border-style: none !important;
}
.menu-text {
  font-size: 12px !important;
  margin-bottom: 10px !important;
  line-height: 12px !important;
  font-weight: 800 !important;
  text-transform: none !important;
  text-align: start !important;
  margin-left: 3px !important;
}
.clear-jobs-row {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-bottom: 5px !important;
}
</style>
