import store from '../../store'
import { get } from 'lodash'

export class FormContext {
  constructor({ hub, currentUser, additionalContext }) {
    this.data = {
      ironsight: {
        locations: (id) => store.getters['locations/getById'](id),
        users: (id) => store.getters['users/getBySid'](id),
        businessUnits: (id) =>
          store.getters['businessUnits/getBusinessUnitById'](id),
      },
      context: {
        hub: {
          id: hub?.id,
          name: hub?.name,
        },
        currentUser: {
          id: currentUser?.id,
          name: currentUser?.name,
        },
        ...additionalContext,
      },
    }
  }

  resolveProperty(path, args) {
    const resolver = get(this.data, path)
    const { id, property } = args

    if (resolver instanceof Function) {
      const resolvedValue = resolver(id)
      if (!resolvedValue) return null
      return get(resolvedValue, property)
    }

    return resolver
  }
}
