<template>
  <v-tooltip bottom close-delay="100">
    <template #activator="{ on }">
      <v-img
        width="32"
        height="32"
        :loading="loading"
        :src="require(`@/components/common/unit-icons/${statusIcon}`)"
        v-on="on"
      />
    </template>
    <span>{{ statusHelpText }}</span>
  </v-tooltip>
</template>

<script>
import { LOADING, OFFLINE, STANDBY, ACTIVE } from '@/models/unit_number'

export default {
  name: 'UnitNumberStatusIcon',

  props: {
    status: {
      type: Number,
      default: () => LOADING,
    },
    hasOperator: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    loading() {
      return this.status === LOADING
    },
    statusHelpText() {
      switch (this.status) {
        case STANDBY:
          return 'Standby'
        case ACTIVE:
          return 'Active'
        case OFFLINE:
        default:
          return 'Offline'
      }
    },
    statusIcon() {
      let statusName
      switch (this.status) {
        case STANDBY:
          statusName = 'standby'
          break
        case ACTIVE:
          statusName = 'active'
          break
        case OFFLINE:
        default:
          statusName = 'offline'
          break
      }
      return `arrow-${statusName}-${this.hasOperator ? 'op' : 'noop'}.png`
    },
  },
}
</script>
