import { render, staticRenderFns } from "./banner.vue?vue&type=template&id=24c7d818&scoped=true&"
import script from "./banner.vue?vue&type=script&lang=ts&setup=true&"
export * from "./banner.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./banner.vue?vue&type=style&index=0&id=24c7d818&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24c7d818",
  null
  
)

export default component.exports