import { Banner } from '../bannerStore'
import march7SalesEvent from './march_7_2024_sales_event'
import june13SalesEvent from './june_13_2024_sales_event'
import september19SalesEvent from './september_19_2024_sales_event'

export const allLocalBanners: Banner[] = [
  march7SalesEvent,
  june13SalesEvent,
  september19SalesEvent,
]
