"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ObjectFieldDefinition = void 0;
const form_field_definition_1 = require("./form_field_definition");
const form_builder_1 = require("../form_builder/form_builder");
const subject_field_definition_1 = require("./subject_field_definition");
const subject_location_field_definition_1 = require("./subject_location_field_definition");
const object_field_ui_schema_1 = require("../models/field_ui_schema/object_field_ui_schema");
const json_schema_keys_1 = require("../json_schema_keys");
const object_field_property_description_1 = require("../models/field_property_descriptions/object_field_property_description");
const object_array_property_description_1 = require("../models/field_property_descriptions/object_array_property_description");
const object_array_ui_schema_1 = require("../models/field_ui_schema/object_array_ui_schema");
class ObjectFieldDefinition extends form_field_definition_1.PrimitiveValueField {
    constructor() {
        super(...arguments);
        this.builder = form_builder_1.FormBuilder.newForm();
        this.isArray = false;
    }
    get dataType() {
        return this.isArray ? json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_ARRAY : json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_OBJECT;
    }
    uiSchema() {
        return this.isArray ? new object_array_ui_schema_1.ObjectArrayUiSchema(this) : new object_field_ui_schema_1.ObjectFieldUiSchema(this);
    }
    propertyDescription() {
        return this.isArray ? new object_array_property_description_1.ObjectArrayPropertyDescription(this) : new object_field_property_description_1.ObjectFieldPropertyDescription(this);
    }
    validateField(field) {
        if (field instanceof subject_field_definition_1.SubjectFieldDefinition) {
            throw new Error("Subject fields cannot be nested in an object field");
        }
        if (field instanceof subject_location_field_definition_1.SubjectLocationFieldDefinition) {
            throw new Error("Subject location fields cannot be nested in an object field");
        }
    }
    addField(field) {
        this.validateField(field);
        this.builder.addFieldToPage(field, 0, json_schema_keys_1.JsonSchemaKeys.ROOT_SCHEMA_KEY);
    }
    addFields(fields) {
        for (const field of fields) {
            this.validateField(field);
            this.builder.addFieldToPage(field, 0, json_schema_keys_1.JsonSchemaKeys.ROOT_SCHEMA_KEY);
        }
    }
    removeField(field) {
        this.builder.removeField(field);
    }
    get fields() {
        return this.builder.fieldsOfPage(0, json_schema_keys_1.JsonSchemaKeys.ROOT_SCHEMA_KEY);
    }
    fieldIsOrphaned(field) {
        return this.builder.fieldIsOrphaned(field);
    }
    reorderField(field, newIndex) {
        this.builder.reorderField(field, newIndex);
    }
    immediateConstDependenciesOfField(field) {
        return this.builder.immediateConstDependenciesOfField(field);
    }
    immediateReqDependenciesOfField(field) {
        return this.builder.immediateReqDependenciesOfField(field);
    }
    immediateParentsOfField(field) {
        return this.builder.immediateParentsOfField(field);
    }
    fieldsSelectableDependencies(field) {
        return this.builder.fieldsSelectableDependencies(field);
    }
    fieldHasDependencies(field) {
        return this.builder.fieldHasDependencies(field);
    }
    fieldIsDependency(field) {
        return this.builder.fieldIsDependency(field);
    }
    validateConstDTO(dto, isEditing) {
        return this.builder.validateConstDependencyDTO(dto, isEditing);
    }
    validateReqDTO(dto, isEditing) {
        return this.builder.validateReqDependencyDTO(dto, isEditing);
    }
    addConstDependencyToField(dto) {
        this.builder.addConstDependencyToField(dto);
    }
    addReqDependencyToField(dto) {
        this.builder.addReqDependencyToField(dto);
    }
    modifyConstDependencyOfField(original, dto) {
        this.builder.modifyConstDependencyOfField(original, dto);
    }
    modifyReqDependencyOfField(original, dto) {
        this.builder.modifyReqDependencyOfField(original, dto);
    }
    deleteConstDependencyOfField(dto) {
        this.builder.deleteConstDependencyOfField(dto);
    }
    deleteReqDependencyOfField(dto) {
        this.builder.deleteReqDependencyOfField(dto);
    }
    copy() {
        // Because clone and object.assign will hold on to a reference to the same form builder,
        // the entire form builder reference would need to be cloned too in order to implement this
        throw new Error("Object fields cannot be copied, only their children can");
    }
}
exports.ObjectFieldDefinition = ObjectFieldDefinition;
