var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vs-wrapper',[_c('vs-text',[_vm._v(_vm._s(_vm.scheduleOptionDescription))]),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-1"},[_c('vs-autocomplete',{attrs:{"data-test":"time-delta-quantity-selector","type":"number","value":_vm.timeDeltaQuantity,"items":_vm.getTimeDeltaQuantityOptions,"item-value":"text","required":"","rules":[
          (value) =>
            parseFloat(value) <= 0 && `Enter a value that's greater than 0`,
          (value) =>
            !!value &&
            !Number.isInteger(parseFloat(value)) &&
            `Please enter a whole number`,
        ],"free-form":""},on:{"input":_vm.handleQuantityChange}})],1),_c('v-col',{staticClass:"pa-1"},[_c('vs-autocomplete',{attrs:{"data-test":"time-delta-unit-selector","value":_vm.timeDeltaUnit,"items":_vm.getTimeDeltaUnitOptions,"item-value":"value","required":"","disable-clear":""},on:{"input":_vm.handleUnitChange}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }