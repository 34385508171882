import { ref, computed, Ref } from 'vue'

/*
 * A composable for creating an object and treating it as a stack.
 * Access the current state through the `current` return value.
 * Change the current state using push and pop
 */
export const useObjectAsStack = <T = never>(initialValue: T) => {
  const data = ref<T[]>([initialValue]) as Ref<T[]>

  const pop = () => {
    if (data.value.length > 1) {
      const removed = data.value.pop() ?? null
      return { removed, next: data.value[data.value.length - 1] }
    }

    return { removed: null, next: data.value[data.value.length - 1] }
  }

  const push = (item: T) => {
    data.value.push(item)
  }

  const current = computed(() => {
    return data.value[data.value.length - 1]
  })

  const exportState = () => {
    return JSON.stringify(data.value)
  }

  const importState = (state: string) => {
    data.value = JSON.parse(state)
    return { next: data.value[data.value.length - 1] }
  }

  return { current, pop, push, exportState, importState }
}
