<template>
  <vs-wrapper space="large">
    <v-row no-gutters align="center">
      <v-col>
        <vs-heading type="overline">What needs to be done</vs-heading>
      </v-col>
    </v-row>

    <vs-wrapper space="small">
      <v-row no-gutters>
        <v-col>
          <TaskItemHeader
            data-test="task-header"
            :resource-type-value="selectedResourceType"
            :resource-type-items="sortedResourceTypes"
            :activity-value="activityId"
            :activity-items="activities"
            :task-status="status"
            :active-duration="activeDuration"
            :disabled="resourceTypeActivityFieldDisabled"
            :should-show-status="!!unitId"
            @update-resource="updateResourceType"
            @update-activity="updateActivity"
          />
        </v-col>
      </v-row>

      <vs-wrapper :class="{ 'border rounded': displayGroupRow }" space="small">
        <vs-autocomplete
          v-if="displayAssignmentField"
          ref="resourceAssignment"
          :disabled="assignmentFieldDisabled"
          :items="assignableUnitsAndServiceProviders"
          :label="resourceAssignmentDropDownLabel"
          :value="resourceAssignmentValue"
          :required="assignmentRequired"
          data-test="resource-assignment"
          @input="updateResourceAssignment"
        />

        <v-row
          v-if="displayGroupRow"
          data-test="job-assignment-row"
          class="d-flex align-center"
        >
          <v-col class="pl-6 shrink">
            <v-row>
              <v-icon small class="d-flex shrink pb-1 pr-2"
                >mdi-view-agenda-outline
              </v-icon>
              <vs-text
                type="subdued"
                class="pb-1"
                data-test="job-assigned-group-label"
                >{{ jobAssignedToGroupLabel }}
              </vs-text>
            </v-row>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="shrink pr-6">
            <v-btn
              data-test="remove-job-from-group"
              icon
              small
              @click="removeJobFromGroup"
            >
              <v-icon small color="red">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </vs-wrapper>

      <slot name="assigned" />

      <TicketUnitNumberCrew
        v-if="unitId && unit"
        data-test="pick-operator"
        :resource-owner="unit.ownerId"
        :crew="crew"
        :disabled="isDisabled"
        @crew-changed="updateCrew"
      />

      <v-row
        no-gutters
        align="center"
        :class="{
          'pt-5': true,
          'pb-0': true,
          'negative-margin-correction': mapLink,
        }"
      >
        <v-col>
          <vs-heading type="overline">Where From/To?</vs-heading>
        </v-col>
        <v-col v-if="mapLink" class="shrink">
          <div>
            <v-tooltip left>
              <template #activator="{ on }">
                <div v-on="on">
                  <vs-button
                    data-test="see-on-map"
                    icon="mdi-map-outline"
                    type="tertiary"
                    small
                    :to="mapLink"
                  />
                </div>
              </template>
              <span>View on map</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>

      <job-locations-and-inventory-edit
        ref="inventory-edit-component"
        class="pb-4"
        :activity-id="activityId"
        :destination-location-id="destinationLocationId"
        :inventories="inventory"
        :job-hub-id="hubId"
        :is-pickup-required="false"
        :is-disabled="isDisabled"
        @update-inventories="updateInventory"
        @update-destination="updateDestination"
        @add-location:open="$emit('add-location:open')"
        @add-location:close="$emit('add-location:close')"
      />
    </vs-wrapper>
  </vs-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import TicketUnitNumberCrew from './TicketUnitNumberCrew'
import TaskItemHeader from './TaskItemHeader'
import JobLocationsAndInventoryEdit from './JobLocationsAndInventoryEdit'
import { orderBy } from 'lodash'
import { onMounted, ref, toRef } from 'vue'
import { useHubEntitlementsQuery } from '@/api/business-units'
import {
  getAssignablePoolDefinitions,
  usePoolDefinitionQuery,
} from '@/api/pools'
import { useGetServiceProviderCapabilities } from '@/api/companies'
import { useBusinessUnitPermissions } from '@/components/tickets/common/useBusinessUnitPermissions'

export default {
  name: 'TaskItem',
  components: {
    TicketUnitNumberCrew,
    JobLocationsAndInventoryEdit,
    TaskItemHeader,
  },
  props: {
    assignmentGroupNumber: {
      type: Number,
      default: null,
    },
    unitId: {
      type: Number,
      default: null,
    },
    serviceProviderBusinessUnitId: {
      type: String,
      default: null,
    },
    jobStatus: {
      type: String,
      default: () => '',
    },
    status: {
      type: String,
      default: null,
    },
    activeDuration: {
      type: Object,
      default: null,
    },
    activityId: {
      type: Number,
      default: null,
    },
    inventory: {
      type: Array,
      default: () => [],
    },
    crew: {
      type: Array,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    hubId: {
      type: String,
      required: true,
    },
    accountId: {
      type: String,
      default: null,
    },
    destinationLocationId: {
      type: Number,
      default: null,
    },
    poolDefinitionId: {
      type: String,
      default: null,
    },
    poolId: {
      type: String,
      default: null,
    },
    isCreatingAdHocPool: {
      type: Boolean,
      default: false,
    },
    mapLink: {
      type: String,
      default: null,
    },
  },

  setup(props) {
    const assignablePoolDefinitions = ref([])

    const poolId = toRef(props, 'poolId')
    const { data: selectedPoolDefinition } = usePoolDefinitionQuery(poolId)

    const hubId = toRef(props, 'hubId')
    const { data: entitlements } = useHubEntitlementsQuery(hubId)

    const { isCurrentHubPro } = useBusinessUnitPermissions()

    const { data: serviceProviderCandidates } =
      useGetServiceProviderCapabilities()

    onMounted(async () => {
      assignablePoolDefinitions.value = await getAssignablePoolDefinitions(
        props.hubId
      )
    })

    return {
      assignablePoolDefinitions,
      selectedPoolDefinition,
      entitlements,
      isCurrentHubPro,
      serviceProviderCandidates,
    }
  },
  data() {
    return {
      selectedResourceType: null,
      selectedTask: null,
    }
  },

  computed: {
    ...mapGetters({
      selectableResourceTypes: 'resourceTypes/getResourceTypesForBusinessUnit',
      selectableActivityTypes:
        'activities/getActivitiesForBusinessUnitAndResourceType',
      assignableUnits:
        'unitNumbers/getAssignableUnitsForBusinessUnitAndResourceType',
      unitById: 'unitNumbers/getById',
      activityById: 'activities/getById',
      companyByOrgId: 'companies/getByOrganizationId',
      getDispatchersOrAdminsInOrganization:
        'users/getDispatchersOrAdminsInOrganization',
      isHostOrganizationUser: 'permissions/isHostOrganizationUser',
      canAssignPoolToJob: 'permissions/canAssignPoolToJob',
      canAssignServiceProviderToJob:
        'permissions/canAssignServiceProviderToJob',
      canAssignResourceToJob: 'permissions/canAssignResourceToJob',
      isRequesterForHubId: 'permissions/isRequesterFor',
      userOrgId: 'permissions/getOrganizationId',
      partnerIds: 'permissions/getPartnerIdsForBusinessUnit',
    }),
    jobAssigned() {
      return this.jobStatus !== 'Draft' && this.jobStatus !== 'Requested'
    },
    displayGroupRow() {
      return this.assignmentGroupNumber !== null && this.displayAssignmentField
    },
    displayAssignmentField() {
      return (
        this.canAssignResourceToJob(this.hubId) ||
        this.canAssignServiceProviderToJob(this.hubId) ||
        (this.isRequesterForHubId(this.hubId) && this.jobAssigned)
      )
    },
    assignmentRequired() {
      return !this.isCurrentHubPro && !this.serviceProviderBusinessUnitId
    },
    resourceTypeActivityFieldDisabled() {
      return this.isDisabled || (this.jobAssigned && !this.canReassignJob)
    },
    assignmentFieldDisabled() {
      return (
        !this.selectedResourceType ||
        this.isDisabled ||
        (this.jobAssigned && !this.canReassignJob)
      )
    },
    canSelectPool() {
      return (
        this.entitlements?.pools.enabled && this.canAssignPoolToJob(this.hubId)
      )
    },
    canReassignJob() {
      return (
        this.userOrgId === this.serviceProviderBusinessUnitId ||
        this.userOrgId === this.accountId
      )
    },
    jobAssignedToGroupLabel() {
      return `Assignment applies to all jobs in Group #${
        this.assignmentGroupNumber || ''
      }`
    },
    unit() {
      return this.unitById(this.unitId)
    },
    activity() {
      return this.activityById(this.activityId)
    },
    resourceAssignmentValue() {
      if (this.unitId) {
        return `unit__${this.unitId}`
      } else if (
        this.serviceProviderBusinessUnitId &&
        this.isHostOrganizationUser
      ) {
        return `company__${this.serviceProviderBusinessUnitId}`
      } else if (this.poolId && this.isHostOrganizationUser) {
        if (
          this.selectedPoolDefinition?.parentId &&
          this.selectedPoolDefinition?.parentId !== this.poolId
        ) {
          return `pool__${this.selectedPoolDefinition.parentId}`
        }
        return `pool__${this.poolId}`
      } else if (this.poolDefinitionId) {
        return `pool__${this.poolDefinitionId}`
      } else if (this.isCreatingAdHocPool) {
        return 'ad-hoc-pool__new'
      }
      return null
    },
    resourceAssignmentDropDownLabel() {
      if (!this.resourceAssignmentValue) return 'Assign to'

      return 'Assigned to'
    },
    units() {
      return this.assignableUnits(this.hubId, this.selectedResourceType)
    },
    serviceProviders() {
      const validServiceProviders = [
        ...this.partnerIds(this.hubId),
        this.userOrgId,
      ]

      return (
        this.serviceProviderCandidates?.filter(
          (sp) =>
            validServiceProviders.includes(sp.id) &&
            sp.resourceTypes.includes(this.selectedResourceType)
        ) || []
      )
    },
    assignableUnitsAndServiceProviders() {
      const options = []

      if (this.canSelectPool) {
        options.push({ header: 'Marketplace' })

        options.push(
          ...this.assignablePoolDefinitions.map((d) => ({
            text: d.name,
            value: `pool__${d.id}`,
            icon: 'bolt',
          }))
        )

        options.push({
          text: '+  New Pool',
          value: 'ad-hoc-pool__new',
          icon: 'add',
        })

        if (this.poolId && !this.selectedPoolDefinition?.parentId) {
          options.push({
            text: 'Pool',
            value: `pool__${this.poolId}`,
            icon: 'bolt',
            disabled: true,
          })
        }
      }

      const orderedServiceProviders = orderBy(this.serviceProviders, [
        (sp) => sp.name.toLowerCase(),
      ])

      orderedServiceProviders.forEach((serviceProvider) => {
        const legacyCompany = this.companyByOrgId(serviceProvider.id)
        let addedCompanyHeader = false

        if (
          this.canAssignServiceProviderToJob(this.hubId) &&
          this.getDispatchersOrAdminsInOrganization(serviceProvider.id)
        ) {
          options.push({ header: serviceProvider.name })
          addedCompanyHeader = true

          options.push({
            text: serviceProvider.name,
            value: `company__${serviceProvider.id}`,
            description: this.serviceProviderDescription(legacyCompany),
            icon: 'villa',
          })
        }

        if (this.canAssignResourceToJob(this.hubId)) {
          const unitsForCompany = this.units.filter(
            (unit) => unit.organizationId === serviceProvider.id
          )
          const orderedUnits = orderBy(unitsForCompany, [
            'isOffline',
            (u) => u.name.toLowerCase(),
          ])

          if (!addedCompanyHeader && orderedUnits.length > 0) {
            options.push({ header: serviceProvider.name })
          }

          options.push(
            ...orderedUnits.map((unit) => ({
              text: unit.name,
              value: `unit__${unit.id}`,
              description: this.resourceDescription(
                legacyCompany.shortCode,
                unit.description
              ),
              ...(unit.status === 0 && { chip: 'Offline' }),
            }))
          )
        } else if (this.displayAssignmentField && this.unitId) {
          const unit = this.units.find((unit) => unit.id === this.unitId)
          options.push({
            text: unit.name,
            value: `unit__${unit.id}`,
            description: this.resourceDescription(
              legacyCompany.shortCode,
              unit.description
            ),
            ...(unit.status === 0 && { chip: 'Offline' }),
          })
        }
      })

      return options
    },
    activities() {
      const visibleActivities = this.selectableActivityTypes(
        this.hubId,
        this.selectedResourceType
      )

      if (
        !visibleActivities.some((rta) => rta.id === this.activityId) &&
        this.activityId
      )
        visibleActivities.push(this.activity)

      return orderBy(visibleActivities, (a) => a.taskName.toLowerCase())
    },
    sortedResourceTypes() {
      return orderBy(this.selectableResourceTypes(this.hubId), (rt) =>
        rt.name.toLowerCase()
      )
    },
    autoAssignServiceProvider() {
      return (
        this.jobStatus === 'Draft' &&
        this.serviceProviders.length === 1 &&
        this.getDispatchersOrAdminsInOrganization(this.serviceProviders[0].id)
      )
    },
  },

  // Properly updates the UI on a discard
  watch: {
    activityId(newValue) {
      const newActivity = this.activityById(newValue)
      if (newActivity) this.selectedResourceType = newActivity?.resourceTypeId
      this.selectedTask = newActivity?.taskId
    },
  },

  created() {
    if (this.activity) {
      this.selectedResourceType = this.activity.resourceTypeId
      this.selectedTask = this.activity.taskId

      if (this.autoAssignServiceProvider) {
        this.autoFill('service-provider', this.serviceProviders[0].id)
      }
    } else {
      const selectableResourceTypes = this.selectableResourceTypes(this.hubId)
      if (selectableResourceTypes.length === 1) {
        const selectableActivityTypes = this.selectableActivityTypes(
          this.hubId,
          selectableResourceTypes[0].id
        )
        if (selectableActivityTypes.length === 1) {
          this.autoFill('activity', selectableActivityTypes[0].id)
        } else {
          this.autoFill('resource-type', selectableResourceTypes[0].id)
        }
      }
    }
  },

  methods: {
    updateResourceType(resourceTypeId) {
      this.createAdHocPool(false)

      this.selectedResourceType = resourceTypeId

      const selectableActivityTypes = this.selectableActivityTypes(
        this.hubId,
        resourceTypeId
      )

      const activity = selectableActivityTypes.find(
        (activity) => activity.taskId === this.selectedTask
      )
      if (!activity) {
        this.selectedTask = null
      }

      this.updateActivity(activity?.id)
      this.updateUnitNumber(null)
      this.updateInventory(this.inventory || [])
      this.updatePool(null)

      if (selectableActivityTypes.length === 1) {
        this.autoFill('activity', selectableActivityTypes[0].id)
      }

      if (this.autoAssignServiceProvider) {
        this.autoFill('service-provider', this.serviceProviders[0].id)
      } else {
        this.updateServiceProviderBusinessUnitId(null)
      }
    },
    updateResourceAssignment(val) {
      if (val === this.resourceAssignmentValue && val != null) return
      this.updatePool(null)
      this.createAdHocPool(false)

      if (val === null) {
        this.updateUnitNumber(null)
        if (this.isHostOrganizationUser) {
          this.updateServiceProviderBusinessUnitId(null)
        }
        return
      }
      const [type, id] = val.split('__')
      if (type === 'unit') {
        const unitId = parseInt(id)
        this.updateUnitNumber(unitId)
        this.updateServiceProviderBusinessUnitId(
          this.units.find((unit) => unit.id === unitId).ownerId
        )
      } else if (type === 'pool') {
        this.updatePool(id)
      } else if (type === 'ad-hoc-pool') {
        this.createAdHocPool(true)
      } else {
        this.updateUnitNumber(null)
        this.updateServiceProviderBusinessUnitId(id)
      }
    },
    updateActivity(activityId) {
      this.$emit('update-activity', activityId)
    },
    updateUnitNumber(unitId) {
      this.updateCrew([])
      this.$emit('update-unit', unitId)
    },
    updateServiceProviderBusinessUnitId(serviceProviderId) {
      this.updateCrew([])
      this.$emit('update-service-provider-business-unit-id', serviceProviderId)
    },
    updatePool(id) {
      if (id !== null) {
        this.updateCrew([])
        this.updateUnitNumber(null)
        this.updateServiceProviderBusinessUnitId(null)
      }

      this.$emit('update-pool', id)
    },
    createAdHocPool(value) {
      this.$emit('create-ad-hoc-pool', value)
    },
    updateCrew(modified) {
      this.$emit('update-crew', modified)
    },
    updateInventory(inventories) {
      this.$emit('update-inventory', inventories)
    },
    updateDestination(id) {
      this.$emit('update-destination-location-id', id)
    },
    serviceProviderDescription(company) {
      const message = `Let their dispatchers assign their own resource.\n`
      if (company.email) {
        return message + `We'll notify ${company.email}`
      }
      return message + `No email setup to notify this company yet.`
    },
    resourceDescription(shortcode, description) {
      const message = shortcode
      if (description) {
        return message + ` · ${description}`
      }
      return message
    },
    validate(jobStatus) {
      return this.$refs['inventory-edit-component'].validate(jobStatus)
    },
    removeJobFromGroup() {
      this.$emit('remove-job-from-group')
    },
    autoFill(field, value) {
      this.$emit('auto-fill', field, value)
    },
  },
}
</script>

<style scoped>
.unit-offline-text {
  font-size: 12px;
  color: gray;
}

>>> span.v-list__tile__mask {
  color: black !important;
  background: none !important;
}

.menu {
  border-radius: 24px;
  box-shadow: var(--shadow-high);
}

.border {
  border-color: var(--color-grey--light) !important;
  border-width: 1px !important;
  border-style: solid !important;
}

.negative-margin-correction {
  margin-bottom: calc(-1 * var(--spacing)) !important;
}
</style>
