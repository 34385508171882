"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MarkdownPropertyDescription = void 0;
const json_schema_keys_1 = require("../../json_schema_keys");
const field_property_description_1 = require("./field_property_description");
class MarkdownPropertyDescription extends field_property_description_1.DefaultFieldPropertyDescription {
    set markdownString(description) {
        this[json_schema_keys_1.JsonSchemaKeys.MARKDOWN_KEY] = description;
    }
    constructor(field) {
        super(field);
        this.markdownString = field.markdown;
    }
}
exports.MarkdownPropertyDescription = MarkdownPropertyDescription;
