import { render, staticRenderFns } from "./SubmissionArrayField.vue?vue&type=template&id=055e8158&scoped=true&"
import script from "./SubmissionArrayField.vue?vue&type=script&lang=js&"
export * from "./SubmissionArrayField.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "055e8158",
  null
  
)

export default component.exports