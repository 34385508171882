var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_setup.isSearchResultsEmpty)?_c('div',{staticClass:"mt-2"},[_c(_setup.VsButton,{attrs:{"full-width":"","label":`Create client: ${_vm.search}...`,"type":"secondary"},on:{"click":_setup.handleOpenCreateClientForm}})],1):_vm._e(),(_setup.isLoading)?_c('v-progress-circular',{staticClass:"ma-auto",attrs:{"size":64,"width":6,"indeterminate":"","color":"primary"}}):(!_setup.isSearchResultsEmpty)?_c('div',[(_setup.showConfirmationDialog && _setup.selectedHub)?_c(_setup.ConnectionRequestConfirmationDialog,{attrs:{"hub-name":_setup.selectedHub.name,"org-name":_setup.selectedHub.ownerName,"auto-connect":!_setup.selectedHub.canManageConnections},on:{"close":function($event){_setup.showConfirmationDialog = false},"confirm":_setup.handleSendConnection}}):_vm._e(),_c('v-list',{staticClass:"client-hubs-list"},[_vm._l((_setup.clients),function(client,i){return [(client)?_c('v-list-item',{key:i},[_c('v-list-item-content',[_c('div',{staticClass:"hub-name"},[_c(_setup.VsText,{attrs:{"bold":""}},[_vm._v(_vm._s(client.ownerName))]),(client.canManageConnections)?_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/logo_black.png")}}):_vm._e()],1),_c(_setup.VsText,[_vm._v(_vm._s(client.name))])],1),_c('v-list-item-action',[_c(_setup.VsButton,{class:{
                'connect-button': true,
                'connect-button-disabled': client.connectionStatus != null,
              },attrs:{"label":_setup.getStatusChipText(
                  client.connectionStatus,
                  client.canManageConnections
                ),"loading":_vm.creatingConnection && _setup.selectedHub?.id === client.id,"destructive":client.connectionStatus === _setup.ConnectionStatus.Rejected,"type":"secondary","small":""},on:{"click":function($event){return _setup.handleSelectHub(client.id)}}})],1)],1):_vm._e()]})],2),_c(_setup.VsPagination,{attrs:{"value":_setup.currentPage,"length":_setup.totalPages},on:{"input":_setup.updatePage}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }