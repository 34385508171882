import moment from 'moment'

export default class JobAttachment {
  constructor(json, tempFile: File | null = null) {
    this.id = json.id
    this.source = json.source
    this.filename = json.filename
    this.createdBy = json.created_by
    this.created = moment.utc(json.created)
    this.tempFile = tempFile
  }

  id: string
  source: string
  filename: string
  createdBy: string
  created: moment.Moment
  // Used during job creation to temporarily store the file attachment before upload
  tempFile: File | null
}
