<template>
  <div class="nested-object">
    <submission-field :submission-data="value" :flattened-fields="children" />
  </div>
</template>

<script>
export default {
  name: 'SubmissionObjectField',
  props: {
    children: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
.nested-object {
  border-left: 1px solid rgb(212, 212, 212);
  padding-left: 7px;
}
</style>
