import axios from 'axios'
import { get, post } from './base'

let getLocationInventorySource = axios.CancelToken.source()

const locationInventoryEndPoint = '/api/v2/locations/{id}/inventory'
const createInventoryAdjustmentEndPoint =
  '/api/v2/inventory/locations/{id}/adjustments'

export function getLocationInventory(locationId) {
  getLocationInventorySource.cancel('Cancel old location inventory requests')
  getLocationInventorySource = axios.CancelToken.source()

  return get(locationInventoryEndPoint.replace(/{id}/, `${locationId}`), {
    cancelToken: getLocationInventorySource.token,
  })
}

export function createInventoryAdjustment(inventoryAdjustment) {
  const url = createInventoryAdjustmentEndPoint.replace(
    /{id}/,
    `${inventoryAdjustment.locationId}`
  )
  const payload = {
    location_id: inventoryAdjustment.locationId,
    asset_id: inventoryAdjustment.assetId,
    reason: inventoryAdjustment.reason,
    quantity_changes: inventoryAdjustment.quantityChanges.map((ic) => {
      return {
        asset_condition_id: ic.assetConditionId,
        quantity_delta: ic.quantityChange,
      }
    }),
  }

  return post(url, { data: payload })
}
