import { PNG, JPEG, JPG, CSV, DOCX, XLSX, PDF } from './constants'

export function getContentTypeForFileExtension(extension) {
  switch (extension) {
    case PNG.extension:
      return PNG.type

    case JPEG.extension:
    case JPG.extension:
      return JPG.type

    case CSV.extension:
      return CSV.type

    case DOCX.extension:
      return DOCX.type

    case XLSX.extension:
      return XLSX.type

    case PDF.extension:
      return PDF.type
    default:
      return null
  }
}
