import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import moment from 'moment'

type ApprovalStatus = 'Approved' | 'PendingApproval' | 'NoApproval'

export const useJobFilterStore = defineStore(
  'job-filters',
  () => {
    const isMigratedFromVuex = ref<boolean>(false)
    const isShowingFilters = ref<boolean>(false)
    const search = ref<string>('')
    const selectedPriorities = ref<string[]>([])
    const selectedDivisions = ref<string[]>([])
    const selectedServiceProviders = ref<(string | null)[]>([])
    const selectedResourceTypes = ref<number[]>([])
    const selectedActivities = ref<number[]>([])
    const selectedJobStatuses = ref<string[]>([])
    const selectedBatchStatuses = ref<string[]>([])
    const selectedApprovalStatuses = ref<ApprovalStatus[]>([])
    const selectedLocations = ref<number[]>([])

    const start = ref<moment.Moment | null>(
      moment().startOf('day').subtract(7, 'days')
    )
    const end = ref<moment.Moment | null>(moment().endOf('day'))

    const hasDateRange = computed(() => {
      return start.value !== null && end.value !== null
    })

    const dateRangeLabel = computed(() => {
      return hasDateRange.value
        ? `${end.value!.diff(start.value, 'days') + 1} dates selected`
        : 'Showing all dates'
    })

    const showFilterPanel = () => {
      isShowingFilters.value = true
    }

    const hideFilterPanel = () => {
      isShowingFilters.value = false
    }

    const clearDateRange = () => {
      start.value = null
      end.value = null
    }

    const updateDateRange = (range: {
      start: moment.Moment | null
      end: moment.Moment | null
    }) => {
      start.value = range.start
      end.value = range.end
    }

    const updateSearch = (input: string) => {
      search.value = input
    }

    const updatePriority = (priorities: string[]) => {
      selectedPriorities.value = priorities
    }

    const updateDivision = (divisions: string[]) => {
      selectedDivisions.value = divisions
    }

    const updateServiceProvider = (serviceProviders: string[]) => {
      selectedServiceProviders.value = serviceProviders
    }

    const updateResourceType = (resourceTypes: number[]) => {
      selectedResourceTypes.value = resourceTypes
    }

    const updateActivity = (activities: number[]) => {
      selectedActivities.value = activities
    }

    const updateJobStatus = (jobStatuses: string[]) => {
      selectedJobStatuses.value = jobStatuses
    }

    const updateBatchStatus = (batchStatuses: string[]) => {
      selectedBatchStatuses.value = batchStatuses
    }

    const updateApprovalStatus = (approvalStatuses: ApprovalStatus[]) => {
      selectedApprovalStatuses.value = approvalStatuses
    }

    const updateLocation = (locations: number[]) => {
      selectedLocations.value = locations
    }
    
    const resetAllFilters = () => {
      search.value = ""
      start.value = moment().subtract(1, 'week').startOf('day')
      end.value = moment().endOf('day')
      selectedPriorities.value = []
      selectedDivisions.value = []
      selectedServiceProviders.value = []
      selectedResourceTypes.value = []
      selectedActivities.value = []
      selectedJobStatuses.value = []
      selectedBatchStatuses.value = []
      selectedApprovalStatuses.value = []
      selectedLocations.value = []
    }

    return {
      isMigratedFromVuex,
      isShowingFilters,
      search,
      start,
      end,
      selectedPriorities,
      selectedDivisions,
      selectedServiceProviders,
      selectedResourceTypes,
      selectedActivities,
      selectedJobStatuses,
      selectedBatchStatuses,
      selectedApprovalStatuses,
      selectedLocations,
      hasDateRange,
      dateRangeLabel,
      showFilterPanel,
      hideFilterPanel,
      clearDateRange,
      updateDateRange,
      updateSearch,
      updatePriority,
      updateDivision,
      updateServiceProvider,
      updateResourceType,
      updateActivity,
      updateJobStatus,
      updateBatchStatus,
      updateApprovalStatus,
      updateLocation,
      resetAllFilters
    }
  },
  {
    persist: {
      afterRestore: (ctx) => {
        ctx.store.start =
          ctx.store.start != null ? moment(ctx.store.start) : null
        ctx.store.end = ctx.store.end != null ? moment(ctx.store.end) : null
      },
    },
  }
)
