import {
  activateJob,
  addJobToAssignmentGroup,
  approveJob,
  archive,
  attachFormRequirementSubmission,
  attachFormSubmission,
  bulkCancel,
  bulkOverwriteSchedule,
  bulkReschedule,
  bulkUpdateCostCenter,
  bulkUpdateDestination,
  bulkUpdateDetails,
  bulkUpdatePickupLocation,
  cancelJob,
  unCancelJob,
  unCompleteJob,
  cancelJobApprovalRequest,
  completeJob,
  createJobComment,
  createJobs,
  deleteAssignmentGroup,
  fetchJobAttachment,
  getJob,
  getJobByUuid,
  getJobs,
  groupJobs,
  removeAttachment,
  removeJobFromAssignmentGroup,
  rescheduleGroup,
  rescheduleJob,
  submitJobForApproval,
  unapproveJob,
  unarchive,
  updateCompletedJobActualTimes,
  updateJob,
  updatePriority,
  updateTagsOnApprovedJob,
  uploadAttachment,
} from '@/api/jobs'
import { getTags } from '@/api/tags.js'
import Job, {
  ACTIVE,
  APPROVED,
  ASSIGNED_TO_POOL,
  ASSIGNED_TO_SERVICE_PROVIDER,
  CANCELLED,
  UNCANCELLED,
  COMPLETED,
  createJobFactory,
  NO_APPROVAL,
  PAUSED,
  PENDING_APPROVAL,
  REQUESTED,
  SCHEDULED,
} from '@/models/job'
import Tag from '@/models/tag'
import { priorities, priorityMap } from '@/utils/constants'

import JobAttachment from '@/models/job_attachment'
import Axios from 'axios'
import { isEqual, keyBy, mapValues } from 'lodash'
import moment, { Moment } from 'moment'
import { v4 as uuidv4 } from 'uuid'

import { Commit, Dispatch } from 'vuex'
import JobAssignmentFormRequirement from '@/models/job-assignment-form-requirement'

const ADD_TO_PROJECT = 'ADD_TO_PROJECT'
const APPROVE_JOB = 'APPROVE_JOB'
const UNAPPROVE_JOB = 'UNAPPROVE_JOB'
const CLEAR_SELECTED_JOBS = 'CLEAR_SELECTED_JOBS'
const CLEAR_TIMEOUT = 'CLEAR_TIMEOUT'
const DESELECT_JOB = 'DESELECT_JOB'
const ERROR_JOBS = 'ERROR_JOBS'
export const ERROR_SAVING_JOB = 'ERROR_SAVING_JOB'
const LOADING_JOBS = 'LOADING_JOBS'
const LOADING_TAGS = 'LOADING_TAGS'
const RECEIVE_JOBS = 'RECEIVE_JOBS'
const RECEIVE_JOBS_JSON = 'RECEIVE_JOBS_JSON'
export const RECEIVE_JOB_JSON = 'RECEIVE_JOB_JSON'
const RECEIVE_TAGS = 'RECEIVE_TAGS'
const REMOVE_FROM_PROJECT = 'REMOVE_FROM_PROJECT'
const REMOVE_JOB = 'REMOVE_JOB'
export const SAVED_JOB = 'SAVED_JOB'
export const SAVING_JOB = 'SAVING_JOB'
const SELECT_JOB = 'SELECT_JOB'
const SELECT_JOBS = 'SELECT_JOBS'
const SET_JOB_ASSIGNMENT = 'SET_JOB_ASSIGNMENT'
const SET_JOB_FACTORY = 'SET_JOB_FACTORY'
const SET_JOB_PRIORITY = 'SET_JOB_PRIORITY'
const SET_JOB_TIME = 'SET_JOB_TIME'
const SET_TIMEOUT = 'SET_TIMEOUT'
const RESET_WINDOW = 'RESET_WINDOW'
const UPDATE_WINDOW = 'UPDATE_WINDOW'
const JOB_SUPPLEMENTARY_DATA_LOADED = 'JOB_SUPPLEMENTARY_DATA_LOADED'
const ARCHIVE_JOB = 'ARCHIVE_JOB'
const UNARCHIVE_JOB = 'UNARCHIVE_JOB'
const RECEIVE_ATTACHMENT = 'RECEIVE_ATTACHMENT'

const pollingInterval = moment.duration(30, 'seconds')

interface JobsState {
  loading: boolean
  error: boolean | null
  lastFetch: {
    timestamp: Moment | null
    query: any
  }
  window: {
    start: any
    end: any
  }
  timeout: number | undefined
  all: any
  selectedJobIds: number[]
  tags: Tag[]
  saving: boolean
  supplementaryDataLoaded: boolean
  jobFactory: any
}

const state = () => ({
  loading: false,
  error: null,
  lastFetch: {
    timestamp: null,
    query: {},
  },
  window: {
    start: null,
    end: null,
  },
  timeout: null,
  all: {},
  selectedJobIds: [],
  tags: [],
  saving: false,
  supplementaryDataLoaded: false,
  jobFactory: null,
})

export const getters = {
  getById:
    (state: JobsState) =>
    (id: number): Job =>
      state.all && state.all[id],
  getJobStatuses: () => [
    {
      id: REQUESTED,
      name: 'Requested',
    },
    {
      id: ASSIGNED_TO_POOL,
      name: 'Assigned To Pool',
    },
    {
      id: ASSIGNED_TO_SERVICE_PROVIDER,
      name: 'Assigned To Service Provider',
    },
    {
      id: SCHEDULED,
      name: 'Scheduled',
    },
    {
      id: ACTIVE,
      name: 'Active',
    },
    {
      id: PAUSED,
      name: 'Paused',
    },
    {
      id: COMPLETED,
      name: 'Completed',
    },
    {
      id: CANCELLED,
      name: 'Cancelled',
    },
  ],
  getApprovalStatuses: () => [
    {
      id: APPROVED,
      name: 'Approved',
    },
    {
      id: PENDING_APPROVAL,
      name: 'Pending Approval',
    },
    {
      id: NO_APPROVAL,
      name: 'No Approval',
    },
  ],
  getSelectedJobs: (state: JobsState) =>
    Object.values<Job>(state.all).filter((job: Job) =>
      state.selectedJobIds.includes(job.id)
    ),
  getSelectedJobPriorityIds: (_state: JobsState, getters) => {
    return Object.values<Job>(getters.getSelectedJobs).map(
      (job: Job) => job.priorityId
    )
  },
  getSaving: (state: JobsState) => state.saving,
  getNonArchivedJobs: (state: JobsState) =>
    Object.values<Job>(state.all).filter((job: Job) => !job.isArchived),
}

export const actions = {
  async initialize({ commit, rootGetters }) {
    commit(SET_JOB_FACTORY, createJobFactory(rootGetters))
  },
  async refreshJobs({ commit, dispatch }, query) {
    commit(RESET_WINDOW)

    dispatch('fetchJobs', query)
  },
  async fetchUpdates({ state, commit, rootGetters }, modifiedSince) {
    commit(SET_JOB_FACTORY, createJobFactory(rootGetters))

    try {
      const { data } = await getJobs({
        modifiedSince,
      })
      commit(RECEIVE_JOBS_JSON, { data })
    } catch (err) {
      if (!Axios.isCancel(err)) {
        commit(ERROR_JOBS, err)
      }
    }
  },
  async fetchJobs(
    {
      commit,
      state,
      rootGetters,
    }: {
      commit: Commit
      state: JobsState
      rootGetters
    },
    query
  ) {
    commit(LOADING_JOBS)

    // check if we have already fetched within this range
    // if we have we only need to find the modified jobs
    query = query || state.window
    const { start, end, ...queryProps } = query
    const {
      start: _start,
      end: _end,
      modifiedSince: _modifiedSince,
      ...lastQueryProps
    } = state.lastFetch.query
    const { start: windowStart, end: windowEnd } = state.window
    let modifiedSince

    if (
      state.lastFetch.timestamp &&
      (windowStart === null || start.isSameOrAfter(windowStart)) &&
      (windowEnd === null || end.isSameOrBefore(windowEnd)) &&
      isEqual(queryProps, lastQueryProps)
    ) {
      modifiedSince = moment(state.lastFetch.timestamp)
        .subtract(30, 'seconds')
        .toISOString()
    }

    const request = {
      modifiedSince,
      ...query,
    }

    commit(UPDATE_WINDOW, request)
    commit(SET_JOB_FACTORY, createJobFactory(rootGetters))

    try {
      const { data } = await getJobs(request)
      commit(RECEIVE_JOBS_JSON, { data })
    } catch (err) {
      if (!Axios.isCancel(err)) {
        commit(ERROR_JOBS, err)
      }
    }
  },
  async fetchJob({ commit, rootGetters }, id) {
    try {
      const createJob = createJobFactory(rootGetters)
      commit(SET_JOB_FACTORY, createJob)

      const { data } = await getJob(id)
      commit(RECEIVE_JOB_JSON, { data })

      return createJob(data)
    } catch (err) {
      commit('tickets/ERROR_TICKETS', err, { root: true })
    }
  },
  async startPolling(
    {
      commit,
      state,
      dispatch,
    }: {
      commit: Commit
      state: JobsState
      dispatch: Dispatch
    },
    query
  ) {
    commit(CLEAR_TIMEOUT)

    const updatedQuery = {
      ...query,
      ...{
        start: query.start || moment().startOf('day').subtract(2, 'day'),
        end: query.end || moment().endOf('day').add(2, 'day'),
      },
    }

    await dispatch('fetchJobs', updatedQuery)

    const timeout = setTimeout(async () => {
      await dispatch('startPolling', query)
    }, pollingInterval.asMilliseconds())

    commit(SET_TIMEOUT, timeout)
  },
  stopPolling({ commit }) {
    commit(CLEAR_TIMEOUT)
  },
  async fetchTags({ commit }) {
    commit(LOADING_TAGS)

    const { data } = await getTags()
    commit(RECEIVE_TAGS, { data })
  },
  async updatePriority(
    {
      commit,
      state,
    }: {
      commit: Commit
      state: JobsState
    },
    { jobIds, priorityId }
  ) {
    const priority = priorities.find((priority) => priority.id === priorityId)

    if (!priority) {
      return
    }

    await updatePriority(jobIds, priority.id)

    const data = jobIds.map((jobId) =>
      Object.assign(state.all[jobId], { priorityId, priority })
    )

    commit(RECEIVE_JOBS, { data })
  },
  async bulkUpdateDestination(
    {
      commit,
      state,
      rootGetters,
    }: {
      commit: Commit
      state: JobsState
      rootGetters
    },
    { destinationId }
  ) {
    await bulkUpdateDestination(state.selectedJobIds, destinationId)
    const location = rootGetters['locations/getById'](destinationId)

    const data = state.selectedJobIds.map((jobId) =>
      Object.assign(state.all[jobId], {
        destinationLocationId: destinationId,
        destinationLocation: location,
      })
    )

    commit(RECEIVE_JOBS, { data })
  },
  async bulkUpdatePickupLocation(
    {
      commit,
      state,
    }: {
      commit: Commit
      state: JobsState
    },
    { pickupLocationId }
  ) {
    await bulkUpdatePickupLocation(state.selectedJobIds, pickupLocationId)

    const data = state.selectedJobIds.map((jobId) =>
      Object.assign(state.all[jobId], {
        inventory: state.all[jobId].inventory.map((inv) => ({
          ...inv,
          pickupLocationId,
        })),
      })
    )

    commit(RECEIVE_JOBS, { data })
  },
  async bulkUpdateCostCenter(
    {
      commit,
      state,
    }: {
      commit: Commit
      state: JobsState
    },
    { costCenter }
  ) {
    await bulkUpdateCostCenter(state.selectedJobIds, costCenter)

    const data = state.selectedJobIds.map((jobId) =>
      Object.assign(state.all[jobId], { costCenter })
    )

    commit(RECEIVE_JOBS, { data })
  },
  async bulkUpdateDetails(
    {
      commit,
      state,
    }: {
      commit: Commit
      state: JobsState
    },
    { details }
  ) {
    await bulkUpdateDetails(state.selectedJobIds, details)

    const data = state.selectedJobIds.map((jobId) =>
      Object.assign(state.all[jobId], { details })
    )

    commit(RECEIVE_JOBS, { data })
  },
  async bulkCancel({ commit, state }: { commit: Commit; state: JobsState }) {
    await bulkCancel(state.selectedJobIds)

    const data = state.selectedJobIds.map((jobId) =>
      Object.assign(state.all[jobId], { status: 'Cancelled' })
    )

    commit(RECEIVE_JOBS, { data })
  },

  async bulkOverwriteSchedule(
    {
      commit,
      state,
    }: {
      commit: Commit
      state: JobsState
    },
    { startTime, endTime }
  ) {
    await bulkOverwriteSchedule(state.selectedJobIds, startTime, endTime)

    const data = state.selectedJobIds.map((jobId) =>
      Object.assign(state.all[jobId], { startTime, endTime })
    )

    commit(RECEIVE_JOBS, { data })
  },

  async bulkReschedule(
    {
      commit,
      state,
    }: {
      commit: Commit
      state: JobsState
    },
    { duration }
  ) {
    await bulkReschedule(state.selectedJobIds, duration)

    const data = state.selectedJobIds.map((jobId) =>
      Object.assign(state.all[jobId], {
        startTime: state.all[jobId].startTime.add(duration, 'seconds'),
        endTime: state.all[jobId].endTime.add(duration, 'seconds'),
      })
    )

    commit(RECEIVE_JOBS, { data })
  },

  async updateTagsOnApprovedJob({ commit }, { jobId, tags, divisionId }) {
    commit(SAVING_JOB)

    await updateTagsOnApprovedJob(jobId, tags, divisionId)

    const { data } = await getJob(jobId)
    commit(SAVED_JOB)
  },

  async createJobs(
    { commit },
    {
      jobUuid,
      activityId,
      attachmentsStorageId,
      costCenter,
      createProject = true,
      crew,
      extensionSchemaId,
      customData,
      customerSid,
      destinationLocationId,
      details,
      ownerId,
      duration,
      followers,
      inventory,
      priorityId,
      projectId,
      serviceProviderBusinessUnitId,
      startTimes,
      tags,
      unitId,
      poolExecutionPlan,
      assignmentFormRequirements = [],
    }
  ) {
    commit('tickets/CLEAR_TICKETS_ERROR', {}, { root: true })
    commit(SAVING_JOB)

    try {
      const response = await createJobs(
        {
          Uuid: jobUuid,
          activity_id: activityId,
          attachments_storage_id:
            startTimes.length > 1 ? null : attachmentsStorageId,
          cost_center: costCenter,
          crew: crew.map((c) => ({
            user_sid: c,
          })),
          extension_schema_id: extensionSchemaId,
          custom_data: customData,
          customer_sid: customerSid,
          destination_location_id: destinationLocationId,
          details,
          owner_id: ownerId,
          followers,
          inventory: inventory.map((inv) => ({
            quantity: inv.quantity,
            inventory_sub_type_id: inv.inventorySubTypeId,
            pickup_location_id: inv.pickupLocationId,
            inventory_id: inv.inventoryId,
            position: inv.position,
          })),
          // for backwards compatibility
          pickup_location_id: inventory?.length
            ? inventory[0].pickupLocationId
            : null,
          priority: priorityId,
          project_id: projectId,
          service_provider_business_unit_id: serviceProviderBusinessUnitId,
          tags,
          time_slots: {
            start_times: startTimes,
            duration,
          },
          unit_id: unitId,
          pool_execution_plan: poolExecutionPlan,
          assignment_form_requirements: assignmentFormRequirements.map(
            (requirement: JobAssignmentFormRequirement) => ({
              id: requirement.id,
              form_id: requirement.formId,
            })
          ),
        },
        createProject
      )

      commit(SAVED_JOB)
      return response.data
    } catch (err) {
      commit('tickets/ERROR_TICKETS', err, { root: true })
      commit(ERROR_SAVING_JOB)
    }
  },

  async updateJob(
    { commit, dispatch },
    {
      id,
      priorityId,
      ownerId,
      customerSid,
      followers,
      startTime,
      endTime,
      activityId,
      inventory,
      crew,
      unitId,
      serviceProviderBusinessUnitId,
      costCenter,
      details,
      destinationLocationId,
      extensionSchemaId,
      customData,
      tags,
      poolId,
      poolExecutionPlan,
      assignmentFormRequirements = [],
    }
  ) {
    commit('tickets/CLEAR_TICKETS_ERROR', {}, { root: true })
    commit(SAVING_JOB)
    try {
      const dateModified = moment()
      await updateJob({
        id,
        priority: priorityId,
        followers,
        customer_sid: customerSid,
        owner_id: ownerId,
        destination_location_id: destinationLocationId,
        details,
        cost_center: costCenter,
        extension_schema_id: extensionSchemaId,
        custom_data: customData,
        tags,
        activity_id: activityId,
        unit_id: unitId,
        service_provider_business_unit_id: serviceProviderBusinessUnitId,
        crew: crew.filter(Boolean).map((c) => ({
          user_sid: c,
        })),
        inventory: inventory.map((inv) => ({
          quantity: inv.quantity,
          inventory_sub_type_id: inv.inventorySubTypeId,
          pickup_location_id: inv.pickupLocationId,
          inventory_id: inv.inventoryId,
          position: inv.position,
        })),
        // for backwards compatibility
        pickup_location_id: inventory?.length
          ? inventory[0].pickupLocationId
          : null,
        start_time: startTime,
        duration: moment.duration(endTime.diff(startTime)).asSeconds(),
        pool_id: poolId,
        pool_execution_plan: poolExecutionPlan,
        assignment_form_requirements: assignmentFormRequirements.map(
          (requirement: JobAssignmentFormRequirement) => ({
            id: requirement.id,
            form_id: requirement.formId,
          })
        ),
      })
      await dispatch('fetchUpdates', dateModified)

      commit(SAVED_JOB)
    } catch (err) {
      commit('tickets/ERROR_TICKETS', err, { root: true })
      commit(ERROR_SAVING_JOB)
    }
  },

  async archiveJob({ commit }, { uuid }) {
    commit(SAVING_JOB)
    try {
      await archive(uuid)

      commit(ARCHIVE_JOB, { uuid })
      commit(SAVED_JOB)
    } catch (err) {
      commit('tickets/ERROR_TICKETS', err, { root: true })
      commit(ERROR_SAVING_JOB)
    }
  },

  async unarchiveJob({ commit }, { uuid }) {
    commit(SAVING_JOB)
    try {
      await unarchive(uuid)

      commit(UNARCHIVE_JOB, { uuid })
      commit(SAVED_JOB)
    } catch (err) {
      commit('tickets/ERROR_TICKETS', err, { root: true })
      commit(ERROR_SAVING_JOB)
    }
  },

  async unCompleteJob({ commit }, { jobNumber }) {
    commit(SAVING_JOB)
    try {
      await unCompleteJob(jobNumber)
      commit(SAVED_JOB)
    } catch (err) {
      commit('tickets/ERROR_TICKETS', err, { root: true })
      commit(ERROR_SAVING_JOB)
    }
  },

  async submitJobForApproval({ commit }, { uuid }) {
    commit(SAVING_JOB)
    try {
      await submitJobForApproval(uuid)

      const { data } = await getJob(uuid)

      commit(SAVED_JOB)
      commit(RECEIVE_JOB_JSON, { data })
    } catch (err) {
      commit('tickets/ERROR_TICKETS', err, { root: true })
      commit(ERROR_SAVING_JOB)
    }
  },
  async cancelJobApprovalRequest({ commit }, { uuid }) {
    commit(SAVING_JOB)
    try {
      await cancelJobApprovalRequest(uuid)

      const { data } = await getJob(uuid)

      commit(SAVED_JOB)
      commit(RECEIVE_JOB_JSON, { data })
    } catch (err) {
      commit('tickets/ERROR_TICKETS', err, { root: true })
      commit(ERROR_SAVING_JOB)
    }
  },
  async updateJobStatus({ commit }, { id, jobStatus }) {
    commit(SAVING_JOB)

    try {
      switch (jobStatus) {
        case ACTIVE:
          await activateJob(id)
          break
        case COMPLETED:
          await completeJob(id)
          break
        case CANCELLED:
          await cancelJob(id)
          break
        case UNCANCELLED:
          await unCancelJob(id)
          break
        case PAUSED:
          break
      }

      const { data } = await getJob(id)

      commit(SAVED_JOB)
      commit(RECEIVE_JOB_JSON, { data })
    } catch (err) {
      commit('tickets/ERROR_TICKETS', err, { root: true })
      commit(ERROR_SAVING_JOB)
    }
  },
  async updateCompletedJobActualTimes(
    { commit },
    { id, startTime, endTime, activeTime, completedTime }
  ) {
    commit('tickets/CLEAR_TICKETS_ERROR', {}, { root: true })
    commit(SAVING_JOB)
    try {
      await updateCompletedJobActualTimes(id, {
        start_time: startTime.toISOString(),
        end_time: endTime.toISOString(),
        active_time: activeTime.toISOString(),
        completed_time: completedTime.toISOString(),
      })
      const { data } = await getJob(id)

      commit(SAVED_JOB)
      commit(RECEIVE_JOB_JSON, { data })
    } catch (err) {
      commit('tickets/ERROR_TICKETS', err, { root: true })
      commit(ERROR_SAVING_JOB)
    }
  },
  async approveJob({ commit }, { id }) {
    commit(SAVING_JOB)
    await approveJob(id)
    commit(SAVED_JOB)
    commit(APPROVE_JOB, { id })
  },
  async unapproveJob({ commit }, { id }) {
    commit(SAVING_JOB)
    await unapproveJob(id)
    commit(SAVED_JOB)
    commit(UNAPPROVE_JOB, { id })
  },
  async attachFormSubmissionId(
    { commit },
    { jobId, formId, formSubmissionId }
  ) {
    commit(SAVING_JOB)
    try {
      await attachFormSubmission(jobId, {
        form_id: formId,
        submission_id: formSubmissionId,
        created_at: moment().toISOString(),
      })

      const { data } = await getJob(jobId)

      commit(SAVED_JOB)
      commit(RECEIVE_JOB_JSON, { data })
    } catch (err) {
      commit('tickets/ERROR_TICKETS', err, { root: true })
      commit(ERROR_SAVING_JOB)
    }
  },
  async attachFormRequirementSubmission(
    { commit },
    { jobId, formRequirementId, formSubmissionId }
  ) {
    commit(SAVING_JOB)
    try {
      await attachFormRequirementSubmission(
        jobId,
        formRequirementId,
        formSubmissionId
      )

      const { data } = await getJob(jobId)

      commit(SAVED_JOB)
      commit(RECEIVE_JOB_JSON, { data })
    } catch (err) {
      commit('tickets/ERROR_TICKETS', err, { root: true })
      commit(ERROR_SAVING_JOB)
    }
  },
  async fetchAttachment(
    { commit }: { commit: Commit },
    { jobId, attachmentId }: { jobId: string; attachmentId: string }
  ) {
    try {
      const attachmentResponse = await fetchJobAttachment(jobId, attachmentId)
      const attachment = new JobAttachment(attachmentResponse.data)

      commit(RECEIVE_ATTACHMENT, { jobId, attachment })

      return attachment
    } catch {
      return null
    }
  },
  async addAttachment(
    {
      commit,
      rootGetters,
    }: {
      commit
      rootGetters
    },
    {
      jobId,
      attachmentId,
      file,
    }: {
      jobId: string
      attachmentId: string
      file: File
    }
  ) {
    try {
      await uploadAttachment(jobId, attachmentId, file)

      const { data } = await getJobByUuid(jobId)
      commit(SAVED_JOB)
      commit(RECEIVE_JOB_JSON, {
        data,
        jobFactory: createJobFactory(rootGetters),
      })

      return new JobAttachment(
        data.attachments.find((a) => a.id === attachmentId)
      )
    } catch (err) {
      commit('tickets/ERROR_TICKETS', err, { root: true })
      commit(ERROR_SAVING_JOB)
    }
  },
  async removeAttachment({ commit }, { jobId, attachmentId }) {
    try {
      commit(SAVING_JOB)
      await removeAttachment(jobId, attachmentId)
      const { data } = await getJobByUuid(jobId)

      commit(SAVED_JOB)
      commit(RECEIVE_JOB_JSON, { data })
    } catch (err) {
      commit('tickets/ERROR_TICKETS', err, { root: true })
      commit(ERROR_SAVING_JOB)
    }
  },
  async createJobComment({ commit }, { jobId, comment }) {
    commit(SAVING_JOB)
    const id = uuidv4()
    const submittedOn = moment.utc().toISOString()

    try {
      await createJobComment(jobId, id, comment, submittedOn)
      const { data } = await getJobByUuid(jobId)

      commit(SAVED_JOB)
      commit(RECEIVE_JOB_JSON, { data })
    } catch (err) {
      commit('tickets/ERROR_TICKETS', err, { root: true })
      commit(ERROR_SAVING_JOB)
    }
  },
  async scheduleJob(
    { commit, state, rootGetters },
    {
      jobNumber,
      startDate,
      resourceId,
      serviceProviderId,
    }: {
      jobNumber: number
      startDate: moment.Moment
      resourceId: number | null
      serviceProviderId: string | null
    }
  ) {
    const job = state.all[jobNumber] as Job
    const jobStartDateTime = moment(job.startTime)
    let jobEndDateTime = moment(job.endTime)
    const dateChange = !job.startTime.isSame(startDate, 'date')
    const unitChange = job.unitId !== resourceId
    const companyChange =
      job.serviceProviderBusinessUnitId !== serviceProviderId
    const isInAssignmentGroup = job.assignmentGroupId !== null
    const isInAssignmentGroupWithCompletedJob = Object.values<Job>(
      state.all
    ).find(
      (j: Job) => j.isCompleted && j.assignmentGroupId === job.assignmentGroupId
    )

    if (!isInAssignmentGroup && !dateChange && !unitChange && !companyChange)
      return

    if (isInAssignmentGroup) {
      if (isInAssignmentGroupWithCompletedJob) {
        try {
          await removeJobFromAssignmentGroup(job.assignmentGroupId!, job.uuid)
        } catch (e) {
          commit('UPDATE_GROUP_ID', {
            jobNumbers: [job.id],
            assignmentGroupId: job.assignmentGroupId,
          })
          throw e
        }
      }
      commit('UPDATE_GROUP_ID', {
        jobNumbers: [job.id],
        assignmentGroupId: null,
      })
    }

    if (dateChange) {
      const duration = moment
        .duration(job.endTime.diff(job.startTime))
        .asMinutes()
      const newDateObject = {
        year: startDate.get('year'),
        month: startDate.get('month'),
        date: startDate.get('date'),
      }
      jobStartDateTime.set(newDateObject)
      jobEndDateTime = jobStartDateTime.clone().add(duration, 'minutes')

      commit(SET_JOB_TIME, {
        id: job.id,
        startTime: jobStartDateTime,
        endTime: jobEndDateTime,
      })
    }

    if (unitChange || companyChange) {
      commit(SET_JOB_ASSIGNMENT, {
        id: job.id,
        newCompany:
          rootGetters['companies/getByOrganizationId'](serviceProviderId),
        newUnitId: resourceId,
        newCrew: [],
      })
    }

    if (job.priority.name === 'New') {
      commit(SET_JOB_PRIORITY, {
        id: job.id,
        priority: priorityMap.Normal,
      })
    }

    try {
      await rescheduleJob(
        job.id,
        jobStartDateTime.toDate(),
        jobEndDateTime.toDate(),
        resourceId,
        serviceProviderId
      )
    } catch (e) {
      commit(SET_JOB_TIME, {
        id: job.id,
        startTime: job.startTime,
        endTime: job.endTime,
      })

      if (isInAssignmentGroup) {
        commit('UPDATE_GROUP_ID', {
          jobNumbers: [job.id],
          assignmentGroupId: job.assignmentGroupId,
        })
      }

      commit(SET_JOB_ASSIGNMENT, {
        id: job.id,
        newCompany: rootGetters['companies/getByOrganizationId'](
          job.serviceProviderBusinessUnitId
        ),
        newUnitId: job.unitId,
        newCrew: job.crew,
      })

      commit(SET_JOB_PRIORITY, {
        id: job.id,
        priority: job.priority.id,
      })
    }
  },
  async rescheduleGroup(
    { commit, state, rootGetters },
    {
      assignmentGroupId,
      offset,
      resourceId,
      serviceProviderId,
    }: {
      assignmentGroupId: string
      offset: moment.Duration
      resourceId?: number | null
      serviceProviderId?: string | null
    }
  ) {
    const assignmentGroupMembers = Object.values(
      state.all as Record<string, Job>
    ).filter((j) => j.assignmentGroupId === assignmentGroupId)
    const modelJob = assignmentGroupMembers[0]

    const unitChange = modelJob.unitId !== (resourceId ?? null)
    const companyChange =
      modelJob.serviceProviderBusinessUnitId !== (serviceProviderId || null)

    if (offset.asMilliseconds() === 0 && !unitChange && !companyChange) return

    for (const groupJob of assignmentGroupMembers) {
      const newStartTime = moment(groupJob.startTime).add(offset)
      const newEndTime = moment(groupJob.endTime).add(offset)
      commit(SET_JOB_TIME, {
        id: groupJob.id,
        startTime: newStartTime,
        endTime: newEndTime,
      })

      if (unitChange || companyChange) {
        commit(SET_JOB_ASSIGNMENT, {
          id: groupJob.id,
          newCompany:
            rootGetters['companies/getByOrganizationId'](serviceProviderId),
          newUnitId: resourceId,
          newCrew: [],
        })
      }

      if (groupJob.priority.name === 'New') {
        commit(SET_JOB_PRIORITY, {
          id: groupJob.id,
          priority: priorityMap.Normal,
        })
      }
    }

    try {
      await rescheduleGroup(assignmentGroupId, {
        offset: formatDuration(offset),
        resourceId,
        serviceProviderId,
      })
    } catch (e) {
      for (const groupJob of assignmentGroupMembers) {
        commit(SET_JOB_TIME, {
          id: groupJob.id,
          startTime: groupJob.startTime,
          endTime: groupJob.endTime,
        })

        commit(SET_JOB_ASSIGNMENT, {
          id: groupJob.id,
          newCompany: rootGetters['companies/getByOrganizationId'](
            groupJob.serviceProviderBusinessUnitId
          ),
          newUnitId: groupJob.unitId,
          newCrew: groupJob.crew,
        })

        commit(SET_JOB_PRIORITY, {
          id: groupJob.id,
          priority: groupJob.priority.id,
        })
      }
    }
  },
  async groupJobs({ state, commit }, jobNumbers: number[]) {
    commit(SAVING_JOB)
    const jobs: Job[] = jobNumbers
      .map((id) => state.all[id] as Job | null)
      .filter(Boolean) as Job[]

    commit('UPDATE_GROUP_ID', { jobNumbers, assignmentGroupId: jobs[0].uuid })
    const groupResponse = await groupJobs(jobs.map((j) => j.uuid)).then(
      (r) => r.data
    )
    commit('UPDATE_GROUP_ID', {
      jobNumbers,
      assignmentGroupId: groupResponse,
    })
    commit(SAVED_JOB)
  },
  async deleteAssignmentGroup({ state, commit }, assignmentGroupId: string) {
    commit(SAVING_JOB)
    const jobs = Object.values(state.all as Record<number, Job>).filter(
      (j) => j.assignmentGroupId === assignmentGroupId
    )
    const jobNumbers = jobs.map((j) => j.id)
    commit('UPDATE_GROUP_ID', {
      jobNumbers,
      assignmentGroupId: null,
    })
    try {
      await deleteAssignmentGroup(assignmentGroupId)
    } catch (e) {
      // rollback
      commit('UPDATE_GROUP_ID', {
        jobNumbers,
        assignmentGroupId,
      })
      throw e
    } finally {
      commit(SAVED_JOB)
    }
  },
  async addJobToAssignmentGroup(
    { state, commit, rootGetters },
    {
      assignmentGroupId,
      jobNumber,
    }: {
      assignmentGroupId: string
      jobNumber: number
    }
  ) {
    commit(SAVING_JOB)
    const job = state.all[jobNumber] as Job
    const originalGroup = job.assignmentGroupId
    const originalResource = job.unitId
    const originalServiceProviderId = job.serviceProviderBusinessUnitId
    const groupLeader = Object.values(state.all as Record<number, Job>).find(
      (j) => j.assignmentGroupId === assignmentGroupId
    )

    if (!groupLeader) {
      throw new Error('Assignment group not found')
    }

    commit(SET_JOB_ASSIGNMENT, {
      id: jobNumber,
      newCompany: rootGetters['companies/getByOrganizationId'](
        groupLeader.serviceProviderBusinessUnitId
      ),
      newUnitId: groupLeader.unitId,
      newCrew: job.crew,
    })

    commit('UPDATE_GROUP_ID', {
      jobNumbers: [jobNumber],
      assignmentGroupId,
    })
    try {
      await addJobToAssignmentGroup(assignmentGroupId, job.uuid)
    } catch (e) {
      commit('UPDATE_GROUP_ID', {
        jobNumbers: [jobNumber],
        assignmentGroupId: originalGroup,
      })
      commit(SET_JOB_ASSIGNMENT, {
        id: jobNumber,
        newCompany: rootGetters['companies/getByOrganizationId'](
          originalServiceProviderId
        ),
        newUnitId: originalResource,
        // newCrew: groupLeader.crew,
      })
    }

    commit(SAVED_JOB)
  },
  async removeJobFromAssignmentGroup({ state, commit }, jobNumber: number) {
    commit(SAVING_JOB)
    const job = state.all[jobNumber] as Job
    const assignmentGroupId = job.assignmentGroupId
    if (!assignmentGroupId) return

    commit('UPDATE_GROUP_ID', { jobNumbers: [job.id], assignmentGroupId: null })
    try {
      await removeJobFromAssignmentGroup(assignmentGroupId, job.uuid)
    } catch (e) {
      commit('UPDATE_GROUP_ID', { jobNumbers: [job.id], assignmentGroupId })
      throw e
    }
    commit(SAVED_JOB)
  },
}

export const mutations = {
  [LOADING_JOBS](state: JobsState) {
    state.loading = true
    state.error = false
  },
  [RECEIVE_JOB_JSON](state: JobsState, { data }) {
    state.loading = false
    state.error = null
    state.all = Object.freeze({
      ...state.all,
      [data.id]: state.jobFactory(data),
    })
  },
  [RECEIVE_JOBS_JSON](state: JobsState, { data }) {
    state.loading = false
    state.error = null
    state.saving = false
    const dataById = keyBy(data, 'id')
    const jobs = mapValues(dataById, state.jobFactory)
    state.all = Object.freeze({ ...state.all, ...jobs })
  },
  [RECEIVE_JOBS](state: JobsState, { data }) {
    state.loading = false
    state.error = null
    state.saving = false

    const jobsById = keyBy(data, 'id')
    state.all = Object.freeze({ ...state.all, ...jobsById })
  },
  [ERROR_JOBS](state: JobsState, error) {
    state.error = error
    state.saving = false
    state.lastFetch.timestamp = null
  },
  [CLEAR_TIMEOUT](state: JobsState) {
    clearTimeout(state.timeout)
    state.timeout = undefined
  },
  [SET_TIMEOUT](state: JobsState, timeout) {
    state.timeout = timeout
  },
  [RESET_WINDOW](state) {
    state.lastFetch.timestamp = null
    state.all = {}
  },
  [UPDATE_WINDOW](state: JobsState, query) {
    state.window.start = query.start?.clone()
    state.window.end = query.end?.clone()

    state.lastFetch = {
      timestamp: moment.utc(),
      query,
    }
  },
  [REMOVE_FROM_PROJECT](state: JobsState, id) {
    const job = state.all[id]
    job.projectId = null
    state.all = Object.freeze({ ...state.all, [id]: job })
    state.saving = false
  },
  [ADD_TO_PROJECT](state: JobsState, { projectId, id }) {
    const job = state.all[id]
    job.projectId = projectId
    state.all = Object.freeze({ ...state.all, [id]: job })
    state.saving = false
  },
  [SELECT_JOB](state: JobsState, jobId) {
    state.selectedJobIds.push(jobId)
  },
  [DESELECT_JOB](state: JobsState, jobId) {
    state.selectedJobIds = state.selectedJobIds.filter((id) => id !== jobId)
  },
  [CLEAR_SELECTED_JOBS](state: JobsState) {
    state.selectedJobIds = []
  },
  [SELECT_JOBS](state: JobsState, jobIds) {
    state.selectedJobIds = []
    state.selectedJobIds = jobIds
  },
  [SET_JOB_TIME](state: JobsState, { id, startTime, endTime }) {
    const job = Object.assign(state.all[id], { startTime, endTime })
    state.all = Object.freeze({ ...state.all, [id]: job })
  },
  [SET_JOB_ASSIGNMENT](
    state: JobsState,
    { id, newUnitId, newCompany, newCrew }
  ) {
    const job = state.all[id]

    job.crew = newCrew

    // Assign a new resource
    if (newUnitId) {
      job.status = SCHEDULED
      job.unitId = newUnitId
      job.serviceProviderBusinessUnitId = newCompany.organizationId
    }

    // Assign a job to a service provider
    if (!newUnitId && newCompany) {
      job.status = ASSIGNED_TO_SERVICE_PROVIDER
      job.unitId = null
      job.serviceProviderBusinessUnitId = newCompany.organizationId
    }

    // Unassign a job
    if (!newUnitId && !newCompany) {
      job.status = REQUESTED
      job.unitId = null
      job.serviceProviderBusinessUnitId = null
    }

    state.all = Object.freeze({ ...state.all, [id]: job })
  },
  [SET_JOB_PRIORITY](state: JobsState, { id, priority }) {
    const job = state.all[id]
    job.priority = priority
    state.all = Object.freeze({ ...state.all, [id]: job })
  },
  [APPROVE_JOB](state: JobsState, { id }) {
    const job = state.all[id]
    job.approvalStatus = APPROVED
    state.all = Object.freeze({ ...state.all, [id]: job })
  },
  [UNAPPROVE_JOB](state: JobsState, { id }) {
    const job = state.all[id]
    job.approvalStatus = PENDING_APPROVAL
    state.all = Object.freeze({ ...state.all, [id]: job })
  },
  [ARCHIVE_JOB](state: JobsState, { uuid }) {
    const job = Object.values(state.all as Record<number, Job>).find(
      (j) => j.uuid === uuid
    )
    if (!job) return

    job.isArchived = true
    state.all = Object.freeze({ ...state.all, [job.id]: job })
  },
  [UNARCHIVE_JOB](state: JobsState, { uuid }) {
    const job = Object.values(state.all as Record<number, Job>).find(
      (j) => j.uuid === uuid
    )
    if (!job) return

    job.isArchived = false
    state.all = Object.freeze({ ...state.all, [job.id]: job })
  },
  [LOADING_TAGS](state: JobsState) {},
  [RECEIVE_TAGS](state: JobsState, { data }) {
    const receivedTags: Tag[] = []

    data.forEach((json) => {
      receivedTags.push(new Tag(json))
    })

    state.tags = receivedTags
  },
  [REMOVE_JOB](state: JobsState, id) {
    const jobsToKeep = Object.values<Job>(state.all).filter(
      (job: Job) => job.id !== id
    )
    state.all = keyBy(jobsToKeep, 'id')
  },
  [SAVING_JOB](state: JobsState) {
    state.saving = true
  },
  [SAVED_JOB](state: JobsState) {
    state.saving = false
  },
  [ERROR_SAVING_JOB](state: JobsState) {
    state.saving = false
  },
  [SET_JOB_FACTORY](state: JobsState, factory) {
    state.jobFactory = factory
  },
  [JOB_SUPPLEMENTARY_DATA_LOADED](state: JobsState) {
    state.supplementaryDataLoaded = true
  },
  [RECEIVE_ATTACHMENT](
    state: JobsState,
    { jobId, attachment }: { jobId: string; attachment: JobAttachment }
  ) {
    const job = Object.values(state.all as Record<number, Job>).find(
      (j) => j.uuid === jobId
    )
    if (!job) return

    const jobAttachment = job.attachments.find((a) => a.id === attachment.id)
    if (!jobAttachment) {
      job.attachments.push(attachment)
    } else {
      jobAttachment.source = attachment.source
    }

    state.all = Object.freeze({ ...state.all, [job.id]: job })
  },
  UPDATE_GROUP_ID(
    state: JobsState,
    {
      jobNumbers,
      assignmentGroupId,
    }: {
      jobNumbers: number[]
      assignmentGroupId: string | null
    }
  ) {
    const jobList = jobNumbers.map((j) => state.all[j] as Job)
    const groupNumber =
      assignmentGroupId === null ? null : Math.min(...jobList.map((j) => j.id))

    for (const job of jobList) {
      job.assignmentGroupId = assignmentGroupId
      job.assignmentGroupNumber = groupNumber
    }
    state.all = Object.freeze({ ...state.all, ...keyBy(jobList, 'id') })
  },
}

function createDivisionFilter(divisions) {
  return (job) => {
    return divisions.includes(job.ownerId)
  }
}

function createResourceTypeFilter(resourceTypes) {
  return (job) => {
    return resourceTypes.includes(job.resourceTypeId)
  }
}

function formatDuration(duration) {
  let ms = duration.asMilliseconds()
  const sign = ms < 0 ? '-' : ''
  ms = Math.abs(ms)
  const days = Math.floor(ms / 86400000)
  const hours = Math.floor((ms % 86400000) / 3600000)
  const minutes = Math.floor((ms % 3600000) / 60000)
  const seconds = Math.floor((ms % 60000) / 1000)
  return `${sign}${days}.${padZero(hours, 2)}:${padZero(minutes, 2)}:${padZero(
    seconds,
    2
  )}`
}

function padZero(num, size) {
  let s = num + ''
  while (s.length < size) s = '0' + s
  return s
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
