import moment from 'moment'

export default class JobFormSubmission {
  constructor(json) {
    this.formSubmissionId = json.id
    this.formSubmissionUuid = json.submission_id
    this.formId = json.form_id
    this.submittedBy = json.submitted_by
    this.submittedOn = moment.utc(json.submitted_on).local()
  }

  formSubmissionId: number
  formSubmissionUuid: string
  formId: number
  submittedBy: string
  submittedOn: moment.Moment
}
