import { isEqual, orderBy } from 'lodash'
import Vue from 'vue'
import * as api from '../../api/cost-centers'
import CostCenter from '../../models/cost-center'
import errorNotification from './error-notification'

export const LOADING_COST_CENTERS = 'LOADING_COST_CENTERS'
export const RECEIVE_COST_CENTERS = 'RECEIVE_COST_CENTERS'
export const RECEIVE_ITEM = 'RECEIVE_ITEM'
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const ERROR_LOADING = 'ERROR_LOADING'

const modules = {
  errorNotification,
}

const state = () => {
  return {
    all: {},
    loading: false,
  }
}

const getters = {
  getSortedCostCenterArray: (state) =>
    orderBy(Object.values(state.all), [
      (costCenter) => costCenter.name.toLowerCase(),
    ]),
  getCostCentersForHub: (state, getters) => (hubId) =>
    getters.getSortedCostCenterArray.filter(
      (costCenter) => costCenter.ownerId === hubId
    ),
}

const actions = {
  fetchCostCenters({ commit }) {
    commit(LOADING_COST_CENTERS)

    return api
      .getAll()
      .then((res) => {
        commit(RECEIVE_COST_CENTERS, res)
        commit(CLEAR_ERROR, res)
      })
      .catch((err) => commit(ERROR_LOADING, err))
  },
}

const mutations = {
  [LOADING_COST_CENTERS](state) {
    state.loading = true
  },
  [RECEIVE_COST_CENTERS](state, { data }) {
    const receivedItems = {}

    data.forEach((json) => {
      receivedItems[json.id] = new CostCenter(json)
    })

    if (!isEqual(receivedItems, state.all)) {
      state.all = receivedItems
    }

    state.loading = false
  },
  [RECEIVE_ITEM](state, item) {
    Vue.set(state.all, item.id, item)
  },
}

export default {
  namespaced: true,
  modules,
  state,
  getters,
  actions,
  mutations,
}
