import { difference, isEqual } from 'lodash'
import {
  computed,
  InjectionKey,
  provide,
  Ref,
  ref,
  unref,
  watchEffect,
} from 'vue'
import { injectWithSelf } from '@/utils/injectWithSelf'

type MaybeRef<T> = Ref<T> | T

type IdType = number | string | null

export type HeadlessAutoCompleteValueType = {
  id: IdType
  title: string | null
}

export type ValueResolver = (
  id: IdType
) => Promise<HeadlessAutoCompleteValueType>

export type UseAutoCompleteOptions = {
  search?: Ref<string | undefined>
  valueResolver?: ValueResolver
} & (
  | {
      multiple?: false
      onValueChanged?: (_: HeadlessAutoCompleteValueType | undefined) => void
      value?: MaybeRef<IdType>
    }
  | {
      multiple: true
      onValueChanged?: (_: HeadlessAutoCompleteValueType[]) => void
      value?: MaybeRef<IdType[]>
    }
)

/**
 * A composable for dealing with autocomplete input state.
 *
 * It is recommended not to use this hook directly, but use a
 * combination of useAutoCompleteProvider / useAutoCompleteContext
 */
export const useAutoComplete = (options: UseAutoCompleteOptions) => {
  const search = options.search ?? ref('')
  const singleValue = ref<HeadlessAutoCompleteValueType>()
  const multiValue = ref<HeadlessAutoCompleteValueType[]>([])
  const expanded = ref<boolean>()
  const modified = ref<boolean>()

  const inputText = computed(() => {
    if (options.multiple) {
      if (expanded.value) {
        return search.value
      } else {
        return ''
      }
    }

    if (expanded.value) {
      if (singleValue.value && !modified.value) {
        return singleValue.value.title
      } else {
        return search.value
      }
    } else {
      return singleValue.value?.title
    }
  })

  const addValue = (item: HeadlessAutoCompleteValueType) => {
    if (options.multiple) {
      if (!multiValue.value?.find((v) => v.id === item.id)) {
        multiValue.value.push(item)
        notifyValueChanged()
      }
    } else {
      if (singleValue.value?.id !== item.id) {
        singleValue.value = item
        notifyValueChanged()
      }
    }
  }

  const removeValue = (item?: HeadlessAutoCompleteValueType) => {
    if (options.multiple && item) {
      const newArray = multiValue.value.filter((v) => v.id !== item.id)
      if (!isEqual(multiValue.value, newArray)) {
        multiValue.value = newArray
        notifyValueChanged()
      }
    } else {
      if (singleValue.value !== undefined) {
        singleValue.value = undefined
        notifyValueChanged()
      }
    }
  }

  const toggleValue = (item: HeadlessAutoCompleteValueType) => {
    if (
      multiValue.value.find((v) => v.id === item.id) ||
      singleValue.value?.id === item.id
    ) {
      removeValue(item)
    } else {
      addValue(item)
    }

    notifyValueChanged()
  }

  const handleBackspace = () => {
    if (
      options.multiple &&
      multiValue.value?.length &&
      inputText.value?.length === 0
    ) {
      multiValue.value.pop()
      notifyValueChanged()
    }
  }

  const notifyValueChanged = () => {
    if (options.multiple) {
      options.onValueChanged?.(multiValue.value)
    } else {
      options.onValueChanged?.(singleValue.value)
    }
  }

  const setModified = (val: boolean) => {
    modified.value = val
  }

  const setExpanded = (val: boolean) => {
    expanded.value = val
  }

  const clear = () => {
    multiValue.value = []
    singleValue.value = undefined
    modified.value = false
    notifyValueChanged()
  }

  watchEffect(() => {
    if (!options.valueResolver) {
      return
    }
    if (options.multiple) {
      const valueIds = unref(options.value) || []
      const currentIds = multiValue.value.map((v) => v.id)

      Promise.all(
        difference(valueIds, currentIds).map(options.valueResolver)
      ).then((results) => {
        results.map(addValue)
      })
    } else {
      const value = unref(options.value)
      if (value && value !== singleValue.value?.id) {
        options.valueResolver(value).then(addValue)
      }
    }
  })

  return {
    chips: multiValue,
    inputText,
    addValue,
    removeValue,
    toggleValue,
    handleBackspace,
    setModified,
    expanded,
    setExpanded,
    clear,
  }
}

export type AutoCompleteContextType = ReturnType<typeof useAutoComplete>
export const AutoCompleteContextInjectionKey: InjectionKey<AutoCompleteContextType> =
  Symbol('AutoCompleteContext')

/**
 * Creates an autocomplete context, and 'provides' it to child components.
 */
export const useAutoCompleteProvider = (options: UseAutoCompleteOptions) => {
  const composable = useAutoComplete(options)
  provide(AutoCompleteContextInjectionKey, composable)

  return composable
}

/**
 * Injects an autocomplete context, providing access to the calculated
 * inputText value, selected value chips, and helper methods for updating
 * the values.
 *
 * Parent components should call useAutoCompleteProvider() to establish the
 * context.
 */
export const useAutoCompleteContext = () => {
  return injectWithSelf(AutoCompleteContextInjectionKey, () => {
    return useAutoComplete({})
  })
}
