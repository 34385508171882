"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formButtonsFieldMatcher = void 0;
const form_buttons_definition_1 = require("../../form_fields/form_buttons_definition");
const json_schema_keys_1 = require("../../json_schema_keys");
const formButtonsFieldMatcher = (uniqueKey, isRequired, properties, uiSchema, fieldDefinitions, next) => {
    const type = properties[json_schema_keys_1.JsonSchemaKeys.TYPE_KEY];
    const formButton = uiSchema[json_schema_keys_1.JsonSchemaKeys.UI_WIDGET_KEY] === json_schema_keys_1.JsonSchemaKeys.WIDGET_TYPE_FORM_BUTTONS;
    if (type === json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_STRING && formButton) {
        const field = new form_buttons_definition_1.FormButtonsDefinition();
        field.title = properties.title;
        field.description = properties.description;
        field.buttonLabels = properties.buttons.map((button) => button.label);
        return field;
    }
    return next(uniqueKey, isRequired, properties, uiSchema, fieldDefinitions);
};
exports.formButtonsFieldMatcher = formButtonsFieldMatcher;
