var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"layers-menu rounded-lg"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-btn',{key:"locations",class:{
          'layer-btn': true,
          active: _setup.state.layerToggles.locations,
        },attrs:{"text":""},on:{"click":function($event){return _setup.toggleLayer('locations')}}},[_c('v-row',{staticClass:"flex-column",attrs:{"align":"center","justify":"center"}},[_c('img',{staticClass:"layer-btn-img rounded-lg",attrs:{"src":require("../../assets/map/LocationsLayer.png")}}),_c('span',{staticClass:"text-subtitle-2 text-capitalize"},[_vm._v(" Locations ")])])],1)],1),_c('v-col',[_c('v-btn',{key:"jobs",class:{
          'layer-btn': true,
          active: _setup.state.layerToggles.jobs,
        },attrs:{"text":""},on:{"click":function($event){return _setup.toggleLayer('jobs')}}},[_c('v-row',{staticClass:"flex-column",attrs:{"align":"center","justify":"center"}},[_c('img',{staticClass:"layer-btn-img rounded-lg",attrs:{"src":require("../../assets/map/JobsLayer.png")}}),_c('span',{staticClass:"text-subtitle-2 text-capitalize"},[_vm._v(" Jobs ")])])],1)],1),_c('v-col',[_c('v-btn',{key:"resources",class:{
          'layer-btn': true,
          active: _setup.state.layerToggles.resources,
        },attrs:{"text":""},on:{"click":function($event){return _setup.toggleLayer('resources')}}},[_c('v-row',{staticClass:"flex-column",attrs:{"align":"center","justify":"center"}},[_c('img',{staticClass:"layer-btn-img rounded-lg",attrs:{"src":require("../../assets/map/ResourcesLayer.png")}}),_c('span',{staticClass:"text-subtitle-2 text-capitalize"},[_vm._v(" Resources ")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }