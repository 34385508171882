import axios from 'axios'
import qs from 'qs'
import { del, get, post, put } from './base'

const projectsEndpoint = '/api/v3/projects'

let source = axios.CancelToken.source()

export function createProject(projectName, ownerId) {
  source.cancel('Cancel old get projects request')
  source = axios.CancelToken.source()
  return post(projectsEndpoint, {
    data: {
      name: projectName,
      owner_id: ownerId,
    },
    cancelToken: source.token,
  })
}

export function editProject(projectId, projectName) {
  source.cancel('Cancel old edit projects request')
  source = axios.CancelToken.source()
  return put(`${projectsEndpoint}/${projectId}`, {
    data: {
      name: projectName,
    },
    cancelToken: source.token,
  })
}

export function getProjects(projectIds) {
  source.cancel('Cancel old get projects request')
  source = axios.CancelToken.source()
  return get(`${projectsEndpoint}`, {
    cancelToken: source.token,
    params: {
      projectIds,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  })
}

export function getProject(projectId) {
  source.cancel('Cancel old get project request')
  source = axios.CancelToken.source()
  return get(`${projectsEndpoint}/${projectId}`, {
    cancelToken: source.token,
  })
}

export function getProjectJobs(projectId) {
  source.cancel('Cancel old get project jobs request')
  source = axios.CancelToken.source()
  return get(`${projectsEndpoint}/${projectId}/jobs`, {
    cancelToken: source.token,
  })
}

export function addJobToProject(projectId, jobId) {
  source.cancel('Cancel old add job to projects request')
  source = axios.CancelToken.source()
  return post(`${projectsEndpoint}/${projectId}/jobs`, {
    data: {
      job_id: jobId,
    },
    cancelToken: source.token,
  })
}

export function removeJobFromProject(projectId, jobId) {
  source.cancel('Cancel old remove job from projects request')
  source = axios.CancelToken.source()
  return del(`${projectsEndpoint}/${projectId}/jobs/${jobId}`, {
    cancelToken: source.token,
  })
}
