"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextEntryFieldDefinition = void 0;
const json_schema_keys_1 = require("../json_schema_keys");
const field_ui_schema_1 = require("../models/field_ui_schema/field_ui_schema");
const text_area_ui_schema_1 = require("../models/field_ui_schema/text_area_ui_schema");
const form_field_definition_1 = require("./form_field_definition");
class TextEntryFieldDefinition extends form_field_definition_1.PrimitiveValueField {
    constructor() {
        super(...arguments);
        this.dataType = json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_STRING;
        this.isMultiline = false;
    }
    uiSchema() {
        if (this.isMultiline) {
            return new text_area_ui_schema_1.TextAreaUiSchema();
        }
        else {
            return new field_ui_schema_1.EmptyUiSchema();
        }
    }
}
exports.TextEntryFieldDefinition = TextEntryFieldDefinition;
