import Vue from 'vue'
import {
  createInventoryAdjustment,
  getLocationInventory,
} from '@/api/inventory'
import LocationInventory from '../../models/location_inventory'
import { errorMutationHandler } from '../utils'

export const LOADING_INVENTORY = 'LOADING_INVENTORY'
export const RECEIVE_INVENTORY = 'RECEIVE_INVENTORY'
export const RECEIVE_LOCATION_INVENTORY = 'RECEIVE_LOCATION_INVENTORY'
export const ERROR_INVENTORY = 'ERROR_INVENTORY'

type State = {
  all: { [key: number]: LocationInventory }
  loading: boolean
  error: null | boolean
}

const state = () =>
  <State>{
    all: {},
    loading: false,
    error: null,
  }

const getters = {
  getById: (state: State) => (id) => state.all[id] || null,
  isLoading: (state: State) => state.loading,
}

export const actions = {
  async fetchLocationInventory({ commit }, { id }) {
    commit(LOADING_INVENTORY)

    let res
    try {
      res = await getLocationInventory(id)
    } catch (err) {
      commit(ERROR_INVENTORY, err)
      return
    }
    commit(RECEIVE_LOCATION_INVENTORY, res)
  },
  async adjustInventory({ commit }, inventoryAdjustment) {
    let res
    try {
      res = await createInventoryAdjustment(inventoryAdjustment)
    } catch {
      throw Error('Error Adjusting Inventory')
    }
    commit(RECEIVE_LOCATION_INVENTORY, res)
  },
}

export const mutations = {
  [LOADING_INVENTORY](state: State) {
    state.loading = true
    state.error = null
  },
  [RECEIVE_INVENTORY](state: State, { data }) {
    const locationInventory = {}

    data.forEach((location) => {
      locationInventory[location.id] = new LocationInventory(location)
    })

    state.all = locationInventory
    state.loading = false
    state.error = null
  },
  [RECEIVE_LOCATION_INVENTORY](state: State, { data }) {
    state.loading = false
    state.error = false

    if (data) {
      Vue.set(state.all, `${data.id}`, new LocationInventory(data))
    }
  },
  [ERROR_INVENTORY](state, error) {
    state.error = error
    errorMutationHandler(state, error)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
