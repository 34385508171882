import * as api from '../../api/schemas'
import errorNotification from './error-notification'
import { isEqual } from 'lodash'

export const LOADING_SCHEMAS = 'LOADING_SCHEMAS'
export const RECEIVE_SCHEMAS = 'RECEIVE_SCHEMAS'
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const ERROR_LOADING = 'ERROR_LOADING'

const modules = {
  errorNotification,
}

const state = () => {
  return {
    jobCustomFieldsSchema: {},
    loading: false,
  }
}

const getters = {
  getJobCustomFieldsSchema: (state) => state.jobCustomFieldsSchema,
}

export const actions = {
  async fetchSchemas({ commit }) {
    commit(LOADING_SCHEMAS)

    try {
      const res = await api.getJobCustomFieldsSchema()
      commit(RECEIVE_SCHEMAS, res)
      commit(CLEAR_ERROR, res)
    } catch (err) {
      commit(ERROR_LOADING, err)
    }
  },
}

export const mutations = {
  [LOADING_SCHEMAS](state) {
    state.loading = true
  },
  [RECEIVE_SCHEMAS](state, { data }) {
    if (!('schema' in data)) {
      data = { schema: data }
    }
    if (!isEqual(data, state.jobCustomFieldsSchema)) {
      state.jobCustomFieldsSchema = data
    }
    state.loading = false
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
}
