import { render, staticRenderFns } from "./VsCard.vue?vue&type=template&id=49f7a992&scoped=true&"
import script from "./VsCard.vue?vue&type=script&setup=true&lang=ts&"
export * from "./VsCard.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./VsCard.vue?vue&type=style&index=0&id=49f7a992&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49f7a992",
  null
  
)

export default component.exports