import * as icons from './icons'
import { camelCase } from 'lodash'
import { StyleImageInterface } from './StyleImageInterface'
import { calculateIconColor } from './common'

export default function CirclePin(
  name: string,
  { scale = 1, pixelRatio = 4 } = {}
): StyleImageInterface {
  const pinDetails = name.split('__')
  const fill = pinDetails[1]
  const icon = pinDetails[2]
  const size = (icon ? 24 : 18) * pixelRatio * scale
  const strokeWidth = 1.35
  const strokeColor = '#3c3d3f'

  return {
    width: size,
    height: size,
    data: new Uint8Array(size * size * pixelRatio),

    async onAdd(map) {
      this.map = map
      const canvas = document.createElement('canvas')
      canvas.width = size
      canvas.height = size
      const context = canvas.getContext('2d')!

      context.strokeStyle = strokeColor
      context.lineWidth = strokeWidth * pixelRatio
      context.fillStyle = fill

      const radius = size / 2 - strokeWidth * pixelRatio
      context.beginPath()
      context.arc(size / 2, size / 2, radius, 0, 2 * Math.PI)
      context.stroke()
      context.fill()

      context.fillStyle = calculateIconColor(fill)

      const iconPath = icons[camelCase(icon)]
      if (iconPath) {
        const iconCanvasSize = 24
        const iconScaleAmount = 2.6
        const iconPath2D = new Path2D(iconPath)
        const iconPosTranlated = (iconCanvasSize * iconScaleAmount) / 2

        context.translate(
          size / 2 - iconPosTranlated,
          size / 2 - iconPosTranlated
        )
        context.scale(iconScaleAmount, iconScaleAmount)
        context.fill(iconPath2D)
      }

      this.data = context.getImageData(0, 0, this.width, this.height).data
    },

    render() {
      if (!this.initialized) {
        this.initialized = true

        return true
      }

      return false
    },
  }
}
