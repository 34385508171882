"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConstGoToFlowEdge = void 0;
const const_go_to_flow_dto_1 = require("../models/conditionals/const_go_to_flow_dto");
const conditions_1 = require("../models/dependencies/conditions");
const edge_1 = require("./edge");
class ConstGoToFlowEdge extends edge_1.EdgeBase {
    constructor(source, target, dependentOn, requiredValue, flowKey, conditionalType) {
        super();
        this.source = source;
        this.target = target;
        this.dependentOn = dependentOn;
        this.requiredValue = requiredValue;
        this.flowKey = flowKey;
        this.conditionalType = conditionalType;
    }
    condition() {
        return new conditions_1.ConstCondition(this.requiredValue);
    }
    asDTO() {
        return new const_go_to_flow_dto_1.ConstGoToFlowDTO(this.dependentOn, this.requiredValue, this.flowKey, this.conditionalType);
    }
}
exports.ConstGoToFlowEdge = ConstGoToFlowEdge;
