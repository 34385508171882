<template>
  <p class="content">
    <span>
      {{ displayedText }}
    </span>
    <v-tooltip top max-width="250px">
      <template #activator="{ on }">
        <span v-on="on"
          ><strong>{{ hiddenLocationsText }}</strong></span
        >
      </template>
      <span v-html="toolTipText"></span>
    </v-tooltip>
  </p>
</template>

<script>
import { groupBy } from 'lodash'

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    job: {
      type: Object,
      required: true,
    },
  },
  computed: {
    pickupUpLocations() {
      const groupedInventory = Object.values(
        groupBy(this.job.inventory, (item) => item.position)
      )
      return groupedInventory.map((group) => {
        return (
          this.value.find(
            (location) => location.id === group[0].pickupLocationId
          )?.location ?? 'Pick up location undecided'
        )
      })
    },
    displayedText() {
      return this.pickupUpLocations[0]
    },
    hiddenLocationsText() {
      return this.pickupUpLocations.length > 1
        ? ` +${this.pickupUpLocations.length - 1} more`
        : ''
    },
    toolTipText() {
      return this.pickupUpLocations.join('<br />')
    },
  },
}
</script>

<style scoped>
.content {
  margin: 0;
}
</style>
