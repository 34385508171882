"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ObjectFieldPropertyDescription = void 0;
const json_schema_keys_1 = require("../../json_schema_keys");
const field_property_description_1 = require("./field_property_description");
class ObjectFieldPropertyDescription extends field_property_description_1.DefaultFieldPropertyDescription {
    constructor(objectField) {
        super(objectField);
        const schema = objectField.builder.build().pages[0].schema;
        this[json_schema_keys_1.JsonSchemaKeys.PROPERTIES_KEY] = schema.properties;
        this[json_schema_keys_1.JsonSchemaKeys.DEPENDENCIES_KEY] = schema.dependencies;
        this[json_schema_keys_1.JsonSchemaKeys.REQUIRED_KEY] = schema.required;
    }
}
exports.ObjectFieldPropertyDescription = ObjectFieldPropertyDescription;
