import * as icons from './icons'
import { camelCase } from 'lodash'
import { StyleImageInterface } from './StyleImageInterface'

function calculateIconColor(fill: string) {
  const hex = fill.replace('#', '')
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  const a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255

  return a < 0.47 ? '#000000' : '#FFFFFF'
}

export default function OwnedLocationPin(
  name: string,
  { scale = 1, pixelRatio = 4 } = {}
): StyleImageInterface {
  const pinDetails = name.split('__')
  const fill = pinDetails[1]
  const icon = pinDetails[2]
  const width = 24 * pixelRatio * scale
  const height = 25 * pixelRatio * scale
  const strokeWidth = 2.5
  const strokeColor = '#fff'
  const pinPath =
    'M15 2.804c-6.627 0-12 5.373-12 12V72.99c0 6.628 5.373 12 12 12h19.77C42.086 91.298 48 95.28 48 95.28s5.915-3.982 13.23-10.29H81c6.627 0 12-5.372 12-12V14.804c0-6.627-5.373-12-12-12H15Z'

  return {
    width,
    height,
    data: new Uint8Array(width * height * pixelRatio),

    async onAdd(map: mapboxgl.Map) {
      this.map = map
      const canvas = document.createElement('canvas')
      canvas.width = this.width
      canvas.height = this.height
      const context = canvas.getContext('2d')!

      const iconPath = icons[camelCase(icon)]

      context.strokeStyle = strokeColor
      context.lineWidth = strokeWidth * pixelRatio
      context.fillStyle = fill

      const pinPath2D = new Path2D(pinPath)
      const iconPath2D = new Path2D(iconPath)

      context.scale(scale, scale)
      context.stroke(pinPath2D)
      context.fill(pinPath2D)

      context.scale(3, 3)
      context.translate(4, 2)
      context.fillStyle = calculateIconColor(fill)
      context.fill(iconPath2D)

      this.data = context.getImageData(0, 0, this.width, this.height).data
    },

    render() {
      if (!this.initialized) {
        this.initialized = true

        return true
      }

      return false
    },
  }
}
