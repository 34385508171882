<template>
  <vs-wrapper>
    <vs-autocomplete
      data-test="user-selector-field"
      :disabled="disabled"
      :items="users"
      :label="title"
      :hint="description"
      :value="formData"
      :error-messages="errorMessages"
      @input="$emit('change', $event)"
    />
  </vs-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import { sortBy } from 'lodash'

export default {
  name: 'UserSelectorField',
  props: {
    errorSchema: Object,
    formData: String,
    businessUnitId: String,
    params: Object,
    rootSchema: Object,
    schema: Object,
    title: String,
    description: String,
    uiSchema: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getUsersRelatedToBusinessUnit: 'users/getUsersRelatedToBusinessUnit',
      getBusinessUnitById: 'businessUnits/getBusinessUnitById',
    }),
    users() {
      const users = this.getUsersRelatedToBusinessUnit(this.businessUnitId).map(
        (user) => {
          return {
            text: user.name,
            value: user.id,
            description: this.formatDescription(user),
          }
        }
      )

      return sortBy(users, ['text'])
    },
    errorMessages() {
      const errors = this.errorSchema?.errors ?? []
      if (this.formData && typeof this.formData !== 'string') {
        errors.push('User value expected.')
      }
      return errors
    },
  },
  methods: {
    formatDescription(user) {
      const org = this.getBusinessUnitById(user.organizationId)
      const team = this.getBusinessUnitById(user.defaultBusinessUnitId)
      if (!team) {
        return org.name
      } else {
        return org.name + ' > ' + team.name
      }
    },
  },
}
</script>

<style scoped></style>
