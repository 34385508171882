<template>
  <div class="wrapper">
    <Transition name="pop">
      <router-view />
    </Transition>

    <RightDrawer :is-visible="visible">
      <router-view :key="$route.path" name="sidesheet" />
    </RightDrawer>
  </div>
</template>

<script>
import RightDrawer from '../RightDrawer'
import Transition from '@/components/common/Transition'
export default {
  components: { RightDrawer, Transition },
  data() {
    return { visible: !!this.$route.params.id || !!this.$route.params.formId }
  },
  watch: {
    $route(to, from) {
      const hasIDParams = !!(to && to.params && to.params.id)
      const fromNewForm = from && from.params && from.params.id === 'new'

      if (this.visible && !fromNewForm) {
        this.visible = false
        setTimeout(() => (this.visible = hasIDParams), 200)
      } else {
        this.visible = hasIDParams
      }
    },
  },
}
</script>

<style scoped>
.wrapper {
  display: inherit;
  width: inherit;
  height: inherit;
}
</style>
