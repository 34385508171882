import { isEqual, orderBy } from 'lodash'

export function inventoriesAreEqual(inventories1, inventories2) {
  return isEqual(
    getComparableInventories(inventories1),
    getComparableInventories(inventories2)
  )
}

function getComparableInventories(inventories) {
  return orderBy(
    inventories.map((i) => ({
      pickupLocationId: i.pickupLocationId,
      inventorySubTypeId: i.inventorySubTypeId,
      quantity: parseFloat(i.quantity),
      position: i.position,
    })),
    ['pickupLocationId', 'inventorySubTypeId', 'position']
  )
}
