import createSignalrPlugin from '@/utils/signalr-vuex-plugin'
import LogRocket from 'logrocket'
import createPlugin from 'logrocket-vuex'
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import * as actions from './actions'
import activities from './modules/activities'
import assets from './modules/assets'
import attachments from './modules/attachments'
import businessUnits from './modules/business-units'
import companies from './modules/companies'
import costCenters from './modules/cost-centers'
import dataErrors from './modules/data-errors'
import dayView, { SET_SHOW_JOBS_TABLE } from './modules/day-view'
import divisions from './modules/divisions'
import featureFlags from './modules/feature-flags'
import firebase from './modules/firebase'
import formSubmissions, { SET_MEMORY_FIELDS } from './modules/form-submissions'
import formSubmissionsFilters, {
  SET_ACTIVE_SAVED_FILTER_ID,
  SET_ASIDE_VISIBILITY,
  SET_DATE_RANGE,
  SET_INVERSE_FORM_ACTIONS,
  RESET_INVERSE_FORM_ACTIONS,
  SET_INVERSE_FORM_IDS,
  RESET_INVERSE_FORM_IDS,
  SET_INVERSE_LOCATIONS,
  RESET_INVERSE_LOCATIONS,
  SET_SUBJECT_SEARCH_BUTTONS,
  SET_SUBJECT_SEARCH_IDS,
  RESET_SUBJECT_SEARCH_IDS,
  SET_TAGS,
  RESET_TAGS,
} from './modules/form-submissions-filters'
import forms from './modules/forms'
import inventory from './modules/inventory'
import itemForm from './modules/item-form'
import jobs from './modules/jobs'
import jobsList, {
  JOBS_LIST_INITIALIZED,
  SET_VISIBLE_COLUMNS,
  SET_COLUMN_ORDERING,
} from './modules/jobs-list'
import notifications, {
  SET_NOTIFICATIONS_TOKEN,
  SET_NOTIFICATIONS_BINDING,
} from './modules/notifications'
import organizationConfig from './modules/organization-config'
import permissions from './modules/permissions'
import projects from './modules/projects'
import resourceTypes from './modules/resource-types'
import schemas from './modules/schemas'
import services from './modules/services'
import tasks from './modules/tasks'
import tickets from './modules/tickets'
import unitNumbers from './modules/unit-numbers'
import users from './modules/users'
import weekView, { SET_SELECTED_RESOURCE_TYPE_ID } from './modules/week-view'
import mutations from './mutations'
import state from './state'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    jobsList: {
      initialized: state.jobsList.initialized,
      visibleColumns: state.jobsList.visibleColumns,
      columnOrdering: state.jobsList.columnOrdering,
    },
    dayView: {
      showJobQueue: state.dayView.showJobQueue,
    },
    weekView: {
      selectedResourceTypeId: state.weekView.selectedResourceTypeId,
    },
    notifications: {
      token: state.notifications.token,
      binding: state.notifications.binding,
    },
    formSubmissionsFilters: {
      isAsideVisible: state.formSubmissionsFilters.isAsideVisible,
      inverseFormIds: state.formSubmissionsFilters.inverseFormIds,
      inverseFormActions: state.formSubmissionsFilters.inverseFormActions,
      tags: state.formSubmissionsFilters.tags,
      dateRange: {
        start: state.formSubmissionsFilters.dateRange.start,
        end: state.formSubmissionsFilters.dateRange.end,
      },
      subjectSearchIds: state.formSubmissionsFilters.subjectSearchIds,
      subjectSearchButtons: state.formSubmissionsFilters.subjectSearchButtons,
      inverseLocations: state.formSubmissionsFilters.inverseLocations,
      activeSavedFilterId: state.formSubmissionsFilters.activeSavedFilterId,
    },
    formSubmissions: {
      formFieldMemory: state.formSubmissions.formFieldMemory,
    },
  }),
  filter: (mutation) =>
    [
      `jobsList/${JOBS_LIST_INITIALIZED}`,
      `jobsList/${SET_VISIBLE_COLUMNS}`,
      `jobsList/${SET_COLUMN_ORDERING}`,
      `dayView/${SET_SHOW_JOBS_TABLE}`,
      `weekView/${SET_SELECTED_RESOURCE_TYPE_ID}`,
      `notifications/${SET_NOTIFICATIONS_TOKEN}`,
      `notifications/${SET_NOTIFICATIONS_BINDING}`,
      `formSubmissionsFilters/${SET_ASIDE_VISIBILITY}`,
      `formSubmissionsFilters/${SET_INVERSE_FORM_IDS}`,
      `formSubmissionsFilters/${SET_INVERSE_FORM_ACTIONS}`,
      `formSubmissionsFilters/${SET_TAGS}`,
      `formSubmissionsFilters/${SET_DATE_RANGE}`,
      `formSubmissionsFilters/${SET_SUBJECT_SEARCH_IDS}`,
      `formSubmissionsFilters/${SET_SUBJECT_SEARCH_BUTTONS}`,
      `formSubmissionsFilters/${SET_INVERSE_LOCATIONS}`,
      `formSubmissionsFilters/${RESET_SUBJECT_SEARCH_IDS}`,
      `formSubmissionsFilters/${RESET_INVERSE_LOCATIONS}`,
      `formSubmissionsFilters/${RESET_TAGS}`,
      `formSubmissionsFilters/${RESET_INVERSE_FORM_ACTIONS}`,
      `formSubmissionsFilters/${RESET_INVERSE_FORM_IDS}`,
      `formSubmissionsFilters/${SET_ACTIVE_SAVED_FILTER_ID}`,
      `formSubmissions/${SET_MEMORY_FIELDS}`,
    ].includes(mutation.type),
})

const signalrPlugin = createSignalrPlugin({
  unitNumbers,
})

const plugins = [createPlugin(LogRocket), vuexLocal.plugin, signalrPlugin]

const store = new Vuex.Store({
  state,
  actions,
  mutations,
  plugins,
  modules: {
    activities,
    assets,
    attachments,
    businessUnits,
    companies,
    costCenters,
    dataErrors,
    dayView,
    divisions,
    featureFlags,
    firebase,
    formSubmissions,
    forms,
    formSubmissionsFilters,
    inventory,
    itemForm,
    jobs,
    jobsList,
    notifications,
    organizationConfig,
    permissions,
    projects,
    resourceTypes,
    schemas,
    services,
    tasks,
    tickets,
    unitNumbers,
    users,
    weekView,
  },
  strict: debug,
})

if (module.hot) {
  module.hot.accept(
    [
      './state',
      './actions',
      './mutations',
      './modules/activities',
      './modules/assets',
      './modules/attachments',
      './modules/business-units',
      './modules/companies',
      './modules/cost-centers',
      './modules/data-errors',
      './modules/day-view',
      './modules/divisions',
      './modules/feature-flags',
      './modules/firebase',
      './modules/form-submissions',
      './modules/form-submissions-filters',
      './modules/forms',
      './modules/inventory',
      './modules/item-form',
      './modules/jobs',
      './modules/jobs-list',
      './modules/notifications',
      './modules/organization-config',
      './modules/permissions',
      './modules/projects',
      './modules/resource-types',
      './modules/schemas',
      './modules/services',
      './modules/tasks',
      './modules/tickets',
      './modules/unit-numbers',
      './modules/users',
      './modules/week-view',
    ],
    () => {
      store.hotUpdate({
        state: require('./state').default,
        actions: require('./actions').default,
        mutations: require('./mutations').default,
        modules: {
          activities: require('./modules/activities').default,
          assets: require('./modules/assets').default,
          businessUnits: require('./modules/business-units').default,
          companies: require('./modules/companies').default,
          costCenters: require('./modules/cost-centers').default,
          dataErrors: require('./modules/data-errors').default,
          dayView: require('./modules/day-view').default,
          divisions: require('./modules/divisions').default,
          featureFlags: require('./modules/feature-flags').default,
          firebase: require('./modules/firebase').default,
          formSubmissions: require('./modules/form-submissions').default,
          formSubmissionsFilters: require('./modules/form-submissions-filters')
            .default,
          forms: require('./modules/forms').default,
          inventory: require('./modules/inventory').default,
          itemForm: require('./modules/item-form').default,
          jobs: require('./modules/jobs').default,
          jobsList: require('./modules/jobs-list').default,
          notifications: require('./modules/notifications').default,
          organizationConfig: require('./modules/organization-config').default,
          permissions: require('./modules/permissions').default,
          projects: require('./modules/projects').default,
          resourceTypes: require('./modules/resource-types').default,
          schemas: require('./modules/schemas').default,
          services: require('./modules/services').default,
          tasks: require('./modules/tasks').default,
          tickets: require('./modules/tickets').default,
          unitNumbers: require('./modules/unit-numbers').default,
          users: require('./modules/users').default,
          weekView: require('./modules/week-view').default,
        },
      })
    }
  )
}

export default store
