<template>
  <v-list-item :key="itemKey" :disabled="disabled" @click="$emit('add-field')">
    <v-list-item-avatar>
      <v-icon>{{ icon }}</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>{{ label }}</v-list-item-title>
      <v-list-item-subtitle>{{ description }} </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'VsListFieldItem',
  props: {
    itemKey: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    },
    label: {
      type: String,
    },
    description: {
      type: String,
    },
    disabled: false,
  },
}
</script>
