import moment from 'moment'
import { currentTimezone } from '@/utils/date-formatting.js'
import { del, get, post, put } from './base'
import qs from 'qs'
import axios, { AxiosError, AxiosResponse, CancelToken } from 'axios'
import { snakeCase, sortBy } from 'lodash'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import { computed, getCurrentInstance, isRef, unref } from 'vue'
import { RECEIVE_JOB_JSON } from '@/store/modules/jobs'
import { LineItem } from '@/models/line-item'
import { JobEvent } from '@/models/job-event'
import { MaybeRef } from '@vueuse/core'

const v3JobsEndpoint = '/api/v3/jobs'
const v4JobsEndpoint = '/api/v4/jobs'
const v5JobsEndpoint = '/api/v5/jobs'
const v6JobsEndpoint = '/api/v6/jobs'

let getJobsCancelToken = axios.CancelToken.source()

export function getJobs({
  start,
  end,
  modifiedSince,
  divisions,
  resourceTypes,
  statuses,
  includeOngoing,
  includeArchived = false,
}: {
  start?: moment.Moment
  end?: moment.Moment
  modifiedSince?: moment.Moment
  divisions?: []
  resourceTypes?: []
  statuses?: []
  includeOngoing?: boolean
  includeArchived?: boolean
}) {
  getJobsCancelToken.cancel(
    'Cancelling get jobs request because a new request is in progress.'
  )
  getJobsCancelToken = axios.CancelToken.source()
  // Expects datetime values in ISO 8601 format
  return get(v5JobsEndpoint, {
    cancelToken: getJobsCancelToken.token,
    params: {
      start: moment(start).toISOString(),
      end: moment(end).toISOString(),
      modifiedSince: modifiedSince && moment(modifiedSince).toISOString(),
      d: divisions,
      rt: resourceTypes,
      statuses,
      includeOngoing,
      includeArchived,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  })
}

export function searchJobs(params, cancelToken: CancelToken) {
  return post(v6JobsEndpoint + '/search', {
    data: Object.fromEntries(
      Object.entries(params).map(([k, v]) => [snakeCase(k), v])
    ),
    config: {
      cancelToken,
    },
  })
}

export function searchJobsCount(params, cancelToken: CancelToken) {
  return post(v6JobsEndpoint + '/search/count', {
    data: Object.fromEntries(
      Object.entries(params).map(([k, v]) => [snakeCase(k), v])
    ),
    config: {
      cancelToken,
    },
  })
}

export function rescheduleGroup(
  groupId: string,
  {
    offset,
    resourceId,
    serviceProviderId,
  }: {
    offset: string
    resourceId?: number | null
    serviceProviderId?: string | null
  }
) {
  return post(`${v3JobsEndpoint}/group/${groupId}/reschedule`, {
    data: {
      offset,
      resource_id: resourceId,
      service_provider_id: serviceProviderId,
    },
  })
}

export function groupJobs(jobIds: string[]) {
  return put(`${v3JobsEndpoint}/group`, {
    data: jobIds,
  })
}

export function deleteAssignmentGroup(groupId: string) {
  return del(`${v3JobsEndpoint}/group/${groupId}`)
}

export function addJobToAssignmentGroup(groupId: string, jobIdAdd: string) {
  return put(`${v3JobsEndpoint}/group/${groupId}/jobs/${jobIdAdd}`)
}

export function removeJobFromAssignmentGroup(
  groupId: string,
  jobIdToRemove: string
) {
  return del(`${v3JobsEndpoint}/group/${groupId}/jobs/${jobIdToRemove}`)
}

export function exportJobs(params) {
  return post(v5JobsEndpoint + '/export', {
    data: params,
    config: {
      responseType: 'blob',
    },
  })
}

export function getJob(jobNumber: number) {
  return get(`${v5JobsEndpoint}/${jobNumber}`, {})
}

export function getJobByUuid(id: string) {
  return get(`${v5JobsEndpoint}/${id}`, {})
}

export function getJobPdf(jobId: string, pdfFormatId: string) {
  return get(`${v4JobsEndpoint}/${jobId}/pdf`, {
    params: {
      timezone: currentTimezone(),
      pdfFormat: pdfFormatId,
    },
    responseType: 'blob',
  })
}

export function getJobHistory(jobNumber: number) {
  return get(`${v3JobsEndpoint}/${jobNumber}/history`, {})
}

export function createJobs(createJobsRequest, createProject: boolean) {
  return post(v5JobsEndpoint, {
    data: createJobsRequest,
    config: {
      params: {
        createProject,
      },
    },
  })
}

export function createJobComment(
  jobId: string,
  id: string,
  comment: string,
  submittedOn: string
) {
  return post(`${v5JobsEndpoint}/${jobId}/comment`, {
    data: {
      id,
      comment,
      submitted_on: submittedOn,
    },
  })
}

export function importJobs(request) {
  return post(`${v3JobsEndpoint}/import`, {
    data: request,
  })
}

export function rescheduleJob(
  id: number,
  startTime: Date,
  endTime: Date,
  unitId: number | null,
  serviceProviderBusinessUnitId: string | null
) {
  return put(`${v3JobsEndpoint}/${id}/reschedule`, {
    data: {
      start_time: startTime,
      end_time: endTime,
      unit_id: unitId,
      service_provider_business_unit_id: serviceProviderBusinessUnitId,
    },
  })
}

export function updateJob(job) {
  return put(`${v5JobsEndpoint}/${job.id}`, {
    data: job,
  })
}

export function activateJob(jobNumber: number) {
  return post(`${v3JobsEndpoint}/${jobNumber}/activate`, {})
}

export function completeJob(jobNumber: number) {
  return post(`${v3JobsEndpoint}/${jobNumber}/complete`, {})
}

export function cancelJob(jobNumber: number) {
  return post(`${v3JobsEndpoint}/${jobNumber}/cancel`, {})
}

export function unCancelJob(jobNumber: number) {
  return post(`/api/jobs/${jobNumber}/uncancel`, {})
}

export function unCompleteJob(jobNumber: number) {
  return post(`/api/jobs/${jobNumber}/uncomplete`, {})
}

export function archive(jobId: string) {
  return bulkArchive([jobId])
}

export function unarchive(id: string) {
  return post(`${v3JobsEndpoint}/${id}/unarchive`, {})
}

export function approveJob(jobNumber: number) {
  return post(`${v3JobsEndpoint}/${jobNumber}/approve`, {})
}

export function submitJobForApproval(
  uuid: string,
  emails: string[] | null = null
) {
  return post(`${v3JobsEndpoint}/${uuid}/approval`, {
    data: {
      emails,
    },
  })
}

export function cancelJobApprovalRequest(uuid: string) {
  return post(`${v3JobsEndpoint}/${uuid}/approval/retract`, {})
}

export function rejectJobApproval(id: string, rejectionMessage: string) {
  return post(`${v3JobsEndpoint}/${id}/reject-approval`, {
    data: {
      rejection_message: rejectionMessage,
    },
  })
}

export function unapproveJob(jobNumber: number) {
  return post(`${v3JobsEndpoint}/${jobNumber}/unapprove`, {})
}

export function fetchJobAttachment(jobId: string, attachmentId: string) {
  return get(`${v5JobsEndpoint}/${jobId}/attachments/${attachmentId}`)
}

export function updateTagsOnApprovedJob(
  jobNumber: number,
  tags: string[],
  divisionId: string
) {
  return put(`${v3JobsEndpoint}/${jobNumber}/tags`, {
    data: {
      job_number: jobNumber,
      tags,
      division_id: divisionId,
    },
  })
}

export function updatePriority(jobNumbers: number[], priority: number) {
  return put(`${v3JobsEndpoint}/priority`, {
    data: {
      priority,
      job_ids: jobNumbers,
    },
  })
}

export function bulkOverwriteSchedule(
  jobNumbers: number[],
  startTime: moment.Moment | null,
  endTime: moment.Moment | null
) {
  return put(`${v3JobsEndpoint}/scheduled-time`, {
    data: {
      start_time: startTime,
      end_time: endTime,
      job_ids: jobNumbers,
    },
  })
}

export function bulkReschedule(jobNumbers: number[], duration: number) {
  return post(`${v3JobsEndpoint}/reschedule`, {
    data: {
      time_offset_seconds: duration,
      job_ids: jobNumbers,
    },
  })
}

export function bulkUpdateDestination(
  jobNumbers: number[],
  destinationId: number
) {
  return put(`${v3JobsEndpoint}/destination`, {
    data: {
      destination_id: destinationId,
      job_ids: jobNumbers,
    },
  })
}

export function bulkUpdatePickupLocation(
  jobNumbers: number[],
  pickupLocationId: number | undefined
) {
  return put(`${v3JobsEndpoint}/pickup-location`, {
    data: {
      pickup_location_id: pickupLocationId,
      job_ids: jobNumbers,
    },
  })
}

export function bulkUpdateCostCenter(jobNumbers: number[], costCenter: string) {
  return put(`${v3JobsEndpoint}/cost-center`, {
    data: {
      cost_center: costCenter,
      job_ids: jobNumbers,
    },
  })
}

export function bulkUpdateDetails(jobNumbers: number[], details: string) {
  return put(`${v3JobsEndpoint}/details`, {
    data: {
      details,
      job_ids: jobNumbers,
    },
  })
}

export function bulkCancel(jobNumbers: number[]) {
  return put(`${v3JobsEndpoint}/cancel`, {
    data: {
      job_ids: jobNumbers,
    },
  })
}

export function bulkArchive(jobIds: string[]) {
  return put(`${v3JobsEndpoint}/archive`, {
    data: {
      job_ids: jobIds,
    },
  })
}

export function bulkUpdateCustomField(
  jobIds: string[],
  customFieldKey: string,
  customFieldValue: any
) {
  return put(`${v3JobsEndpoint}/custom-field`, {
    data: {
      job_ids: jobIds,
      custom_field_key: customFieldKey,
      custom_field_value: customFieldValue,
    },
  })
}

export function bulkUpdateProject(jobIds: string[], projectId: string) {
  return put(`${v3JobsEndpoint}/project`, {
    data: {
      job_ids: jobIds,
      project_id: projectId,
    },
  })
}

export function bulkAddTags(jobNumbers: number[], tags: string[]) {
  return put(`${v3JobsEndpoint}/tags/add`, {
    data: {
      job_numbers: jobNumbers,
      tags,
    },
  })
}

export function bulkRemoveTags(jobNumbers: number[], tags: string[]) {
  return put(`${v3JobsEndpoint}/tags/remove`, {
    data: {
      job_numbers: jobNumbers,
      tags,
    },
  })
}

export function bulkOverwriteTags(jobNumbers: number[], tags: string[]) {
  return put(`${v3JobsEndpoint}/tags/overwrite`, {
    data: {
      job_numbers: jobNumbers,
      tags,
    },
  })
}

export function sendEmailNotificationForJob(
  jobNumber: number,
  emails: Record<number, string[]>,
  userSids: string[]
) {
  return post(`${v3JobsEndpoint}/${jobNumber}/notify`, {
    data: {
      emails,
      user_sids: userSids,
    },
  })
}

export function attachFormSubmission(
  jobId: string,
  attachFormSubmissionRequest: {
    form_id: string
    submission_id: string
    created_at: string
  }
) {
  return post(`${v3JobsEndpoint}/${jobId}/formSubmissions`, {
    data: attachFormSubmissionRequest,
  })
}

export function attachFormRequirementSubmission(
  jobId: string,
  formRequirementId: string,
  formSubmissionId: string
) {
  return put(
    `${v4JobsEndpoint}/${jobId}/form-requirements/${formRequirementId}`,
    {
      data: {
        form_submission_id: formSubmissionId,
      },
    }
  )
}

export function uploadAttachment(
  jobId: string,
  attachmentId: string,
  file: File
) {
  return post(`${v5JobsEndpoint}/${jobId}/attachments/${attachmentId}`, {
    data: {
      attachment: file,
    },
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  })
}

export function removeAttachment(jobId: string, attachmentId: string) {
  return del(`${v3JobsEndpoint}/${jobId}/attachments/${attachmentId}`)
}

export function updateCompletedJobActualTimes(
  jobId: string,
  updateCompletedJobActualTimesRequest: {
    start_time: string
    end_time: string
    active_time: string
    completed_time: string
  }
) {
  return put(`${v3JobsEndpoint}/${jobId}/actual-times`, {
    data: updateCompletedJobActualTimesRequest,
  })
}

export interface GetJobEventsQueryResponse {
  job_id: string
  event_id: string
  event_type: string
  timestamp: Date
  metadata: Record<string, any> | null
  data: Record<string, any> | null
}

export function getJobEventsQuery(jobId: MaybeRef<string>) {
  const computedJobId = computed(() => unref(jobId))
  return useQuery({
    queryKey: ['events', computedJobId.value],
    queryFn: async () => {
      const response = await get<GetJobEventsQueryResponse[]>(
        `${v3JobsEndpoint}/${computedJobId.value}/events`,
        {}
      )

      return response.data
    },
    select: (data) => data.map((event) => new JobEvent(event)),
  })
}

export function useAcceptJobMutation() {
  const queryClient = useQueryClient()
  const { isLoading, isError, error, isSuccess, mutate } = useMutation<
    AxiosResponse,
    AxiosError
  >({
    mutationFn: (jobNumber) => post(`${v3JobsEndpoint}/${jobNumber}/accept`),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['jobs'])
    },
  })

  return {
    isLoading,
    isError,
    error,
    isSuccess,
    accept: mutate,
  }
}

export function useDeclineJobMutation() {
  const queryClient = useQueryClient()
  const { isLoading, isError, error, isSuccess, mutate } = useMutation({
    mutationFn: (jobNumber) => post(`${v3JobsEndpoint}/${jobNumber}/decline`),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['jobs'])
    },
  })

  return {
    isLoading,
    isError,
    error,
    isSuccess,
    decline: mutate,
  }
}

export function useJobQuery(jobNumber: number) {
  const enabled = computed(() => !isRef(jobNumber) || !!jobNumber.value)
  const { data, isLoading, error } = useQuery({
    queryKey: ['jobs', jobNumber],
    queryFn: () =>
      getJob(unref(jobNumber)).then((r) => {
        const instance = getCurrentInstance()
        instance?.proxy?.$store.commit(`jobs/${RECEIVE_JOB_JSON}`, r)
        return r.data
      }),
    enabled,
    retry: (failureCount, error: AxiosError<{ detail: string }>) => {
      return !(failureCount > 2 || error.response?.status === 404)
    },
  })

  return { data, isLoading, error }
}

export function useBulkAssignMutation() {
  const queryClient = useQueryClient()
  const { isLoading, isError, error, isSuccess, mutateAsync } = useMutation({
    mutationFn: (variables: {
      jobIds: string[]
      resourceId?: number | null
      serviceProviderId?: string | null
      operators?: string[] | null
    }) =>
      put(`${v3JobsEndpoint}/assign`, {
        data: {
          job_ids: variables.jobIds,
          resource_id: variables.resourceId,
          service_provider_id: variables.serviceProviderId,
          operators: variables.operators,
        },
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['jobs'])
    },
  })

  return {
    isLoading,
    isError,
    error,
    isSuccess,
    bulkAssign: mutateAsync,
  }
}

export function useBulkUnassignMutation() {
  const queryClient = useQueryClient()
  const { isLoading, isError, error, isSuccess, mutateAsync } = useMutation({
    mutationFn: (variables: { jobIds: string[] }) =>
      put(`${v3JobsEndpoint}/unassign`, {
        data: { job_ids: variables.jobIds },
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['jobs'])
    },
  })

  return {
    isLoading,
    isError,
    error,
    isSuccess,
    bulkUnassign: mutateAsync,
  }
}

export interface GetJobLineItemQueryResponse {
  id: string
  name: string
  rate: number | null
  quantity: number | null
  created: Date
}

export function getJobLineItemsQuery(jobId: MaybeRef<string>) {
  const computedJobId = computed(() => unref(jobId))
  return useQuery({
    queryKey: ['line-items', computedJobId.value],
    queryFn: async () => {
      const response = await get<GetJobLineItemQueryResponse[]>(
        `${v3JobsEndpoint}/${computedJobId.value}/line-items`,
        {}
      )

      return response.data
    },
    select: (data) => {
      return sortBy(
        data.map((lineItem) => new LineItem(lineItem)),
        (lineItem) => lineItem.created
      )
    },
  })
}

interface JobLineItemRequest {
  id: string
  name: string | null
  rate?: number | null
  quantity?: number | null
  price_book_item_id?: string | null
}

export function createJobLineItemMutation() {
  const queryClient = useQueryClient()
  return useMutation<
    AxiosResponse,
    AxiosError,
    {
      jobId: string
      request: JobLineItemRequest
    }
  >({
    mutationFn: ({ jobId, request }) => {
      return post(`${v3JobsEndpoint}/${jobId}/line-items`, {
        data: request,
      })
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries(['line-items', variables.jobId])
      await queryClient.invalidateQueries(['invoice'])
    },
  })
}

export function updateJobLineItemMutation() {
  const queryClient = useQueryClient()
  return useMutation<
    AxiosResponse,
    AxiosError,
    {
      jobId: string
      request: JobLineItemRequest
    }
  >({
    mutationFn: ({ jobId, request }) => {
      return post(`${v3JobsEndpoint}/${jobId}/line-items/${request.id}`, {
        data: request,
      })
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries(['line-items', variables.jobId])
      await queryClient.invalidateQueries(['invoice'])
    },
  })
}

export function deleteJobLineItemMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({
      jobId,
      lineItemId,
    }: {
      jobId: string
      lineItemId: string
    }) => {
      return del(`${v3JobsEndpoint}/${jobId}/line-items/${lineItemId}`)
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries(['line-items', variables.jobId])
      await queryClient.invalidateQueries(['invoice'])
    },
  })
}

export function rejectJobApprovalMutation() {
  return useMutation({
    mutationFn: ({
      id,
      rejectionMessage,
    }: {
      id: string
      rejectionMessage: string
    }) => {
      return rejectJobApproval(id, rejectionMessage)
    },
  })
}

export function submitJobForApprovalMutation() {
  return useMutation({
    mutationFn: ({
      uuid,
      emails,
    }: {
      uuid: string
      emails: string[] | null
    }) => {
      return submitJobForApproval(uuid, emails)
    },
  })
}

export function useBulkArchiveMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ jobIds }: { jobIds: string[] }) => {
      return bulkArchive(jobIds)
    },
    onSuccess: async (_, variables) => {
      await Promise.all(
        variables.jobIds.map((jobId) =>
          queryClient.invalidateQueries(['jobs', jobId])
        )
      )
    },
  })
}

export function useUnarchiveMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ jobId }: { jobId: string }) => {
      return unarchive(jobId)
    },
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries(['jobs', variables.jobId])
    },
  })
}
