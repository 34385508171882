"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LookupProperty = exports.LookupPropertyDescription = void 0;
const json_schema_keys_1 = require("../../json_schema_keys");
const schema_object_1 = require("../schema_object");
const field_property_description_1 = require("./field_property_description");
class LookupPropertyDescription extends field_property_description_1.DefaultFieldPropertyDescription {
    constructor(field) {
        super(field);
        this[json_schema_keys_1.JsonSchemaKeys.LOOKUP_KEY] = new LookupProperty(field.lookupEntity.entity);
    }
}
exports.LookupPropertyDescription = LookupPropertyDescription;
class LookupProperty extends schema_object_1.SchemaObject {
    constructor(entity) {
        super();
        this[json_schema_keys_1.JsonSchemaKeys.ENTITY_KEY] = entity;
        this[json_schema_keys_1.JsonSchemaKeys.REF_KEY] = json_schema_keys_1.JsonSchemaKeys.IRONSIGHT_REF_KEY;
    }
}
exports.LookupProperty = LookupProperty;
