<template>
  <v-menu :close-on-content-click="false" left offset-y rounded="xl">
    <template #activator="{ on }">
      <vs-button
        :disabled="disabled"
        class="ml-2"
        icon="mdi-tune"
        type="secondary"
        v-on="on"
      >
        <v-icon>mdi-tune</v-icon>
      </vs-button>
    </template>

    <v-list flat>
      <v-subheader class="d-flex justify-space-between">
        Status
        <select-all-toggle
          :selected-item-count="selectedFilters.length"
          :total-item-count="filterOptions.length"
          @toggle="toggleAll"
        />
      </v-subheader>

      <v-list-item-group v-model="model" multiple>
        <v-list-item v-for="{ id, name, count } in filterOptions" :key="id">
          <template #default="{ active }">
            <v-list-item-action>
              <v-checkbox :input-value="active"></v-checkbox>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ name }} ({{ count }})</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import SelectAllToggle from '@/components/common/SelectAllToggle'

export default {
  name: 'ProjectFilter',
  components: { SelectAllToggle },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    filterOptions: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    model: {
      get() {
        return this.selectedFilters
      },
      set(value) {
        this.$emit(
          'input',
          this.filterOptions.filter((_, i) => value.includes(i))
        )
      },
    },
    selectedFilters() {
      return this.value.map((f) =>
        this.filterOptions.findIndex((o) => o.id === f.id)
      )
    },
  },

  methods: {
    toggleAll(isAllSelected) {
      if (isAllSelected) {
        this.model = []
      } else {
        this.model = this.filterOptions.map((_, i) => i)
      }
    },
  },
}
</script>
