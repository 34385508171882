<template>
  <vs-wrapper space="small">
    <v-checkbox
      mandatory
      :input-value="field.isMultiline"
      label="Multi line text input"
      @change="toggleMultiline"
    ></v-checkbox>
  </vs-wrapper>
</template>

<script>
import VsWrapper from '@/components/vision/VsWrapper'
export default {
  name: 'TextEntryFieldSettings',
  components: { VsWrapper },

  props: {
    field: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    toggleMultiline() {
      this.emitChanges('isMultiline', !this.field.isMultiline)
    },
    emitChanges(propertyKey, value) {
      this.$emit('modify-field', {
        propertyKey,
        fieldValue: value,
        key: this.field.uniqueKey(),
      })
    },
  },
}
</script>

<style scoped></style>
