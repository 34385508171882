"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IfConditionModel = void 0;
const json_schema_keys_1 = require("../../json_schema_keys");
const schema_object_1 = require("../schema_object");
class IfConditionModel extends schema_object_1.SchemaObject {
    constructor(properties) {
        super();
        this.type = json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_OBJECT;
        this[json_schema_keys_1.JsonSchemaKeys.$SCHEMA_KEY] = json_schema_keys_1.JsonSchemaKeys.SCHEMA_DRAFT_7_URL;
        this.properties = properties;
        this.required = Object.keys(this.properties);
    }
}
exports.IfConditionModel = IfConditionModel;
