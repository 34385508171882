<template>
  <vs-wrapper>
    <vs-heading type="subhead">Select from existing projects</vs-heading>

    <vs-text-input
      label="Search by project name"
      :value="search"
      @input="handleSearch"
    >
      <template #append>
        <v-icon>search</v-icon>
      </template>
    </vs-text-input>

    <v-progress-circular
      v-if="loading"
      class="ma-auto"
      :size="64"
      :width="6"
      indeterminate
      color="primary"
    />

    <v-list v-else-if="pageProjects.length > 0" class="projects-list">
      <v-list-item-group :value="projectPageIndex">
        <template v-for="(project, i) in pageProjects">
          <v-list-item v-if="project" :key="i" @click="select(project.id)">
            <v-list-item-content>
              <vs-text>{{ project.displayName }}</vs-text>

              <created-info
                :creator-sid="project.createdBy"
                :created-time="project.created"
                text-type="subdued"
              />
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>

    <div v-else class="text-center">
      <vs-text v-if="!search" type="caption">No projects found</vs-text>
      <vs-text v-else type="caption"
        >No results for <em>{{ search }}</em></vs-text
      >
    </div>
  </vs-wrapper>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import CreatedInfo from '../common/CreatedInfo.vue'
import VsWrapper from '@/components/vision/VsWrapper.vue'
import VsHeading from '@/components/vision/VsHeading.vue'
import VsText from '@/components/vision/VsText.vue'
import VsTextInput from '@/components/vision/Inputs/VsTextInput.vue'

export default {
  components: { VsTextInput, VsText, VsHeading, VsWrapper, CreatedInfo },
  props: {
    ownerId: {
      type: String,
      required: true,
    },
    selectedProjectId: {
      type: String,
      required: false,
      default: () => null,
    },
  },
  computed: {
    ...mapState('projects', ['loading', 'error', 'search']),
    ...mapGetters({
      getPageProjects: 'projects/getPageProjects',
    }),
    pageProjects() {
      return this.getPageProjects(this.ownerId)
    },
    projectPageIndex() {
      return this.pageProjects.findIndex((p) => p.id === this.selectedProjectId)
    },
  },
  mounted() {
    this.fetchProjects()
  },
  beforeDestroy() {
    this.updateSearch('')
    this.updatePage(1)
  },
  methods: {
    ...mapActions('projects', ['fetchProjects']),
    ...mapMutations('projects', {
      updateSearch: 'UPDATE_SEARCH',
      updatePage: 'UPDATE_CURRENT_PAGE',
    }),
    select(projectId) {
      this.$emit('select', projectId)
    },
    handleSearch(search) {
      this.updateSearch(search)
      this.updatePage(1)
    },
  },
}
</script>

<style>
.projects-list {
  margin: 0 -16px;
}
</style>
