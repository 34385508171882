<script>
import Vue from 'vue'
import SubmissionPrimitiveField from '@/components/forms/submission-fields/SubmissionPrimitiveField'
import SubmissionArrayField from '@/components/forms/submission-fields/SubmissionArrayField'
import SubmissionObjectField from '@/components/forms/submission-fields/SubmissionObjectField'
import SubmissionOverlineText from '@/components/forms/submission-fields/SubmissionOverlineText'
import SubmissionAttachmentField from '@/components/forms/submission-fields/SubmissionAttachmentField'
import SubmissionLatLngField from '@/components/forms/submission-fields/SubmissionLatLngField'
import VsWrapper from '@/components/vision/VsWrapper.vue'
import { VRow, VCol } from 'vuetify/lib'
import { isEmpty } from 'lodash'

export default Vue.component('SubmissionField', {
  props: {
    flattenedFields: {
      type: Object,
      required: true,
    },
    submissionData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    shouldRenderTitle(title) {
      return !!(title && title.length > 0)
    },
  },
  render: function (createElement) {
    const fields = []

    for (const [fieldKey, fieldProperties] of Object.entries(
      this.flattenedFields
    )) {
      const fieldValue = this.submissionData[fieldKey]

      if (fieldValue === null || fieldValue === undefined) {
        continue
      }
      let field

      if (fieldProperties.dataType === 'array') {
        field = createElement(SubmissionArrayField, {
          props: {
            children: fieldProperties.children,
            lookup: fieldProperties.lookup,
            format: fieldProperties.format,
            values: fieldValue,
          },
        })
      } else if (fieldProperties.dataType === 'object') {
        field = createElement(SubmissionObjectField, {
          props: {
            children: fieldProperties.children,
            value: fieldValue,
          },
        })
      } else {
        if (fieldProperties.format === 'data-url') {
          field = createElement(SubmissionAttachmentField, {
            props: {
              lookup: fieldProperties.lookup,
              format: fieldProperties.format,
              value: fieldValue,
            },
          })
        } else if (fieldProperties.format === 'point') {
          field = createElement(SubmissionLatLngField, {
            props: {
              lookup: fieldProperties.lookup,
              format: fieldProperties.format,
              value: fieldValue,
            },
          })
        } else {
          field = createElement(SubmissionPrimitiveField, {
            props: {
              lookup: fieldProperties.lookup,
              format: fieldProperties.format,
              value: fieldValue,
            },
          })
        }
      }

      if (this.shouldRenderTitle(fieldProperties.title)) {
        if (
          fieldProperties.dataType === 'object' ||
          (fieldProperties.dataType === 'array' &&
            !isEmpty(fieldProperties.children)) ||
          fieldProperties.format === 'data-url' ||
          fieldProperties.format === 'point'
        ) {
          field = createElement(
            SubmissionOverlineText,
            {
              props: {
                title: fieldProperties.title,
              },
            },
            [field]
          )
        } else {
          const title = createElement(SubmissionOverlineText, {
            props: {
              title: fieldProperties.title,
            },
          })

          const formatTitle = createElement(VCol, [title])

          const formatField = createElement(VCol, [field])
          field = createElement(VRow, [formatTitle, formatField])
        }
      }
      fields.push(field)
    }

    return createElement(VsWrapper, fields)
  },
})
</script>

<style scoped></style>
