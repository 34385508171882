<script>
import Vue from 'vue'
import { orderProperties } from '@/utils/forms/form-resolver'
import { ContextualizedFormResolver } from '@/utils/forms/contextualized-form-resolver'
import { VRow } from 'vuetify/lib'

export default Vue.component('ObjectField', {
  name: 'ObjectField',
  props: {
    description: String,
    errorSchema: Object,
    businessUnitId: String,
    formData: Object,
    params: Object,
    rootSchema: Object,
    schema: Object,
    title: String,
    uiSchema: Object,
    formResolver: {
      type: ContextualizedFormResolver,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isRequired() {
      const schema = this.schema
      return (
        Array.isArray(schema.required) && schema.required.indexOf(name) !== -1
      )
    },
  },
  mounted() {
    // This will give nested objects a default value of the empty object.
    // Required for AJV to properly validate any required fields inside
    if (this.formData === undefined) {
      this.$emit('change', {})
    }
  },
  render: function (createElement) {
    const schema = this.formResolver.retrieveSchema(
      this.schema,
      this.rootSchema,
      this.formData
    )

    const properties = Object.keys(schema.properties || {})
    const orderedProperties = orderProperties(
      properties,
      this.uiSchema['ui:order']
    )

    const children = orderedProperties.map((name) => {
      return createElement('schema-field', {
        props: {
          schema: schema.properties[name],
          uiSchema: this.uiSchema[name],
          rootSchema: this.rootSchema,
          formData: (this.formData || {})[name],
          errorSchema: this.errorSchema ? this.errorSchema[name] : {},
          required: schema.required
            ? schema.required.indexOf(name) >= 0
            : false,
          businessUnitId: this.businessUnitId,
          formResolver: this.formResolver,
          disabled: this.disabled,
        },
        on: {
          change: (value) => {
            const formData = { ...this.formData, [name]: value }
            this.$emit('change', formData)
          },
        },
      })
    })

    if (this.description) {
      children.unshift(
        createElement(
          'div',
          {
            attrs: {
              class: 'hint',
            },
          },
          [
            createElement(
              'vs-text',
              {
                props: {
                  type: 'subdued',
                },
              },
              this.description
            ),
          ]
        )
      )
    }

    if (this.title) {
      const headingType = this.params?.headingType || 'subhead'
      const instanceNumber = this.params?.instanceNumber
      const title = `${this.title}${instanceNumber ? ` ${instanceNumber}` : ''}`

      const heading = createElement(
        'vs-heading',
        {
          attrs: {
            'data-test': 'object-field-title',
          },
          props: {
            type: headingType,
          },
        },
        title
      )

      const row = createElement(
        VRow,
        {
          class: 'typical-field-height',
          props: {
            align: 'center',
            'no-gutters': true,
          },
        },
        [heading]
      )

      children.unshift(row)
    }

    return createElement('vs-wrapper', children)
  },
})
</script>
