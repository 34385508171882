import { computed } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { useStore, useGetters } from 'vuex-composition-helpers'
import { useFormatting } from './useFormatting'
import { notEmpty } from '../utils/notEmpty'
import { getIconForChange } from '../utils/getIconForChange'
import { eventIsUnapproval } from '../utils/eventIsUnapproval'
import JobHistory from '@/models/job-history'

export function useFormattedChanges(props: {
  date: string | number
  changes: JobHistory[]
  jobStart?: object | null
  jobEnd?: object | null
  jobHubId: string
}) {
  const { getBySid: getUserBySid } = useGetters('users', ['getBySid'])
  const getUserName = (userSid: string) => {
    const userName = getUserBySid.value(userSid)?.name
    return userName ? `by ${userName}` : ''
  }

  const { isHostOrganizationUser, getOrganizationId: getUserOrgId } =
    useGetters('permissions', ['isHostOrganizationUser', 'getOrganizationId'])
  const store = useStore()

  const getBusinessUnitById = computed(
    () => store.getters['businessUnits/getBusinessUnitById']
  )

  const getOrganizationName = (id: string) => {
    return getBusinessUnitById.value(id)?.name ?? 'Unknown company'
  }

  const {
    formatPlannedTimeChange,
    formatActiveTimeChange,
    formatImportantField,
    formatSecondaryFieldName,
    formatSecondaryFieldsDisplay,
    formatType,
  } = useFormatting(props)

  const formattedDate = computed(() => {
    const today = moment().format('MMM DD, YYYY')
    const yesterday = moment().subtract(1, 'day').format('MMM DD, YYYY')
    if (props.date === yesterday) {
      return `YESTERDAY · ${props.date}`
    } else if (props.date === today) {
      return `TODAY · ${props.date}`
    } else {
      return props.date
    }
  })

  const formattedChanges = computed(() => {
    const datetimeMinValue = '0001-01-01T00:00:00'

    return props.changes
      .map((change) => {
        // Extract time changes
        const startTimeChange = change.fieldsChanged.find(
          (field) =>
            field.columnName === 'start_time' &&
            field.originalValue !== datetimeMinValue
        )
        const endTimeChange = change.fieldsChanged.find(
          (field) =>
            field.columnName === 'end_time' &&
            field.originalValue !== datetimeMinValue
        )
        const activeTimeChange = change.fieldsChanged.find(
          (field) =>
            field.columnName === 'active_time' &&
            field.originalValue !== datetimeMinValue &&
            field.originalValue !== null
        )
        const completedTimeChange = change.fieldsChanged.find(
          (field) =>
            field.columnName === 'completed_time' &&
            field.originalValue !== datetimeMinValue &&
            field.originalValue !== null
        )

        // Format important fields
        const importantFields = [
          ...change.fieldsChanged
            .filter((field) => field.isImportant)
            .map((field) => formatImportantField(field, change)),
          change.type === 'reopen'
            ? null
            : formatPlannedTimeChange(props, startTimeChange, endTimeChange),
          change.type === 'reopen'
            ? null
            : formatActiveTimeChange(activeTimeChange, completedTimeChange),
        ].filter(notEmpty)

        // Format secondary fields
        const secondaryFieldsChanged = change.fieldsChanged
          .filter((field) => !field.isImportant)
          .map((field) => formatSecondaryFieldName(field.columnName))
          .filter(notEmpty)

        let secondaryFields = formatSecondaryFieldsDisplay(
          secondaryFieldsChanged
        )

        // Handle unapproval events
        if (eventIsUnapproval(change)) {
          change.type = 'unapproved'
        }

        // Handle availability events
        if (
          ['availability_accepted', 'availability_declined'].includes(
            change.type
          )
        ) {
          const orgId = getUserBySid.value(change.modifiedBy).organizationId

          if (!isHostOrganizationUser && orgId !== getUserOrgId) {
            return null
          }

          secondaryFields = `On behalf of ${getOrganizationName(orgId)}`
        }

        if (change.type === 'trigger_threshold_resolved') {
          secondaryFields = `Level dropped by ${Math.round(
            change.fieldsChanged[0].originalValue.max -
              change.fieldsChanged[0].originalValue.min
          )} bbls`
        }

        if (change.type === 'item_received_at_location') {
          secondaryFields = `Received ${Math.round(
            change.fieldsChanged[0].originalValue.quantity
          )} bbls`
        }

        // Adjust change type for modified events
        let changeType = change.type
        if (changeType === 'modified') {
          const pendingApprovalFieldChange = change.fieldsChanged.find(
            (f) => f.columnName === 'pending_approval'
          )
          if (pendingApprovalFieldChange) {
            if (
              pendingApprovalFieldChange.originalValue &&
              !pendingApprovalFieldChange.newValue
            ) {
              changeType = 'approval_request_cancelled'
            }
          }
        }

        return {
          importantFields,
          secondaryFields,
          iconInfo: getIconForChange(changeType),
          modified: change.modified,
          modifiedDisplay: change.modified.format('HH:mm'),
          modifiedBy: getUserName(change.modifiedBy),
          type: formatType(changeType, change.modifiedBy, change.fieldsChanged),
          uuid: uuidv4(),
        }
      })
      .filter(notEmpty)
  })

  return {
    formattedDate,
    formattedChanges,
  }
}
