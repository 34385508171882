
import { mapState as mapPiniaState, mapActions as mapPiniaActions } from 'pinia'
import { useScheduleFilterStore } from '../components/schedule/ScheduleFilterStore'
import { useJobFilterStore } from '../components/field-view-next/JobsFilterStore'
import { computed, ComputedRef, defineComponent } from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Axios from 'axios'
import { debounce, sortBy } from 'lodash'
import moment from 'moment'

import {
  SET_SELECTED_RESOURCE_TYPE_ID,
  SET_WEEK,
} from '@/store/modules/week-view'

import WeekHeader from '@/components/weekly-schedule/WeekHeader.vue'
import ResourceTypeSelector from '@/components/schedule/ResourceTypeSelector.vue'
import ResourceTypeSchedule from '@/components/weekly-schedule/ResourceTypeSchedule.vue'
import WeekSelector from '@/components/weekly-schedule/calendar/WeekSelector.vue'
import RightDrawer from '@/components/common/RightDrawer.vue'
import EmptyState from '@/components/common/EmptyState.vue'
import VsSnackbar from '@/components/vision/VsSnackbar.vue'
import {
  canGroup,
  ContextMenuContext,
  getDaysForPeriod,
  Item,
  tryExtractWeekStart,
} from '@/components/weekly-schedule/utils'

import Job from '@/models/job'
import ResourceType from '@/models/resource_type'
import { UnitNumber } from '@/models/unit_number'
import Company from '@/models/company'
import ContextMenu from '@/components/weekly-schedule/ContextMenu.vue'
import { useGetJobFilterOptions } from '@/api/filter-options'
import { until } from '@vueuse/core'

function groupIsDraggable(jobs: Item[]) {
  return !jobs.some((job) => job.isCompleted || job.isCancelled)
}

export default defineComponent({
  name: 'WeekView',

  components: {
    ContextMenu,
    ResourceTypeSchedule,
    WeekHeader,
    ResourceTypeSelector,
    WeekSelector,
    RightDrawer,
    EmptyState,
    VsSnackbar,
  },

  setup() {
    const { data: filterOptions, isFetched: isFetchedFilterOptions } =
      useGetJobFilterOptions()
    return { filterOptions, isFetchedFilterOptions }
  },

  provide(): {
    isDragging: ComputedRef<boolean>
    selectedJobs: ComputedRef<Item[]>
  } {
    return {
      isDragging: computed(() => this.isDragging),
      selectedJobs: computed(() => this.selectedJobs),
    }
  },

  beforeRouteUpdate(to, _from, next) {
    if (to.name === 'week-view') {
      const weekStart = tryExtractWeekStart(
        to.params as unknown as { year: number; month: number; day: number }
      )
      if (weekStart) {
        this.selectWeek([weekStart, moment(weekStart).endOf('week')])
      }
    }
    next()
  },

  data(): {
    showContextMenu: boolean
    contextMenuContext: ContextMenuContext | null
    isDragging: boolean
    isDraggingGroup: boolean
    observer: ResizeObserver | null
    fetch: () => void
    selection: number[]
    savingJob: number | null
    snackbar: {
      timeout: 6000
      messageKey: string | null
      showActions: true
      type?: 'error'
      messages: Record<string, string>
    }
    isSnackbarVisible: boolean
  } {
    return {
      showContextMenu: false,
      contextMenuContext: null,
      isDragging: false,
      isDraggingGroup: false,
      observer: null,
      fetch: () => {},
      selection: [],
      savingJob: null,
      snackbar: {
        timeout: 6000,
        messageKey: null,
        showActions: true,
        messages: {
          invalidServiceProviderError:
            'Cannot assign job to a company with no dispatcher/admin',
          changeResourceType: 'Cannot drop to a different resource type',
          changeCompletedOrCancelledJob:
            'Cannot change a completed/cancelled job',
          groupDragFinishedJobError:
            'Cannot change an assignment group with completed/cancelled job(s)',
        },
      },
      isSnackbarVisible: false,
    }
  },

  computed: {
    ...mapPiniaState(useScheduleFilterStore, {
      showOfflineUnits: 'showOfflineUnits',
      showStandbyUnits: 'showStandbyUnits',
      showUnitsWithNoAssignedJobs: 'showUnitsWithNoAssignedJobs',
    }),
    ...mapPiniaState(useJobFilterStore, {
      selectedDivisions: 'selectedDivisions',
      selectedResourceTypes: 'selectedResourceTypes',
      selectedServiceProviders: 'selectedServiceProviders',
    }),
    ...mapGetters('tickets', { getShouldNavigate: 'getShouldNavigate' }),
    ...mapGetters('users', {
      getDispatchersOrAdminsInOrganization:
        'getDispatchersOrAdminsInOrganization',
    }),
    ...mapGetters('jobs', {
      jobs: 'getNonArchivedJobs',
    }),
    ...mapState('weekView', {
      week: 'week',
      selectedResourceTypeId: 'selectedResourceTypeId',
    }),
    ...mapState('resourceTypes', {
      resourceTypes: 'all',
    }),
    ...mapState('featureFlags', {
      isGroupingEnabled: (state) => state.all.jobAssignmentGroups,
    }),

    filters() {
      return {
        selectedResourceTypeIds: this.selectedResourceTypes,
        selectedDivisionIds: this.selectedDivisions,
        selectedCompanyIds: this.selectedServiceProviders,
        showOfflineUnits: this.showOfflineUnits,
        showStandbyUnits: this.showStandbyUnits,
        showUnitsWithNoAssignedJobs: this.showUnitsWithNoAssignedJobs,
      }
    },

    selectedResourceType(): ResourceType | null {
      const resourceTypes = this.resourceTypes as Record<number, ResourceType>
      if (
        this.selectedResourceTypeId &&
        this.filters.selectedResourceTypeIds.includes(
          this.selectedResourceTypeId
        ) &&
        resourceTypes[this.selectedResourceTypeId as number]
      ) {
        return resourceTypes[this.selectedResourceTypeId as number]
      }

      return null
    },

    jobsForWeek(): Job[] {
      const isInRange = (j) => {
        return (
          j.displayedStartTime.isSameOrBefore(
            this.weekDays[this.weekDays.length - 1]?.date,
            'day'
          ) && j.displayedEndTime.isSameOrAfter(this.weekDays[0]?.date, 'day')
        )
      }
      const assignmentGroupIsInRange = (j) => {
        const jobsInAssignmentGroup = this.jobs.filter(
          (gj) => gj.assignmentGroupId === j.assignmentGroupId
        )
        return jobsInAssignmentGroup.some((gj) => isInRange(gj))
      }
      return this.jobs.filter(
        (j) =>
          j.resourceTypeId === this.selectedResourceTypeId &&
          (isInRange(j) || (j.assignmentGroupId && assignmentGroupIsInRange(j)))
      )
    },

    jobsForResourceType(): Item[] {
      const self = this
      return this.jobsForWeek
        .filter((j) => j.resourceTypeId === this.selectedResourceTypeId)
        .map((j) => ({
          ...j,
          get isSelected() {
            return self.selection.includes(this.id)
          },
          get isBeingDragged() {
            return this.isSelected && self.isDragging
          },
          isSaving: this.savingJob ? this.savingJob === j.id : false,
          displayedStartTime: j.displayedStartTime,
          displayedEndTime: j.displayedEndTime,
          currentActiveTime: j.currentActiveTime,
          pausedDuration: j.pausedDuration,
          actualActiveDuration: j.actualActiveDuration,
          estimatedDuration: j.estimatedDuration,
          isCompleted: j.isCompleted,
          isCancelled: j.isCancelled,
        }))
    },

    selectedJobs(): Item[] {
      return this.selection.map((id) => this.jobs.find((j) => j.id === id))
    },

    contextIsSelected() {
      return (
        !this.contextMenuContext ||
        this.contextMenuContext.data.every((j) => this.selection.includes(j.id))
      )
    },

    hasSelection() {
      return this.selection.length > 0
    },

    contextMenuActions() {
      if (!this.isGroupingEnabled) {
        return []
      }

      if (this.contextMenuContext === null) {
        return []
      }

      const actions: {
        title: string
        action(context: ContextMenuContext): void | Promise<void>
      }[] = []

      if (!this.hasSelection) {
        actions.push({
          title: `Select ${
            this.contextMenuContext?.type === 'job' ? 'job' : 'group'
          }`,
          action: (context) => {
            this.selection.push(...context.data.map((j) => j.id))
          },
        })
      }

      if (this.hasSelection && !this.contextIsSelected) {
        actions.push({
          title: 'Add to selection',
          action: (context) => {
            this.selection.push(...context.data.map((j) => j.id))
          },
        })
      }

      if (canGroup(this.selectedJobs)) {
        actions.push({ title: 'Group', action: this.groupSelection })
      }

      if (
        this.contextMenuContext.type === 'group' &&
        ((this.contextIsSelected &&
          this.selection.length === this.contextMenuContext.data.length) ||
          !this.hasSelection)
      ) {
        actions.push({ title: 'Ungroup', action: this.deleteGroup })
      }

      if (
        this.contextMenuContext.type === 'job' &&
        this.contextMenuContext.data[0].assignmentGroupId &&
        this.selection.length <= 1
      ) {
        actions.push({
          title: 'Remove from group',
          action: (context) => this.removeJobFromGroup(context.data[0]),
        })
      }

      if (this.hasSelection) {
        actions.push({ title: 'Clear selection', action: this.clearSelection })
      }

      return actions
    },

    showRightDrawer() {
      return this.$route.name !== 'week-view'
    },

    showBottomDrawer() {
      return this.$route.name === 'svwv-unit-edit-view'
    },

    weekDays() {
      return getDaysForPeriod(this.week[0], this.week[1])
    },
  },

  watch: {
    week() {
      this.fetch()
    },
    selectedResourceTypeId() {
      this.fetch()
    },
  },

  created() {
    moment.locale('en')
    this.fetch = debounce(this._fetch, 500)

    const fromRoute = tryExtractWeekStart(
      this.$route.params as unknown as {
        year: number
        month: number
        day: number
      }
    )

    if (fromRoute) {
      this.selectWeek([fromRoute, moment(fromRoute).endOf('week')])
      this.setLastViewedSchedule(
        `week/${this.$route.params.year}/${this.$route.params.month}/${this.$route.params.day}`
      )
    } else {
      this.fetch()
      this.setLastViewedSchedule('week')
    }
  },

  mounted() {
    document.addEventListener('click', this.clickOutside)

    const element = document.getElementById('schedule-view')
    this.observer = new ResizeObserver(this.syncSizes)
    if (element) {
      this.observer.observe(element)
    }
  },

  beforeDestroy() {
    document.removeEventListener('click', this.clickOutside)
    this.stopPollingJobs()
    this.observer?.disconnect()
  },

  methods: {
    ...mapPiniaActions(useJobFilterStore, {
      updateDivision: 'updateDivision',
      updateServiceProvider: 'updateServiceProvider',
      updateResourceType: 'updateResourceType',
    }),
    ...mapPiniaActions(useScheduleFilterStore, {
      updateShowStandbyUnits: 'updateShowStandbyUnits',
      updateShowOfflineUnits: 'updateShowOfflineUnits',
      setLastViewedSchedule: 'updateLastViewedSchedule',
    }),
    ...mapActions('jobs', {
      startPollingJobs: 'startPolling',
      stopPollingJobs: 'stopPolling',
      scheduleJob: 'scheduleJob',
      rescheduleGroup: 'rescheduleGroup',
      groupJobs: 'groupJobs',
      deleteAssignmentGroup: 'deleteAssignmentGroup',
      addJobToAssignmentGroup: 'addJobToAssignmentGroup',
      removeJobFromAssignmentGroup: 'removeJobFromAssignmentGroup',
    }),
    ...mapMutations('weekView', {
      selectWeek: SET_WEEK,
      selectResourceType: SET_SELECTED_RESOURCE_TYPE_ID,
    }),

    syncSizes() {
      const topRow = document.getElementById('schedule-view-top-row')
      const header = document.getElementById('calendar-header')

      if (topRow) {
        const width = topRow.getBoundingClientRect().width
        header?.setAttribute('style', `width:${width}px`)
      } else {
        header?.setAttribute('style', `width:100%`)
      }
    },

    showSnackbarError(messageKey: string, message?: string) {
      this.snackbar.type = 'error'
      this.snackbar.messageKey = messageKey
      if (message) {
        this.snackbar.messages = {
          ...this.snackbar.messages,
          [messageKey]: message,
        }
      }
      this.isSnackbarVisible = true
    },

    _fetch() {
      this.startPollingJobs({
        start: moment(this.week[0]),
        end: moment(this.week[1]),
        resourceTypes: this.selectedResourceTypeId
          ? [this.selectedResourceTypeId]
          : [],
      })
    },

    navigateToWeek(range) {
      const year = range[0].year()
      const month = range[0].month() + 1 // month is 0 indexed
      const day = range[0].date()

      this.$router.push({
        name: 'week-view',
        params: {
          year,
          month,
          day,
        },
      })
      this.setLastViewedSchedule(`week/${year}/${month}/${day}`)
    },

    resetAllFilters() {
      if (!this.isFetchedFilterOptions) return
      // reset divisions
      this.updateDivision(
        this.filterOptions?.jobFilterOptions.divisionOptions.map((d) => d.id) ||
          []
      )

      // reset resource types
      this.updateResourceType(
        this.filterOptions?.jobFilterOptions.resourceTypeOptions.map(
          (d) => d.id
        ) || []
      )
      // reset service providers

      this.updateServiceProvider(
        this.filterOptions?.jobFilterOptions.serviceProviderOptions.map(
          (d) => d.id
        ) || []
      )

      this.updateShowStandbyUnits(true)
      this.updateShowOfflineUnits(true)
    },

    clearSelection() {
      this.selection = []
      this.closeContextMenu()
    },

    openJobContextMenu(job: Item, event: MouseEvent) {
      this.openContextMenu(event, 'job', [job])
    },

    openGroupContextMenu(groupNumber: number, jobs: Item[], event: MouseEvent) {
      this.openContextMenu(event, 'group', jobs)
    },

    selectJob(job: Item, addToSelection?: boolean, event?: MouseEvent) {
      this.closeContextMenu()
      if (!addToSelection) {
        this.clearSelection()
      }
      const existingIndex = this.selection.indexOf(job.id)
      if (existingIndex >= 0) {
        this.selection.splice(existingIndex, 1)
      } else {
        this.selection.push(job.id)
        if (event) {
          this.openContextMenu(event, 'job', [job])
        }
      }
    },

    handleGroupSelected(groupNumber: number, jobs: Item[], event: MouseEvent) {
      this.closeContextMenu()

      const isAlreadySelected = jobs.every((j) => j.isSelected)
      if (isAlreadySelected) {
        for (const job of jobs) {
          const existingIndex = this.selection.indexOf(job.id)
          if (existingIndex >= 0) {
            this.selection.splice(existingIndex, 1)
          }
        }
      } else {
        this.selection = [...this.selection, ...jobs.map((j) => j.id)]
        this.openContextMenu(event, 'group', jobs)
      }
    },

    clickOutside(event: MouseEvent) {
      if (event.button === 2 || event.metaKey) {
        return
      }
      if (this.showContextMenu) {
        this.closeContextMenu()
        event.stopPropagation()
      } else {
        this.clearSelection()
      }
    },

    handleEsc() {
      if (this.showContextMenu) {
        this.closeContextMenu()
      } else {
        this.clearSelection()
      }
    },

    openContextMenu(event: MouseEvent, type: 'job' | 'group', data: Item[]) {
      this.showContextMenu = true
      const elementPosition = this.$el.getBoundingClientRect()
      this.contextMenuContext = {
        data,
        type,
        position: {
          x: event.clientX - elementPosition.x,
          y: event.clientY - elementPosition.y,
        },
      }
    },

    closeContextMenu() {
      this.showContextMenu = false
      this.contextMenuContext = null
    },

    async openJob(job: Item) {
      if (this.$route.name === 'svwv-ticket-info-panel') {
        await this.pushToWeekView()
      }
      this.selection = [job.id]
      setTimeout(() => this.pushToJobForm(job.id), 350)
    },

    async pushToWeekView() {
      this.clearSelection()
      await this.$router.push({ name: 'week-view' })
    },

    async pushToJobForm(id) {
      await this.$router.push({
        name: 'svwv-ticket-info-panel',
        params: { id },
      })
    },

    handleDragJob(job: Item) {
      if (job.isCancelled || job.isCompleted) {
        return this.showSnackbarError('changeCompletedOrCancelledJob')
      }
      this.isDragging = true
      this.$el.ownerDocument.addEventListener('dragend', this.handleDragEnd)
      this.selectJob(job)
    },

    handleDragGroup(groupNumber: number, jobs: Item[]) {
      if (!groupIsDraggable(jobs)) {
        return this.showSnackbarError('groupDragFinishedJobError')
      }
      this.isDragging = true
      this.isDraggingGroup = true
      this.$el.ownerDocument.addEventListener('dragend', this.handleDragEnd)
      this.clearSelection()
      for (const job of jobs) {
        this.selectJob(job, true)
      }
    },

    handleDragEnd() {
      this.isDragging = false
      this.isDraggingGroup = false
      this.$el.ownerDocument.removeEventListener('dragend', this.handleDragEnd)
    },

    async dropJobOntoGroup(assignmentGroupId: string) {
      try {
        this.isDragging = false
        this.savingJob = this.selection[0]
        this.closeContextMenu()
        await this.addJobToAssignmentGroup({
          assignmentGroupId,
          jobNumber: this.savingJob,
        })
      } catch (e) {
        if (Axios.isAxiosError(e)) {
          this.showSnackbarError('saveError', e.message ?? '')
        }
        throw e
      } finally {
        this.savingJob = null
      }
    },

    async dropJob({
      date,
      type,
      entity,
    }: {
      date: moment.Moment
      type: 'rt' | 'company' | 'unit'
      entity: Company | UnitNumber | ResourceType
    }) {
      const isDraggingGroup = this.isDraggingGroup
      this.isDragging = false
      this.isDraggingGroup = false

      const job = sortBy(this.selectedJobs, (j) => j.displayedStartTime)[0]
      if (!job) return

      try {
        this.savingJob = job.id
        const resource = type === 'unit' ? (entity as UnitNumber) : null
        const serviceProvider = type === 'company' ? (entity as Company) : null

        const isInAssignmentGroup = job.assignmentGroupId !== null
        const noDateChange = job.startTime.isSame(date, 'date')
        const noUnitChange = job.unitId === resource?.id
        const noCompanyChange =
          job.serviceProviderBusinessUnitId ===
          (serviceProvider?.id ?? resource?.organizationId)

        if (
          !isInAssignmentGroup &&
          noDateChange &&
          noUnitChange &&
          noCompanyChange
        )
          return

        if (
          serviceProvider &&
          !this.getDispatchersOrAdminsInOrganization(serviceProvider.id)
        ) {
          this.showSnackbarError('invalidServiceProviderError')
          return
        }

        if (isDraggingGroup) {
          const offset = moment.duration(
            moment(date)
              .startOf('day')
              .diff(moment(job.displayedStartTime).startOf('day'), 'days'),
            'days'
          )
          await this.rescheduleGroup({
            assignmentGroupId: job.assignmentGroupId,
            offset,
            resourceId: resource?.id,
            serviceProviderId: serviceProvider?.id ?? resource?.organizationId,
          })
        } else {
          await this.scheduleJob({
            jobNumber: job.id,
            startDate: date,
            resourceId: resource?.id,
            serviceProviderId: serviceProvider?.id ?? resource?.organizationId,
          })
        }

        await this.fetch()
      } catch (e) {
        if (Axios.isAxiosError(e)) {
          this.showSnackbarError('saveError', e.message ?? '')
        }
        throw e
      } finally {
        this.savingJob = null
        this.clearSelection()
      }
    },

    async groupSelection() {
      try {
        this.savingJob = this.selection[0]
        const jobs = [...this.selection]
        this.closeContextMenu()
        await this.groupJobs(jobs)
      } catch (e) {
        if (Axios.isAxiosError(e)) {
          this.showSnackbarError('saveError', e.message ?? '')
        }
        throw e
      } finally {
        this.savingJob = null
      }
    },

    async deleteGroup() {
      if (!this.contextMenuContext) {
        return
      }
      const assignmentGroupId = this.contextMenuContext.data
        .map((j) => j.assignmentGroupId)
        .filter(Boolean)[0]
      if (!assignmentGroupId) {
        return
      }
      this.selection = this.contextMenuContext.data.map((j) => j.id)
      this.contextMenuContext.type = 'job'
      await this.deleteAssignmentGroup(assignmentGroupId)
    },

    async removeJobFromGroup(job: Item) {
      this.closeContextMenu()
      await this.removeJobFromAssignmentGroup(job.id)
    },
  },
})
