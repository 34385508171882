"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LookupUiSchema = void 0;
const json_schema_keys_1 = require("../../json_schema_keys");
const lookup_entity_1 = require("../lookup_entity");
const field_ui_schema_1 = require("./field_ui_schema");
class LookupUiSchema extends field_ui_schema_1.FieldUiSchema {
    constructor(lookupEntity) {
        super();
        if (lookupEntity instanceof lookup_entity_1.UserLookup) {
            this.widgetType = json_schema_keys_1.JsonSchemaKeys.WIDGET_TYPE_USER_SELECTOR;
        }
        else if (lookupEntity instanceof lookup_entity_1.BusinessUnitLookup) {
            this.widgetType = json_schema_keys_1.JsonSchemaKeys.WIDGET_TYPE_BUSINESS_UNIT_SELECTOR;
        }
        else if (lookupEntity instanceof lookup_entity_1.LocationLookup) {
            this.widgetType = json_schema_keys_1.JsonSchemaKeys.WIDGET_TYPE_LOCATION_SELECTOR;
        }
        else if (lookupEntity instanceof lookup_entity_1.CompanyLookup) {
            this.widgetType = json_schema_keys_1.JsonSchemaKeys.WIDGET_TYPE_COMPANY_SELECTOR;
        }
        else {
            throw new Error(`Lookup of type ${lookupEntity === null || lookupEntity === void 0 ? void 0 : lookupEntity.entity} is not implemnted`);
        }
    }
}
exports.LookupUiSchema = LookupUiSchema;
