const modularResources = [
  'companies',
  'costCenters',
  'divisions',
  'locations',
  'tasks',
]

const monolithicResources = [
  'activities',
  'resourceTypes',
  'services',
  'unitNumbers',
  'users',
]

const getters = {
  modularErrors: (_state, _getters, _rootState, rootGetters) => {
    return modularResources
      .map((resource) => rootGetters[`${resource}/getLoadingError`])
      .filter((error) => !!error)
  },
  resourceStates: (_state, _getters, rootState) => {
    return monolithicResources.map((resource) => rootState[resource])
  },
  monolithicErrors: (_state, getters) => {
    return getters.resourceStates
      .map((state) => state.error)
      .filter((error) => !!error)
  },
  errors: (_state, getters) => {
    return getters.modularErrors.concat(getters.monolithicErrors)
  },
  serverErrors: (_state, getters) => {
    return getters.errors.filter(
      (error) => error.response && error.response.status >= 500
    )
  },
  networkErrors: (_state, getters) => {
    return getters.errors.filter((error) => error.message === 'Network Error')
  },
  connectionErrors: (_state, getters) => {
    return getters.networkErrors.concat(getters.serverErrors)
  },
  sessionExpired: (_state, getters) => {
    return getters.errors.some(
      (error) => error.response && error.response.status === 401
    )
  },
}

export default {
  namespaced: true,
  getters,
}
