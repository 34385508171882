<template>
  <v-container fluid class="schedule-toolbar pa-0 ma-0">
    <v-row no-gutters align="center" class="px-4 pt-3 pb-2 white">
      <v-col>
        <v-row dense align="center">
          <v-col class="shrink">
            <vs-heading type="title">Schedule</vs-heading>
          </v-col>
          <v-col class="shrink pl-3">
            <ScheduleToggle />
          </v-col>
          <v-col class="shrink">
            <ScheduleFiltersMenu />
          </v-col>
          <v-col class="shrink">
            <v-tooltip right close-delay="100">
              <template #activator="{ on }">
                <vs-button
                  small
                  icon="refresh"
                  type="secondary"
                  :loading="loading"
                  v-on="on"
                  @click="refreshJobData"
                />
              </template>
              <span>{{ lastUpdatedTime }}</span>
            </v-tooltip>
          </v-col>
          <v-col v-if="isDayView" class="shrink">
            <vs-button
              :label="queueLabel"
              icon="list_alt"
              small
              type="secondary"
              @click="shouldShowJobsList = !shouldShowJobsList"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col class="shrink">
        <new-job-button :parent-route-short-name="routeShortName" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import { SET_SHOW_JOBS_TABLE } from '@/store/modules/day-view'
import { debounce } from 'lodash'
import moment from 'moment'
import { refreshButtonDebounceDuration } from '../../utils/constants'
import ScheduleFiltersMenu from './ScheduleFiltersMenu.vue'
import ScheduleToggle from './ScheduleToggle'
import NewJobButton from '../common/NewJobButton'

export default {
  components: {
    ScheduleFiltersMenu,
    ScheduleToggle,
    NewJobButton,
  },
  data() {
    return {
      lastUpdatedTime: '',
      interval: null,
    }
  },
  computed: {
    ...mapState('dayView', ['showJobQueue']),
    ...mapState('jobs', ['lastFetch', 'loading']),
    isDayView() {
      return this.$route.path.includes('day')
    },
    shouldShowJobsList: {
      get() {
        return this.showJobQueue
      },
      set(newBoolean) {
        this.setShowJobsList(newBoolean)
      },
    },

    queueLabel() {
      return this.shouldShowJobsList ? 'Hide queue' : 'Show queue'
    },

    routeShortName() {
      return this.isDayView ? 'sv' : 'svwv'
    },
  },

  created() {
    this.updateLastUpdatedTime()
  },

  beforeDestroy() {
    clearInterval(this.interval)
  },

  methods: {
    ...mapMutations('dayView', {
      setShowJobsList: SET_SHOW_JOBS_TABLE,
    }),
    ...mapActions('weekView', ['fetchJobsForWeek']),
    ...mapActions('dayView', ['fetchJobsForDayRange']),

    updateLastUpdatedTime() {
      this.interval = setInterval(() => {
        this.lastUpdatedTime = `Jobs last updated: ${moment(
          this.lastFetch.timestamp ? this.lastFetch.timestamp : moment()
        ).fromNow()}`
      }, 1000)
    },
    refreshJobData() {
      if (this.isDayView) {
        this.refreshJobForDay()
      } else {
        this.fetchJobsForWeek()
      }
    },
    refreshJobForDay: debounce(
      function () {
        this.fetchJobsForDayRange()
      },
      refreshButtonDebounceDuration,
      { leading: true, trailing: false }
    ),
    refreshJobForWeek: debounce(
      function () {
        this.fetchJobsForWeek()
      },
      refreshButtonDebounceDuration,
      { leading: true, trailing: false }
    ),
  },
}
</script>

<style scoped>
.schedule-toolbar {
  border-bottom: 1px solid var(--color-grey--lighter);
}
</style>
