import axios, { AxiosError, AxiosResponse } from 'axios'
import { searchJobs, searchJobsCount } from '@/api/jobs'
import { computed, Ref, ref, unref, UnwrapNestedRefs, watchEffect } from 'vue'
import moment, { isMoment } from 'moment/moment'
import { ACTIVE, CANCELLED, COMPLETED, NO_APPROVAL, PAUSED } from '@/models/job'
import { SearchJobsQuery } from '@/components/jobs/query'
import { useQuery } from '@tanstack/vue-query'
import { JobSearchResponse } from '@/models/types/job-search-response'

type MaybeRef<T> = Ref<T> | T

export function useJobsSearch(
  query: MaybeRef<UnwrapNestedRefs<SearchJobsQuery>>,
  options?: { enabled: boolean }
) {
  const queryParams = computed<object>(() => standardizeQueryParams(query))
  const lastUpdatedTime = ref()
  const lastUpdatedTimeAgo = ref()

  const queryKey = computed(() => ['jobs', unref(query)])

  const {
    data,
    error,
    isLoading,
    isFetched,
    isFetching,
    refetch,
    isRefetching,
  } = useQuery<AxiosResponse, AxiosError, AxiosResponse, any>({
    queryKey,
    queryFn: ({ signal }) => {
      // Create a new CancelToken source for this request
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      const q = unref(query)
      // prevent the query from triggering if any of
      // the filters are empty collections
      if (
        'approvalStatuses' in q &&
        (q.approvalStatuses?.length === 0 ||
          q.divisions?.length === 0 ||
          q.priorities?.length === 0 ||
          q.serviceProviders?.length === 0 ||
          q.resourceTypes?.length === 0 ||
          q.statuses?.length === 0)
      ) {
        return { data: { data: [], meta: { total_pages: 0, total: 0 } } }
      }

      const promise = searchJobs(queryParams.value, source.token)

      // Cancel the request if TanStack Query signals to abort
      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by TanStack Query')
      })

      return promise
    },
    retry: false,
    keepPreviousData: true,
    refetchInterval: moment.duration(30, 'seconds').asMilliseconds(),
    ...options,
  })

  watchEffect(() => {
    if (data.value && !isLoading.value) {
      lastUpdatedTime.value = moment()
    }
  })

  watchEffect(() => {
    setInterval(() => {
      if (lastUpdatedTime.value) {
        lastUpdatedTimeAgo.value = lastUpdatedTime.value.fromNow()
      }
    }, 1000)
  })

  const jobs = computed<JobSearchResponse[]>(() => {
    return Object.freeze(
      (data?.value?.data?.data ?? []).map((j) => {
        return {
          ...j,
          estimatedDuration: moment.duration(
            moment(j.requested_end).diff(moment(j.requested_start))
          ),
          startTime: j.requested_start,
          endTime: j.requested_end,
          approvalStatus: j.approval_status ?? NO_APPROVAL,
          get estimatedCompletedTime() {
            return moment(j.active_time ?? j.requested_start).add(
              this.estimatedDuration
            )
          },
          get isOverdue() {
            return (
              j.status !== COMPLETED &&
              j.status !== CANCELLED &&
              moment(j.requested_start).isBefore()
            )
          },
          get isLate() {
            return (
              (j.status === ACTIVE || j.status === PAUSED) &&
              this.estimatedCompletedTime.isBefore()
            )
          },
        }
      })
    )
  })

  const hasNextPage = computed<boolean>(() => {
    return data?.value?.data?.meta?.has_next_page
  })

  return {
    jobs,
    hasNextPage,
    error,
    isLoading,
    isFetched,
    isFetching,
    isRefetching,
    reFetch: refetch,
    lastUpdatedTimeAgo,
  }
}

export function useJobsSearchCount(
  countQuery: MaybeRef<UnwrapNestedRefs<SearchJobsQuery>>,
  options?: { enabled: boolean }
) {
  const queryParams = computed<object>(() => standardizeQueryParams(countQuery))
  const queryKey = computed(() => ['jobs-count', unref(countQuery)])

  const {
    data: countData,
    error: countError,
    isLoading: countLoading,
    isFetched: countFetched,
    isFetching: countFetching,
    refetch: countRefetch,
    isRefetching: countRefetching,
  } = useQuery<
    AxiosResponse<SearchCount>,
    AxiosError,
    AxiosResponse<SearchCount>,
    any
  >({
    queryKey,
    queryFn: ({ signal }) => {
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()
      const q = unref(countQuery)

      // prevent the query from triggering if any of
      // the filters are empty collections
      if (
        'approvalStatuses' in q &&
        (q.approvalStatuses?.length === 0 ||
          q.divisions?.length === 0 ||
          q.priorities?.length === 0 ||
          q.serviceProviders?.length === 0 ||
          q.resourceTypes?.length === 0 ||
          q.statuses?.length === 0)
      ) {
        return { data: { data: [], meta: { total_pages: 0, total: 0 } } }
      }

      const promise = searchJobsCount(queryParams.value, source.token)

      // Cancel the request if TanStack Query signals to abort
      signal?.addEventListener('abort', () => {
        source.cancel('Job search count query was cancelled by TanStack Query')
      })

      return promise
    },
    retry: false,
    keepPreviousData: true,
    ...options,
  })

  const count = computed<number>(() => {
    return countData.value?.data.count ?? 0
  })

  return {
    count,
    countError,
    countLoading,
    countFetched,
    countFetching,
    countRefetch,
    countRefetching,
  }
}

function standardizeQueryParams(
  query: MaybeRef<UnwrapNestedRefs<SearchJobsQuery>>
) {
  const params = {}
  Object.entries(unref(query))
    .filter(([, v]) => !!v)
    .forEach(([k, v]) => {
      if (isMoment(v)) {
        params[k] = v.toISOString()
      } else if (Array.isArray(v)) {
        params[k] = v
      } else {
        params[k] = v?.toString()
      }
    })
  return params
}

class SearchCount {
  constructor(count: number) {
    this.count = count
  }

  count: number
}
