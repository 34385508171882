<template>
  <div :class="{ 'overflow-hidden': disableOverflow }">
    <transition
      :name="getTransitionName"
      mode="out-in"
      @enter="handleDisableOverflow"
      @after-enter="handleEnableOverflow"
      @leave="handleDisableOverflow"
      @after-leave="handleEnableOverflow"
    >
      <div v-if="!hasChanges" key="action-bar" class="bar-wrapper">
        <slot name="action-bar" />
      </div>

      <v-row
        v-else
        key="save-bar"
        no-gutters
        justify="end"
        align="center"
        class="bar-wrapper"
      >
        <v-col v-if="$listeners['delete']">
          <v-tooltip top>
            <template #activator="{ on }">
              <vs-button
                icon="delete"
                type="tertiary"
                destructive
                data-test="delete-action"
                v-on="on"
                @click="$emit('delete')"
              />
            </template>
            Delete
          </v-tooltip>
        </v-col>

        <v-col v-if="hasChanges && $listeners['save']" class="mr-4">
          <vs-text type="subdued" data-test="unsaved-message">
            {{ changesLabel }}
          </vs-text>
        </v-col>

        <v-col v-if="hasChanges && $listeners['discard']" class="shrink mr-2">
          <vs-button
            type="secondary"
            :label="discardLabel"
            data-test="discard-action"
            @click="$emit('discard')"
          />
        </v-col>

        <v-col
          v-if="hasChanges && $listeners['save'] && !hideSaveButton"
          class="shrink"
        >
          <vs-button
            data-test="save-action"
            :loading="isSaving"
            :label="saveLabel"
            @click="$emit('save')"
          />
        </v-col>
      </v-row>
    </transition>
  </div>
</template>

<script>
import VsText from '@/components/vision/VsText.vue'
import VsButton from '@/components/vision/VsButton.vue'

export default {
  components: { VsText, VsButton },
  props: {
    saveLabel: {
      type: String,
      default: 'Save',
    },
    discardLabel: {
      type: String,
      default: 'Discard',
    },
    changesLabel: {
      type: String,
      default: 'You have unsaved changes',
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
    hasChanges: {
      type: Boolean,
      default: false,
    },
    hideSaveButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      disableOverflow: false,
    }
  },
  computed: {
    getTransitionName() {
      return this.$slots['action-bar'] && !this.hasChanges
        ? 'slide-fade-down'
        : 'slide-fade-up'
    },
  },
  methods: {
    handleEnableOverflow() {
      this.disableOverflow = false
    },
    handleDisableOverflow() {
      this.disableOverflow = true
    },
  },
}
</script>

<style scoped>
.bar-wrapper {
  padding: var(--space-small) var(--space-large);
}

.slide-fade-up-enter-active,
.slide-fade-up-leave-active,
.slide-fade-down-enter-active,
.slide-fade-down-leave-active {
  transform: translateY(0);
  transition: all 100ms;
  will-change: transform;
}

.slide-fade-up-enter,
.slide-fade-up-leave-to,
.slide-fade-down-enter,
.slide-fade-down-leave-to {
  opacity: 0;
}

.slide-fade-up-enter {
  transform: translateY(var(--space-small));
}

.slide-fade-up-leave-to {
  transform: translateY(calc(var(--space-small) * -1));
}

.slide-fade-down-enter {
  transform: translateY(calc(var(--space-small) * -1));
}

.slide-fade-down-leave-to {
  transform: translateY(var(--space-small));
}
</style>
