import { render, staticRenderFns } from "./CreatableSelectorField.vue?vue&type=template&id=067a71cf&scoped=true&"
import script from "./CreatableSelectorField.vue?vue&type=script&lang=js&"
export * from "./CreatableSelectorField.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "067a71cf",
  null
  
)

export default component.exports