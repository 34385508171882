<template>
  <vs-wrapper>
    <vs-autocomplete
      data-test="generic-selector-field"
      :items="items"
      :label="title"
      :hint="description"
      :value="formData"
      :error-messages="errorMessages"
      :disabled="disabled"
      @input="$emit('change', $event)"
    />
  </vs-wrapper>
</template>

<script>
export default {
  name: 'GenericSelectorField',
  props: {
    errorSchema: {
      type: Object,
      default: () => {},
    },
    formData: {
      type: [String, Number],
      default: null,
    },
    params: {
      type: Object,
      default: () => {},
    },
    rootSchema: {
      type: Object,
      default: () => {},
    },
    schema: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    uiSchema: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    items() {
      let items = []

      if (this.schema.enum) {
        items = this.schema.enum.map((choice) => {
          return { text: choice, value: choice }
        })
      } else if (this.schema.oneOf) {
        items = this.schema.oneOf.map((choice) => {
          return { text: choice.title, value: choice.const }
        })
      } else if (this.uiSchema['ui:options']) {
        this.uiSchema['ui:options'].groups.forEach((group) => {
          items.push({ header: group.title })

          group.items.forEach((choice) => {
            items.push({ text: choice, value: choice })
          })
        })
      }

      return items
    },
    errorMessages() {
      const errors = this.errorSchema?.errors ?? []
      if (
        this.formData &&
        typeof this.formData !== 'number' &&
        typeof this.formData !== 'string'
      ) {
        errors.push('Field value expected.')
      }
      return errors
    },
  },
}
</script>

<style scoped></style>
