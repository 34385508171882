import { orderBy } from 'lodash'

export type AssetQuantityCondition = {
  id: number | null
  name: string
  quantity: number
  disabled?: boolean
  selected?: boolean
}

export class Asset {
  id: number | null = null
  name: string | null = null
  conditions: AssetQuantityCondition[] = [{ id: null, name: '', quantity: 0 }]

  constructor(json) {
    if (!json) return
    this.id = json.id
    this.name = json.name
    this.conditions = orderBy(json.asset_conditions, [
      (condition: AssetQuantityCondition) => condition.name.toLowerCase(),
    ])
  }

  get conditionNames(): string[] {
    return this.conditions.map(
      (condition: AssetQuantityCondition) => condition.name
    )
  }

  isEmpty(): boolean {
    return (
      this.conditions.filter((cond: AssetQuantityCondition) => {
        return cond.quantity !== 0
      }).length === 0
    )
  }
}
