"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IfThenModel = void 0;
const schema_object_1 = require("../schema_object");
class IfThenModel extends schema_object_1.SchemaObject {
    constructor(ifModel, then) {
        super();
        this["if"] = [ifModel];
        this["then"] = [then];
    }
}
exports.IfThenModel = IfThenModel;
