import moment from 'moment'

export const currentTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const formatDateTime = (dateTime) => {
  const momentDateTime = moment(dateTime)
  return momentDateTime.format('MMM DD, HH:mm')
}

export const formatDateTimeWithYear = (dateTime) => {
  const momentDateTime = moment(dateTime)
  return momentDateTime.format('MMM DD, YYYY HH:mm')
}

export const formatCurrentDateTime = () => {
  return moment().format('YYYY-MM-DD-HH-mm-ss')
}

export const formatDateWithYear = (date) => {
  return moment(date).format('MMM DD, YYYY')
}

export const formatTimeRange = (
  start,
  end,
  delimiter = '-',
  noEndText = ''
) => {
  const startDate = moment(start).format('MMM DD')
  const endDate = end ? moment(end).format('MMM DD') : null
  const startTime = moment(start).format('HH:mm')
  const endTime = end ? moment(end).format('HH:mm') : null

  if (startDate === endDate) {
    return `${startDate}, ${startTime} ${delimiter} ${endTime}`
  } else if (endDate && endTime) {
    return `${startDate}, ${startTime} ${delimiter} ${endDate}, ${endTime}`
  } else {
    return noEndText
      ? `${startDate}, ${startTime} ${delimiter} ${noEndText}`
      : `${startDate}, ${startTime}`
  }
}

export const formatDuration = (duration, numberOfTimeSegments = 2) => {
  const days = duration.days()
  const hours = duration.hours()
  const minutes = duration.minutes()
  const seconds = duration.seconds()
  return [
    days > 0 ? `${days}day${days > 1 ? 's' : ''}` : null,
    hours > 0 ? `${hours}${hours > 1 ? 'hrs' : 'hr'}` : null,
    minutes > 0 ? `${minutes}m` : null,
    seconds > 0 ? `${seconds}s` : null,
  ]
    .filter((c) => c !== null)
    .slice(0, numberOfTimeSegments)
    .join(' ')
}

export const formatDurationAsHours = (duration, showZeroDuration = false) => {
  if (duration === null || duration === undefined) return null

  const durationAsHours = Math.round(duration.asHours() * 100) / 100

  if (!showZeroDuration && durationAsHours === 0) return null

  return `${durationAsHours.toFixed(2)} ${durationAsHours > 1 ? 'hrs' : 'hr'}`
}
