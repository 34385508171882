import { computed, isRef, Ref, unref } from 'vue'
import { get } from './base'
import { useQuery, UseQueryOptions } from '@tanstack/vue-query'
import moment from 'moment'
import axios from 'axios'
import { convertToCamelCase } from '@/models/utils/casing'

const businessUnitsV4EndPoint = '/api/business-units'

interface BusinessUnitPayload {
  name: string
  owner_id: string
  path: string
  type: 'Organization' | 'Hub' | 'Division' | 'Team'
}
interface BusinessUnitsResponse {
  data: BusinessUnitPayload[]
}

export function getBusinessUnit(
  id: string
): Promise<{ data: BusinessUnitPayload }> {
  return get(`${businessUnitsV4EndPoint}/${id}`)
}

export function getBusinessUnits(): Promise<BusinessUnitsResponse> {
  return get(`${businessUnitsV4EndPoint}`)
}

export function useBusinessUnitQuery(
  id: Ref<string | undefined> | string,
  options: Omit<
    UseQueryOptions<
      BusinessUnitPayload,
      unknown,
      BusinessUnitPayload,
      (string | Ref<string | undefined>)[]
    >,
    'queryKey' | 'queryFunction' | 'enabled' | 'staleTime'
  > = {}
) {
  const enabled = computed(() => !isRef(id) || !!id.value)

  const { data, error } = useQuery({
    queryKey: ['businessUnits', id],
    queryFn: () => getBusinessUnit(unref(id) as string).then((r) => r.data),
    enabled,
    staleTime: moment.duration(10, 'minutes').asMilliseconds(),
    ...options,
  })

  return { data, error }
}

export interface HubEntitlements {
  pools: { enabled: boolean }
}

export function getHubHubEntitlementsQuery(
  hubId: string,
  signal?: AbortSignal
) {
  const businessUnitEndpointSource = axios.CancelToken.source()

  const hubHubEntitlements = get<HubEntitlements>(
    `${businessUnitsV4EndPoint}/${hubId}/entitlements`,
    {
      cancelToken: businessUnitEndpointSource.token,
    }
  ).then((r) => convertToCamelCase(r.data))

  signal?.addEventListener('abort', () => {
    businessUnitEndpointSource.cancel('Query was cancelled by TanStack Query')
  })

  return hubHubEntitlements
}

export function useHubEntitlementsQuery(hubId: Ref<string>) {
  const queryKey = computed(() => [hubId.value, 'entitlements'])

  return useQuery({
    queryKey,
    queryFn: ({ signal }) => getHubHubEntitlementsQuery(hubId.value, signal),
    staleTime: moment.duration(2, 'minutes').asMilliseconds(),
  })
}
