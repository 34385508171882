import mapboxgl from 'mapbox-gl'
import { onBeforeMount, onBeforeUnmount } from 'vue'
import { useMap } from './context'

export type MapboxRequestParams = mapboxgl.RequestParameters & {
  body?: string
}

export type RequestInterceptor = (
  url: string,
  resourceType: mapboxgl.ResourceType,
  currentRequest: MapboxRequestParams
) => MapboxRequestParams

export const useMapboxRequestInterceptor = (
  key: string,
  interceptor: RequestInterceptor
) => {
  const { addRequestHandler, removeRequestHandler } = useMap()

  onBeforeMount(() => {
    addRequestHandler(key, interceptor)
  })

  onBeforeUnmount(() => {
    removeRequestHandler(key)
  })
}
