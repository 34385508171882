"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonValue = exports.FormButtonsPropertyDescription = void 0;
const json_schema_keys_1 = require("../../json_schema_keys");
const field_property_description_1 = require("./field_property_description");
class FormButtonsPropertyDescription extends field_property_description_1.DefaultFieldPropertyDescription {
    constructor(field) {
        super(field);
        this[json_schema_keys_1.JsonSchemaKeys.BUTTONS_KEY] = field.buttonLabels.map((label) => new ButtonValue(label));
    }
}
exports.FormButtonsPropertyDescription = FormButtonsPropertyDescription;
class ButtonValue {
    constructor(label) {
        this.label = label;
        this.value = label;
    }
}
exports.ButtonValue = ButtonValue;
