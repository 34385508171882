"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchemaModel = void 0;
const json_schema_keys_1 = require("../json_schema_keys");
const schema_properties_1 = require("./schema_properties");
class SchemaModel {
    constructor() {
        this.type = json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_OBJECT;
        this.title = "";
        this.required = [];
        this.properties = new schema_properties_1.SchemaProperties();
    }
}
exports.SchemaModel = SchemaModel;
