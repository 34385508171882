import { getInstance } from '@/auth'
import { forEach } from 'lodash'

export const APP_INITIALIZED = 'APP_INITIALIZED'

export default function createSignalrPlugin(modules) {
  const pushHandlers = {}

  forEach(modules, (module, moduleName) => {
    forEach(module.pushHandlers, (actionName, eventType) => {
      pushHandlers[eventType] = `${moduleName}/${actionName}`
    })
  })

  return async (store) => {
    store.subscribe(async (mutation) => {
      if (mutation.type === APP_INITIALIZED) {
        const authService = getInstance()
        let connection = null
        if (store.getters['permissions/hasPushDataAccess']) {
          connection = new window.signalR.HubConnectionBuilder()
            .withUrl(`${location.origin}/event-stream`, {
              accessTokenFactory: async () =>
                await authService.getTokenSilently(),
            })
            .withAutomaticReconnect()
            .build()

          connection.serverTimeoutInMilliseconds = 240000 // 4 minutes

          connection.on('Notify', (payload) => {
            const unpacked = JSON.parse(payload)

            if (unpacked.type in pushHandlers) {
              store.dispatch(pushHandlers[unpacked.type], unpacked.data)
            }
          })

          connection.onclose(async () => {
            await connection.start()
          })
          await connection.start()
        }
      }
    })
  }
}
