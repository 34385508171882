<template>
  <v-list-item ripple @click="toggleSelectAll">
    <v-list-item-action>
      <v-icon :color="content.color">{{ content.icon }}</v-icon>
    </v-list-item-action>
    <v-list-item-title>{{ content.text }}</v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: 'SelectAllForFilter',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    allSelections: {
      type: Array,
      default: () => [],
    },
    fieldName: {
      type: String,
      default: () => 'id',
    },
  },
  computed: {
    content() {
      let icon
      let color = 'indigo darken-4'
      let text = 'Select All'
      if (this.value.length === 0) {
        icon = 'mdi-checkbox-blank-outline'
        color = ''
      } else if (this.value.length === this.allSelections.length) {
        icon = 'mdi-minus-box'
        text = 'Deselect All'
      } else if (this.value.length > 0) {
        icon = 'mdi-close-box'
      }
      return { icon, color, text }
    },
  },
  methods: {
    toggleSelectAll() {
      if (this.value.length === this.allSelections.length) {
        this.deselectAll()
      } else {
        this.selectAll()
      }
    },
    deselectAll() {
      this.$emit('input', [])
    },
    selectAll() {
      this.$emit(
        'input',
        this.allSelections.map((s) => s[this.fieldName])
      )
    },
  },
}
</script>

<style></style>
