import axios from 'axios'
import { get } from '@/api/base'
import { convertToCamelCase } from '@/models/utils/casing'
import { useQuery } from '@tanstack/vue-query'
import moment from 'moment/moment'
import { computed, unref } from 'vue'
import { MaybeRef } from '@vueuse/core'

const invoicesEndPoint = '/api/v5/accounts'

export interface AccountEntitlementsResponse {
  organizationId: string
  entitlements: string[]
  hasAccount: boolean
}

export function getAccountEntitlements(
  organizationId: string | null = null,
  signal?: AbortSignal
) {
  const source = axios.CancelToken.source()
  const result = get<AccountEntitlementsResponse>(
    organizationId
      ? `${invoicesEndPoint}/entitlements?organizationId=${organizationId}`
      : `${invoicesEndPoint}/entitlements`,
    {
      cancelToken: source.token,
    }
  ).then((res) => convertToCamelCase(res.data))
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled by TanStack Query')
  })
  return result as unknown as Promise<AccountEntitlementsResponse>
}

export function useGetAccountEntitlements(
  organizationId: MaybeRef<string | null> = null
) {
  const computedId = computed(() => unref(organizationId))
  const queryKey = computed(() => ['account', 'entitlements', computedId.value])
  return useQuery({
    queryKey,
    queryFn: ({ signal }) => getAccountEntitlements(computedId.value, signal),
    staleTime: moment.duration(10, 'minutes').asMilliseconds(),
  })
}
