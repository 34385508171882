"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyLookup = exports.LocationLookup = exports.BusinessUnitLookup = exports.UserLookup = exports.LookupEntity = void 0;
const json_schema_keys_1 = require("../json_schema_keys");
class LookupEntity {
}
exports.LookupEntity = LookupEntity;
class UserLookup extends LookupEntity {
    constructor() {
        super(...arguments);
        this.entity = json_schema_keys_1.JsonSchemaKeys.LOOKUP_ENTITY_USER;
        this.dataType = json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_STRING;
    }
}
exports.UserLookup = UserLookup;
class BusinessUnitLookup extends LookupEntity {
    constructor() {
        super(...arguments);
        this.entity = json_schema_keys_1.JsonSchemaKeys.LOOKUP_BUSINESS_UNIT_KEY;
        this.dataType = json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_STRING;
    }
}
exports.BusinessUnitLookup = BusinessUnitLookup;
class LocationLookup extends LookupEntity {
    constructor() {
        super(...arguments);
        this.entity = json_schema_keys_1.JsonSchemaKeys.LOOKUP_ENTITY_LOCATION;
        this.dataType = json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_NUMBER;
    }
}
exports.LocationLookup = LocationLookup;
class CompanyLookup extends LookupEntity {
    constructor() {
        super(...arguments);
        this.entity = json_schema_keys_1.JsonSchemaKeys.LOOKUP_ENTITY_COMPANY;
        this.dataType = json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_NUMBER;
    }
}
exports.CompanyLookup = CompanyLookup;
