import { computed, onMounted, reactive } from 'vue'
import LocationDTO from '@/models/location'
import { useRoute } from 'vue-router/composables'
import { useStore } from 'vuex-composition-helpers'
import { useHubs } from '@/common/useHubs'
import { useCurrentOrg } from '@/common/useCurrentOrg'
import { useRemovableField } from '@/components/locations/on-the-fly-locations/removable-field'

export interface FormStates {
  parentId?: number | null
  latitude?: number | null
  longitude?: number | null
  accessBusinessUnitIds?: string[] | null
  locationTypeId?: number | null
  colorId?: number | null
  description?: string | null
  location?: string | null // location name
  ownerId?: string | null
  private?: boolean | null
}

export function useFormStates(
  defaultParentLocId: () => number | null | undefined
) {
  const store = useStore()
  const route = useRoute()
  const { currentHub, currentHubOwner } = useHubs()
  const { currentOrg } = useCurrentOrg()

  // Coordinates
  const { isVisible: showCoordinatesField, handleRemove: removeCoordinates } =
    useRemovableField(() => {
      formData.latitude = 0
      formData.longitude = 0
    }, true)
  // Parent Locations
  const {
    isVisible: showParentLocationField,
    handleRemove: removeParentLocation,
  } = useRemovableField(() => {
    formData.parentId = null
  })
  // Description
  const { isVisible: showDescriptionField, handleRemove: removeDescription } =
    useRemovableField(() => {
      formData.description = null
    })
  // Location Status
  const { isVisible: showStatusField, handleRemove: removeStatus } =
    useRemovableField(() => {
      formData.colorId = null
    })

  const isInMapView = computed(() => route.name?.startsWith('fvnext'))
  const formData = reactive<FormStates>({
    parentId: undefined,
    latitude: undefined,
    longitude: undefined,
    accessBusinessUnitIds: undefined,
    locationTypeId: undefined,
    colorId: undefined,
    description: undefined,
    location: undefined,
    ownerId: undefined,
    private: undefined,
  })

  const mapCenterPoint = computed<
    | {
        latitude: number
        longitude: number
        zoom: number
      }
    | undefined
  >(
    () =>
      (
        store.state as {
          organizationConfig: {
            mapCenterPoint?: {
              latitude: number
              longitude: number
              zoom: number
            }
          }
        }
      ).organizationConfig?.mapCenterPoint
  )

  const defaultOwnerId = computed<Partial<Pick<LocationDTO, 'ownerId'>>>(() => {
    // the default owner id of the new location should typically be
    // my org id, however, if we are in the context of hub that is
    // owned by a managed company, we should use that managed company's id.
    if (currentHubOwner.value?.ownerId === currentOrg.value?.id) {
      return { ownerId: currentHubOwner.value?.id }
    }

    return { ownerId: currentOrg.value?.id }
  })

  onMounted(() => {
    formData.ownerId = defaultOwnerId.value.ownerId
    formData.accessBusinessUnitIds = currentHub.value?.id
      ? [currentHub.value?.id]
      : []
    formData.latitude = 0
    formData.longitude = 0
    formData.parentId = defaultParentLocId()
    if (formData.parentId) {
      showParentLocationField.value = true
    }
    formData.private = false
  })

  return {
    formData,
    mapCenterPoint,
    isInMapView,
    showCoordinatesField,
    removeCoordinates,
    showParentLocationField,
    removeParentLocation,
    showDescriptionField,
    removeDescription,
    showStatusField,
    removeStatus,
  }
}
