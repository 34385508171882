<template>
  <vs-autocomplete
    ref="autocomplete"
    multiselect
    :disabled="disabled"
    label="Tags"
    empty-state-text="No existing tags yet. Start typing to create one."
    :value="value"
    :items="options"
    :search-value.sync="searchValue"
    @input="handleInput"
    @keydown.enter="handleHotKeys"
    @keydown.tab="handleHotKeys"
  >
    <template v-if="showCustomTagOption" #append-item>
      <v-divider class="mb-2" />
      <v-list-item data-test="create-tile" @click.stop="handleCustomAdd">
        <v-list-item-content>
          <vs-text>Create "{{ searchValue }}" tag</vs-text>
        </v-list-item-content>
      </v-list-item>
    </template>
  </vs-autocomplete>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    /**
     * Array of selected tags
     */
    value: {
      type: Array[String],
      default: [],
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hubId: {
      type: String,
      required: true,
    },
    showCreateCustomTag: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      searchValue: '',
    }
  },
  computed: {
    ...mapState({
      tagOptions: (state) => state.jobs.tags,
    }),
    options() {
      const tagsForHub = this.tagOptions
        .filter((tag) => tag.ownerId === this.hubId)
        .map((tag) => tag.name)
      const values = new Set([...tagsForHub, ...this.value])
      return [...values].map((tag) => ({ text: tag, value: tag }))
    },
    matchingOption() {
      const matchedOption = this.options.find(
        (tag) => tag.text.toLowerCase() === this.searchValue.toLowerCase()
      )
      return matchedOption?.text
    },
    isSearchAlreadyAdded() {
      const lowerCaseValues = this.value.map((tag) => tag.toLowerCase())
      return lowerCaseValues.includes(this.searchValue.toLowerCase())
    },
    showCustomTagOption() {
      return (
        this.searchValue.length &&
        !this.matchingOption &&
        this.showCreateCustomTag
      )
    },
  },
  mounted() {
    this.fetchTags()
  },
  methods: {
    ...mapActions('jobs', ['fetchTags']),
    handleInput(value) {
      this.$emit('input', value)
    },
    handleAddMatchingOption() {
      this.handleInput([...this.value, this.matchingOption])
      this.resetField()
    },
    handleCustomAdd() {
      this.handleInput([...this.value, this.searchValue])
      this.resetField()
    },
    handleHotKeys(event) {
      if (this.searchValue.length) {
        event.preventDefault()
        if (this.isSearchAlreadyAdded) {
          this.resetField()
        } else if (this.matchingOption) {
          this.handleAddMatchingOption()
        } else {
          this.handleCustomAdd()
        }
      }
    },
    resetField() {
      this.searchValue = ''
      this.$refs.autocomplete.focus()
      this.$refs.autocomplete.clearSearchValue()
    },
  },
}
</script>
