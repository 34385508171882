<template>
  <vs-text-input
    v-model.lazy="field.markdown"
    multiline
    label="Markdown String"
    :value="field.markdown"
    @input="updateMarkdown"
  >
  </vs-text-input>
</template>

<script>
import VsTextInput from '@/components/vision/Inputs/VsTextInput'
export default {
  name: 'MarkdownFieldSettings',
  components: { VsTextInput },

  props: {
    field: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    updateMarkdown(markdown) {
      this.emitChanges('markdown', markdown)
    },
    emitChanges(propertyKey, value) {
      this.$emit('modify-field', {
        propertyKey,
        fieldValue: value,
        key: this.field.uniqueKey(),
      })
    },
  },
}
</script>

<style scoped></style>
