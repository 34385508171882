<template>
  <vs-wrapper>
    <vs-text>{{ scheduleOptionDescription }}</vs-text>
    <v-row no-gutters>
      <v-col class="pa-1">
        <vs-autocomplete
          data-test="time-delta-quantity-selector"
          type="number"
          :value="timeDeltaQuantity"
          :items="getTimeDeltaQuantityOptions"
          item-value="text"
          required
          :rules="[
            (value) =>
              parseFloat(value) <= 0 && `Enter a value that's greater than 0`,
            (value) =>
              !!value &&
              !Number.isInteger(parseFloat(value)) &&
              `Please enter a whole number`,
          ]"
          free-form
          @input="handleQuantityChange"
        >
        </vs-autocomplete>
      </v-col>
      <v-col class="pa-1">
        <vs-autocomplete
          data-test="time-delta-unit-selector"
          :value="timeDeltaUnit"
          :items="getTimeDeltaUnitOptions"
          item-value="value"
          required
          disable-clear
          @input="handleUnitChange"
        >
        </vs-autocomplete>
      </v-col>
    </v-row>
  </vs-wrapper>
</template>

<script>
export default {
  name: 'RescheduleForm',
  props: {
    saving: {
      type: Boolean,
      default: false,
    },
    scheduleOption: {
      type: String,
      required: true,
    },
    timeDeltaQuantity: {
      type: Number,
      default: 1,
    },
    timeDeltaUnit: {
      type: String,
      default: 'day',
    },
  },
  computed: {
    getTimeDeltaQuantityOptions() {
      return [...Array(100).keys()].map((num) => {
        return { text: (++num).toString() }
      })
    },
    getTimeDeltaUnitOptions() {
      return [
        { text: this.timeDeltaQuantity > 1 ? 'days' : 'day', value: 'day' },
        { text: this.timeDeltaQuantity > 1 ? 'hours' : 'hour', value: 'hour' },
      ]
    },
    scheduleOptionDescription() {
      if (this.scheduleOption === 'delay') {
        return 'Delay start date/time by...'
      }
      return 'Move start date/time earlier by...'
    },
  },
  methods: {
    handleQuantityChange(value) {
      this.$emit('update-quantity', value)
    },
    handleUnitChange(value) {
      this.$emit('update-unit', value)
    },
  },
}
</script>
