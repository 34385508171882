<template>
  <a
    v-if="updateAvailable"
    class="client-version update"
    :title="`Update to ${version}`"
    :href="path"
    @click="reload"
    >Update to {{ version }}</a
  >
  <span v-else class="client-version" :title="version">{{ version }}</span>
</template>

<script>
export default {
  name: 'VersionFooter',

  props: {
    version: {
      type: String,
      required: true,
    },

    updateAvailable: Boolean,
  },

  computed: {
    path() {
      return location.href
    },
  },

  methods: {
    reload() {
      window.location.reload(true)
    },
  },
}
</script>

<style scoped>
.client-version {
  flex-shrink: 0;
  max-width: 84px;
  padding: 4px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.2);
  font-size: 0.5rem;
  text-align: center;
  text-overflow: ellipsis;
}

.update {
  color: var(--color-blue--light);
}
</style>
