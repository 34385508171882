<template>
  <icon-two-line-cell
    :primary-text="getSubmitterName"
    :secondary-text="getBusinessUnitPath"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import IconTwoLineCell from '@/components/forms/cells/IconTwoLineCell'
import BusinessUnitPathMixin from '@/components/forms/cells/business-unit-path-mixin'

export default {
  name: 'SubmitterCell',
  components: { IconTwoLineCell },
  mixins: [BusinessUnitPathMixin],
  props: {
    userSid: {
      type: String,
      required: true,
    },
    submittersBusinessUnitId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getBusinessUnitById: 'businessUnits/getBusinessUnitById',
      getUserBySid: 'users/getBySid',
    }),
    businessUnit() {
      return this.getBusinessUnitById(this.submittersBusinessUnitId)
    },
    getSubmitterName() {
      return this.getUserBySid(this.userSid)?.name ?? 'Unknown user'
    },
  },
}
</script>

<style scoped></style>
