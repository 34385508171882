<template>
  <div>
    <v-menu open-on-hover bottom offset-y :nudge-width="32" rounded="xl">
      <template #activator="{ on }">
        <div v-on="on">
          <div
            v-for="state in progressBarItems"
            :key="state.name"
            :style="{
              width: getWidthPercentage(state.count),
            }"
            :class="[state.name, 'project-progress-bar']"
          />
        </div>
      </template>

      <v-card>
        <v-card-text>
          <v-row v-for="state in progressBarItems" :key="state.name">
            <v-col class="shrink">
              <div :class="[state.name, 'status-chip']"></div>
            </v-col>
            <v-col class="shrink">
              <vs-text
                >{{ getWidthPercentage(state.count) }} {{ state.name }}
              </vs-text>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-menu>

    <v-row justify="center" align="center" dense>
      <v-col v-for="state in progressBarItems" :key="state.name" class="shrink">
        <vs-text type="caption">
          <v-icon id="status-circle" :class="[state.name]">
            mdi-checkbox-blank-circle
          </v-icon>
          {{ `${state.count} ${state.name}` }}
        </vs-text>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VsText from '@/components/vision/VsText.vue'

export default {
  components: { VsText },
  props: {
    jobStatusCount: {
      type: Array,
      required: true,
    },
  },
  computed: {
    progressBarItems() {
      return this.jobStatusCount.filter((item) => item.count > 0)
    },
  },
  methods: {
    getWidthPercentage(count) {
      const total = this.progressBarItems.reduce(
        (acc, val) => acc + val.count,
        0
      )
      return `${Math.floor((count / total) * 1000) / 10}%`
    },
  },
}
</script>

<style scoped>
.project-progress-bar {
  background-color: var(--progress-color, var(--color-silver));
  height: var(--space-large);
  display: inline-block;
  margin-bottom: var(--space-small);
  margin-top: var(--space-small);
}

.project-progress-bar:first-child {
  border-top-left-radius: var(--space-base);
  border-bottom-left-radius: var(--space-base);
}

.project-progress-bar:last-child {
  border-top-right-radius: var(--space-base);
  border-bottom-right-radius: var(--space-base);
}

.completed {
  --progress-color: var(--color-green);
}

.active {
  --progress-color: #c8e6c9;
}

.status-chip {
  background-color: var(--progress-color, var(--color-silver));
  height: var(--space-base);
  width: 32px;
  display: inline-block;
  margin-top: var(--space-smaller);

  border-top-left-radius: var(--space-base);
  border-bottom-left-radius: var(--space-base);

  border-top-right-radius: var(--space-base);
  border-bottom-right-radius: var(--space-base);
}

#status-circle {
  color: var(--progress-color, var(--color-silver));
}

.v-menu__content {
  min-width: 32px !important;
}
</style>
