export const formatSecondaryFieldName = (fieldName: string): string | null => {
  const secondaryFieldNameMap = {
    priority: 'Priority',
    division_id: 'Division',
    customer_sid: 'Requester',
    cost_center: 'Cost Center',
    details: 'Details',
    custom_data: 'Custom Fields',
    project_id: 'Project',
  }
  return (secondaryFieldNameMap[fieldName] ?? null) as string | null
}

export const formatSecondaryFieldsDisplay = (
  fields: string[]
): string | null => {
  fields = fields.filter(Boolean)
  fields.sort()
  return fields.length > 1
    ? fields.slice(0, -1).join(', ') + ' and ' + fields.at(-1) + ' updated'
    : fields.length === 1
    ? fields.at(0) + ' updated'
    : null
}
