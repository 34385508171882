import FormBuilder from '@/pages/FormBuilder'
import FormPreview from '@/pages/FormPreview'
import Portal from '@/pages/Portal'
import AccountCreation from '@/pages/AccountCreation'
import Welcome from '@/pages/Welcome'
import VueRouter from 'vue-router'
import { authGuard } from './auth/authGuard'
import { savePreviousRoute } from './utils/save-previous-route'
import {
  ANALYTICS_VIEWER_ROLE,
  CUSTOMER_ROLE,
  DISPATCHER_ROLE,
  FORM_REVIEWER_ROLE,
  OPERATOR_ROLE,
  SUPERADMIN_ROLE,
  WORKFLOW_MANAGER_ROLE,
} from './auth/index'
import Analytics from './components/analytics/Analytics'
import LegacyAnalyticsDashboard from './components/analytics/LegacyAnalyticsDashboard'
import SchedulePageLayout from './components/common/layouts/SchedulePageLayout'
import SideSheetLayout from './components/common/layouts/SideSheetLayout'
import UnitOverview from './components/common/unit-number-panel/UnitOverview'
import FieldViewNextProvision from './components/data-provisions/FieldViewNextProvision'
import FormBuilderProvision from './components/data-provisions/FormBuilderProvision'
import FormsSubmissionsProvision from './components/data-provisions/FormsSubmissionsProvision'
import JobsListProvision from './components/data-provisions/JobsListProvision'
import ScheduleProvision from './components/data-provisions/ScheduleProvision'
import FieldViewNext from './components/field-view-next/FieldView'
import FieldViewLocationFormNext from './components/field-view-next/FieldViewLocationForm'
import FieldViewInfoPanelNext from './components/field-view-next/FieldViewInfoPanel'
import FieldViewPickerPanelNext from './components/field-view-next/FieldViewPickerPanel'

import FormSubmission from './components/forms/FormSubmission'
import SchemaBasedForm from './components/forms/SchemaBasedForm'
import Home from './components/Home'
import JobsFilterAside from './components/jobs/JobsFilterAside'
import Logout from './components/Logout'
import Project from './components/project/Project'
import ScheduleToolbar from './components/schedule/ScheduleToolbar'
import Ticket from './components/tickets/Ticket.vue'
import Batch from './components/batches/Batch.vue'
import ResourceForm from './components/units/ResourceForm.vue'
import DayView from './pages/DayView'
import FormSubmissions from './pages/FormSubmissions'
import WeekView from './pages/WeekView.vue'
import { versionGuard } from '@/version-guard'
import NewAnalyticsDashboard from '@/components/analytics/NewAnalyticsDashboard.vue'
import ClientSelectionPage from '@/components/companies/ClientSelectionPage.vue'
import BatchSyncDetailsEditForm from '@/components/batches/BatchSyncDetailsEditForm.vue'

// Manually handling routing errors via: https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => {
    const navErrors = VueRouter.NavigationFailureType

    // We ignore navigation duplicated errors globally
    if (VueRouter.isNavigationFailure(err, navErrors.duplicated)) {
      return err
    }

    // We ignore navigation aborted errors globally
    if (VueRouter.isNavigationFailure(err, navErrors.aborted)) {
      return err
    }

    return Promise.reject(err)
  })
}

const ScheduleViewChildren = (parentRouteName, prefix) => [
  {
    path: 'unit-numbers/:id',
    name: `${prefix}-unit-overview`,
    component: UnitOverview,
    props: (route) => ({
      id: parseInt(route.params.id, 10),
      parentRouteName,
      editViewRouteName: `${prefix}-unit-edit-view`,
    }),
  },
  {
    path: 'unit-numbers/:id/edit',
    name: `${prefix}-unit-edit-view`,
    components: {
      default: UnitOverview,
      bottomdrawer: ResourceForm,
    },
    props: {
      default: (route) => ({
        id: parseInt(route.params.id, 10),
        parentRouteName,
        editViewRouteName: `${prefix}-unit-edit-view`,
      }),
      bottomdrawer: (route) => ({
        id: parseInt(route.params.id, 10),
        dismissTo: {
          name: `${prefix}-unit-overview`,
          params: {
            id: route.params.id,
          },
        },
      }),
    },
  },
  {
    path: 'jobs/:id/:hubId?',
    name: `${prefix}-ticket-info-panel`,
    component: Ticket,
    props: (route) => ({
      parentRouteName,
      onSaveRoute: `${prefix}-ticket-info-panel`,
      id: route.params.id === 'new' ? 'new' : parseInt(route.params.id, 10),
      hubId: route.params.hubId,
    }),
  },
  {
    path: 'projects/:projectId',
    name: `${prefix}-project`,
    component: Project,
    props: (route) => ({
      parentRouteName,
      id: route.params.projectId,
    }),
    children: [
      {
        path: ':id/:hubId?',
        name: `${prefix}-project-ticket-info-panel`,
        component: Ticket,
        props: (route) => ({
          parentRouteName: `${prefix}-project`,
          onSaveRoute: `${prefix}-project-ticket-info-panel`,
          id: route.params.id === 'new' ? 'new' : parseInt(route.params.id, 10),
          hubId: route.params.hubId,
        }),
      },
    ],
  },
  {
    path: 'clients',
    name: `${prefix}-search-clients`,
    component: ClientSelectionPage,
    props: () => ({
      onBackRoute: parentRouteName,
      onSaveAndCreateRoute: `${prefix}-ticket-info-panel`,
    }),
  },
]

const routerConfig = {
  mode: 'history',
  routes: [
    {
      path: '/vision',
      name: 'vision',
      component: () =>
        import(/* webpackChunkName: "vision" */ '@/pages/Vision'),
    },
    {
      path: '/form-preview',
      permissions: [SUPERADMIN_ROLE],
      components: {
        default: FormPreview,
      },
    },
    {
      path: '/form-builder',
      redirect: 'schema-builder',
    },
    {
      path: '/schema-builder',
      permissions: [SUPERADMIN_ROLE, WORKFLOW_MANAGER_ROLE],
      components: {
        default: FormBuilder,
        provision: FormBuilderProvision,
      },
    },
    {
      path: '/',
      name: 'home',
      components: {
        default: Home,
      },
    },
    {
      path: '/logout',
      name: 'logout',
      components: {
        default: Logout,
      },
    },
    {
      path: '/welcome',
      name: 'welcome',
      component: Welcome,
    },
    {
      path: '/portal',
      name: 'portal',
      component: Portal,
    },
    {
      path: '/account-creation',
      name: 'account-creation',
      component: AccountCreation,
    },
    {
      path: '/schedule',
      name: 'schedule',
      components: {
        default: SchedulePageLayout,
        toolbar: ScheduleToolbar,
        provision: ScheduleProvision,
      },
      children: [
        {
          path: 'day',
          name: 'day-view',
          components: {
            default: DayView,
          },
          children: ScheduleViewChildren('day-view', 'sv'),
        },
        {
          path: 'week/:year?/:month?/:day?',
          name: 'week-view',
          components: {
            default: WeekView,
          },
          children: ScheduleViewChildren('week-view', 'svwv'),
        },
      ],
      meta: {
        permissions: [SUPERADMIN_ROLE, DISPATCHER_ROLE],
        type: 'main-nav',
        display: {
          icon: 'calendar_today',
          title: 'Schedule',
        },
      },
    },
    { path: '/field-view', redirect: '/map' },
    { path: '/field-view/*', redirect: '/map/*' },
    { path: '/field-view-next', redirect: '/map' },
    { path: '/field-view-next/*', redirect: '/map/*' },
    {
      /*
      All named routes in Field View Next is prepend with "fvnext"
      e.g., fv-new-location => fvnext-new-location;
      Special case:  field-view => field-view-next
      Remember to change it back when we sunsetting the old Field View Next.
       */
      path: '/map',
      name: 'field-view-next',
      components: {
        default: FieldViewNext,
        provision: FieldViewNextProvision,
        aside: JobsFilterAside,
      },
      props: {
        aside: () => ({ hideSearch: true }),
      },
      children: [
        {
          path: 'locations/new',
          name: 'fvnext-new-location',
          component: FieldViewLocationFormNext,
          props: {
            dismissTo: { name: 'field-view-next' },
            redirectAfterSaveTo: {
              name: 'fvnext-info-panel',
              params: { source: 'locations' },
            },
          },
          meta: {
            permissions: [SUPERADMIN_ROLE],
          },
        },
        {
          path: 'picker/:source/:ids',
          name: 'fvnext-picker-panel',
          component: FieldViewPickerPanelNext,
          props: true,
          children: [],
        },
        {
          path: 'jobs/:id/:hubId?',
          name: 'fvnext-ticket-info-panel-direct',
          component: Ticket,
          props: (route) => ({
            parentRouteName: 'field-view-next',
            parentRouteParams: {},
            onSaveRoute: 'fvnext-ticket-info-panel-direct',
            id:
              route.params.id === 'new' ? 'new' : parseInt(route.params.id, 10),
            hubId: route.params.hubId,
          }),
        },
        {
          path: ':source/:id',
          name: 'fvnext-info-panel',
          component: FieldViewInfoPanelNext,
          props: true,
          children: [],
        },
        {
          path: ':source/:sourceId/jobs/:id/:hubId?',
          name: 'fvnext-ticket-info-panel',
          component: Ticket,
          props: (route) => ({
            parentRouteName: 'fvnext-info-panel',
            parentRouteParams: {
              source: route.params.source,
              sourceId: route.params.sourceId,
              id: route.params.sourceId,
            },
            onSaveRoute: 'fvnext-ticket-info-panel',
            id:
              route.params.id === 'new' ? 'new' : parseInt(route.params.id, 10),
            hubId: route.params.hubId,
            locationId: Number(route.query.locationId),
          }),
        },
        {
          path: ':source/:id/clients',
          name: 'fvnext-search-clients',
          component: ClientSelectionPage,
          props: () => ({
            onBackRoute: 'fvnext-info-panel',
            onSaveAndCreateRoute: 'fvnext-ticket-info-panel',
          }),
        },
        {
          path: ':source/:sourceId/projects/:projectId',
          name: 'fvnext-project',
          component: Project,
          props: (route) => ({
            parentRouteName: 'fvnext-info-panel',
            parentRouteParams: {
              source: route.params.source,
              sourceId: route.params.sourceId,
              id: route.params.sourceId,
            },
            id: route.params.projectId,
          }),
          children: [
            {
              path: 'jobs/:id/:hubId?',
              name: 'fvnext-project-ticket-info-panel',
              component: Ticket,
              props: (route) => ({
                parentRouteName: 'fvnext-project',
                onSaveRoute: 'fvnext-project-ticket-info-panel',
                id:
                  route.params.id === 'new'
                    ? 'new'
                    : parseInt(route.params.id, 10),
                hubId: route.params.hubId,
              }),
            },
          ],
        },
      ],
      meta: {
        permissions: [CUSTOMER_ROLE, SUPERADMIN_ROLE, DISPATCHER_ROLE],
        type: 'main-nav',
        display: {
          icon: 'map',
          title: 'Map',
        },
      },
    },
    {
      path: '/jobs',
      alias: '/jobs-list',
      name: 'job-search',
      components: {
        default: () =>
          import(/* webpackChunkName: "job-search" */ '@/pages/next/JobSearch'),
        aside: JobsFilterAside,
        provision: JobsListProvision,
      },
      children: [
        {
          path: 'clients',
          name: 'search-search-clients',
          component: ClientSelectionPage,
          props: () => ({
            onBackRoute: 'job-search',
            onSaveAndCreateRoute: 'search-ticket-info-panel',
          }),
        },
        {
          path: 'projects/:projectId',
          name: 'search-project',
          component: Project,
          props: (route) => ({
            parentRouteName: 'job-search',
            id: route.params.projectId,
          }),
          children: [
            {
              path: ':id/:hubId?',
              name: 'search-project-ticket-info-panel',
              component: Ticket,
              props: (route) => ({
                parentRouteName: 'search-project',
                onSaveRoute: 'search-project-ticket-info-panel',
                id:
                  route.params.id === 'new'
                    ? 'new'
                    : parseInt(route.params.id, 10),
                hubId: route.params.hubId,
              }),
            },
          ],
        },
        {
          path: 'batches/:batchId/',
          name: 'job-search-batches-info-panel',
          component: Batch,
          props: (route) => ({
            parentRouteName: 'job-search',
            id: route.params.batchId,
          }),
          children: [
            {
              path: 'sync/:syncTargetId/',
              name: 'job-search-batches-info-panel-sync-details-edit',
              component: BatchSyncDetailsEditForm,
              props: (route) => ({
                batchId: route.params.batchId,
                syncTargetId: route.params.syncTargetId,
                parentRouteName: 'job-search-batches-info-panel',
                parentRouteParams: {
                  batchId: route.params.batchId,
                },
              }),
            },
            {
              path: ':id/:hubId?',
              name: 'job-search-batches-info-panel-ticket-info-panel',
              component: Ticket,
              props: (route) => ({
                parentRouteName: 'job-search-batches-info-panel',
                onSaveRoute: 'job-search-batches-info-panel-ticket-info-panel',
                id:
                  route.params.id === 'new'
                    ? 'new'
                    : parseInt(route.params.id, 10),
                hubId: route.params.hubId,
              }),
            },
          ],
        },
        {
          path: ':id/:hubId?',
          name: 'search-ticket-info-panel',
          component: Ticket,
          props: (route) => ({
            parentRouteName: 'job-search',
            onSaveRoute: 'search-ticket-info-panel',
            id:
              route.params.id === 'new' ? 'new' : parseInt(route.params.id, 10),
            hubId: route.params.hubId,
          }),
        },
      ],
      meta: {
        permissions: [
          SUPERADMIN_ROLE,
          DISPATCHER_ROLE,
          CUSTOMER_ROLE,
          OPERATOR_ROLE,
        ],
        type: 'main-nav',
        display: {
          icon: 'list',
          title: 'Jobs',
        },
      },
    },
    {
      path: '/form-submissions',
      components: {
        default: SideSheetLayout,
        provision: FormsSubmissionsProvision,
      },
      children: [
        {
          path: '',
          name: 'form-submissions',
          components: {
            default: FormSubmissions,
          },
        },
        {
          path: ':id',
          name: 'view-form-submission',
          components: {
            default: FormSubmissions,
            sidesheet: FormSubmission,
          },
          props: {
            default: true,
            sidesheet: (route) => ({
              formSubmissionId: route.params.id,
              parentRouteName: 'form-submissions',
            }),
          },
        },
        {
          path: ':formId/:businessUnitId/new-submission',
          name: 'submit-form',
          components: {
            default: FormSubmissions,
            sidesheet: SchemaBasedForm,
          },
          props: {
            default: false,
            sidesheet: (route) => ({
              formId: route.params.formId,
              businessUnitId: route.params.businessUnitId,
              parentRouteName: 'form-submissions',
            }),
          },
        },
      ],
      meta: {
        type: 'main-nav',
        display: {
          icon: 'receipt_long',
          title: 'Forms',
        },
        requiresForms: true,
      },
    },
    {
      path: '/analytics',
      name: 'analytics',
      components: {
        default: Analytics,
      },
      children: [
        {
          path: ':id',
          name: 'metabase-dashboard',
          component: LegacyAnalyticsDashboard,
        },
        {
          path: 'dashboards/:id',
          name: 'new-metabase-dashboard',
          component: NewAnalyticsDashboard,
        },
      ],
      meta: {
        permissions: [
          SUPERADMIN_ROLE,
          DISPATCHER_ROLE,
          FORM_REVIEWER_ROLE,
          ANALYTICS_VIEWER_ROLE,
        ],
        type: 'main-nav',
        display: {
          icon: 'trending_up',
          title: 'Analytics',
        },
      },
    },
  ],
}

const router = new VueRouter(routerConfig)

router.beforeEach(versionGuard)
router.beforeEach(authGuard)
router.afterEach(savePreviousRoute)

export default router
