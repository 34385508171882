<template>
  <div>
    <v-tooltip v-for="priority in allPriorities" :key="priority.id" bottom>
      <template #activator="{ on }">
        <vs-button
          :data-test="`priority-${priority.name}`"
          class="mr-2"
          :type="selected !== priority.id ? 'secondary' : 'primary'"
          :icon="priority.icon"
          small
          :style="{ backgroundColor: buttonColor(priority) }"
          v-on="on"
          @click="updateTicketPriority(priority.id)"
        />
      </template>
      <span>{{ priority.name }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { priorities } from '../../utils/constants'

export default {
  name: 'PrioritySelector',
  props: {
    selected: {
      type: Number,
      default: () => 0,
    },
  },
  computed: {
    allPriorities() {
      return priorities
    },
  },
  methods: {
    buttonColor(priority) {
      if (this.selected !== priority.id) return null
      switch (priority.color) {
        case 'blue':
          return 'var(--color-blue)'
        case 'red':
          return 'var(--color-red)'
        case 'grey darken-2':
          return 'var(--color-grey--dark)'
        case 'orange':
          return 'var(--color-yellow)'
        default:
          return 'var(--color-grey--light)'
      }
    },
    updateTicketPriority(id) {
      this.$emit('on-update', id)
    },
  },
}
</script>
