
/**
 * Text is used to add a supporting text to your screen
 */
export default {
  props: {
    /**
     * Changes the html tag
     */
    tag: {
      type: String,
      default: 'p',
    },
    /**
     * Changes the styling of the text to visually communicate its emphasis
     *
     * paragraph - Default text body
     * alt-paragraph - Paragraph with reduced contrast
     * subdued - Italicize and reduce contrast
     * caption - Make the text smaller than default
     * intro - Make the text bigger than default
     */
    type: {
      type: String,
      default: 'paragraph',
      validator: (val: string) =>
        [
          'paragraph',
          'alt-paragraph',
          'subdued',
          'caption',
          'intro',
          'overline',
          'warning',
        ].includes(val),
    },
    truncate: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
      default: false,
    },
  },
}
