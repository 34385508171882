import {
  ACTIVE,
  APPROVED,
  ASSIGNED_TO_POOL,
  ASSIGNED_TO_SERVICE_PROVIDER,
  CANCELLED,
  COMPLETED,
  DRAFT,
  NO_APPROVAL,
  PAUSED,
  PENDING_APPROVAL,
  REQUESTED,
  SCHEDULED,
  REJECTED,
} from '@/models/job'
import {
  TASK_ACTIVE,
  TASK_CANCELLED,
  TASK_COMPLETED,
  TASK_NOT_STARTED,
  TASK_PAUSED,
} from '@/models/unit_number'

export const getTaskStatusText = (status: string) => {
  switch (status) {
    case TASK_NOT_STARTED:
      return 'Not Started'
    case TASK_ACTIVE:
      return 'Active'
    case TASK_PAUSED:
      return 'Paused'
    case TASK_COMPLETED:
      return 'Done'
    case TASK_CANCELLED:
      return 'Cancelled'
    default:
      return null
  }
}

export const getTaskStatusColor = (status: string) => {
  switch (status) {
    case TASK_ACTIVE:
      return 'orange lighten-4'
    case TASK_PAUSED:
      return 'yellow lighten-4'
    case TASK_COMPLETED:
      return 'green lighten-4'
    case TASK_CANCELLED:
      return 'red lighten-4'
    default:
      return null
  }
}

export const getJobStatusName = (status: string) => {
  /*
  status.replace(/([A-Z])/g, ' $1') is a regular expression pattern that looks for any uppercase letters in the status string. 
  When it finds an uppercase letter, it inserts a space before it using the replacement pattern ' $1'. 
  $1 refers to the matched uppercase letter.
  For example, if you pass in the string 'InProgress', the function will return 'In Progress'.
   */
  return status.replace(/([A-Z])/g, ' $1').trim()
}

export const getJobStatusColor = (status: string) => {
  switch (status) {
    case ASSIGNED_TO_POOL:
      return 'purple purple--text'
    case REQUESTED:
      return 'blue lighten-4'
    case ASSIGNED_TO_SERVICE_PROVIDER:
      return 'purple lighten-4'
    case SCHEDULED:
      return 'indigo lighten-4'
    case ACTIVE:
      return 'orange lighten-4'
    case PAUSED:
      return 'yellow lighten-4'
    case COMPLETED:
    case NO_APPROVAL:
      return 'green lighten-4'
    case PENDING_APPROVAL:
      return 'green green--text'
    case APPROVED:
      return 'green lighten-4'
    case CANCELLED:
    case REJECTED:
      return 'red lighten-4'
    case DRAFT:
      return 'grey lighten-4'
    default:
      return null
  }
}
