var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.shouldShowHover)?_c('div',{ref:"floating",staticClass:"floater",style:(_setup.floatingStyles)},[_c('div',{ref:"floatingArrow",staticClass:"floatingArrow",style:({
      position: 'absolute',
      width: '12px',
      height: '12px',
      background: '#fff',
      left:
        _setup.middlewareData.arrow?.x != null ? `${_setup.middlewareData.arrow?.x}px` : '',
      top:
        _setup.middlewareData.arrow?.y != null ? `${_setup.middlewareData.arrow?.y}px` : '',
      [_setup.staticSide]: '-6px',
      transform: 'rotate(45deg)',
    })}),(_setup.isLocation)?_c(_setup.LocationHover,{attrs:{"hovered-feature":_setup.fieldViewState.hoveredFeature ?? undefined}}):(_setup.isResource)?_c(_setup.ResourceHover,{attrs:{"hovered-feature":_setup.fieldViewState.hoveredFeature ?? undefined}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }