<template>
  <icon-two-line-cell
    :icon="getSubjectIcon"
    :primary-text="getSubjectName"
    :secondary-text="getBusinessUnitPath"
  />
</template>

<script>
import { ORGANIZATION } from '@/models/business-unit'
import { mapGetters } from 'vuex'
import IconTwoLineCell from '@/components/forms/cells/IconTwoLineCell'
import { getFormFields } from '@/utils/field-schema-helper'

export default {
  name: 'SubjectCell',
  components: { IconTwoLineCell },
  props: {
    subjectId: {
      type: String,
      required: true,
    },
    formId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getBusinessUnitById: 'businessUnits/getBusinessUnitById',
      getForm: 'forms/getById',
    }),
    businessUnit() {
      return this.getBusinessUnitById(this.subjectId)
    },
    getSubjectName() {
      return this.businessUnit?.name ?? ''
    },
    getSubjectIcon() {
      return this.businessUnit?.type === ORGANIZATION ? 'villa' : 'hive'
    },
    getBusinessUnitPath() {
      let title = ''
      const form = this.getForm(this.formId)
      if (form) {
        title = getFormFields(form.structure)?.subject?.title ?? ''
      }
      return title
    },
  },
}
</script>
