<script>
import Vue from 'vue'
import {
  getDefaultRegistry,
  getDefaultWidgetType,
  getSchemaType,
} from '@/utils/forms/form-resolver'
import { ContextualizedFormResolver } from '@/utils/forms/contextualized-form-resolver'

const COMPONENT_TYPES = {
  array: 'ArrayField',
  boolean: 'BooleanField',
  companySelector: 'CompanySelectorField',
  creatableSelector: 'CreatableSelectorField',
  FormButtons: 'FormButtonsField',
  genericSelector: 'GenericSelectorField',
  GroupRadioSelector: 'GroupRadioSelectorField',
  groupSelector: 'GroupSelectorField',
  Heading: 'HeadingField',
  imageWidget: 'ImageField',
  integer: 'IntegerField',
  integerStepper: 'IntegerField',
  locationSelector: 'LocationSelectorField',
  Markdown: 'MarkdownField',
  null: 'NullField',
  number: 'NumberField',
  numberStepper: 'NumberField',
  object: 'ObjectField',
  radio: 'RadioField',
  textarea: 'TextAreaField',
  UserSelector: 'UserSelectorField',
  string: 'StringField',
  BusinessUnitSelectorField: 'BusinessUnitSelectorField',
  latLngSelector: 'LatLngField',
  fileSelector: 'FileAttachmentField',
  inventorySelector: 'InventorySelectorField',
}

export default Vue.component('SchemaField', {
  props: {
    errorSchema: {
      type: Object,
    },
    formData: {
      // Boolean must come after String to prevent empty string from being converted to true.
      type: [Array, Number, Object, String, Boolean],
    },
    businessUnitId: {
      type: String,
      default: '',
    },
    params: {
      type: Object,
    },
    required: {
      type: Boolean,
    },
    rootSchema: {
      type: Object,
    },
    schema: {
      type: Object,
    },
    uiSchema: {
      type: Object,
    },
    formResolver: {
      type: ContextualizedFormResolver,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getFieldComponent(schema, uiSchema, fields) {
      const componentName =
        COMPONENT_TYPES[
          uiSchema['ui:widget'] ||
            uiSchema['ui:field'] ||
            getDefaultWidgetType(schema) ||
            getSchemaType(schema)
        ]

      if (!componentName && (schema.anyOf || schema.oneOf)) {
        return () => null
      }

      // Return unsupported widget
      return componentName in fields
        ? fields[componentName]
        : fields.UnsupportedField
    },
  },
  render: function (createElement) {
    const fields = getDefaultRegistry()
    const schema = this.formResolver.retrieveSchema(
      this.schema,
      this.rootSchema,
      this.formData
    )
    const uiSchema = this.uiSchema || {}
    const fieldComponent = this.getFieldComponent(schema, uiSchema, fields)

    let title = uiSchema['ui:title'] || schema.title
    const description = uiSchema['ui:description'] || schema.description
    if (title && this.required) {
      title += ' *'
    }

    return createElement(fieldComponent, {
      props: {
        errorSchema: this.errorSchema,
        formData: this.formData,
        businessUnitId: this.businessUnitId,
        params: this.params,
        rootSchema: this.rootSchema,
        formResolver: this.formResolver,
        schema,
        uiSchema,
        title,
        description,
        disabled: this.disabled,
      },
      on: {
        change: (data) => this.$emit('change', data),
      },
    })
  },
})
</script>

<style>
.typical-field-height {
  box-sizing: border-box;
  min-height: 48px;
}
</style>
