import axios from 'axios'
import { get } from './base'
import { convertToCamelCase } from '@/models/utils/casing'
import { useQuery } from '@tanstack/vue-query'
import moment from 'moment'
import { MapLayersResponse } from '@/models/map_layer_dto'

const mapLayersEndpoint = '/api/v2/maps/layers'

export function getMapLayers(signal?: AbortSignal) {
  const source = axios.CancelToken.source()

  const layers = get<MapLayersResponse>(mapLayersEndpoint, {
    cancelToken: source.token,
  }).then((response) => convertToCamelCase(response.data))

  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled by TanStack Query')
  })

  return layers
}

export function useGetMapLayersQuery() {
  return useQuery({
    queryKey: ['map-layers'],
    queryFn: ({ signal }) => getMapLayers(signal),
    staleTime: moment.duration(1, 'day').asMilliseconds(),
    cacheTime: Infinity,
  })
}
