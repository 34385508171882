import { get, post, put } from '../base'
import { useMutation, useQueryClient } from '@tanstack/vue-query'

const manageCompaniesEndPoint = '/api/v2/account-data/companies'

export function createClient(
  name: string,
  shortCode: string,
  billingAddress: string | null | undefined
) {
  return post(manageCompaniesEndPoint + '/client', {
    data: {
      short_code: shortCode,
      name,
      billing_address: billingAddress,
    },
  })
}

export function useCreateClientMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (variables: {
      shortCode: string
      name: string
      billingAddress: string | null | undefined
    }) =>
      createClient(
        variables.name,
        variables.shortCode,
        variables.billingAddress
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['connection-request', 'clients'])
    },
  })
}
