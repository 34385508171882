
export enum SnackType {
  ERROR = 'error',
  INFO = 'info',
  WARN = 'warn',
}

const snackType = {
  ERROR: SnackType.ERROR,
  INFO: SnackType.INFO,
  WARN: SnackType.WARN,
}

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    keepOpen: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: snackType.INFO,
      validator: (value) => Object.values(snackType).includes(value),
    },
    sticky: {
      type: Boolean,
      default: false,
    },
  },
  barColor(type) {
    switch (type) {
      case snackType.ERROR:
        return 'color-red--lighter'
      case snackType.WARN:
        return 'color-yellow--light'
      default:
        return 'color-blue--lighter'
    }
  },
  timeoutDuration(keepOpen) {
    return keepOpen ? -1 : 4000
  },
}
