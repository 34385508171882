<template>
  <vs-wrapper space="small">
    <TicketUnitNumberCrewSelector
      v-for="(userSid, index) in localCrew"
      :key="userSid + index"
      :index="index"
      :value="localCrew[index]"
      :users-list="selectableOperators(localCrew[index])"
      :can-remove-crew="localCrew.length > 1"
      :can-add-crew="canAddMoreCrew"
      :disabled="disabled"
      @input="handleInput"
      @on-add="addCrew"
      @on-delete="removeCrew"
    />
  </vs-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import TicketUnitNumberCrewSelector from './TicketUnitNumberCrewSelector'
import { cloneDeep } from 'lodash'

export default {
  components: { TicketUnitNumberCrewSelector },
  props: {
    crew: {
      type: Array[String],
      default: () => [],
    },
    resourceOwner: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localCrew: [],
    }
  },
  computed: {
    ...mapGetters({
      operatorsForBusinessUnit: 'users/getOperatorsForBusinessUnit',
      userBySid: 'users/getBySid',
    }),
    canAddMoreCrew() {
      return this.localCrew.every((crewMember) => crewMember !== '')
    },
  },
  watch: {
    crew(newValue) {
      this.setCrew(newValue)
    },
  },
  mounted() {
    this.setCrew(this.crew)
  },
  methods: {
    setCrew(crew) {
      if (crew.length === 0) {
        this.localCrew = ['']
      } else {
        this.localCrew = cloneDeep(crew)
      }
    },
    addCrew() {
      this.localCrew.push('')
    },
    removeCrew(index) {
      this.localCrew.splice(index, 1)
      this.$emit('crew-changed', this.localCrew)
    },
    handleInput(value) {
      this.localCrew.splice(value.index, 1, value.userSid)
      this.$emit('crew-changed', this.localCrew.filter(Boolean))
    },
    selectableOperators(currentlySelectedUserSid) {
      const selectableOperators = this.operatorsForBusinessUnit(
        this.resourceOwner
      ).filter((u) => !this.crew.includes(u.id))

      if (currentlySelectedUserSid) {
        return selectableOperators.concat(
          this.userBySid(currentlySelectedUserSid)
        )
      }

      return selectableOperators
    },
  },
}
</script>
