"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OneOfPropertyModel = exports.OneOfArrayModel = exports.DependenciesModel = void 0;
const schema_object_1 = require("../schema_object");
const schema_properties_1 = require("../schema_properties");
class DependenciesModel extends schema_object_1.SchemaObject {
    addConstCondition(edge, dependentFields) {
        var _a;
        const key = edge.dependentOn.uniqueKey();
        const requiredFieldKeys = dependentFields.filter((field) => field.isRequired).map((field) => field.uniqueKey());
        const propertyModel = new OneOfPropertyModel(edge.condition(), key, requiredFieldKeys);
        propertyModel.properties.addFields(dependentFields);
        (_a = this[key]) !== null && _a !== void 0 ? _a : (this[key] = new OneOfArrayModel());
        this[key].oneOf.push(propertyModel);
    }
    addRequiredCondition(edge) {
        var _a;
        const key = edge.dependentOn.uniqueKey();
        const propertyModel = new OneOfPropertyModel(edge.condition(), key, edge.requiredFields);
        (_a = this[key]) !== null && _a !== void 0 ? _a : (this[key] = new OneOfArrayModel());
        this[key].oneOf.push(propertyModel);
    }
    get(key) {
        return this[key];
    }
}
exports.DependenciesModel = DependenciesModel;
class OneOfArrayModel {
    constructor() {
        this.oneOf = [];
    }
}
exports.OneOfArrayModel = OneOfArrayModel;
class OneOfPropertyModel {
    constructor(condition, key, required) {
        this.properties = new schema_properties_1.SchemaProperties();
        this.properties[key] = condition;
        if (required.length > 0) {
            this.required = required;
        }
    }
}
exports.OneOfPropertyModel = OneOfPropertyModel;
