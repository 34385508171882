<template>
  <vs-wrapper space="small">
    <vs-heading type="title">Edit Pages/Flows</vs-heading>

    <vs-dialog
      title="Add Flow"
      confirm-on-close
      :visible="isDialogVisible"
      @on-close="toggleDialogVisible"
    >
      <vs-wrapper space="small">
        <vs-text> Give this flow an alphanumeric unique name.</vs-text>
        <vs-text type="subdued">
          Form submitters do not see this, and is only used as reference while
          building the form. Some special characters (e.g. / ; ~ , ! " ' ) are
          not permitted in flow names
        </vs-text>
        <vs-text-input
          v-model.lazy="flowName"
          label="Flow name"
        ></vs-text-input>
        <div class="d-flex justify-end">
          <vs-button
            type="secondary"
            label="Cancel"
            class="mr-2"
            @click="toggleDialogVisible"
          ></vs-button>
          <vs-button label="Save" @click="handleSave"></vs-button>
        </div>
      </vs-wrapper>
    </vs-dialog>

    <vs-wrapper v-for="flowKey in flowKeys">
      <vs-card accent="var(--color-blue--lighter)">
        <v-row align="center">
          <v-col>
            <vs-text-input disabled label="Flow name" :value="flowKey" />
          </v-col>
        </v-row>

        <v-row
          v-for="(pageTitle, i) in builder.pageTitles(flowKey)"
          class="ml-6"
          align="center"
        >
          <v-col class="mr-n4">
            <vs-text-input
              v-model="builder.pageTitles(flowKey)[i]"
              :value="pageTitle"
              label="Page Name"
              @input="renamePageTitle($event, i, flowKey)"
            />
          </v-col>
          <v-col cols="auto">
            <v-icon
              v-show="builder.pageTitles(flowKey).length > 1"
              color="red lighten-2"
              @click="deletePage(i, flowKey)"
            >
              delete
            </v-icon>
          </v-col>
        </v-row>

        <div class="d-flex justify-space-between">
          <vs-button
            v-if="flowKey !== '#root_schema'"
            class="pa-3"
            label="Delete Flow"
            type="tertiary"
            destructive
            @click="deleteFlow(0, flowKey)"
          />
          <vs-button
            class="pa-3 mr-9 ml-auto"
            label="Add Page"
            icon="post_add"
            type="secondary"
            @click="addPage(0, flowKey)"
          />
        </div>
      </vs-card>
    </vs-wrapper>

    <vs-button
      label="Add Flow"
      icon="route"
      full-width
      type="secondary"
      @click="addFlow"
    />
  </vs-wrapper>
</template>

<script>
import VsWrapper from '@/components/vision/VsWrapper'
import VsText from '@/components/vision/VsText'
import VsHeading from '@/components/vision/VsHeading'
import VsCard from '@/components/vision/VsCard'
import VsTextInput from '@/components/vision/Inputs/VsTextInput'
import VsButton from '@/components/vision/VsButton'
import VsDialog from '@/components/vision/VsDialog'
export default {
  name: 'EditPagesAndFlowsSettings',
  components: {
    VsDialog,
    VsButton,
    VsTextInput,
    VsCard,
    VsHeading,
    VsText,
    VsWrapper,
  },

  props: {
    builder: {
      type: Object,
      default: () => {},
    },
    flowKeys: {
      type: Array,
      default: () => [],
    },
  },

  data: () => {
    return {
      pageNumberToEdit: 0,
      flowKeyToEdit: '#root_schema',
      pageNumberToUpdate: 0,
      flowKeyToUpdate: '#root_schema',
      isDialogVisible: false,
      flowName: '',
    }
  },

  computed: {
    fields() {
      return this.builder.fieldsOfPage(
        this.pageNumberToEdit,
        this.flowKeyToEdit
      )
    },
  },

  methods: {
    toggleDialogVisible() {
      this.isDialogVisible = !this.isDialogVisible
      this.resetDialog()
    },
    resetDialog() {
      this.errors = []
      this.flowName = ''
    },
    handleSave() {
      const isValid = this.builder.validateFlowName(this.flowName)
      if (isValid) {
        this.$emit('add-flow', this.flowName)
        this.toggleDialogVisible()
        this.resetDialog()
      }
    },
    addFlow() {
      this.toggleDialogVisible()
    },
    showDeletePageDialog() {
      return confirm(
        'Are you sure you want to delete this page?\n' +
          'It will delete all of its fields and dependencies.'
      )
    },
    deleteFlow(pageNumber, flowKey) {
      this.$emit('delete-flow', flowKey)
    },
    addPage(pageNumber, flowKey) {
      this.$emit('add-page', flowKey)
    },
    deletePage(pageNumber, flowKey) {
      console.log(pageNumber, flowKey)
      const shouldDeletePage =
        this.fields.length > 0 ? this.showDeletePageDialog() : true
      if (shouldDeletePage) {
        this.$emit('delete-page', {
          index: pageNumber,
          flowKey,
        })
      }
    },
    renamePageTitle(pageTitle, pageNumber, flowKey) {
      this.$emit('rename-page-title', {
        title: pageTitle,
        index: pageNumber,
        flowKey,
      })
    },
  },
}
</script>

<style scoped></style>
