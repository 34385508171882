"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LatLntSelectorDefinition = void 0;
const json_schema_keys_1 = require("../json_schema_keys");
const lat_lng_selector_ui_schema_1 = require("../models/field_ui_schema/lat_lng_selector_ui_schema");
const form_field_definition_1 = require("./form_field_definition");
class LatLntSelectorDefinition extends form_field_definition_1.FormattedValueField {
    constructor() {
        super(...arguments);
        this.format = json_schema_keys_1.JsonSchemaKeys.DATA_FORMAT_POINT;
        this.dataType = json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_STRING;
    }
    uiSchema() {
        return new lat_lng_selector_ui_schema_1.LatLngSelectorUiSchema();
    }
}
exports.LatLntSelectorDefinition = LatLntSelectorDefinition;
