interface IconInfo {
  icon: string
  color: string
  topPadding: number
  bottomPadding: number
  leftPadding: number
  rightPadding: number
}

const defaultIconInfo: IconInfo = {
  icon: 'mdi-square-edit-outline',
  color: 'grey lighten-3',
  topPadding: 2,
  bottomPadding: 0,
  leftPadding: 0,
  rightPadding: 0,
}

const eventActionIconInfoMap: Record<string, Partial<IconInfo>> = {
  opened: { icon: 'mdi-email-open-outline' },
  requested: {
    icon: 'mdi-send mdi-rotate-315',
    color: 'blue lighten-4',
    topPadding: 0,
    bottomPadding: 2,
    leftPadding: 2,
  },
  scheduled: {
    icon: 'mdi-calendar',
    color: 'indigo lighten-4',
  },
  assigned: {
    icon: 'mdi-share-variant',
    color: 'purple lighten-4',
    rightPadding: 2,
  },
  activated: {
    icon: 'mdi-play-outline',
    color: 'orange lighten-4',
    leftPadding: 2,
  },
  paused: {
    icon: 'mdi-pause',
    color: 'yellow lighten-4',
  },
  completed: {
    icon: 'mdi-check',
    color: 'green lighten-4',
  },
  approved: {
    icon: 'mdi-check-all',
    color: 'green lighten-4',
  },
  cancelled: {
    icon: 'mdi-close',
    color: 'red lighten-4',
  },
  comment: {
    icon: 'comment',
    color: 'blue lighten-4',
  },
  unapproved: {
    icon: 'mdi-undo',
    color: 'red lighten-4',
  },
  rejected_approval: {
    icon: 'mdi-close',
    color: 'red lighten-4',
    bottomPadding: 2,
    leftPadding: 2,
  },
  submitted_for_approval: {
    icon: 'mdi-thumbs-up-down-outline',
    color: 'grey lighten-3',
  },
  approval_request_cancelled: {
    icon: 'mdi-undo',
    color: 'grey lighten-3',
  },
  location_visited: {
    icon: 'mdi-map-marker-radius',
    color: 'orange lighten-4',
    bottomPadding: 2,
  },
  assigned_to_pool: {
    icon: 'mdi-share',
    color: 'purple lighten-4',
  },
  availability_accepted: {
    icon: 'mdi-share-variant',
    color: 'green lighten-4',
    rightPadding: 2,
  },
  availability_declined: {
    icon: 'mdi-share-off',
    color: 'red lighten-4',
    rightPadding: 2,
  },
  signed: {
    icon: 'mdi-draw-pen',
    color: 'green lighten-4',
  },
  signature_deleted: {
    icon: 'mdi-delete-outline',
    color: 'red lighten-4',
  },
  reopen: {
    icon: 'mdi-backup-restore',
    color: 'orange lighten-4',
  },
  trigger_threshold_resolved: {
    icon: 'mdi-trending-down',
    color: 'blue lighten-4',
  },
  item_received_at_location: {
    icon: 'mdi-package-variant-closed',
    color: 'blue lighten-4',
  },
}

export function getIconForChange(type: string): IconInfo {
  return {
    ...defaultIconInfo,
    ...(eventActionIconInfoMap[type] || {}),
  }
}
