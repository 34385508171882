import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useCurrentUser } from '@/common/useCurrentUser'

type LastEnteredCustomerNameInfo = {
  [userSid: string]: {
    lastEnteredCustomerName?: string
  }
}
export const useInvoicesSyncStore = defineStore(
  'invoices-sync',
  () => {
    const savedState = ref<LastEnteredCustomerNameInfo>({})
    const currentUser = useCurrentUser()
    const lastEnteredCustomerName = computed(() =>
      currentUser.value && currentUser.value.userSid in savedState.value
        ? savedState.value[currentUser.value.userSid].lastEnteredCustomerName
        : undefined
    )

    const updateLastEnteredCustomerName = (newCustomerNameValue: string) => {
      savedState.value = {
        ...savedState.value,
        [currentUser.value.userSid]: {
          lastEnteredCustomerName: newCustomerNameValue,
        },
      }
    }

    return {
      savedState,
      lastEnteredCustomerName,
      updateLastEnteredCustomerName,
    }
  },
  {
    persist: true,
  }
)
