"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ObjectArrayPropertyDescription = void 0;
const json_schema_keys_1 = require("../../json_schema_keys");
const schema_object_1 = require("../schema_object");
const field_property_description_1 = require("./field_property_description");
class ObjectArrayPropertyDescription extends field_property_description_1.DefaultFieldPropertyDescription {
    constructor(objectField) {
        super(objectField);
        const schema = objectField.builder.build().pages[0].schema;
        const items = new schema_object_1.SchemaObject();
        items[json_schema_keys_1.JsonSchemaKeys.TYPE_KEY] = json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_OBJECT;
        items[json_schema_keys_1.JsonSchemaKeys.PROPERTIES_KEY] = schema.properties;
        items[json_schema_keys_1.JsonSchemaKeys.DEPENDENCIES_KEY] = schema.dependencies;
        items[json_schema_keys_1.JsonSchemaKeys.TITLE_KEY] = objectField.title;
        items[json_schema_keys_1.JsonSchemaKeys.REQUIRED_KEY] = schema.required;
        this[json_schema_keys_1.JsonSchemaKeys.ITEMS_KEY] = items;
        if (objectField.minimumItems) {
            this[json_schema_keys_1.JsonSchemaKeys.MIN_ITEMS_KEY] = objectField.minimumItems;
        }
        if (objectField.maximumItems) {
            this[json_schema_keys_1.JsonSchemaKeys.MAX_ITEMS_KEY] = objectField.maximumItems;
        }
    }
}
exports.ObjectArrayPropertyDescription = ObjectArrayPropertyDescription;
