<template>
  <div class="vs-button-group">
    <vs-button
      v-for="item in items"
      :key="item.text"
      :label="item.text"
      :icon="item.icon"
      :small="small"
      :to="item.to"
      shrink
      type="secondary"
      :class="{ 'is-active': item.active }"
      v-on="getListeners(item)"
    />
  </div>
</template>

<script>
export default {
  /**
   * Provides a set of related actions so that users may choose between one or
   * more options.
   */
  props: {
    /**
     *  The list of actions that the user can choose from.
     *
     * *Example*
     * ```html
     * <vs-toggle-buttons
     *   :items="[{ text: 'Day', to: '/day' }, { text: 'Week', to: '/week'} ]"
     * />
     * ```
     *
     * @param {Object} items Items object.
     * @param {String} items.text The label of the button.
     * @param {String} items.icon Adds an icon beside the label.
     * @param {Boolean} items.active Highlights the button.
     * @param {Function} items.to Router link.
     * @param {Function} items.onClick Click callback.
     */
    items: {
      type: Array[Object],
      default: null,
      required: true,
      validator: (value) => value.length > 1,
    },
    /**
     * Makes the button small
     */
    small: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getListeners(item) {
      if (item.onClick) {
        return { click: item.onClick }
      } else {
        return null
      }
    },
  },
}
</script>

<style scoped>
.vs-button-group {
  display: inline-block;
}

.vs-button-group .vs-button {
  background-color: var(--color-white);
  border-radius: 0;
  margin-left: -1px;
}

.vs-button-group .vs-button:first-child {
  margin-left: 0;
  border-top-left-radius: var(--space-larger);
  border-bottom-left-radius: var(--space-larger);
}

.vs-button-group .vs-button:last-child {
  border-top-right-radius: var(--space-larger);
  border-bottom-right-radius: var(--space-larger);
}

/**
 * 1. The v-ripple on button adds a position:static on mouseup. This counters it.
 */

.vs-button-group .vs-button.is-active {
  position: relative !important; /* 1 */
  z-index: var(--elevation-base);
  background-color: var(--color-blue--lighter);
  border-color: var(--color-blue--light);
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
}

.vs-button-group .vs-button:focus,
.vs-button-group .vs-button:hover {
  background-color: var(--color-blue--lighter);
}
</style>
