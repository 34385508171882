import { mapActions, mapGetters, mapState } from 'vuex'
import moment from 'moment'
import { isArray, sortBy } from 'lodash'
import { FollowupActionStatus } from '@/models/form_submission'

export default {
  computed: {
    ...mapState({
      options: (state) => state.formSubmissions.tags,
      locations: (state) => state.locations.all,
    }),
    tagOptions() {
      const values = new Set([...this.getTags.map((tag) => tag.name)])
      return [...values].map((tag) => ({ name: tag, id: tag }))
    },
    ...mapGetters({
      dateRange: 'formSubmissionsFilters/getDateRange',
      getDateRange: 'formSubmissionsFilters/getDateRange',
      getAllVisibleForms: 'forms/getAllVisible',
      getSubjectSearchIds: 'formSubmissionsFilters/getSubjectSearchIds',
      isFiltersVisible: 'formSubmissionsFilters/getFilterAsideVisibility',
      getTags: 'formSubmissions/getTags',
    }),
    dateRangeStart() {
      return this.getDateRange.start ? moment(this.getDateRange.start) : null
    },
    dateRangeEnd() {
      return this.getDateRange.end ? moment(this.getDateRange.end) : null
    },
    dateRangeLabel() {
      return this.hasDateRange
        ? `${
            this.dateRangeEnd.diff(this.dateRangeStart, 'days') + 1
          } dates selected`
        : 'Showing all dates'
    },
    hasDateRange() {
      return this.dateRangeStart !== null && this.dateRangeEnd !== null
    },
    formNameOptions() {
      const options = []
      const groupedForms = {}

      for (const form of Object.values(this.getAllVisibleForms)) {
        if (form.accessGrants.length > 0) {
          const categoryName = form.category?.name ?? 'Uncategorized'
          if (!isArray(groupedForms[categoryName])) {
            groupedForms[categoryName] = []
          }
          groupedForms[categoryName].push(form)
        }
      }

      const sortedGroupedForms = Object.keys(groupedForms)
        .sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1))
        .reduce(
          (grouped, key) =>
            Object.assign(grouped, { [key]: groupedForms[key] }),
          {}
        )

      for (const [categoryName, forms] of Object.entries(sortedGroupedForms)) {
        options.push({ header: true, text: categoryName })
        for (const form of sortBy(forms, [(form) => form.name.toLowerCase()])) {
          options.push(form)
        }
      }

      return options
    },
    getFormActions() {
      return [
        { id: FollowupActionStatus.None, name: 'None' },
        {
          id: FollowupActionStatus.RequiredAndUnassigned,
          name: 'Required and unassigned',
        },
        {
          id: FollowupActionStatus.RequiredAndAssigned,
          name: 'Required and assigned',
        },
        { id: FollowupActionStatus.Completed, name: 'Completed' },
      ]
    },
    baseLocations() {
      const locations = Object.values(this.locations)
      locations.forEach((l) => {
        l.name = l.location
      })
      return [
        {
          id: null,
          location: 'No location',
          name: 'No location',
          parent_id: null,
        },
        ...locations,
      ]
    },
  },
  methods: {
    ...mapActions({
      showFiltersAside: 'formSubmissionsFilters/showAside',
      hideFiltersAside: 'formSubmissionsFilters/hideAside',
    }),
  },
}
