"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fileSelectorFieldMatcher = void 0;
const file_selector_definition_1 = require("../../form_fields/file_selector_definition");
const json_schema_keys_1 = require("../../json_schema_keys");
const fileSelectorFieldMatcher = (uniqueKey, isRequired, properties, uiSchema, fieldDefinitions, next) => {
    const type = properties[json_schema_keys_1.JsonSchemaKeys.FORMAT_KEY];
    if (type === json_schema_keys_1.JsonSchemaKeys.DATA_FORMAT_DATA_URL) {
        const field = new file_selector_definition_1.FileSelectorDefinition();
        field.title = properties.title;
        field.description = properties.description;
        field.isRequired = isRequired;
        field.defaultValue = properties.default;
        return field;
    }
    return next(uniqueKey, isRequired, properties, uiSchema, fieldDefinitions);
};
exports.fileSelectorFieldMatcher = fileSelectorFieldMatcher;
