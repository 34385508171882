import { render, staticRenderFns } from "./VsSnackbar.vue?vue&type=template&id=65880b68&"
import script from "./VsSnackbar.vue?vue&type=script&lang=ts&"
export * from "./VsSnackbar.vue?vue&type=script&lang=ts&"
import style0 from "./VsSnackbar.vue?vue&type=style&index=0&id=65880b68&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports