import moment from 'moment/moment'
import {
  computed,
  ref,
  Ref,
  watch,
  watchEffect,
  nextTick,
  UnwrapNestedRefs,
} from 'vue'
import { useRoute } from 'vue-router/composables'
import { useJobFilterStore } from '../field-view-next/JobsFilterStore'
import { storeToRefs } from 'pinia'
import { MaybeRef } from '@vueuse/core'

export interface JobFilter {
  start: Ref<{ toISOString(): string } | null>
  end: Ref<{ toISOString(): string } | null>
  statuses: Ref<string[] | null> | string[]
  locations: Ref<(number | string)[] | null>
  approvalStatuses: Ref<
    ('Approved' | 'PendingApproval' | 'NoApproval')[] | null
  >
  divisions: Ref<string[] | null>
  resourceTypes: Ref<number[] | null>
  serviceProviders: Ref<string[] | null>
  priorities: Ref<any[] | null>
  query: Ref<string>
  archived: Ref<boolean>
  activities?: Ref<number[] | null>
  resourceId?: Ref<number | null>
  batchStatuses: Ref<('Batched' | 'NotBatched')[] | null>
}

export interface JobQueueFilter {
  statuses: Ref<string[]> | string[]
  divisions: Ref<string[]>
  resourceTypes: Ref<number[] | null>
  priorities: Ref<number[]>
  query: Ref<string>
}

export interface JobNumberFilter {
  jobNumbers: Ref<(number | string)[]>
}

export function useDailyViewFilters(): JobQueueFilter {
  const store = useJobFilterStore()

  const selectedPriorities = computed(() =>
    nullIfEmpty(store.selectedPriorities)
  )
  const selectedDivisions = computed(() => nullIfEmpty(store.selectedDivisions))
  const selectedResourceTypes = computed(() =>
    nullIfEmpty(store.selectedResourceTypes)
  )
  const search = computed(() => store.search)

  return {
    statuses: ['Requested', 'AssignedToServiceProvider'],
    divisions: selectedDivisions as any,
    resourceTypes: selectedResourceTypes as any,
    priorities: selectedPriorities as any,
    query: search as any,
  }
}

export function useFilterAside() {
  const store = useJobFilterStore()
  const { isShowingFilters } = storeToRefs(store)

  return {
    showFiltersAside: isShowingFilters,
    handleShowFilterAside(show: string | boolean = true) {
      if (show) {
        store.showFilterPanel()
        if (show === 'search') {
          nextTick(() => {
            document
              .querySelector<HTMLInputElement>('#table-search input')
              ?.focus()
          })
        }
      } else {
        store.hideFilterPanel()
      }
    },
    resetAllFilters: store.resetAllFilters
  }
}

export function useTopBarFilters(query) {
  const store = useJobFilterStore()
  const { selectedPriorities } = storeToRefs(store)

  const searchButtonLabel = computed(() => {
    const search = query.query
    if (search) {
      let label = search
      const maxStringLength = 14
      if (search.length > maxStringLength) {
        label = `${search.substring(0, maxStringLength)}…`
      }
      return `Look for: '${label}'`
    } else {
      return null
    }
  })

  const dateRangeString = computed(() => {
    const { start, end } = query
    if (start == null && end == null) {
      return 'All dates'
    }

    if (start.isSame(end, 'day')) {
      return start.format('MMM DD')
    } else if (start.isSame(end, 'month')) {
      return `${start.format('MMM DD')} - ${end.format('DD')}`
    } else {
      return `${start.format('MMM DD')} to ${end.format('MMM DD')}`
    }
  })

  watchEffect(() => {
    if (query.archived) {
      query.page = 1
    }
  })

  return {
    searchButtonLabel,
    dateRangeString,
    selectedPriorities,
    handleSelectPriority(values) {
      store.updatePriority(values)
      query.page = 1
    },
    toggleArchived() {
      query.archived = !query.archived
    },
  }
}

export interface ProjectViewFilter {
  statuses: Ref<string[]>
  query: Ref<string>
  projectId?: Ref<string>
}

export function useProjectViewFilters(
  projectId: string,
  selectedStatusFilterForProject: Ref<{ id: string; name: string }[]>
): ProjectViewFilter {
  const selectedStatuses = computed(() =>
    selectedStatusFilterForProject.value.map((status) => status.id)
  )
  return {
    statuses: selectedStatuses,
    query: ref(''),
    projectId: ref(projectId),
  }
}

export interface BatchViewFilter {
  statuses: Ref<string[]>
  query: Ref<string>
  invoiceId?: Ref<string>
}

export function useBatchViewFilters(
  batchId: MaybeRef<UnwrapNestedRefs<string>>,
  selectedStatusFilterForInvoice: Ref<{ id: string; name: string }[]>
): BatchViewFilter {
  const selectedStatuses = computed(() =>
    selectedStatusFilterForInvoice.value.map((status) => status.id)
  )
  return {
    statuses: selectedStatuses,
    query: ref(''),
    invoiceId: ref(batchId),
  }
}

export type FieldViewFilter = {
  start: Ref<{ toISOString(): string }>
  end: Ref<{ toISOString(): string }>
  locations: (number | null)[]
}

export function useFieldViewFilters(
  type: 'currentJobs' | 'pastJobs',
  destinationLocationId: number | null
): FieldViewFilter {
  const start = ref(moment().startOf('day'))
  const end = ref(moment().endOf('day').add(1, 'years'))

  if (type === 'pastJobs') {
    start.value = moment().startOf('day').subtract(1, 'years')
    end.value = moment().startOf('day').subtract(1, 'days')
  }

  return {
    start,
    end,
    locations: [destinationLocationId],
  }
}

export function useMapFilters(): JobFilter {
  const store = useJobFilterStore()

  const rangeStart = computed(() => store.start)
  const rangeEnd = computed(() => store.end)

  const selectedPriorities = computed(() =>
    nullIfEmpty(store.selectedPriorities)
  )
  const selectedStatuses = computed(() =>
    nullIfEmpty(store.selectedJobStatuses)
  )
  const selectedApprovalStatuses = computed(() =>
    nullIfEmpty(store.selectedApprovalStatuses)
  )
  const selectedDivisions = computed(() => nullIfEmpty(store.selectedDivisions))
  const selectedServiceProviders = computed(() =>
    nullIfEmpty(store.selectedServiceProviders)
  )
  const selectedActivities = computed(() =>
    nullIfEmpty(store.selectedActivities)
  )
  const selectedResourceTypes = computed(() =>
    nullIfEmpty(store.selectedResourceTypes)
  )
  const selectedLocations = computed(() => nullIfEmpty(store.selectedLocations))
  const selectedBatchStatuses = computed(() =>
    nullIfEmpty(store.selectedBatchStatuses)
  )
  const search = ref('')
  const archived = ref(false)

  return {
    start: rangeStart,
    end: rangeEnd,
    priorities: selectedPriorities,
    divisions: selectedDivisions,
    serviceProviders: selectedServiceProviders,
    statuses: selectedStatuses,
    approvalStatuses: selectedApprovalStatuses,
    resourceTypes: selectedResourceTypes,
    activities: selectedActivities,
    locations: selectedLocations,
    batchStatuses: selectedBatchStatuses,
    query: search,
    archived,
  }
}

export function useFilters(): JobFilter {
  const store = useJobFilterStore()
  const route = useRoute()

  const rangeStart = computed(() => store.start)
  const rangeEnd = computed(() => store.end)

  const search = computed(() => store.search)

  const selectedPriorities = computed(() =>
    nullIfEmpty(store.selectedPriorities)
  )
  const selectedStatuses = computed(() =>
    nullIfEmpty(store.selectedJobStatuses)
  )
  const selectedApprovalStatuses = computed(() =>
    nullIfEmpty(store.selectedApprovalStatuses)
  )
  const selectedDivisions = computed(() => nullIfEmpty(store.selectedDivisions))
  const selectedServiceProviders = computed(() =>
    nullIfEmpty(store.selectedServiceProviders)
  )
  const selectedActivities = computed(() =>
    nullIfEmpty(store.selectedActivities)
  )
  const selectedResourceTypes = computed(() =>
    nullIfEmpty(store.selectedResourceTypes)
  )
  const selectedLocations = computed(() => nullIfEmpty(store.selectedLocations))
  const selectedBatchStatuses = computed(() =>
    nullIfEmpty(store.selectedBatchStatuses)
  )

  const showArchived = ref(route.query.archived === 'true')

  watch(route, (_, newRoute) => {
    if ('archived' in newRoute.query) {
      showArchived.value = newRoute.query.archived === 'true'
    }
  })

  return {
    activities: selectedActivities,
    approvalStatuses: selectedApprovalStatuses,
    archived: showArchived,
    divisions: selectedDivisions,
    end: rangeEnd,
    locations: selectedLocations,
    priorities: selectedPriorities,
    query: search,
    resourceTypes: selectedResourceTypes,
    serviceProviders: selectedServiceProviders,
    start: rangeStart,
    statuses: selectedStatuses,
    batchStatuses: selectedBatchStatuses,
  }
}

/**
 * We need to convert our empty arrays into nulls before sending the search
 * request to the api otherwise the api will return no results
 */
const nullIfEmpty = (value?: any[]) => {
  if (value?.length === 0) {
    return null
  }
  return value || null
}
