<template>
  <v-data-table
    :headers="headers"
    :items="transformedItems"
    :header-props="{ sortIcon: 'mdi-menu-up' }"
    :options="pagination"
    :disable-sort="true"
    fixed-header
    :item-class="selectedRowClass"
    hide-default-footer
    @click:row="handleClickRow"
  >
    <template #item.form="{ value }">
      <form-cell :form-id="value" />
    </template>

    <template #item.submittedByUser="{ item }">
      <submitter-cell
        :user-sid="item.submittedByUser"
        :submitters-business-unit-id="item.submittersBusinessUnitId"
      />
    </template>

    <template #item.subjectId="{ item }">
      <subject-cell
        v-if="item.subjectId"
        :subject-id="item.subjectId"
        :form-id="item.form"
      />
      <vs-text v-else>-</vs-text>
    </template>

    <template #item.subjectLocation="{ item }">
      <subject-location-cell
        v-if="item.subjectLocation"
        :subject-location="item.subjectLocation"
      />
      <vs-text v-else>-</vs-text>
    </template>

    <template #item.assignee="{ value }">
      <assignee-cell v-if="value" :assignee="value" />
      <vs-text v-else>-</vs-text>
    </template>

    <template #item.actions="{ value }">
      <actions-cell v-if="hasAction(value)" :actions="value" />
      <vs-text v-else>-</vs-text>
    </template>

    <template #item.tags="{ value }">
      <tags-cell :tags="value" />
    </template>

    <template #item.jobNumber="{ item }">
      <job-cell
        v-if="item.jobNumber"
        :jobNumber="item.jobNumber"
      />
    </template>
  </v-data-table>
</template>

<script>
import { formatDateTime } from '@/utils/date-formatting'
import { mapGetters } from 'vuex'
import { DEFAULT_FORM_SUBMISSION_PAGE_SIZE } from '@/store/modules/form-submissions'
import moment from 'moment'
import SubjectCell from '@/components/forms/cells/SubjectCell'
import AssigneeCell from '@/components/forms/cells/AssigneeCell'
import ActionsCell from '@/components/forms/cells/ActionsCell'
import SubmitterCell from '@/components/forms/cells/SubmitterCell'
import FormCell from '@/components/forms/cells/FormCell'
import TagsCell from '@/components/forms/cells/TagsCell'
import SubjectLocationCell from '@/components/forms/cells/SubjectLocationCell'
import JobCell from '@/components/forms/cells/JobCell'

export default {
  name: 'FormsTable',
  components: {
    SubjectCell,
    SubjectLocationCell,
    AssigneeCell,
    ActionsCell,
    SubmitterCell,
    FormCell,
    TagsCell,
    JobCell
  },
  props: {
    selectedFormId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      headers: [
        { text: 'ID#', value: 'number' },
        {
          text: 'Date submitted',
          value: 'submittedOnDisplay',
        },
        {
          text: 'Form name',
          align: 'start',
          value: 'form',
        },
        { text: 'Submitter', value: 'submittedByUser' },
        { text: 'Subject', value: 'subjectId' },
        { text: 'Location', value: 'subjectLocation' },
        { text: 'Assignee', value: 'assignee' },
        { text: 'Actions', value: 'actions' },
        { text: 'Tags', value: 'tags' },
        { text: 'Job Number', value: 'jobNumber' },
      ],
      pagination: {
        itemsPerPage: DEFAULT_FORM_SUBMISSION_PAGE_SIZE,
      },
    }
  },
  computed: {
    ...mapGetters({
      getPage: 'formSubmissions/getPage',
    }),
    transformedItems() {
      return this.getPage.map((item) => {
        return {
          number: item.number,
          form: item.formId,
          submittedByUser: item.createdBy,
          submittersBusinessUnitId: item.submitterBusinessUnitId,
          submittedOnDisplay: formatDateTime(moment.utc(item.created).local()),
          id: item.formSubmissionId,
          subjectId: item.subjectId,
          subjectLocation: item.subjectLocation,
          assignee: item.assignedTo,
          actions: item.followUpActions,
          tags: item.tags,
          jobNumber: item.jobNumber,
        }
      })
    },
  },
  methods: {
    hasAction(actions) {
      return actions.length > 0
    },
    handleClickRow(item) {
      this.$router.push({
        name: 'view-form-submission',
        params: { id: item.id },
      })
    },
    selectedRowClass(item) {
      return { 'color-grey--lightest': item.id === this.selectedFormId }
    },
  },
}
</script>

<style scoped>
.v-data-table,
::v-deep .v-data-table__wrapper {
  height: 100%;
  overflow: auto;
}

.v-data-table thead tr {
  height: auto;
}

.v-data-table::v-deep {
  font-family: var(--font-regular);
}

::v-deep th[role='columnheader'] {
  white-space: nowrap;
  height: auto !important;
  z-index: var(--elevation-base);
  padding: var(--space-small) var(--space-base) !important;
}
</style>
