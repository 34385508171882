<template>
  <v-dialog v-model="show" width="1000" :persistent="persistent" scrollable>
    <template #activator="{ on }">
      <slot name="activator" v-on="on" />
    </template>

    <v-card>
      <v-app-bar dense color="primary" dark clipped-right>
        <v-btn icon @click.stop="dialogClosed">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>Send emails</v-toolbar-title>

        <v-spacer />

        <v-progress-circular
          v-if="isNotifying"
          indeterminate
        ></v-progress-circular>
        <v-tooltip v-else top z-index="900">
          <template #activator="{ on }">
            <v-btn
              icon
              :disabled="allSelectedCount === 0"
              v-on="on"
              @click.stop="sendNotificationAndEmail"
            >
              <v-icon>send</v-icon>
            </v-btn>
          </template>
          <span>Send email</span>
        </v-tooltip>
      </v-app-bar>

      <vs-alert v-if="showErrorMessage" type="error">
        {{ errorMessage }}
      </vs-alert>

      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="candidates"
        hide-default-footer
        class="elevation-1"
        show-select
        item-key="key"
        :items-per-page="50"
      >
        <template #items="props">
          <tr>
            <td>
              <v-checkbox
                v-model="props.selected"
                primary
                hide-details
              ></v-checkbox>
            </td>
            <td>{{ props.item.name }}</td>
            <td>{{ props.item.email }}</td>
            <td>{{ props.item.roles.join(', ') }}</td>
            <td>{{ props.item.description }}</td>
          </tr>
        </template>
      </v-data-table>
      <v-list two-line subheader :expand="true">
        <v-list-group :value="show">
          <template #activator="{ on }">
            <v-list-item v-on="on">
              <v-list-item-content>
                <v-list-item-title
                  >Additional Recipients
                  {{ emailCountSuffix }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item>
            <v-list-item-content>
              <v-text-field
                v-model.trim="customEmail"
                class="add-email-text-field"
                :rules="emailRules"
                label="Add an email address"
                hint="Notify users who do not have an IronSight account"
                persistent-hint
                append-icon="add"
                @click:append="addCustomEmail"
                @keydown.native.enter="addCustomEmail"
              />
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-for="email in emails" :key="email">
            <v-list-item-content @click="editEmail(email)">
              <v-list-item-title>{{ email }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon ripple @click="deleteEmail(email)">
                <v-icon>delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import { sendEmailNotificationForJob } from '../../api/jobs'
import { compact, groupBy, mapValues } from 'lodash'

function mergeDescriptions(userDescription, description) {
  if (!userDescription) {
    return description
  }

  if (userDescription.includes(description)) {
    return userDescription
  }

  return `${userDescription}, ${description}`
}

function updateUserDescription(user, description) {
  if (user) {
    return Object.assign({}, user, {
      description: mergeDescriptions(user.description, description)
    })
  }

  return user
}

function updateCandidates(candidates, user, description, ticket) {
  if (user) {
    const candidate = updateUserDescription(
      candidates.get(user.id) || user,
      description
    )
    candidate.ticketId = ticket.id
    candidate.ticketName = `Job ${ticket.id}`
    candidate.key = `${ticket.id}-${user.email}`
    if (candidate) {
      candidates.set(candidate.id, candidate)
    }
  }
  return candidates
}

export default {
  name: 'SendEmailDialog',
  props: {
    value: Boolean, // This must be the variable name to support v-model
    persistent: Boolean,
    jobId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isNotifying: false,
      selected: [],
      showErrorMessage: false,
      errorMessage: '',
      customEmail: '',
      emailRules: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          'E-mail must be valid',
      ],
      emails: [],
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: 'Roles',
          value: 'roles',
        },
        {
          text: 'Description',
          value: 'description',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getJobById: 'jobs/getById',
      userBySid: 'users/getBySid',
    }),
    ticket() {
      return this.getJobById(this.jobId)
    },
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    candidates() {
      return this.ticket ? this.getCandidatesForTicket(this.ticket) : []
    },
    emailCountSuffix() {
      if (this.emails.length === 0) {
        return ''
      } else {
        return `(${this.emails.length})`
      }
    },
    allSelectedCount() {
      return this.selected.length + this.emails.length
    },
    allEmailsGroupedByTicketId() {
      if (!this.ticket) {
        return
      }
      const groupedCandidates = groupBy(this.selected, (c) => c.ticketId)
      const groupedEmails = mapValues(groupedCandidates, (candidates) =>
        candidates.map((c) => c.email)
      )
      groupedEmails[this.ticket.id] = compact(
        this.emails.concat(groupedEmails[this.ticket.id])
      )
      return groupedEmails
    },
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue === true && oldValue === false) {
        this.selected = this.candidates
      }
    },
  },
  methods: {
    getCandidatesForTicket(ticket) {
      let candidates = new Map()
      if (ticket) {
        const creator = this.userBySid(ticket.createdBy)
        candidates = updateCandidates(candidates, creator, 'Creator', ticket)

        if (ticket.createdBy !== ticket.modifiedBy) {
          const mrModifier = this.userBySid(ticket.modifiedBy)
          candidates = updateCandidates(
            candidates,
            mrModifier,
            'Last Modified Job',
            ticket
          )
        }

        const customer = this.userBySid(ticket.customerSid)
        if (customer) {
          candidates = updateCandidates(
            candidates,
            customer,
            'Requester',
            ticket
          )
        }

        ticket.followers.forEach((follower) => {
          const tempFollower = this.userBySid(follower)
          candidates = updateCandidates(
            candidates,
            tempFollower,
            'Follower',
            ticket
          )
        })

        const tempCrewUnit = ticket.unit
        const description = tempCrewUnit && `Assigned to ${tempCrewUnit.name} `

        ticket.crew.forEach((member) => {
          const tempCrew = this.userBySid(member)
          candidates = updateCandidates(
            candidates,
            tempCrew,
            description || tempCrew.description,
            ticket
          )
        })
      }

      return [...candidates.values()]
    },
    dialogClosed() {
      this.show = false
      this.$emit('set-email-dialog-visibility', false)
    },
    async sendNotificationAndEmail() {
      const userSids = this.selected.map((u) => u.id)
      this.isNotifying = true
      await sendEmailNotificationForJob(
        this.ticket.id,
        this.allEmailsGroupedByTicketId,
        userSids
      ).catch((err) => {
        console.error(err)
        this.showErrorMessage = true
        this.errorMessage = err
      })
      this.isNotifying = false
      setTimeout(() => {
        this.$emit('notification-sent')
        this.dialogClosed()
      }, 100)
    },
    addCustomEmail() {
      this.emails.push(this.customEmail)
      this.customEmail = ''
    },
    deleteEmail(email) {
      this.emails = this.emails.filter((el) => el !== email)
    },
    editEmail(email) {
      this.customEmail = email
      this.emails = this.emails.filter((el) => el !== email)
    },
  },
}
</script>

<style>
.add-email-text-field {
  width: 100%;
}
</style>
