export const APP_INITIALIZED = 'APP_INITIALIZED'
export const SET_CLIENT_VERSION = 'SET_CLIENT_VERSION'
export const SET_JOB_DATA_LAST_FETCH = 'SET_JOB_DATA_LAST_FETCH'

export default {
  [APP_INITIALIZED](state) {
    state.initialized = true
  },
  [SET_JOB_DATA_LAST_FETCH](state, timestamp) {
    state.jobDataLastFetch = timestamp
  },
  [SET_CLIENT_VERSION](state, version) {
    state.clientVersion = version
  },
}
