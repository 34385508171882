"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchemaProperties = void 0;
const schema_object_1 = require("./schema_object");
class SchemaProperties extends schema_object_1.SchemaObject {
    addFields(fields) {
        for (const field of fields) {
            this.addField(field);
        }
    }
    addField(field) {
        this[field.uniqueKey()] = field.propertyDescription();
    }
}
exports.SchemaProperties = SchemaProperties;
