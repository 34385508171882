import axios from 'axios'
import { post } from './base'

let source = axios.CancelToken.source()

export function getFirebaseToken() {
  source.cancel()
  source = axios.CancelToken.source()

  return post('/api/v2/token/firebase', { cancelToken: source.token })
}
