import { v4 } from 'uuid'

export default class JobAssignmentFormRequirement {
  constructor(json, isDraft = false) {
    this.id = json.id
    this.formId = json.form_id
    this.formSubmissionId = json.form_submission_id
    this.created = json.created
    this.#isDraft = isDraft
  }

  public static fromFormId(formId: string) {
    return new JobAssignmentFormRequirement(
      {
        id: v4(),
        form_id: formId,
        form_submission_id: null,
        created: null,
      },
      true
    )
  }

  public isDraft(): boolean {
    return this.#isDraft
  }

  id: string
  formId: string
  formSubmissionId: string | null
  created: Date | null
  #isDraft: boolean
}
