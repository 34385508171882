<template>
  <div class="wrapper">
    <div ref="top-bar" class="top-bar">
      <v-row no-gutters>
        <v-col>
          <v-row no-gutters>
            <v-col>
              <vs-heading data-test="new-form-selection-title" type="title"
                >Select a Form</vs-heading
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col class="shrink">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                data-test="new-form-selection-close"
                class="ma-0"
                icon
                v-on="on"
                @click="handleDismiss"
              >
                <v-icon>close</v-icon>
              </v-btn>
            </template>

            Close
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="mt-2">
          <vs-text-input
            ref="search"
            v-model="state.search"
            label="Filter"
            data-test="new-form-selection-search"
          >
            <template #append>
              <v-icon>search</v-icon>
            </template>
          </vs-text-input>
        </v-col>
      </v-row>
    </div>
    <div ref="content" class="content">
      <v-list class="form-list" data-test="new-form-selection-list">
        <div v-for="(categoryForms, category) in searchedForms">
          <v-subheader :key="category" class="list-subheader">
            {{ category }}
          </v-subheader>
          <v-list-item
            v-for="form in categoryForms"
            :key="form.id"
            @click="handleSelect(form.id)"
          >
            {{ form.name }}
          </v-list-item>
        </div>

        <v-list-item
          v-if="searchedForms.length === 0 && searchedForms.length !== 0"
        >
          <vs-text
            >No results for <i>{{ state.search }}</i>
          </vs-text>
        </v-list-item>
        <v-list-item v-else-if="searchedForms.length === 0">
          <vs-text>No forms found.</vs-text>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script setup>
import { checkSearchResult } from '@/utils/search'
import { debounce, orderBy } from 'lodash'
import { reactive, computed } from 'vue'
import { isScrolled } from '@/utils/form-layout'

const props = defineProps({
  forms: {
    type: Array,
    default: () => [],
  },
})

const emits = defineEmits(['dismiss', 'select'])

const state = reactive({ search: '' })

const searchedForms = computed(() => {
  const filteredForms = props.forms.filter((a) =>
    checkSearchResult(state.search, a.name)
  )
  let groupedForms = {}
  for (const form of filteredForms) {
    const formCategoryName = form.category?.name ?? 'Uncategorized'
    if (groupedForms[formCategoryName] === undefined) {
      groupedForms[formCategoryName] = []
    }
    groupedForms[formCategoryName].push(form)
  }
  for (const [key] of Object.entries(groupedForms)) {
    groupedForms[key] = orderBy(groupedForms[key], [
      (form) => form.name.toLowerCase(),
    ])
  }
  groupedForms = Object.keys(groupedForms)
    .sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1))
    .filter((key) => groupedForms[key].length > 0)
    .reduce(
      (grouped, key) => Object.assign(grouped, { [key]: groupedForms[key] }),
      {}
    )
  return groupedForms
})

const handleDismiss = () => {
  emits('dismiss')
}

const handleSelect = (formId) => {
  emits('select', { formId })
}

const refreshBarPositions = debounce(function () {
  const content = this.$refs.content
  if (content) {
    this.isTopBarSticked = isScrolled(content.scrollTop)
  }
}, 20)
</script>

<style scoped>
.form-list {
  overflow-y: auto;
}

.list-subheader {
  font-weight: bold;
  color: black;
  font-family: Montserrat, serif;
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.top-bar {
  padding: var(--space-base) var(--space-large) var(--space-small)
    var(--space-large);
  box-shadow: 0px 4px 4px -4px rgba(0, 0, 0, 0.1),
    0px 16px 16px -16px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  z-index: var(--elevation-sticky-bar);
  position: relative;
  flex: 0 0 auto;
}

.content {
  position: relative;
  flex: 0 1 auto;
  overflow: auto;
  padding: var(--space-base) var(--space-small);
}
</style>
