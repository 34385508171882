<template>
  <vs-wrapper space="small">
    <vs-heading class="pb-5" type="heading">Relationships</vs-heading>

    <vs-text v-if="numberOfRelationships < 1" type="subdued">
      This field has no relationships.<br />
      Use dependencies to show other fields based on selection of this field.
      <br />
      <template v-if="showFlows">
        Use go to flow to go to a flow based on selection of this field.
      </template>
    </vs-text>

    <v-expansion-panels v-model="expandedPanels" flat multiple>
      <v-expansion-panel v-if="numberOfDependencies > 0">
        <v-expansion-panel-header color="var(--color-silver--light)">
          <vs-heading type="subhead"> Dependencies</vs-heading>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="var(--color-silver--light)">
          <vs-alert type="warn">
            All dependencies must be removed before this field can be deleted
          </vs-alert>
          <div class="scrollable-content">
            <dependency-item
              v-for="dto in dependencies"
              :key="dto.parentField.uniqueKey()"
              class="mb-2"
              :field="field"
              :dto="dto"
              @delete-dependency="deleteDependency(dto)"
            ></dependency-item>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="goToFlowsOfField.length > 0">
        <v-expansion-panel-header color="var(--color-silver--light)">
          <vs-heading type="subhead"> Go to Flows</vs-heading>
        </v-expansion-panel-header>

        <v-expansion-panel-content color="var(--color-silver--light)">
          <go-to-flow-item
            v-for="dto in goToFlowsOfField"
            :key="dto.requiredValue"
            class="mb-2"
            :field="field"
            :dto="dto"
            @delete-flow="deleteFlow(dto)"
          ></go-to-flow-item>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <vs-wrapper space="small" class="mb-4">
      <vs-button
        full-width
        type="secondary"
        label="Add Dependency"
        @click="toggleDependencySettings()"
      />
      <vs-button
        v-if="showFlows"
        full-width
        type="secondary"
        label="Add Go to Flow"
        @click="toggleFlowSettings()"
      />
    </vs-wrapper>

    <add-flow-dialog
      :visible="isFlowSettingsVisible"
      :builder="builder"
      :active-flow="activeFlow"
      :field="field"
      :flow-items="flowItems"
      :required-value="requiredValue"
      :required-value-editor-component="requiredValueEditorComponent"
      :current-properties="currentProperties"
      @toggle-dialog="toggleFlowSettings"
      @required-value-change="handleRequiredValueChange"
    >
    </add-flow-dialog>

    <add-dependency-dialog
      :visible="isDependencySettingsVisible"
      :builder="builder"
      :active-flow="activeFlow"
      :field="field"
      :field-items="fieldItems"
      :required-value="requiredValue"
      :required-value-editor-component="requiredValueEditorComponent"
      :current-properties="currentProperties"
      @toggle-dialog="toggleDependencySettings"
      @required-value-change="handleRequiredValueChange"
    >
    </add-dependency-dialog>
  </vs-wrapper>
</template>

<script>
import VsWrapper from '@/components/vision/VsWrapper'
import VsButton from '@/components/vision/VsButton'
import VsHeading from '@/components/vision/VsHeading'
import VsText from '@/components/vision/VsText'
import VsAlert from '@/components/vision/VsAlert.vue'
import * as fb from 'ironsight-form-builder'
import { EnumStringFieldDefinition, GenericSelectorDefinition, NumberFieldDefinition } from 'ironsight-form-builder'
import DependencyItem from '@/components/form-builder/DependencyItem'
import GoToFlowItem from '@/components/form-builder/GoToFlowItem'
import AddFlowDialog from '@/components/form-builder/AddFlowDialog'
import AddDependencyDialog from '@/components/form-builder/AddDependencyDialog'
import formFieldTitle from '@/utils/forms/form-field-title'

export default {
  name: 'RelationshipsEditor',
  components: {
    VsAlert,
    AddDependencyDialog,
    AddFlowDialog,
    GoToFlowItem,
    DependencyItem,
    VsText,
    VsHeading,
    VsButton,
    VsWrapper,
  },

  props: {
    builder: {
      type: Object,
      required: true,
    },
    field: {
      type: Object,
      default: () => {},
    },
    showFlows: {
      type: Boolean,
      default: true,
    },
    activeFlow: {
      type: String,
      default: '#root_schema',
    },
  },

  data: () => {
    return {
      isDialogVisible: false,
      isFlowSettingsVisible: false,
      isDependencySettingsVisible: false,
      requiredValue: null,
    }
  },

  computed: {
    textInputProps() {
      return {
        value: this.requiredValue,
        label: 'Show fields when text matches (case sensitive)',
      }
    },
    numberInputProps() {
      return {
        value: this.requiredValue,
        label: 'Show fields when number matches',
        type: 'number',
      }
    },
    getSelectableOptions() {
      if (this.field instanceof GenericSelectorDefinition) {
        const items = []
        this.field.optionGroups.forEach((field) => {
          items.push({ header: field.title })
          if (field.items) {
            field.items.forEach((option) => {
              items.push({ text: option, value: option })
            })
          } else {
            items.push({ text: field.title, value: field.title })
          }
        })
        return items
      }
      if (this.field instanceof EnumStringFieldDefinition) {
        return this.field.selectableOptions.map((option) => {
          return {
            text: option,
            value: option,
          }
        })
      }
      return []
    },
    currentProperties() {
      const checkField =
        this.field instanceof fb.ReferenceField
          ? this.field.reference
          : this.field

      switch (this.requiredValueEditorComponent) {
        case 'vs-text-input':
          if (checkField instanceof NumberFieldDefinition) {
            return this.numberInputProps
          } else {
            return this.textInputProps
          }
        case 'v-checkbox':
          this.requiredValue = this.requiredValue ?? false
          if (this.isFlowSettingsVisible) {
            return {
              value: this.requiredValue,
              label: this.requiredValue
                ? 'Show flow when checked'
                : 'Show flow when not checked',
            }
          } else if (this.isDependencySettingsVisible) {
            return {
              value: this.requiredValue,
              label: this.requiredValue
                ? 'Show fields when checked'
                : 'Show fields when not checked',
            }
          }
          break
        case 'vs-autocomplete':
          return {
            label: 'Show fields when value selected',
            items: this.getSelectableOptions,
            value: this.requiredValue,
          }
        case 'v-select':
          if (checkField.isRadio) {
            return {
              label: 'Show fields when value selected',
              items: [
                { text: 'Yes', value: true },
                { text: 'No', value: false },
              ],
              value: this.requiredValue,
            }
          } else {
            return {
              label: 'Show fields when value selected',
              items: [{ text: 'Checked', value: true }],
              value: this.requiredValue,
            }
          }
        default:
          return {}
      }
    },
    requiredValueEditorComponent() {
      const checkField =
        this.field instanceof fb.ReferenceField
          ? this.field.reference
          : this.field

      if (checkField instanceof fb.BooleanFieldDefinition) {
        return 'v-select'
      }
      if (
        checkField instanceof fb.EnumStringFieldDefinition ||
        checkField instanceof fb.GenericSelectorDefinition
      ) {
        return 'vs-autocomplete'
      }

      return 'vs-text-input'
    },
    fieldItems() {
      return this.fields.map((field) => {
        return {
          text: this.getFieldLabel(field),
          description: field.description,
          value: field.uniqueKey(),
        }
      })
    },
    flowItems() {
      if (this.builder.getFlows().length > 1) {
        return this.builder
          .getFlows()
          .filter((flow) => flow !== this.activeFlow)
          .map((flow) => {
            return {
              text: flow === '#root_schema' ? 'Form' : flow,
              value: flow,
            }
          })
      }
    },
    fields() {
      return this.builder.fieldsSelectableDependencies(this.field)
    },
    dependencies() {
      return this.builder.immediateConstDependenciesOfField(this.field)
    },
    numberOfDependencies() {
      return !this.field ? 0 : this.dependencies.length
    },
    expandedPanels() {
      return this.numberOfDependencies > 0 ? [0] : []
    },
    goToFlowsOfField() {
      return this.builder.constGoToFlowConditionsOfField(this.field)
    },
    numberOfRelationships() {
      return this.numberOfDependencies + this.goToFlowsOfField.length
    },
  },
  methods: {
    getFieldLabel(field) {
      return formFieldTitle(field)
    },
    toggleFlowSettings() {
      if (!this.isDependencySettingsVisible) {
        this.isFlowSettingsVisible = !this.isFlowSettingsVisible
      }
      this.reset()
    },
    toggleDependencySettings() {
      if (!this.isFlowSettingsVisible) {
        this.isDependencySettingsVisible = !this.isDependencySettingsVisible
      }
      this.reset()
    },
    deleteDependency(dto) {
      this.builder.deleteConstDependencyOfField(dto)
    },
    deleteFlow(dto) {
      this.builder.deleteConstGoToFlowCondition(dto)
    },
    reset() {
      this.requiredValue = null
    },
    handleRequiredValueChange(value) {
      this.requiredValue = value

      if (this.field instanceof NumberFieldDefinition) {
        this.requiredValue = this.field.allowDecimals
          ? parseFloat(value)
          : parseInt(value)
      }
    },
  },
}
</script>

<style scoped>
.scrollable-content {
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
}
</style>
