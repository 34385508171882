import { deleteToken, getMessaging, getToken } from 'firebase/messaging'
import { bindWithNotify, unbindFromNotify } from '../../api/twilio'

export const NOTIFICATIONS_PERMISSIONS = 'NOTIFICATIONS_PERMISSIONS'
export const NOTIFICATIONS_INITIALIZED = 'NOTIFICATIONS_INITIALIZED'
export const SET_JOB_AUDIBLE_ALERT = 'SET_JOB_AUDIBLE_ALERT'
export const SET_NOTIFICATIONS_BINDING = 'SET_NOTIFICATIONS_BINDING'
export const SET_NOTIFICATIONS_TOKEN = 'SET_NOTIFICATIONS_TOKEN'
export const ERROR_NOTIFICATIONS = 'ERROR_NOTIFICATIONS'

const AUDIBLE_ALERT_LOCALSTORAGE = 'jobAudibleAlert'

let firebaseMessaging

const state = () => {
  return {
    initialized: false,
    error: null,
    token: '',
    binding: '',
    permission: null,
    audibleAlert:
      window.localStorage.getItem(AUDIBLE_ALERT_LOCALSTORAGE) === 'true',
  }
}
const actions = {
  async initialize({ state, dispatch, commit, rootGetters }) {
    if (
      rootGetters['permissions/hasChatAccess'] ||
      rootGetters['permissions/hasNotificationAccess']
    ) {
      firebaseMessaging = getMessaging()

      if (!('Notification' in window)) {
        commit(NOTIFICATIONS_PERMISSIONS, 'denied')
      } else {
        commit(NOTIFICATIONS_PERMISSIONS, Notification.permission)
      }

      if (
        state.permission === 'granted' &&
        (state.token === '' || state.binding === '')
      ) {
        await dispatch('getTokenAndBinding')
      }
    }

    commit(NOTIFICATIONS_INITIALIZED)
  },
  async enableNotifications({ commit }) {
    if (!('Notification' in window)) {
      return
    }
    try {
      const permission = await Notification.requestPermission()
      commit(NOTIFICATIONS_PERMISSIONS, permission)
    } catch (error) {
      commit(ERROR_NOTIFICATIONS, { error })
    }
  },
  async getTokenAndBinding({ commit, state }) {
    try {
      const token = await getToken(firebaseMessaging)

      commit(SET_NOTIFICATIONS_TOKEN, { token })

      const { data } = await bindWithNotify({
        data: {
          binding_type: 'fcm',
          address: state.token,
        },
      })

      commit(SET_NOTIFICATIONS_BINDING, { binding: data.binding })
    } catch (error) {
      commit(ERROR_NOTIFICATIONS, { error })
    }
  },
  async terminateNotifications({ state, commit }) {
    if (state.token) {
      await deleteToken(firebaseMessaging)
      commit(SET_NOTIFICATIONS_TOKEN, { token: '' })
    }

    if (state.binding) {
      await unbindFromNotify({
        data: {
          binding_sid: state.binding,
        },
      })

      commit(SET_NOTIFICATIONS_BINDING, { binding: '' })
    }
  },
}

const mutations = {
  [NOTIFICATIONS_PERMISSIONS](state, permission) {
    state.permission = permission
  },
  [NOTIFICATIONS_INITIALIZED](state) {
    state.initialized = true
  },
  [SET_NOTIFICATIONS_BINDING](state, { binding }) {
    state.binding = binding
  },
  [SET_NOTIFICATIONS_TOKEN](state, { token }) {
    state.token = token
  },
  [ERROR_NOTIFICATIONS](state, { error }) {
    state.error = error
  },
  [SET_JOB_AUDIBLE_ALERT](state, audibleAlert) {
    state.audibleAlert = audibleAlert
    window.localStorage.setItem(AUDIBLE_ALERT_LOCALSTORAGE, audibleAlert)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
