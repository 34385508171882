<template>
  <div>
    <v-row justify="center" align="center" dense>
      <v-col class="shrink">
        <slot></slot>
      </v-col>
      <v-col class="shrink">
        <vs-button
          label="Reset filters"
          type="secondary"
          small
          @click="resetFilters"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  methods: {
    resetFilters() {
      this.$emit('reset-filters')
    },
  },
}
</script>
