import {
  transform,
  camelCase,
  isArray,
  isObject,
  snakeCase,
  isDate,
  find,
} from 'lodash'
import { isMoment } from 'moment'
import { CamelCasedPropertiesDeep, SnakeCasedPropertiesDeep } from 'type-fest'

type Casing = 'snake' | 'camel'

export type SkipKey = { key: string; onlyDeep?: boolean }

const convert = {
  snake: snakeCase,
  camel: camelCase,
}

/**
  * Converts an object from snake_case to camelCase, and converts the ts type
  * back to the original model T. This is useful in our api where we automatically
  * convert the model to snake_case and then need to call convert. 
  */
export function convertTo<T>(obj: SnakeCasedPropertiesDeep<T>, skipKeys?: SkipKey[]) {
  return convertToCamelCase(obj, skipKeys) as T;
}

/**
  * Converts an object and its typings to camelCase.
  */
export function convertToCamelCase<T>(obj: T, skipKeys?: SkipKey[]) {
  return convertToCase(obj, 'camel', skipKeys) as CamelCasedPropertiesDeep<T>;
}

/**
  * Converts an object and its typings to snake_case.
  */
export function convertToSnakeCase<T>(obj: T, skipKeys?: SkipKey[]) {
  return convertToCase(obj, 'snake', skipKeys) as SnakeCasedPropertiesDeep<T>
}

function convertToCase<T>(
  obj: T,
  casing: Casing,
  skipKeys: SkipKey[] | undefined
) {
  if (!isObject(obj) || isDate(obj) || isMoment(obj)) {
    return obj
  }

  return transform(obj as any, (result: T, value: T, key: string, target) => {
    const properKey = isArray(target) ? key : convert[casing](key)
    const skipKey = (skipKeys ?? []).find((s) => s.key === key)

    if (skipKey) {
      result[skipKey.onlyDeep ? properKey : key] = value
    } else {
      result[properKey] = isDate(value)
        ? value
        : isObject(value)
        ? convertToCase(value as T, casing, skipKeys)
        : value
    }
  })
}
