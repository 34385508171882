<template>
  <div v-once id="visualization" ref="visualization"></div>
</template>

<script>
import { DataSet, DataView } from 'vis-data/peer'
import { Timeline } from 'vis-timeline/peer'
import { difference } from 'lodash'
import moment from 'moment'

export default {
  name: 'DailyScheduleTimeline',
  props: {
    groups: {
      type: [Array, DataSet, DataView],
      default: () => [],
    },
    items: {
      type: [Array, DataSet, DataView],
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    range: {
      type: Object,
      default: () => ({
        start: moment().subtract(4, 'hours').valueOf(),
        end: moment().add(4, 'hours').valueOf(),
      }),
    },
    events: {
      type: Array,
      default: () => [
        // 'click',
        // 'contextmenu',
        // 'currentTimeTick',
        // 'doubleClick',
        // 'drop',
        // 'mouseOver',
        // 'mouseDown',
        // 'mouseUp',
        // 'mouseMove',
        // 'groupDragged',
        // 'changed',
        'rangechange',
        'rangechanged',
        'select',
        // 'itemover',
        // 'itemout',
        // 'timechange',
        // 'timechanged'
      ],
    },
    selection: {
      type: [Array, String],
      default: () => [],
    },
  },
  data() {
    return {
      visData: {
        items: null,
        groups: null,
      },
    }
  },
  watch: {
    groups: {
      deep: true,
      handler(v) {
        const newIds = new DataSet(v, {}).getIds()
        const diff = difference(this.visData.groups.getIds(), newIds)
        this.visData.groups.update(v)
        this.visData.groups.remove(diff)
      },
    },
    items: {
      deep: true,
      handler(v) {
        const newIds = new DataSet(v, {}).getIds()

        const diff = difference(this.visData.items.getIds(), newIds)
        this.visData.items.update(v)
        this.visData.items.remove(diff)
      },
    },
    options: {
      deep: true,
      handler(v) {
        this.timeline.setOptions(v)
      },
    },
    selection: {
      deep: false,
      handler(v) {
        this.timeline.setSelection(v)
      },
    },
    range: {
      deep: true,
      handler(v) {
        this.timeline.setWindow(v.start, v.end)
      },
    },
  },
  mounted() {
    const container = this.$refs.visualization
    this.visData.items = new DataSet(this.items, {})
    this.visData.groups = new DataSet(this.groups, {})

    this.timeline = new Timeline(
      container,
      this.visData.items,
      this.visData.groups,
      { ...this.options, ...this.range }
    )

    this.events.forEach((eventName) =>
      this.timeline.on(eventName, (props) => {
        this.$emit(translateEvent(eventName), props)
      })
    )
  },
  created() {
    this.timeline = null
  },
  beforeDestroy() {
    this.events.forEach((eventName) =>
      this.timeline.off(eventName, (props) =>
        this.$emit(translateEvent(eventName), props)
      )
    )

    this.timeline.destroy()
  },
}

const translateEvent = (event) => {
  return event.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase()
}
</script>

<style>
#visualization {
  height: 100%;
}

.vis-ltr .vis-label.vis-nested-group.vis-group-level-1,
.vis-ltr .vis-label.vis-nested-group.vis-group-level-1 .vis-inner {
  padding: 0;
  width: 100%;
}

.vis-item {
  background: none;
  border: 0;
}

.vis-item .vis-item-content {
  padding: 0;
  width: 100%;
}

.vis-item.completed {
  opacity: 0.6;
}

.vis-item.new {
  opacity: 0.8;
}

.vis-item.shadow {
  background: none;
}

.vis-item.shadow .vis-item-content {
  opacity: 0.5;
}

.vis-item.shadow .vis-item-overflow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 14px;
  text-align: right;
  text-transform: uppercase;
}

.vis-item.shadow.overdue .vis-item-overflow {
  background: rgba(255, 205, 210, 0.6);
}

.vis-item.shadow.late .vis-item-overflow {
  background: rgba(255, 236, 179, 0.6);
}

.vis-item .vis-item-overflow {
  overflow: visible;
  min-height: 64px;
}
</style>
