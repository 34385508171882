"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateableSelectorDefinition = void 0;
const createable_selector_ui_schema_1 = require("../models/field_ui_schema/createable_selector_ui_schema");
const text_entry_field_definition_1 = require("./text_entry_field_definition");
class CreateableSelectorDefinition extends text_entry_field_definition_1.TextEntryFieldDefinition {
    constructor() {
        super(...arguments);
        this.selectableOptions = [];
    }
    uiSchema() {
        return new createable_selector_ui_schema_1.CreateableSelectorUiSchema(this);
    }
}
exports.CreateableSelectorDefinition = CreateableSelectorDefinition;
