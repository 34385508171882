import { del, post } from './base'

const notifyBindingEndpoint = '/api/v2/notifications/binding'

export function bindWithNotify(args) {
  return post(notifyBindingEndpoint, args)
}

export function unbindFromNotify(args) {
  return del(notifyBindingEndpoint, args)
}
