<template>
  <portal to="dialog">
    <div v-if="visible" class="dialog">
      <div class="dialog-box">
        <div v-if="title" class="dialog-header">
          <div class="dialog-title">
            <vs-heading>{{ title }}</vs-heading>
          </div>
          <button
            v-ripple
            type="button"
            class="dialog-close"
            data-test="close-action"
            @click="handleCloseDialog"
          >
            <v-icon class="dialog-icon">close</v-icon>
          </button>
        </div>
        <div
          :class="{
            'dialog-content': true,
            'no-content-padding': noContentPadding,
          }"
        >
          <slot />
        </div>

        <div v-if="$listeners['on-submit']" class="dialog-actions">
          <vs-button
            label="Save"
            data-test="submit-action"
            @click="handleSubmitClick"
          />
        </div>
      </div>

      <div class="dialog-overlay" @click="handleCloseDialog" />
    </div>
  </portal>
</template>

<script>
/**
 * Modals are overlays that allow users to view, edit, or show informations that
 * doesn't require a page to be built. It also prevent users from interacting
 * with the rest of the application until a specific action is taken.
 */
export default {
  props: {
    /**
     * The title of the card
     */
    title: {
      type: String,
      default: null,
    },
    /**
     * Determines if the dialog is visible or not
     */
    visible: {
      type: Boolean,
      default: false,
    },
    /**
     * Removes the default padding on the content
     */
    noContentPadding: {
      type: Boolean,
      default: false,
    },
    /**
     * Ask for confirmation before closing the dialog
     */
    confirmOnClose: {
      type: [Boolean, String],
      default: false,
    },
  },
  methods: {
    handleCloseDialog() {
      if (this.confirmOnClose) {
        let confirmMessage =
          'You have unsaved changes, do you want to discard them?'
        typeof this.confirmOnClose === 'string' &&
          (confirmMessage = this.confirmOnClose)
        if (!confirm(confirmMessage)) return
      }
      this.$emit('on-close')
    },
    handleSubmitClick() {
      this.$emit('on-submit')
    },
  },
}
</script>

<style scoped>
.dialog {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: var(--elevation-dialog);
  display: flex;
  padding: var(--space-base) 0;
  overflow: auto;
  -webkit-overflow-scrolling: scroll;
}

.dialog-box {
  width: 100%;
  max-width: 480px;
  margin: auto;
  background-color: var(--color-white);
  border-radius: var(--space-base);
  box-sizing: border-box;
  box-shadow: var(--shadow-base);
  z-index: var(--elevation-base);
}

.dialog-header,
.dialog-content:not(.no-content-padding),
.dialog-actions {
  padding: var(--space-base);
  box-sizing: border-box;
}

.dialog-header {
  padding-bottom: 0;
  display: flex;
}

.dialog-title {
  flex: 1;
  align-self: center;
}

.dialog-close {
  flex: 0;
  padding: var(--space-smaller);
  border-radius: 100%;
  appearance: none;
}

.dialog-close:focus {
  outline: none;
}

.dialog-icon {
  display: block;
  color: var(--color-grey);
}

.dialog-actions {
  padding-top: 0;
  text-align: right;
}

.dialog-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-black);
  opacity: 0.5;
}
</style>
