import moment from 'moment'
import { TASK_ACTIVE, UnitNumber } from '@/models/unit_number'
import { groupBy, has, isObject, orderBy, sortBy } from 'lodash'
import Job from '@/models/job'
import { Organization } from '@/models/business-unit'
import ResourceType from '@/models/resource_type'

export type ScheduleEvent<T> = T & {
  id: number | string
  isSelected: boolean
  isBeingDragged: boolean
  isSaving: boolean
}

type JobDetails = Pick<
  Job,
  | 'id'
  | 'uuid'
  | 'startTime'
  | 'endTime'
  | 'activeTime'
  | 'displayedStartTime'
  | 'displayedEndTime'
  | 'currentActiveTime'
  | 'completedTime'
  | 'activeDuration'
  | 'pausedDuration'
  | 'actualActiveDuration'
  | 'estimatedDuration'
  | 'customer'
  | 'approvalStatus'
  | 'status'
  | 'division'
  | 'ownerId'
  | 'destinationLocation'
  | 'destinationLocationId'
  | 'inventory'
  | 'activity'
  | 'activityId'
  | 'priority'
  | 'details'
  | 'activityLog'
  | 'isCompleted'
  | 'isCancelled'
  | 'assignmentGroupNumber'
  | 'assignmentGroupId'
  | 'unitId'
  | 'resourceTypeId'
  | 'serviceProviderBusinessUnitId'
  | 'unit'
  | 'serviceProvider'
  | 'hubId'
>

export type Item = ScheduleEvent<JobDetails>

export function canGroup(jobs: Item[]) {
  if (jobs.length < 2) {
    return false
  }
  const [modelJob] = jobs
  const assignmentGroups = [
    ...new Set(jobs.map((j) => j.assignmentGroupNumber)),
  ]
  const anyEmpty = assignmentGroups.some((a) => !a)
  // cannot group jobs that are in different groups
  if (assignmentGroups.length > 2 && !anyEmpty) {
    return false
  }
  // cannot group jobs that are already in the same group
  if (assignmentGroups.length === 1 && !anyEmpty) {
    return false
  }

  return jobs.every(
    (j) =>
      j.serviceProviderBusinessUnitId ===
        modelJob.serviceProviderBusinessUnitId &&
      j.resourceTypeId === modelJob.resourceTypeId &&
      j.unitId === modelJob.unitId &&
      !j.isCancelled
  )
}

export type ContextMenuContext = {
  position: { x: number; y: number }
  type: 'job' | 'group'
  data: [Item] | Item[]
}

interface WeekDay<T = 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat'> {
  current: boolean
  date: moment.Moment
  name: T
  number: number
}

export type WeekDays = [
  WeekDay<'sun'>,
  WeekDay<'mon'>,
  WeekDay<'tue'>,
  WeekDay<'wed'>,
  WeekDay<'thu'>,
  WeekDay<'fri'>,
  WeekDay<'sat'>
]

export function getDaysForPeriod(
  start: moment.Moment,
  end: moment.Moment,
  currentDay: moment.Moment = moment()
): Partial<WeekDays> {
  const range: Partial<WeekDays> = []
  const currentDate = moment(start).startOf('day') // Start from the beginning of the start day

  while (currentDate.isSameOrBefore(end, 'day')) {
    range.push({
      name: currentDate.format('ddd').toLowerCase(),
      number: currentDate.date(),
      current: currentDay.isSame(currentDate, 'day'),
      date: currentDate.clone(),
    } as WeekDay)

    currentDate.add(1, 'day') // Move to the next day
  }

  return range
}

export function tryExtractWeekStart(
  params: {
    year?: number | string
    month?: number | string
    day?: number | string
  } | null
) {
  if (
    isObject(params) &&
    has(params, 'year') &&
    has(params, 'month') &&
    has(params, 'day')
  ) {
    const dateStr = `${params.year} ${params.month} ${params.day}`
    const date = moment(dateStr, 'YYYY M D', true)
    if (date.isValid()) {
      return moment(date).startOf('week')
    }
  }
  return null
}

export function getWeekOverviewForJobs(
  jobs,
  weekStart,
  jobText = 'job'
): {
  sun?: string
  mon?: string
  tue?: string
  wed?: string
  thu?: string
  fri?: string
  sat?: string
} {
  if (!jobs) {
    return {}
  }
  const initialJobText = jobText

  return moment.weekdaysShort().reduce((overview, name, i) => {
    jobText = initialJobText
    const currentDayStart = weekStart.clone().startOf('day').add(i, 'days')
    const currentDayEnd = weekStart.clone().endOf('day').add(i, 'days')
    const currentDayJobs = jobs.filter((job) => {
      const start = job.displayedStartTime
      const end = job.displayedEndTime

      return (
        start.isSameOrBefore(currentDayEnd) &&
        end.isAfter(currentDayStart) &&
        !job.isCancelled
      )
    })

    if (currentDayJobs.length === 0) {
      return Object.assign(overview, {
        [name.toLowerCase()]: '',
      })
    }

    const workingMinutes = currentDayJobs.reduce((totalMinutes, job) => {
      if (job.isCompleted && job.pausedDuration.asSeconds() > 1) {
        const activeSegmentsForCurrentDay = job.activityLog.segments
          .filter(
            (s) =>
              s.start.occurredAt.isSameOrAfter(currentDayStart) &&
              s.start.occurredAt.isSameOrBefore(currentDayEnd) &&
              s.start.status === TASK_ACTIVE
          )
          .reduce((t, i) => t.add(i.duration), moment.duration(0))

        return totalMinutes + activeSegmentsForCurrentDay.asMinutes()
      }

      const start = job.displayedStartTime
      const end = job.displayedEndTime

      const displayedStart = start.isSameOrBefore(currentDayStart)
        ? currentDayStart
        : start
      const displayedEnd = end.isSameOrBefore(currentDayEnd)
        ? end
        : currentDayEnd

      const diff = moment
        .duration(displayedEnd.diff(displayedStart))
        .asMinutes()

      return totalMinutes + diff
    }, 0)

    const roundedWorkingHours = Math.round(workingMinutes / 0.6) / 100

    if (jobText === 'job' && currentDayJobs.length > 1) {
      jobText = 'jobs'
    }

    const hourText = roundedWorkingHours !== 1 ? 'hours' : 'hour'
    const overviewText = `${currentDayJobs.length} ${jobText}, ${roundedWorkingHours} ${hourText}`

    return Object.assign(overview, {
      [name.toLowerCase()]: overviewText,
    })
  }, {})
}

export function generateCalendarGridTemplateColumns(
  weekDays: Partial<WeekDays>,
  options: {
    offsetLeft?:
      | `${number}px`
      | `var(--${string})`
      | `var(--${string}, ${number}px)`
  } = {}
) {
  const columns: {
    name:
      | 'group'
      | 'sun'
      | 'mon'
      | 'tue'
      | 'wed'
      | 'thu'
      | 'fri'
      | 'sat'
      | 'unknown'
    width: string
  }[] = weekDays.map((d) => ({
    name: d?.name ?? 'unknown',
    width: 'var(--column-size)',
  }))
  if (options.offsetLeft) {
    columns.unshift({ name: 'group', width: options.offsetLeft })
  }
  const [first, ...rest] = columns
  const last = rest.pop() ?? first

  const rows = [`[${first.name}-start] ${first.width}`]
  for (let i = 0; i < columns.length; i++) {
    const day = columns[i]
    const nextDay = columns[i + 1]
    if (nextDay) {
      rows.push(`[${day.name}-end ${nextDay.name}-start] ${nextDay.width}`)
    }
  }
  rows.push(`[${last.name}-end]`)
  return rows.join('\n')
}

function constructUnitOverview(currentOverview, count, type) {
  if (count === 0) return ''

  let newOverview = ''

  !!currentOverview && (newOverview += ', ')
  newOverview += `${count} ${type}`

  return newOverview
}

export function constructResourceTypeOverview(
  resources: UnitNumber[],
  showStandbyUnits: boolean,
  showOfflineUnits: boolean,
  total: number
) {
  if (total === 0 && length === 0) return 'No resources in this group'

  let overview = ''
  const activeUnitsCount = resources.filter((unit) => unit.isActive).length
  overview += constructUnitOverview(overview, activeUnitsCount, 'active')

  if (showStandbyUnits) {
    const standbyUnitsCount = resources.filter((unit) => unit.isStandby).length

    overview += constructUnitOverview(overview, standbyUnitsCount, 'standby')
  }

  if (showOfflineUnits) {
    const offlineUnitsCount = resources.filter((unit) => unit.isOffline).length

    overview += constructUnitOverview(overview, offlineUnitsCount, 'offline')
  }

  const hiddenUnitsCount = total - resources.length

  overview += constructUnitOverview(overview, hiddenUnitsCount, 'hidden')

  return overview
}

export function groupJobs(jobs: Item[]) {
  const groups = groupBy(sortBy(jobs, 'number'), (j) => j.assignmentGroupId)
  const { null: ungrouped = [], ...grouped } = groups
  const collection = [...Object.values(grouped), ...ungrouped.map((j) => [j])]
  return orderBy(collection, [
    ([j]) => j.displayedStartTime,
    ([j]) => j.displayedEndTime,
    ([j]) => j.id,
  ])
}

export enum RowType {
  Resource = 'unit',
  ResourceType = 'rt',
  ServiceProvider = 'company',
}

export interface Row {
  id: number | string
  type: RowType
  item: UnitNumber | Organization | ResourceType
  jobs: Item[][]
  overview: Record<string, string>
}
