<template>
  <div />
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'FieldViewNextProvision',
  created() {
    this.fetchJobData()
  },
  methods: {
    ...mapActions({
      fetchJobData: 'fetchJobData',
    }),
  },
}
</script>
