"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DependencyParser = void 0;
const form_field_definition_1 = require("../form_fields/form_field_definition");
const json_schema_keys_1 = require("../json_schema_keys");
const const_dependency_dto_1 = require("../models/dependencies/const_dependency_dto");
const required_dependency_dto_1 = require("../models/dependencies/required_dependency_dto");
class DependencyParser {
    constructor(allFields) {
        this.fieldsOfPage = allFields;
    }
    parse(dependencies) {
        var _a;
        const dtos = new Array();
        for (const key of Object.keys(dependencies !== null && dependencies !== void 0 ? dependencies : {})) {
            const parent = this.field(key);
            for (const oneOf of dependencies[key].oneOf) {
                const isReqDependency = oneOf.properties[key].hasOwnProperty('requiredField');
                if (isReqDependency) {
                    const requiredValue = oneOf.properties[key].requiredField;
                    dtos.push(new required_dependency_dto_1.RequiredDependencyDTO(parent, requiredValue, oneOf.required));
                    continue;
                }
                const requiredValue = oneOf.properties[key].const;
                const children = Object.keys(oneOf.properties).filter((k) => k !== key).map((k) => this.field(k));
                if (children.length === 0) {
                    continue;
                }
                for (const child of children) {
                    child.isRequired = ((_a = oneOf[json_schema_keys_1.JsonSchemaKeys.REQUIRED_KEY]) !== null && _a !== void 0 ? _a : []).includes(child.uniqueKey());
                }
                dtos.push(new const_dependency_dto_1.ConstDependencyDTO(parent, requiredValue, children));
            }
        }
        return dtos;
    }
    field(uniqueKey) {
        const field = this.fieldsOfPage.find((f) => f.uniqueKey() === uniqueKey);
        if (field instanceof form_field_definition_1.FormFieldDefinition) {
            return field;
        }
        throw new Error(`Field ${uniqueKey} was not found while parsing dependencies`);
    }
}
exports.DependencyParser = DependencyParser;
