// These constants can be removed once the TypeScript conversion is complete and BusinessUnitType is used everywhere.
export const ORGANIZATION = 'Organization'
export const HUB = 'Hub'
export const DIVISION = 'Division'
export const TEAM = 'Team'

export enum BusinessUnitType {
  Organization = 'Organization',
  Hub = 'Hub',
  Division = 'Division',
  Team = 'Team',
}

export class BusinessUnit {
  id: string
  name: string
  ownerId?: string
  type: BusinessUnitType

  constructor(json: any, type: BusinessUnitType) {
    this.id = json.id
    this.name = json.name
    this.type = type
    this.ownerId = json.owner_id
  }
}

export class Organization extends BusinessUnit {
  constructor(json: any) {
    super(json, BusinessUnitType.Organization)
  }
}

export class Hub extends BusinessUnit {
  constructor(json: any) {
    super(json, BusinessUnitType.Hub)
  }
}

export class Division extends BusinessUnit {
  constructor(json: any) {
    super(json, BusinessUnitType.Division)
  }
}

export class Team extends BusinessUnit {
  constructor(json: any) {
    super(json, BusinessUnitType.Team)
  }
}

export default {
  BusinessUnit,
  Organization,
  Hub,
  Division,
  Team,
}
