"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArrayPropertyDescription = void 0;
const array_field_definition_1 = require("../../form_fields/array_field_definition");
const json_schema_keys_1 = require("../../json_schema_keys");
const lookup_entity_1 = require("../lookup_entity");
const field_property_description_1 = require("./field_property_description");
const lookup_property_description_1 = require("./lookup_property_description");
class ArrayPropertyDescription extends field_property_description_1.DefaultFieldPropertyDescription {
    set minimumItems(minimumItems) {
        this[json_schema_keys_1.JsonSchemaKeys.MIN_ITEMS_KEY] = minimumItems;
    }
    set maximumItems(maximumItems) {
        this[json_schema_keys_1.JsonSchemaKeys.MAX_ITEMS_KEY] = maximumItems;
    }
    set arrayItems(arrayItems) {
        this[json_schema_keys_1.JsonSchemaKeys.ITEMS_KEY] = arrayItems;
    }
    constructor(field) {
        super(field);
        this.arrayItems = new ArrayItemType(field.itemDataType, field.title);
        if (field.maximumItems) {
            this.maximumItems = field.maximumItems;
        }
        if (field.minimumItems) {
            this.minimumItems = field.minimumItems;
        }
        var lookupEntity;
        switch (field.itemDataType) {
            case array_field_definition_1.ArrayItemDataType.businessUnit:
                lookupEntity = new lookup_entity_1.BusinessUnitLookup();
                break;
            case array_field_definition_1.ArrayItemDataType.user:
                lookupEntity = new lookup_entity_1.UserLookup();
                break;
            case array_field_definition_1.ArrayItemDataType.location:
                lookupEntity = new lookup_entity_1.LocationLookup();
                break;
            case array_field_definition_1.ArrayItemDataType.company:
                lookupEntity = new lookup_entity_1.CompanyLookup();
                break;
            default:
                lookupEntity = null;
        }
        if (lookupEntity) {
            this.lookup = new lookup_property_description_1.LookupProperty(lookupEntity.entity);
        }
    }
}
exports.ArrayPropertyDescription = ArrayPropertyDescription;
class ArrayItemType {
    constructor(arrayItemDataType, title) {
        this.title = title;
        switch (arrayItemDataType) {
            case array_field_definition_1.ArrayItemDataType.businessUnit:
            case array_field_definition_1.ArrayItemDataType.user:
            case array_field_definition_1.ArrayItemDataType.string:
                this.type = json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_STRING;
                break;
            case array_field_definition_1.ArrayItemDataType.decimal:
            case array_field_definition_1.ArrayItemDataType.location:
            case array_field_definition_1.ArrayItemDataType.company:
                // Even though the ids of locations and companies are int,
                // these are meant to be number in the schema
                this.type = json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_NUMBER;
                break;
            case array_field_definition_1.ArrayItemDataType.integer:
                this.type = json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_INTEGER;
                break;
            default:
                throw new Error(`Type ${arrayItemDataType} is not implemented`);
        }
    }
}
