<template>
  <vs-wrapper>
    <vs-autocomplete
      data-test="group-selector-field"
      :items="items"
      :label="title"
      :hint="description"
      :value="formData"
      :error-messages="errorMessages"
      :disabled="disabled"
      @input="$emit('change', $event)"
    />
  </vs-wrapper>
</template>

<script>
export default {
  name: 'GroupSelectorField',
  props: {
    errorSchema: Object,
    formData: String,
    params: Object,
    rootSchema: Object,
    schema: Object,
    title: String,
    description: String,
    uiSchema: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    items() {
      const fields = this.uiSchema['ui:options'].fields
      const items = []

      fields.forEach((field) => {
        items.push({ header: field.name })

        if (field.options) {
          field.options.forEach((option) => {
            items.push({ text: option.name, value: option.value })
          })
        } else {
          items.push({ text: field.name, value: field.value })
        }
      })

      return items
    },
    errorMessages() {
      const errors = this.errorSchema?.errors ?? []
      if (this.formData && typeof this.formData !== 'string') {
        errors.push('Field value expected.')
      }
      return errors
    },
  },
}
</script>

<style scoped></style>
