"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormModel = void 0;
class FormModel {
    constructor() {
        this.pages = [];
        this.widgets_version = 1;
    }
}
exports.FormModel = FormModel;
