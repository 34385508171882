<script>
/**
 * Automatically add a spacing on every first-level children and determine the
 * direction from horizontal to vertical.
 */
export default {
  name: 'VsWrapper',
  functional: true,
  props: {
    /**
     * Determines the spacing between childrens
     */
    space: {
      type: String,
      default: 'base',
      validator: (value) => ['base', 'small', 'large'].includes(value),
    },
    /**
     * Changes the direction on which the children gets laid out
     */
    direction: {
      type: String,
      default: 'vertical',
      validator: (value) => ['vertical', 'horizontal'].includes(value),
    },
    /**
     * Adds a padding around the main wrapper
     */
    padding: {
      type: String,
      default: null,
      validator: (value) => ['base', 'small', 'large'].includes(value),
    },
    /**
     * Adds a divider line between children
     */
    divider: {
      type: Boolean,
      default: false,
    },
  },
  render(createElement, { children, props }) {
    return createElement(
      'div',
      {
        class: {
          wrapper: true,
          [props.direction]: props.direction,
          [`space-${props.space}`]: props.space,
          [`padding-${props.padding}`]: props.padding,
          divider: props.divider,
        },
      },
      children.map((child, index) => {
        if (!child.context) return // Don't render if there's nothing to render
        return createElement('div', { class: 'children' }, [child])
      })
    )
  },
}
</script>

<style scoped>
.wrapper {
  --spacing: var(--space-base);
  display: flex;
  flex: 1;
  width: 100%;
}

.horizontal {
  flex-direction: row;
}

.vertical {
  flex-direction: column;
}

.space-small {
  --spacing: var(--space-small);
}

.space-large {
  --spacing: var(--space-large);
}

.padding-base {
  padding: var(--space-base);
}

.padding-small {
  padding: var(--space-small);
}

.padding-large {
  padding: var(--space-large);
}

.children {
  flex: 1;
}

.children:empty {
  display: none;
}

.vertical > .children:not(:last-of-type) {
  margin-bottom: var(--spacing);
}

.horizontal > .children:not(:last-of-type) {
  padding-right: var(--spacing);
}

.divider.vertical > .children:not(:last-of-type) {
  padding-bottom: var(--spacing);
  border-bottom: 1px solid var(--color-grey--lighter);
}

.divider.horizontal > .children:not(:last-of-type) {
  margin-right: var(--spacing);
  border-right: 1px solid var(--color-grey--lighter);
}
</style>
