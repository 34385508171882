import { render, staticRenderFns } from "./VsDialog.vue?vue&type=template&id=9a6c2266&scoped=true&"
import script from "./VsDialog.vue?vue&type=script&lang=js&"
export * from "./VsDialog.vue?vue&type=script&lang=js&"
import style0 from "./VsDialog.vue?vue&type=style&index=0&id=9a6c2266&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a6c2266",
  null
  
)

export default component.exports