<template>
  <div>
    <vs-autocomplete
      :items="items"
      :label="label"
      :placeholder="`Select a ${label.toLowerCase()}`"
      :value="value"
      :required="required"
      :search-value.sync="searchValue"
      :hint="hint"
      data-test="autocomplete"
      :disabled="disabled"
      @input="handleInput"
    >
      <template v-if="$slots.dialog" #append-item>
        <v-divider class="mb-2" />
        <v-list-item data-test="create-tile" @click.stop="handleCreateClick">
          <v-list-item-content>
            <vs-text>{{ createNewLabel }}</vs-text>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>add</v-icon>
          </v-list-item-action>
        </v-list-item>
      </template>
    </vs-autocomplete>

    <vs-dialog
      v-if="$slots.dialog"
      :visible="isDialogVisible"
      :title="`Create ${label}`"
      confirm-on-close
      @on-close="handleDialogClose"
    >
      <slot name="dialog" />
    </vs-dialog>
  </div>
</template>

<script>
import VsAutocomplete from '@/components/vision/Inputs/VsAutocomplete/VsAutocomplete.vue'
export default {
  components: { VsAutocomplete },
  props: {
    /**
     *  The list of options that the user can choose from.
     *
     * @param {Object} items Items object.
     * @param {String} items.text The label of the option.
     * @param {Number} items.value The ID of the option.
     * @param {Number} items.icon Adds an icon beside the label.
     * @param {Number} items.color Adds a color on the icon. If there's no icon, it uses an avatar to visualize the color.
     */
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: () => null,
      required: true,
    },
    value: {
      type: [String, Number],
      default: () => null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    isDialogVisible: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchValue: null,
    }
  },
  computed: {
    createNewLabel() {
      if (this.showCreateCustomItem) {
        return `Create and use ${this.searchValue}`
      }

      return `New ${this.label}`
    },
    showCreateCustomItem() {
      return (
        this.searchValue &&
        this.searchValue !== '' &&
        !this.items
          .map((item) => item.text.toLowerCase())
          .includes(this.searchValue.toLowerCase())
      )
    },
  },
  methods: {
    handleInput(newValue) {
      this.$emit('input', newValue)
    },
    handleCreateClick() {
      const searchText = this.showCreateCustomItem ? this.searchValue : null
      this.$emit('click-add', searchText)
    },
    handleDialogClose() {
      this.$emit('dialog-close')
    },
  },
}
</script>

<style scoped>
* >>> span.v-list__tile__mask {
  color: black !important;
  background: none !important;
}
</style>
