"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.subjectLocationFieldMatcher = void 0;
const subject_location_field_definition_1 = require("../../form_fields/subject_location_field_definition");
const json_schema_keys_1 = require("../../json_schema_keys");
const subjectLocationFieldMatcher = (uniqueKey, isRequired, properties, uiSchema, fieldDefinitions, next) => {
    if (uniqueKey === json_schema_keys_1.JsonSchemaKeys.SUBJECT_LOCATION_FIELD_KEY) {
        const field = new subject_location_field_definition_1.SubjectLocationFieldDefinition();
        field.title = properties.title;
        field.description = properties.description;
        field.defaultValue = properties.default;
        return field;
    }
    return next(uniqueKey, isRequired, properties, uiSchema, fieldDefinitions);
};
exports.subjectLocationFieldMatcher = subjectLocationFieldMatcher;
