"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UiOptionGroups = exports.OptionsGroup = exports.GenericSelectorUiSchema = void 0;
const json_schema_keys_1 = require("../../json_schema_keys");
const field_ui_schema_1 = require("./field_ui_schema");
class GenericSelectorUiSchema extends field_ui_schema_1.FieldUiSchema {
    set uiOptionGroups(optionGroups) {
        this[json_schema_keys_1.JsonSchemaKeys.UI_OPTIONS_KEY] = optionGroups;
    }
    constructor(field) {
        super();
        this.widgetType = json_schema_keys_1.JsonSchemaKeys.WIDGET_TYPE_SELECTOR;
        this.uiOptionGroups = new UiOptionGroups(field.optionGroups);
    }
}
exports.GenericSelectorUiSchema = GenericSelectorUiSchema;
class OptionsGroup {
    constructor(title, items) {
        this.title = title;
        this.items = items !== null && items !== void 0 ? items : [];
    }
}
exports.OptionsGroup = OptionsGroup;
class UiOptionGroups {
    constructor(choices) {
        this.groups = choices;
    }
}
exports.UiOptionGroups = UiOptionGroups;
