<template>
  <div class="aside">
    <div class="topStickyMenu">
      <div class="top-panel-commands">
        <vs-button
          class="vs-asidePanel"
          icon="chevron_left"
          :label="panelLabel"
          small
          data-test="hide-filter"
          @click="handleLeavePanel"
        />
      </div>
      <div>
        <div class="field-wrapper">
          <div class="chip-container">
            <v-chip
              v-for="chip in chips.filter((c) =>
                parentIsNotSelected(c.parentId)
              )"
              :key="chip.id"
              small
              :close="true"
              @click:close="handleDeSelect(chip)"
            >
              {{ chipText(chip) }}
            </v-chip>
          </div>
          <div class="field">
            <input
              v-model="searchValue"
              class="search-input"
              placeholder="Look for..."
            />
            <div class="field-suffix">
              <v-icon>search</v-icon>
            </div>
          </div>
        </div>
      </div>
      <vs-text
        v-if="selectedCounter !== 'None'"
        class="item-counter"
        type="subdued"
      >
        {{ selectedCounter }} selected
      </vs-text>

      <div class="subjectButtons">
        <slot
          name="subjectButtons"
          :handle-button-selection="handleButtonSelection"
        />
      </div>
    </div>

    <div class="content">
      <slot
        name="menu"
        :items="filteredItems"
        :selected-items="selectedItems"
        :search-value="searchValue"
        :handle-selection="handleSelection"
      />
    </div>

    <div class="bottomStickyMenu">
      <vs-button
        v-if="showSaveButton"
        class="clearButton text-none"
        label="Clear"
        small
        type="secondary"
        @click="resetSelectedLocalItems"
      />
      <vs-button
        v-if="showSaveButton"
        class="saveButton text-none"
        label="Save"
        small
        type="primary"
        @click="handleSaveFilters"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FormSubmissionFilterAsideMixin from '@/components/forms/form-submission-filter-aside-mixin'
import { uniq } from 'lodash'

export default {
  name: 'FormSubmissionFilterAsidePanel',
  mixins: [FormSubmissionFilterAsideMixin],
  props: {
    panelLabel: {
      type: String,
      required: true,
      default: 'Form filter panel',
    },
    items: {
      type: Array,
      default: () => [],
      required: false,
    },
    selectedItems: {
      type: Array,
      default: () => [],
      required: false,
    },
    subjectSearchIds: {
      type: Array,
      default: () => [],
      required: false,
    },
    allLocationItems: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      searchValue: '',
      filtersChanged: false,
      localSelection: [],
      filterObjects: [],
      parents: [],
    }
  },
  computed: {
    ...mapGetters({
      getAvailableSubjectButtons:
        'formSubmissionsFilters/getAvailableSubjectButtons',
      getCount: 'formSubmissions/getCount',
      getSubjectSearchButtons: 'formSubmissionsFilters/subjectSearchButtons',
    }),
    selectedCounter() {
      let totalItems
      if (!this.allLocationItems.length > 0) {
        totalItems = this.items
      } else {
        totalItems = this.allLocationItems
      }
      const totalCounterItems = totalItems.filter(
        (item) => !('header' in item || 'divider' in item)
      )
      if (this.localSelection.length === 0) {
        return 'None'
      } else {
        return `${this.localSelection.length} / ${totalCounterItems.length}`
      }
    },
    chipColor() {
      if (this.localSelection.length === 0) {
        return 'var(--color-silver)'
      } else {
        return 'var(--color-blue--lighter)'
      }
    },
    filteredItems() {
      const matchWith = (value) => {
        if (!value) return false
        const criteria = value.replace(/\s/g, '').toLocaleLowerCase()
        const searchVal = this.searchValue
          .replace(/\s/g, '')
          .toLocaleLowerCase()
        return criteria.indexOf(searchVal) > -1
      }
      const match = (item) =>
        matchWith(item.name) ||
        matchWith(item.text) ||
        matchWith(item.description) ||
        matchWith(item.additionalKeywords)
      return this.items
        .filter(
          (item) =>
            !this.localSelection.includes(item.id) &&
            !this.localSelection.includes(item.value?.businessUnitId) &&
            !this.parents.includes(item.parent) &&
            (!this.searchValue || match(item) || item.children?.some(match))
        )
        .map((item) => {
          return {
            ...item,
            children: item.children?.filter(
              ({ id }) => !this.localSelection.includes(id)
            ),
          }
        })
    },
    chips() {
      let items
      if (!this.allLocationItems.length > 0) {
        items = this.items
      } else {
        items = this.allLocationItems
      }
      return this.localSelection.map((itemId) => {
        const item = items.find(
          (item) => item.id === itemId || item.value?.businessUnitId === itemId
        )
        if (item) {
          if (item.value) {
            return {
              name: item.name ?? item.text,
              id: item.value.businessUnitId ?? item.value.userId,
              parent: item.parent,
            }
          } else {
            return {
              name: item.name ?? item.text,
              id: item.id,
              parentId: item.parentId,
              children: item.children,
            }
          }
        } else {
          return { name: 'Unknown item', id: itemId }
        }
      })
    },
    showSaveButton() {
      return this.filtersChanged
    },
  },
  mounted() {
    this.localSelection = [...this.selectedItems]
    if (this.subjectSearchIds.length > 0) {
      this.filterObjects = [...this.subjectSearchIds]
    } else {
      this.filterObjects = [...this.selectedItems]
    }

    this.selectedItems.forEach((selectedItem) => {
      const subjectItem = this.items.find(
        (item) => item.value?.businessUnitId === selectedItem
      )

      if (subjectItem && !('parent' in subjectItem)) {
        this.parents.push(subjectItem.text)
        const children = this.items
          .filter((item) => item?.parent === subjectItem.text)
          .map((child) => child.value.userId ?? child.value.businessUnitId)
          .filter((id) => this.localSelection.includes(id))
        this.localSelection = this.localSelection.filter(
          (id) => !children.includes(id)
        )
      }
    })
  },
  methods: {
    ...mapActions({
      updateSubjectSearchIds: 'formSubmissionsFilters/updateSubjectSearchIds',
      setInverseSelectedFormActions:
        'formSubmissionsFilters/setInverseFormActions',
      setInverseSelectedLocations: 'formSubmissionsFilters/setInverseLocations',
      setSelectedTags: 'formSubmissionsFilters/setTags',
      setSubjectButtons: 'formSubmissionsFilters/setSubjectSearchButtons',
      setInverseSelectedFormIds: 'formSubmissionsFilters/setInverseFormIds',
    }),
    parentIsNotSelected(parentId) {
      if (parentId === null) {
        return true
      } else {
        return !this.localSelection.includes(parentId)
      }
    },
    handleSelection(selected) {
      const locationItem = this.allLocationItems.find(
        (item) => item.id === selected
      )
      const childIds = []
      if (
        locationItem &&
        locationItem.children !== undefined &&
        locationItem?.children.length > 0
      ) {
        locationItem.children.forEach((child) => {
          childIds.push(child.id)

          const getChildren = (node) => {
            for (const c of node.children) {
              childIds.push(c.id)
              getChildren(c)
            }
          }

          for (const c of child.children) {
            childIds.push(c.id)
            getChildren(c)
          }
        })
      }

      this.filterObjects.push(selected, ...childIds)

      if (selected?.businessUnitId || selected?.userId) {
        if (selected?.businessUnitId) {
          const subjectItem = this.items.find(
            (item) => item.value?.businessUnitId === selected?.businessUnitId
          )
          if (!('parent' in subjectItem)) {
            this.parents.push(subjectItem.text)
            const children = this.items
              .filter((item) => item?.parent === subjectItem.text)
              .map((child) => child.value.userId ?? child.value.businessUnitId)
              .filter((id) => this.localSelection.includes(id))
            this.localSelection = this.localSelection.filter(
              (id) => !children.includes(id)
            )
            this.filterObjects = this.filterObjects.filter(
              (object) => !children.includes(object?.businessUnitId)
            )
            this.filterObjects = this.filterObjects.filter(
              (object) => !children.includes(object?.userId)
            )
          }
        }
        this.localSelection.push(selected.businessUnitId ?? selected.userId)
      } else {
        this.localSelection.push(selected, ...childIds)
      }

      this.filterObjects = uniq(this.filterObjects)
      this.localSelection = uniq(this.localSelection)
      this.filtersChanged = true
      this.searchValue = ''
    },
    handleDeSelect(selected) {
      const deselectedItems = [selected.id]

      if (selected.children !== undefined && selected.children.length > 0) {
        selected.children.forEach((child) => {
          deselectedItems.push(child.id)

          const getChildren = (node) => {
            for (const c of node.children) {
              deselectedItems.push(c.id)
              getChildren(c)
            }
          }

          for (const c of child.children) {
            deselectedItems.push(c.id)
            getChildren(c)
          }
        })
      }
      this.filterObjects = this.filterObjects.filter((object) => {
        if (object.businessUnitId) {
          return !deselectedItems.includes(object.businessUnitId)
        } else if (object.userId) {
          return !deselectedItems.includes(object.userId)
        } else {
          return !deselectedItems.includes(object)
        }
      })

      this.localSelection = this.localSelection.filter(
        (i) => !deselectedItems.includes(i)
      )
      if (selected.parent === undefined) {
        const children = this.items
          .filter((item) => item?.parent === selected.name)
          .map((child) => child.value.userId ?? child.value.businessUnitId)
          .filter((id) => this.localSelection.includes(id))
        this.localSelection = this.localSelection.filter(
          (id) => !children.includes(id)
        )
      }
      this.parents = this.parents.filter((parent) => parent !== selected.name)

      this.filterObjects = uniq(this.filterObjects)
      this.localSelection = uniq(this.localSelection)
      this.filtersChanged = true
    },
    handleButtonSelection(buttons) {
      this.filtersChanged = true
      this.$emit('filter-subject-buttons-changed', buttons)
      this.searchValue = ''
    },
    resetSelectedLocalItems() {
      this.localSelection = []
      this.filterObjects = []
      this.parents = []
      this.filtersChanged = true
    },
    handleSaveFilters() {
      this.$emit('save-filters', this.filterObjects)
    },
    handleLeavePanel() {
      this.resetSelectedLocalItems()
      this.$emit('leave-panel')
    },
    chipText(chip) {
      if (chip.children && chip.children.length > 0) {
        let totalChildren = 0
        const ids = []
        const getChildren = (node) => {
          for (const c of node.children) {
            if (!ids.includes(c.id)) {
              totalChildren++
              ids.push(c.id)
              getChildren(c)
            }
          }
        }

        for (const c of chip.children) {
          totalChildren++
          ids.push(c.id)
          getChildren(c)
        }
        return `${chip.name}` + ` +${totalChildren}`
      } else {
        return `${chip.name}`
      }
    },
  },
}
</script>

<style scoped>
.aside {
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  border-right: 1px solid var(--color-grey--lightest);
}

.field-wrapper {
  flex: 1;
  border: 1px solid var(--color-grey--lighter);
  border-radius: 24px;
  overflow: hidden;
  transition: all 200ms;
}

.field-wrapper:focus-within,
.field-wrapper:hover {
  border-color: var(--color-blue);
}

.field-suffix v-icon {
  color: var(--color-grey-lighter);
}

.field {
  display: flex;
  padding: 0 var(--space-base);
  align-items: center;
}

.search-input {
  width: 100%;
  line-height: 20px;
  padding: calc(var(--space-base) - 2px) 0;
}

.search-input:focus {
  outline: none;
}

.chip-container {
  display: flex;
  flex-wrap: wrap;
  max-height: 130px;
  overflow-y: auto;
  padding: calc(var(--space-base) - 4px) calc(var(--space-base) - 4px) 0;
  gap: var(--space-smaller);
}

.chip-container:empty {
  display: none;
}

.topStickyMenu {
  display: flex;
  flex-direction: column;
  gap: var(--space-small);
  position: sticky;
  padding: var(--space-small);
  background: var(--color-white);
  border-bottom: var(--color-grey--lightest) solid 2px;
  box-shadow: 0 6px 6px -6px var(--color-grey--lighter);
  z-index: 2;
}

.content {
  position: relative;
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.bottomStickyMenu {
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  position: sticky;
  background: var(--color-white);
  z-index: 2;
  box-shadow: 0px -3px 5px var(--color-grey--lighter);
}

.confirmFiltersButton {
  margin: var(--space-small) auto;
  display: block;
  font-weight: bold;
  width: 95%;
}
.saveButton,
.clearButton {
  margin: 10px;
  display: inline-block;
}
.saveButton {
  width: 130px;
}
.clearButton {
  width: 130px;
}

.vs-asidePanel {
  --text-color: var(--color-grey--dark);
  background-color: transparent;
  font-size: 14px;
}

.vs-asidePanel:hover,
.vs-asidePanel:focus {
  background-color: var(--color-grey--lightest);
}
.item-counter {
  font-size: 11px;
  font-style: italic;
  color: var(--color-grey);
  text-align: center;
  line-height: 2;
}
</style>
