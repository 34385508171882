<template>
  <p class="content">
    <span>
      {{ totalQuantity }}
    </span>
    <v-tooltip top max-width="350px">
      <template #activator="{ on }">
        <span v-on="on"><strong v-if="value.length"> total</strong></span>
      </template>
      <span v-html="tooltipText"></span>
    </v-tooltip>
  </p>
</template>

<script>
import { sum } from 'lodash'

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    job: {
      type: Object,
      required: true,
    },
  },
  computed: {
    items() {
      this.value.sort((a, b) => a.position - b.position)
      return this.value.map(
        (inv) => `${inv.inventorySubTypeName}: ${inv.quantity}`
      )
    },
    totalQuantity() {
      return !this.value.length
        ? ''
        : parseFloat(sum(this.value.map((inv) => inv.quantity)).toFixed(6))
    },
    tooltipText() {
      return this.items.join('<br />')
    },
  },
}
</script>

<style scoped>
.content {
  margin: 0;
}
</style>
