
import { defineComponent } from 'vue'
import { Prop } from 'vue/types/options'

export default defineComponent({
  name: 'JobBreadcrumbs',

  props: {
    job: {
      type: Object as Prop<{
        number: number
        assignmentGroupNumber: number | undefined
      }>,
    },
  },
})
