import * as fb from 'ironsight-form-builder'

export default function formFieldTitle(field) {
  if (field instanceof fb.MarkdownFieldDefinition) {
    return 'Markdown'
  }

  if (field instanceof fb.ReferenceField) {
    const title = field.titleOverride ?? field.reference.title
    return title && title.length > 0 ? title : 'Unnamed Field'
  }

  if (field.title && field.title.length > 0) {
    return field.title
  }
  return 'Unnamed Field'
}
