<template>
  <vs-dialog
    confirm-on-close
    :title="title"
    :visible="visible"
    @on-close="$emit('toggle-dialog')"
  >
    <vs-wrapper space="small">
      <vs-text v-if="requiredValueIsSelected">
        If field "<b class="emphasis">{{ getFieldLabel(field) }} </b>" is "<b
          class="emphasis"
          >{{ requiredValue }}</b
        >"
      </vs-text>
      <vs-text v-else
        >If field <b class="emphasis">{{ getFieldLabel(field) }}</b> matches the
        answer below
      </vs-text>

      <component
        :is="requiredValueEditorComponent"
        v-bind="currentProperties"
        v-model="requiredValue"
        @change="handleRequiredValueChange"
        @input="handleRequiredValueChange"
      ></component>

      <vs-text>This field determines which flow to go to </vs-text>

      <vs-autocomplete
        v-model="selectedFlow"
        :items="flowItems"
        label="Select a flow to go to"
        @input="handleInput"
      ></vs-autocomplete>

      <v-radio-group
        v-model="conditionalType"
        hide-details
        label="When should this flow appear?"
      >
        <v-radio :value="1" label="after the next button is pressed"></v-radio>
        <v-radio :value="0" label="immediately when condition is met"></v-radio>
      </v-radio-group>

      <vs-text v-if="errors.length > 0" class="error-message"
        >{{ errorMessage }}
      </vs-text>

      <div class="d-flex justify-end">
        <vs-button
          type="secondary"
          label="Cancel"
          class="mr-2"
          @click="$emit('toggle-dialog')"
        ></vs-button>
        <vs-button label="Save" @click="handleSave"></vs-button>
      </div>
    </vs-wrapper>
  </vs-dialog>
</template>

<script>
import VsDialog from '@/components/vision/VsDialog'
import VsWrapper from '@/components/vision/VsWrapper'
import VsText from '@/components/vision/VsText'
import VsAutocomplete from '@/components/vision/Inputs/VsAutocomplete/VsAutocomplete'
import VsButton from '@/components/vision/VsButton'
import { ConstGoToFlowDTO } from 'ironsight-form-builder/dist/models/conditionals/const_go_to_flow_dto'
import { ConditionalType } from 'ironsight-form-builder/dist/models/dependencies/conditions'
import {
  BooleanFieldDefinition,
  NumberFieldDefinition,
} from 'ironsight-form-builder'
import formFieldTitle from '@/utils/forms/form-field-title'
export default {
  name: 'AddFlowDialog',
  components: { VsButton, VsAutocomplete, VsText, VsWrapper, VsDialog },

  props: {
    builder: {
      type: Object,
      default: () => {},
    },
    activeFlow: {
      type: String,
      default: '#root_schema',
    },
    field: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: 'Add Go To Flow',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    selectFlow: {
      type: String,
      default: '',
    },
    requiredValueEditorComponent: {
      type: String,
      default: '',
    },
    currentProperties: {
      type: Object,
      default: () => {},
    },
    flowItems: {
      type: Array,
      default: () => [],
    },
  },

  data: () => {
    return {
      selectedFlow: '',
      errors: [],
      conditionalType: 1,
      requiredValue: undefined,
    }
  },

  computed: {
    dtoFlowFromInputs() {
      return new ConstGoToFlowDTO(
        this.field,
        this.requiredValue,
        this.selectedFlow,
        this.conditionalType
      )
    },
    requiredValueIsSelected() {
      return this.requiredValue !== undefined || this.requiredValue
    },
    errorMessage() {
      return this.errors.join('\n')
    },
  },
  methods: {
    getFieldLabel(field) {
      return formFieldTitle(field)
    },
    handleRequiredValueChange(value) {
      this.requiredValue = value

      if (this.field instanceof BooleanFieldDefinition) {
        this.requiredValue = value != null
      }
      if (this.field instanceof NumberFieldDefinition) {
        this.requiredValue = this.field.allowDecimals
          ? parseFloat(value)
          : parseInt(value)
      }
      this.$emit('required-value-change', this.requiredValue)
    },
    handleInput(value) {
      this.selectedFlow = value
    },
    handleSave() {
      this.errors = this.builder.validateConstGoToFlowDTO(
        this.dtoFlowFromInputs,
        false
      )
      if (this.errors.length === 0) {
        this.builder.addConstGoToFlowCondition(this.dtoFlowFromInputs)
        this.$emit('toggle-dialog')
      }
    },
  },
}
</script>

<style scoped>
.emphasis {
  color: var(--color-blue);
}
.error-message {
  color: var(--color-red);
  margin-bottom: var(--space-smaller);
}
</style>
