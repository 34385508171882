import { getFeatureFlagConfig } from '@/api/config'
import FeatureFlags from '../../models/feature_flags'
import { errorMutationHandler } from '../utils'

export const LOADING_FEATURE_FLAGS = 'LOADING_FEATURE_FLAGS'
export const RECEIVE_FEATURE_FLAGS = 'RECEIVE_FEATURE_FLAGS'
export const ERROR_FEATURE_FLAGS = 'ERROR_FEATURE_FLAGS'

const state = () => {
  return {
    all: {},
    loading: false,
    error: null,
  }
}

const getters = {
  getLoading: (state) => state.loading,
  getAllFeatureFlags: (state) => state.all,
  analyticsDrillDownEnabled: (state) => state.all.dashboardDrillDown,
  jobTripMapEnabled: (state) => state.all.jobTripMap,
  jobCommentsEnabled: (state) => state.all.jobComments,
  jobAssignmentGroupsEnabled: (state) => state.all.jobAssignmentGroups,
  jobsListFilterBeyond30DaysEnabled: (state) =>
    state.all.jobsListFilterBeyond30Days,
  assignmentFormRequirements: (state) => state.all.assignmentFormRequirements,
  weekViewDoubleHeightJobCard: (state) => state.all.weekViewDoubleHeightJobCard,
  submitJobForApprovalEnabled: (state) => state.all.submitJobForApproval,
  lineItems: (state) => state.all.lineItems,
  invoices: (state) => state.all.invoices,
  displayLocationVisitedEvents: (state) =>
    state.all.displayLocationVisitedEvents,
  customFieldVersioningEnabled: (state) =>
    state.all.customFieldVersioningEnabled,
  batchSyncing: (state) => state.all.batchSyncing,
}

const actions = {
  fetchFeatureFlags({ commit }) {
    commit(LOADING_FEATURE_FLAGS)

    return getFeatureFlagConfig()
      .then((res) => commit(RECEIVE_FEATURE_FLAGS, res))
      .catch((err) => commit(ERROR_FEATURE_FLAGS, err))
  },
}

const mutations = {
  [LOADING_FEATURE_FLAGS](state) {
    state.loading = true
  },
  [RECEIVE_FEATURE_FLAGS](state, { data }) {
    state.all = new FeatureFlags(data)
    state.loading = false
    state.error = null
  },
  [ERROR_FEATURE_FLAGS](state, error) {
    errorMutationHandler(state, error)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
