import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { computed } from 'vue'

export function useBulkUpdate() {
  const { useGetters: usePermissionsGetters } =
    createNamespacedHelpers('permissions')
  const { canBulkUpdateJobs } = usePermissionsGetters(['canBulkUpdateJobs'])

  const bulkUpdateEnabled = computed<boolean>(() => {
    return canBulkUpdateJobs.value
  })

  return bulkUpdateEnabled
}
