import { isEqual, orderBy } from 'lodash'
import Vue from 'vue'
import { getUser, getUsers } from '@/api/users'
import { DISPATCHER_ROLE, getInstance, SUPERADMIN_ROLE } from '@/auth'
import User from '../../models/user'
import { errorMutationHandler } from '../utils'

export const LOADING_USERS = 'LOADING_USERS'
export const RECEIVE_USER = 'RECEIVE_USER'
export const RECEIVE_USERS = 'RECEIVE_USERS'
export const SAVING_USER = 'SAVING_USER'
export const CLEAR_USER_ERROR = 'CLEAR_USER_ERROR'
export const ERROR_USERS = 'ERROR_USERS'

const operatorRole = 'Operator'

const state = () => {
  return {
    all: {},
    loading: false,
    saving: false,
    error: null,
  }
}

const getters = {
  getAllUsers: (state) => state.all,
  getLoading: (state) => state.loading,
  getSaving: (state) => state.saving,
  getError: (state) => state.error,
  getOperatorsForBusinessUnit: (_state, getters) => (businessUnitId) => {
    return getters
      .getUsersForBusinessUnit(businessUnitId)
      .filter((user) => user.roles.includes(operatorRole))
  },
  getBySid: (state) => (id) => state.all[id] || null,
  getSortedListItems: (state) => {
    return orderBy(
      Object.values(state.all),
      ['isDisabled', 'created'],
      ['asc', 'desc']
    )
  },
  getNonDisabledUsers: (state) => {
    return Object.values(state.all).filter((user) => user.isDisabled === false)
  },
  getVisibleUsers: (_state, getters, _rootState, rootGetters) => {
    const authService = getInstance()
    const partnerIds = rootGetters['permissions/getPartnerIds']
    const hostIds = rootGetters['permissions/getHostIds']

    const users = getters.getNonDisabledUsers.filter(
      (user) =>
        user.organizationId === authService.user.org_id ||
        partnerIds.includes(user.organizationId) ||
        user.scopes.some((scope) => hostIds.includes(scope))
    )

    return orderBy(users, [(user) => user.name.toLowerCase()])
  },
  getUsersForOrganizations: (_, getters) => (organizationId) =>
    getters.getNonDisabledUsers.filter(
      (user) => user.organizationId === organizationId
    ),
  getUserInListBySid: (state) => (sid) => state.all[sid] || null,
  getDispatchersOrAdminsInOrganization:
    (_state, getters) => (businessUnitId) => {
      return getters.getNonDisabledUsers.some(
        (user) =>
          user.organizationId === businessUnitId &&
          (user.roles.includes(DISPATCHER_ROLE) ||
            user.roles.includes(SUPERADMIN_ROLE))
      )
    },
  getAllUsersForOrganization: (_state, getters) => (organizationId) => {
    const users = Object.values(getters.getNonDisabledUsers).filter(
      (user) => user.organizationId === organizationId
    )

    return orderBy(users, [(user) => user.name.toLowerCase()])
  },
  getUsersRelatedToBusinessUnit:
    (_state, getters, _rootState, rootGetters) => (businessUnitId) => {
      const context = rootGetters['permissions/getContext']
      const scopes = context.roleAssignments.map((ra) => ra.businessUnitId)

      // Short circuit if the users scopes are not loaded yet
      if (scopes.length === 0) return []

      let users = getters.getUsersForBusinessUnit(businessUnitId)

      // If I belong to the business unit, fetch users partnered to said
      // business unit OUTSIDE of my direct lineage
      if (scopes.includes(businessUnitId)) {
        const partnerBusinessUnitIds = context.lineage
          .filter((bu) => bu.id === businessUnitId)
          .map((bu) => [...bu.partnerIds, ...bu.hostIds])
          .flat()

        users = users.concat(
          getters.getUsersForBusinessUnits(partnerBusinessUnitIds)
        )
        // If I am partnered to the business unit, fetch users partnered to
        // said business unit INSIDE of my direct lineage
      } else {
        const partneredBusinessUnitIds = context.lineage
          .filter((bu) => bu.hostIds.includes(businessUnitId))
          .map((bu) => bu.id)

        users = users.concat(
          getters.getUsersForBusinessUnits(partneredBusinessUnitIds)
        )
      }

      return orderBy(users, [(user) => user.name.toLowerCase()])
    },
  getUsersForBusinessUnit: (_state, getters) => (businessUnitId) => {
    return getters.getNonDisabledUsers.filter((user) =>
      user.scopes.includes(businessUnitId)
    )
  },
  getUsersForBusinessUnits: (_state, getters) => (businessUnitIds) => {
    return getters.getNonDisabledUsers.filter((user) =>
      businessUnitIds.some((id) => user.scopes.includes(id))
    )
  },
}

const actions = {
  fetchAllUsers({ commit }) {
    commit(LOADING_USERS)

    return getUsers()
      .then((res) => commit(RECEIVE_USERS, res))
      .catch((err) => commit(ERROR_USERS, err))
  },
  fetchUsersBySid({ commit }, sid) {
    commit(LOADING_USERS)

    return getUser(sid)
      .then((res) => commit(RECEIVE_USER, res))
      .catch((err) => commit(ERROR_USERS, err))
  },
}

const mutations = {
  [LOADING_USERS](state) {
    state.loading = true
  },
  [RECEIVE_USERS](state, { data }) {
    const receivedUsers = {}

    data.forEach((json) => {
      receivedUsers[json.id] = new User(json)
    })

    if (!isEqual(receivedUsers, state.all)) {
      state.all = receivedUsers
    }

    state.loading = false
    state.error = null
  },
  [RECEIVE_USER](state, { data }) {
    state.saving = false
    state.loading = false
    Vue.set(state.all, data.id, new User(data))
    state.error = null
  },
  [ERROR_USERS](state, error) {
    state.saving = false
    state.loading = false
    errorMutationHandler(state, error)
  },
  [CLEAR_USER_ERROR](state) {
    state.error = null
  },
  [SAVING_USER](state) {
    state.saving = true
    state.error = null
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
