<template>
  <vs-wrapper>
    <vs-heading type="subhead">Create a new project</vs-heading>
    <vs-wrapper space="small">
      <vs-form ref="project-form">
        <vs-text-input
          :value="projectName"
          label="Name"
          required
          autofocus
          @input="setProjectName"
        />
      </vs-form>

      <v-row no-gutters justify="end" align="center" class="mt-3">
        <vs-button
          data-test="create-project"
          label="Save"
          :loading="saving"
          @click="save"
        />
      </v-row>
    </vs-wrapper>
  </vs-wrapper>
</template>

<script>
import { mapState } from 'vuex'
import VsWrapper from '@/components/vision/VsWrapper.vue'
import VsHeading from '@/components/vision/VsHeading.vue'
import VsButton from '@/components/vision/VsButton.vue'
import VsForm from '@/components/vision/VsForm.vue'
import VsTextInput from '@/components/vision/Inputs/VsTextInput.vue'

export default {
  components: { VsTextInput, VsForm, VsButton, VsHeading, VsWrapper },
  data() {
    return {
      projectName: '',
    }
  },

  computed: {
    ...mapState('projects', ['saving', 'error']),
    hasChanges() {
      return !!this.projectName
    },
  },

  methods: {
    setProjectName(newName) {
      this.projectName = newName
    },
    async save() {
      if (!this.$refs['project-form'].isValid()) {
        return
      }

      this.$emit('save', this.projectName)
    },
  },
}
</script>
