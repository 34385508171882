<template>
  <div />
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ScheduleProvision',
  data() {
    return {
      pollingInterval: 30000,
      unitsTimeout: null,
    }
  },
  created() {
    this.fetchJobData()
    this.initializeJobColumns()
    this.fetchProjects()
    this.startUnitPolling()
  },
  destroyed() {
    this.stopUnitPolling()
  },
  methods: {
    ...mapActions({
      initializeJobColumns: 'jobsList/tryInitializeColumns',
      fetchJobData: 'fetchJobData',
      fetchUnits: 'unitNumbers/fetchUnitNumbers',
      fetchProjects: 'projects/fetchProjects',
    }),
    startUnitPolling() {
      clearTimeout(this.unitsTimeout)

      this.fetchUnits()

      this.unitsTimeout = setTimeout(async () => {
        this.startUnitPolling()
      }, this.pollingInterval)
    },
    stopUnitPolling() {
      clearTimeout(this.unitsTimeout)
    },
  },
}
</script>
