import Vue, { watchEffect, Ref, ref } from 'vue'
import { useQueryClient } from '@tanstack/vue-query'
import { getById } from '@/api/locations'
import JobHistory from '@/models/job-history'
import Location from '@/models/location'
import { notEmpty } from '../utils/notEmpty'

export function useLocations(changesRef: Ref<JobHistory[]>) {
  const knownLocations = ref<Record<number, Location>>({})
  const queryClient = useQueryClient()

  watchEffect(async () => {
    const locationsToFetch = changesRef.value.flatMap((change) => {
      return change.fieldsChanged
        .flatMap((field) => {
          if (field.columnName === 'destination_location_id') {
            return [field.originalValue, field.newValue]
          }

          if (field.columnName === 'inventory_item_id') {
            return [field.originalValue?.pickup, field.newValue?.pickup]
          }

          if (field.columnName === 'location_visited') {
            return [field.newValue]
          }

          return []
        })
        .filter(notEmpty)
    })

    const uniqueLocationIds = [...new Set(locationsToFetch)].filter(
      (id) => id && !knownLocations.value[id]
    )

    const locationPromises = uniqueLocationIds.map((id) =>
      queryClient
        .fetchQuery({
          queryKey: ['locations', id],
          queryFn: ({ signal }) => getById(id, signal),
          staleTime: 600000, // 10 minutes
        })
        .then((location) => ({ id, location }))
    )

    const results = await Promise.all(locationPromises)

    results.forEach(({ id, location }) => {
      Vue.set(knownLocations.value, id, location)
    })
  })

  return {
    knownLocations,
  }
}
