import axios, { AxiosInstance } from 'axios'
import { getInstance } from '@/auth'
import { SnakeCasedPropertiesDeep } from 'type-fest'
// Will only add the bearer token to endpoints that start with the base
export const BASE_API_ENDPOINT = '/api'

const addAuthInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    async function (config) {
      const authService = getInstance()
      const token = await authService.getTokenSilently()

      if (config.url!.startsWith(BASE_API_ENDPOINT)) {
        config.headers!.Authorization = `Bearer ${token}`
      }
      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  return instance;
}

export const defaultVersion = '2024-06-01';

export function createClient(version: string) {
  const instance: AxiosInstance = addAuthInterceptor(axios.create());

  instance.defaults.headers.common['Api-Version'] = version;

  return instance;
}

export const ironsightAxios = createClient(defaultVersion);

export function get<T = any>(url: string, args = {}) {
  return ironsightAxios.get<SnakeCasedPropertiesDeep<T>>(url, args)
}

export function patch(url: string, args: any = {}) {
  return ironsightAxios.patch(url, args.data || {}, args.config || {})
}

export function post(url: string, args: any = {}) {
  return ironsightAxios.post(url, args.data || {}, args.config || {})
}

export function put(url: string, args: any = {}) {
  return ironsightAxios.put(url, args.data || {}, args.config || {})
}

export function del(url: string, args: any = {}) {
  return ironsightAxios.delete(url, args || {})
}


