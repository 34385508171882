import Activity from '@/models/activity'
import { isEqual, orderBy, groupBy } from 'lodash'
import { getAll } from '../../api/assets'
import { Asset, AssetQuantityCondition } from '../../models/asset'
import errorNotification from './error-notification'

export const LOADING_ASSETS = 'LOADING_ASSETS'
export const RECEIVE_ASSETS = 'RECEIVE_ASSETS'
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const ERROR_LOADING = 'ERROR_LOADING'

export interface NormalizedInventory {
  id: number | null
  name: string | null
  assetConditions: AssetQuantityCondition[]
}

function normalizeInventories(inventories: Asset[]): NormalizedInventory[] {
  const normalizedInventories = Object.values(inventories).map((inv) => {
    const normalizedConditions: AssetQuantityCondition[] = inv.conditions.map(
      (cond) => Object.assign({}, cond, { quantity: 0 })
    )
    return {
      id: inv.id,
      name: inv.name,
      assetConditions: normalizedConditions,
    }
  })

  return orderBy(normalizedInventories, [(asset) => asset.name?.toLowerCase()])
}

const state = () => {
  return {
    all: {},
    loading: false,
  }
}

const modules = {
  errorNotification,
}

const getters = {
  getAllAssets: (state) => state.all,
  getNormalizedInventories: (state) => normalizeInventories(state.all),
  getById: (state) => (id) => state.all[id] || null,
  getConditionById: (state) => (id) => {
    const items = Object.values(state.all)
    const item: any = items.find((i: any) =>
      i.conditions?.some((c) => c.id === id)
    )
    return item?.conditions?.find((c) => c.id === id)
  },
  getByConditionId: (state) => (id) => {
    const items = Object.values(state.all)
    return items.find((i: any) => i.conditions?.some((c) => c.id === id))
  },
  isLoading: (state) => state.loading,
  getSortedItems: () => (items) => {
    return orderBy(items, 'id', 'desc')
  },
  getInventoriesByResourceTypeActivity:
    (_state, getters, rootState) => (resourceTypeActivityId?: number) => {
      const activity: Activity | null = resourceTypeActivityId
        ? rootState.activities.all[resourceTypeActivityId]
        : null

      const inventories =
        getters.getNormalizedInventories as NormalizedInventory[]

      if (!activity || !activity.inventoryAssociations?.length) {
        return inventories
      }

      const groupedInventoryAssociations = groupBy(
        activity.inventoryAssociations,
        (ia) => ia.inventoryTypeId
      )

      return Object.entries(groupedInventoryAssociations)
        .map(([key, inventoryAssociations]) => {
          const inventoryTypeId = parseInt(key)
          const asset = inventories.find((inv) => inv.id === inventoryTypeId)

          if (!asset) {
            return null
          }

          const hasSingleInventoryAssociation =
            inventoryAssociations?.length === 1 &&
            !inventoryAssociations[0].inventorySubTypeId

          const shouldShowAllSubTypes = inventoryAssociations.some(
            (ia) => ia.inventorySubTypeId === null
          )

          const canShowAssetWithoutFilteringSubTypes =
            hasSingleInventoryAssociation || shouldShowAllSubTypes

          if (canShowAssetWithoutFilteringSubTypes) {
            return asset
          } else {
            const subTypes = inventoryAssociations.map(
              (ia) => ia.inventorySubTypeId
            )
            return {
              ...asset,
              assetConditions: asset.assetConditions.filter((ac) =>
                subTypes.includes(ac.id!)
              ),
            }
          }
        })
        .filter((inv) => inv !== null)
    },
}

const actions = {
  async fetchAssets({ commit }) {
    commit(LOADING_ASSETS)

    return getAll()
      .then((res) => {
        commit(RECEIVE_ASSETS, res)
        commit(CLEAR_ERROR, res)
      })
      .catch((err) => commit(ERROR_LOADING, err))
  },
}

const mutations = {
  [LOADING_ASSETS](state) {
    state.loading = true
  },
  [RECEIVE_ASSETS](state, { data }) {
    const receivedItems = {}

    data.forEach((json) => {
      receivedItems[json.id] = new Asset(json)
    })

    if (!isEqual(receivedItems, state.all)) {
      state.all = receivedItems
    }

    state.loading = false
  },
}

export default {
  namespaced: true,
  modules,
  state,
  getters,
  actions,
  mutations,
}
