<template>
  <vs-wrapper>
    <vs-autocomplete
      data-test="creatable-selector-field"
      free-form-create
      :items="items"
      :label="title"
      :hint="description"
      :value="formData"
      :error-messages="errorMessages"
      :disabled="disabled"
      @input="handleChange"
    />
  </vs-wrapper>
</template>

<script>
export default {
  name: 'CreatableSelectorField',
  props: {
    errorSchema: Object,
    formData: [String, Number],
    params: Object,
    rootSchema: Object,
    schema: Object,
    title: String,
    description: String,
    uiSchema: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    items() {
      return this.uiSchema['ui:options'].choices.map((choice) => {
        return { text: String(choice), value: choice }
      })
    },
    errorMessages() {
      const errors = this.errorSchema?.errors ?? []
      if (
        this.formData &&
        typeof this.formData !== 'number' &&
        typeof this.formData !== 'string'
      ) {
        errors.push('Field value expected.')
      }
      return errors
    },
    selectorType() {
      return this.schema.type
    },
  },
  methods: {
    handleChange(value) {
      if (this.selectorType === 'integer') {
        value = parseInt(value) || null
      } else if (this.selectorType === 'number') {
        value = parseFloat(value) || null
      }

      this.$emit('change', value)
    },
  },
}
</script>

<style scoped></style>
