import {
  formatDateTime,
  formatDateWithYear,
  formatDurationAsHours,
} from '@/utils/date-formatting'
import { intersection, sum } from 'lodash'
import moment from 'moment'
import {
  getAllJobCustomFields,
  mergeListOfJsonSchemas,
} from '@/utils/field-schema-helper'
import { LocationJobColumn } from '@/components/jobs/cells'

export const SET_VISIBLE_COLUMNS = 'SET_VISIBLE_COLUMNS'
export const SET_COLUMN_ORDERING = 'SET_COLUMN_ORDERING'
export const JOBS_LIST_INITIALIZED = 'JOBS_LIST_INITIALIZED'

export const columns = {
  priority: {
    value: 'priority',
    text: 'Priority',
    key: 'name',
    component: 'Priority',
  },
  id: {
    value: 'id',
    text: '#',
  },
  status: {
    value: 'status',
    text: 'Job Status',
    component: 'JobStatus',
    formatter: (value) => value.replace(/([A-Z])/g, ' $1'),
  },
  resourceType: {
    value: 'resourceType',
    text: 'Resource Type',
    key: 'name',
  },
  activity: {
    value: 'activity',
    text: 'Activity',
    key: 'taskName',
  },
  unit: {
    value: 'unit',
    text: 'Resource',
    key: 'name',
  },
  serviceProvider: {
    value: 'serviceProvider',
    text: 'Service Provider',
    key: 'name',
  },
  operator: {
    value: 'operator',
    text: 'Operator',
    component: 'Operator',
    formatter: (value) => value.name,
    sortBy: (value) => value[0]?.name ?? '',
  },
  details: {
    value: 'details',
    text: 'Details',
    component: 'Details',
  },
  created: {
    value: 'created',
    text: 'Submitted',
    formatter: (value) => value && formatDateTime(value),
    sortBy: (value) => moment(value).unix(),
  },
  createdBy: { selector: 'submitter', text: 'Submitter', value: 'createdBy' },
  modified: {
    value: 'modified',
    text: 'Last updated',
    formatter: (value) => value && formatDateTime(value),
    sortBy: (value) => moment(value).unix(),
  },
  startTime: {
    value: 'startTime',
    text: 'Start',
    component: 'StartTime',
    formatter: (value) => value && formatDateTime(value),
    sortBy: (value) => moment(value).unix(),
  },
  endTime: {
    value: 'endTime',
    text: 'End',
    component: 'EndTime',
    formatter: (value) => value && formatDateTime(value),
    sortBy: (value) => moment(value).unix(),
  },
  pickupLocations: {
    value: 'pickupLocations',
    text: 'Pick Up Location',
    component: 'PickupLocations',
    formatter: (value) => value.location,
    sortBy: (value) => value[0]?.location ?? '',
  },
  pickupLocationItems: {
    value: 'pickupLocationItems',
    text: 'Item',
    component: 'Items',
    formatter: (value) => value.itemName,
    sortBy: (value) => value[0]?.itemName ?? '',
  },
  inventory: {
    value: 'inventory',
    text: 'Qty',
    component: 'ItemsQuantity',
    formatter: (value) => value.quantity.toString(),
    sortBy: (value) => sum(value.map((inv) => inv.quantity)),
  },
  destinationLocation: {
    value: 'destinationLocation',
    text: 'Destination',
    key: 'location',
  },
  customer: {
    value: 'customer',
    text: 'Requester',
    key: 'name',
  },
  costCenter: {
    value: 'costCenter',
    text: 'Cost Center',
  },
  division: {
    value: 'division',
    text: 'Division',
    key: 'name',
  },
  approvalStatus: {
    value: 'approvalStatus',
    text: 'Approval Status',
    formatter: (value) => value.replace(/([A-Z])/g, ' $1').trim(),
  },
  project: {
    value: 'project',
    text: 'Project',
    key: 'displayName',
  },
  batchNumber: {
    value: 'batchNumber',
    text: 'Batch #',
  },
  tags: {
    value: 'tags',
    text: 'Tags',
    component: 'Tags',
    formatter: (value) => value,
  },
  actualActiveDuration: {
    value: 'actualActiveDuration',
    text: 'Active Duration',
    formatter: (value, job) => formatDurationAsHours(value, job.isCompleted),
    sortBy: (value) => value?.asMilliseconds() ?? 0,
  },
  activeTime: {
    value: 'activeTime',
    text: 'Activated',
    formatter: (value) => value && formatDateTime(value),
    sortBy: (value) => moment(value).unix(),
  },
  completedTime: {
    value: 'completedTime',
    text: 'Completed',
    formatter: (value) => value && formatDateTime(value),
    sortBy: (value) => moment(value).unix(),
  },
}

const state = {
  initialized: false,
  visibleColumns: [],
  columnOrdering: [],
  pagination: {
    page: 1,
    itemsPerPage: 20,
    sortBy: [],
    sortDesc: [],
  },
}

export const getters = {
  getFeatureFieldColumns: (_state, _getters, _rootState, rootGetters) => {
    const allFeatureColumns = {
      assignmentGroupNumber: {
        value: 'assignmentGroupNumber',
        text: 'Assignment Group',
      },
    }
    const jobAssignmentGroupsEnabled =
      rootGetters['featureFlags/jobAssignmentGroupsEnabled']

    return jobAssignmentGroupsEnabled ? allFeatureColumns : {}
  },
  getCustomFieldColumns: (_state, _getters, rootState, rootGetters) => {
    const baseSchema = rootGetters['schemas/getJobCustomFieldsSchema']
    const divisionCustomFieldsSchemas =
      rootGetters['divisions/getCustomFieldsSchema']
    const activityCustomFieldsSchemas =
      rootGetters['tasks/getCustomFieldsSchema']

    return buildColumnListFromSchemas(
      baseSchema,
      divisionCustomFieldsSchemas,
      activityCustomFieldsSchemas,
      rootGetters
    )
  },

  getHubSpecificCustomFieldColumns: (
    _state,
    getters,
    _rootState,
    rootGetters
  ) => {
    const baseSchema = rootGetters['schemas/getJobCustomFieldsSchema']

    const allDivisions = rootGetters['divisions/getAllDivisions']
    const allActivities = rootGetters['tasks/getAllItems']

    const allHubIds = rootGetters['permissions/getAccessibleHubIds']
    const hubBasedSchemas = allHubIds.reduce((obj, hubId) => {
      const divisionCustomFieldsSchemas = Object.values(allDivisions)
        .filter(
          (division) =>
            division &&
            division.jobCustomFieldsSchema &&
            division.ownerId === hubId
        )
        .map((division) => division.jobCustomFieldsSchema)

      const activityCustomFieldsSchemas = Object.values(allActivities)
        .filter(
          (task) => task && task.jobCustomFieldsSchema && task.ownerId === hubId
        )
        .map((task) => task.jobCustomFieldsSchema)

      return {
        ...obj,
        [hubId]: buildColumnListFromSchemas(
          baseSchema,
          divisionCustomFieldsSchemas,
          activityCustomFieldsSchemas,
          rootGetters
        ),
      }
    }, {})

    const finalSchema = {}
    Object.entries(getters.getCustomFieldColumns).forEach(([key, field]) => {
      finalSchema[key] = {
        ...field,
        formatter: buildHubKeyedFormatter(hubBasedSchemas, allHubIds, key),
        component: buildHubKeyedComponent(hubBasedSchemas, allHubIds, key),
      }
    })

    return finalSchema
  },

  getCoreColumns: (_state, getters, _, rootGetters) => {
    const canViewBatchColumn =
      rootGetters['permissions/canViewBatchColumnInJobsList']
    if (!canViewBatchColumn) {
      const { batchNumber, ...newColumns } = columns
      return {
        ...newColumns,
        ...getters.getFeatureFieldColumns,
      }
    }
    return {
      ...columns,
      ...getters.getFeatureFieldColumns,
    }
  },

  getAllColumns(_state, getters, _, rootGetters) {
    const canViewBatchColumn =
      rootGetters['permissions/canViewBatchColumnInJobsList']
    if (!canViewBatchColumn) {
      const { batchNumber, ...newColumns } = columns
      return {
        ...newColumns,
        ...getters.getFeatureFieldColumns,
        ...getters.getCustomFieldColumns,
      }
    }
    return {
      ...columns,
      ...getters.getFeatureFieldColumns,
      ...getters.getCustomFieldColumns,
    }
  },

  getOrderedColumns(state, getters) {
    return columnOrderer(getters.getAllColumns, state.columnOrdering)
  },

  getVisibleColumnIds: (state, getters) => {
    return removeDuplicateAndOutdatedColumns(
      Object.keys(getters.getAllColumns),
      state.visibleColumns
    )
  },
  allCoreColumnsSelected: (state, getters) => {
    const coreColumnValues = Object.values(getters.getCoreColumns).map(
      (d) => d.value
    )
    return coreColumnValues.every((c) => state.visibleColumns.includes(c))
  },
  allCustomColumnsSelected: (state, getters) => {
    const customColumnValues = Object.values(getters.getCustomFieldColumns).map(
      (d) => d.value
    )
    return customColumnValues.every((c) => state.visibleColumns.includes(c))
  },
  allColumnsSelected: (_, getters) => {
    return getters.allCoreColumnsSelected && getters.allCustomColumnsSelected
  },
}

export const actions = {
  updateVisibleColumns({ commit, getters }, columnIds) {
    const columns = removeDuplicateAndOutdatedColumns(
      Object.keys(getters.getAllColumns),
      columnIds
    )
    commit(SET_VISIBLE_COLUMNS, {
      columns,
    })
  },
  updateColumnOrder({ commit, getters }, columnIds) {
    const ordering = removeDuplicateAndOutdatedColumns(
      Object.keys(getters.getAllColumns),
      columnIds
    )
    commit(SET_COLUMN_ORDERING, {
      ordering,
    })
  },
  tryInitializeColumns({ commit, state, getters }) {
    if (state.initialized && state.visibleColumns.length > 0) {
      return true
    }

    commit(SET_VISIBLE_COLUMNS, {
      columns: Object.values(getters.getCoreColumns).map((d) => d.value),
    })

    commit(SET_COLUMN_ORDERING, {
      ordering: Object.values(getters.getAllColumns).map((d) => d.value),
    })

    commit(JOBS_LIST_INITIALIZED)
  },

  toggleAllColumns({ state, commit, getters }) {
    if (getters.allColumnsSelected) {
      commit(SET_VISIBLE_COLUMNS, {
        columns: [],
      })
    } else {
      const coreColumnValues = Object.values(getters.getCoreColumns).map(
        (d) => d.value
      )
      const customColumnValues = Object.values(
        getters.getCustomFieldColumns
      ).map((d) => d.value)

      commit(SET_VISIBLE_COLUMNS, {
        columns: [...coreColumnValues, ...customColumnValues],
      })
    }
  },

  toggleCoreColumns({ state, commit, getters }) {
    let visibleColumns = state.visibleColumns
    const coreColumnValues = Object.values(getters.getCoreColumns).map(
      (d) => d.value
    )

    if (getters.allCoreColumnsSelected) {
      visibleColumns = visibleColumns.filter(
        (c) => !coreColumnValues.includes(c)
      )
    } else {
      visibleColumns = [...new Set([...visibleColumns, ...coreColumnValues])]
    }

    commit(SET_VISIBLE_COLUMNS, {
      columns: visibleColumns,
    })
  },

  toggleCustomColumns({ state, commit, getters }) {
    let visibleColumns = state.visibleColumns
    const customColumnValues = Object.values(getters.getCustomFieldColumns).map(
      (d) => d.value
    )

    if (getters.allCustomColumnsSelected) {
      visibleColumns = visibleColumns.filter(
        (c) => !customColumnValues.includes(c)
      )
    } else {
      visibleColumns = [...new Set([...visibleColumns, ...customColumnValues])]
    }

    commit(SET_VISIBLE_COLUMNS, {
      columns: visibleColumns,
    })
  },

  resetColumns({ commit, dispatch }) {
    commit(SET_VISIBLE_COLUMNS, {
      columns: [],
    })

    dispatch('tryInitializeColumns')
  },
}

const mutations = {
  SET_PAGINATION(state, pagination) {
    state.pagination = pagination
  },
  SET_PAGINATION_PAGE(state, { page }) {
    state.pagination.page = page
  },
  SET_VISIBLE_COLUMNS(state, { columns }) {
    state.visibleColumns = columns
  },
  SET_COLUMN_ORDERING(state, { ordering }) {
    state.columnOrdering = ordering
  },
  JOBS_LIST_INITIALIZED(state) {
    state.initialized = true
  },
}

function removeDuplicateAndOutdatedColumns(validColumnIds, inputColumnIds) {
  // The input ids can contain outdated values from local storage
  // (e.g. if we rename a column, or change a custom field)
  return [...new Set(intersection(inputColumnIds, validColumnIds))]
}

function getCustomFieldColumnFormatter(definition, rootGetters) {
  if (definition.dataType === 'boolean') {
    return (value) => formatBooleanValue(value)
  } else if (definition.format) {
    return formatDateValues(definition)
  } else if (definition?.lookup?.entity) {
    return formatLookupValues(definition, rootGetters)
  } else {
    return null
  }
}

function getCustomFieldColumnComponent(definition) {
  if (definition?.lookup?.entity) {
    switch (definition.lookup.entity) {
      case 'location':
        return LocationJobColumn
    }
  }
  return null
}

function columnOrderer(columns, sortingOrder) {
  const result = []
  // If the sorting order is missing values present in the column object (which
  // can happen on a users first load, due to the custom field columns not being
  // ready when the default order is set), add the keys before sorting
  const missingKeys = Object.keys(columns)
    .filter((column) => !sortingOrder.includes(column))
    .map((key) => columns[key].value)

  sortingOrder = [...sortingOrder, ...missingKeys]

  sortingOrder.map((value) =>
    result.push(
      Object.values(columns).filter((column) => column.value === value)[0]
    )
  )

  return result.filter(Boolean)
}

function formatBooleanValue(value) {
  if (value === true) {
    return 'Yes'
  } else if (value === false) {
    return 'No'
  } else {
    return value
  }
}

function formatDateValues(definition) {
  switch (definition.format) {
    case 'date':
      return (value) => value && formatDateWithYear(value)
    case 'date-time':
      return (value) => value && formatDateTime(value)
    default:
      return (value) => value
  }
}

function formatLookupValues(definition, rootGetters) {
  switch (definition.lookup.entity) {
    case 'user':
      return (value) => value && rootGetters['users/getBySid'](value)?.name
    case 'company':
      return (value) => value && rootGetters['companies/getById'](value)?.name
    case 'business_unit':
      return (value) =>
        value && rootGetters['businessUnits/getBusinessUnitById'](value)?.name
    case 'inventory_sub_type':
      return (value) =>
        value &&
        rootGetters['assets/getConditionById'](value)?.name +
          ' · ' +
          rootGetters['assets/getByConditionId'](value)?.name
    case 'location':
    default:
      return (value) => value
  }
}

function buildColumnListFromSchemas(
  baseSchema,
  divisionCustomFieldsSchemas,
  activityCustomFieldsSchemas,
  rootGetters
) {
  if (
    !baseSchema &&
    !divisionCustomFieldsSchemas &&
    !activityCustomFieldsSchemas
  ) {
    return {}
  }

  const ignorableFieldTypes = ['object', 'array', 'null']

  const mergedSchemas = mergeListOfJsonSchemas(
    activityCustomFieldsSchemas ?? {},
    divisionCustomFieldsSchemas ?? {},
    baseSchema ?? {}
  )
  const customFields = getAllJobCustomFields(mergedSchemas)
  const customFieldColumns = {}

  Object.entries(customFields).forEach(([key, field]) => {
    if (!ignorableFieldTypes.includes(field.dataType)) {
      const formatterFunction = getCustomFieldColumnFormatter(
        field,
        rootGetters
      )
      const customComponent = getCustomFieldColumnComponent(field)

      customFieldColumns[key] = {
        value: key,
        text: field.title,
        formatter: formatterFunction,
      }
      if (customComponent != null) {
        customFieldColumns[key].component = customComponent
      }
    }
  })

  return customFieldColumns
}

function buildHubKeyedFormatter(hubBasedSchemas, hubIds, fieldKey) {
  return hubIds.reduce((a, b) => {
    return { ...a, [b]: hubBasedSchemas[b][fieldKey]?.formatter }
  }, {})
}

function buildHubKeyedComponent(hubBasedSchemas, hubIds, fieldKey) {
  return hubIds.reduce((a, b) => {
    return { ...a, [b]: hubBasedSchemas[b][fieldKey]?.component }
  }, {})
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
