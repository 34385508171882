<template>
  <v-breadcrumbs
    v-if="breadcrumbs.length > 0"
    class="pa-0 mb-2 pt-2"
    :items="breadcrumbs"
    divider="/"
  >
    <template #item="{ item }">
      <v-breadcrumbs-item :text="item.text" :to="item.to" :exact="item.exact">
        <span class="overflow">
          {{ item.text }}
        </span>
      </v-breadcrumbs-item>
    </template>

    <template #divider>
      <v-icon>chevron_right</v-icon>
    </template>
  </v-breadcrumbs>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    jobId: {
      type: Number,
      required: true,
    },
    jobDisplayName: {
      type: String,
      required: true,
    },
    route: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      getProjectById: 'projects/getById',
      getJobById: 'jobs/getById',
    }),
    breadcrumbs() {
      const job = this.getJobById(this.jobId)
      if (job == null) return []
      const project = this.getProjectById(job.projectId)
      if (project == null) return []

      return [
        {
          text: project.displayName,
          exact: true,
          to: {
            name: this.route,
            params: { projectId: job.projectId },
          },
        },
        {
          text: this.jobDisplayName,
        },
      ]
    },
  },
}
</script>

<style scoped>
.overflow {
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
