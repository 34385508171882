import { computed, Ref } from 'vue'
import { useInvoiceQuery } from '@/api/invoice'
import { BusinessUnit } from '@/models/business-unit'
import { useStore } from 'vuex-composition-helpers'

export const useBatchStates = (batchId: Ref<string | null | undefined>) => {
  const { data: batch } = useInvoiceQuery(batchId)
  const batchHasLineItems = computed(() => batch.value?.hasLineItems ?? false)

  const store = useStore()
  const getBusinessUnitById = computed<
    (id?: string | null) => BusinessUnit | null | undefined
  >(() => store.getters['businessUnits/getBusinessUnitById'])
  const hub = computed(() => getBusinessUnitById.value(batch.value?.hubId))
  const hubOwner = computed(() => getBusinessUnitById.value(hub.value?.ownerId))
  const batchClientName = computed(() => hubOwner.value?.name)

  return {
    batchHasLineItems,
    batchClientName,
  }
}
