import { computed, inject, onMounted, onUnmounted, Ref, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'

type FieldOptions = {
  value: Ref<any>
  required?: boolean
  maxLength?: number
  minLength?: number
  localRules?: ((value: any) => string | undefined)[]
}

/**
 * This composables is a replacement for using the sharedOptions
 * mixin that was used by other vision inputs for registering the
 * input field with the form, setting error messages, running
 * validation etc..
 */
export const useFormField = (options: FieldOptions) => {
  const form = inject('VsFormRegistration', {
    register: (_: any) => {},
    unregister: (_: any) => {},
  })

  const _uid = ref<string>(uuidv4())
  const allErrorMessages = ref<string[]>([])

  const hasErrorMessages = computed(() => allErrorMessages.value.length > 0)

  const runValidation = () => {
    const messages: string[] = []

    const valueIsEmpty =
      !(options.value.value || options.value.value === 0) ||
      (typeof options.value.value === 'string' &&
        options.value.value.trim().length === 0) ||
      (Array.isArray(options.value.value) && options.value.value.length === 0)

    // Don't fire other validation rules when a field is required and left empty
    if (options.required && valueIsEmpty) {
      messages.push('This field is required')
    } else if (
      options.maxLength &&
      !valueIsEmpty &&
      options.value.value.length > options.maxLength
    ) {
      messages.push(
        `This field can contain at most ${options.maxLength} characters`
      )
    } else if (
      options.minLength &&
      !valueIsEmpty &&
      options.value.value.length < options.minLength
    ) {
      messages.push(
        `This field must contain at least ${options.minLength} characters`
      )
    }

    options.localRules?.forEach((func) => {
      const result = func(options.value)
      if (result) {
        messages.push(result)
      }
    })

    allErrorMessages.value = messages.filter(Boolean)

    return allErrorMessages.value.length > 0
  }

  const addErrorMessages = (message: string[]) => {
    allErrorMessages.value = message
  }

  const resetErrorMessages = () => {
    allErrorMessages.value = []
  }

  const self = {
    _uid,
    hasErrorMessages,
    allErrorMessages,
    runValidation,
    addErrorMessages,
    resetErrorMessages,
  }

  onMounted(() => {
    form.register(self)
  })

  onUnmounted(() => {
    form.unregister(self)
  })

  return self
}
