<template>
  <div class="page" :class="width">
    <div ref="bar" class="heading" :class="{ 'is-sticked ': isTopBarSticked }">
      <div class="heading-content">
        <v-row dense align="center">
          <v-col class="py-0">
            <vs-heading type="title" tag="h1">{{ title }}</vs-heading>
          </v-col>

          <v-col v-if="primaryAction" class="shrink py-0">
            <vs-button
              :label="primaryAction.label"
              :to="primaryAction.to"
              data-test="primary-action"
            />
          </v-col>
        </v-row>
      </div>
    </div>

    <div v-if="description" class="page-description">
      <vs-text type="intro">{{ description }}</vs-text>
    </div>

    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: () => null,
      required: true,
    },
    description: {
      type: String,
      default: () => null,
    },

    /**
     *  Adds a blue button at the far right side of the title and description.
     *
     * @param {Object} action Action object.
     * @param {String} action.label The label of the action
     * @param {String | Object} action.to Goes to a url or route object
     */
    primaryAction: {
      type: Object,
      default: () => null,
    },
    width: {
      type: String,
      default: 'standard',
      validator: (value) => ['fill', 'narrow', 'standard'].includes(value),
    },
  },
  data() {
    return {
      isTopBarSticked: false,
    }
  },
  mounted() {
    const observer = new IntersectionObserver(
      ([entries]) => {
        const isIntersecting = entries.intersectionRect.y === 0
        return (this.isTopBarSticked = isIntersecting)
      },
      { threshold: [1] }
    )
    observer.observe(this.$refs.bar)
  },
}
</script>

<style scoped>
.page {
  padding-top: var(--space-small);
}

.page-title {
  font-size: 26px;
  line-height: 36px;
  color: var(--color-black);
}

.page-description {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
}

/**
 * 1. Temporary fix for windows chrome not sticking properly
 */

.heading {
  position: sticky;
  top: -1px;
  z-index: 2;
  background-color: #fafafa; /* 1 */
}

.heading-content {
  padding: var(--space-base) var(--space-large);
}

.heading.is-sticked {
  background-color: var(--color-white);
  box-shadow: 0px 4px 4px -4px rgba(0, 0, 0, 0.1),
    0px 16px 16px -16px rgba(0, 0, 0, 0.1);
}

.page-description {
  padding: 0 var(--space-large) var(--space-base);
}

.content {
  padding: 0 var(--space-large) var(--space-large);
}

.narrow .heading-content,
.narrow .page-description,
.narrow .content {
  max-width: 960px;
  margin: 0 auto;
}

.standard .heading-content,
.standard .page-description,
.standard .content {
  max-width: 1280px;
  margin: 0 auto;
}
</style>
