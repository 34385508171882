<template>
  <div
    ref="wrapper"
    class="form-layout-wrapper"
    :class="{ 'full-screen': fullScreen, 'has-description': description }"
  >
    <div
      v-if="!hideTopBar"
      ref="top-bar"
      space="small"
      class="top-bar"
      :class="{ 'is-sticky': isTopBarSticked }"
    >
      <v-row no-gutters>
        <v-col>
          <v-row no-gutters>
            <v-col>
              <slot name="breadcrumbs" />
              <vs-heading v-if="overline" type="overline">
                <router-link
                  v-if="overlineLinkTo"
                  :to="overlineLinkTo"
                  class="overline-link"
                  >{{ overline }}
                </router-link>
                <span v-else>{{ overline }}</span>
              </vs-heading>
              <vs-heading
                v-if="title"
                data-test="formlayout-title"
                type="title"
                class="formlayout-title"
                >{{ title }}
              </vs-heading>
            </v-col>
            <v-col
              v-if="label || $slots.label"
              class="shrink pl-2 pt-1"
              :align-self="$slots.breadcrumbs ? 'start' : 'center'"
            >
              <slot name="label">
                <v-chip :color="labelColor">{{ label }}</v-chip>
              </slot>
            </v-col>
          </v-row>
        </v-col>

        <v-col v-if="!fullScreen" class="shrink">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                data-test="formlayout-close"
                class="ma-0"
                icon
                v-on="on"
                @click="handleDismiss"
              >
                <v-icon>close</v-icon>
              </v-btn>
            </template>

            Close
          </v-tooltip>
        </v-col>
      </v-row>
      <slot name="extras" />
    </div>

    <div
      ref="content"
      :class="{ content: true, 'no-scroll': noScroll, 'content-dense': dense }"
      @scroll.passive="refreshBarPositions"
    >
      <slot name="top-sheet" />

      <template v-if="isLoading">
        <v-progress-circular
          :size="64"
          :width="6"
          indeterminate
          color="primary"
        />
      </template>
      <template v-else>
        <vs-text v-if="description" tag="pre" class="mb-2"
          >{{ description }}
        </vs-text>

        <slot />
      </template>

      <div v-if="$slots['snack-bar-wrapper']" class="snack-bar-wrapper">
        <slot name="snack-bar-wrapper" />
      </div>
    </div>

    <slot v-if="!isLoading" name="bulk-update-save-button" />

    <div
      v-if="!isLoading && !hideBottomBar"
      ref="bottom-bar"
      class="bottom-bar"
      :class="{
        'is-sticky': bottomBarStickBottom || isBottomBarSticked,
        'bottom-bar-stick-bottom': bottomBarStickBottom,
      }"
    >
      <FormLayoutActionBar
        :save-label="saveLabel"
        :discard-label="discardLabel"
        :changes-label="changesLabel"
        :is-saving="isSaving"
        :has-changes="hasChanges"
        :hide-save-button="hideSaveButton"
        v-on="getActions"
      >
        <template #action-bar>
          <slot name="action-bar" />
        </template>
      </FormLayoutActionBar>
    </div>
    <slot name="bottom-sheet" />
  </div>
</template>

<script>
import { isBottomBarSticky, isScrolled } from '@/utils/form-layout'
import FormLayoutActionBar from '@/components/common/FormLayoutActionBar'
import { debounce } from 'lodash'
import VsHeading from '@/components/vision/VsHeading.vue'
import VsText from '@/components/vision/VsText.vue'

export default {
  name: 'FormLayout',
  components: { FormLayoutActionBar, VsHeading, VsText },
  props: {
    title: {
      type: String,
      default: null,
    },
    overline: {
      type: String,
      default: null,
    },
    overlineLinkTo: {
      type: Object,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    hasChanges: {
      type: Boolean,
      default: false,
    },
    saveLabel: {
      type: String,
      default: 'Save',
    },
    discardLabel: {
      type: String,
      default: 'Discard',
    },
    changesLabel: {
      type: String,
      default: 'You have unsaved changes',
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
    backLabel: {
      type: String,
      default: 'Back',
    },
    label: {
      type: String,
      default: null,
    },
    labelColor: {
      type: String,
      default: null,
    },
    hideSaveButton: {
      type: Boolean,
      default: false,
    },
    hideTopBar: {
      type: Boolean,
      default: false,
    },
    hideBottomBar: {
      type: Boolean,
      default: false,
    },
    noScroll: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    bottomBarStickBottom: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isTopBarSticked: false,
      isBottomBarSticked: false,
      stickyBarObserver: null,
    }
  },
  computed: {
    getActions() {
      return {
        ...(this.$listeners['on-save'] && { save: this.handleSave }),
        ...(this.$listeners['on-discard'] && { discard: this.handleDiscard }),
        ...(this.$listeners['on-delete'] && { delete: this.handleDelete }),
      }
    },
  },
  mounted() {
    document.addEventListener('keyup', this.handleEscKeyPress)
    this.stickyBarObserver = new ResizeObserver(this.refreshBarPositions)
    this.stickyBarObserver.observe(this.$refs.content)
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.handleEscKeyPress)
    this.stickyBarObserver.unobserve(this.$refs.content)
  },
  methods: {
    refreshBarPositions: debounce(function () {
      const content = this.$refs.content
      if (content) {
        this.isBottomBarSticked = isBottomBarSticky(
          content.scrollTop,
          content.scrollHeight,
          content.offsetHeight
        )
        this.isTopBarSticked = isScrolled(content.scrollTop)
      }
    }, 20),
    handleSave() {
      this.$emit('on-save')
    },
    handleDismiss() {
      this.$emit('on-dismiss')
    },
    handleDiscard() {
      this.$emit('on-discard')
    },
    handleDelete() {
      this.$emit('on-delete')
    },
    handleBackClick() {
      this.$emit('on-back')
    },
    handleEscKeyPress(event) {
      if (event.key === 'Esc' || event.key === 'Escape') {
        this.handleDismiss()
      }
    },
  },
}
</script>

<style scoped>
.form-layout-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.full-screen {
  background-color: #fff;
}

.content {
  position: relative;
  flex: 0 1 auto;
  overflow: auto;
  padding: var(--space-base) var(--space-large);
}

.has-description .content {
  padding-top: 0;
}

.top-bar,
.bottom-bar {
  position: relative;
  flex: 0 0 auto;
  transition: all 100ms ease;
}

.top-bar {
  padding: var(--space-base) var(--space-large) var(--space-small)
    var(--space-large);
}

.bottom-bar {
  background-color: #fff;
}

.bottom-bar-stick-bottom {
  margin-block-start: auto;
}

/* Is Sticky */

.is-sticky {
  background-color: #fff;
  z-index: var(--elevation-sticky-bar);
}

.top-bar.is-sticky {
  padding: var(--space-base) var(--space-large) var(--space-small)
    var(--space-large);
  box-shadow: 0px 4px 4px -4px rgba(0, 0, 0, 0.1),
    0px 16px 16px -16px rgba(0, 0, 0, 0.1);
}

.bottom-bar.is-sticky {
  box-shadow: 0px -4px 4px -4px rgba(0, 0, 0, 0.1),
    0px -16px 16px -16px rgba(0, 0, 0, 0.1);
}

.snack-bar-wrapper {
  position: sticky;
  bottom: 0;
  z-index: var(--elevation-snackbar);
}

.overline-link {
  color: var(--color-black);
}

.formlayout-title {
  line-height: 1.1em;
  margin-bottom: var(--space-small);
}

.no-scroll {
  height: 100%;
  overflow: hidden;
}

.content-dense {
  padding-top: var(--space-small);
  padding-bottom: var(--space-small);
}
</style>
