"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnumPropertyDescription = void 0;
const json_schema_keys_1 = require("../../json_schema_keys");
const field_property_description_1 = require("./field_property_description");
class EnumPropertyDescription extends field_property_description_1.DefaultFieldPropertyDescription {
    set selectableOptions(selectableOptions) {
        this[json_schema_keys_1.JsonSchemaKeys.ENUM_KEY] = selectableOptions;
    }
    constructor(field) {
        super(field);
        this.selectableOptions = field.selectableOptions;
    }
}
exports.EnumPropertyDescription = EnumPropertyDescription;
