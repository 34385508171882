"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReferenceField = void 0;
const reference_field_property_description_1 = require("../models/field_property_descriptions/reference_field_property_description");
const form_field_definition_1 = require("./form_field_definition");
class ReferenceField extends form_field_definition_1.FormFieldDefinition {
    constructor(reference) {
        super();
        this.reference = reference;
    }
    propertyDescription() {
        return new reference_field_property_description_1.ReferenceFieldPropertyDescription(this);
    }
    uiSchema() {
        return this.reference.uiSchema();
    }
    reset() {
        this.titleOverride = null;
        this.descriptionOverride = null;
        this.defaultValueOverride = null;
    }
}
exports.ReferenceField = ReferenceField;
