"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.subjectFieldMatcher = void 0;
const subject_field_definition_1 = require("../../form_fields/subject_field_definition");
const json_schema_keys_1 = require("../../json_schema_keys");
const subjectFieldMatcher = (uniqueKey, isRequired, properties, uiSchema, fieldDefinitions, next) => {
    if (uniqueKey === json_schema_keys_1.JsonSchemaKeys.SUBJECT_FIELD_KEY) {
        const field = new subject_field_definition_1.SubjectFieldDefinition();
        field.title = properties.title;
        field.description = properties.description;
        field.defaultValue = properties.default;
        return field;
    }
    return next(uniqueKey, isRequired, properties, uiSchema, fieldDefinitions);
};
exports.subjectFieldMatcher = subjectFieldMatcher;
