<template>
  <div v-if="job.isLate">
    <v-tooltip bottom :color="lateColor">
      <template #activator="{ on }">
        <strong :class="lateTextColor" v-on="on">{{ dateValue }}</strong>
      </template>
      <span>Late</span>
    </v-tooltip>
  </div>

  <div v-else>
    {{ dateValue }}
  </div>
</template>

<script>
import { formatDateTime } from '@/utils/date-formatting'

export default {
  name: 'EndTime',
  props: {
    job: {
      type: Object,
      default: null,
    },
  },
  computed: {
    lateColor() {
      return this.job.isOverdue ? 'orange darken-2' : 'red'
    },
    lateTextColor() {
      return this.job.isOverdue ? 'orange--text' : 'red--text'
    },
    dateValue() {
      return dateFormatter(this.job.endTime)
    },
  },
}

const dateFormatter = (value) => value && formatDateTime(value)
</script>
