export const SET_ERROR = 'SET_ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const ERROR_LOADING = 'ERROR_LOADING'
export const ERROR_SAVING = 'ERROR_SAVING'

const state = () => {
  return {
    error: null,
    loadingError: null,
    savingError: null,
  }
}

const getters = {
  getError: (state) => state.error,
  getLoadingError: (state) => state.loadingError,
  getSavingError: (state) => state.savingError,
  isErrorLoading: (state) => !!state.loadingError,
  isErrorSaving: (state) => !!state.savingError,

  getErrorMessages: (state) => {
    if (!state.savingError) return null

    if (
      state.savingError &&
      state.savingError.response &&
      state.savingError.response.data
    ) {
      const data = state.savingError.response.data
      if (data.errors) return data.errors
      if (typeof data === 'string') return { base: data }
      return data
    }
    return { base: 'Something went wrong. Please try again.' }
  },

  getSavingErrorMessage: (state) => {
    if (!state.savingError) return null
    return Object.values(getters.getErrorMessages(state)).join('\n')
  },
  getLoadingErrorMessage: (_state) =>
    'Something went wrong, could not load data',
}

const mutations = {
  [SET_ERROR](state, error) {
    state.error = error
  },
  [ERROR_LOADING](state, error) {
    state.loadingError = error
  },
  [ERROR_SAVING](state, error) {
    state.savingError = error
  },
  [CLEAR_ERROR](state) {
    state.error = null
    state.loadingError = null
    state.savingError = null
  },
}

export default {
  namespaced: false,
  state,
  getters,
  mutations,
}
