export default class CostCenter {
  description = null
  divisions = []
  id = null
  label = null
  locations = []
  ownerId = null

  constructor(json) {
    if (!json) return

    this.description = json.description
    this.divisionIds = json.divisions
    this.id = json.id
    this.label = json.label
    this.locationIds = json.locations
    this.ownerId = json.owner_id
  }

  get name() {
    return this.label
  }
}
