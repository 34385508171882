<template>
  <InputWrapper
    :label="label"
    :disabled="disabled"
    :mini-label="!!value"
    :error-messages="allErrorMessages"
    :hint="hint"
  >
    <flat-pickr
      v-bind="attrs"
      :config="config"
      data-input
      @input="handleInput"
      @blur="handleBlur"
      @on-close="handleClose"
      @on-change="handleChange"
      @focus="handleFocus"
    />

    <template #append>
      <v-icon data-toggle>calendar_today</v-icon>
    </template>
  </InputWrapper>
</template>

<script>
import InputWrapper from './InputWrapper'
import { inputProps } from './VsTextInput'
import { sharedOptions } from './helper'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.min.css'
import moment from 'moment'
import { isString } from 'lodash'

export default {
  components: { InputWrapper, flatPickr },
  mixins: [sharedOptions],
  props: {
    ...inputProps,
    value: {
      type: [Object, String],
      default: null,
    },
    canSelectPast: {
      type: Boolean,
      default: false,
    },
    enableTime: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isStringValue() {
      return isString(this.value)
    },
    attrs() {
      return {
        value: this.normalizeValue,
        placeholder: this.label,
        disabled: this.disabled,
      }
    },
    normalizeValue() {
      if (this.value) {
        if (!this.isStringValue) {
          return this.value.toISOString()
        } else {
          return this.value
        }
      }
      return this.config.defaultDate
    },
    config() {
      const altFormat = this.enableTime ? 'M d, Y H:i' : 'M d, Y'
      const dateFormat = this.enableTime ? 'Y-m-dTH:i:S' : 'Y-m-d'

      return {
        defaultDate: null,
        altInput: true,
        altInputClass: 'input',
        altFormat,
        dateFormat,
        enableTime: this.enableTime,
        time_24hr: true,
        allowInput: false,
        minuteIncrement: 15,
        wrap: true,
        minDate: this.canSelectPast ? null : 'today',
      }
    },
  },
  watch: {
    rules() {
      this.runValidation()
    },
  },
  mounted() {
    if (!this.canSelectPast) {
      this.localRules.push(
        (value) =>
          moment(value).isBefore(moment()) &&
          `${this.label} cannot be in the past`
      )
    }
  },
  methods: {
    handleInput(value) {
      if (value == null) this.$emit('input', null)

      if (this.isStringValue) {
        const newValue = moment(value).toISOString()
        if (this.value && moment(this.value).isSame(newValue)) return
        this.$emit('input', newValue)
      } else {
        const newValue = moment(value)
        if (this.value && this.value.isSame(newValue)) return
        this.$emit('input', newValue)
      }
    },
    handleBlur(event) {
      return this.$emit('blur', event)
    },
    handleClose(event) {
      return this.$emit('close', event)
    },
    handleChange(event) {
      this.runValidation()
      return this.$emit('change', event)
    },
    handleFocus(event) {
      return this.$emit('focus', event)
    },
  },
}
</script>

<style scoped></style>
