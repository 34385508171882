<template>
  <div>
    <v-sheet class="px-3 primary">
      <v-col class="overflow-y-auto">
        <vs-wrapper space="small">
          <vs-heading type="overline" class="textColor-blue--lighter"
            >{{ activeFlow === '#root_schema' ? rootHeading : activeFlow }}
          </vs-heading>

          <vs-autocomplete
            v-if="builder.sourceIsCustomFieldSchema === false"
            v-model="selectedPage"
            v-click-outside="onClickOutside"
            :filter="filter"
            :items="pageTitles"
            :value="selectedPage"
            @input="handleInput"
          >
          </vs-autocomplete>
          <vs-wrapper direction="horizontal">
            <vs-button
              v-if="builder.sourceIsCustomFieldSchema === false"
              class="btn"
              label="Pages/Flows"
              icon="settings"
              full-width
              @click="$emit('edit-pages-flows')"
            />

            <add-field-button
              :is-editing-custom-fields="isEditingCustomFields"
              :subject-field-exists="subjectFieldExists"
              :subject-location-field-exists="subjectLocationFieldExists"
              @field-clicked="addField"
            ></add-field-button>
          </vs-wrapper>

          <vs-wrapper direction="horizontal">
            <!-- Custom fields and template fields have some complicated edge
            cases that we'd rather avoid for now. -->
            <add-reference-field-button
              v-if="fieldDefinitions.length > 0 && !isEditingCustomFields"
              :items="fieldDefinitions"
              @add-reference-field="addReferenceField"
            ></add-reference-field-button>
          </vs-wrapper>
        </vs-wrapper>
      </v-col>
    </v-sheet>

    <draggable
      :list="fieldsOfPage"
      :class="fieldDefinitions.length > 0 ? 'scroll-short' : 'scroll'"
      @change="reorderField"
    >
      <v-list-item
        v-for="field in fieldsOfPage"
        :key="field.uniqueKey()"
        :class="{ highlight: field === selected }"
        @click="onFieldClicked(field)"
        @contextmenu="showContextMenu($event, field)"
      >
        <v-list-item-icon>
          <v-icon>
            {{ getFieldIcon(field) }}
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title
            v-text="truncateLabel(getFieldLabel(field))"
          ></v-list-item-title>
        </v-list-item-content>

        <v-list-item-action v-if="showDependencyLinkIcon(field)">
          <v-row>
            <v-icon>link</v-icon>
            <vs-text class="pl-2">{{ numberOfDependencies(field) }}</vs-text>
          </v-row>
        </v-list-item-action>
      </v-list-item>
    </draggable>
  </div>
</template>

<script>
import VsText from '@/components/vision/VsText'
import draggable from 'vuedraggable'
import formFieldIcon from '@/utils/forms/form-field-icons'
import formFieldTitle from '@/utils/forms/form-field-title'
import AddFieldButton from '@/components/form-builder/AddFieldButton'
import VsWrapper from '@/components/vision/VsWrapper'
import VsButton from '@/components/vision/VsButton'
import VsHeading from '@/components/vision/VsHeading'
import AddReferenceFieldButton from '@/components/form-builder/AddReferenceFieldButton'
import VsAutocomplete from '@/components/vision/Inputs/VsAutocomplete/VsAutocomplete'

export default {
  name: 'FormFieldsTreeView',
  components: {
    VsAutocomplete,
    AddReferenceFieldButton,
    VsHeading,
    VsButton,
    VsWrapper,
    AddFieldButton,
    VsText,
    draggable,
  },

  props: {
    builder: {
      type: Object,
      default: () => {},
    },
    fieldsOfPage: {
      type: Array,
      default: () => [],
    },
    fieldDefinitions: {
      type: Array,
      default: () => {},
    },
    selected: {
      type: Object,
      default: () => {},
    },
    pageNumberSelected: {
      type: Number,
      required: true,
      default: 0,
    },
    activeFlow: {
      type: String,
      default: '#root_schema',
    },
    isEditingCustomFields: {
      type: Boolean,
      default: false,
    },
    subjectLocationFieldExists: {
      type: Boolean,
      default: false,
    },
    subjectFieldExists: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedPage: this.pageKey(this.pageNumberSelected, this.activeFlow),
    }
  },

  computed: {
    pageTitles() {
      this.selectedPage = this.pageKey(this.pageNumberSelected, this.activeFlow)
      return this.builder
        .getFlows()
        .map((flowKey) => {
          const titles = []
          titles.push({
            header: flowKey === '#root_schema' ? 'Form' : flowKey,
          })

          this.builder.pageTitles(flowKey).forEach((pageTitle, i) => {
            titles.push({
              value: this.pageKey(i, flowKey),
              text: this.pageTitle(i, flowKey, true),
              description: flowKey === '#root_schema' ? 'Form' : flowKey,
            })
          })
          return titles.flat()
        })
        .flat()
    },
    rootHeading() {
      return this.builder.sourceIsCustomFieldSchema ? 'Custom Fields' : 'Form'
    },
  },

  methods: {
    onClickOutside() {
      if (this.selectedPage === null) {
        this.selectedPage = this.pageKey(
          this.pageNumberSelected,
          this.activeFlow
        )
      }
    },
    numberOfDependencies(field) {
      return !field
        ? 0
        : this.builder
            .immediateConstDependenciesOfField(field)
            .length.toString()
    },
    showDependencyLinkIcon(field) {
      return this.numberOfDependencies(field) > 0
    },
    getFieldIcon(field) {
      return formFieldIcon(field)
    },
    getFieldLabel(field) {
      return formFieldTitle(field)
    },
    truncateLabel(label) {
      if (label.length > 30) {
        return label.slice(0, 30) + '...'
      }
      return label
    },
    filter(item, queryText, itemText) {
      console.log(item, queryText, itemText)
      const pageNameFound =
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      const flowNameFound =
        item.description &&
        item.description
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      return pageNameFound || flowNameFound
    },
    handleInput(pageKey) {
      if (pageKey !== null && pageKey !== undefined) {
        this.$emit('change-selected-page', {
          flowKey: this.getFlowKeyFromPageKey(pageKey),
          index: this.getPageIndexFromPageKey(pageKey),
          key: pageKey,
        })
      }
    },
    pageTitle(pageIndex, flowKey, isDisplay = false) {
      const pageTitle = this.builder.pageTitles(
        this.builder.getFlows().indexOf(flowKey) > -1
          ? flowKey
          : this.activeFlow
      )[pageIndex]
      return !pageTitle || (isDisplay && pageTitle.length === 0)
        ? `Page ${pageIndex + 1}`
        : pageTitle
    },
    pageKey(pageIndex, flowKey) {
      return `${flowKey}_${pageIndex}`
    },
    getFlowKeyFromPageKey(pageKey) {
      return pageKey.slice(0, pageKey.lastIndexOf('_'))
    },
    getPageIndexFromPageKey(pageKey) {
      return pageKey === null
        ? this.pageNumberSelected
        : parseInt(pageKey.slice(pageKey.lastIndexOf('_') + 1))
    },
    addReferenceField(field) {
      this.$emit('add-reference-field', field)
    },
    addField(field) {
      this.$emit('add-field', field)
    },
    onFieldClicked(field) {
      this.$emit('click-field', field)
    },
    showContextMenu(e, field) {
      this.$emit('right-click-field', { e, field })
    },
    reorderField(event) {
      this.$emit('reorder-field', event)
    },
  },
}
</script>

<style scoped>
.highlight {
  background: var(--color-blue--lighter);
}
.btn,
.btn:hover,
.btn:focus {
  background-color: var(--color-blue--lighter);
  --text-color: var(--color-blue);
}
.scroll {
  overflow-y: scroll !important;
  height: 70vh;
  min-height: 57vh;
  max-height: 73vh;
}
.scroll-short {
  overflow-y: scroll !important;
  height: 65vh;
  min-height: 57vh;
  max-height: 65vh;
}
</style>
