<template>
  <vs-wrapper>
    <vs-heading
      v-show="!isReferenceField && fieldComponentType"
      type="overline"
      class="textColor-grey"
      >Specific Field Settings</vs-heading
    >
    <component
      :is="fieldComponentType"
      :field="field"
      @modify-field="modifyField"
    />
  </vs-wrapper>
</template>

<script>
import SchemaBasedForm from '@/components/forms/SchemaBasedForm'
import VsHeading from '@/components/vision/VsHeading'
import RightDrawer from '@/components/common/RightDrawer'
import VsButton from '@/components/vision/VsButton'
import * as fb from 'ironsight-form-builder'
import Page from '@/components/common/Page'
import ArrayFieldSettings from '@/components/form-builder/field-settings/ArrayFieldSettings'
import GenericSelectorSettings from '@/components/form-builder/field-settings/GenericSelectorSettings'
import NumberFieldSettings from '@/components/form-builder/field-settings/NumberFieldSettings'
import CoreFieldSettings from '@/components/form-builder/field-settings/CoreFieldSettings'
import EnumStringFieldSettings from '@/components/form-builder/field-settings/EnumStringFieldSettings'
import BooleanFieldSettings from '@/components/form-builder/field-settings/BooleanFieldSettings'
import MarkdownFieldSettings from '@/components/form-builder/field-settings/MarkdownFieldSettings'
import RelationshipsEditor from '@/components/form-builder/RelationshipsEditor'
import DateTimeFieldSettings from '@/components/form-builder/field-settings/DateTimeFieldSettings'
import LookupSelectorFieldSettings from '@/components/form-builder/field-settings/LookupSelectorFieldSettings'
import TextEntryFieldSettings from '@/components/form-builder/field-settings/TextEntryFieldSettings'
import ObjectFieldSettings from '@/components/form-builder/field-settings/ObjectFieldSettings'
import FormButtonsFieldSettings from '@/components/form-builder/field-settings/FormButtonsFieldSettings'
import VsWrapper from '@/components/vision/VsWrapper'
import ReferenceFieldSettings from '@/components/form-builder/field-settings/ReferenceFieldSettings'

export default {
  name: 'SpecificFieldSettings',
  components: {
    VsWrapper,
    RelationshipsEditor,
    CoreFieldSettings,
    Page,
    VsButton,
    VsHeading,
    SchemaBasedForm,
    RightDrawer,
  },

  props: {
    field: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    isReferenceField() {
      return this.field instanceof fb.ReferenceField
    },
    fieldComponentType() {
      // Most specific field definitions to least
      if (this.field instanceof fb.ReferenceField) {
        return ReferenceFieldSettings
      }
      if (this.field instanceof fb.ObjectFieldDefinition) {
        return ObjectFieldSettings
      }
      if (
        this.field instanceof fb.SubjectLocationFieldDefinition ||
        this.field instanceof fb.SubjectFieldDefinition
      ) {
        return null
      }
      if (this.field instanceof fb.CreateableSelectorDefinition) {
        return EnumStringFieldSettings
      }
      if (this.field instanceof fb.DateFieldDefinition) {
        return DateTimeFieldSettings
      }
      if (this.field instanceof fb.LookupFieldDefinition) {
        return LookupSelectorFieldSettings
      }
      if (this.field instanceof fb.GenericSelectorDefinition) {
        return GenericSelectorSettings
      }
      if (this.field instanceof fb.NumberFieldDefinition) {
        return NumberFieldSettings
      }
      if (this.field instanceof fb.EnumStringFieldDefinition) {
        return EnumStringFieldSettings
      }
      if (this.field instanceof fb.FormButtonsDefinition) {
        return FormButtonsFieldSettings
      }
      if (this.field instanceof fb.BooleanFieldDefinition) {
        return BooleanFieldSettings
      }
      if (this.field instanceof fb.MarkdownFieldDefinition) {
        return MarkdownFieldSettings
      }
      if (this.field instanceof fb.ArrayFieldDefinition) {
        return ArrayFieldSettings
      }
      if (this.field instanceof fb.TextEntryFieldDefinition) {
        return TextEntryFieldSettings
      }
      return null
    },
  },

  methods: {
    modifyField(props) {
      this.$emit('modify-field', props)
    },

    removeField(field) {
      this.$emit('remove-field', field)
    },
  },
}
</script>
