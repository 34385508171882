<template>
  <vs-wrapper>
    <v-radio-group
      :key="title"
      data-test="radio-field"
      class="mt-0"
      :label="title"
      :error-messages="errorMessages"
    >
      <div v-if="description" class="hint radio-hint">
        <vs-text type="subdued">{{ description }}</vs-text>
      </div>
      <v-checkbox
        v-for="option in options"
        :key="option.value"
        :input-value="formData"
        class="mb-0 mt-2 form-field-checkbox"
        off-icon="$radioOff"
        on-icon="$radioOn"
        :label="option.label"
        hide-details
        :true-value="option.value"
        :false-value="null"
        :disabled="disabled"
        @change="change"
      />
    </v-radio-group>
  </vs-wrapper>
</template>

<script>
export default {
  name: 'RadioField',
  props: {
    errorSchema: Object,
    formData: [Boolean, String],
    params: Object,
    rootSchema: Object,
    schema: Object,
    title: String,
    description: String,
    uiSchema: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    options() {
      let options = []

      if (this.schema.type === 'boolean') {
        options.push({ label: 'Yes', value: true })
        options.push({ label: 'No', value: false })
      } else if (this.schema.enum) {
        options = this.schema.enum.map((choice) => {
          return { label: choice, value: choice }
        })
      }

      return options
    },
    errorMessages() {
      const errors = this.errorSchema?.errors ?? []
      if (
        this.formData &&
        typeof this.formData !== 'string' &&
        typeof this.formData !== 'boolean'
      ) {
        errors.push('Field value expected.')
      }
      return errors
    },
  },
  methods: {
    change(value) {
      this.$emit('change', value)
    },
  },
}
</script>

<style scoped>
.v-input--radio-group {
  margin-top: -8px;
}

::v-deep .form-field-checkbox .v-label {
  font-size: 14px;
}

.radio-hint {
  margin-top: -8px;
}
</style>
