import { getCurrentUserContext } from '@/api/users'
import { HUB, ORGANIZATION } from '@/models/business-unit'
import CurrentUserContext from '@/models/current-user-context'
import { getAccountEntitlements } from '@/api/accounts'
import { getInstance } from '@/auth'

// It may seem like auth.js has the same consts, but their string values are
// slightly different.
export const ADMIN_ROLE = 'Admin'
export const COORDINATOR_ROLE = 'Coordinator'
export const REQUESTER_ROLE = 'Requester'
export const SUPER_REQUESTER = 'SuperRequester'
export const FIELD_WORKER_ROLE = 'FieldWorker'
export const MEMBER_ROLE = 'Member'
export const FORM_REVIEWER_ROLE = 'FormReviewer'
export const ANALYTICS_VIEWER_ROLE = 'AnalyticsViewer'
export const WORKFLOW_MANAGER_ROLE = 'WorkflowManager'

const namespaced = true

const state = {
  context: {
    lineage: [],
    roleAssignments: [],
    userSid: '',
    userDetails: {},
    orgManagerId: null,
  },
  error: null,
  loading: true,
  loaded: false,
  accountEntitlementsError: null,
  accountEntitlementsLoading: true,
  accountEntitlementsLoaded: false,
  invoicingEntitlement: false,
  manageNetworkEntitlement: false,
}

export const getters = {
  getContext: (state) => state.context,
  getLoading: (state) => state.loading,
  getOrganizationId: (state) =>
    state.context.lineage.find((bu) => bu.type === ORGANIZATION).id,
  getAccessibleHubIds: (state) =>
    state.context.lineage
      .filter((bu) => bu.type === HUB)
      .map((bu) => bu.id)
      .concat(state.context.lineage.map((bu) => bu.hostIds).flat()),
  getPartnerIds: (state) =>
    state.context.lineage
      ? state.context.lineage.map((bu) => bu.partnerIds).flat()
      : [],
  getPartnerIdsForBusinessUnit: (state) => (businessUnitId) =>
    state.context.lineage
      .filter((bu) => bu.id === businessUnitId)
      .map((bu) => bu.partnerIds)
      .flat() || [],
  getHostIds: (state) =>
    state.context.lineage
      ? state.context.lineage.map((bu) => bu.hostIds).flat()
      : [],
  getRoleAssignmentsForBusinessUnit: (state) => (businessUnitId) => {
    const scopes = state.context.lineage
      .map((bu) => bu.id)
      .concat(state.context.lineage.map((bu) => bu.partnerIds).flat())
      .concat(state.context.lineage.map((bu) => bu.hostIds).flat())

    if (!scopes.includes(businessUnitId)) return []

    return state.context.roleAssignments
  },
  getRoleAssignmentBusinessUnitIds: (state) =>
    state.context.roleAssignments.map((ra) => ra.businessUnitId),
  getRolesForBusinessUnit: (_state, getters) => (businessUnitId) =>
    getters
      .getRoleAssignmentsForBusinessUnit(businessUnitId)
      .map((ra) => ra.role),
  getAllRoles: (state) => state.context.roleAssignments.map((ra) => ra.role),
  isHostOrganizationUser: (state) =>
    state.context.lineage?.some((bu) => bu.type === HUB),
  isOrgAdmin: (state, getters) => {
    return state.context.roleAssignments.some(
      (ra) =>
        ra.role === ADMIN_ROLE &&
        ra.businessUnitId === getters.getOrganizationId
    )
  },
  isAdmin: (_state, getters) => {
    const roles = getters.getAllRoles
    return roles.includes(ADMIN_ROLE)
  },
  isGlobalAdmin: (_state, _getters) => {
    const auth0 = getInstance()
    return auth0.isGlobalAdmin
  },

  // Complex job permissions
  canArchiveJob: (_state, getters) => (hubId, accountId) => {
    const roles = getters.getRolesForBusinessUnit(hubId)

    return getters.getOrganizationId === accountId && roles.includes(ADMIN_ROLE)
  },
  canClaimJob: (state) => (hubId) => {
    const connectedBusinessUnits = state.context.lineage
      .filter((l) => l.hostIds.includes(hubId))
      .map((l) => l.id)

    return state.context.roleAssignments
      .filter((ra) => connectedBusinessUnits.includes(ra.businessUnitId))
      .map((ra) => ra.role)
      .some((role) => role === ADMIN_ROLE || role === COORDINATOR_ROLE)
  },
  canSubmitForApproval: (state) => (serviceProviderId) => {
    const roles = state.context.roleAssignments.filter(
      (roleAssignment) => roleAssignment.businessUnitId === serviceProviderId
    )

    return (
      roles.some((roleAssignment) => roleAssignment.role === ADMIN_ROLE) ||
      roles.some((roleAssignment) => roleAssignment.role === COORDINATOR_ROLE)
    )
  },
  canApproveJob: (_state, getters) => (hubId, accountId) => {
    const roles = getters.getRolesForBusinessUnit(hubId)

    if (getters.isHostOrganizationUser) {
      return (
        roles.includes(ADMIN_ROLE) ||
        roles.includes(COORDINATOR_ROLE) ||
        roles.includes(REQUESTER_ROLE)
      )
    } else {
      return (
        getters.getOrganizationId === accountId &&
        (roles.includes(ADMIN_ROLE) || roles.includes(COORDINATOR_ROLE))
      )
    }
  },
  canUnapproveJob: (_state, getters) => (hubId, approvedBy, accountId) => {
    const roles = getters.getRolesForBusinessUnit(hubId)
    const userIsApprover = getters.getContext.userSid === approvedBy

    if (getters.isHostOrganizationUser) {
      return roles.includes(ADMIN_ROLE) || userIsApprover
    } else {
      return (
        getters.getOrganizationId === accountId &&
        (roles.includes(ADMIN_ROLE) ||
          roles.includes(COORDINATOR_ROLE) ||
          userIsApprover)
      )
    }
  },
  canReopenJob: (_state, getters) => {
    return getters.isGlobalAdmin
  },
  canModifyCompletedJobActualTimes: (_state, getters) => (hubId, accountId) => {
    const roles = getters.getRolesForBusinessUnit(hubId)

    return getters.getOrganizationId === accountId && roles.includes(ADMIN_ROLE)
  },
  canModifyCompletedJobsPendingApproval: (_state, getters) => (hubId) => {
    const roles = getters.getRolesForBusinessUnit(hubId)

    return (
      roles.includes(ADMIN_ROLE) ||
      ((roles.includes(COORDINATOR_ROLE) || roles.includes(REQUESTER_ROLE)) &&
        getters.isHostOrganizationUser)
    )
  },
  canModifyCompletedJobsWithNoApproval:
    (_state, getters, _rootState, rootGetters) => (hubId) => {
      const featureEnabled =
        rootGetters['featureFlags/submitJobForApprovalEnabled']
      const roles = getters.getRolesForBusinessUnit(hubId)

      if (!featureEnabled) {
        return roles.includes(ADMIN_ROLE)
      }

      // Same as pending approval when feature is enabled and job is completed
      return (
        roles.includes(ADMIN_ROLE) ||
        ((roles.includes(COORDINATOR_ROLE) || roles.includes(REQUESTER_ROLE)) &&
          getters.isHostOrganizationUser)
      )
    },

  // Simple role based job permissions
  canSetJobState: (_state, getters) => (hubId) => {
    const roles = getters.getRolesForBusinessUnit(hubId)

    return roles.includes(ADMIN_ROLE) || roles.includes(COORDINATOR_ROLE)
  },
  canCancelJob:
    (_state, getters) => (hubId, userIsRequesterOnUnassignedJob) => {
      return getters.canSetJobState(hubId) || userIsRequesterOnUnassignedJob
    },
  canModifyScheduledOrInProgressJob: (_state, getters) => (hubId) => {
    const roles = getters.getRolesForBusinessUnit(hubId)

    return roles.includes(ADMIN_ROLE) || roles.includes(COORDINATOR_ROLE)
  },
  canAssignPoolToJob: (state) => (hubId) => {
    const roles = state.context.roleAssignments.filter(
      (roleAssignment) => roleAssignment.businessUnitId === hubId
    )

    return (
      roles.some((roleAssignment) => roleAssignment.role === ADMIN_ROLE) ||
      roles.some((roleAssignment) => roleAssignment.role === COORDINATOR_ROLE)
    )
  },
  canAssignServiceProviderToJob: (state) => (hubId) => {
    const roles = state.context.roleAssignments
      .filter((roleAssignment) => roleAssignment.businessUnitId === hubId)
      .map((ra) => ra.role)

    return (
      roles.some((role) => role === ADMIN_ROLE) ||
      roles.some((role) => role === COORDINATOR_ROLE) ||
      roles.some((role) => role === SUPER_REQUESTER)
    )
  },
  canAssignResourceToJob: (_state, getters) => (hubId) => {
    const roles = getters.getRolesForBusinessUnit(hubId)

    return roles.includes(ADMIN_ROLE) || roles.includes(COORDINATOR_ROLE)
  },
  canModifyRequester: (_state, getters) => {
    const roles = getters.getAllRoles

    return roles.includes(ADMIN_ROLE) || roles.includes(COORDINATOR_ROLE)
  },
  canScheduleJobsInPast: (_state, getters) => {
    const roles = getters.getAllRoles

    return roles.includes(ADMIN_ROLE) || roles.includes(COORDINATOR_ROLE)
  },
  canModifyPriority: (_state, getters) => {
    const roles = getters.getAllRoles

    return roles.includes(ADMIN_ROLE) || roles.includes(COORDINATOR_ROLE)
  },
  canDeleteAttachments: (_state, getters) => {
    const roles = getters.getAllRoles

    return roles.includes(ADMIN_ROLE) || roles.includes(COORDINATOR_ROLE)
  },
  canBulkUpdateJobs: (_state, getters) => {
    const roles = getters.getAllRoles

    return roles.includes(ADMIN_ROLE) || roles.includes(COORDINATOR_ROLE)
  },

  // Invoice related permissions
  getInvoicingEntitlement: (state) => state.invoicingEntitlement,
  canViewBatchColumnInJobsList: (state, getters, _, rootGetters) => {
    return (
      rootGetters['featureFlags/invoices'] && getters.getInvoicingEntitlement
    )
  },
  canViewBatch: (state, getters, _, rootGetters) => (batchOwnerId) => {
    const invoiceFeatureEnabled = rootGetters['featureFlags/invoices']
    return (
      invoiceFeatureEnabled &&
      getters.getInvoicingEntitlement &&
      getters.getPartnerIds
        .concat([getters.getOrganizationId])
        .includes(batchOwnerId)
    )
  },
  canViewAddToBatchSelectionPage: (state, getters, _, rootGetters) => {
    const invoiceFeatureEnabled = rootGetters['featureFlags/invoices']
    const currentOrgId = getters.getOrganizationId
    const roles = state.context.roleAssignments.filter(
      (roleAssignment) => roleAssignment.businessUnitId === currentOrgId
    )
    const accessibleRoles =
      roles.some((roleAssignment) => roleAssignment.role === ADMIN_ROLE) ||
      roles.some((roleAssignment) => roleAssignment.role === COORDINATOR_ROLE)
    return (
      invoiceFeatureEnabled &&
      getters.getInvoicingEntitlement &&
      accessibleRoles
    )
  },
  canEditBatch: (state, getters, _, rootGetters) => (batchOwnerId) => {
    const invoiceFeatureEnabled = rootGetters['featureFlags/invoices']
    const roles = state.context.roleAssignments.filter(
      (roleAssignment) => roleAssignment.businessUnitId === batchOwnerId
    )
    const accessibleRoles =
      roles.some((roleAssignment) => roleAssignment.role === ADMIN_ROLE) ||
      roles.some((roleAssignment) => roleAssignment.role === COORDINATOR_ROLE)
    return (
      invoiceFeatureEnabled &&
      getters.getInvoicingEntitlement &&
      batchOwnerId === getters.getOrganizationId &&
      accessibleRoles
    )
  },

  // Creating and updating data access policies
  canPartiallyModifyResource: (_state, getters) => {
    const roles = getters.getAllRoles

    return roles.includes(ADMIN_ROLE) || roles.includes(COORDINATOR_ROLE)
  },
  canFullyModifyResource: (_state, getters) => {
    const roles = getters.getAllRoles

    return roles.includes(ADMIN_ROLE)
  },
  canViewLocationInventory:
    (_state, getters, _rootState, rootGetters) => (locationOrgId) => {
      const managedOrganizations = rootGetters[
        'businessUnits/getManagedOrganizations'
      ](getters.getOrganizationId)

      const managedOrgIds = managedOrganizations.map((org) => org.id)
      return managedOrgIds.includes(locationOrgId)
    },
  canEditLocationDetails:
    (_state, getters, _rootState, rootGetters) => (locationOrgId) => {
      const roles = getters.getAllRoles

      const managedOrganizations = rootGetters[
        'businessUnits/getManagedOrganizations'
      ](getters.getOrganizationId)

      const managedOrgIds = managedOrganizations.map((org) => org.id)

      return (
        managedOrgIds.includes(locationOrgId) &&
        (roles.includes(ADMIN_ROLE) || roles.includes(COORDINATOR_ROLE))
      )
    },
  canFullyEditLocation:
    (_state, getters, _rootState, rootGetters) => (locationOrgId) => {
      const roles = getters.getAllRoles

      const managedOrganizations = rootGetters[
        'businessUnits/getManagedOrganizations'
      ](getters.getOrganizationId)

      const managedOrgIds = managedOrganizations.map((org) => org.id)

      return managedOrgIds.includes(locationOrgId) && roles.includes(ADMIN_ROLE)
    },
  canCreateClient: (state, getters) => {
    return getters.isOrgAdmin && state.manageNetworkEntitlement
  },
  hasAccountOrganizationAccess: (_state, getters) => {
    const roles = getters.getAllRoles

    return roles.includes(ADMIN_ROLE)
  },

  // Misc access policies
  canAssignForm: (_state, getters) => {
    const roles = getters.getAllRoles

    return roles.includes(ADMIN_ROLE) || roles.includes(FORM_REVIEWER_ROLE)
  },
  canDeleteFormSubmission: (_state, getters) => {
    // Must also check that the forms onwer id is the same as the users org id
    const roles = getters.getAllRoles

    return roles.includes(ADMIN_ROLE)
  },
  canCompleteActionWithoutBeingAssigned: (_state, getters) => {
    const roles = getters.getAllRoles

    return roles.includes(ADMIN_ROLE) || roles.includes(FORM_REVIEWER_ROLE)
  },
  canUseFormSubmissionSubjectFilter: (_state, getters) => {
    const roles = getters.getAllRoles

    return roles.includes(ADMIN_ROLE) || roles.includes(FORM_REVIEWER_ROLE)
  },
  canSaveCustomFieldsSchema: (_, getters) => {
    return getters.getAllRoles.includes(WORKFLOW_MANAGER_ROLE)
  },
  hasChatAccess: (_state, getters) => {
    const roles = getters.getAllRoles

    return roles.includes(ADMIN_ROLE) || roles.includes(COORDINATOR_ROLE)
  },
  hasNotificationAccess: (_state, getters) => {
    const roles = getters.getAllRoles

    return (
      roles.includes(ADMIN_ROLE) ||
      roles.includes(COORDINATOR_ROLE) ||
      roles.includes(REQUESTER_ROLE) ||
      roles.includes(MEMBER_ROLE) ||
      roles.includes(FORM_REVIEWER_ROLE)
    )
  },
  hasPushDataAccess: (_state, getters) => {
    const roles = getters.getAllRoles

    return (
      roles.includes(ADMIN_ROLE) ||
      roles.includes(COORDINATOR_ROLE) ||
      roles.includes(REQUESTER_ROLE)
    )
  },
  canViewAnalyticsDrillDowns: (_state, getters) => {
    const roles = getters.getAllRoles

    return roles.includes(ANALYTICS_VIEWER_ROLE)
  },
  isAdminFor: (state) => (businessUnitId) => {
    return state.context.roleAssignments.some(
      (ra) => ra.role === ADMIN_ROLE && ra.businessUnitId === businessUnitId
    )
  },
  isCoordinatorFor: (state) => (businessUnitId) => {
    return state.context.roleAssignments.some(
      (ra) =>
        ra.role === COORDINATOR_ROLE && ra.businessUnitId === businessUnitId
    )
  },
  isRequesterFor: (state) => (businessUnitId) => {
    return state.context.roleAssignments.some(
      (ra) => ra.role === REQUESTER_ROLE && ra.businessUnitId === businessUnitId
    )
  },
  rolesForBusinessUnit: (state) => (businessUnitId) => {
    return (state.context.roleAssignments ?? []).filter(
      (ra) => ra.businessUnitId === businessUnitId
    )
  },
  hasLineItemRateAccess: (state, getters) => (serviceProviderId, hubId) => {
    const userIsServiceProviderForJob =
      serviceProviderId !== null &&
      getters.rolesForBusinessUnit(serviceProviderId).length > 0

    if (userIsServiceProviderForJob) {
      // Check if user is admin or coordinator for SP
      return (
        getters.isAdminFor(serviceProviderId) ||
        getters.isCoordinatorFor(serviceProviderId)
      )
    }
    const hubIds = state.context.lineage
      .filter((l) => l.type === HUB)
      .map((l) => l.id)
    // Check if user is energy producer for job
    return hubIds?.includes(hubId) ?? false
  },
  hasLineItemEditApprovalAccess: (_state, getters) => {
    const roles = getters.getAllRoles
    return roles.includes(ADMIN_ROLE) || roles.includes(COORDINATOR_ROLE)
  },
}

export const actions = {
  async fetchCurrentUserContext({ commit }) {
    commit('LOADING_CONTEXT')

    try {
      const res = await getCurrentUserContext()
      commit('RECEIVE_CONTEXT', res.data)
    } catch (err) {
      commit('ERROR_CONTEXT', err)
    }
  },
  async fetchAccountEntitlements({ commit }) {
    commit('LOADING_ACCOUNT_ENTITLEMENTS')

    try {
      const res = await getAccountEntitlements()
      commit('RECEIVE_ACCOUNT_ENTITLEMENTS', res)
    } catch (err) {
      commit('ERROR_ACCOUNT_ENTITLEMENTS', err)
    }
  },
}

export const mutations = {
  LOADING_CONTEXT(state) {
    state.loading = true
  },
  RECEIVE_CONTEXT(state, data) {
    state.context = new CurrentUserContext(data)
    state.loading = false
    state.loaded = true
  },
  ERROR_CONTEXT(state, err) {
    state.error = err
    state.loading = false
  },
  LOADING_ACCOUNT_ENTITLEMENTS(state) {
    state.accountEntitlementsLoading = true
  },
  ERROR_ACCOUNT_ENTITLEMENTS(state, err) {
    state.accountEntitlementsError = err
    state.accountEntitlementsLoading = false
  },
  RECEIVE_ACCOUNT_ENTITLEMENTS(state, data) {
    state.invoicingEntitlement =
      data?.entitlements.includes('Invoicing') ?? false
    state.manageNetworkEntitlement =
      data?.entitlements.includes('ManageNetwork') ?? false
    state.accountEntitlementsLoading = false
    state.accountEntitlementsLoaded = true
  },
}

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
