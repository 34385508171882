<template>
  <vs-wrapper space="small">
    <v-row
      align="center"
      no-gutters
      data-test="filteraside-clickable"
      @click="handleExpandToggle"
    >
      <v-col>
        <vs-text type="caption">{{ title }}</vs-text>
      </v-col>

      <v-col class="shrink">
        <select-all-toggle
          :total-item-count="selectableItems?.length"
          :selected-item-count="selected?.length"
          :skip-empty-filter="skipEmptyFilter"
          :empty-is-all="emptyIsAll"
          @toggle="handleShowToggle"
        />
      </v-col>

      <v-col class="shrink">
        <v-btn class="ma-0" small icon @click.stop="handleExpandToggle">
          <v-icon
            data-test="filteraside-expand-toggle"
            small
            :class="['expand-icon', { 'is-expanded': isExpanded }]"
            >expand_more
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <div v-if="showExpansionRegion" data-test="filteraside-items">
      <template v-for="(item, i) in items">
        <vs-text
          v-if="item.header"
          :key="item.header"
          type="overline"
          class="pb-1 pt-2"
          >{{ item.header }}
        </vs-text>
        <v-checkbox
          v-if="item.name"
          :key="`${item.name} ${item[itemValue]}`"
          v-model="selectedItems"
          :value="item[itemValue]"
          color="var(--color-blue)"
          hide-details
          dense
          class="pa-0 ma-0"
        >
          <template #label>
            <vs-text type="caption">{{ item.name }}</vs-text>
          </template>
        </v-checkbox>

        <v-divider v-if="item.divider" :key="i" class="my-1" />
      </template>
    </div>
  </vs-wrapper>
</template>

<script>
import SelectAllToggle from '@/components/common/SelectAllToggle'
import VsWrapper from '@/components/vision/VsWrapper.vue'
import VsText from '@/components/vision/VsText.vue'

export default {
  components: { SelectAllToggle, VsWrapper, VsText },
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    items: {
      type: Array[Object],
    },
    itemValue: {
      type: String,
      default: 'id',
    },
    selected: {
      type: Array[Number],
      default: [],
      required: true,
    },
    noExpand: {
      type: Boolean,
      default: false,
    },
    skipEmptyFilter: {
      type: Boolean,
      default: false,
    },
    emptyIsAll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { isExpanded: false }
  },
  computed: {
    selectedItems: {
      get() {
        return this.selected
          ? this.selected.filter((item) => this.selectableItems?.includes(item))
          : []
      },
      set(newValue) {
        this.$emit('changed', newValue)
      },
    },
    selectableItems() {
      return this.items
        ? this.items
            .filter((item) => !('header' in item || 'divider' in item))
            .map((item) => item[this.itemValue])
        : null
    },
    showExpansionRegion() {
      return this.isExpanded && !this.noExpand
    },
  },

  methods: {
    handleShowToggle(isAllSelected) {
      let newValue = []
      if (!isAllSelected) {
        newValue = this.selectableItems
      }
      this.$emit('changed', newValue)
    },
    handleExpandToggle() {
      this.isExpanded = !this.isExpanded
      if (this.isExpanded) {
        this.$emit('on-section-expand')
      }
    },
  },
}
</script>

<style scoped>
.expand-toggle {
  transition: all 200ms;
}

.is-expanded {
  transform: rotate(180deg);
}
</style>
