"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageUiSchema = void 0;
const json_schema_keys_1 = require("../json_schema_keys");
const schema_object_1 = require("./schema_object");
class PageUiSchema extends schema_object_1.SchemaObject {
    constructor(uiOrder) {
        super();
        this.uiOrder = uiOrder;
    }
    get uiOrder() {
        return this[json_schema_keys_1.JsonSchemaKeys.UI_ORDER_KEY];
    }
    set uiOrder(uiOrder) {
        this[json_schema_keys_1.JsonSchemaKeys.UI_ORDER_KEY] = uiOrder;
    }
    indexOfField(field) {
        return this.uiOrder.indexOf(field.uniqueKey());
    }
    addFields(fields) {
        for (const field of fields) {
            if (field.hasUiSchema) {
                this[field.uniqueKey()] = field.uiSchema();
            }
        }
    }
}
exports.PageUiSchema = PageUiSchema;
