<template>
  <transition
    :name="showHeader ? 'slide-fade-up' : 'slide-fade-down'"
    mode="out-in"
  >
    <div
      v-if="showHeader || disabled"
      key="header"
      :class="{ header: true, disabled }"
      data-test="header"
      @click="handleHeaderClick"
    >
      <v-tooltip tag="div" top :disabled="disabled" open-delay="0">
        <template #activator="{ on }">
          <v-row no-gutters align="center" v-on="on">
            <v-col>
              <vs-text type="overline">
                <b>{{ getResourceTypeName }}</b>
              </vs-text>
              <vs-heading>{{ getActivityName }}</vs-heading>
            </v-col>
            <v-col v-if="shouldShowStatus && taskStatus" class="shrink">
              <task-status-chip
                :status="taskStatus"
                :active-duration="activeDuration"
              />
            </v-col>
          </v-row>
        </template>

        Click to edit resource type and activity
      </v-tooltip>
    </div>
    <div v-else key="field" v-click-outside="toggleHeader">
      <vs-wrapper direction="horizontal" space="small">
        <vs-autocomplete
          id="resource-type-picker"
          label="Resource type"
          :value="resourceTypeValue"
          :items="resourceTypeItems"
          item-text="name"
          item-value="id"
          :disabled="disabled"
          required
          @input="handleUpdateResourceType"
        />

        <vs-autocomplete
          id="activity-picker"
          label="Activity"
          :value="activityValue"
          :items="activityItems"
          item-text="taskName"
          item-value="id"
          :disabled="disabled || !resourceTypeValue"
          required
          @input="handleUpdateActivity"
        />
      </vs-wrapper>
    </div>
  </transition>
</template>

<script>
import TaskStatusChip from '@/components/tickets/TaskStatusChip'

export default {
  components: { TaskStatusChip },
  props: {
    resourceTypeValue: {
      type: Number,
      default: null,
    },
    resourceTypeItems: {
      type: Array[Object],
      default: null,
    },
    activityValue: {
      type: Number,
      default: null,
    },
    activityItems: {
      type: Array[Object],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    taskStatus: {
      type: String,
      default: null,
    },
    activeDuration: {
      type: Object,
      default: null,
    },
    shouldShowStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      clicked: false,
    }
  },
  computed: {
    getResourceTypeName() {
      const resourceType = this.resourceTypeItems.find(
        (resource) => resource.id === this.resourceTypeValue
      )
      return resourceType && resourceType.name
    },
    getActivityName() {
      const activity = this.activityItems.find(
        (activity) => activity.id === this.activityValue
      )
      return activity && activity.taskName
    },
    showHeader() {
      return !!this.resourceTypeValue && !!this.activityValue && !this.clicked
    },
  },
  methods: {
    handleUpdateResourceType(value) {
      this.$emit('update-resource', value)
    },
    handleUpdateActivity(value) {
      this.$emit('update-activity', value)
    },
    handleHeaderClick() {
      !this.disabled && (this.clicked = true)
    },
    toggleHeader() {
      if (!this.activityValue) return
      this.clicked = false
    },
  },
}
</script>

<style scoped>
.header {
  cursor: pointer;
}

.disabled {
  pointer-events: none;
}

.slide-fade-up-enter-active,
.slide-fade-up-leave-active,
.slide-fade-down-enter-active,
.slide-fade-down-leave-active {
  transform: translateY(0);
  transition: all 100ms;
  will-change: transform;
}

.slide-fade-up-enter,
.slide-fade-up-leave-to,
.slide-fade-down-enter,
.slide-fade-down-leave-to {
  opacity: 0;
}

.slide-fade-up-enter {
  transform: translateY(var(--space-small));
}

.slide-fade-up-leave-to {
  transform: translateY(calc(var(--space-small) * -1));
}

.slide-fade-down-enter {
  transform: translateY(calc(var(--space-small) * -1));
}

.slide-fade-down-leave-to {
  transform: translateY(var(--space-small));
}
</style>
