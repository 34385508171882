<template>
  <vs-wrapper space="small">
    <vs-heading type="subhead">Form Buttons</vs-heading>
    <v-row v-for="(item, index) in field.buttonLabels" align="center">
      <v-col class="mr-n4">
        <vs-text-input
          label="Button label"
          :value="item"
          @input="(newText) => updateLabelText(newText, index)"
      /></v-col>
      <v-col cols="auto">
        <v-icon color="red lighten-2" @click="removeFormButton(index)">
          delete
        </v-icon>
      </v-col>
    </v-row>
    <div class="mr-9">
      <vs-button
        class="pa-3 mr-9"
        label="Add Form Button"
        type="secondary"
        full-width
        @click="addFormButton"
      />
    </div>
  </vs-wrapper>
</template>

<script>
import VsWrapper from '@/components/vision/VsWrapper'
import VsTextInput from '@/components/vision/Inputs/VsTextInput'
import VsButton from '@/components/vision/VsButton'
import VsHeading from '@/components/vision/VsHeading'

export default {
  name: 'FormButtonFieldSettings',
  components: { VsHeading, VsButton, VsTextInput, VsWrapper },

  props: {
    field: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    addFormButton() {
      const updatedLabels = [...this.field.buttonLabels, '']
      this.emitChanges('buttonLabels', updatedLabels)
    },
    removeFormButton(index) {
      const updatedLabels = [...this.field.buttonLabels]
      updatedLabels.splice(index, 1)
      this.emitChanges('buttonLabels', updatedLabels)
    },
    updateLabelText(text, index) {
      const updatedLabels = [...this.field.buttonLabels]
      updatedLabels[index] = text
      this.emitChanges('buttonLabels', updatedLabels)
    },
    emitChanges(propertyKey, value) {
      this.$emit('modify-field', {
        propertyKey,
        fieldValue: value,
        key: this.field.uniqueKey(),
      })
    },
  },
}
</script>

<style scoped></style>
