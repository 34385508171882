import * as icons from './icons'
import { camelCase } from 'lodash'
import { StyleImageInterface } from './StyleImageInterface'

function calculateIconColor(fill: string) {
  const hex = fill.replace('#', '')
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  const a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255

  return a < 0.47 ? '#000000' : '#FFFFFF'
}

export default function PartnerLocationPin(
  name: string,
  { scale = 1, pixelRatio = 4 } = {}
): StyleImageInterface {
  const pinDetails = name.split('__')
  const fill = pinDetails[1]
  const icon = pinDetails[2]
  const width = 24 * pixelRatio * scale
  const height = 25 * pixelRatio * scale
  const strokeWidth = 2.5
  const strokeColor = '#fff'
  const pinPath =
    'M90 42.7C88.7 18.9 69.1 0 45 0S1.3 18.9 0 42.7V45c0 20.7 15.6 35.2 34.4 43.4L45 92.5l10.6-4.1C74.4 80.2 90 65.7 90 45v-2.3z'

  return {
    width,
    height,
    data: new Uint8Array(width * height * pixelRatio),

    async onAdd(map) {
      this.map = map
      const canvas = document.createElement('canvas')
      canvas.width = this.width
      canvas.height = this.height
      const context = canvas.getContext('2d')!

      const iconPath = icons[camelCase(icon)]

      context.translate(3, 3)

      context.strokeStyle = strokeColor
      context.lineWidth = strokeWidth * pixelRatio
      context.fillStyle = fill

      const pinPath2D = new Path2D(pinPath)
      const iconPath2D = new Path2D(iconPath)

      context.scale(scale, scale)
      context.stroke(pinPath2D)
      context.fill(pinPath2D)

      context.scale(3, 3)
      context.translate(3, 2)
      context.fillStyle = calculateIconColor(fill)
      context.fill(iconPath2D)

      this.data = context.getImageData(0, 0, this.width, this.height).data
    },

    render() {
      if (!this.initialized) {
        this.initialized = true

        return true
      }

      return false
    },
  }
}
