"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.referenceFieldMatcher = void 0;
const form_field_definition_1 = require("../../form_fields/form_field_definition");
const reference_field_1 = require("../../form_fields/reference_field");
const json_schema_keys_1 = require("../../json_schema_keys");
const referenceFieldMatcher = (uniqueKey, isRequired, properties, uiSchema, fieldDefinitions, next) => {
    const referenceString = properties[json_schema_keys_1.JsonSchemaKeys.REF_KEY];
    if (referenceString !== null && referenceString !== undefined) {
        const refKey = referenceString.split("/")[2];
        const reference = fieldDefinitions.find((def) => def.uniqueKey() === refKey);
        if (reference instanceof form_field_definition_1.FormFieldDefinition) {
            const keys = Object.keys(properties);
            const allowedKeys = [
                json_schema_keys_1.JsonSchemaKeys.REF_KEY,
                json_schema_keys_1.JsonSchemaKeys.TITLE_KEY,
                json_schema_keys_1.JsonSchemaKeys.DESCRIPTION_KEY,
                json_schema_keys_1.JsonSchemaKeys.DEFAULT_VALUE_KEY,
            ];
            if (!keys.every((k) => allowedKeys.includes(k))) {
                throw new Error(`Only the keys [${allowedKeys}] can be overriden in a reference field, but [${keys}] were provided`);
            }
            const field = new reference_field_1.ReferenceField(reference);
            if (properties.title !== null && properties.title !== undefined) {
                field.titleOverride = properties.title;
            }
            if (properties.description !== null && properties.description !== undefined) {
                field.descriptionOverride = properties.description;
            }
            if (properties.default !== null && properties.default !== undefined) {
                field.defaultValueOverride = properties.default;
            }
            return field;
        }
        else {
            throw Error(`Field ${uniqueKey} has a reference to ${refKey}, which was not found`);
        }
    }
    return next(uniqueKey, isRequired, properties, uiSchema, fieldDefinitions);
};
exports.referenceFieldMatcher = referenceFieldMatcher;
