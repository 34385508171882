import { Hub, Organization } from '@/models/business-unit'
import { injectWithSelf } from '@/utils/injectWithSelf'
import { computed, InjectionKey, provide, ref, Ref, unref } from 'vue'
import { useStore } from 'vuex-composition-helpers'

type HubContextType = {
  hubId: Ref<string | null | undefined>
}
const HubContextKey: InjectionKey<HubContextType> = Symbol('HubContext')

type MaybeRef<T> = Ref<T> | T

export const useHubContextProvider = (
  hubId: MaybeRef<string | null | undefined>
) => {
  const _hubId = computed(() => unref(hubId))
  provide(HubContextKey, { hubId: _hubId })
}

export const useHubs = () => {
  const { hubId: injectedHubId } = injectWithSelf(HubContextKey, () => ({
    hubId: ref(''),
  }))

  const store = useStore()
  const getBusinessUnitById = store.getters['businessUnits/getBusinessUnitById']

  const accessibleHubs = computed<Hub[]>(() => {
    const hubIds = store.getters['permissions/getAccessibleHubIds']
    return hubIds.map((id) => getBusinessUnitById(id))
  })

  const isMultiHub = computed(() => accessibleHubs.value.length > 1)

  const currentHub = computed<Hub | null>(() => {
    if (injectedHubId.value) {
      return getBusinessUnitById(injectedHubId.value) || null
    }
    return null
  })

  const currentHubOwner = computed<Organization | null>(() => {
    if (currentHub.value && currentHub.value.ownerId) {
      return getBusinessUnitById(currentHub.value.ownerId) || null
    }
    return null
  })

  return { accessibleHubs, isMultiHub, currentHub, currentHubOwner }
}
