<template>
  <v-menu
    v-model="menu"
    :offset-x="offsetX"
    :offset-y="offsetY"
    :top="top"
    :bottom="bottom"
    :left="left"
    :right="right"
    :nudge-top="nudgeTop"
    :nudge-bottom="nudgeBottom"
    :nudge-left="nudgeLeft"
    :nudge-right="nudgeRight"
    :transition="transition"
    :min-width="minWidth"
    :max-width="maxWidth"
    :max-height="maxHeight"
    :close-on-content-click="false"
  >
    <template #activator="{ on }">
      <vs-button
        label="Filters"
        icon="mdi-filter"
        type="secondary"
        small
        v-on="on"
      />
    </template>
    <v-card>
      <v-app-bar dark dense>
        <v-toolbar-title>Filters</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text icon dark @click="menu = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text
        :style="{ maxHeight: `${maxHeight - 48}px` || false }"
        style="overflow-y: auto; overflow-x: hidden"
      >
        <slot></slot>

        <slot name="post-filters"></slot>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'FiltersMenu',
  props: {
    offsetX: {
      type: Boolean,
      default: () => false,
    },
    offsetY: {
      type: Boolean,
      default: () => false,
    },
    top: {
      type: Boolean,
      default: () => false,
    },
    bottom: {
      type: Boolean,
      default: () => false,
    },
    left: {
      type: Boolean,
      default: () => false,
    },
    right: {
      type: Boolean,
      default: () => false,
    },
    maxHeight: {
      type: Number,
      default: () => null,
    },
    maxWidth: {
      type: Number,
      default: () => 320,
    },
    minWidth: {
      type: Number,
      default: () => 320,
    },
    nudgeTop: {
      type: Number,
      default: () => 0,
    },
    nudgeBottom: {
      type: Number,
      default: () => 0,
    },
    nudgeLeft: {
      type: Number,
      default: () => 0,
    },
    nudgeRight: {
      type: Number,
      default: () => 0,
    },
    transition: {
      type: String,
      default: () => 'scale-transition',
    },
  },
  data: () => ({
    menu: false,
  }),
}
</script>
