<template>
  <div ref="wrapper" :class="['wrapper', { 'full-width': buttonFullWidth }]">
    <span
      v-if="$slots.activator"
      :class="{ 'full-width': buttonFullWidth }"
      @click.stop="toggleMenu"
    >
      <slot name="activator" />
    </span>
    <vs-button
      v-else
      icon="more_horiz"
      type="secondary"
      full-width
      @click.stop="toggleMenu"
    />

    <transition name="fade">
      <div
        v-show="showMenu"
        :class="{ overlay: true, hasBG: overlay }"
        @click.stop="toggleMenu"
      />
    </transition>

    <transition :name="position.y">
      <div
        v-show="showMenu"
        ref="menu"
        class="menu"
        :class="{ [position.y]: position.y, [position.x]: position.x }"
        :style="{ maxHeight: maxHeight ? maxHeight + 'px' : null }"
        @click.stop
      >
        <v-list dense>
          <template v-for="(item, index) in items">
            <v-divider v-if="'divider' in item" :key="index" class="my-2" />
            <v-list-item
              v-else
              :key="item.text"
              :data-test="item.text || item.header"
              :disabled="disableListItem(item)"
              :to="item.to"
              class="list-item"
              @click="handleClick(item.onClick)"
            >
              <v-list-item-content>
                <vs-heading v-if="'header' in item" type="overline"
                  >{{ item.header }}
                </vs-heading>
                <vs-text
                  v-if="item.text"
                  :class="{
                    'red--text text--lighten-1': item.destructive,
                    'grey--text text--lighten-1': item.disabled,
                  }"
                  >{{ item.text }}
                </vs-text>

                <vs-text
                  v-if="item.description"
                  type="subdued"
                  :class="{
                    'grey--text text--lighten-1': item.disabled,
                  }"
                  >{{ item.description }}
                </vs-text>
              </v-list-item-content>

              <v-list-item-action v-if="item.icon">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    /**
     *  The list of actions that the user can choose from.
     *
     * @param {Object} items Items object.
     * @param {String} items.text The label of the option.
     * @param {String} items.icon Adds an icon beside the label
     * @param {Boolean} items.destructive Changes the text color to red
     * @param {Boolean} items.disabled Changes the text color to grey
     * @param {String} items.header Adds an overline header
     * @param {Boolean} items.divider Adds a divider
     */
    items: {
      type: Array[Object],
      default: null,
      required: true,
    },
    /**
     * Adds a transparent gray background that blocks contents from behind
     */
    overlay: {
      type: Boolean,
      default: false,
    },
    /**
     * Force menu to be on a specific horizontal position. This stops the
     * repositioning of the menu.
     */
    horizontalPosition: {
      type: String,
      default: null,
      validator: (value) => ['left', 'right'].includes(value),
    },
    /**
     * Limit the height of the menu and scroll when it overflows
     */
    maxHeight: {
      type: Number,
      default: null,
    },
    /**
     * Allow the activator button to become full-width
     */
    buttonFullWidth: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMenu: false,
      position: {
        y: 'top',
        x: this.horizontalPosition ? this.horizontalPosition : 'right',
      },
    }
  },
  computed: {},
  methods: {
    disableListItem(item) {
      return item.disabled || (!item.onClick && !item.to)
    },
    handleClick(callback) {
      this.toggleMenu()
      callback && callback()
    },
    toggleMenu() {
      this.showMenu = !this.showMenu
      this.repositionMenu()
    },
    repositionMenu() {
      const bounds = this.$refs.wrapper.getBoundingClientRect()
      if (bounds.top <= window.innerHeight / 2) {
        this.position.y = 'bottom'
      } else {
        this.position.y = 'top'
      }

      if (bounds.x <= window.innerWidth / 2) {
        this.position.x = 'left'
      } else {
        this.position.x = 'right'
      }

      this.horizontalPosition && (this.position.x = this.horizontalPosition)
    },
  },
}
</script>

<style scoped>
.wrapper {
  display: inline-block;
  position: relative;
}
.full-width {
  display: block;
}
.menu {
  position: absolute;
  z-index: var(--elevation-menu);
  border-radius: 24px;
  box-shadow: var(--shadow-high);
  background-color: var(--color-white);
  width: 240px;
  overflow: auto;
}

.top {
  bottom: 100%;
}

.bottom {
  top: 100%;
}

.left {
  left: 0;
}

.right {
  right: 0;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
  z-index: var(--elevation-menu);
}

.hasBG {
  background-color: var(--color-black);
}

::v-deep .list-item > * {
  height: auto;
}

.bottom-enter-active,
.bottom-leave-active,
.top-enter-active,
.top-leave-active {
  transform: translateY(0);
  transition: opacity 200ms, transform 200ms;
  will-change: opacity;
}

.bottom-enter,
.bottom-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

.top-enter,
.top-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

/* Fade */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 200ms;
  will-change: opacity;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
