export default class JobFormAssociation {
  constructor(json) {
    this.formUuid = json.form_uuid
    this.formId = json.form_id
    this.name = json.name
    this.required = json.required
  }

  formUuid: string
  formId: number
  name: string
  required: boolean
}
