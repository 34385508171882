export class InventoryAssociation {
  inventoryTypeId: number
  inventorySubTypeId: number | null

  constructor(json) {
    this.inventoryTypeId = json.inventory_type_id
    this.inventorySubTypeId = json.inventory_sub_type_id
  }
}

export default class Activity {
  id: number
  taskName: string
  resourceTypeId: number
  resourceTypeName: string
  taskId: number
  approvalRequirement: string
  isActive: boolean
  ownerId: string
  ownerName: string
  pdfFormatIds: string[]
  inventoryAssociations: InventoryAssociation[]

  constructor(json) {
    this.id = parseInt(json.id, 10)
    this.taskName = json.activity_name
    this.resourceTypeId = json.resource_type_id
    this.resourceTypeName = json.resource_type_name
    this.taskId = json.activity_id
    this.approvalRequirement = json.approval_requirement
    this.isActive = json.is_active
    this.ownerId = json.owner_id
    this.ownerName = json.owner_name
    this.pdfFormatIds = json.pdf_format_ids
    this.inventoryAssociations = json.inventory_associations.map(
      (ia) => new InventoryAssociation(ia)
    )
  }
}
