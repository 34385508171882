<template>
  <p class="content">
    <span>
      {{ displayedName }}
    </span>
    <v-tooltip top max-width="250px" :disabled="!value">
      <template #activator="{ on }">
        <span v-on="on"
          ><strong>{{ hiddenNamesCount }}</strong></span
        >
      </template>
      <span v-html="formattedOperatorNames"></span>
    </v-tooltip>
  </p>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    operatorName() {
      return this.value.map((operator) => operator.name)
    },
    displayedName() {
      return this.operatorName[0]
    },
    hiddenNamesCount() {
      return this.operatorName.length > 1
        ? ` + ${this.operatorName.length - 1} more`
        : ''
    },
    formattedOperatorNames() {
      return this.operatorName.join('<br />')
    },
  },
}
</script>

<style scoped>
.content {
  margin: 0;
}
</style>
