import moment from 'moment'
import JobChange from '@/models/job_change'
import { formatTimeRange } from '@/utils/date-formatting.js'
import { useLocations } from './useLocations'
import { computed, toRef } from 'vue'
import { useGetters, useStore } from 'vuex-composition-helpers'
import JobHistory from '@/models/job-history'
import {
  formatSecondaryFieldName,
  formatSecondaryFieldsDisplay,
} from '../utils/formatting'

export function useFormatting(props: {
  date: string | number
  changes: JobHistory[]
  jobStart?: object | null
  jobEnd?: object | null
  jobHubId: string
}) {
  const changesRef = toRef(props, 'changes')
  const { knownLocations } = useLocations(changesRef)
  const { getBySid: getUserBySid } = useGetters('users', ['getBySid'])
  const { getById: getActivityById } = useGetters('activities', ['getById'])
  const { getById: getUnitById } = useGetters('unitNumbers', ['getById'])
  const { getById: getCompanyById } = useGetters('companies', ['getById'])

  const getActivityName = (id: number) => {
    const activity = getActivityById.value(id)
    return activity
      ? `${activity.resourceTypeName} - ${activity.taskName}`
      : 'Unknown'
  }

  const getUnitName = (id: number) => {
    return getUnitById.value(id)?.name ?? 'Unknown resource'
  }

  const getCompanyName = (id: number) => {
    return getCompanyById.value(id)?.name ?? 'Unknown company'
  }

  const store = useStore()

  const getBusinessUnitById = computed(
    () => store.getters['businessUnits/getBusinessUnitById']
  )

  const jobHub = computed(() => getBusinessUnitById.value(props.jobHubId))
  const jobOrg = computed(() =>
    jobHub.value ? getBusinessUnitById.value(jobHub.value.ownerId) : null
  )

  const getLocationName = (id: number) => {
    return knownLocations.value[id]?.location ?? 'Unknown location'
  }

  const { getNormalizedInventories: getNormalizedInventory } = useGetters(
    'assets',
    ['getNormalizedInventories']
  )

  const inventoryItems = computed<Record<number, string>>(() => {
    const inventoryItems = {}
    for (const inventoryTypes of getNormalizedInventory.value) {
      for (const inventoryItem of inventoryTypes.assetConditions) {
        inventoryItems[inventoryItem.id] = inventoryItem.name
      }
    }
    return inventoryItems
  })

  const getInventoryItemName = (id: number) => {
    return inventoryItems.value[id] ?? 'Unknown inventory item'
  }

  const getInventoryPickup = (id: number) => {
    return knownLocations.value[id]?.location ?? 'Undecided pickup'
  }

  const getInventoryItemDisplayString = (value: {
    pickup: number
    quantity: number
    sub_type_id: number
  }) => {
    return `${getInventoryPickup(value.pickup)}: (Qty ${
      value.quantity
    }) ${getInventoryItemName(value.sub_type_id)}`
  }

  const formatAssignmentValue = (
    change: { id: number; type: string } | undefined | null
  ) => {
    if (!change) return null

    if (change.type === 'assigned_to_pool_id') return 'Pool'

    return change.type === 'resource_id'
      ? getUnitName(change.id)
      : getCompanyName(change.id)
  }

  const formatPlannedTimeChange = (
    props: Partial<{
      jobStart?: object | null
      jobEnd?: object | null
    }>,
    startTimeChange?: Partial<JobChange>,
    endTimeChange?: Partial<JobChange>
  ) => {
    if (!startTimeChange && !endTimeChange) return null
    const oldStart = startTimeChange?.originalValue
      ? moment.utc(startTimeChange?.originalValue).local()
      : props.jobStart
    const newStart = startTimeChange?.newValue
      ? moment.utc(startTimeChange?.newValue).local()
      : props.jobStart
    const oldEnd = endTimeChange?.originalValue
      ? moment.utc(endTimeChange?.originalValue).local()
      : props.jobEnd
    const newEnd = endTimeChange?.newValue
      ? moment.utc(endTimeChange?.newValue).local()
      : props.jobEnd

    return {
      strikeLeft: false,
      columnName: 'time_range',
      newValue: formatTimeRange(newStart, newEnd),
      originalValue: `Planned: ${formatTimeRange(oldStart, oldEnd)}`,
    }
  }

  const formatActiveTimeChange = (
    activeTimeChange?: Partial<JobChange>,
    completedTimeChange?: Partial<JobChange>
  ) => {
    if (!activeTimeChange && !completedTimeChange) return null
    const oldActiveTime = moment.utc(activeTimeChange?.originalValue).local()
    const newActiveTime = moment.utc(activeTimeChange?.newValue).local()
    const oldCompletedTime = moment
      .utc(completedTimeChange?.originalValue)
      .local()
    const newCompletedTime = moment.utc(completedTimeChange?.newValue).local()
    return {
      strikeLeft: false,
      columnName: 'time_range',
      newValue: formatTimeRange(newActiveTime, newCompletedTime),
      originalValue: `Actual: ${formatTimeRange(
        oldActiveTime,
        oldCompletedTime
      )}`,
    }
  }

  const formatImportantField = (field: JobChange, change) => {
    switch (field.columnName) {
      case 'destination_location_id':
        return {
          strikeLeft: Boolean(field.originalValue),
          columnName: field.columnName,
          newValue: getLocationName(field.newValue),
          originalValue: getLocationName(field.originalValue),
        }
      case 'activity_type_id':
        return {
          strikeLeft: Boolean(field.originalValue),
          columnName: field.columnName,
          newValue: getActivityName(field.newValue),
          originalValue: getActivityName(field.originalValue),
        }
      case 'assigned_to': {
        const newValue = formatAssignmentValue(field.newValue)
        const originalValue = formatAssignmentValue(field.originalValue)

        if (newValue === originalValue) return null

        return {
          strikeLeft: Boolean(field.originalValue),
          columnName: field.columnName,
          newValue,
          originalValue: originalValue ?? 'Assigned to',
        }
      }
      case 'inventory_item_id': {
        return {
          strikeLeft: Boolean(field.originalValue),
          columnName: field.columnName,
          newValue:
            field.newValue != null
              ? getInventoryItemDisplayString(field.newValue)
              : null,
          originalValue:
            field.originalValue != null
              ? getInventoryItemDisplayString(field.originalValue)
              : 'Added',
        }
      }
      case 'comment': {
        return {
          columnName: field.columnName,
          newValue: field.newValue,
          strikeLeft: false,
          originalValue: field.originalValue,
        }
      }
      case 'location_visited': {
        return {
          columnName: 'Location visited',
          newValue: getLocationName(field.newValue),
          strikeLeft: false,
          originalValue: getUnitName(field.originalValue),
        }
      }
      case 'rejection_reason': {
        if (change.type === 'rejected_approval') {
          return {
            columnName: 'Reason',
            newValue: field.originalValue,
            strikeLeft: false,
            originalValue: field.newValue,
          }
        }
        return null
      }
      default:
        return null
    }
  }

  const formatType = (
    type: string,
    modifiedByUserSid: string,
    fieldsChanged: JobChange[]
  ) => {
    if (type === 'approved') {
      const userOrgId = getUserBySid.value(modifiedByUserSid)?.organizationId
      if (userOrgId && jobOrg.value?.ownerId === userOrgId) {
        return `Approved on behalf of ${jobOrg.value.name}`
      } else {
        return 'Approved'
      }
    }

    if (type === 'assigned_to_pool') {
      return 'Assigned to Pool'
    }
    if (type === 'availability_accepted') {
      return 'Claimed'
    }
    if (type === 'availability_declined') {
      return 'Declined'
    }
    if (type === 'rejected_approval') {
      return 'Approval rejected'
    }
    if (type === 'location_visited') {
      return 'Location Visited'
    }
    if (type === 'submitted_for_approval') {
      return 'Submitted for approval'
    }
    if (type === 'approval_request_cancelled') {
      return 'Approval request cancelled'
    }
    if (type === 'signature_deleted') {
      return 'Signature deleted'
    }
    if (type === 'signed') {
      const name = fieldsChanged.find((c) => c.columnName === 'name')?.newValue
      const modifiedByUserName = getUserBySid.value(modifiedByUserSid)?.name

      if (name !== modifiedByUserName) {
        return `Signature collected from ${name}`
      }
    }
    if (type === 'reopen') {
      return 'Reopened'
    }
    if (type === 'trigger_threshold_resolved') {
      return 'Trigger threshold resolved'
    }
    if (type === 'item_received_at_location') {
      return 'Item received at location'
    }

    // Capitalize the first letter
    return type.charAt(0).toUpperCase() + type.slice(1)
  }

  return {
    formatPlannedTimeChange,
    formatActiveTimeChange,
    formatImportantField,
    formatSecondaryFieldName,
    formatSecondaryFieldsDisplay,
    formatType,
  }
}
