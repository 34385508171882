<template>
  <vs-text-input
    id="table-search"
    ref="input"
    :label="label"
    :value="searchValue"
    :autofocus="autofocus"
    @input="onSearchInput"
    @paste="onPaste"
  >
    <template #append>
      <v-icon>search</v-icon>
    </template>
  </vs-text-input>
</template>

<script>
export default {
  props: {
    searchValue: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: () => 'Search',
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchDebounce: null,
    }
  },
  methods: {
    onSearchInput(search) {
      if (this.searchDebounce) {
        clearTimeout(this.searchDebounce)
        this.searchDebounce = null
      }

      this.searchDebounce = setTimeout(
        () => this.$emit('update-search', search),
        250
      )
    },
    onPaste(event) {
      const pastedText = event.clipboardData.getData('text/plain')
      const inputEl = this.$refs.input.$refs.input
      const { selectionStart, selectionEnd } = inputEl

      if (
        isValidPaste(this.searchValue, pastedText, selectionStart, selectionEnd)
      ) {
        event.preventDefault()
        this.handlePasteListOfNumbers(pastedText, selectionStart, selectionEnd)
      }
    },
    handlePasteListOfNumbers(pastedText, selectionStart, selectionEnd) {
      const inputEl = this.$refs.input.$refs.input
      const newText = formatText(
        'number',
        this.searchValue,
        pastedText,
        selectionStart,
        selectionEnd
      )

      this.$emit('update-search', newText)

      // Update input with new text and selection range
      this.$nextTick(() => {
        const newCursorPosition = selectionStart === 0 ? 0 : newText.length
        inputEl.setSelectionRange(newCursorPosition, newCursorPosition + 6)
      })
    },
  },
}

// Check if the pasted data is a newline separated list of numbers
// and that the cursor is at the start or end of the input.
// We interpret this as pasting a list of job numbers from a spreadsheet
function isValidPaste(currentText, pastedText, selectionStart, selectionEnd) {
  const lines = pastedText
    .split('\n')
    .map((line) => line.replaceAll('\r', '').trim())
  const isOnlyNumbers = lines.every((line) => /^\d+$/.test(line))
  const isCursorAtStartOrEnd =
    selectionStart === 0 || selectionEnd === currentText.length

  return pastedText.includes('\n') && isOnlyNumbers && isCursorAtStartOrEnd
}

// Format the pasted text as a list of numbers
// and prepend the given token
function formatText(
  token,
  currentText,
  pastedText,
  selectionStart,
  selectionEnd
) {
  const lines = pastedText
    .split('\n')
    .map((line) => line.replaceAll('\r', '').trim())
    .join(',')

  let newText = lines
  if (selectionStart === 0 && selectionEnd === currentText.length) {
    newText = lines
  } else if (selectionStart === 0) {
    newText = lines + ' ' + currentText
  } else if (selectionEnd === currentText.length) {
    newText = currentText + ' ' + lines
  }
  return `${token}:(${newText})`
}
</script>
