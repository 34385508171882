<template>
  <vs-wrapper space="small">
    <span v-for="value in values">
      <submission-object-field
        v-if="isObjectArray"
        :value="value"
        :children="children"
      />
      <submission-primitive-field
        v-else
        :lookup="lookup"
        :format="format"
        :value="value"
      />
    </span>
  </vs-wrapper>
</template>

<script>
import SubmissionPrimitiveField from '@/components/forms/submission-fields/SubmissionPrimitiveField'
import SubmissionObjectField from '@/components/forms/submission-fields/SubmissionObjectField'

export default {
  name: 'SubmissionArrayField',
  components: { SubmissionObjectField, SubmissionPrimitiveField },
  props: {
    children: {
      type: Object,
      required: true,
    },
    lookup: {
      type: Object,
      required: true,
    },
    format: {
      type: String,
      required: true,
    },
    values: {
      type: Array,
      required: true,
    },
  },
  computed: {
    isObjectArray() {
      return this.values.length > 0 && typeof this.values[0] === 'object'
    },
  },
}
</script>

<style scoped></style>
