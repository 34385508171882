import moment from 'moment'
import JobChange from './job_change'

export default class JobHistory {
  constructor(json) {
    this.modified = moment.utc(json.modified).local()
    this.modifiedBy = json.modified_by
    this.type = json.type
    this.fieldsChanged = json.fields_changed.map(
      (change) => new JobChange(change)
    )
  }

  modified: moment.Moment
  modifiedBy: string
  type: string
  fieldsChanged: JobChange[]
}
