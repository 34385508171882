export const sharedOptions = {
  data() {
    return {
      allErrorMessages: this.errors || [],
      localRules: this.rules || [],
    }
  },
  computed: {
    hasErrorMessages() {
      return !!this.allErrorMessages.length
    },
    getParent() {
      let component = null
      let parent = this.$parent
      while (parent && !component) {
        if (parent.$options.name === 'VsForm') {
          component = parent
        }
        parent = parent.$parent
      }
      return component
    },
  },
  watch: {
    value() {
      this.hasErrorMessages && this.runValidation()
    },
    errorMessages(newValue) {
      this.allErrorMessages = newValue
    },
  },
  created() {
    !!this.getParent && this.getParent.register(this)
  },
  destroyed() {
    !!this.getParent && this.getParent.unregister(this)
  },
  methods: {
    runValidation() {
      const messages = []
      const valueIsEmpty =
        !(this.value || this.value === 0) ||
        (typeof this.value === 'string' && this.value.trim().length === 0)

      // Don't fire other validation rules when a field is required and left empty
      if (this.required && valueIsEmpty) {
        this.useShortMessages? messages.push('Required') : messages.push('This field is required')
      } else if (
        this.maxLength &&
        !valueIsEmpty &&
        this.value.length > this.maxLength
      ) {
        messages.push(
          `This field can contain at most ${this.maxLength} characters`
        )
      } else if (
        this.minLength &&
        !valueIsEmpty &&
        this.value.length < this.minLength
      ) {
        messages.push(
          `This field must contain at least ${this.minLength} characters`
        )
      }

      this.localRules.forEach((func) => {
        func(this.value) && messages.push(func(this.value))
      })

      this.allErrorMessages = messages.filter((message) => message !== true)
      return this.allErrorMessages.length > 0
    },
    addErrorMessages(message) {
      this.allErrorMessages = message
    },
    resetErrorMessages() {
      this.allErrorMessages = []
    },
  },
}
