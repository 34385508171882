import moment from 'moment'

export type ItemWhenSigned = {
  [key: string]: number
}

export type SignatureMetadata = {
  itemsWhenSigned: ItemWhenSigned[]
}

// Simplifies the API request when creating signatures
export type SignatureMetadataJson = {
  items_when_signed: ItemWhenSigned[]
}

export default class JobSignature {
  constructor(json) {
    this.id = json.id
    this.signedAt = moment.utc(json.signed_at).local()
    this.createdOn = moment.utc(json.created).local()
    this.createdBy = json.created_by
    this.name = json.name
    this.email = json.email
    this.metadata = {
      itemsWhenSigned: json.metadata?.items_when_signed ?? [],
    }
  }

  id: string
  signedAt: moment.Moment
  createdOn: moment.Moment
  createdBy: string
  name: string
  email: string
  metadata: SignatureMetadata
}
