<template>
  <div ref="menu" :class="{ [`menu-${type}`]: type }">
    <v-list data-test="menu-list" dense>
      <v-lazy
        v-for="(item, index) in filteredItems"
        :key="[index, item.value].join()"
        :options="{ threshold: 0.5 }"
        min-height="10"
      >
        <v-list-item
          :class="{
            indent: item.indent,
            'list-item': true,
          }"
          data-test="list-item"
          @[clickable(item)].stop="handleClick(item)"
        >
          <v-list-item-content>
            <vs-heading v-if="item.header" type="overline"
              >{{ item.text }}
            </vs-heading>
            <vs-text v-else>{{ item.text }} </vs-text>
            <vs-text v-if="item.description" type="subdued"
              >{{ item.description }}
            </vs-text>
          </v-list-item-content>
          <v-list-item-icon v-if="item.icon">
            <v-icon size="20">
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-lazy>

      <v-list-item v-if="items && items.length === 0">
        <v-list-item-content>
          <vs-text>
            <template v-if="filterValue">
              <template>
                No results for <em>{{ filterValue }}</em>
              </template>
            </template>
            <template v-else>
              <template>No {{ label }} found</template>
            </template>
          </vs-text>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { clone, uniqBy } from 'lodash'
export default {
  name: 'AutoCompleteBUMenu',
  props: {
    /**
     * Change the default not found message
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * Filters the items in the menu
     */
    filterValue: {
      type: String,
      default: null,
    },
    /**
     * Changes the styling of the menu
     *
     * embedded - menu is used by itself
     * popOver - menu is used by VsAutocomplete
     * aside - menu is used by filterAside
     */
    type: {
      type: String,
      default: 'popOver',
      validator: (val) => ['popOver', 'embedded', 'aside'].includes(val),
    },
    items: {
      type: Array,
      default() {
        return []
      },
    },
  },
  computed: {
    filteredItems() {
      if (this.filterValue) return this.filter()
      return this.items
    },
  },
  methods: {
    handleClick(businessUnit) {
      this.$emit('click', businessUnit.value)
    },
    clickable(item) {
      return 'value' in item && !item.disabled ? 'click' : null
    },
    matchWith(value) {
      if (!value) return false
      const criteria = value.replace(/\s/g, '').toLocaleLowerCase()
      const searchVal = this.filterValue.replace(/\s/g, '').toLocaleLowerCase()
      return criteria.indexOf(searchVal) > -1
    },
    filter() {
      let filtered = this.items.filter(
        (item) =>
          this.matchWith(item.text) ||
          this.matchWith(item.description) ||
          this.matchWith(item.additionalKeywords)
      )
      // The same entity may appear multiple times in this.items, under different headings
      // (e.g. a user that is role assigned to multiple hubs).
      // However, we do not want to show duplicates in the search results.
      filtered = uniqBy(filtered, (item) => item.id ?? item.text)
      for (const item of filtered) {
        if (!filtered.includes(item.parent)) {
          const cloneItem = clone(item)
          cloneItem.indent = false
          const index = filtered.indexOf(item)
          if (index !== -1) {
            filtered[index] = cloneItem
          }
        }
      }
      return filtered
    },
  },
}
</script>
<style scoped>
.menu-popOver {
  max-height: 400px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: var(--space-base);
}
.menu-embedded {
  max-height: 100%;
  min-height: 400px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: var(--space-base);
  margin: -16px;
}
.menu-aside {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: var(--space-base);
}
.number ::v-deep .list-item {
  text-align: right;
  align-items: flex-end;
}
.indent {
  border-left: var(--color-grey--lightest) solid 1.2px;
  margin-left: 20px;
  padding-left: 5px;
}
</style>
