export function pointToLatLng(point) {
  try {
    const parts = point.split(' ')
    const lat = parts[1].substring(1).slice(0, -1)
    const lng = parts[2].slice(0, -1)

    return {
      lat,
      lng,
    }
  } catch (err) {
    return null
  }
}

export function latLngToPoint(latLng) {
  const lat = latLng.lat.toFixed(5)
  const lng = latLng.lng.toFixed(5)
  return `POINT (${lat}, ${lng})`
}

export function pointToMapData(point) {
  const latLng = pointToLatLng(point)

  const data = {
    features: [
      {
        type: 'Feature',
        id: 0,
        geometry: {
          coordinates: [latLng.lng, latLng.lat],
          type: 'Point',
        },
      },
    ],
    type: 'FeatureCollection',
  }

  return data
}
