import axios from 'axios'
import { get } from './base'
import { useQuery } from '@tanstack/vue-query'
import moment from 'moment/moment'
import { SensorSummary, TankLevelSensorChartData } from '@/models/sensors-dto'
import { convertToCamelCase } from '@/models/utils/casing'
import { computed, Ref, unref } from 'vue'

const sensorsEndPoint = '/api/v2/sensors'

export function getSensorsForLocation(
  id: string | number,
  signal?: AbortSignal
) {
  const source = axios.CancelToken.source()

  const sensors = get<SensorSummary[]>(`${sensorsEndPoint}?locationId=${id}`, {
    cancelToken: source.token,
  }).then((res) => convertToCamelCase(res.data))

  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled by TanStack Query')
  })

  return sensors
}

type MaybeRef<T> = Ref<T> | T

export function useSensorsForLocationQuery(
  locationId?: MaybeRef<string | number | null | undefined>
) {
  const computedId = computed(() => unref(locationId))
  const queryKey = computed(() => ['sensors-at', computedId.value])
  const enabled = computed(() => {
    return computedId.value !== null && computedId.value !== undefined
  })

  const query = useQuery({
    queryKey,
    queryFn: ({ signal }) => getSensorsForLocation(computedId.value!, signal),
    staleTime: moment.duration(10, 'minutes').asMilliseconds(),
    enabled,
  })

  return query
}

export function getTankLevelSensorChartData(
  sensorId: string,
  windowStart: Date,
  windowEnd: Date,
  signal?: AbortSignal
) {
  const source = axios.CancelToken.source()

  const searchParams = new URLSearchParams()
  searchParams.append('sensorId', sensorId)
  searchParams.append('windowStart', windowStart.toISOString())
  searchParams.append('windowEnd', windowEnd.toISOString())

  const sensors = get<TankLevelSensorChartData>(
    `${sensorsEndPoint}/charts/tanklevelsensor?${searchParams.toString()}`,
    {
      cancelToken: source.token,
    }
  ).then((res) => convertToCamelCase(res.data))

  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled by TanStack Query')
  })

  return sensors
}

export function useTankLevelSensorChartData(
  sensorId?: MaybeRef<string | null | undefined>,
  windowStart?: MaybeRef<Date | null | undefined>,
  windowEnd?: MaybeRef<Date | null | undefined>
) {
  const id = computed(() => unref(sensorId))
  const start = computed(() => unref(windowStart))
  const end = computed(() => unref(windowEnd))

  const queryKey = computed(() => ['sensor-data', id, sensorId, start, end])
  const enabled = computed(() => {
    return (
      id.value !== null && start.value !== undefined && end.value !== undefined
    )
  })

  const query = useQuery({
    queryKey,
    queryFn: ({ signal }) =>
      getTankLevelSensorChartData(id.value!, start.value!, end.value!, signal),
    staleTime: moment.duration(10, 'minutes').asMilliseconds(),
    enabled,
  })

  return query
}
