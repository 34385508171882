"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConstDependencyDTO = void 0;
class ConstDependencyDTO {
    constructor(parentField, requiredValue, dependentFields) {
        this.parentField = parentField;
        this.requiredValue = requiredValue;
        this.dependentFields = dependentFields;
    }
}
exports.ConstDependencyDTO = ConstDependencyDTO;
