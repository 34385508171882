import axios from 'axios'
import { get, post, put } from './base'

let getAllSource = axios.CancelToken.source()
let getByIdSource = axios.CancelToken.source()
let createSource = axios.CancelToken.source()
let updateSource = axios.CancelToken.source()
let updateCustomFieldsSource = axios.CancelToken.source()

const endPoint = '/api/v2/tasks'
const v3EndPoint = '/api/v3/tasks'

export function getById(id) {
  getByIdSource.cancel('Cancel old get task by ID request')
  getByIdSource = axios.CancelToken.source()

  return get(`/api/tasks/${id}`, { cancelToken: getByIdSource.token })
}

export function getAll() {
  getAllSource.cancel('Cancel old get tasks request')
  getAllSource = axios.CancelToken.source()

  return get(v3EndPoint, { cancelToken: getAllSource.token })
}

export function create(item) {
  createSource.cancel('Cancel old create tasks request')
  createSource = axios.CancelToken.source()

  return post(endPoint, {
    cancelToken: createSource.token,
    data: {
      name: item.name,
      is_active: item.isActive,
      owner_id: item.ownerId,
    },
  })
}

// @TODO(aaron): endpoint DNE in backend, remove
export function update(item) {
  updateSource.cancel('Cancel old update tasks request')
  updateSource = axios.CancelToken.source()

  return put(`${endPoint}/${item.id}`, {
    cancelToken: updateSource.token,
    data: {
      id: item.id,
      name: item.name,
      is_active: item.isActive,
    },
  })
}

export function updateCustomFields(id, customFields) {
  updateCustomFieldsSource.cancel('Cancel old custom fields update request')
  updateCustomFieldsSource = axios.CancelToken.source()

  return post(`/api/tasks/${id}/custom-fields`, {
    cancelToken: updateCustomFieldsSource.token,
    data: {
      activity_id: id,
      custom_fields_schema: customFields,
    },
  })
}
