import { getBusinessUnits } from '@/api/business-units'
import {
  DIVISION,
  Division,
  HUB,
  Hub,
  ORGANIZATION,
  Organization,
  TEAM,
  Team,
} from '@/models/business-unit'
import { keyBy, orderBy } from 'lodash'

export const RECEIVE_BUSINESS_UNITS = 'RECEIVE_BUSINESS_UNITS'
export const LOADING_BUSINESS_UNITS = 'LOADING_BUSINESS_UNITS'
export const ERROR_LOADING = 'ERROR_LOADING'

const state = {
  all: {},
  loading: false,
  error: null,
}

export const getters = {
  getAllBusinessUnits: (state) =>
    orderBy(Object.values(state.all), [(bu) => bu.name.toLowerCase()]),
  getAllOrganizations: (_, getters) =>
    getters.getAllBusinessUnits.filter((bu) => bu.type === ORGANIZATION),
  getManagedOrganizations: (_, getters) => (orgId) =>
    getters.getAllOrganizations.filter(
      (org) => org.ownerId === orgId || org.id === orgId
    ),
  getAllHubs: (_, getters) =>
    getters.getAllBusinessUnits.filter((bu) => bu.type === HUB),
  getAllDivisions: (_, getters) =>
    getters.getAllBusinessUnits.filter((bu) => bu.type === DIVISION),
  getAllTeams: (_, getters) =>
    getters.getAllBusinessUnits.filter((bu) => bu.type === TEAM),
  getBusinessUnitById: (state) => (id) => state.all[id],
  getHierarchyLevel: (state, getters) => (id, level) => {
    const bu = state.all[id]
    if (!bu) return null
    if (bu.type === level) return bu
    const owner = getters.getBusinessUnitById(bu.ownerId)
    return getters.getHierarchyLevel(owner?.id, level)
  },
  getLoading: (state) => state.loading,
}

export const actions = {
  async fetchBusinessUnits({ commit }) {
    try {
      commit(LOADING_BUSINESS_UNITS)
      const { data } = await getBusinessUnits()

      const businessUnits = data
        .map((entry) => {
          switch (entry.type) {
            case ORGANIZATION:
              return new Organization(entry)
            case HUB:
              return new Hub(entry)
            case DIVISION:
              return new Division(entry)
            case TEAM:
              return new Team(entry)
            default:
              return null
          }
        })
        .filter((entry) => !!entry)

      commit(RECEIVE_BUSINESS_UNITS, businessUnits)
    } catch (err) {
      commit(ERROR_LOADING, err)
    }
  },
}

export const mutations = {
  [LOADING_BUSINESS_UNITS](state) {
    state.loading = true
  },
  [RECEIVE_BUSINESS_UNITS](state, data) {
    state.all = keyBy(data, 'id')
    state.loading = false
  },
  [ERROR_LOADING](state, err) {
    state.error = err
    state.loading = false
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
