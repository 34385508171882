<template>
  <vs-wrapper space="small">
    <v-radio-group
      mandatory
      label="Choose the lookup selector type"
      :value="field.lookupEntity.entity"
      @change="changeLookupType"
    >
      <v-radio
        v-for="choice in lookupTypeChoices"
        :key="choice.value"
        :label="choice.name"
        :value="choice.value"
      >
      </v-radio>
    </v-radio-group>
  </vs-wrapper>
</template>

<script>
import VsWrapper from '@/components/vision/VsWrapper'
import {
  BusinessUnitLookup,
  CompanyLookup,
  LocationLookup,
  UserLookup,
} from 'ironsight-form-builder/dist/models/lookup_entity'
export default {
  name: 'LookupSelectorFieldSettings',
  components: { VsWrapper },

  props: {
    field: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      lookupTypeChoices: {
        user: {
          name: 'User',
          value: 'user',
          build: () => new UserLookup(),
        },
        company: {
          name: 'Company',
          value: 'company',
          build: () => new CompanyLookup(),
        },
        location: {
          name: 'Location',
          value: 'location',
          build: () => new LocationLookup(),
        },
        businessUnit: {
          name: 'Business Unit',
          value: 'business_unit',
          build: () => new BusinessUnitLookup(),
        },
      },
    }
  },
  methods: {
    getLookupBuild(lookupChoiceValue) {
      switch (lookupChoiceValue) {
        case 'user':
          return this.lookupTypeChoices.user.build()
        case 'company':
          return this.lookupTypeChoices.company.build()
        case 'location':
          return this.lookupTypeChoices.location.build()
        case 'business_unit':
          return this.lookupTypeChoices.businessUnit.build()
      }
    },
    changeLookupType(lookupChoiceValue) {
      this.emitChanges('lookupEntity', this.getLookupBuild(lookupChoiceValue))
    },
    emitChanges(propertyKey, value) {
      this.$emit('modify-field', {
        propertyKey,
        fieldValue: value,
        key: this.field.uniqueKey(),
      })
    },
  },
}
</script>

<style scoped></style>
