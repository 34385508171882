<template>
  <v-row no-gutters>
    <v-col>
      <v-menu
        ref="menu"
        v-model="show"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template #activator="{ on }">
          <div class="week-selector" v-on="on">
            <vs-heading type="overline">
              <vs-button icon="date_range" type="tertiary" small />
              {{ display }}
            </vs-heading>
          </div>
        </template>
        <v-date-picker
          range
          :value="dates"
          no-title
          show-adjacent-months
          color="color-blue--light"
          @input="select"
        />
      </v-menu>
    </v-col>

    <v-col class="shrink">
      <vs-button
        v-show="!inHomeWeek"
        icon="home"
        small
        type="tertiary"
        @click="onHomeClicked"
      />

      <vs-button
        data-test="previous-week-button"
        icon="chevron_left"
        type="tertiary"
        small
        @click="onSelectPreviousWeekClicked"
      />

      <vs-button
        data-test="next-week-button"
        icon="chevron_right"
        type="tertiary"
        small
        @click="onSelectNextWeekClicked"
      />
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'

export default {
  name: 'WeekSelector',

  props: {
    value: {
      type: Array,
      default: () => [moment().startOf('week'), moment().endOf('week')],
    },
  },

  data() {
    return {
      show: false,
    }
  },

  computed: {
    display() {
      if (this.value[0].isSame(this.value[1], 'month')) {
        return this.value[0].format('MMM YYYY')
      } else {
        return `${this.value[0].format('MMM')} - ${this.value[1].format(
          'MMM'
        )} ${this.value[0].format('YYYY')}`
      }
    },
    dates() {
      const [start, end] = this.value
      return [
        start.startOf('week').format('YYYY-MM-DD'),
        end.endOf('week').format('YYYY-MM-DD'),
      ]
    },
    inHomeWeek() {
      const [start, end] = this.value
      return moment().isBetween(start, end, 'day', '[]')
    },
  },

  methods: {
    calculateAndEmitInput(startDate) {
      const weekStart = moment(startDate).startOf('week')
      const range = [weekStart, moment(weekStart).endOf('week')]
      this.$emit('input', range)
    },
    select(value) {
      const [start] = value
      this.$refs.menu.save()
      this.calculateAndEmitInput(start)
    },
    onSelectPreviousWeekClicked() {
      this.calculateAndEmitInput(moment(this.value[0]).subtract(7, 'd'))
    },
    onSelectNextWeekClicked() {
      this.calculateAndEmitInput(moment(this.value[0]).add(7, 'd'))
    },
    onHomeClicked() {
      this.calculateAndEmitInput(moment())
    },
  },
}
</script>

<style scoped>
.week-selector {
  cursor: pointer;
  display: inline-block;
}
</style>
