import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useScheduleFilterStore = defineStore(
  'schedule-filters',
  () => {
    const isMigratedFromVuex = ref<boolean>(false)
    const lastViewedSchedule = ref<string>('day')
    const showOfflineUnits = ref<boolean>()
    const showStandbyUnits = ref<boolean>()
    const showUnitsWithNoAssignedJobs = ref<boolean>()

    const updateShowOfflineUnits = (show: boolean) => {
      showOfflineUnits.value = show
    }

    const updateShowStandbyUnits = (show: boolean) => {
      showStandbyUnits.value = show
    }

    const updateShowUnitsWithNoAssignedJobs = (show: boolean) => {
      showUnitsWithNoAssignedJobs.value = show
    }

    const updateLastViewedSchedule = (view: string) => {
      lastViewedSchedule.value = view
    }

    return {
      isMigratedFromVuex,
      lastViewedSchedule,
      showOfflineUnits,
      showStandbyUnits,
      showUnitsWithNoAssignedJobs,
      updateShowOfflineUnits,
      updateShowStandbyUnits,
      updateShowUnitsWithNoAssignedJobs,
      updateLastViewedSchedule,
    }
  },
  {
    persist: true,
  }
)
