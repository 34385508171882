
import { computed, defineComponent, toRefs } from 'vue'
import { Prop } from 'vue/types/options'

import DurationDisplay from '@/components/jobs/fields/DurationDisplay.vue'
import DateRangeDisplay from '@/components/jobs/fields/DateRangeDisplay.vue'
import moment from 'moment'

export default defineComponent({
  name: 'JobScheduleSection',
  components: { DateRangeDisplay, DurationDisplay },

  props: {
    job: {
      type: Object as Prop<{
        requestedStart: moment.Moment
        requestedEnd: moment.Moment
      }>,
    },
  },

  setup(props) {
    const { job } = toRefs(props)

    const startTime = computed(() => {
      return moment.utc(job.value?.requestedStart).local().toString()
    })
    const endTime = computed(() => {
      return moment.utc(job.value?.requestedEnd).local().toString()
    })

    return { startTime, endTime }
  },
})
