<template>
  <vs-wrapper space="small">
    <v-row dense>
      <v-col>
        <vs-text-input
          v-model.lazy="
            titleDisabled ? field.reference.title : field.titleOverride
          "
          label="Title"
          :value="field.titleOverride"
          :disabled="titleDisabled"
          @input="updateTitle"
        />
      </v-col>

      <v-col cols="auto">
        <vs-wrapper space="small" direction="horizontal">
          <vs-button
            type="secondary"
            label="Edit title"
            @click="updateTitle('')"
          ></vs-button>
          <vs-button
            type="secondary"
            label="Use default"
            :disabled="titleDisabled"
            @click="updateTitle(null)"
          ></vs-button>
        </vs-wrapper>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        <vs-text-input
          v-model.lazy="
            descriptionDisabled
              ? field.reference.description
              : field.descriptionOverride
          "
          label="Description"
          :value="field.descriptionOverride"
          :disabled="descriptionDisabled"
          @input="updateDescription"
        />
      </v-col>

      <v-col cols="auto">
        <vs-wrapper space="small" direction="horizontal">
          <vs-button
            type="secondary"
            label="Edit description"
            @click="updateDescription('')"
          ></vs-button>
          <vs-button
            type="secondary"
            label="Use default"
            :disabled="descriptionDisabled"
            @click="updateDescription(null)"
          ></vs-button>
        </vs-wrapper>
      </v-col>
    </v-row>

    <v-switch
      inset
      label="Required"
      :input-value="field.isRequired"
      :value="field.isRequired"
      @change="updateFieldIsRequired"
    ></v-switch>
  </vs-wrapper>
</template>

<script>
import VsTextInput from '@/components/vision/Inputs/VsTextInput'
import VsWrapper from '@/components/vision/VsWrapper'
import VsButton from '@/components/vision/VsButton'
import VsHeading from '@/components/vision/VsHeading'
export default {
  name: 'ReferenceFieldSettings',
  components: { VsHeading, VsButton, VsWrapper, VsTextInput },

  props: {
    field: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    titleDisabled() {
      return this.field.titleOverride == null
    },
    descriptionDisabled() {
      return this.field.descriptionOverride == null
    },
  },

  methods: {
    updateTitle(title) {
      this.emitChanges('titleOverride', title)
    },
    updateDescription(description) {
      this.emitChanges('descriptionOverride', description)
    },
    updateFieldIsRequired(value) {
      this.emitChanges('isRequired', value !== null)
    },
    removeField() {
      this.$emit('remove-field', this.field)
    },
    emitChanges(propertyKey, value) {
      this.$emit('modify-field', {
        propertyKey,
        fieldValue: value,
        key: this.field.uniqueKey(),
      })
    },
  },
}
</script>

<style scoped></style>
