import mapboxgl from 'mapbox-gl'

export const locationPinStyle: Partial<mapboxgl.SymbolLayer> = {
  layout: {
    'symbol-sort-key': [
      'case',
      ['!', ['has', 'ParentId']],
      1,
      ['to-number', ['id']],
    ],
    'icon-allow-overlap': {
      stops: [
        [13, false],
        [14, true],
      ],
    },
    'icon-size': 0.8,
    'icon-image': [
      'concat',
      'circle',
      '__',
      ['coalesce', ['get', 'IconColor'], '#eeeeee'],
      '__',
      ['coalesce', ['get', 'Icon'], 'mdi-map-marker'],
    ],
    'icon-anchor': 'center',
    'text-field': ['to-string', ['get', 'Name']],
    'text-font': ['Montserrat Medium', 'Arial Unicode MS Regular'],
    'text-radial-offset': 1.3,
    'text-line-height': 1.1,
    'text-allow-overlap': false,
    'text-optional': true,
    'text-anchor': 'top',
    'text-letter-spacing': 0.1,
    'text-size': {
      stops: [
        [14, 0],
        [15, 11],
        [15.5, 12],
        [17, 14],
      ],
    },
  },
  paint: {
    'text-halo-color': 'hsl(0, 5%, 0%)',
    'text-color': 'hsl(0, 0%, 100%)',
    'text-halo-width': 2,
    'text-halo-blur': 0.5,
    'text-opacity': {
      stops: [
        [15.5, 0],
        [16, 1],
      ],
    },
  },
}
