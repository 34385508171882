<template>
  <dl>
    <div v-for="assignment in value" :key="`${assignment.id}`">
      <Assignment
        :activity="assignment.activity"
        :resource-type="assignment.resourceType"
        :unit="assignment.unit"
      />
    </div>
  </dl>
</template>

<script>
import Assignment from './Assignment'
export default {
  name: 'Assignments',
  components: {
    Assignment,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
