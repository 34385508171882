import { defineComponent, PropType, h } from 'vue'
import moment from 'moment'
import JobCell from '@/components/weekly-schedule/JobCell.vue'
import AssignmentGroupCell from '@/components/weekly-schedule/AssignmentGroupCell.vue'
import { Item } from '@/components/weekly-schedule/utils'

export default defineComponent({
  props: {
    weekStart: {
      type: Object as PropType<moment.Moment>,
      required: true,
    },
    events: {
      type: Array as unknown as PropType<Item[]>,
      required: true,
    },
  },

  setup(props, { listeners }) {
    return function renderEventCell() {
      if (props.events.length > 1) {
        return h(AssignmentGroupCell, {
          on: listeners,
          props: { weekStart: props.weekStart, jobs: props.events },
        })
      }
      return h(JobCell, {
        on: listeners,
        props: { weekStart: props.weekStart, job: props.events[0] },
      })
    }
  },
})
