<template>
  <v-container>
    <vs-heading>{{ name }}</vs-heading>
    <vs-text type="subdued">
      {{ unitsOverview }}
    </vs-text>
    <vs-text type="subdued">
      {{ unscheduledJobsText }}
    </vs-text>
  </v-container>
</template>

<script>
export default {
  name: 'ResourceTypeGroup',

  props: {
    name: {
      type: String,
      required: true,
    },
    unitsOverview: {
      type: String,
      required: true,
    },
    unscheduledJobCount: {
      type: Number,
      required: true,
    },
  },

  computed: {
    unscheduledJobsText() {
      const jobText = this.unscheduledJobCount !== 1 ? 'jobs' : 'job'

      return `${this.unscheduledJobCount} ${jobText} to schedule`
    },
  },
}
</script>
