// calculates the icon color: white or black, based on the lightness
// of the background circle color.  Fill colors must be in the full
// 6 character hexadecimal for this to work properly
export function calculateIconColor(fill: string) {
  const hex = fill.replace('#', '')
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  const a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255

  return a < 0.47 ? '#3c3d3f' : '#FFFFFF'
}
