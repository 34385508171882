
import { defineComponent, PropType } from 'vue'

import moment from 'moment'
import Day from '@/components/weekly-schedule/calendar/Day.vue'
import { WeekDays } from '@/components/weekly-schedule/utils'
import CalendarGrid from '@/components/weekly-schedule/CalendarGrid.vue'

export default defineComponent({
  name: 'Week',

  components: {
    CalendarGrid,
    Day,
  },

  props: {
    first: { type: Boolean, default: false },
    last: { type: Boolean, default: false },
    weekDays: {
      type: Array as unknown as PropType<WeekDays>,
      required: true,
    },
  },

  computed: {
    weekStart() {
      return this.weekDays[0].date
    },
  },

  methods: {
    onDrop(date: moment.Moment) {
      this.$emit('drop', date)
    },
  },
})
