import {
  Details as DetailsCell,
  EndTime,
  JobStatus as JobStatusCell,
  Priority as PriorityCell,
  StartTime,
  Tags,
} from '@/components/jobs/cells'
import Batch from '@/components/jobs/cells/Batch.vue'
import Division from '@/components/jobs/cells/Division.vue'
import {
  formatDateTime,
  formatDurationAsHours,
} from '@/utils/date-formatting.js'
import moment from 'moment/moment'
import { Component, computed, ComputedRef, reactive } from 'vue'
import {
  createNamespacedHelpers,
  useGetters,
  useStore,
} from 'vuex-composition-helpers'
import { GetterTree } from 'vuex'
import { priorityMap } from '@/utils/constants'
import { snakeCase } from 'lodash'

export type JobListItem = {
  id: string
  number: number
  status:
    | 'Requested'
    | 'AssignedToServiceProvider'
    | 'Scheduled'
    | 'Active'
    | 'Paused'
    | 'Completed'
    | 'Cancelled'
  approval_status: 'Approved' | 'Pending Approval' | 'No Approval' | null
  priority: 'New' | 'Normal' | 'Deferred' | 'High' | 'Filler'
  created_by_user_sid: string
  created: string
  modified: string
  hub_id: string
  owner_id: string
  requester_user_sid: string
  destination_id: number
  requested_start: string
  requested_end: string
  active_time: string | null
  completed_time: string | null
  cost_center: string
  details: string
  activity_id: number
  resource_type_id: number
  resource_type_activity_id: number
  inventory_requirements: {
    inventory_type_id: number
    inventory_sub_type_id: number
    name: string
    pickup_location_id: number | null
    quantity: number
    position: number
  }[]
  project_id: number | null
  assigned_service_provider_id: string
  assigned_resource_id: number
  followers: string[]
  tags: string[]
  operators: string[]
  extension_data: Record<string, any>
  invoice_id: string | null
  invoice_number: string | null
}

interface RootGetters extends GetterTree<any, any> {
  'companies/getByOrganizationId': () => (
    orgId: string
  ) => { name: string } | null
  'locations/getById': () => (locationId: number) => { location: string } | null
  'users/getBySid': () => (userSid: string) => { name: string } | null
  'resourceTypes/getById': () => (
    resourceTypeId: number
  ) => { name: string } | null
  'unitNumbers/getById': () => (resourceId: number) => { name: string } | null
  'projects/getById': () => (projectId: string) => {
    name: string
    number: number
    displayName: string
  } | null
  'tasks/getById': () => (activityTypeId: number) => { name: string } | null
}

export type Formatter = (value: any, job: JobListItem) => any

export type HubKeyedFormatter = { [hubId: string]: Formatter | undefined }
export type HubKeyedComponent = { [hubId: string]: Component | undefined }

export type Column = {
  selector: string
  text: string
  value: string
}

export type ColumnHeader =
  | (Column & {
      isCustom?: false
      formatter?: Formatter
      component?: Component
    })
  | (Column & {
      isCustom: true
      formatter: HubKeyedFormatter
      component: HubKeyedComponent
    })

export function useOrderedJobColumns(): {
  headers: ComputedRef<ColumnHeader[]>
} {
  const {
    'companies/getByOrganizationId': getOrganizationById,
    'users/getBySid': getUserBySid,
    'resourceTypes/getById': getResourceTypeById,
    'tasks/getById': getActivityById,
    'unitNumbers/getById': getResourceById,
    'projects/getById': getProjectById,
  } = reactive(
    useGetters<RootGetters>([
      'companies/getByOrganizationId',
      'users/getBySid',
      'resourceTypes/getById',
      'unitNumbers/getById',
      'projects/getById',
      'tasks/getById',
    ])
  )
  const store = useStore()
  const canViewBatchColumnInJobsList = computed(
    () => store.getters['permissions/canViewBatchColumnInJobsList']
  )

  const columns: ColumnHeader[] = [
    {
      selector: 'priority',
      text: 'Priority',
      value: 'priority',
      component: PriorityCell,
      formatter: (value: 'New' | 'Normal' | 'Deferred' | 'High' | 'Filler') =>
        priorityMap[value],
    },
    {
      selector: 'id',
      text: '#',
      value: 'number',
    },
    {
      selector: 'status',
      text: 'Job Status',
      value: 'status',
      component: JobStatusCell,
    },
    {
      selector: 'resourceType',
      text: 'Resource Type',
      value: 'resource_type_id',
      formatter: (value: number) =>
        getResourceTypeById(value)?.name || 'Unknown',
    },
    {
      selector: 'activity',
      text: 'Activity',
      value: 'activity_id',
      formatter: (value: number) => getActivityById(value)?.name,
    },
    {
      selector: 'destinationLocation',
      text: 'Destination',
      value: 'destination_name',
    },
    {
      selector: 'unit',
      text: 'Resource',
      value: 'assigned_resource_id',
      formatter: (value: number) =>
        value ? getResourceById(value)?.name : null,
    },
    {
      selector: 'serviceProvider',
      text: 'Service Provider',
      value: 'assigned_service_provider_id',
      formatter: (value: string) =>
        value ? getOrganizationById(value)?.name : null,
    },
    {
      selector: 'operator',
      text: 'Operator',
      value: 'operators[*]',
      formatter: (value: string) => getUserBySid(value)?.name,
    },
    {
      selector: 'details',
      text: 'Details',
      value: 'details',
      component: DetailsCell,
    },
    {
      selector: 'created',
      text: 'Submitted',
      value: 'created',
      formatter: (created: string) => formatDateTime(created),
    },
    {
      selector: 'modified',
      text: 'Last updated',
      value: 'modified',
      formatter: (modified: string) => formatDateTime(modified),
    },
    {
      selector: 'createdBy',
      text: 'Created By',
      value: 'created_by_user_sid',
      formatter: (value: string) => getUserBySid(value)?.name,
    },
    {
      selector: 'startTime',
      text: 'Start',
      value: 'requested_start',
      component: StartTime,
      formatter: (requestedStart: string) => formatDateTime(requestedStart),
    },
    {
      selector: 'endTime',
      text: 'End',
      value: 'requested_end',
      component: EndTime,
      formatter: (requestedEnd: string) => formatDateTime(requestedEnd),
    },
    {
      selector: 'pickupLocations',
      text: 'Pick Up Location',
      value: 'inventory_requirements[*].pickup_location_name',
    },
    {
      selector: 'pickupLocationItems',
      text: 'Item',
      value: 'inventory_requirements[*].name',
    },
    {
      selector: 'inventory',
      text: 'Qty',
      value: 'inventory_requirements[*].quantity',
    },
    {
      selector: 'customer',
      text: 'Requester',
      value: 'requester_user_sid',
      formatter: (value: string) => getUserBySid(value)?.name,
    },
    { selector: 'costCenter', text: 'Cost Center', value: 'cost_center' },
    {
      selector: 'division',
      text: 'Division',
      value: 'owner_id',
      component: Division,
    },
    {
      selector: 'approvalStatus',
      text: 'Approval Status',
      value: 'approval_status',
      formatter: (value: string | null) => {
        return value ? value.replace(/(?!^)([A-Z])/g, ' $1') : null
      },
    },
    {
      selector: 'project',
      text: 'Project',
      value: 'project_id',
      formatter: (value: string | null) =>
        value ? getProjectById(value)?.displayName : null,
    },
    {
      selector: 'batchNumber',
      text: 'Batch #',
      value: 'invoice_number',
      component: Batch,
    },
    { selector: 'tags', text: 'Tags', value: 'tags[*]', component: Tags },
    {
      selector: 'actualActiveDuration',
      text: 'Active Duration',
      value: 'active_duration',
      formatter: (duration: string | null, job: { status: string }) =>
        formatDurationAsHours(
          moment.duration(duration),
          job.status === 'Completed'
        ),
    },
    {
      selector: 'activeTime',
      text: 'Activated',
      value: 'active_time',
      formatter: (value: string | null) =>
        value ? formatDateTime(value) : null,
    },
    {
      selector: 'completedTime',
      text: 'Completed',
      value: 'completed_time',
      formatter: (value: string | null) =>
        value ? formatDateTime(value) : null,
    },
  ]
  const { useState: useJobsListState, useGetters: useJobsListGetters } =
    createNamespacedHelpers('jobsList')
  const { columnOrdering, visibleColumns } = useJobsListState([
    'columnOrdering',
    'visibleColumns',
  ])
  const {
    getHubSpecificCustomFieldColumns: customFields,
    getFeatureFieldColumns: featureFields,
  } = useJobsListGetters([
    'getHubSpecificCustomFieldColumns',
    'getFeatureFieldColumns',
  ])

  const headers = computed(() => {
    const unorderedColumns = visibleColumns.value.filter(
      (vc: any) => !columnOrdering.value.includes(vc)
    )
    const orderedVisibleColumns = columnOrdering.value.filter((o: any) =>
      visibleColumns.value.includes(o)
    )
    let columnList = orderedVisibleColumns.concat(unorderedColumns)
    if (!canViewBatchColumnInJobsList.value) {
      columnList = columnList.filter((c) => c !== 'batchNumber')
    }

    let featureHeaders: ColumnHeader[] = []
    if (Object.keys(featureFields.value).length > 0) {
      featureHeaders = Object.entries<{ text: string; value: string }>(
        featureFields.value
      ).map(([key, column]) => ({
        ...column,
        selector: column.value,
        value: snakeCase(key),
      }))
    }

    const customHeaders = Object.values<any>(customFields.value).map((cf) => ({
      ...cf,
      selector: cf.value,
      value: `extension_data.${cf.value}`,
      isCustom: true,
    }))

    const allHeaders = columns.concat(featureHeaders).concat(customHeaders)

    return columnList
      .map((o: string) => allHeaders.find((c) => (c.selector ?? c.value) === o))
      .filter(Boolean)
  })

  return {
    headers,
  }
}
