import { getCurrentInstance } from 'vue'

function isSmallScreen() {
  switch (this.$vuetify.breakpoint.name) {
    case 'xs':
      return true
    case 'sm':
      return true
    default:
      return false
  }
}

function isSmallScreenComposition() {
  const vm = getCurrentInstance()
  const vuetify = vm.proxy?.$vuetify || undefined

  switch (vuetify?.breakpoint.name) {
    case 'xs':
      return true
    case 'sm':
      return true
    default:
      return false
  }
}

export { isSmallScreen, isSmallScreenComposition }
