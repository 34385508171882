<template>
  <bottom-sheet-layout title="Assign to..." @on-dismiss="dismiss">
    <template #top-bar-ext>
      <vs-wrapper>
        <vs-text-input
          v-model="searchValue"
          append-icon="search"
          class="ma-0 pt-3 pb-0"
          hide-mini-label
          label="Companies, teams, or users"
        >
          <template #append>
            <v-icon>search</v-icon>
          </template>
        </vs-text-input>
      </vs-wrapper>
    </template>
    <business-unit-menu
      :items="getSubjectsWithoutDivisions"
      type="embedded"
      label="Companies, teams, or users"
      :filter-value="searchValue"
      @click="itemSelected"
    />
  </bottom-sheet-layout>
</template>
<script>
import BottomSheetLayout from '@/components/common/BottomSheetLayout'
import BusinessUnitMenu from '@/components/forms/BusinessUnitMenu'
import FormSubmission from '@/models/form_submission'
import BusinessUnitMenuItemsMixin from '@/components/forms/business-unit-menu-items-mixin'

export default {
  name: 'AssignFormSubmissionTo',
  components: {
    BottomSheetLayout,
    BusinessUnitMenu,
  },
  mixins: [BusinessUnitMenuItemsMixin],
  props: {
    businessUnitId: { type: String, default: null },
  },
  data() {
    return {
      searchValue: '',
    }
  },
  methods: {
    dismiss() {
      this.$emit('dismiss')
    },
    itemSelected(subject) {
      if (subject.userId !== null) {
        this.$emit(
          'assignee-selected',
          subject.userId,
          FormSubmission.ASSIGNMENT_ENTITY_TYPE_USER
        )
      } else if (subject.businessUnitId !== null) {
        this.$emit(
          'assignee-selected',
          subject.businessUnitId,
          FormSubmission.ASSIGNMENT_ENTITY_TYPE_BUSINESS_UNIT
        )
      }
    },
  },
}
</script>

<style scoped></style>
