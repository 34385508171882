import axios from 'axios'
import { get, patch, post } from './base'
import {
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/vue-query'
import moment from 'moment/moment'
import LocationDTO from '@/models/location'
import {
  PaginatedRequest,
  SearchLocationDTO,
} from '@/models/search-location-dto'
import { convertToCamelCase } from '@/models/utils/casing'
import { computed, ref, Ref, unref, watch } from 'vue'
import { useDebounceFn } from '@vueuse/core'
import { LocationStatus } from '@/models/location_status'

const locationsEndPoint = '/api/v3/locations'
const colorsEndpoint = '/api/v2/colors'

export function getById(id: string | number, signal?: AbortSignal) {
  const locationsEndpointSource = axios.CancelToken.source()

  const location = get<LocationDTO>(`${locationsEndPoint}/${id}`, {
    cancelToken: locationsEndpointSource.token,
  }).then((res) =>
    convertToCamelCase(res.data, [{ key: 'custom_data', onlyDeep: true }])
  )

  signal?.addEventListener('abort', () => {
    locationsEndpointSource.cancel('Query was cancelled by TanStack Query')
  })

  return location
}

// A wrapper around getById that caches the data by using Tanstack query
export function fetchLocationById(
  queryClient: QueryClient | undefined = undefined,
  id: string | number
) {
  const client = queryClient ?? useQueryClient()
  return client.fetchQuery({
    queryKey: ['location', id],
    queryFn: ({ signal }) => getById(id, signal),
    staleTime: moment.duration(10, 'minutes').asMilliseconds(),
  })
}

type MaybeRef<T> = Ref<T> | T

export function useLocationQuery(
  locationId?: MaybeRef<string | number | null | undefined>
) {
  const computedId = computed(() => unref(locationId))
  const queryKey = computed(() => ['location', computedId.value])
  const enabled = computed(() => {
    return computedId.value !== null && computedId.value !== undefined
  })

  const query = useQuery({
    queryKey,
    queryFn: ({ signal }) => getById(computedId.value!, signal),
    staleTime: moment.duration(10, 'minutes').asMilliseconds(),
    enabled,
  })

  return query
}

export interface SearchLocationsOptions {
  searchText?: string | null
  locationTypeId?: number | null
  parentId?: number | null
  sort?: { key: string; order: 'ASC' | 'DESC' }[] | null
  modifiedSince?: Date | null
  hubId?: string | null
  includeOwnedLocations?: boolean | null
}

interface SearchLocationsPaginationOptions {
  offset?: number
  limit?: number
}

const initialPageParam = { limit: 50, offset: 0 }

export function useSearchLocationsQuery(options: Ref<SearchLocationsOptions>) {
  const { queryKey } = useDebouncedQueryKeyBuilder(options)

  const query = useInfiniteQuery({
    queryKey,
    queryFn: ({ signal, pageParam }) => {
      return searchLocations(
        unref(options),
        pageParam || initialPageParam,
        signal
      )
    },
    keepPreviousData: true,
    getNextPageParam: (previousPage) => {
      if (previousPage.meta.currentPage < previousPage.meta.totalPages) {
        return {
          limit: previousPage.meta.pageSize,
          offset: previousPage.meta.currentPage * previousPage.meta.pageSize,
        }
      }
    },
    // We want this query to never go stale, so it never re-fetches.
    // Otherwise we risk some UI jank if the query returns data from the cache,
    // but refetches in the background. If the locations on the server have
    // changed, location items may get bumped around in the list. The
    // user also may have paginated a hundred times, and we don't want to
    // re-execute all of those requests if we dont have to.
    //
    // The cache will be cleared by default 5 minutes after all components
    // using this query unmount.
    staleTime: Infinity,
    refetchOnMount: false,
  })

  return query
}

/**
 * So far I've found the best way to debounce queries run by tanstack is to
 * debounce the key. The query key changing is what causes the refetch.
 * We need a little more sophistication however in that we only want
 * to debounce the user typing
 */
function useDebouncedQueryKeyBuilder(options: Ref<SearchLocationsOptions>) {
  const queryKey = ref<any[]>(['locations', unref(options)])

  const updateQueryKey = (newOptionsValue: SearchLocationsOptions) => {
    queryKey.value = ['locations', newOptionsValue]
  }

  const debouncedUpdate = useDebounceFn(updateQueryKey, 200)

  watch(options, (newOptions, oldOptions) => {
    // We only want to debounce when typing in the searchText, i.e., when the searchText is changed
    // However, there's an exception to this: If we click a location to navigate to its children's view,
    // the searchText is clear, but we don't want to debounce in this case
    if (
      newOptions.searchText !== oldOptions.searchText &&
      newOptions.parentId === oldOptions.parentId
    ) {
      debouncedUpdate(newOptions)
    } else {
      updateQueryKey(newOptions)
    }
  })

  return { queryKey }
}

function searchLocations(
  options: SearchLocationsOptions,
  paginationOptions: SearchLocationsPaginationOptions,
  signal?: AbortSignal
) {
  const source = axios.CancelToken.source()

  const queryParams = new URLSearchParams()

  if (options.searchText) {
    queryParams.append('search', options.searchText)
  }

  if (options.locationTypeId) {
    queryParams.append('locationTypeId', options.locationTypeId.toString())
  }

  if (options.parentId) {
    queryParams.append('parentLocationId', options.parentId.toString())
  }

  if (options.modifiedSince) {
    queryParams.append('limit', options.modifiedSince.toISOString())
  }

  if (options.hubId) {
    queryParams.append('hubId', options.hubId)
  }

  if (options.includeOwnedLocations) {
    queryParams.append('includeOwnedLocations', true.toString())
  }

  if (options.sort) {
    for (let i = 0; i < options.sort.length; i++) {
      queryParams.append('sortBy', options.sort[i].key)
      queryParams.append(
        'sortDesc',
        options.sort[i].order === 'DESC' ? 'true' : 'false'
      )
    }
  }

  if (paginationOptions.offset) {
    queryParams.append('offset', paginationOptions.offset.toString())
  }

  if (paginationOptions.limit) {
    queryParams.append('limit', paginationOptions.limit.toString())
  }

  const locations = get<PaginatedRequest<SearchLocationDTO>>(
    `${locationsEndPoint}/search?${queryParams.toString()}`,
    {
      cancelToken: source.token,
    }
  ).then((response) => convertToCamelCase(response.data))

  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled by TanStack Query')
  })

  return locations
}

export function getColors(signal?: AbortSignal) {
  const colorsEndpointSource = axios.CancelToken.source()

  const colors = get<LocationStatus[]>(colorsEndpoint, {
    cancelToken: colorsEndpointSource.token,
  }).then((response) => convertToCamelCase(response.data))

  signal?.addEventListener('abort', () => {
    colorsEndpointSource.cancel('Query was cancelled by TanStack Query')
  })
  return colors
}

export function useGetLocationStatusesQuery() {
  const queryKey = computed(() => ['location-statuses'])
  return useQuery({
    queryKey,
    queryFn: ({ signal }) => getColors(signal),
    staleTime: moment.duration(1, 'day').asMilliseconds(),
    cacheTime: Infinity,
  })
}

export function createNewColorRequest(color) {
  const colorsEndpointSource = axios.CancelToken.source()

  return post(colorsEndpoint, {
    cancelToken: colorsEndpointSource.token,
    data: {
      hex_code: color.hexCode,
      name: color.name,
    },
  })
}

export function useCreateLocationStatus() {
  return useMutation({
    mutationFn: ({ status }: { status: Partial<LocationStatus> }) =>
      createNewColorRequest(status),
  })
}

export function getLocationImportOptions(signal?: AbortSignal) {
  const source = axios.CancelToken.source()

  const request = get<{ value: string; label: string }[]>(
    `${locationsEndPoint}/importOptions`,
    {
      cancelToken: source.token,
    }
  )

  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled by TanStack Query')
  })

  return request
}

export function grantLocationToHub(locationId: number, hubId: string) {
  // If the location is already granted to this hub, then this API call will return 200 and do nothing
  const source = axios.CancelToken.source()
  const queryParams = new URLSearchParams()
  queryParams.append('locationId', locationId.toString())
  queryParams.append('hubId', hubId.toString())
  return patch(`${locationsEndPoint}/GrantToHub?${queryParams.toString()}`, {
    cancelToken: source.token,
  })
}

export function useGrantLocationToHub() {
  return useMutation({
    mutationFn: (variables: { locationId: number; hubId: string }) =>
      grantLocationToHub(variables.locationId, variables.hubId),
  })
}
