import { fill } from 'lodash'
import { resolveFormDSL } from '@/utils/forms/domain-specific-language/form-grammar-parser'
import { isValid } from '@/utils/forms/form-validate'
import {
  getSchemaType,
  isObject,
  mergeSchemas,
} from '@/utils/forms/form-resolver'

export class ContextualizedFormResolver {
  constructor(formContext) {
    this.formContext = formContext
  }

  /**
   * Calculates the default form data on an empty form. Should pre-populate deeply nested dependencies and objects as well
   * as primitive fields.
   *
   * @param {Object} schema - The current schema being evaluated
   * @param {Object} rootSchema - The root schema
   * @param {Object} parentDefaults - The defaults of the parent object
   * @param {Object} formFieldMemory - The recently submitted fields with memory
   * @returns {Object} - The default form data
   */
  getDefaultFormData(schema, rootSchema, parentDefaults, formFieldMemory) {
    let defaults = parentDefaults

    if (isObject(defaults) && isObject(schema.default)) {
      defaults = mergeSchemas(defaults, schema.default)
    } else if (schema.memory && formFieldMemory) {
      defaults = formFieldMemory
    } else if ('default' in schema) {
      defaults = schema.default
    } else if ('$ref' in schema) {
      const refSchema = ContextualizedFormResolver.#findSchemaDefinition(
        schema.$ref,
        rootSchema
      )
      return this.getDefaultFormData(
        refSchema,
        rootSchema,
        defaults,
        formFieldMemory
      )
    } else if ('dependencies' in schema) {
      const resolvedSchema = this.#resolveDependencies(
        schema,
        rootSchema,
        defaults
      )
      return this.getDefaultFormData(
        resolvedSchema,
        rootSchema,
        defaults,
        formFieldMemory
      )
    } else if ('dynamicDependencies' in schema) {
      const resolvedSchema = this.#resolveDynamicDependencies(
        schema,
        rootSchema
      )
      return this.getDefaultFormData(
        resolvedSchema,
        rootSchema,
        defaults,
        formFieldMemory
      )
    }

    switch (getSchemaType(schema)) {
      case 'object':
        return Object.keys(schema.properties || {}).reduce((data, key) => {
          const defaultObjectData = this.getDefaultFormData(
            schema.properties[key],
            rootSchema,
            (defaults || {})[key],
            (formFieldMemory || {})[key]
          )
          if (defaultObjectData !== undefined) {
            data[key] = defaultObjectData
          }
          return data
        }, {})
      case 'array':
        if (Array.isArray(defaults)) {
          defaults = defaults.map((item, index) => {
            return this.getDefaultFormData(
              schema.items,
              rootSchema,
              (defaults || {})[index],
              (formFieldMemory || {})[index]
            )
          })
        }
        if (schema.minItems) {
          const defaultsLength = defaults ? defaults.length : 0
          if (schema.minItems > defaultsLength) {
            const defaultEntries = defaults || []
            const fillerSchema = schema.items
            const fillerEntries = fill(
              new Array(schema.minItems - defaultsLength),
              this.getDefaultFormData(
                fillerSchema,
                rootSchema,
                fillerSchema.defaults,
                formFieldMemory
              )
            )

            return defaultEntries.concat(fillerEntries)
          } else {
            return defaults || []
          }
        }
    }

    return defaults
  }

  /**
   * Attempts to resolve the given schema based on root schema and form data
   * @param {Object} schema - The current schema
   * @param {Object} rootSchema - The root schema
   * @param {Object} formData - Data for the current schema
   * @returns {Object} - The resolved schema if possible. Empty object if the schema is not an object
   */
  retrieveSchema(schema, rootSchema, formData) {
    if (!isObject(schema)) {
      return {}
    }

    return this.#resolveSchema(schema, rootSchema, formData)
  }

  #resolveSchema(schema, rootSchema, formData) {
    if ('$ref' in schema) {
      return this.#resolveReference(schema, rootSchema, formData)
    } else if ('dependencies' in schema) {
      const resolvedSchema = this.#resolveDependencies(
        schema,
        rootSchema,
        formData
      )
      return this.retrieveSchema(resolvedSchema, rootSchema, formData)
    } else if ('dynamicDependencies' in schema) {
      const resolvedSchema = this.#resolveDynamicDependencies(
        schema,
        rootSchema
      )
      return this.retrieveSchema(resolvedSchema, rootSchema, formData)
    } else if ('allOf' in schema) {
      return {
        ...schema,
        allOf: schema.allOf.map((allOfSubSchema) =>
          this.retrieveSchema(allOfSubSchema, rootSchema, formData)
        ),
      }
    } else {
      return schema
    }
  }

  #resolveReference(schema, rootSchema, formData) {
    const refSchema = ContextualizedFormResolver.#findSchemaDefinition(
      schema.$ref,
      rootSchema
    )
    const { $ref, ...localSchema } = schema

    return this.retrieveSchema(
      { ...refSchema, ...localSchema },
      rootSchema,
      formData
    )
  }

  static #findSchemaDefinition(ref, rootSchema) {
    const definitionKey = ref.substring(ref.lastIndexOf('/') + 1)
    if (!rootSchema.definitions || !(definitionKey in rootSchema.definitions))
      throw new Error(
        `Definitions does not contain schema for key ${definitionKey}`
      )
    return rootSchema.definitions[definitionKey]
  }

  #resolveDependencies(schema, rootSchema, formData) {
    let { dependencies = {}, ...resolvedSchema } = schema
    if ('oneOf' in resolvedSchema) {
      resolvedSchema =
        resolvedSchema.oneOf[
          ContextualizedFormResolver.#getMatchingOption(
            resolvedSchema.oneOf,
            rootSchema,
            formData
          )
        ]
    } else if ('anyOf' in resolvedSchema) {
      resolvedSchema =
        resolvedSchema.anyOf[
          ContextualizedFormResolver.#getMatchingOption(
            resolvedSchema.anyOf,
            rootSchema,
            formData
          )
        ]
    }

    return this.#processDependencies(
      dependencies,
      resolvedSchema,
      rootSchema,
      formData
    )
  }

  #resolveDynamicDependencies(schema, rootSchema) {
    let { dynamicDependencies = [], ...resolvedSchema } = schema
    for (const dynamicDependency of dynamicDependencies) {
      const { version = 1, dynamicStatement, ...dependency } = dynamicDependency

      const processedDynamicDependency = resolveFormDSL(
        dynamicStatement,
        version,
        this.formContext
      )

      const combinedDependency = {
        oneOf: [mergeSchemas(processedDynamicDependency.schema, dependency)],
      }

      resolvedSchema = this.#withDependentSchema(
        resolvedSchema,
        rootSchema,
        'dynamicComparator',
        combinedDependency,
        processedDynamicDependency.data
      )
    }

    return resolvedSchema
  }

  static #getMatchingOption(options, rootSchema, formData) {
    for (let i = 0; i < options.length; i++) {
      const option = options[i]
      let augmentedSchema

      if ('properties' in option) {
        const requiresAnyOf = {
          anyOf: Object.keys(option.properties).map((key) => ({
            required: [key],
          })),
        }

        if ('anyOf' in option) {
          const { ...shallowClone } = option

          if (!('allOf' in shallowClone)) {
            shallowClone.allOf = []
          } else {
            shallowClone.allOf = shallowClone.allOf.slice()
          }

          shallowClone.allOf.push(requiresAnyOf)
          augmentedSchema = shallowClone
        } else {
          augmentedSchema = { ...option, ...requiresAnyOf }
        }

        delete augmentedSchema.required

        if (isValid(augmentedSchema, formData)) {
          return i
        }
      } else if (isValid(option, formData)) {
        return i
      }
    }
  }

  #processDependencies(dependencies, resolvedSchema, rootSchema, formData) {
    for (const dependencyKey in dependencies) {
      if (formData && formData[dependencyKey] === undefined) {
        continue
      }
      if (
        resolvedSchema.properties &&
        dependencyKey in resolvedSchema.properties
      ) {
        const { [dependencyKey]: dependencyValue, ...remainingDependencies } =
          dependencies
        if (Array.isArray(dependencyValue)) {
          resolvedSchema = ContextualizedFormResolver.#withDependentProperties(
            resolvedSchema,
            dependencyValue
          )
        } else if (isObject(dependencyValue)) {
          resolvedSchema = this.#withDependentSchema(
            resolvedSchema,
            rootSchema,
            dependencyKey,
            dependencyValue,
            formData
          )
        }
        return this.#processDependencies(
          remainingDependencies,
          resolvedSchema,
          rootSchema,
          formData
        )
      }
    }
    return resolvedSchema
  }

  static #withDependentProperties(schema, dependencyValue) {
    if (!dependencyValue) {
      return schema
    }
    const required = Array.isArray(schema.required)
      ? Array.from(new Set([...schema.required, ...dependencyValue]))
      : dependencyValue
    return { ...schema, required }
  }

  #withDependentSchema(
    schema,
    rootSchema,
    dependencyKey,
    dependencyValue,
    formData
  ) {
    const { oneOf, ...dependentSchema } = this.retrieveSchema(
      dependencyValue,
      rootSchema,
      formData
    )

    schema = mergeSchemas(schema, dependentSchema)

    if (oneOf === undefined) {
      return schema
    } else if (Array.isArray(oneOf)) {
      const resolvedOneOf = oneOf.map((subSchema) =>
        '$ref' in subSchema
          ? this.#resolveReference(subSchema, rootSchema, formData)
          : subSchema
      )

      return this.#withExactlyOneSubSchema(
        schema,
        rootSchema,
        dependencyKey,
        resolvedOneOf,
        formData
      )
    }

    throw new Error(`oneOf is ${typeof oneOf} instead of Array`)
  }

  #withExactlyOneSubSchema(schema, rootSchema, dependencyKey, oneOf, formData) {
    const validSubSchemas = oneOf.filter((subSchema) => {
      if (!subSchema.properties) {
        return false
      }
      const { [dependencyKey]: conditionPropertySchema } = subSchema.properties
      if (conditionPropertySchema) {
        const conditionSchema = {
          type: 'object',
          properties: {
            [dependencyKey]: conditionPropertySchema,
          },
        }
        return isValid(conditionSchema, formData)
      }
      return false
    })

    if (validSubSchemas.length !== 1) {
      return schema
    }

    const subSchema = validSubSchemas[0]
    const { [dependencyKey]: conditionPropertySchema, ...dependentSubSchema } =
      subSchema.properties
    const dependentSchema = { ...subSchema, properties: dependentSubSchema }
    return mergeSchemas(
      schema,
      this.retrieveSchema(dependentSchema, rootSchema, formData)
    )
  }
}
