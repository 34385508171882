import { computed } from 'vue'
import {
  ACTIVE,
  ASSIGNED_TO_SERVICE_PROVIDER,
  CANCELLED,
  COMPLETED,
  PAUSED,
  REQUESTED,
  SCHEDULED,
} from '@/models/job'

export function useJobStatuses() {
  return computed(() => [
    {
      id: REQUESTED,
      name: 'Requested',
    },
    {
      id: ASSIGNED_TO_SERVICE_PROVIDER,
      name: 'Assigned To Service Provider',
    },
    {
      id: SCHEDULED,
      name: 'Scheduled',
    },
    {
      id: ACTIVE,
      name: 'Active',
    },
    {
      id: PAUSED,
      name: 'Paused',
    },
    {
      id: COMPLETED,
      name: 'Completed',
    },
    {
      id: CANCELLED,
      name: 'Cancelled',
    },
  ])
}
