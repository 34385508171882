"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.numberFieldMatcher = void 0;
const number_field_definition_1 = require("../../form_fields/number_field_definition");
const json_schema_keys_1 = require("../../json_schema_keys");
const numberFieldMatcher = (uniqueKey, isRequired, properties, uiSchema, fieldDefinitions, next) => {
    const isDataTypeNumber = properties[json_schema_keys_1.JsonSchemaKeys.TYPE_KEY] === json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_NUMBER;
    const isDataTypeInt = properties[json_schema_keys_1.JsonSchemaKeys.TYPE_KEY] === json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_INTEGER;
    const hasNoSpecifiedWidget = !uiSchema[json_schema_keys_1.JsonSchemaKeys.UI_WIDGET_KEY];
    if (hasNoSpecifiedWidget && (isDataTypeNumber || isDataTypeInt)) {
        const field = new number_field_definition_1.NumberFieldDefinition();
        field.title = properties.title;
        field.description = properties.description;
        field.isRequired = isRequired;
        field.maximumValue = properties[json_schema_keys_1.JsonSchemaKeys.MAXIMUM_KEY];
        field.minimumValue = properties[json_schema_keys_1.JsonSchemaKeys.MINIMUM_KEY];
        field.allowDecimals = isDataTypeNumber;
        field.defaultValue = properties.default;
        return field;
    }
    return next(uniqueKey, isRequired, properties, uiSchema, fieldDefinitions);
};
exports.numberFieldMatcher = numberFieldMatcher;
