<template>
  <v-list-item ripple @click="channelClicked">
    <v-list-item-content>
      <vs-text truncate>
        {{ channelTitle }}
      </vs-text>
      <vs-text type="subdued" truncate>
        {{ channelSubtitle }}
      </vs-text>
    </v-list-item-content>

    <v-list-item-action v-if="hasUnread">
      <UnreadCount :unread-count="unreadCount" />
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapGetters } from 'vuex'
import { parseGroupId } from '../../utils/chat'
import UnreadCount from './UnreadCount'

export default {
  name: 'ChannelInfo',
  components: {
    UnreadCount,
  },
  props: {
    channel: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      getUnitById: 'unitNumbers/getById',
      allUnits: 'unitNumbers/getVisibleUnits',
      unitsLoading: 'unitNumbers/getLoading',
      getUserBySid: 'users/getBySid',
      users: 'users/getVisibleUsers',
      usersLoading: 'users/getLoading',
    }),
    channelTitle() {
      if (!this.channel.documentId) {
        return
      }
      const docInfo = parseGroupId(this.channel.documentId)
      if (docInfo.type === 'unit') {
        if (this.unitsLoading && Object.keys(this.allUnits).length === 0) {
          return 'Loading Channel...'
        }
        if (this.getUnitById(docInfo.id)) {
          return this.getUnitById(docInfo.id).name
        }
      }

      return this.participantNames
    },
    channelSubtitle() {
      if (this.channel.unsubscribed) {
        return 'Click to join the chat'
      } else if (!this.channel.recent) {
        return 'No messages'
      } else {
        return this.channel.recent.message
      }
    },
    participantNames() {
      if (this.usersLoading && this.users.length === 0) {
        return 'Loading Channel...'
      }
      return this.channel.participants
        .map((p) => (this.getUserBySid(p) ? this.getUserBySid(p).name : null))
        .filter((n) => n)
        .join(', ')
    },
    unreadCount() {
      return this.channel.unreadCount > 99 ? '99+' : this.channel.unreadCount
    },
    hasUnread() {
      return this.channel.unreadCount && this.channel.unreadCount > 0
    },
  },
  methods: {
    channelClicked() {
      this.$emit('channel-clicked', {
        documentId: this.channel.documentId,
        group: this.channelTitle,
        unsubscribed: !!this.channel.unsubscribed,
      })
    },
  },
}
</script>

<style scoped>
.channel-sub {
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
  height: 21px;
}
</style>
