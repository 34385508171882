"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UiOptions = exports.CreateableSelectorUiSchema = void 0;
const json_schema_keys_1 = require("../../json_schema_keys");
const field_ui_schema_1 = require("./field_ui_schema");
class CreateableSelectorUiSchema extends field_ui_schema_1.FieldUiSchema {
    set uiOptions(options) {
        this[json_schema_keys_1.JsonSchemaKeys.UI_OPTIONS_KEY] = options;
    }
    constructor(field) {
        super();
        this.uiOptions = new UiOptions(field.selectableOptions);
        this.widgetType = json_schema_keys_1.JsonSchemaKeys.WIDGET_TYPE_CREATABLE_SELECTOR;
    }
}
exports.CreateableSelectorUiSchema = CreateableSelectorUiSchema;
class UiOptions {
    constructor(choices) {
        this.choices = choices;
    }
}
exports.UiOptions = UiOptions;
