"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConstGoToFlowDTO = void 0;
class ConstGoToFlowDTO {
    constructor(parentField, requiredValue, flowKey, conditionalType) {
        this.parentField = parentField;
        this.requiredValue = requiredValue;
        this.flowKey = flowKey;
        this.conditionalType = conditionalType;
    }
}
exports.ConstGoToFlowDTO = ConstGoToFlowDTO;
