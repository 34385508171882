
import { computed, defineComponent, toRef } from 'vue'
import { useUserQuery } from '@/api/users'

export default defineComponent({
  name: 'PersonName',

  props: {
    sid: {
      type: String,
      required: true,
    },
    tag: {
      type: String,
      default: 'span',
    },
  },

  setup(props) {
    const sid = toRef(props, 'sid')

    const { data } = useUserQuery(sid)

    const name = computed(() => data.value?.name)

    return {
      name,
    }
  },
})
