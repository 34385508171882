<template>
  <div class="navigator">
    <v-row dense align="center">
      <v-col v-show="date !== today()" class="py-0">
        <v-fab-transition>
          <vs-button icon="home" small type="secondary" @click="homeClicked" />
        </v-fab-transition>
      </v-col>
      <v-col class="py-0">
        <vs-button
          icon="keyboard_arrow_left"
          small
          type="secondary"
          @click="leftArrowClicked"
        />
      </v-col>
      <v-col class="py-0">
        <vs-button
          icon="keyboard_arrow_right"
          small
          type="secondary"
          @click="rightArrowClicked"
        />
      </v-col>
      <v-col class="py-0">
        <v-menu
          v-model="menu"
          offset-y
          :close-on-content-click="false"
          :close-on-click="false"
          :nudge-bottom="8"
        >
          <template #activator="{ on }">
            <vs-button
              icon="event"
              small
              type="secondary"
              v-on="on"
              @click="menu = !menu"
            />
          </template>
          <v-date-picker :value="date" @change="dateSelected" />
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    range: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      menu: false,
    }
  },
  computed: {
    date() {
      return moment(this.range.start)
        .add(this.numHoursShown / 2, 'hours')
        .format('YYYY-MM-DD')
    },
    numHoursShown() {
      return moment(this.range.end).diff(this.range.start, 'hours')
    },
  },
  methods: {
    today() {
      return moment().format('YYYY-MM-DD')
    },
    homeClicked() {
      this.dateSelected(this.today())
    },
    leftArrowClicked() {
      this.setRange({
        start: moment(this.range.start).startOf('hour').subtract(12, 'hours'),
        end: moment(this.range.end).startOf('hour').subtract(12, 'hours'),
      })
    },
    rightArrowClicked() {
      this.setRange({
        start: moment(this.range.start).startOf('hour').add(12, 'hours'),
        end: moment(this.range.end).startOf('hour').add(12, 'hours'),
      })
    },
    dateSelected(dateStr) {
      setTimeout(() => {
        this.menu = false
      }, 250)

      this.setRange({
        start: moment(dateStr, 'YYYY-MM-DD').hour(6),
        end: moment(dateStr, 'YYYY-MM-DD').hour(18),
      })
    },
    setRange(range) {
      this.$emit('rangechanged', range)
    },
  },
}
</script>

<style scoped>
.navigator {
  position: absolute;
  right: 16px;
  top: 24px;
  z-index: var(--elevation-schedule-view-navigator);
  background-color: var(--color-white);
  padding: var(--space-small);
  border-radius: var(--space-larger);
  box-shadow: var(--shadow-low);
}
</style>
