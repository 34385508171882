import { getJobEventsQuery } from '@/api/jobs'
import { computed } from 'vue'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import moment from 'moment'
import JobHistory from '@/models/job-history'
import {
  isItemReceivedAtLocationEvent,
  isLocationVisitedEvent,
  isTriggerThresholdResolvedEvent,
} from '@/models/job-event'

export function useFormattedJobEvents(jobId: string) {
  const { data: jobEvents } = getJobEventsQuery(jobId)

  const { displayLocationVisitedEvents } = createNamespacedHelpers(
    'featureFlags'
  ).useGetters(['displayLocationVisitedEvents'])

  const formattedJobEvents = computed<JobHistory[]>(() => {
    if (!jobEvents.value) return []

    return jobEvents.value
      .map((jobEvent) => {
        if (
          isLocationVisitedEvent(jobEvent) &&
          displayLocationVisitedEvents.value
        ) {
          return new JobHistory({
            type: 'location_visited',
            modified: moment(jobEvent.timestamp),
            modified_by: jobEvent.data?.recorded_by_user_sid,
            fields_changed: [
              {
                column_name: 'location_visited',
                original_value: jobEvent.data?.resource_id,
                new_value: jobEvent.data?.location_id,
                is_important: true,
              },
            ],
          })
        } else if (isTriggerThresholdResolvedEvent(jobEvent)) {
          return new JobHistory({
            type: 'trigger_threshold_resolved',
            modified: moment(jobEvent.timestamp),
            modified_by: jobEvent.data?.source_id,
            fields_changed: [
              {
                column_name: 'trigger_threshold_resolved',
                original_value: jobEvent.data,
                new_value: null,
                is_important: true,
              },
            ],
          })
        } else if (isItemReceivedAtLocationEvent(jobEvent)) {
          return new JobHistory({
            type: 'item_received_at_location',
            modified: moment(jobEvent.timestamp),
            modified_by: jobEvent.data?.recorded_by_user_sid,
            fields_changed: [
              {
                column_name: 'item_received_at_location',
                original_value: jobEvent.data,
                new_value: null,
                is_important: true,
              },
            ],
          })
        }
        return null
      })
      .filter((e) => e !== null) as JobHistory[]
  })

  return {
    formattedJobEvents,
  }
}
