import moment from 'moment'

export const SET_RANGE = 'SET_RANGE'
export const SET_SHOW_JOBS_TABLE = 'SET_SHOW_JOBS_TABLE'

const state = () => {
  return {
    range: {
      start: moment().subtract(4, 'hours'),
      end: moment().add(4, 'hours'),
    },
    showJobQueue: true,
  }
}

export const getters = {}

export const actions = {
  fetchJobsForDayRange({ state, dispatch }) {
    dispatch(
      'jobs/refreshJobs',
      {
        start: state.range.start.clone().startOf('day').subtract(1, 'day'),
        end: state.range.end.clone().endOf('day').add(1, 'day'),
      },
      { root: true }
    )
  },
}

const mutations = {
  [SET_RANGE](state, { start, end }) {
    state.range = { start, end }
  },
  [SET_SHOW_JOBS_TABLE](state, show) {
    state.showJobQueue = show
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
