"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubjectFieldDefinition = void 0;
const json_schema_keys_1 = require("../json_schema_keys");
const lookup_property_description_1 = require("../models/field_property_descriptions/lookup_property_description");
const lookup_entity_1 = require("../models/lookup_entity");
const lookup_field_definition_1 = require("./lookup_field_definition");
class SubjectFieldDefinition extends lookup_field_definition_1.LookupFieldDefinition {
    uniqueKey() {
        return json_schema_keys_1.JsonSchemaKeys.SUBJECT_FIELD_KEY;
    }
    constructor() {
        super(new lookup_entity_1.BusinessUnitLookup());
        this.isRequired = true;
    }
    propertyDescription() {
        return new lookup_property_description_1.LookupPropertyDescription(this);
    }
}
exports.SubjectFieldDefinition = SubjectFieldDefinition;
