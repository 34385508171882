import { getForms, GetFormsResponse } from '@/api/forms'
import Form, { FormState } from '@/models/form'

interface FormsState {
  all: Record<string, Form>
  loading: boolean
}

const state = (): FormsState => {
  return {
    all: {},
    loading: false,
  }
}

const CREATE_PREVIEW_FORM = 'CREATE_PREVIEW_FORM'
const SET_PREVIEW_STRUCTURE = 'SET_PREVIEW_STRUCTURE'

const getters = {
  getAll: (state: FormsState) => state.all,
  getAllVisible: (state: FormsState) =>
    Object.values(state.all).filter(
      (form) =>
        (!form.category?.hidden ?? true) && !(form.state !== FormState.Active)
    ),
  getHasAnyVisibleForms: (_, getters) => getters.getAllVisible.length > 0,
  getById: (state: FormsState) => (id: string) => state.all[id] || null,
  getLoading: (state: FormsState) => state.loading,
}

const actions = {
  async fetch({ commit }) {
    commit('LOADING_FORMS')

    let formsData = await getForms()
    formsData = formsData.data

    commit('RECEIVE_FORMS', formsData)
    commit('LOADED_FORMS')
  },
  createPreviewForm({ commit }) {
    commit(CREATE_PREVIEW_FORM)
  },
  updatePreviewFormStructure({ commit }, struct: GetFormsResponse) {
    commit(SET_PREVIEW_STRUCTURE, { struct })
  },
}

const mutations = {
  RECEIVE_FORMS(state: FormsState, data: GetFormsResponse[]) {
    const receivedForms = {}

    data.forEach((json) => {
      const form = new Form(json)
      receivedForms[form.id] = form
    })

    state.all = Object.freeze({ ...state.all, ...receivedForms })
  },
  LOADING_FORMS(state: FormsState) {
    state.loading = true
  },
  LOADED_FORMS(state: FormsState) {
    state.loading = false
  },
  [CREATE_PREVIEW_FORM](state: FormsState) {
    const builderForm = new Form({} as GetFormsResponse)
    builderForm.id = 'preview_form'
    builderForm.structure = {
      pages: [{}],
    }

    state.all = Object.freeze({ ...state.all, preview_form: builderForm })
  },

  [SET_PREVIEW_STRUCTURE](state: FormsState, { struct }) {
    state.all.preview_form.structure = struct
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
