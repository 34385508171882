import { mergeJsonSchemas } from '@/utils/json-schema-merger.js'
import { intersectionWith, isEqual } from 'lodash'
import {
  CustomFieldsSchema,
  CustomFieldsSchemaObject,
  CustomFieldsUiSchema,
  FormSchema,
  SchemaDefinitionsRecord,
  SchemaDependencyRecord,
  SchemaProperty,
  SchemaPropertyRecord,
  UiOnCloneSchema,
  UiSchemaFieldObject,
} from '@/models/types/custom-fields-schema'
import { CustomField, CustomFieldRecord } from '@/models/types/custom-field'
import { CustomData, CustomValue } from '@/models/types/custom-data'
import {
  resolveDependentFormDataForSubmission,
  resolveEmptyFormDataForSubmission,
} from '@/utils/forms/form-resolver'

type FieldDefinition = {
  type: string
  title: string
  format?: string
  lookup?: { $ref: string; entity: string }
  enum?: string[]
  description?: string
}

export type SimpleField = {
  key: string
  required: boolean
  definition: FieldDefinition
  uiSchema?: UiSchemaFieldObject
}

const KEY_UI_ORDER = 'ui:order'

/**
 * Pulls each field out of a form.
 * @param  {Object} form - The form structure
 * @return {Object} - An object containing all field keys and definitions/properties
 */
export function getFormFields(form: FormSchema): CustomFieldRecord {
  const formFields = {}

  // Pulls each field out of each page
  for (const page of form.pages) {
    extractFieldsFromSchema(page.schema, page.uiSchema ?? {}, formFields)
  }

  // Pulls each field out of each flow, which has its own pages
  if (form.flows) {
    for (const flow of Object.values(form.flows)) {
      for (const page of flow.pages) {
        extractFieldsFromSchema(page.schema, page.uiSchema ?? {}, formFields)
      }
    }
  }

  return formFields
}

/**
 * Pulls the independent fields, dependent fields, and the field order out of a list of
 * mergedSchemas.
 * @param   {Object} mergedSchemas - The deep clone/merged schemas, merged from most
 *   dependent to least
 * @returns {Object} customFields - The result from extractFieldsFromSchema - an object
 *   containing all all field keys and most items required for display
 */
export function getAllJobCustomFields(
  mergedSchemas: CustomFieldsSchema[]
): CustomFieldRecord {
  const customFields: CustomFieldRecord = {}

  for (const mergedSchema of Object.values(mergedSchemas)) {
    if (mergedSchema.schema && mergedSchema.schema.properties) {
      extractFieldsFromSchema(
        mergedSchema.schema,
        mergedSchema.uiSchema || {},
        customFields
      )
    }
  }

  return customFields
}

/**
 * Pulls a field definitions out of a schema which could contain properties and
 * definitions
 * @param {Object} schema - An object which houses properties and definitions of fields
 * @param {Object} uiSchema - current uiSchema
 * @param {Object} formFields - The current map of form fields
 */
export function extractFieldsFromSchema(
  schema: CustomFieldsSchemaObject,
  uiSchema: CustomFieldsUiSchema | undefined,
  formFields
): void {
  const extractedDependencies = extractFieldsFromDependencies(
    schema.dependencies ?? {}
  )
  const extractedDynamicDependencies = extractFieldsFromDynamicDependencies(
    schema.dynamicDependencies ?? []
  )
  const properties = {
    ...schema.properties,
    ...extractedDependencies,
    ...extractedDynamicDependencies,
  }
  extractFormProperties(
    properties,
    uiSchema ?? {},
    schema.definitions ?? {},
    formFields
  )
}

/**
 * Takes lists of schemas and merges them into a single list of schemas
 * @param   {Array[Object]} activitySchemas - List of activity schemas
 * @param   {Array[Object]} divisionSchemas - List of division schemas
 * @param   {Array[Object]} baseSchema - The tenant based schema
 * @returns {Array[Object]} - An array of merged schemas
 */

export function mergeListOfJsonSchemas(
  activitySchemas: CustomFieldsSchema[],
  divisionSchemas: CustomFieldsSchema[],
  baseSchema: CustomFieldsSchema
): CustomFieldsSchema[] {
  const mergedSchemas: CustomFieldsSchema[] = []
  for (const schema of divisionSchemas) {
    mergedSchemas.push(mergeJsonSchemas(schema, baseSchema))
  }
  for (const schema of activitySchemas) {
    mergedSchemas.push(mergeJsonSchemas(schema, baseSchema))
  }
  return mergedSchemas
}

/**
 * Pulls out and flattens fields from dependencies
 * @param   {Object} dependencies - An object containing dependencies
 * @returns {Object} - An object containing dependency keys and definitions
 */
function extractFieldsFromDependencies(
  dependencies: SchemaDependencyRecord
): CustomFieldRecord {
  let dependencyFields = {}

  for (const [dependencyKey, dependency] of Object.entries(dependencies)) {
    const dependencyOneOf = dependency.oneOf
    if (!Array.isArray(dependencyOneOf)) {
      continue
    }

    dependencyOneOf.forEach((oneOfDependency) => {
      const dependencyProperties = oneOfDependency.properties
      const nestedDependencies = oneOfDependency.dependencies ?? {}
      if (!nestedDependencies.isEmpty) {
        dependencyFields = {
          ...dependencyFields,
          ...extractFieldsFromDependencies(nestedDependencies),
        }
      }

      Object.keys(dependencyProperties)
        .filter((fieldKey) => fieldKey !== dependencyKey)
        .forEach((fieldKey) => {
          dependencyFields[fieldKey] = dependencyProperties[fieldKey]
        })
    })
  }

  return dependencyFields
}

/**
 * Pulls out and flattens fields from dynamic dependencies
 * @param {Array} dynamicDependencies - An array containing dynamic dependencies
 * @returns {Object} - An object containing dependency keys and definitions
 */
function extractFieldsFromDynamicDependencies(
  dynamicDependencies: SchemaDependencyRecord[]
): CustomFieldRecord {
  const dynamicDependencyFields = {}

  for (const dynamicDependency of dynamicDependencies) {
    const dynamicDependencyProperties = dynamicDependency.properties
    for (const [propertyKey, property] of Object.entries(
      dynamicDependencyProperties
    )) {
      dynamicDependencyFields[propertyKey] = property
    }
  }

  return dynamicDependencyFields
}

/**
 * Loops through a map of properties and assigns the flattened results to the
 * formFields object
 * @param {Object} properties - The JSON properties field
 * @param {Object} uiSchema - The ui schema
 * @param {Object} definitions - The definitions for any field references
 * @param {Object} formFields - The current map of form fields
 */
function extractFormProperties(
  properties: SchemaPropertyRecord,
  uiSchema: CustomFieldsUiSchema,
  definitions: SchemaDefinitionsRecord,
  formFields: CustomFieldRecord
): void {
  const uiOrder = uiSchema[KEY_UI_ORDER] ?? []
  for (const propertyKey of uiOrder) {
    if (!(propertyKey in properties)) {
      continue
    }
    extractFormProperty(
      propertyKey,
      properties[propertyKey],
      uiSchema[propertyKey] ?? {},
      definitions,
      formFields
    )
  }
  for (const [propertyKey, property] of Object.entries(properties)) {
    if (uiOrder.includes(propertyKey)) {
      continue
    }
    extractFormProperty(
      propertyKey,
      property,
      uiSchema[propertyKey] ?? {},
      definitions,
      formFields
    )
  }
}

/**
 * Assigns definitions into the formFields object that is passed in, and also returned
 * back
 * @param {String} propertyKey - The key of the field
 * @param {Object} property - The JSON representation of the field
 * @param {Object} uiSchema - The current uiSchema
 * @param {Object} definitions - References used to dereference fields
 * @param {Object} formFields - the current map of form fields
 */
function extractFormProperty(
  propertyKey: string,
  property: SchemaProperty,
  uiSchema: CustomFieldsUiSchema,
  definitions: SchemaDefinitionsRecord,
  formFields: CustomFieldRecord
): void {
  const fieldDefinition = dereferenceProperty(property, definitions)

  const title =
    fieldDefinition.title ??
    fieldDefinition.admin?.title ??
    fieldDefinition.items?.title ??
    ''

  const lookup = fieldDefinition.lookup ?? {}
  const memory = fieldDefinition.memory ?? false
  const format = fieldDefinition.format ?? ''
  const children = {}
  const dataType = fieldDefinition.type

  if (fieldDefinition.type === 'array') {
    const itemDependencies = extractFieldsFromDependencies(
      fieldDefinition.items?.dependencies ?? {}
    )
    const itemProperties = {
      ...(fieldDefinition.items?.properties ?? {}),
      ...itemDependencies,
    }
    extractFormProperties(
      itemProperties,
      uiSchema.items ?? {},
      definitions,
      children
    )
  }

  if (fieldDefinition.type === 'object') {
    const extractedDependencies = extractFieldsFromDependencies(
      fieldDefinition.dependencies ?? {}
    )
    const properties = {
      ...fieldDefinition.properties,
      ...extractedDependencies,
    }
    extractFormProperties(properties, uiSchema, definitions, children)
  }

  formFields[propertyKey] = {
    title,
    lookup,
    format,
    children,
    dataType,
    memory,
  } as CustomField
}

/**
 * Takes an array of schemas and returns any simple fields that are common among them
 * @param   {Object[]} schemas - The schemas to extract simple fields from
 * @param   {Object} definitions - A collection of references used to dereference fields
 * @returns {SimpleField[]} - An array containing simple fields valid for bulk updating
 */
export function getBulkUpdatableFields(
  schemas: CustomFieldsSchema[],
  definitions: SchemaDefinitionsRecord
): SimpleField[] {
  const simpleFieldGroups: SimpleField[][] = []

  for (const schema of schemas) {
    if (schema.schema) {
      simpleFieldGroups.push(
        extractSimpleFields(schema.schema, schema?.uiSchema ?? {}, definitions)
      )
    }
  }

  return extractCommonSimpleFields(simpleFieldGroups)
}

/**
 * Takes a schema and returns any root level fields that are not involved in
 * dependencies or have array, object or null types
 * @param   {Object} schema - The base schema to extract simple fields from
 * @param   {Object} uiSchema - The current UI schema
 * @param   {Object} definitions - A collection of property definitions to reference
 * @returns {SimpleField[]} - An array containing the simple fields for the given
 *   schema
 */
export function extractSimpleFields(
  schema: CustomFieldsSchemaObject,
  uiSchema: CustomFieldsUiSchema,
  definitions: SchemaDefinitionsRecord
): SimpleField[] {
  const simplifiedSchema: SimpleField[] = []

  if (!schema.properties) return []

  const fieldsWithDependentFields = schema?.dependencies
    ? Object.keys(schema.dependencies)
    : []

  for (const [propertyKey, property] of Object.entries(schema.properties)) {
    if (fieldsWithDependentFields.includes(propertyKey)) {
      continue
    }

    const fieldDefinition = dereferenceProperty(property, definitions)

    if (
      fieldDefinition.type !== 'array' &&
      fieldDefinition.type !== 'object' &&
      fieldDefinition.type !== 'null'
    ) {
      const uiSchemaForProperty = uiSchema[propertyKey]

      const simpleField = {
        key: propertyKey,
        required: schema.required?.includes(propertyKey) ?? false,
        definition: fieldDefinition,
        ...(uiSchemaForProperty && { uiSchema: uiSchemaForProperty }),
      }

      simplifiedSchema.push(simpleField)
    }
  }

  return simplifiedSchema
}

/**
 * Finds all the simple fields that are identical within every schema provided
 * @param   {SimpleField[][]} schemas - An array of custom field schemas
 * @returns {SimpleField[]} - An array containing the fields present within each schema
 */
export function extractCommonSimpleFields(
  schemas: SimpleField[][]
): SimpleField[] {
  if (schemas.length === 0) return []

  return schemas.reduce((acc, curr) => {
    return intersectionWith(acc, curr, isEqual)
  })
}

/**
 * Extracts a field definition, which might live at a reference such as
 * #/definitions/some_field
 * @param   {Object} property - The property of a field, which could be a reference
 * @param   {Object} definitions - The rest of the definitions which may be referred to
 * @returns {Object} The property or value that lives at the relevant reference
 */
export function dereferenceProperty(
  property: SchemaProperty,
  definitions: SchemaDefinitionsRecord
): SchemaProperty {
  const ref = property.$ref

  if (!ref) {
    return property
  }

  const splitRef = ref.split('/')
  const refKey = splitRef[splitRef.length - 1]
  const definition = { ...definitions[refKey], ...property }
  delete definition.$ref

  return definition
}

/**
 * Compares custom data to a mergedSchema and trims all keys that are not relevant to the schema
 * @param  {CustomFieldsSchema} mergedSchema - A custom fields schema
 * @param  {CustomData} customData - The custom data to be sanitized
 * @returns {CustomData} Custom data with irrelevant keys deleted
 */
export function sanitizeCustomDataForSchema(
  mergedSchema: CustomFieldsSchema,
  customData: CustomData
): CustomData {
  if (
    Object.keys(customData).length === 0 ||
    !mergedSchema ||
    !mergedSchema.schema
  ) {
    return customData
  }

  const schemaCustomFields: CustomFieldRecord = {}
  extractFieldsFromSchema(
    mergedSchema.schema,
    mergedSchema.uiSchema,
    schemaCustomFields
  )

  const sanitizedData: CustomData = {}
  Object.keys(customData).forEach((key) => {
    const keyIsValid = Object.prototype.hasOwnProperty.call(
      schemaCustomFields,
      key
    )
    if (keyIsValid) {
      sanitizedData[key] = customData[key]
    }
  })

  return sanitizedData
}

/**
 * Prepares custom data for submission by removing empty, extra or no longer valid
 * keys from the custom data.
 * @param rootSchema The root schema of the custom fields
 * @param customData The custom data for the custom fields
 * @return The resolved custom data
 */
export function prepareCustomDataForSubmission(
  rootSchema: CustomFieldsSchema,
  customData: CustomData
): CustomData {
  const customDataEmptyResolved = resolveEmptyFormDataForSubmission(customData)
  const customDataSanitized = sanitizeCustomDataForSchema(
    rootSchema,
    customDataEmptyResolved
  )

  return resolveDependentFormDataForSubmission(
    rootSchema.schema,
    customDataSanitized
  )
}

/**
 * Upon cloning a job, copying that job's user inputs for custom data may not be
 * correct. For example, the new job might require a user to check a box, and we
 * would not want that box to be checked off already during the cloning
 * operation.
 * @param uiOnCloneSchema A JSON schema containing job on-clone effects.
 * @param customData The `custom_data` as stored in our database.
 * @returns Custom data that has been processed for on-clone effects.
 */
export function applyCustomDataOnCloneEffects(
  uiOnCloneSchema: UiOnCloneSchema,
  customData: CustomData
): CustomData {
  if (
    Object.keys(customData).length === 0 ||
    !uiOnCloneSchema ||
    !uiOnCloneSchema.deleteInputs
  ) {
    return customData
  }

  const deleteInputs = uiOnCloneSchema.deleteInputs
  const cloneCustomData: CustomData = {}

  for (const [k, v] of Object.entries(customData)) {
    if (deleteInputs.includes(k)) {
      continue
    }

    const resetValue = deleteCustomInputsByFieldName(deleteInputs, v)

    if (resetValue != null) {
      cloneCustomData[k] = resetValue
    }
  }

  return cloneCustomData
}

/**
 * Recursively finds and deletes records, useful when cloning a job and custom
 * field inputs need to be reset.
 * @param fieldNames Case-sensitive.
 * @param customValue
 * @returns a `CustomValue` sans certain inputs, or `null`
 */
function deleteCustomInputsByFieldName(
  fieldNames: string[],
  customValue: CustomValue
): CustomValue | null {
  if (typeof customValue !== 'object') {
    // Not an object with key/value pairs, so can't have nested children.
    return customValue
  } else if (Array.isArray(customValue)) {
    const resetCustomValueArray: CustomValue = []

    customValue.forEach((v) => {
      if (typeof v !== 'object') {
        // Not an object with key/value pairs, so can't have nested children.
        resetCustomValueArray.push(v)
      } else {
        const result = deleteCustomInputsByFieldName(fieldNames, v)

        if (result !== null) {
          resetCustomValueArray.push(result)
        }
      }
    })

    if (resetCustomValueArray.length === 0) {
      return null
    } else {
      return resetCustomValueArray
    }
  } else {
    const resetCustomValueObject: CustomValue = {}

    for (const [k, v] of Object.entries(customValue)) {
      if (fieldNames.includes(k)) {
        continue
      }

      const theValue = deleteCustomInputsByFieldName(fieldNames, v)

      if (theValue !== null) {
        resetCustomValueObject[k] = theValue
      }
    }

    if (Object.keys(resetCustomValueObject).length === 0) {
      // Sentinel to terminate the recursion
      return null
    } else {
      return resetCustomValueObject
    }
  }
}
