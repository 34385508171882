"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultFieldPropertyDescription = exports.FieldPropertyDescription = void 0;
const form_field_definition_1 = require("../../form_fields/form_field_definition");
const json_schema_keys_1 = require("../../json_schema_keys");
const schema_object_1 = require("../schema_object");
class FieldPropertyDescription extends schema_object_1.SchemaObject {
}
exports.FieldPropertyDescription = FieldPropertyDescription;
class DefaultFieldPropertyDescription extends FieldPropertyDescription {
    set fieldTitle(title) {
        this[json_schema_keys_1.JsonSchemaKeys.TITLE_KEY] = title;
    }
    set fieldDescription(description) {
        this[json_schema_keys_1.JsonSchemaKeys.DESCRIPTION_KEY] = description;
    }
    set defaultValue(value) {
        this[json_schema_keys_1.JsonSchemaKeys.DEFAULT_VALUE_KEY] = value;
    }
    set dataType(value) {
        this[json_schema_keys_1.JsonSchemaKeys.TYPE_KEY] = value;
    }
    set dataFormat(value) {
        this[json_schema_keys_1.JsonSchemaKeys.FORMAT_KEY] = value;
    }
    constructor(field) {
        super();
        if (field.title != null) {
            this.fieldTitle = field.title;
        }
        if (field.description != null) {
            this.fieldDescription = field.description;
        }
        if (field.defaultValue != null) {
            this.defaultValue = field.defaultValue;
        }
        if (field instanceof form_field_definition_1.PrimitiveValueField) {
            this.dataType = field.dataType;
        }
        if (field instanceof form_field_definition_1.FormattedValueField) {
            this.dataFormat = field.format;
        }
    }
}
exports.DefaultFieldPropertyDescription = DefaultFieldPropertyDescription;
