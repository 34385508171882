"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dateFieldMatcher = void 0;
const date_field_definition_1 = require("../../form_fields/date_field_definition");
const json_schema_keys_1 = require("../../json_schema_keys");
const dateFieldMatcher = (uniqueKey, isRequired, properties, uiSchema, fieldDefinitions, next) => {
    const isDataTypeString = properties[json_schema_keys_1.JsonSchemaKeys.TYPE_KEY] === json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_STRING;
    const isDateFormat = properties[json_schema_keys_1.JsonSchemaKeys.FORMAT_KEY] === json_schema_keys_1.JsonSchemaKeys.DATA_FORMAT_DATE;
    const isDateTimeFormat = properties[json_schema_keys_1.JsonSchemaKeys.FORMAT_KEY] === json_schema_keys_1.JsonSchemaKeys.DATA_FORMAT_DATE_TIME;
    if (isDataTypeString && (isDateFormat || isDateTimeFormat)) {
        const field = new date_field_definition_1.DateFieldDefinition();
        field.title = properties.title;
        field.description = properties.description;
        field.isRequired = isRequired;
        field.allowTimeOfDaySelection = isDateTimeFormat;
        field.defaultValue = properties.default;
        return field;
    }
    return next(uniqueKey, isRequired, properties, uiSchema, fieldDefinitions);
};
exports.dateFieldMatcher = dateFieldMatcher;
