"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.genericSelectorFieldMatcher = void 0;
const generic_selector_definition_1 = require("../../form_fields/generic_selector_definition");
const json_schema_keys_1 = require("../../json_schema_keys");
const genericSelectorFieldMatcher = (uniqueKey, isRequired, properties, uiSchema, fieldDefinitions, next) => {
    const isSelectorWidget = uiSchema[json_schema_keys_1.JsonSchemaKeys.UI_WIDGET_KEY] === json_schema_keys_1.JsonSchemaKeys.WIDGET_TYPE_SELECTOR;
    const uiOptions = uiSchema[json_schema_keys_1.JsonSchemaKeys.UI_OPTIONS_KEY];
    if (isSelectorWidget && uiOptions) {
        const field = new generic_selector_definition_1.GenericSelectorDefinition();
        field.title = properties.title;
        field.description = properties.description;
        field.isRequired = isRequired;
        field.optionGroups = uiOptions[json_schema_keys_1.JsonSchemaKeys.GROUPS_KEY];
        field.defaultValue = properties.default;
        return field;
    }
    return next(uniqueKey, isRequired, properties, uiSchema, fieldDefinitions);
};
exports.genericSelectorFieldMatcher = genericSelectorFieldMatcher;
