"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormButtonsUiSchema = void 0;
const json_schema_keys_1 = require("../../json_schema_keys");
const field_ui_schema_1 = require("./field_ui_schema");
class FormButtonsUiSchema extends field_ui_schema_1.FieldUiSchema {
    constructor() {
        super();
        this.widgetType = json_schema_keys_1.JsonSchemaKeys.WIDGET_TYPE_FORM_BUTTONS;
    }
}
exports.FormButtonsUiSchema = FormButtonsUiSchema;
