<template>
  <v-btn-toggle
    v-model="selected"
    :dense="dense"
    rounded
    :mandatory="mandatory"
    multiple
    color="blue"
  >
    <v-btn
      v-for="priority in priorities"
      :key="priority.id"
      :data-test="`priority-${priority.name}`"
      :value="priority.id"
      :min-width="0"
      :max-height="34"
      text
    >
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-icon :color="priority.color" :size="20" v-on="on">
            {{ priority.icon }}
          </v-icon>
        </template>

        <span>{{ priority.name }}</span>
      </v-tooltip>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
import { priorities } from '../../utils/constants'

export default {
  name: 'PriorityFilter',
  props: {
    selectedPriorities: {
      type: Array,
      default: null,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    mandatory: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      priorities,
    }
  },
  computed: {
    selected: {
      get() {
        return this.selectedPriorities
      },
      set(newArray) {
        this.$emit('on-change', newArray)
      },
    },
  },
}
</script>

<style></style>
