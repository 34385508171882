
import { defineComponent } from 'vue'
import { Prop } from 'vue/types/options'
import { priorityMap } from '@/utils/constants'
import JobPriorityDisplay from '@/components/jobs/fields/JobPriorityDisplay.vue'
import JobStatusChip from '@/components/tickets/JobStatusChip.vue'
import { JobStatus } from '@/models/job'

type PriorityId = typeof priorityMap[keyof typeof priorityMap]['id']

export default defineComponent({
  name: 'JobHeaderLabel',
  components: { JobStatusChip, JobPriorityDisplay },

  props: {
    job: {
      type: Object as Prop<{ priority: PriorityId; status: string }>,
      default: () => ({
        priority: priorityMap.Normal.id,
        status: JobStatus.DRAFT,
      }),
    },
  },
})
