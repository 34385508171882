<template>
  <div v-if="errorFetchingExtensionSchema">
    <vs-alert type="warn">Failed to load activity fields</vs-alert>
    <vs-button
      label="Try again"
      type="tertiary"
      @click="retryFetchingExtensionSchema"
    />
  </div>
  <div v-else>
    <vs-heading type="overline" class="pb-2">
      Loading activity fields...
    </vs-heading>
    <v-progress-linear
      class="ma-auto progress-center"
      :size="64"
      :width="6"
      indeterminate
      color="primary"
    />
  </div>
</template>

<script>
export default {
  name: 'LoadingActivityFields',
  props: {
    errorFetchingExtensionSchema: {
      type: Object,
      default: null,
    },
  },
  methods: {
    retryFetchingExtensionSchema() {
      this.$emit('retry')
    },
  },
}
</script>

<style scoped>
.progress-center {
  margin: auto;
}
</style>
