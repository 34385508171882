import { GetJobLineItemQueryResponse } from '@/api/jobs'

export class LineItem {
  constructor(json: GetJobLineItemQueryResponse) {
    this.id = json.id
    this.name = json.name
    this.rate = json.rate
    this.quantity = json.quantity
    this.created = json.created
    this.priceBookItemId = null
  }

  id: string
  name: string
  rate: number | null
  quantity: number | null
  created: Date
  priceBookItemId: string | null
}
