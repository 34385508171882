import BaseObject from './base_object'
import { Asset } from './asset'

export default class LocationInventory extends BaseObject {
  assets: { [key: number]: Asset }
  constructor(json) {
    super(json)
    this.assets = {}
    json.assets.forEach((asset) => {
      this.assets[asset.id] = new Asset(asset)
    })
  }

  hasAnyInventory() {
    return (
      Object.values(this.assets).filter((asset) => {
        return !asset.isEmpty()
      }).length > 0
    )
  }
}
