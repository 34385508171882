<template>
  <div
    ref="menu"
    class="autocomplete-menu"
    :class="{ [position]: position, [type]: type }"
    @scroll.passive="handleScroll"
  >
    <v-list data-test="menu-list" dense>
      <slot name="prepend-item" />
      <v-list-item
        v-for="(item, index) in filteredItems"
        :key="[index, item[itemValue]].join()"
        :class="{
          'list-item': true,
          disabled: item.disabled,
        }"
        data-test="list-item"
        @[clickable(item)].stop="handleClick(item[itemValue])"
      >
        <v-list-item-action v-if="itemValue in item && multiselect">
          <v-checkbox
            :input-value="checkboxValue"
            :value="item[itemValue]"
            :disabled="item.disabled"
          />
        </v-list-item-action>

        <v-divider v-if="'divider' in item" />
        <v-list-item-content v-else :class="{ indent: item.indent }">
          <vs-heading v-if="'header' in item" type="overline"
            >{{ item.header }}
          </vs-heading>
          <vs-text v-else>{{ item[itemText] }}</vs-text>
          <vs-text v-if="item.description" type="subdued"
            >{{ item.description }}
          </vs-text>
        </v-list-item-content>

        <v-list-item-action
          v-if="item.icon || item.color || item.chip || item.actionText"
        >
          <v-icon v-if="item.icon" :color="item.color">
            {{ item.icon }}
          </v-icon>
          <v-avatar v-else-if="item.color" :color="item.color" size="24" />
          <v-chip v-if="item.chip">{{ item.chip }}</v-chip>
          <vs-text v-if="item.actionText">{{ item.actionText }}</vs-text>
        </v-list-item-action>
      </v-list-item>

      <v-list-item
        v-if="filteredItems && filteredItems.length === 0 && !freeForm"
      >
        <v-list-item-content>
          <vs-text>
            <template v-if="filterValue">
              <template v-if="notFoundText"
                ><em>{{ notFoundText }}</em></template
              >
              <template v-else>
                <em>No results</em>
              </template>
            </template>
            <template v-else>
              <template v-if="emptyStateText">{{ emptyStateText }}</template>
              <template v-else>No {{ label }} found</template>
            </template>
          </vs-text>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-else-if="filteredItems && filteredItems.length === 0 && freeForm"
        class="list-item"
        data-test="create-list-item"
        @click.stop="handleClick(filterValue)"
      >
        <v-list-item-content>
          <vs-text>{{ filterValue }}</vs-text>
        </v-list-item-content>
      </v-list-item>

      <slot name="append-item" />
    </v-list>
  </div>
</template>

<script>
import { chunk } from 'lodash'
import VsText from '@/components/vision/VsText.vue'

export default {
  components: {
    VsText,
  },
  props: {
    /**
     * Determines which checkboxes are checked
     */
    checkboxValue: {
      type: Array[Number],
      default: null,
    },
    /**
     *  The list of options that the user can choose from.
     *
     * @param {Object} items Items object.
     * @param {String} items.text The name of the option.
     * @param {String} items.description The label of the option (subdued).
     * @param {Number} items.value The ID of the option.
     * @param {String} items.icon Adds an icon beside the label.
     * @param {String} items.color Adds a color on the icon. If there's no icon, it uses an avatar to visualize the color.
     * @param {Boolean} items.disabled Disables interaction
     * @param {Boolean} items.indent Adds indentation with joining line
     */
    items: {
      type: Array[Object],
      default: null,
      required: true,
    },
    /**
     * Change the property of item's text value
     */
    itemText: {
      type: String,
      default: 'text',
    },
    /**
     * Change the property of item's value
     */
    itemValue: {
      type: String,
      default: 'value',
    },
    /**
     * Change the default not found message
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * Allows the user to select multiple choices at once
     */
    multiselect: {
      type: Boolean,
      default: false,
    },
    /**
     * Change the default not found message
     */
    notFoundText: {
      type: String,
      default: null,
    },
    /**
     * Change the default empty message when there are no options to choose from
     */
    emptyStateText: {
      type: String,
      default: null,
    },
    /**
     * Determines where to put the menu
     */
    position: {
      type: String,
      default: null,
      validator: (value) => ['below', 'above'].includes(value),
    },
    /**
     * Filters the items in the menu
     */
    filterValue: {
      type: String,
      default: null,
    },
    /**
     * Allow clicking on search value to create when none found
     */
    freeForm: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
      validator: (value) => ['text', 'number'].includes(value),
    },
  },
  data() {
    return {
      filteredItems: null,
      chunkedItems: chunk(this.items, 15),
      chunkIndex: 0,
    }
  },
  watch: {
    filterValue(value) {
      if (value) {
        return this.filter()
      }
      this.resetChunk(this.items)
    },
    items(value) {
      value && this.resetChunk(value)
    },
  },
  created() {
    this.resetChunk(this.items)
  },
  methods: {
    handleClick(value) {
      this.$emit('click', value)
    },
    clickable(item) {
      return (this.itemValue in item && !item.disabled) || !this.checkboxValue
        ? 'click'
        : null
    },
    resetChunk(items) {
      this.chunkIndex = 0
      this.chunkedItems = chunk(items, 15)
      this.filteredItems = this.chunkedItems.length
        ? this.chunkedItems[this.chunkIndex]
        : []
    },
    matchWith(value) {
      if (!value) return false
      const criteria = value.replace(/\s/g, '').toLocaleLowerCase()
      const searchVal = this.filterValue.replace(/\s/g, '').toLocaleLowerCase()
      return criteria.indexOf(searchVal) > -1
    },
    filter() {
      const filtered = this.items.filter(
        (item) =>
          this.matchWith(item[this.itemText]) ||
          this.matchWith(item.description) ||
          this.matchWith(item.additionalKeywords)
      )
      this.resetChunk(filtered)
    },
    handleScroll() {
      const target = this.$refs.menu
      const scrollPosition = target.scrollTop + target.offsetHeight
      const bottom = scrollPosition >= target.scrollHeight - 40
      if (bottom && this.chunkedItems.length > this.chunkIndex + 1) {
        this.filteredItems.push(...this.chunkedItems[++this.chunkIndex])
      }
    },
  },
}
</script>

<style scoped>
.autocomplete-menu {
  max-height: 400px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: var(--space-base);
}

.number ::v-deep .list-item {
  text-align: right;
  align-items: flex-end;
}

.disabled {
  background-color: var(--color-silver);
}

.v-list-item__content.indent {
  border-left: var(--color-grey--lighter) solid 2px;
  margin-left: 5px;
  padding-left: 5px;
}
</style>
