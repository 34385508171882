import { getOrganizationConfig } from '../../api/config'

const LOADING = 'LOADING_CONFIG'
const RECEIVE_CONFIG = 'RECEIVE_CONFIG'
const ERROR = 'ERROR'

const ironsightHQ = {
  latitude: 53.5455488,
  longitude: -113.5018336,
  zoom: 10,
}

export enum TripMapDistanceUnits {
  MILES = 'miles',
  KILOMETERS = 'kilometers',
}

const state = {
  loading: false,
  error: null,
  mapCenterPoint: ironsightHQ,
  mapboxAccessToken: null,
  tripMapDistanceUnit: null,
  maxJobCsvCount: null,
}

export class OrganizationLoadException {
  name: string
  error: string

  constructor(error) {
    this.error = error
    this.name = 'OrganizationLoadException'
  }

  toString() {
    return `${this.name}: "${JSON.stringify(this.error, null, 2)}"`
  }
}

const actions = {
  async load({ commit }) {
    commit(LOADING)

    try {
      const res = await getOrganizationConfig()
      commit(RECEIVE_CONFIG, res.data)
    } catch (err: any) {
      commit(ERROR, err.toJSON())
      throw new OrganizationLoadException(err.toJSON())
    }
  },
}

const mutations = {
  [LOADING](state) {
    state.error = null
    state.loading = true
  },
  [RECEIVE_CONFIG](
    state,
    {
      map_center_point: mapCenterPoint,
      mapbox_access_token: mapboxAccessToken,
      trip_map_distance_unit: tripMapDistanceUnit,
      max_job_csv_count: maxJobCsvCount,
    }
  ) {
    state.mapCenterPoint = mapCenterPoint || ironsightHQ
    state.mapboxAccessToken = mapboxAccessToken
    state.tripMapDistanceUnit = tripMapDistanceUnit
    state.maxJobCsvCount = maxJobCsvCount
    state.loading = false
  },
  [ERROR](state, err: any) {
    state.error = err
    state.loading = false
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
