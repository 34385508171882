import { getCurrentInstance, inject, InjectionKey } from 'vue'

export function injectWithSelf<T>(
  symbol: InjectionKey<T>,
  defaultFactory?: () => T
): T {
  const vm = getCurrentInstance() as any

  return (
    vm?.provides?.[symbol as any] ??
    inject(symbol, undefined) ??
    defaultFactory?.()
  )
}
