import { get } from './base'
import { useQuery } from '@tanstack/vue-query'
import axios from 'axios'
import { convertToCamelCase } from '@/models/utils/casing'
import { JobStatus } from '@/models/job'

export type ResourceTypeFilterOption = {
  id: number
  name: string
}
export type DivisionOption = {
  id: string
  name: string
  hubName: string
}
export type ResourceTypeOption = {
  id: number
  name: string
  serviceName: string
}
export type ActivityOption = { id: number; name: string; hubName: string }
export type ServiceProviderOption = {
  id: string
  companyNumber: number
  name: string
}
export type ApprovalStatus = {
  id: string
  name: string
}

type JobFilterOptions = {
  jobFilterOptions: {
    divisionOptions: DivisionOption[]
    resourceTypeOptions: ResourceTypeOption[]
    activityOptions: ActivityOption[]
    serviceProviderOptions: ServiceProviderOption[]
    jobStatusOptions: JobStatus[]
    approvalOptions: ApprovalStatus[]
  }
}

export function getJobFilterOptions(signal?: AbortSignal) {
  const fieldViewFilterOptionsSource = axios.CancelToken.source()

  const filterOptions = get<JobFilterOptions>(`/api/v3/jobs/filter-options`, {
    cancelToken: fieldViewFilterOptionsSource.token,
  }).then((r) => convertToCamelCase(r.data))

  signal?.addEventListener('abort', () => {
    fieldViewFilterOptionsSource.cancel('Query was cancelled by TanStack Query')
  })

  return filterOptions
}

export function useGetJobFilterOptions() {
  return useQuery({
    queryKey: ['filter-options'],
    queryFn: ({ signal }) => getJobFilterOptions(signal),
  })
}
