import { ref } from 'vue'

export function useRemovableField(
  removeCallback: () => void,
  showInitially = false
) {
  const isVisible = ref<boolean>(showInitially)

  const handleRemove = () => {
    isVisible.value = false
    removeCallback()
  }

  return { isVisible, handleRemove }
}
