"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JsonSchemaKeys = void 0;
class JsonSchemaKeys {
}
exports.JsonSchemaKeys = JsonSchemaKeys;
JsonSchemaKeys.ROOT_SCHEMA_KEY = "#root_schema";
JsonSchemaKeys.SCHEMA_KEY = "schema";
JsonSchemaKeys.$SCHEMA_KEY = "$schema";
JsonSchemaKeys.UI_SCHEMA_KEY = "uiSchema";
JsonSchemaKeys.IS_SEARCHABLE_KEY = "isSearchable";
JsonSchemaKeys.LOOKUP_BUSINESS_UNIT_KEY = "business_unit";
JsonSchemaKeys.ADMIN_KEY = "admin";
JsonSchemaKeys.ALL_OF_KEY = "allOf";
JsonSchemaKeys.ANY_OF_KEY = "anyOf";
JsonSchemaKeys.BUTTONS_KEY = "buttons";
JsonSchemaKeys.CHOICES_KEY = "choices";
JsonSchemaKeys.CONST_KEY = "const";
JsonSchemaKeys.REQUIRED_FIELD_KEY = "requiredField";
JsonSchemaKeys.DESCRIPTION_KEY = "description";
JsonSchemaKeys.DEFINITIONS_KEY = "definitions";
JsonSchemaKeys.DEPENDENCIES_KEY = "dependencies";
JsonSchemaKeys.ENTITY_KEY = "entity";
JsonSchemaKeys.ENUM_KEY = "enum";
JsonSchemaKeys.EXCLUSIVE_MAXIMUM_KEY = "exclusiveMaximum";
JsonSchemaKeys.EXCLUSIVE_MINIMUM_KEY = "exclusiveMinimum";
JsonSchemaKeys.FIELDS_KEY = "fields";
JsonSchemaKeys.FORMAT_KEY = "format";
JsonSchemaKeys.GROUPS_KEY = "groups";
JsonSchemaKeys.ITEMS_KEY = "items";
JsonSchemaKeys.LABEL_KEY = "label";
JsonSchemaKeys.LOOKUP_KEY = "lookup";
JsonSchemaKeys.MAX_ITEMS_KEY = "maxItems";
JsonSchemaKeys.MAX_LENGTH_KEY = "maxLength";
JsonSchemaKeys.MIN_LENGTH_KEY = "minLength";
JsonSchemaKeys.MAXIMUM_KEY = "maximum";
JsonSchemaKeys.MARKDOWN_KEY = "markdown";
JsonSchemaKeys.MEDIA_KEY = "media";
JsonSchemaKeys.MIN_ITEMS_KEY = "minItems";
JsonSchemaKeys.MINIMUM_KEY = "minimum";
JsonSchemaKeys.NAME_KEY = "name";
JsonSchemaKeys.NOT_KEY = "not";
JsonSchemaKeys.OPTIONS_KEY = "options";
JsonSchemaKeys.ONE_OF_KEY = "oneOf";
JsonSchemaKeys.PAGE_KEY = "page";
JsonSchemaKeys.PAGES_KEY = "pages";
JsonSchemaKeys.PROPERTIES_KEY = "properties";
JsonSchemaKeys.REF_KEY = "$ref";
JsonSchemaKeys.IRONSIGHT_REF_KEY = "ironsight";
JsonSchemaKeys.REQUIRED_KEY = "required";
JsonSchemaKeys.REQUIRED_VALUES_KEY = "requiredValues";
JsonSchemaKeys.SOURCE_KEY = "src";
JsonSchemaKeys.SOURCE_SET_KEY = "srcset";
JsonSchemaKeys.SOURCES_KEY = "sources";
JsonSchemaKeys.SUBJECT_FIELD_KEY = "subject";
JsonSchemaKeys.SUBJECT_LOCATION_FIELD_KEY = "subject_location";
JsonSchemaKeys.TITLE_KEY = "title";
JsonSchemaKeys.TYPE_KEY = "type";
JsonSchemaKeys.UI_DESCRIPTION_KEY = "ui:description";
JsonSchemaKeys.UI_FIELD_KEY = "ui:field";
JsonSchemaKeys.UI_ORDER_KEY = "ui:order";
JsonSchemaKeys.UI_OPTIONS_KEY = "ui:options";
JsonSchemaKeys.UI_WIDGET_KEY = "ui:widget";
JsonSchemaKeys.VALUE_KEY = "value";
JsonSchemaKeys.DEFAULT_VALUE_KEY = "default";
JsonSchemaKeys.ACTION_TYPE_GO_TO_FLOW = "goToFlow";
JsonSchemaKeys.ACTION_TYPE_LAUNCH_DIALOG = "launchDialog";
JsonSchemaKeys.ACTION_TYPE_SUBMIT = "submit";
JsonSchemaKeys.ACTION_NAME_GO_TO_FLOW = "Go to Flow";
JsonSchemaKeys.DATA_FORMAT_DATE = "date";
JsonSchemaKeys.DATA_FORMAT_DATE_TIME = "date-time";
JsonSchemaKeys.DATA_FORMAT_DATA_URL = "data-url";
JsonSchemaKeys.DATA_FORMAT_POINT = "point";
JsonSchemaKeys.DATA_TYPE_ARRAY = "array";
JsonSchemaKeys.DATA_TYPE_BOOLEAN = "boolean";
JsonSchemaKeys.DATA_TYPE_NULL = "null";
JsonSchemaKeys.DATA_TYPE_INTEGER = "integer";
JsonSchemaKeys.DATA_TYPE_NUMBER = "number";
JsonSchemaKeys.DATA_TYPE_OBJECT = "object";
JsonSchemaKeys.DATA_TYPE_STRING = "string";
JsonSchemaKeys.LOOKUP_ENTITY_COMPANY = "company";
JsonSchemaKeys.LOOKUP_ENTITY_LOCATION = "location";
JsonSchemaKeys.LOOKUP_ENTITY_USER = "user";
JsonSchemaKeys.WIDGET_TYPE_SELECTOR = "genericSelector";
JsonSchemaKeys.WIDGET_TYPE_CREATABLE_SELECTOR = "creatableSelector";
JsonSchemaKeys.WIDGET_TYPE_LAT_LNG_SELECTOR = "latLngSelector";
JsonSchemaKeys.WIDGET_TYPE_FILE_SELECTOR = "fileSelector";
JsonSchemaKeys.WIDGET_TYPE_MARKDOWN = "Markdown";
JsonSchemaKeys.WIDGET_TYPE_HEADING = "Heading";
JsonSchemaKeys.WIDGET_TYPE_RADIO = "radio";
JsonSchemaKeys.WIDGET_TYPE_FORM_BUTTONS = "FormButtons";
JsonSchemaKeys.WIDGET_TYPE_TEXT_AREA = "textarea";
JsonSchemaKeys.WIDGET_TYPE_USER_SELECTOR = "UserSelector";
JsonSchemaKeys.WIDGET_TYPE_BUSINESS_UNIT_SELECTOR = "BusinessUnitSelectorField";
JsonSchemaKeys.WIDGET_TYPE_LOCATION_SELECTOR = "locationSelector";
JsonSchemaKeys.WIDGET_TYPE_COMPANY_SELECTOR = "companySelector";
JsonSchemaKeys.WIDGETS_VERSION = "widgets_version";
JsonSchemaKeys.SCHEMA_DRAFT_7_URL = "http://json-schema.org/draft-07/schema#";
