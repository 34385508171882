import axios from 'axios'
import { get } from './base'
import { Asset } from '@/models/asset'

let getSource = axios.CancelToken.source()

const endpoint = '/api/v2/assets'

export function getAll() {
  getSource.cancel('Cancel old get assets request')
  getSource = axios.CancelToken.source()

  return get<Asset[]>(endpoint, {
    cancelToken: getSource.token,
  })
}
