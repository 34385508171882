<template>
  <v-select
    v-model="selectedItemIdsModel"
    :label="label"
    :items="menuItems"
    :item-text="textField"
    :item-value="valueField"
    chips
    multiple
  >
    <template #prepend-item>
      <SelectAllForFilter
        :value="selectedItemIdsModel"
        :all-selections="selectableItems"
        :field-name="valueField"
        @input="handleAllToggled"
      />
    </template>
  </v-select>
</template>

<script>
import SelectAllForFilter from './SelectAllForFilter'

export default {
  name: 'TableFieldFilter',
  components: {
    SelectAllForFilter,
  },
  props: {
    label: {
      type: String,
      default: () => 'Values',
    },
    allItems: {
      type: Array,
      required: true,
    },
    selectedItems: {
      type: Array,
      required: true,
    },
    textField: {
      type: String,
      default: () => 'name',
    },
    valueField: {
      type: String,
      default: () => 'id',
    },
    emptyText: {
      type: String,
      default: () => 'Empty',
    },
  },
  computed: {
    menuItems() {
      return this.allItems
    },
    selectableItems() {
      return this.menuItems.filter(
        (item) => !('header' in item || 'divider' in item)
      )
    },
    selectedItemIdsModel: {
      get() {
        return this.selectedItems
      },
      set(newSelectedItemIds) {
        this.setSelectedIds({ ids: newSelectedItemIds })
      },
    },
  },
  methods: {
    setSelectedIds(data) {
      this.$emit('selected', data)
    },
    handleAllToggled(data) {
      this.selectedItemIdsModel = data
    },
  },
}
</script>
