<template>
  <v-container>
    <v-row>
      <v-col class="shrink">
        <v-icon>villa</v-icon>
      </v-col>
      <v-col>
        <vs-heading type="subhead">{{ name }}</vs-heading>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'CompanyGroup',

  props: {
    name: {
      type: String,
      required: true,
    },
  },
}
</script>
