<template>
  <span>
    <vs-text class="small">{{ title }}</vs-text>
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'SubmissionOverlineText',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>
.small {
  text-transform: none;
  color: var(--color-grey);
}
</style>
