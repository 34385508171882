"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConstDependencyEdge = void 0;
const conditions_1 = require("../models/dependencies/conditions");
const const_dependency_dto_1 = require("../models/dependencies/const_dependency_dto");
const edge_1 = require("./edge");
class ConstDependencyEdge extends edge_1.EdgeBase {
    constructor(source, target, dependentOn, requiredValue) {
        super();
        this.source = source;
        this.target = target;
        this.dependentOn = dependentOn;
        this.requiredValue = requiredValue;
    }
    condition() {
        return new conditions_1.ConstCondition(this.requiredValue);
    }
    asDTO() {
        return new const_dependency_dto_1.ConstDependencyDTO(this.dependentOn, this.requiredValue, this.target.fields);
    }
}
exports.ConstDependencyEdge = ConstDependencyEdge;
