export default class InventoryItem {
  assetConditionId: number | null = null
  quantity: number | null = null

  constructor(json) {
    if (!json) return
    this.assetConditionId = json.asset_condition_id
    this.quantity = json.quantity
  }
}
