import { getDownloadURL } from 'firebase/storage'
import Vue from 'vue'
import {
  deleteAttachment,
  getAttachments,
  postAttachment,
} from '../../api/attachments'
import { getInstance } from '../../auth'
import { Attachment } from '../../models/attachment'
import { AttachmentMetadata } from '../../models/attachment_metadata'
import { getHostName } from '../../utils/chat'
import { errorMutationHandler } from '../utils'

const state = () => {
  return {
    all: {},
    loading: false,
    error: null,
  }
}

export const getters = {
  getAllAttachmentsForStorageId: (state) => (id) => {
    return Object.values(state.all).filter(
      (attachment) => attachment.storageId === id
    )
  },
}

export const actions = {
  async fetchAttachments({ commit }, id) {
    commit('LOADING_ATTACHMENTS')

    try {
      const items = await getAttachments(id)
      commit('RECEIVE_ATTACHMENTS', items)
    } catch (err) {
      commit('ERROR_ATTACHMENTS', err)
    }
  },

  postAttachment({ commit }, { file, storageId }) {
    const metadata = buildMetadata(file)
    const uploadTask = postAttachment(storageId, file, metadata)
    const attachment = new Attachment(
      uploadTask.snapshot.ref.parent.name,
      uploadTask.snapshot.ref.name,
      uploadTask.snapshot.ref.url,
      metadata
    )

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        commit('UPLOADING_ATTACHMENT', {
          attachment,
          bytesTransferred: snapshot.bytesTransferred,
          totalBytes: snapshot.totalBytes,
        })
      },
      (err) => commit('ERROR_ATTACHMENTS', err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
          commit('UPLOADED_ATTACHMENT', {
            attachment,
            downloadUrl,
            metadata: new AttachmentMetadata(
              uploadTask.snapshot.metadata.customMetadata
            ),
          })
        })
      }
    )
  },

  async deleteAttachment({ commit }, { filename, storageId }) {
    try {
      await deleteAttachment(storageId, filename)
      commit('DELETE_ATTACHMENT', filename)
    } catch (err) {
      commit('ERROR_ATTACHMENTS', err)
    }
  },
}

export function buildMetadata(file) {
  return {
    customMetadata: {
      createdBy: getInstance().user.sub,
      tenant: getHostName(),
      timestamp: new Date().toISOString(),
      extension: file.name.split('.').pop(),
      contentType: file.type,
    },
  }
}

export const mutations = {
  LOADING_ATTACHMENTS(state) {
    state.loading = true
  },
  RECEIVE_ATTACHMENTS(state, items) {
    const receivedAttachments = {}

    items.forEach((json) => {
      receivedAttachments[json.name] = new Attachment(
        json.storageId,
        json.name,
        json.url,
        json.metadata && json.metadata.customMetadata
      )
    })

    state.all = receivedAttachments
    state.loading = false
    state.error = null
  },
  UPLOADING_ATTACHMENT(state, { attachment, bytesTransferred, totalBytes }) {
    const uploadingAttachment = { ...attachment, bytesTransferred, totalBytes }
    Vue.set(state.all, attachment.filename, uploadingAttachment)
  },
  UPLOADED_ATTACHMENT(state, { attachment, downloadUrl, metadata }) {
    const uploadedAttachment = { ...attachment, downloadUrl, metadata }
    Vue.set(state.all, attachment.filename, uploadedAttachment)
  },
  DELETE_ATTACHMENT(state, filename) {
    Vue.delete(state.all, filename)
  },
  ERROR_ATTACHMENTS(state, error) {
    errorMutationHandler(state, error)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
