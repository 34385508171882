
import { computed, defineComponent, toRefs } from 'vue'
import { formatTimeRange } from '@/utils/date-formatting.js'
import moment from 'moment'

export default defineComponent({
  name: 'DateRangeDisplay',

  props: {
    start: String,
    end: String,
  },

  setup(props) {
    const { start, end } = toRefs(props)

    const displayValue = computed(() => {
      return formatTimeRange(moment(start.value), moment(end.value), 'to')
    })

    return { displayValue }
  },
})
