import axios from 'axios'
import { get, post } from '@/api/base'
import { PaginatedRequest } from '@/models/search-location-dto'
import { Client } from '@/models/client'
import { convertToCamelCase } from '@/models/utils/casing'
import { MaybeRef } from '@vueuse/core'
import { computed, unref, UnwrapNestedRefs } from 'vue'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import moment from 'moment/moment'

const connectionRequestEndPoint = '/api/v2/connection-request'

export function createConnectionRequest(
  recipientBusinessUnitId: string,
  requesterBusinessUnitId: string
) {
  return post(`${connectionRequestEndPoint}/`, {
    data: {
      recipient_business_unit_id: recipientBusinessUnitId,
      requester_business_unit_id: requesterBusinessUnitId,
    },
  })
}

export function useCreateConnectionRequestMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (variables: {
      recipientBusinessUnitId: string
      requesterBusinessUnitId: string
    }) =>
      createConnectionRequest(
        variables.recipientBusinessUnitId,
        variables.requesterBusinessUnitId
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['connection-request', 'clients'])
    },
  })
}

interface SearchClientsOptions {
  offset?: number
  limit?: number
  query?: string
}

export function getClients(
  searchOptions?: SearchClientsOptions | null,
  signal?: AbortSignal
) {
  const source = axios.CancelToken.source()
  const hubs = get<PaginatedRequest<Client>>(
    `${connectionRequestEndPoint}/client-hubs`,
    {
      params: {
        offset: searchOptions?.offset,
        limit: searchOptions?.limit,
        query: searchOptions?.query,
      },
      cancelToken: source.token,
    }
  ).then((res) => convertToCamelCase(res.data))
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled by TanStack Query')
  })
  return hubs
}

export function useSearchClientsQuery(
  query: MaybeRef<UnwrapNestedRefs<SearchClientsOptions>>
) {
  const queryKey = computed(() => [
    'connection-request',
    'clients',
    unref(query),
  ])
  const {
    data,
    error,
    isLoading,
    isFetched,
    isFetching,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey,
    queryFn: ({ signal }) => getClients(unref(query), signal),
    refetchInterval: moment.duration(30, 'seconds').asMilliseconds(),
    retry: false,
    keepPreviousData: true,
    enabled: true,
  })

  const clients = computed(() => data.value?.data)

  const totalPages = computed<number | null | undefined>(() => {
    return data?.value?.meta?.totalPages
  })
  const totalItems = computed<number | null | undefined>(() => {
    return data?.value?.meta?.total
  })

  return {
    clients,
    totalPages,
    totalItems,
    error,
    isLoading,
    isFetched,
    isFetching,
    isRefetching,
    reFetch: refetch,
  }
}
