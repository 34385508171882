"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageNode = void 0;
const node_1 = require("./node");
class PageNode extends node_1.NodeBase {
    constructor() {
        super(...arguments);
        this.pageTitle = "";
        this.fieldOrder = [];
    }
    get uiOrder() {
        return this.fieldOrder.map((f) => f.uniqueKey());
    }
    reorderField(field, newIndex) {
        if (newIndex < 0 || newIndex > this.fieldOrder.length - 1) {
            throw new RangeError("Index is not in range for reordering");
        }
        const currentIndex = this.fieldOrder.indexOf(field);
        if (currentIndex === -1) {
            throw new Error(`Field ${field.uniqueKey()} does not exist on this page`);
        }
        this.fieldOrder.splice(currentIndex, 1);
        this.fieldOrder.splice(newIndex, 0, field);
    }
}
exports.PageNode = PageNode;
