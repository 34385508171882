export function getScrollParent(node) {
  if (node === null || node === document) {
    return document.body
  }

  const style = window.getComputedStyle(node)
  const hasScrollProperty =
    style.overflow === 'auto' || style.overflow === 'scroll'

  if (node.scrollHeight > node.clientHeight && hasScrollProperty) {
    return node
  } else {
    return getScrollParent(node.parentNode)
  }
}
