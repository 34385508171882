import { getInstance } from '@/auth'
import {
  deleteFormSubmissionFilter,
  getFormSubmissionFilters,
  postFormSubmissionFilter,
  putFormSubmissionFilter,
  searchFormSubmissionsCount,
} from '@/api/form_submissions'
import FormSubmissionSavedFilter, {
  defaultSavedFilterTemplates,
} from '@/models/form-submission-saved-filter'
import Vue from 'vue'
import { isEqual } from 'lodash'

export const SET_INVERSE_FORM_IDS = 'SET_INVERSE_FORM_IDS'
export const SET_ASIDE_VISIBILITY = 'SET_ASIDE_VISIBILITY'

export const SET_VISIBLE_PANEL = 'SET_VISIBLE_PANEL'

export const SET_DATE_RANGE = 'SET_DATE_RANGE'
export const RESET_INVERSE_FORM_IDS = 'RESET_INVERSE_FORM_IDS'
export const SET_INVERSE_FORM_ACTIONS = 'SET_INVERSE_FORM_ACTIONS'
export const RESET_INVERSE_FORM_ACTIONS = 'RESET_INVERSE_FORM_ACTIONS'
export const SET_TAGS = 'SET_TAGS'
export const RESET_TAGS = 'RESET_TAGS'
export const SET_SUBJECT_SEARCH_IDS = 'SET_SUBJECT_SEARCH_IDS'
export const RESET_SUBJECT_SEARCH_IDS = 'RESET_SUBJECT_SEARCH_IDS'
export const SET_SUBJECT_SEARCH_BUTTONS = 'SET_SUBJECT_SEARCH_BUTTONS'
export const SET_INVERSE_LOCATIONS = 'SET_INVERSE_LOCATIONS'
export const SET_LOADING_SAVED_FILTERS = 'SET_LOADING_SAVED_FILTERS'
export const SET_SAVED_FILTERS = 'SET_SAVED_FILTERS'
export const SET_SAVED_FILTER_COUNT = 'SET_SAVED_FILTER_COUNT'
export const SET_ACTIVE_SAVED_FILTER_ID = 'SET_ACTIVE_SAVED_FILTER_ID'
export const RESET_INVERSE_LOCATIONS = 'RESET_INVERSE_LOCATIONS'
export const ERROR_SAVING_FILTER = 'ERROR_SAVING_FILTER'
export const DELETED_SAVED_FILTER = 'DELETED_SAVED_FILTER'
export const EDIT_SAVED_FILTER = 'EDIT_SAVED_FILTER'
export const SET_SAVING_SAVED_FILTERS = 'SET_SAVING_SAVED_FILTERS'
export const SET_SAVED_FILTER = 'SET_SAVED_FILTER'

export const SUBJECT_BUTTON_SUBMITTER = 'Submitter'
export const SUBJECT_BUTTON_ASSIGNEE = 'Assignee'
export const SUBJECT_BUTTON_SUBJECT = 'Subject'

const state = () => {
  return {
    isAsideVisible: true,
    visiblePanels: { panel: null },
    inverseFormIds: [],
    inverseFormActions: [],
    tags: [],
    dateRange: {
      start: null,
      end: null,
    },
    subjectSearchIds: [],
    subjectSearchButtons: [],
    inverseLocations: [],
    loadingSavedFilters: false,
    savingFiltersError: null,
    savingSavedFilters: false,
    savedFilters: {},
    activeSavedFilterId: null,
  }
}

export const getters = {
  getActiveSavedFilterId: (state) => state.activeSavedFilterId,
  getInverseFormIds: (state) => state.inverseFormIds,
  getFilterAsideVisibility: (state) => state.isAsideVisible,
  getVisiblePanels: (state) => state.visiblePanels.panel,
  getDateRange: (state) => state.dateRange,
  getInverseFormActions: (state) => state.inverseFormActions,
  getInverseLocations: (state) => state.inverseLocations,
  getTags: (state) => state.tags,
  getSubjectSearchIds: (state) => state.subjectSearchIds,
  getLoadingSavedFilters: (state) => state.loadingSavedFilters,
  getSavingSavedFilters: (state) => state.savingSavedFilters,
  getAllSavedFilters: (state) => state.savedFilters,
  subjectSearchButtons: (state) => state.subjectSearchButtons,
  getAvailableSubjectButtons: (state) => {
    const defaultButtons = [
      { value: SUBJECT_BUTTON_SUBMITTER, name: 'Submitter' },
      { value: SUBJECT_BUTTON_ASSIGNEE, name: 'Assignee' },
      { value: SUBJECT_BUTTON_SUBJECT, name: 'Subject' },
    ]
    if (state.subjectSearchIds.length === 0) {
      return defaultButtons
    } else if (
      state.subjectSearchIds.some((subject) => subject.businessUnitId !== null)
    ) {
      return defaultButtons
    }
    defaultButtons.pop()
    return defaultButtons
  },
  makeFilterArguments: (state, getters) => {
    return {
      startDate: getters.getDateRange.start,
      endDate: getters.getDateRange.end,
      includeForms: getters.getInverseFormIds,
      includeActions: getters.getInverseFormActions,
      includeTags: getters.getTags,
      submittedBy: getters.subjectSearchButtons.includes(
        SUBJECT_BUTTON_SUBMITTER
      )
        ? getters.getSubjectSearchIds.map((s) => {
            return { user_id: s?.userId, business_unit_id: s?.businessUnitId }
          })
        : [],
      assignedTo: getters.subjectSearchButtons.includes(SUBJECT_BUTTON_ASSIGNEE)
        ? getters.getSubjectSearchIds.map((s) => {
            return { user_id: s?.userId, business_unit_id: s?.businessUnitId }
          })
        : [],
      subject: getters.subjectSearchButtons.includes(SUBJECT_BUTTON_SUBJECT)
        ? getters.getSubjectSearchIds
            .filter((s) => s.businessUnitId != null)
            .map((s) => s.businessUnitId)
        : [],
      includeLocations: getters.getInverseLocations,
    }
  },
}

export const actions = {
  checkForMatchingSavedFilter({ commit, state }) {
    for (const savedFilter of Object.values(state.savedFilters)) {
      if (
        isEqual(savedFilter.filter.tags, state.tags) &&
        isEqual(
          savedFilter.filter.inverseFormActions,
          state.inverseFormActions
        ) &&
        isEqual(savedFilter.filter.inverseFormIds, state.inverseFormIds) &&
        isEqual(savedFilter.filter.subjectSearchIds, state.subjectSearchIds) &&
        (savedFilter.filter.subjectSearchIds.length <= 0 ||
          isEqual(
            savedFilter.filter.subjectSearchButtons,
            state.subjectSearchButtons
          )) &&
        isEqual(savedFilter.filter.inverseLocations, state.inverseLocations)
      ) {
        commit(SET_ACTIVE_SAVED_FILTER_ID, savedFilter.id)
        return
      }
    }
    commit(SET_ACTIVE_SAVED_FILTER_ID, null)
  },
  async deleteFilter({ commit, getters }, id) {
    commit(SET_SAVING_SAVED_FILTERS)

    try {
      await deleteFormSubmissionFilter(id)

      if (getters.getActiveSavedFilterId === id)
        commit(SET_ACTIVE_SAVED_FILTER_ID, null)
      commit(DELETED_SAVED_FILTER, id)
    } catch (e) {
      commit(ERROR_SAVING_FILTER, e)
    }
  },
  async editFilterWithCurrentFilter({ commit, getters, rootGetters }, id) {
    commit(SET_SAVING_SAVED_FILTERS)

    const newFilter = {
      inverse_form_ids: getters.getInverseFormIds,
      inverse_form_actions: getters.getInverseFormActions,
      tags: getters.getTags,
      subject_search_ids: getters.getSubjectSearchIds.map(
        ({ userId, businessUnitId }) => {
          return { user_id: userId, business_unit_id: businessUnitId }
        }
      ),
      subject_search_buttons: getters.subjectSearchButtons,
      inverse_locations: getters.getInverseLocations,
    }

    try {
      await putFormSubmissionFilter(id, { filter: newFilter })
      const filter = new FormSubmissionSavedFilter({ filter: newFilter })
      const count = rootGetters['formSubmissions/getCount']

      commit(EDIT_SAVED_FILTER, {
        id,
        name: null,
        filter: filter.filter,
        count,
      })
      commit(SET_ACTIVE_SAVED_FILTER_ID, id)
    } catch (e) {
      commit(ERROR_SAVING_FILTER, e)
    }
  },
  async editFilterName({ commit }, { id, name }) {
    commit(SET_SAVING_SAVED_FILTERS)

    try {
      await putFormSubmissionFilter(id, { name })

      commit(EDIT_SAVED_FILTER, { id, name, filter: null, count: null })
    } catch (e) {
      commit(ERROR_SAVING_FILTER, e)
    }
  },
  async saveCurrentFilterSet({ commit, getters, rootGetters }, name) {
    commit(SET_SAVING_SAVED_FILTERS)

    const newFilter = {
      inverse_form_ids: getters.getInverseFormIds,
      inverse_form_actions: getters.getInverseFormActions,
      tags: getters.getTags,
      subject_search_ids: getters.getSubjectSearchIds.map(
        ({ userId, businessUnitId }) => {
          return { user_id: userId, business_unit_id: businessUnitId }
        }
      ),
      subject_search_buttons: getters.subjectSearchButtons,
      inverse_locations: getters.getInverseLocations,
    }

    try {
      const saveFilterResponse = await postFormSubmissionFilter({
        name,
        filter: newFilter,
      })

      const filter = new FormSubmissionSavedFilter(saveFilterResponse.data)
      const count = rootGetters['formSubmissions/getCount']

      commit(SET_SAVED_FILTER, { filter, count })
      commit(SET_ACTIVE_SAVED_FILTER_ID, filter.id)
    } catch (e) {
      commit(ERROR_SAVING_FILTER, e)
    }
  },
  async fetchAllSavedFilters({ commit }) {
    commit(SET_LOADING_SAVED_FILTERS)

    const authService = getInstance()
    const defaultFilters = defaultSavedFilterTemplates.map((template) => {
      return new FormSubmissionSavedFilter(template(authService.user.sub))
    })

    const savedFiltersResponse = await getFormSubmissionFilters()
    const savedFilters = savedFiltersResponse.data.map((savedFilter) => {
      return new FormSubmissionSavedFilter(savedFilter)
    })

    commit(SET_SAVED_FILTERS, [...defaultFilters, ...savedFilters])
  },
  async fetchAllSavedFilterCountsAsync({ commit, state }) {
    for (const [id, filter] of Object.entries(state.savedFilters)) {
      const { data } = await searchFormSubmissionsCount({
        startDate: null,
        endDate: null,
        includeForms: filter.filter.inverseFormIds,
        includeActions: filter.filter.inverseFormActions,
        includeTags: filter.filter.tags,
        submittedBy: filter.filter.subjectSearchButtons.includes(
          SUBJECT_BUTTON_SUBMITTER
        )
          ? filter.filter.subjectSearchIds.map((s) => {
              return { user_id: s?.userId, business_unit_id: s?.businessUnitId }
            })
          : [],
        assignedTo: filter.filter.subjectSearchButtons.includes(
          SUBJECT_BUTTON_ASSIGNEE
        )
          ? filter.filter.subjectSearchIds.map((s) => {
              return { user_id: s?.userId, business_unit_id: s?.businessUnitId }
            })
          : [],
        subject: filter.filter.subjectSearchButtons.includes(
          SUBJECT_BUTTON_SUBJECT
        )
          ? filter.filter.subjectSearchIds
              .filter((s) => s.businessUnitId != null)
              .map((s) => s.businessUnitId)
          : [],
        includeLocations: filter.filter.inverseLocations,
      })
      commit(SET_SAVED_FILTER_COUNT, { id, count: data.count })
    }
  },
  showAside({ commit }) {
    commit(SET_ASIDE_VISIBILITY, true)
  },
  hideAside({ commit }) {
    commit(SET_ASIDE_VISIBILITY, false)
  },
  setVisiblePanel({ commit }, panel) {
    commit(SET_VISIBLE_PANEL, panel)
  },
  setDateRange({ commit }, { start, end }) {
    commit(SET_DATE_RANGE, { start, end })
  },
  resetDateRange({ commit }) {
    commit(SET_DATE_RANGE, { start: null, end: null })
  },
  setInverseFormIds({ commit, dispatch }, ids) {
    commit(SET_INVERSE_FORM_IDS, ids)
    dispatch('checkForMatchingSavedFilter')
  },
  resetInverseFormIds({ commit, dispatch }) {
    commit(RESET_INVERSE_FORM_IDS)
    dispatch('checkForMatchingSavedFilter')
  },
  setInverseFormActions({ commit, dispatch }, actions) {
    commit(SET_INVERSE_FORM_ACTIONS, actions)
    dispatch('checkForMatchingSavedFilter')
  },
  resetInverseFormActions({ commit, dispatch }) {
    commit(RESET_INVERSE_FORM_ACTIONS)
    dispatch('checkForMatchingSavedFilter')
  },
  setInverseLocations({ commit, dispatch }, locations) {
    commit(SET_INVERSE_LOCATIONS, locations)
    dispatch('checkForMatchingSavedFilter')
  },
  resetInverseLocations({ commit, dispatch }) {
    commit(RESET_INVERSE_LOCATIONS)
    dispatch('checkForMatchingSavedFilter')
  },
  setTags({ commit, dispatch }, { tags }) {
    commit(SET_TAGS, tags)
    dispatch('checkForMatchingSavedFilter')
  },
  setSavedFilter(
    { commit },
    {
      id,
      inverseFormIds,
      inverseFormActions,
      tags,
      subjectSearchIds,
      subjectSearchButtons,
      inverseLocations,
    }
  ) {
    commit(SET_INVERSE_FORM_IDS, inverseFormIds)
    commit(SET_INVERSE_FORM_ACTIONS, inverseFormActions)
    commit(SET_TAGS, tags)
    commit(SET_SUBJECT_SEARCH_IDS, subjectSearchIds)
    commit(SET_SUBJECT_SEARCH_BUTTONS, subjectSearchButtons)
    commit(SET_INVERSE_LOCATIONS, inverseLocations)
    commit(SET_ACTIVE_SAVED_FILTER_ID, id)
  },
  resetTags({ commit, dispatch }) {
    commit(RESET_TAGS)
    dispatch('checkForMatchingSavedFilter')
  },
  updateSubjectSearchIds({ commit, dispatch }, { subjects }) {
    commit(SET_SUBJECT_SEARCH_IDS, subjects)
    dispatch('checkForMatchingSavedFilter')
  },
  resetSubjectSearchIds({ commit, dispatch }) {
    commit(RESET_SUBJECT_SEARCH_IDS)
    commit(SET_SUBJECT_SEARCH_BUTTONS, ['Submitter', 'Assignee', 'Subject'])
    dispatch('checkForMatchingSavedFilter')
  },
  setSubjectSearchButtons({ commit, dispatch }, buttons) {
    commit(SET_SUBJECT_SEARCH_BUTTONS, buttons)
    dispatch('checkForMatchingSavedFilter')
  },
}

export const mutations = {
  [SET_INVERSE_FORM_IDS](state, ids) {
    state.inverseFormIds = ids
  },
  [RESET_INVERSE_FORM_IDS](state) {
    state.inverseFormIds = []
  },
  [SET_ASIDE_VISIBILITY](state, visible) {
    state.isAsideVisible = visible
  },
  [SET_VISIBLE_PANEL](state, panel) {
    state.visiblePanels.panel = panel
    state.isAsideVisible = !state.isAsideVisible
  },
  [SET_DATE_RANGE](state, { start, end }) {
    state.dateRange = {
      start,
      end,
    }
  },
  [SET_INVERSE_FORM_ACTIONS](state, actions) {
    state.inverseFormActions = actions
  },
  [RESET_INVERSE_FORM_ACTIONS](state) {
    state.inverseFormActions = []
  },
  [SET_TAGS](state, tags) {
    state.tags = tags
  },
  [RESET_TAGS](state) {
    state.tags = []
  },
  [SET_SUBJECT_SEARCH_IDS](state, subjects) {
    state.subjectSearchIds = subjects
  },
  [RESET_SUBJECT_SEARCH_IDS](state) {
    state.subjectSearchIds = []
  },
  [SET_SUBJECT_SEARCH_BUTTONS](state, buttons) {
    state.subjectSearchButtons = buttons
  },
  [SET_INVERSE_LOCATIONS](state, locations) {
    state.inverseLocations = locations
  },
  [SET_LOADING_SAVED_FILTERS](state) {
    state.loadingSavedFilters = true
  },
  [SET_SAVED_FILTERS](state, savedFilters) {
    const receivedFilters = {}

    savedFilters.forEach((filter) => {
      receivedFilters[filter.id] = filter
    })

    state.savedFilters = receivedFilters
    state.loadingSavedFilters = false
  },
  [SET_SAVED_FILTER_COUNT](state, { id, count }) {
    // Originates from an async call -- check if it still exists
    if (state.savedFilters[id]) state.savedFilters[id].count = count
  },
  [DELETED_SAVED_FILTER](state, id) {
    Vue.delete(state.savedFilters, id)
    state.savingSavedFilters = false
  },
  [EDIT_SAVED_FILTER](state, { id, name, filter, count }) {
    if (name !== null) state.savedFilters[id].name = name
    if (filter !== null) state.savedFilters[id].filter = filter
    if (count !== null) state.savedFilters[id].count = count
    state.savingSavedFilters = false
  },
  [ERROR_SAVING_FILTER](state, error) {
    state.savingSavedFilters = false
    state.savingFiltersError = error
  },
  [SET_SAVED_FILTER](state, { filter, count }) {
    filter.count = count
    Vue.set(state.savedFilters, filter.id, filter)
    state.savingSavedFilters = false
  },
  [SET_SAVING_SAVED_FILTERS](state) {
    state.savingSavedFilters = true
  },
  [SET_ACTIVE_SAVED_FILTER_ID](state, id) {
    state.activeSavedFilterId = id
  },
  [RESET_INVERSE_LOCATIONS](state) {
    state.inverseLocations = []
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
