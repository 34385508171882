<template>
  <v-row
    v-if="lostConnection"
    align="center"
    class="color-yellow--light px-4 py-2"
  >
    <v-col>
      <vs-text class="black--text">
        Lost connection. Reconnecting
        <v-progress-circular
          :size="16"
          :width="2"
          indeterminate
          color="primary"
          class="ml-2"
        />
      </vs-text>
    </v-col>
    <v-col class="shrink">
      <vs-button label="Reload" small @click="onRefresh" />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

const FAIL_THRESHOLD = 3

export default {
  name: 'LostConnection',
  data() {
    return {
      lostConnection: false,
    }
  },
  computed: {
    ...mapGetters({
      connectionErrors: 'dataErrors/connectionErrors',
    }),
  },
  watch: {
    connectionErrors(newValue, oldValue) {
      if (newValue.length < oldValue.length) {
        this.lostConnection = false
      } else if (newValue.length >= FAIL_THRESHOLD) {
        this.lostConnection = true
      } else {
        this.lostConnection = false
      }
    },
  },
  methods: {
    onRefresh() {
      this.$emit('refresh')
    },
  },
}
</script>
