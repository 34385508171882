import { computed, Ref } from 'vue'

type OffsetInfo = {
  offset: Ref<number>
  limit: Ref<number> | number
}

export type SearchBatchesQuery = OffsetInfo & {
  query: Ref<string>
}

export function useBuildSearchBatchesQuery(
  currentPage: Ref<number>,
  itemsPerPage = 20,
  query: Ref<string>
): SearchBatchesQuery {
  const offset = computed(() => (currentPage.value - 1) * itemsPerPage)

  return {
    offset,
    limit: itemsPerPage,
    query,
  }
}
