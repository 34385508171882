import { get } from './base'
import Company from '@/models/company'
import { convertToCamelCase } from '@/models/utils/casing'
import { useQuery } from '@tanstack/vue-query'
import axios from 'axios'
import moment from 'moment'

const companiesEndPoint = '/api/v2/companies'

export interface ServiceProviderCapabilitiesResponse {
  id: string
  name: string
  resourceTypes: number[]
}

export function getAll() {
  return get<Company[]>(`${companiesEndPoint}`)
}

export function getShortCodeSuggestion(
  name: string | null | undefined
): Promise<{ shortCode: string }> {
  return get<{ short_code: string }>(
    `${companiesEndPoint}/shortcode-suggestion`,
    {
      params: {
        name,
      },
    }
  ).then((res) => {
    return convertToCamelCase(res.data)
  })
}

export function getServiceProviderCapabilities(signal?: AbortSignal) {
  const source = axios.CancelToken.source()
  const result = get<ServiceProviderCapabilitiesResponse>(
    `${companiesEndPoint}/service-provider-capabilities`,
    {
      cancelToken: source.token,
    }
  ).then((res) => convertToCamelCase(res.data))
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled by TanStack Query')
  })
  return result as unknown as Promise<ServiceProviderCapabilitiesResponse>
}

export function useGetServiceProviderCapabilities() {
  return useQuery({
    queryKey: ['service', 'capabilities'],
    queryFn: ({ signal }) => getServiceProviderCapabilities(signal),
    staleTime: moment.duration(10, 'minutes').asMilliseconds(),
  })
}
