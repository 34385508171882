"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequiredDependencyEdge = void 0;
const conditions_1 = require("../models/dependencies/conditions");
const schema_node_1 = require("../nodes/schema_node");
const edge_1 = require("./edge");
const required_dependency_dto_1 = require("../models/dependencies/required_dependency_dto");
class RequiredDependencyEdge extends edge_1.EdgeBase {
    constructor(source, dependentOn, requiredValue, requiredFields) {
        super();
        this.target = new schema_node_1.SchemaNode();
        this.source = source;
        this.dependentOn = dependentOn;
        this.requiredValue = requiredValue;
        this.requiredFields = requiredFields;
    }
    condition() {
        return new conditions_1.RequiredCondition(this.requiredValue);
    }
    asDTO() {
        return new required_dependency_dto_1.RequiredDependencyDTO(this.dependentOn, this.requiredValue, this.requiredFields);
    }
}
exports.RequiredDependencyEdge = RequiredDependencyEdge;
