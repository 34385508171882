<template>
  <div ref="menu" class="menu-aside">
    <v-list data-test="menu-list" dense>
      <v-lazy
        v-for="item in filteredItems"
        :key="`${item.id || item.text}`"
        :options="{ threshold: 0.5 }"
        min-height="10"
      >
        <v-list-item
          :class="{
            indent: item.indent,
            'list-item': true,
          }"
          class="tile"
          data-test="list-item"
          @[clickable(item)].stop="handleClick(item)"
        >
          <v-list-item-content>
            <vs-heading v-if="item.header" type="overline">
              {{ item.text }}
            </vs-heading>
            <vs-text v-else>
              {{ item.name }}
            </vs-text>
            <vs-text v-if="item.description" type="subdued">
              {{ item.description }}
            </vs-text>
          </v-list-item-content>
        </v-list-item>
      </v-lazy>

      <v-list-item v-if="items && items.length === 0">
        <v-list-item-content>
          <vs-text>
            <template v-if="filterValue">
              <template>
                No results for <em>{{ filterValue }}</em>
              </template>
            </template>
            <template v-else>
              <template>No {{ label }} found</template>
            </template>
          </vs-text>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { clone, uniqBy } from 'lodash'

export default {
  name: 'FormSubmissionFilterAsidePanelItemMenu',
  props: {
    /**
     * Change the default not found message
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * Filters the items in the menu
     */
    filterValue: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  computed: {
    filteredItems() {
      if (this.filterValue) return this.filter()
      return this.items
    },
  },
  watch: {
    filterValue(value) {
      if (value) {
        return this.filter()
      }
    },
  },
  methods: {
    clickable(item) {
      return 'id' in item && !item.disabled ? 'click' : null
    },
    handleClick(item) {
      this.$emit('changed', item.id)
    },
    matchWith(value) {
      if (!value) return false
      const criteria = value.replace(/\s/g, '').toLocaleLowerCase()
      const searchVal = this.filterValue.replace(/\s/g, '').toLocaleLowerCase()
      return criteria.indexOf(searchVal) > -1
    },
    filter() {
      let filtered = this.items.filter(
        (item) =>
          this.matchWith(item.name ?? item.text) ||
          this.matchWith(item.description) ||
          this.matchWith(item.additionalKeywords)
      )

      // The same entity may appear multiple times in this.items, under different headings
      // (e.g. a user that is role assigned to multiple hubs).
      // However, we do not want to show duplicates in the search results.
      filtered = uniqBy(filtered, (item) => item.id ?? item.text)

      for (const item of filtered) {
        if (!filtered.includes(item.parent)) {
          const cloneItem = clone(item)
          cloneItem.indent = false

          const index = filtered.indexOf(item)

          if (index !== -1) {
            filtered[index] = cloneItem
          }
        }
      }
      return filtered
    },
  },
}
</script>

<style scoped>
.menu-aside {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: var(--space-base);
}
.tile {
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>
