<template>
  <vs-wrapper>
    <v-checkbox
      data-test="boolean-field"
      class="mt-0 checkbox"
      :label="title"
      :hint="description"
      persistent-hint
      :input-value="formData"
      :error-messages="errorMessages"
      :disabled="disabled"
      @change="$emit('change', $event)"
    />
  </vs-wrapper>
</template>

<script>
export default {
  name: 'BooleanField',
  props: {
    errorSchema: Object,
    formData: Boolean,
    params: Object,
    rootSchema: Object,
    schema: Object,
    title: String,
    description: String,
    uiSchema: Object,
    disabled: Boolean,
  },
  computed: {
    errorMessages() {
      const errors = this.errorSchema?.errors ?? []
      if (this.formData && typeof this.formData !== 'boolean') {
        errors.push('Yes or No value expected.')
      }
      return errors
    },
  },
}
</script>

<style scoped>
.checkbox :deep .v-input__slot {
  margin-bottom: 2px;
}
:deep .v-label {
  font-size: 14px;
}
.checkbox :deep .v-messages {
  font-size: 14px;
  font-style: italic;
  margin-left: 7%;
}
</style>
