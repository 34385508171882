"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArrayUiSchema = void 0;
const array_field_definition_1 = require("../../form_fields/array_field_definition");
const json_schema_keys_1 = require("../../json_schema_keys");
const field_ui_schema_1 = require("./field_ui_schema");
class ArrayUiSchema extends field_ui_schema_1.FieldUiSchema {
    constructor(arrayItemDataType) {
        super();
        this.items = new ArrayItemsUiSchema(arrayItemDataType);
    }
}
exports.ArrayUiSchema = ArrayUiSchema;
class ArrayItemsUiSchema extends field_ui_schema_1.FieldUiSchema {
    constructor(arrayItemDataType) {
        super();
        switch (arrayItemDataType) {
            case array_field_definition_1.ArrayItemDataType.businessUnit:
                this.widgetType = json_schema_keys_1.JsonSchemaKeys.WIDGET_TYPE_BUSINESS_UNIT_SELECTOR;
                break;
            case array_field_definition_1.ArrayItemDataType.user:
                this.widgetType = json_schema_keys_1.JsonSchemaKeys.WIDGET_TYPE_USER_SELECTOR;
                break;
            case array_field_definition_1.ArrayItemDataType.location:
                this.widgetType = json_schema_keys_1.JsonSchemaKeys.WIDGET_TYPE_LOCATION_SELECTOR;
                break;
            case array_field_definition_1.ArrayItemDataType.company:
                this.widgetType = json_schema_keys_1.JsonSchemaKeys.WIDGET_TYPE_COMPANY_SELECTOR;
                break;
            default:
                throw new Error(`Unexpected array item data type: ${arrayItemDataType}`);
        }
    }
}
