<template>
  <v-row :class="{ completed: completed, readonly: readonly }" no-gutters>
    <v-col class="shrink">
      <v-simple-checkbox
        :value="completed"
        data-test="complete-form-action"
        :indeterminate="isSaving"
        :indeterminate-icon="'mdi-vanish'"
        class="mt-1"
        :disabled="!canComplete || isSaving"
        @click="handleClick"
      />
    </v-col>
    <v-col>
      <vs-text :class="{ completed_text: completed }">{{ title }}</vs-text>
      <vs-text :class="{ completed_text: completed }" type="caption">{{
        description
      }}</vs-text>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'FollowUpAction',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    completed: Boolean,
    isSaving: Boolean,
    readonly: Boolean,
    actionId: {
      type: String,
      required: true,
    },
  },
  computed: {
    canComplete() {
      return !this.completed && !this.readonly
    },
  },
  methods: {
    handleClick() {
      if (!this.isSaving && !this.completed) {
        if (this.readonly) {
          this.$emit('readonly-follow-up-action-clicked')
        } else {
          this.$emit('complete-follow-up-action', this.actionId)
        }
      }
    },
  },
}
</script>

<style scoped>
.completed >>> .theme--light.v-icon.v-icon.v-icon--disabled {
  color: var(--color-blue--dark) !important;
}
.completed_text {
  text-decoration: line-through;
}

.completed {
  opacity: 0.5;
}
.readonly {
  opacity: 0.5;
}
</style>
