<template>
  <div v-if="loading" class="progress-center">
    <v-progress-circular
      class="ma-auto"
      :size="64"
      :width="6"
      indeterminate
      color="primary"
    />
  </div>
  <div v-else-if="dashboardUrl" class="analytics-dashboard-component-container">
    <AnalyticsLegacyUrlBanner />
    <iframe
      :src="dashboardUrl"
      class="dashboard-container"
      allowtransparency
    ></iframe>
  </div>
</template>

<script>
import { getMetabaseDashboardById } from '@/api/dashboards'
import AnalyticsLegacyUrlBanner from '@/components/analytics/AnalyticsLegacyUrlBanner.vue'

export default {
  components: { AnalyticsLegacyUrlBanner },
  data() {
    return {
      dashboardUrl: null,
      loading: false,
    }
  },

  watch: {
    $route: {
      handler(next, current) {
        if (next === current) return

        this.fetchMetabaseDashBoard(parseInt(next.params.id, 10))
      },
      immediate: true,
    },
  },

  mounted() {
    this.fetchMetabaseDashBoard(parseInt(this.$route.params.id, 10))
  },

  methods: {
    fetchMetabaseDashBoard(id) {
      this.loading = true
      getMetabaseDashboardById(id)
        .then(({ data }) => {
          this.dashboardUrl = data
          this.loading = false
        })
        .catch((err) => {
          console.error('Failed to get the metabase dahsboard url: ', err)
        })
    },
  },
}
</script>

<style>
.analytics-dashboard-component-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.dashboard-container {
  width: 100%;
  display: block;
  flex-grow: 1;
  border-width: 0;
}
.progress-center {
  height: 100%;
  width: 100%;
  display: flex;
}
</style>
