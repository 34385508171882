<template>
  <icon-two-line-cell
    :icon="getAssigneeIcon"
    :primary-text="getAssigneeName"
    :secondary-text="getBusinessUnitLabel"
  />
</template>

<script>
import { ORGANIZATION, TEAM } from '@/models/business-unit'
import { mapGetters } from 'vuex'
import IconTwoLineCell from '@/components/forms/cells/IconTwoLineCell'
import FormSubmission from '@/models/form_submission'
import BusinessUnitPathMixin from '@/components/forms/cells/business-unit-path-mixin'

export default {
  name: 'AssigneeCell',
  components: { IconTwoLineCell },
  mixins: [BusinessUnitPathMixin],
  props: {
    assignee: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getBusinessUnitById: 'businessUnits/getBusinessUnitById',
      getHierarchyLevel: 'businessUnits/getHierarchyLevel',
      getUserBySid: 'users/getBySid',
      getCompanyByOrganizationId: 'companies/getByOrganizationId',
    }),
    businessUnit() {
      if (this.assignee.type === FormSubmission.ASSIGNMENT_ENTITY_TYPE_USER) {
        return this.getBusinessUnitById(
          this.getUserBySid(this.assignee.id)?.defaultBusinessUnitId
        )
      } else if (
        this.assignee.type ===
        FormSubmission.ASSIGNMENT_ENTITY_TYPE_BUSINESS_UNIT
      ) {
        return this.getBusinessUnitById(this.assignee.id)
      }
      return null
    },
    getAssigneeName() {
      if (this.assignee.type === FormSubmission.ASSIGNMENT_ENTITY_TYPE_USER) {
        return this.getUserBySid(this.assignee.id)?.name ?? 'Unknown user'
      } else if (
        this.assignee.type ===
        FormSubmission.ASSIGNMENT_ENTITY_TYPE_BUSINESS_UNIT
      ) {
        return this.businessUnit?.name ?? 'Unknown'
      }
      return ''
    },
    getAssigneeIcon() {
      if (this.assignee.type === FormSubmission.ASSIGNMENT_ENTITY_TYPE_USER) {
        return 'account_circle'
      } else if (
        this.assignee.type ===
        FormSubmission.ASSIGNMENT_ENTITY_TYPE_BUSINESS_UNIT
      ) {
        return this.businessUnit?.type === ORGANIZATION ? 'villa' : 'group'
      }
      return ''
    },
    getBusinessUnitLabel() {
      if (this.assignee.type === FormSubmission.ASSIGNMENT_ENTITY_TYPE_USER) {
        const buPath = this.getBusinessUnitPath
        if (buPath.length > 0) {
          return buPath
        }
        const user = this.getUserBySid(this.assignee.id)
        if (user) {
          const businessUnit = this.getBusinessUnitById(user.organizationId)
          if (businessUnit.type === ORGANIZATION) {
            const company = this.getCompanyByOrganizationId(businessUnit.id)
            return company.shortCode
          }
        }
      } else if (
        this.assignee.type ===
        FormSubmission.ASSIGNMENT_ENTITY_TYPE_BUSINESS_UNIT
      ) {
        if (this.businessUnit?.type === TEAM) {
          const ownerBu = this.getHierarchyLevel(
            this.businessUnit.id,
            ORGANIZATION
          )
          const businessUnit = this.getBusinessUnitById(ownerBu.id)
          if (businessUnit.type === ORGANIZATION) {
            return (
              this.getCompanyByOrganizationId(businessUnit.id)?.shortCode ?? ''
            )
          }
        }
        return ''
      }
      return ''
    },
  },
}
</script>
