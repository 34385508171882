<template>
  <div class="field">
    <vs-text class="label" type="alt-paragraph">{{ label }}</vs-text>
    <div class="content">
      <vs-text v-if="value">{{ value }}</vs-text
      ><slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReadonlyField',

  props: {
    label: {
      type: String,
      required: true,
    },

    value: String,
  },
}
</script>

<style scoped>
.field {
  display: flex;
}
.label {
  flex: 1 0 50%;
}
.content {
  flex: 1 0 50%;
  padding-left: 12px;
}
</style>
