<template>
  <div class="panel">
    <header>
      <slot name="header"></slot>

      <location-type-filter
        v-model="selectedLocationTypeId"
        :options="locationTypes"
      />

      <div class="field-wrapper">
        <div class="chip-container">
          <v-chip
            v-for="chip in collapsedSelectedItems"
            :key="chip.id || 'none'"
            small
            close
            @click:close="removeSelection(chip.id)"
            >{{ chip.title
            }}<template v-if="chip.childCount > 0">
              +{{ chip.childCount }}</template
            >
          </v-chip>
        </div>
        <div class="field">
          <input
            ref="search"
            v-model="searchText"
            class="search-input"
            placeholder="Look for..."
          />
          <div class="field-suffix">
            <v-icon>search</v-icon>
          </div>
        </div>
      </div>
      <div v-if="selection.size > 0" class="result-toolbar">
        <vs-text class="item-counter" type="subdued" center>
          {{ selection.size }} / {{ items.length }} selected</vs-text
        ><v-btn icon x-small @click="clear">
          <v-icon small color="red">mdi-close</v-icon>
        </v-btn>
      </div>
    </header>
    <vs-nested-picker
      :value="[...selection]"
      multiple
      :items="items"
      :search-input="searchText"
      :filter="locationTypeFilter"
      @input="addSelection"
      @collapse="handleLocationCollapsed"
      @expand="handleLocationExpanded"
    >
      <template #no-data>
        <v-container class="mt-2">
          <v-row justify="center">
            <vs-text center>{{ noResultsText }}</vs-text>
          </v-row>
        </v-container>
      </template>
    </vs-nested-picker>
    <slot
      name="footer"
      :selection="[...selection]"
      :dirty="isDirty"
      :reset="reset"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { countBy, isEmpty, isEqual } from 'lodash'
import VsNestedPicker from '@/components/vision/vs-nested-picker/VsNestedPicker'
import LocationTypeFilter from '@/components/common/location-list/LocationTypeFilter'

const flatten = (x) => [x, ...x.children.flatMap(flatten)]

export default {
  name: 'FormSubmissionFilterAsideLocationMenu',
  components: { LocationTypeFilter, VsNestedPicker },

  props: {
    value: {
      type: Array,
      default: () => [],
    },

    items: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    const locationMap = new Map(
      this.items.map((i) => [i.id, { ...i, children: [] }])
    )
    for (const item of locationMap.values()) {
      if (item.parentId && locationMap.has(item.parentId)) {
        locationMap.get(item.parentId).children.push(item)
      }
    }

    return {
      locationMap,
      selection: new Set(this.value),
      searchText: '',
      selectedLocationTypeId: null,
    }
  },

  computed: {
    ...mapGetters({
      getLocationTypeById: 'locationTypes/getById',
    }),

    isDirty() {
      return !isEqual([...this.selection], this.value)
    },

    collapsedSelectedItems() {
      const result = []
      this.selection.forEach((id) => {
        const item = this.locationMap.get(id)
        if (!item.parentId || !this.selection.has(item.parentId)) {
          result.push({
            id: item.id,
            title: item.title,
            childCount: item.children.flatMap(flatten).length,
          })
        }
      })
      return result
    },

    locationTypes() {
      return countBy(this.items, 'locationTypeId')
    },

    noResultsText() {
      let message = 'No results found for your filters:'

      if (!isEmpty(this.searchText)) {
        message += `\nSearch text of "${this.searchText}"`
      }

      if (this.selectedLocationTypeId) {
        message += `\nLocation type of ${
          this.getLocationTypeById(this.selectedLocationTypeId)?.name
        }`
      }

      return message
    },
  },

  mounted() {
    this.$refs.search.focus()
  },

  methods: {
    clear() {
      this.selection = new Set()
    },

    reset() {
      this.selection = new Set(this.value)
    },

    locationTypeFilter(item) {
      return (
        !this.selectedLocationTypeId ||
        this.selectedLocationTypeId === item.locationTypeId
      )
    },

    removeSelection(itemId) {
      const item = this.locationMap.get(itemId)
      // create a copy of the set, update it,
      // then trigger a render by setting the value of selection
      const copy = new Set(this.selection)
      flatten(item).forEach((x) => copy.delete(x.id))
      this.selection = copy
    },

    addSelection(itemId) {
      const item = this.locationMap.get(itemId)
      // create a copy of the set, update it,
      // then trigger a render by setting the value of selection
      const copy = new Set(this.selection)
      flatten(item).forEach((x) => copy.add(x.id))
      this.selection = copy
    },

    handleLocationExpanded() {
      this.searchText = ''
    },

    handleLocationCollapsed() {
      this.searchText = ''
    },
  },
}
</script>

<style scoped>
.panel {
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  border-right: 1px solid var(--color-grey--lightest);
  overflow: hidden;
}

header {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: var(--space-small);
  border-bottom: var(--color-grey--lightest) solid 2px;
  box-shadow: 0 6px 6px -6px var(--color-grey--lighter);

  padding: var(--space-small);
}

.field-wrapper {
  align-self: stretch;
  border: 1px solid var(--color-grey--lighter);
  border-radius: 24px;
  overflow: hidden;
  transition: all 200ms;
}

.field-wrapper:focus-within,
.field-wrapper:hover {
  border-color: var(--color-blue);
}

.field-suffix v-icon {
  color: var(--color-grey--lighter);
}

.field {
  display: flex;
  padding: 0 var(--space-base);
  align-items: center;
}

.chip-container {
  display: flex;
  flex-wrap: wrap;
  max-height: 130px;
  overflow-y: auto;
  padding: calc(var(--space-base) - 4px) calc(var(--space-base) - 4px) 0;
  gap: var(--space-smaller);
}

.chip-container:empty {
  display: none;
}

.search-input {
  width: 100%;
  line-height: 20px;
  padding: calc(var(--space-base) - 2px) 0;
}

.search-input:focus {
  outline: none;
}

.result-toolbar {
  display: inline-flex;
  align-items: center;
  align-self: center;
  gap: var(--space-smaller);
}
</style>
