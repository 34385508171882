import { AttachmentMetadata } from './attachment_metadata'

export class Attachment {
  storageId: string | undefined
  filename: string
  downloadUrl: string
  totalBytes?: number | null
  bytesTransferred?: number | null
  metadata: AttachmentMetadata | null

  constructor(storageId, name, url, metadata) {
    this.storageId = storageId
    this.filename = name
    this.downloadUrl = url
    this.totalBytes = null
    this.bytesTransferred = null
    this.metadata = null

    if (metadata) {
      this.metadata = new AttachmentMetadata(metadata)
    }
  }
}
