<template>
  <vs-wrapper>
    <v-progress-circular
      v-if="isLoading"
      :size="64"
      :width="6"
      indeterminate
      color="primary"
    />

    <template v-else>
      <AssetCard
        v-for="(asset, id) in sortedInventoryAssets"
        :key="`${locationInventory.id}-${id}`"
        :asset="asset"
        :can-edit-inventory="canEditInventory"
        @edit-inventory="$emit('edit-inventory', asset.id)"
      />
      <vs-text v-if="!hasInventory" type="subdued">
        No available inventory here
      </vs-text>
      <v-menu
        v-if="canEditInventory"
        v-model="showMenu"
        max-height="80vh"
        :close-on-content-click="false"
        :nudge-bottom="10"
        :nudge-width="80"
        class="rounded-xl"
        offset-y
        rounded="xl"
      >
        <template #activator="{ on }">
          <vs-button
            small
            :label="addInventoryText"
            type="secondary"
            v-on="on"
          />
        </template>
        <v-card>
          <v-list v-if="showMenu">
            <v-subheader class="list-subheader">Select Type</v-subheader>
            <v-list-item
              v-for="asset in sortedAssets"
              :key="asset.id"
              @click="editInventory(asset.id)"
            >
              <v-list-item-title>{{ asset.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </template>
  </vs-wrapper>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AssetCard from '../inventory/AssetCard'

export default {
  components: {
    AssetCard,
  },

  props: {
    location: {
      type: Object,
      default: () => ({ hasInventory: false }),
    },
    canEditInventory: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMenu: false,
    }
  },
  computed: {
    ...mapGetters('inventory', ['isLoading', 'getById']),
    ...mapGetters('assets', ['getAllAssets']),
    sortedAssets() {
      return Object.values(this.getAllAssets).sort((a, b) =>
        a.name.localeCompare(b.name)
      )
    },
    locationInventory() {
      return this.getById(this.location.id) || { assets: [] }
    },
    sortedInventoryAssets() {
      return Object.values(this.locationInventory.assets).sort((a, b) =>
        a.name.localeCompare(b.name)
      )
    },
    hasInventory() {
      return (
        this.locationInventory &&
        Object.keys(this.locationInventory.assets).length > 0
      )
    },
    addInventoryText() {
      return 'Add inventory'
    },
  },

  mounted() {
    this.fetchLocationInventory(this.location)
  },

  methods: {
    ...mapActions('inventory', ['fetchLocationInventory']),
    editInventory(assetId) {
      this.showMenu = false
      this.$emit('edit-inventory', assetId)
    },
  },
}
</script>
<style scoped>
.list-subheader {
  font-weight: bold;
  color: black;
  font-family: Montserrat, serif;
}
</style>
