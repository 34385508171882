<template>
  <vs-wrapper space="small">
    <v-checkbox
      label="Allow time of day input"
      :input-value="field.allowTimeOfDaySelection"
      @change="toggleTimeOfDaySelection"
    >
    </v-checkbox>
  </vs-wrapper>
</template>

<script>
import VsWrapper from '@/components/vision/VsWrapper'
export default {
  name: 'DateTimeFieldSettings',
  components: { VsWrapper },

  props: {
    field: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    toggleTimeOfDaySelection() {
      this.emitChanges(
        'allowTimeOfDaySelection',
        !this.field.allowTimeOfDaySelection
      )
    },
    emitChanges(propertyKey, value) {
      this.$emit('modify-field', {
        propertyKey,
        fieldValue: value,
        key: this.field.uniqueKey(),
      })
    },
  },
}
</script>
