<template>
  <v-dialog v-model="sessionExpired" persistent max-width="420">
    <v-card>
      <v-card-title>
        <vs-heading>You have been logged out</vs-heading>
      </v-card-title>
      <v-card-text>
        <vs-text> The web session expired while you were away </vs-text>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <vs-button label="Log in again" @click="loginClicked" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SessionExpiredDialog',

  computed: {
    ...mapGetters({
      sessionExpired: 'dataErrors/sessionExpired',
    }),
  },

  methods: {
    loginClicked() {
      this.$emit('login')
    },
  },
}
</script>
