<template>
  <vs-wrapper>
    <vs-heading v-if="title" data-test="markdown-heading" type="subhead">{{
      title
    }}</vs-heading>
    <!-- eslint-disable vue/no-v-html -->
    <!-- It's safe to use v-html here as "html" has been sanitized. -->
    <div class="markdown-fields" v-html="html" />
    <!--eslint-enable-->
    <div v-if="description" class="hint">
      <vs-text data-test="markdown-description" type="subdued">{{
        description
      }}</vs-text>
    </div>
  </vs-wrapper>
</template>

<script>
import DOMPurify from 'dompurify'
import { marked } from 'marked'

export default {
  name: 'MarkdownField',
  props: {
    errorSchema: Object,
    formData: undefined,
    params: Object,
    rootSchema: Object,
    schema: Object,
    title: String,
    description: String,
    uiSchema: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    html() {
      const html = marked(this.schema.markdown)
      return DOMPurify.sanitize(html)
    },
  },
}
</script>

<style scoped>
.markdown-fields {
  font-family: var(--font-regular);
}
.markdown-fields::v-deep h1 {
  font-size: 16px;
}
</style>
