"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReferenceFieldPropertyDescription = void 0;
const json_schema_keys_1 = require("../../json_schema_keys");
const field_property_description_1 = require("./field_property_description");
class ReferenceFieldPropertyDescription extends field_property_description_1.FieldPropertyDescription {
    constructor(field) {
        super();
        if (field.titleOverride !== null && field.titleOverride !== undefined) {
            this[json_schema_keys_1.JsonSchemaKeys.TITLE_KEY] = field.titleOverride;
        }
        if (field.descriptionOverride !== null && field.descriptionOverride !== undefined) {
            this[json_schema_keys_1.JsonSchemaKeys.DESCRIPTION_KEY] = field.descriptionOverride;
        }
        if (field.defaultValueOverride !== null && field.defaultValueOverride !== undefined) {
            this[json_schema_keys_1.JsonSchemaKeys.DEFAULT_VALUE_KEY] = field.defaultValueOverride;
        }
        this[json_schema_keys_1.JsonSchemaKeys.REF_KEY] = `#/definitions/${field.reference.uniqueKey()}`;
    }
}
exports.ReferenceFieldPropertyDescription = ReferenceFieldPropertyDescription;
