"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormattedValueField = exports.PrimitiveValueField = exports.FormFieldDefinition = void 0;
const guid_typescript_1 = require("guid-typescript");
const field_property_description_1 = require("../models/field_property_descriptions/field_property_description");
const field_ui_schema_1 = require("../models/field_ui_schema/field_ui_schema");
const lodash_1 = require("lodash");
class FormFieldDefinition {
    constructor() {
        this.keyOverride = null;
        this.guid = guid_typescript_1.Guid.create();
        this.isRequired = false;
    }
    // Creates a unique key based off of the title and guid of this field.
    // Strips out any non-alphanumerica characters from the title, and replaces spaces with underscores.
    uniqueKey() {
        var _a;
        if (this.keyOverride) {
            return this.keyOverride;
        }
        var title = ((_a = this.title) !== null && _a !== void 0 ? _a : "").trim().toLowerCase().split(" ").join("_").replace(/[^a-z0-9_]/gi, '');
        if (title.length > 0) {
            return title.concat("_").concat(this.guid.toString());
        }
        return this.guid.toString();
    }
    propertyDescription() {
        return new field_property_description_1.DefaultFieldPropertyDescription(this);
    }
    uiSchema() {
        return new field_ui_schema_1.EmptyUiSchema();
    }
    get hasUiSchema() {
        return !(this.uiSchema() instanceof field_ui_schema_1.EmptyUiSchema);
    }
    setKeyOverride(key) {
        this.keyOverride = key;
    }
    copy() {
        const copy = (0, lodash_1.cloneDeep)(this);
        copy.guid = guid_typescript_1.Guid.create();
        return copy;
    }
}
exports.FormFieldDefinition = FormFieldDefinition;
class PrimitiveValueField extends FormFieldDefinition {
}
exports.PrimitiveValueField = PrimitiveValueField;
class FormattedValueField extends PrimitiveValueField {
}
exports.FormattedValueField = FormattedValueField;
