export function saveFile(fileResponse) {
  const fileName = extractFileName(fileResponse)
  const fileUrl = window.URL.createObjectURL(fileResponse.data)

  const anchorElement = document.createElement('a')
  anchorElement.download = fileName
  anchorElement.href = fileUrl
  anchorElement.style = 'display: none;'

  document.body.appendChild(anchorElement)
  anchorElement.click()

  window.URL.revokeObjectURL(fileUrl)
  document.body.removeChild(anchorElement)
}

function extractFileName(fileResponse) {
  const regex = /filename="?(?<fileName>.+?)"?;/
  const match = regex.exec(fileResponse.headers['content-disposition'])
  return match.groups.fileName
}
