
import { defineComponent } from 'vue'
import { Prop } from 'vue/types/options'
import PersonName from './PersonName.vue'

export default defineComponent({
  name: 'JobFollowers',

  components: {
    PersonName,
  },

  props: {
    ids: {
      type: Array as Prop<string[]>,
      default: () => [],
    },
  },
})
