var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.CellBase,{class:{
    expanded: _setup.expanded,
    selected: _setup.selected,
    group: true,
    'drag-target': _setup.isDragTarget,
    'crossed-and-grayed-out': _setup.allJobsComplete,
  },attrs:{"draggable":!_setup.hasCompletedJob,"week-start":_vm.weekStart,"start":_setup.start,"end":_setup.end,"is-saving":_setup.isSaving,"color":_setup.color},on:{"drag":_setup.onGroupDrag},nativeOn:{"click":[function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;$event.stopPropagation();return _setup.onGroupClick.apply(null, arguments)},function($event){if(!$event.metaKey)return null;$event.stopPropagation();return _setup.onGroupCtrlClick.apply(null, arguments)},function($event){if(!$event.ctrlKey)return null;$event.stopPropagation();return _setup.onGroupCtrlClick.apply(null, arguments)}],"contextmenu":function($event){$event.preventDefault();$event.stopPropagation();return _setup.onGroupContextMenu.apply(null, arguments)},"dragover":function($event){return _setup.onDragOver.apply(null, arguments)},"dragenter":function($event){return _setup.onDragEnter.apply(null, arguments)},"dragleave":function($event){return _setup.onDragLeave.apply(null, arguments)},"drop":function($event){return _setup.onDrop.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c(_setup.EventCellJobDetails,{attrs:{"job":_setup.groupLeader,"week-start":_vm.weekStart,"description":`${_vm.jobs.length} jobs | #${_setup.groupLeader.id}`},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('span',{staticClass:"count"},[_c('span',[_vm._v(_vm._s(_setup.jobCountDisplay))])])]},proxy:true}])}),(_setup.allJobsComplete)?_c('v-icon',{staticClass:"icon",attrs:{"small":"","color":"green","data-test":"cell-icon"}},[_vm._v("mdi-check-circle-outline")]):_vm._e(),(_setup.expanded)?_c(_setup.CalendarGrid,{staticClass:"expanded-details",attrs:{"start":_setup.expandedCalendarStart,"end":_setup.expandedCalendarEnd}},_vm._l((_vm.jobs),function(job){return _c(_setup.JobCell,_vm._g({key:`group-member__${job.uuid}`,attrs:{"week-start":_vm.weekStart,"job":job}},_vm.$listeners))}),1):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }