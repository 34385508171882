import { FollowupActionStatus } from '@/models/form_submission'
import {
  SUBJECT_BUTTON_ASSIGNEE,
  SUBJECT_BUTTON_SUBMITTER,
} from '@/store/modules/form-submissions-filters'

export default class FormSubmissionSavedFilter {
  constructor(json) {
    this.id = json.id
    this.name = json.name
    this.default = json.default ?? false
    this.count = null
    this.filter = {
      inverseFormIds: json.filter.inverse_form_ids,
      inverseFormActions: json.filter.inverse_form_actions,
      tags: json.filter.tags,
      subjectSearchIds: json.filter.subject_search_ids.map((subject) => {
        return {
          businessUnitId: subject.business_unit_id,
          userId: subject.user_id,
        }
      }),
      subjectSearchButtons: json.filter.subject_search_buttons,
      inverseLocations: json.filter.inverse_locations,
    }
  }
}

export const defaultSavedFilterTemplates = [
  () => {
    return {
      id: 'default-everything',
      name: 'Everything',
      default: true,
      filter: {
        inverse_form_ids: [],
        inverse_form_actions: [],
        tags: [],
        subject_search_ids: [],
        subject_search_buttons: [],
        inverse_locations: [],
      },
    }
  },
  (userSid) => {
    return {
      id: 'default-my-submissions',
      name: 'My submissions',
      default: true,
      filter: {
        inverse_form_ids: [],
        inverse_form_actions: [],
        tags: [],
        subject_search_ids: [
          {
            business_unit_id: null,
            user_id: userSid,
          },
        ],
        subject_search_buttons: [SUBJECT_BUTTON_SUBMITTER],
        inverse_locations: [],
      },
    }
  },
  (userSid) => {
    return {
      id: 'default-assigned-to-me',
      name: 'Assigned to me',
      default: true,
      filter: {
        inverse_form_ids: [],
        inverse_form_actions: [FollowupActionStatus.RequiredAndAssigned],
        tags: [],
        subject_search_ids: [
          {
            business_unit_id: null,
            user_id: userSid,
          },
        ],
        subject_search_buttons: [SUBJECT_BUTTON_ASSIGNEE],
        inverse_locations: [],
      },
    }
  },
]
