<template>
  <component
    :is="determineComponent"
    v-ripple
    :to="to"
    type="button"
    :class="getClassNames"
    :disabled="disabled || loading"
    data-test="vs-button-inner-component"
    v-on="$listeners"
  >
    <v-icon v-if="!!icon && !rightIcon" class="icon" :small="small">
      {{ icon }}
    </v-icon>
    {{ label }}
    <v-icon v-if="!!icon && rightIcon" class="icon" :small="small">
      {{ icon }}
    </v-icon>
  </component>
</template>

<script>
/**
 * Buttons are a core component on every UI. This allows you to initiate any
 * action you desire.
 */
export default {
  props: {
    /**
     * Styles the button red
     */
    destructive: {
      type: Boolean,
      default: false,
    },
    /**
     * Visually communicate to the user that they can't interact with the button
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Makes the button fill the width of the parent.
     */
    fullWidth: {
      type: Boolean,
      default: false,
    },
    /**
     * Adds an icon to the left side of the label. This uses material icon names.
     *
     * {@link https://material.io/resources/icons/}
     */
    icon: {
      type: String,
      default: null,
    },
    /**
     * Adds the button label
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * Adds an animated barber stripe styling to communicate that it's loading.
     * This also disables further interaction.
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Removes the 1px border around the button
     */
    removeInvisibleBorder: {
      type: Boolean,
      default: false,
    },
    /**
     * Use a rectangular shape with sharp corners instead of a pill
     */
    rectangular: {
      type: Boolean,
      default: false,
    },
    /**
     * Pushes the icon to the right side of the label
     */
    rightIcon: {
      type: Boolean,
      default: false,
    },
    /**
     * Removes the minimum width of a button
     */
    shrink: {
      type: Boolean,
      default: false,
    },
    /**
     * Makes the button small
     */
    small: {
      type: Boolean,
      default: false,
    },
    /**
     * A router link
     */
    to: {
      type: [String, Object],
      default: null,
    },
    /**
     * Determines the style of each button going from high emphasis to low
     */
    type: {
      type: String,
      default: 'primary',
      validator: (val) => ['primary', 'secondary', 'tertiary'].includes(val),
    },
  },
  computed: {
    getClassNames() {
      return {
        'full-width': this.fullWidth,
        'has-icon': !!this.icon,
        'right-icon': this.rightIcon,
        'vs-button': true,
        [`vs-${this.type}`]: !!this.type,
        destructive: this.destructive,
        disabled: this.disabled,
        loading: this.loading,
        shrink: this.shrink || !this.label,
        small: this.small,
        'no-label': !this.label,
        'rounded-0': this.rectangular,
        'no-invisible-border': this.removeInvisibleBorder,
      }
    },
    determineComponent() {
      if (this.to) {
        return 'router-link'
      }

      return 'button'
    },
  },
}
</script>

<style scoped>
.vs-button {
  --text-color: var(--color-white);

  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: var(--space-base);
  background-color: var(--color-blue);
  color: var(--text-color);
  border-radius: var(--space-larger);
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  transition: all 200ms;
  border: 1px solid transparent;
  appearance: none;
  box-sizing: border-box;
  min-width: 96px;
  vertical-align: middle;
  white-space: nowrap;
}

.vs-button:hover,
.vs-button:focus {
  background-color: #054eae;
}

.vs-button:focus {
  outline: none;
}

/* Secondary
   ------------------------------------------------------------------------- */

.vs-secondary {
  --text-color: var(--color-blue);
  background-color: transparent;
  border-color: var(--color-blue--lighter);
}

.vs-secondary:hover,
.vs-secondary:focus {
  background-color: var(--color-blue--lighter);
}

/* Tertiary
   ------------------------------------------------------------------------- */

.vs-tertiary {
  --text-color: var(--color-blue);
  background-color: transparent;
}

.vs-tertiary:hover,
.vs-tertiary:focus {
  background-color: var(--color-blue--lighter);
}

/* Destructive
   ------------------------------------------------------------------------- */

.destructive {
  --text-color: var(--color-white);
  background-color: var(--color-red);
}

.destructive:hover,
.destructive:focus {
  background-color: var(--color-red--dark);
}

.destructive.vs-secondary {
  --text-color: var(--color-red);
  background-color: transparent;
  border-color: var(--color-red--lighter);
}

.destructive.vs-secondary:hover,
.destructive.vs-tertiary:hover {
  background-color: var(--color-red--lighter);
}

.destructive.vs-tertiary {
  --text-color: var(--color-red);
  background-color: transparent;
}

/* Disabled
   ------------------------------------------------------------------------- */

.disabled {
  --text-color: var(--color-grey--lighter);
  background-color: var(--color-silver);
  border-color: var(--color-silver);
  pointer-events: none;
}

/* Modifiers
   ------------------------------------------------------------------------- */

.small,
.has-icon.small {
  padding: var(--space-small) 12px;
}

.full-width {
  display: block;
  width: 100%;
}

.has-icon {
  padding: 12px var(--space-base);
}

.icon {
  margin-right: var(--space-smaller);
  color: var(--text-color);
  vertical-align: middle;
}

.theme--light.v-icon.icon {
  color: var(--text-color);
}

.right-icon .icon {
  margin-right: 0;
  margin-left: var(--space-smaller);
}

.no-label {
  padding: 12px;
}

.no-label.small {
  padding: var(--space-small);
}

.no-label .icon {
  margin: 0;
  display: block;
}

.no-invisible-border {
  border: none;
}

.shrink {
  min-width: auto;
}

.loading {
  background-image: linear-gradient(
    135deg,
    rgba(0, 0, 0, 10%) 0%,
    rgba(0, 0, 0, 10%) 25%,
    transparent 25%,
    transparent 50%,
    rgba(0, 0, 0, 10%) 50%,
    rgba(0, 0, 0, 10%) 75%,
    transparent 75%,
    transparent 100%
  );
  background-size: 50px 50px;
  background-repeat: repeat;
  pointer-events: none;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: animateStripes;
  animation-timing-function: linear;
}

@keyframes animateStripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}
</style>
