import moment from 'moment'
import BaseObject from './base_object'

export const LOADING = -1

export const OFFLINE = 0
export const STANDBY = 1
export const ACTIVE = 2

export const TASK_NOT_STARTED = 'none'
export const TASK_PAUSED = 'paused'
export const TASK_ACTIVE = 'active'
export const TASK_COMPLETED = 'done'
export const TASK_CANCELLED = 'cancelled'

const emptyUnit = {
  id: null,
  name: '',
  owner_id: null,
  company_code: null,
  resource_type_id: null,
  status: STANDBY,
  is_active: false,
  is_offline: false,
  division_id: null,
  description: '',
  status_description: '',
  latitude: null,
  longitude: null,
  is_visible: true,
  location_updated: null,
  latest_location: null,
  linked_hub_ids: [],
  operator: null,
  can_edit: true,
}

export class UnitNumber extends BaseObject {
  name: string
  ownerId: string | null

  // TODO: Replace all usage of organizationId with rootOrganizationId
  organizationId: string
  rootOrganizationId: string

  resourceTypeId: number | null
  status: number
  divisionId: number | null
  description: string
  statusDescription: string
  isVisible: boolean
  canEdit: boolean
  operator: {
    occurredAt: moment.Moment
    userSid: string
    onDuty: boolean
  } | null

  constructor(json, resourceTypeId: number | null = null) {
    if (!json) {
      json = emptyUnit
    }
    super(json)

    this.name = json.name
    this.ownerId = json.owner_id
    this.organizationId = json.root_organization_id
    this.rootOrganizationId = json.root_organization_id
    this.resourceTypeId = resourceTypeId || json.resource_type_id
    this.divisionId = json.division_id
    this.description = json.description
    this.statusDescription = json.status_description
    this.isVisible = json.is_visible
    this.canEdit = json.can_edit
    this.operator = json.operator
      ? {
          userSid: json.operator.user_sid,
          onDuty: json.operator.on_duty,
          occurredAt: moment.utc(json.operator.occurred_at),
        }
      : null
    this.status = json.status
  }

  get isStandby() {
    return this.status === STANDBY
  }

  get isLoading() {
    return this.status === LOADING
  }

  get isActive() {
    return this.status === ACTIVE
  }

  get isOffline() {
    return this.status === OFFLINE
  }

  get isArchived() {
    return !this.isVisible
  }
}

export class ResourceLocation {
  resourceId: number | null = null
  userSid: string | null = null
  timestamp: moment.Moment | null = null
  isMoving: boolean | null = null
  latitude: number | null = null
  longitude: number | null = null
  altitude: number | null = null
  heading: number | null = null
  speed: number | null = null
  constructor(json) {
    if (!json) return

    this.resourceId = json.resource_id
    this.userSid = json.user_sid
    this.timestamp = json.timestamp && moment.utc(json.timestamp).local()
    this.isMoving = json.is_moving
    this.latitude = json.latitude
    this.longitude = json.longitude
    this.altitude = json.altitude
    this.heading = json.heading
    this.speed = json.speed
  }
}
