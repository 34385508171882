<template>
  <v-app id="ironsight" class="app-wrapper">
    <v-row no-gutters class="fill-height">
      <v-col class="shrink fill-height">
        <Nav />
      </v-col>

      <v-col class="page-container" style="min-width: 0">
        <Banner />
        <v-row no-gutters class="fill-height">
          <v-col class="shrink fill-height">
            <v-slide-x-transition hide-on-leave>
              <router-view name="aside" class="fill-height" />
            </v-slide-x-transition>
          </v-col>
          <router-view name="provision" />

          <v-col style="min-width: 0">
            <v-row no-gutters class="fill-height flex-column">
              <v-col class="shrink">
                <LostConnection @refresh="reloadPage" />
                <router-view name="toolbar" />
              </v-col>
              <v-col class="content-wrapper">
                <main class="content">
                  <vs-transition>
                    <LoginError v-if="$auth.error" :error="$auth.error" />
                    <router-view v-else />
                  </vs-transition>
                </main>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <session-expired-dialog @login="loginClicked" />

    <portal-target name="dialog" />
    <portal-target name="app" multiple />
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'
import { configureScope } from '@sentry/browser'
import LogRocket from 'logrocket'
import Nav from '@/components/nav/Nav'
import LostConnection from '@/components/common/LostConnection'
import LoginError from '@/components/common/LoginError'
import VsTransition from '@/components/common/Transition'
import SessionExpiredDialog from '@/components/common/SessionExpiredDialog'
import { OrganizationLoadException } from '@/store/modules/organization-config'
import Banner from '@/components/banners/Banner.vue'

export default {
  components: {
    Nav,
    LostConnection,
    LoginError,
    VsTransition,
    SessionExpiredDialog,
    Banner,
  },
  provide() {
    return {
      $appInsights: this.$appInsights,
    }
  },
  watch: {
    async '$auth.loading'(loading) {
      if (loading || !this.$auth.isAuthenticated) {
        return
      }
      const {
        sub: id,
        email,
        nickname,
        orgId,
        env,
        roles,
      } = this.$auth.user || {}
      LogRocket.identify(id, {
        name: nickname,
        email,
      })
      configureScope((scope) => {
        scope.setUser({
          id,
          email,
        })
        scope.setExtra('sessionURL', LogRocket.sessionURL)
      })

      if (this.$appInsights) {
        this.$appInsights.addTelemetryInitializer((envelope) => {
          envelope.data.tenant = env
          envelope.data.orgId = orgId
          envelope.data.userSid = id
        })
        // using email instead of auth0 id because app insights disallows `|` characters
        this.$appInsights.setAuthenticatedUserContext(email, orgId, true)
      }

      try {
        if (this.$router.history.pending.path === '/') {
          const previousRoute = localStorage.getItem('previousRoute')

          if (previousRoute) {
            this.$router.replace(previousRoute)
          } else {
            // TODO: Change to 1 once we remove legacy prefix role duplication
            if (
              roles.length === 2 &&
              (roles.includes('Member') || roles.includes('Form Reviewer'))
            ) {
              this.$router.replace('/forms')
            } else {
              this.$router.replace('/jobs')
            }
          }
        }

        await this.initializeStore()
      } catch (e) {
        if (
          e.constructor.name === OrganizationLoadException.name &&
          e.error.status === 404
        ) {
          return this.$router.replace('/welcome')
        }
        throw e
      }
    },
  },
  methods: {
    ...mapActions({
      initializeStore: 'initialize',
    }),
    reloadPage() {
      location.reload(true)
    },
    loginClicked() {
      this.$auth.loginWithRedirect()
    },
  },
}
</script>

<style>
.v-application {
  font-family: var(--font-regular);
}
</style>

<style scoped>
.app-wrapper {
  height: 100vh;
}

.content-wrapper {
  overflow: hidden;
  height: 100%;
}

.content {
  background-color: #fafafa;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.schedule-view-container {
  flex: 1 1 auto;
  position: relative;
}
</style>

<style>
@import 'styles/variables.css';
@import 'styles/vuetify-colors.css';
@import 'styles/grid.css';
@import 'styles/colors.css';
@import 'styles/z-index.css';
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&family=Montserrat:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap');

html {
  overflow-y: auto;
  font-family: 'Roboto', sans-serif;
}

.content--wrap {
  background-image: url('./assets/logo_bg.svg');
  background-position: center;
  background-size: 15% auto;
}

.truncated {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.z-index-2 {
  z-index: 2;
}

.overline {
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.subtitle-1 {
  font-size: 16px;
  letter-spacing: 0.15px;
}

.subtitle-2 {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
}

.toolbar__content > .tooltip:last-child,
.toolbar__extension > .tooltip:last-child {
  margin-right: 8px !important;
}

.toolbar__content > .menu:last-child,
.toolbar__extension > .menu:last-child {
  margin-right: 0px !important;
}

.btn--flat > .btn__content {
  padding: 0 8px;
}

/* @TODO: I think these were added for IE, but I can't remember.  Test */
.table__overflow {
  display: table;
}

table.table thead td:not(:nth-child(1)),
table.table tbody td:not(:nth-child(1)),
table.table thead th:not(:nth-child(1)),
table.table tbody th:not(:nth-child(1)) {
  padding: 0 12px;
}

.page-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
