export const isBottomBarSticky = (
  scrollPosition,
  scrollHeight,
  contentHeight
) => {
  return scrollPosition + contentHeight < scrollHeight
}

export const isScrolled = (scrollPosition) => {
  return scrollPosition > 0
}
