<template>
  <p class="content">
    <span>
      {{ displayedText }}
    </span>
    <v-tooltip top max-width="350px">
      <template #activator="{ on }">
        <span v-on="on"
          ><strong>{{ hiddenItemsCount }}</strong></span
        >
      </template>
      <span v-html="tooltipText"></span>
    </v-tooltip>
  </p>
</template>

<script>
import { groupBy } from 'lodash'

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    job: {
      type: Object,
      required: true,
    },
  },
  computed: {
    items() {
      const groupedInventory = Object.values(
        groupBy(this.job.inventory, (item) => item.position)
      )
      return groupedInventory.length > 1
        ? groupedInventory.map((group) => {
            return (
              (this.job.pickupLocations.find(
                (location) => location.id === group[0].pickupLocationId
              )?.name ?? 'Pick up location undecided') +
              ': ' +
              group.map((item) => item.inventorySubTypeName).join(', ')
            )
          })
        : this.value.map((item) => item.itemName)
    },
    displayedText() {
      return this.value[0]?.itemName ?? ''
    },
    hiddenItemsCount() {
      return this.value.length > 1 ? ` +${this.value.length - 1} more` : ''
    },
    tooltipText() {
      return this.items.join('<br />')
    },
  },
}
</script>

<style scoped>
.content {
  margin: 0;
}
</style>
