<template>
  <vs-wrapper>
    <div class="tile" @click="$emit('show-panel')">
      <v-row align="center" no-gutters>
        <v-col>
          <vs-text>{{ title }}</vs-text>
        </v-col>
        <v-col class="shrink">
          <slot
            name="toggle"
            :items="selectableItems"
            :selected="selectedItems"
          />
        </v-col>
        <v-col class="shrink">
          <v-btn class="ma-0" small icon>
            <v-icon data-test="filteraside-expand-toggle" small>
              chevron_right
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <slot
        name="item-preview"
        :items="items"
        :selected="selected"
        :title="title"
      />
    </div>
  </vs-wrapper>
</template>

<script>
export default {
  name: 'FormSubmissionsFilterAsideSection',
  props: {
    title: {
      type: String,
      default: '',
      required: false,
    },
    items: {
      type: Array[Object],
      default: () => [],
      required: false,
    },
    itemValue: {
      type: String,
      default: 'id',
    },
    selected: {
      type: Array[Number],
      default: () => [],
      required: false,
    },
  },
  computed: {
    selectedItems: {
      get() {
        return this.selected.filter((item) =>
          this.selectableItems.includes(item)
        )
      },
    },
    selectableItems() {
      return this.items
        .filter((item) => !('header' in item || 'divider' in item))
        .map((item) => item[this.itemValue])
    },
  },
}
</script>

<style scoped>
.tile {
  border-bottom: 1px solid var(--color-grey--lighter);
  padding-top: var(--space-small);
  padding-bottom: var(--space-small);
  padding-left: var(--space-small);
  margin-top: -16px;
}
.tile:hover {
  background-color: #fafafa;
  cursor: pointer;
}
</style>
