<template>
  <nav v-if="showNav" class="navbar">
    <NavItem logo to="/" />

    <NotificationsPanel />

    <NavItem
      v-for="item in items"
      :key="allowedRoutes.length + item.title"
      :data-test="`nav-tab-${item.title}`"
      :icon="item.icon"
      :label="item.title"
      :to="item.to"
    />

    <analytics-pop-over v-if="renderAnalytics && isSmallScreen" />

    <NavAccountPopover v-if="$auth.isAuthenticated" />

    <HelpPopover />

    <VersionFooter
      :version="clientVersion"
      :update-available="$clientVersion !== clientVersion"
    />
  </nav>
</template>

<script>
import NotificationsPanel from '../notifications/NotificationsPanel.vue'
import NavItem from './NavItem.vue'
import NavAccountPopover from './NavAccountPopover.vue'
import HelpPopover from './HelpPopover.vue'
import VersionFooter from './VersionFooter'
import { mapGetters, mapState } from 'vuex'
import AnalyticsPopOver from '@/components/analytics/AnalyticsPopOver'
import { isSmallScreen } from '@/components/nav/mobileUtils'
import { mapState as mapPiniaState } from 'pinia'
import { useScheduleFilterStore } from '@/components/schedule/ScheduleFilterStore'
import { useNavStore } from '@/components/nav/navStore'

export default {
  components: {
    NavAccountPopover,
    NavItem,
    NotificationsPanel,
    VersionFooter,
    AnalyticsPopOver,
    HelpPopover,
  },

  data() {
    return {
      helpItem: {
        icon: 'mdi-help-circle-outline',
        title: 'Help',
      },
      renderAnalytics: false,
    }
  },

  computed: {
    ...mapPiniaState(useNavStore, {
      isNavVisible: 'isNavVisible',
    }),
    ...mapPiniaState(useScheduleFilterStore, {
      lastViewedSchedule: 'lastViewedSchedule',
    }),
    ...mapState({
      clientVersion: (state) => state.clientVersion,
      featureFlags: (state) => state.featureFlags.all,
    }),
    ...mapGetters({
      hasAnyVisibleForms: 'forms/getHasAnyVisibleForms',
      isHostOrganizationUser: 'permissions/isHostOrganizationUser',
    }),
    allowedRoutes() {
      return this.$router.options.routes.filter((route) => {
        if (
          route.meta &&
          route.meta.display &&
          route.meta.type === 'main-nav'
        ) {
          const isInternalRoute = route.meta.isInternal
          const canAccessInternalRoutes =
            isInternalRoute && this.isHostOrganizationUser
          const isDeveloperRoute = route.meta.isDeveloper
          const canAccessDeveloperRoutes =
            isDeveloperRoute && this.$auth.isDeveloper

          const needsFeatureFlag = 'featureFlag' in route.meta
          const requiresForms = route.meta.requiresForms === true

          if (requiresForms && !this.hasAnyVisibleForms) {
            return false
          }

          return (
            (!route.meta.permissions ||
              route.meta.permissions.some((permission) =>
                this.$auth.hasRole(permission)
              )) &&
            (!isInternalRoute || canAccessInternalRoutes) &&
            (!isDeveloperRoute || canAccessDeveloperRoutes) &&
            (!needsFeatureFlag ||
              this.featureFlags[route.meta.featureFlag] === true)
          )
        }
      })
    },
    items() {
      const items = this.allowedRoutes.map((route) => {
        if (route.name === 'schedule') {
          route.path = `/schedule/${this.lastViewedSchedule}`
        }

        if (route.meta.display.title === 'Analytics') {
          this.renderAnalytics = true
        }

        return {
          title: route.meta.display.title,
          icon: route.meta.display.icon,
          to: route.path,
        }
      })

      if (this.isSmallScreen) {
        return items.filter((item) => {
          return item.title !== 'Analytics'
        })
      }
      return items
    },
    isSmallScreen,
    showNav() {
      if (
        this.$route.name !== 'job-search' &&
        this.$route.name !== 'field-view-next'
      ) {
        return true
      }
      return this.isNavVisible
    },
  },
}
</script>

<style scoped>
.navbar {
  display: flex;
  flex-direction: column;
  padding-top: var(--space-small);
  background-color: var(--color-silver);
  border-right: 1px solid var(--color-grey--lightest);
  height: 100%;
  overflow-y: auto;
  position: relative;
  z-index: var(--elevation-none);
}

.help {
  margin-top: auto;
}
</style>
