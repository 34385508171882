<template>
  <vs-wrapper>
    <vs-heading>
      <v-alert
        v-show="!activeFormField"
        border="left"
        color="orange"
        type="info"
        text
      >
        No field selected.<br />
        Select a field to edit it here.
      </v-alert>
    </vs-heading>

    <relationships-editor
      v-if="activeFormField && selectedFieldSupportsRelationships"
      :builder="builder"
      :field="activeFormField"
      :active-flow="activeFlow"
    >
    </relationships-editor>

    <core-field-settings
      v-if="activeFormField"
      class="mb-3"
      :field="activeFormField"
      :builder="builder"
      @modify-field="modifyField"
      @remove-field="removeField"
    ></core-field-settings>

    <specific-field-settings
      v-if="activeFormField"
      :field="activeFormField"
      :field-type-name="fieldTypeName"
      @modify-field="modifyField"
      @remove-field="removeField"
    />
  </vs-wrapper>
</template>

<script>
import RelationshipsEditor from '@/components/form-builder/RelationshipsEditor'
import CoreFieldSettings from '@/components/form-builder/field-settings/CoreFieldSettings'
import SpecificFieldSettings from '@/components/form-builder/field-settings/SpecificFieldSettings'
import VsWrapper from '@/components/vision/VsWrapper'
import VsHeading from '@/components/vision/VsHeading'

export default {
  name: 'FormFieldDefinitionsEditor',
  components: {
    VsHeading,
    VsWrapper,
    SpecificFieldSettings,
    CoreFieldSettings,
    RelationshipsEditor,
  },

  props: {
    builder: {
      type: Object,
      default: () => {},
    },
    fieldTypeName: {
      type: String,
      default: '',
    },
    activeFormField: {
      type: Object,
      default: null,
    },
    activeFlow: {
      type: String,
      default: '#root_schema',
    },
    referenceFields: {
      type: Array,
      default: () => {},
    },
    selectedFieldSupportsRelationships: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    modifyField(data) {
      this.$emit('modify-form-field', data)
    },
    removeField(field) {
      this.$emit('remove-form-field', field)
    },
    addPage(flowKey) {
      this.$emit('add-page', flowKey)
    },
    deletePage(page) {
      this.$emit('delete-page', page)
    },
    renamePageTitle(page) {
      this.$emit('rename-page-title', page)
    },
    addFlow(flowName) {
      this.$emit('add-flow', flowName)
    },
    deleteFlow(flowKey) {
      this.$emit('delete-flow', flowKey)
    },
  },
}
</script>

<style scoped></style>
