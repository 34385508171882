"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequiredDependencyDTO = void 0;
class RequiredDependencyDTO {
    constructor(parentField, requiredValue, requiredFields) {
        this.parentField = parentField;
        this.requiredValue = requiredValue;
        this.requiredFields = requiredFields;
    }
}
exports.RequiredDependencyDTO = RequiredDependencyDTO;
