"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefinitionsModel = void 0;
const schema_object_1 = require("./schema_object");
class DefinitionsModel extends schema_object_1.SchemaObject {
    constructor(definitions) {
        super();
        for (const field of definitions) {
            this[field.uniqueKey()] = field.propertyDescription();
        }
    }
}
exports.DefinitionsModel = DefinitionsModel;
