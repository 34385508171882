
import { defineComponent, PropType } from 'vue'
import JobProgressBar from '@/components/common/JobProgressBar.vue'
import { TASK_ACTIVE, TASK_PAUSED } from '@/models/unit_number'
import Job, { ACTIVE, PAUSED } from '@/models/job'

type Item = Pick<Job, 'status' | 'estimatedDuration' | 'activityLog'>

export default defineComponent({
  components: {
    JobProgressBar,
  },

  props: {
    item: {
      type: Object as PropType<Item>,
      required: true,
    },
  },

  computed: {
    showProgressBar() {
      return (
        (this.item.status === ACTIVE || this.item.status === PAUSED) &&
        this.activeAndPausedSegments.length > 0
      )
    },
    activeAndPausedSegments() {
      return this.item.activityLog.segments.filter(
        (segment) =>
          segment.start.status === TASK_ACTIVE ||
          segment.start.status === TASK_PAUSED
      )
    },
  },
})
