<template>
  <v-row dense>
    <v-col class="shrink">
      <UnitNumberStatusIcon :status="unit.status" :has-operator="hasOperator" />
    </v-col>
    <v-col>
      <vs-text type="overline">
        <span v-if="division?.id" :style="{ color: division.hexCode }">{{
          division.name
        }}</span>
        <template v-if="company && division.id"> : </template>
        <template v-if="company">{{ company.shortCode }}</template>
      </vs-text>

      <vs-text type="intro" truncate>{{ unit.name }}</vs-text>

      <vs-text type="subdued" truncate>{{
        unit.statusDescription || unit.description
      }}</vs-text>

      <vs-text type="paragraph" class="mt-2" truncate>{{
        operatorName
      }}</vs-text>
      <vs-text v-if="hasOperatorData" type="subdued" truncate>{{
        onDutySubtext
      }}</vs-text>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import UnitNumberStatusIcon from './UnitNumberStatusIcon'
import { formatDateTime } from '@/utils/date-formatting'
import moment from 'moment'
import { OFFLINE } from '@/models/unit_number'
export default {
  name: 'UnitCard',
  components: {
    UnitNumberStatusIcon,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getDivisionById: 'divisions/getById',
      getCompanyByOrganizationId: 'companies/getByOrganizationId',
      getUnitById: 'unitNumbers/getById',
      getUserBySid: 'users/getBySid',
    }),
    unit() {
      return this.getUnitById(this.id) || {}
    },
    division() {
      return this.getDivisionById(this.unit.divisionId) || {}
    },
    company() {
      return this.getCompanyByOrganizationId(this.unit.organizationId) || {}
    },
    hasOperator() {
      return this.unit?.operator?.onDuty === true
    },
    hasOperatorData() {
      return this.unit?.operator != null
    },
    operatorName() {
      return (
        this.getUserBySid(this.unit?.operator?.userSid)?.name ??
        'No field workers on duty'
      )
    },
    onDutyColor() {
      if (this.unit?.operator?.onDuty) {
        return 'teal accent-2'
      }
      return 'grey lighten-1'
    },
    onDutySubtext() {
      if (this.unit?.operator == null) return null
      if (this.unit?.operator.onDuty) {
        return `On duty since ${formatDateTime(
          moment(this.unit?.operator.occurredAt).local()
        )}`
      } else {
        return `Last on duty ${formatDateTime(
          moment(this.unit?.operator.occurredAt).local()
        )}`
      }
    },
    statusChangeLabel() {
      return this.unit.status === OFFLINE ? 'Mark as Online' : 'Mark as Offline'
    },
  },
}
</script>
