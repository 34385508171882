<template>
  <component
    :is="determineComponent"
    v-ripple
    :to="to"
    :[useHref]="href"
    :[useTarget]="getTarget"
    :active-class="getActiveClassName"
    :exact="logo"
    class="nav-item"
    v-on="$listeners"
  >
    <img v-if="logo" class="logo" src="../../assets/logo_black.png" />

    <template v-else>
      <v-badge v-model="showBadge" overlap :color="getBadgeColor">
        <template #badge>
          <span>{{ getBadgeValue }}</span>
        </template>

        <v-icon class="icon mb-1">{{ icon }}</v-icon>
      </v-badge>

      <div class="label">
        {{ label }}
      </div>
    </template>
  </component>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: () => null,
    },
    label: {
      type: String,
      default: () => null,
    },
    to: {
      type: [String, Object],
      default: () => null,
    },
    href: {
      type: String,
      default: () => null,
    },
    logo: {
      type: Boolean,
      default: false,
    },
    badge: {
      type: Number,
      default: () => null,
    },
    warn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    determineComponent() {
      if (this.to) {
        return 'router-link'
      }

      if (this.href) {
        return 'a'
      }

      return 'button'
    },
    useHref() {
      return this.href ? 'href' : null
    },
    useTarget() {
      return this.href ? 'target' : null
    },
    getTarget() {
      return this.href ? '_blank' : '_self'
    },
    showBadge() {
      return !!this.badge || this.warn
    },
    getBadgeColor() {
      if (this.warn) {
        return 'orange'
      }
      return 'red'
    },
    getBadgeValue() {
      return this.warn ? '!' : this.badge
    },
    getActiveClassName() {
      return this.logo ? null : 'is-active'
    },
  },
}
</script>

<style scoped>
.nav-item {
  position: relative;
  display: block;
  padding: var(--space-base) var(--space-small);
  width: calc(var(--space-base) * 5.25);
  text-align: center;
  box-sizing: border-box;
  text-decoration: none;
  transition: all 200ms ease;
}

.nav-item:hover,
.nav-item:focus,
.is-active {
  outline: none;
  background-color: var(--color-grey--lightest);
}

.nav-item:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 0;
  background-color: var(--color-blue);
  border-radius: var(--space-smaller) 0 0 var(--space-smaller);
  transition: width 300ms ease;
}

.is-active:before {
  width: var(--space-smaller);
}

.label {
  font-size: 12px;
  color: var(--color-grey);
}

.icon {
  margin-bottom: var(--space-smaller);
  color: var(--color-grey);
}

.is-active * {
  color: var(--color-black);
}

.logo {
  display: block;
  margin: auto;
  width: 100%;
  max-width: calc(var(--base-unit) * 2);
}

/* Dark */

.dark .nav-item:hover,
.dark .nav-item:focus,
.dark .is-active {
  background-color: var(--color-black);
}

.dark .label,
.dark .icon {
  color: var(--color-silver);
}

.dark .is-active * {
  color: var(--color-white);
}
</style>
