"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArrayFieldDefinition = exports.ArrayItemDataType = void 0;
const json_schema_keys_1 = require("../json_schema_keys");
const array_property_description_1 = require("../models/field_property_descriptions/array_property_description");
const array_ui_schema_1 = require("../models/field_ui_schema/array_ui_schema");
const field_ui_schema_1 = require("../models/field_ui_schema/field_ui_schema");
const form_field_definition_1 = require("./form_field_definition");
var ArrayItemDataType;
(function (ArrayItemDataType) {
    ArrayItemDataType[ArrayItemDataType["string"] = 0] = "string";
    ArrayItemDataType[ArrayItemDataType["integer"] = 1] = "integer";
    ArrayItemDataType[ArrayItemDataType["decimal"] = 2] = "decimal";
    ArrayItemDataType[ArrayItemDataType["user"] = 3] = "user";
    ArrayItemDataType[ArrayItemDataType["businessUnit"] = 4] = "businessUnit";
    ArrayItemDataType[ArrayItemDataType["location"] = 5] = "location";
    ArrayItemDataType[ArrayItemDataType["company"] = 6] = "company";
})(ArrayItemDataType || (exports.ArrayItemDataType = ArrayItemDataType = {}));
class ArrayFieldDefinition extends form_field_definition_1.PrimitiveValueField {
    constructor(itemDataType) {
        super();
        this.dataType = json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_ARRAY;
        this.itemDataType = itemDataType;
    }
    propertyDescription() {
        return new array_property_description_1.ArrayPropertyDescription(this);
    }
    uiSchema() {
        switch (this.itemDataType) {
            case ArrayItemDataType.businessUnit:
            case ArrayItemDataType.user:
            case ArrayItemDataType.location:
            case ArrayItemDataType.company:
                return new array_ui_schema_1.ArrayUiSchema(this.itemDataType);
            default:
                return new field_ui_schema_1.EmptyUiSchema();
        }
    }
}
exports.ArrayFieldDefinition = ArrayFieldDefinition;
