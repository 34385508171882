import { put, post } from '../base'
import { FormModel } from 'ironsight-form-builder/dist/models/form_model'

const formsEndPoint = '/api/v2/account-data/forms'

interface CreateFormRequest {
  owner_id: unknown
  name: string
  id: string
  state: string
  fillable: boolean
  structure: FormModel
}

export function createForm(form: CreateFormRequest) {
  return post(formsEndPoint, { data: form })
}

interface UpdateFormRequest {
  name: string
  structure: FormModel
  notificationsEnabled: boolean
}

export function updateForm(id: string, form: UpdateFormRequest) {
  return put(`${formsEndPoint}/${id}`, { data: form })
}
