<template>
  <v-row no-gutters class="fill-height nowrap">
    <v-col v-if="isFiltersVisible" class="shrink fill-height">
      <form-submission-filter-aside
        @hide-filter-aside="hideFiltersAside"
        @filter-changed="handleFilterChanged"
      />
    </v-col>

    <v-col v-if="getVisiblePanels === 'Subjects'" class="shrink fill-height">
      <form-submission-filter-aside-panel
        panel-label="Business units & users"
        :items="getSubjects"
        :selected-items="flattenedSelectedSubjects"
        :subject-search-ids="getSubjectSearchIds"
        @filter-subject-buttons-changed="handleButtonsUpdate"
        @save-filters="handleSelectSubject"
        @leave-panel="setVisiblePanel(null)"
      >
        <template #subjectButtons="buttonProps">
          <v-btn-toggle
            rounded
            mandatory
            multiple
            color="blue"
            :value="getSubjectSearchButtons"
            @change="buttonProps.handleButtonSelection"
          >
            <v-btn
              v-for="button in getAvailableSubjectButtons"
              :key="`subject-button-${button.value}`"
              class="subject-buttons text-none"
              :value="button.value"
              text
              :disabled="button.disabled"
              :width="buttonMaxWidth"
              >{{ button.name }}
            </v-btn>
          </v-btn-toggle>
        </template>
        <template #menu="menuProps">
          <business-unit-menu
            :items="menuProps.items"
            type="aside"
            label="Companies, teams, or users"
            :filter-value="menuProps.searchValue"
            @click="menuProps.handleSelection"
          />
        </template>
      </form-submission-filter-aside-panel>
    </v-col>

    <v-col v-if="getVisiblePanels === 'Forms'" class="shrink fill-height">
      <form-submission-filter-aside-panel
        panel-label="Forms"
        :items="formNameOptions"
        :selected-items="selectedFormIds"
        @save-filters="handleSelectFormName"
        @leave-panel="setVisiblePanel(null)"
      >
        <template #menu="menuProps">
          <form-submission-filter-aside-panel-item-menu
            label="Forms"
            :items="menuProps.items"
            :selected="menuProps.selectedItems"
            :filter-value="menuProps.searchValue"
            @changed="menuProps.handleSelection"
          />
        </template>
      </form-submission-filter-aside-panel>
    </v-col>

    <v-col v-if="getVisiblePanels === 'Actions'" class="shrink fill-height">
      <form-submission-filter-aside-panel
        panel-label="Actions"
        :items="getFormActions"
        :selected-items="selectedFormActions"
        @save-filters="handleSelectAction"
        @leave-panel="setVisiblePanel(null)"
      >
        <template #menu="menuProps">
          <form-submission-filter-aside-panel-item-menu
            label="Actions"
            :items="menuProps.items"
            :selected="menuProps.selectedItems"
            :filter-value="menuProps.searchValue"
            @changed="menuProps.handleSelection"
          />
        </template>
      </form-submission-filter-aside-panel>
    </v-col>

    <v-col v-if="getVisiblePanels === 'Locations'" class="shrink fill-height">
      <new-form-submission-filter-aside-location-menu
        panel-label="Locations"
        :selected-items="selectedLocations"
        @save-filters="handleSelectLocation"
        @leave-panel="setVisiblePanel(null)"
      />
    </v-col>

    <v-col v-if="getVisiblePanels === 'Tags'" class="shrink fill-height">
      <form-submission-filter-aside-panel
        panel-label="Tags"
        :items="tagOptions"
        :selected-items="selectedTags"
        @save-filters="handleSelectTag"
        @leave-panel="setVisiblePanel(null)"
      >
        <template #menu="menuProps">
          <form-submission-filter-aside-panel-item-menu
            label="Tags"
            :items="menuProps.items"
            :filter-value="menuProps.searchValue"
            @changed="menuProps.handleSelection"
          />
        </template>
      </form-submission-filter-aside-panel>
    </v-col>

    <v-col>
      <v-row no-gutters class="fill-height flex-column">
        <v-col class="shrink">
          <v-row no-gutters align="end" class="pt-3 pb-2 pr-4 white">
            <v-col class="shrink ma-1">
              <vs-heading :type="hasDateRange ? 'overline' : 'title'">
                Form submissions
              </vs-heading>
              <vs-heading v-if="hasDateRange" type="title">
                {{ dateRangeString }}
              </vs-heading>
            </v-col>

            <v-col class="shrink ma-1">
              <vs-button
                data-test="form-submissions-refresh"
                small
                icon="refresh"
                type="secondary"
                :loading="getFormSubmissionsLoading"
                @click="fetchFirstPage"
              />
            </v-col>

            <v-col class="grow ma-1">
              <vs-button
                v-if="!isFiltersVisible && filterPanelsAreClosed"
                icon="filter_alt"
                label="Filters"
                small
                type="secondary"
                @click="showFiltersAside"
              />
            </v-col>

            <v-col class="shrink ma-1">
              <v-menu
                v-model="showMenu"
                :close-on-content-click="false"
                :nudge-bottom="10"
                :nudge-width="310"
                class="rounded-xl"
                offset-y
                rounded="xl"
              >
                <template #activator="{ on }">
                  <vs-button
                    v-show="fillableForms.length"
                    data-test="new-form-submission-button"
                    label="New form submission"
                    icon="add"
                    v-on="on"
                  />
                </template>

                <new-form-selection
                  v-if="showMenu"
                  data-test="new-from-selection"
                  :forms="fillableForms"
                  @select="selectNewForm"
                />
              </v-menu>
            </v-col>
          </v-row>
        </v-col>

        <v-col class="content-wrapper">
          <main class="content">
            <forms-table
              :selected-form-id="selectedFormId"
              data-test="form-submissions-table"
            />
          </main>
        </v-col>
        <v-col class="shrink">
          <forms-bottom-bar />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import NewFormSelection from '@/components/forms/NewFormSelection.vue'
import FormsTable from '@/components/forms/FormsTable'
import FormsBottomBar from '@/components/forms/FormsBottomBar'
import FormSubmissionFilterAside from '@/components/forms/FormSubmissionFilterAside'
import FormSubmissionFilterAsidePanel from '@/components/forms/FormSubmissionFilterAsidePanel'
import BusinessUnitMenuItemsMixin from '@/components/forms/business-unit-menu-items-mixin'
import FormSubmissionFilterAsideMixin from '@/components/forms/form-submission-filter-aside-mixin'
import FormSubmissionFilterAsidePanelItemMenu from '@/components/forms/FormSubmissionFilterAsidePanelItemMenu'
import BusinessUnitMenu from '@/components/forms/BusinessUnitMenu'
import FormSubmissionFilterAsideLocationMenu from '@/components/forms/FormSubmissionFilterAsideLocationMenu'
import NewFormSubmissionFilterAsideLocationMenu from '@/components/forms/NewFormSubmissionFilterAsideLocationMenu'

export default {
  name: 'FormSubmissions',
  components: {
    FormsBottomBar,
    NewFormSelection,
    FormsTable,
    FormSubmissionFilterAside,
    FormSubmissionFilterAsidePanel,
    FormSubmissionFilterAsidePanelItemMenu,
    BusinessUnitMenu,
    FormSubmissionFilterAsideLocationMenu,
    NewFormSubmissionFilterAsideLocationMenu,
  },
  mixins: [BusinessUnitMenuItemsMixin, FormSubmissionFilterAsideMixin],
  data() {
    return {
      showMenu: false,
      selectedFormId: null,
    }
  },
  computed: {
    ...mapGetters({
      getAvailableSubjectButtons:
        'formSubmissionsFilters/getAvailableSubjectButtons',
      getSubjectSearchButtons: 'formSubmissionsFilters/subjectSearchButtons',
      getFormsLoading: 'forms/getLoading',
      getForms: 'forms/getAllVisible',
      getVisiblePanels: 'formSubmissionsFilters/getVisiblePanels',
      isFiltersVisible: 'formSubmissionsFilters/getFilterAsideVisibility',
      makeFilterArguments: 'formSubmissionsFilters/makeFilterArguments',
      getFormSubmissionsLoading: 'formSubmissions/getPageIsLoading',
      formSubmissionIsSaving: 'formSubmissions/isCreatingOrUpdating',
      isNotOnFirstPage: 'formSubmissions/canGotoPreviousPage',
      getLocationTypeById: 'locationTypes/getById',
      getLocationColorById: 'locationColors/getById',
      selectedLocations: 'formSubmissionsFilters/getInverseLocations',
      selectedTags: 'formSubmissionsFilters/getTags',
      selectedFormIds: 'formSubmissionsFilters/getInverseFormIds',
      selectedFormActions: 'formSubmissionsFilters/getInverseFormActions',
    }),
    ...mapState('locations', {
      locations: (state) => state.all,
    }),
    buttonMaxWidth() {
      return 283 / this.getAvailableSubjectButtons.length
    },
    tagOptions() {
      const values = new Set([...this.options.map((tag) => tag.name)])
      return [...values].map((tag) => ({ name: tag, id: tag, item: tag }))
    },
    isLoading() {
      return this.getFormsLoading
    },
    fillableForms() {
      return this.getForms.filter((f) => f.fillable)
    },
    dateRangeString() {
      if (!this.hasDateRange) return

      if (this.dateRangeStart.isSame(this.dateRangeEnd, 'day')) {
        return this.dateRangeStart.format('MMM DD')
      } else if (this.dateRangeStart.isSame(this.dateRangeEnd, 'month')) {
        return `${this.dateRangeStart.format(
          'MMM DD'
        )} - ${this.dateRangeEnd.format('DD')}`
      } else {
        return `${this.dateRangeStart.format(
          'MMM DD'
        )} to ${this.dateRangeEnd.format('MMM DD')}`
      }
    },
    filterPanelsAreClosed() {
      return this.getVisiblePanels === null
    },
    flattenedSelectedSubjects() {
      return this.getSubjectSearchIds.map(
        (id) => id.userId ?? id.businessUnitId
      )
    },
    formattedLocations() {
      return [
        {
          id: null,
          title: 'No location',
          subTitle: 'Show submissions with no location field on form',
          fullName: '',
          order: 1,
          parentId: null,
        },
        ...Object.values(this.locations).map((l) => ({
          id: l.id,
          title: l.location,
          subTitle: [
            this.getLocationTypeById(l.locationTypeId)?.name,
            this.getLocationColorById(l.colorId)?.name,
            l.name.endsWith(`> ${l.location}`)
              ? l.name.replace(`> ${l.location}`, '')
              : null,
          ]
            .filter(Boolean)
            .join(' · '),
          parentId: l.parentId ?? null,
          locationTypeId: l.locationTypeId,
          fullName: l.name,
        })),
      ]
    },
  },
  watch: {
    '$route.params.id': {
      handler(id) {
        this.selectedFormId = id
      },
      immediate: true,
    },
    formSubmissionIsSaving(_, newValue) {
      if (newValue && !this.isNotOnFirstPage) {
        this.fetchFirstPage()
      }
    },
  },
  created() {
    document.title = 'Forms - IronSight'
  },
  destroyed() {
    document.title = 'IronSight'
  },
  async mounted() {
    await this.fetchFirstPage()
    await this.fetchTags()
  },
  methods: {
    ...mapActions({
      fetchFirstFormSubmissionPage:
        'formSubmissions/fetchFirstFormSubmissionPage',
      showFiltersAside: 'formSubmissionsFilters/showAside',
      hideFiltersAside: 'formSubmissionsFilters/hideAside',
      fetchTags: 'formSubmissions/fetchTags',
      updateSubjectSearchIds: 'formSubmissionsFilters/updateSubjectSearchIds',
      setInverseSelectedFormActions:
        'formSubmissionsFilters/setInverseFormActions',
      setInverseSelectedLocations: 'formSubmissionsFilters/setInverseLocations',
      setSelectedTags: 'formSubmissionsFilters/setTags',
      setSubjectButtons: 'formSubmissionsFilters/setSubjectSearchButtons',
      setInverseSelectedFormIds: 'formSubmissionsFilters/setInverseFormIds',
      setVisiblePanel: 'formSubmissionsFilters/setVisiblePanel',
    }),
    closeMenu() {
      this.showMenu = false
    },
    selectNewForm({ formId, businessUnitId }) {
      this.closeMenu()
      this.$router.push({
        name: 'submit-form',
        params: { id: 'new', formId, businessUnitId },
      })
    },
    async fetchFirstPage() {
      await this.fetchFirstFormSubmissionPage(this.makeFilterArguments)
    },
    handleFilterChanged() {
      this.fetchFirstFormSubmissionPage(this.makeFilterArguments)
    },
    handleSelectFormName(selectedIds) {
      const inverseFormIds = Object.values(this.getAllVisibleForms)
        .filter((form) => selectedIds.includes(form.id))
        .map((form) => form.id)
      this.setInverseSelectedFormIds(inverseFormIds)
      this.handleFilterChanged()
      this.setVisiblePanel(null)
    },
    handleSelectAction(selectedIds) {
      const inverseFormActions = Object.values(this.getFormActions)
        .filter((action) => selectedIds.includes(action.id))
        .map((action) => action.id)
      this.setInverseSelectedFormActions(inverseFormActions)
      this.handleFilterChanged()
      this.setVisiblePanel(null)
    },
    handleSelectLocation(selectedIds) {
      this.setInverseSelectedLocations(selectedIds)
      this.handleFilterChanged()
      this.setVisiblePanel(null)
    },
    handleSelectTag(tags) {
      this.setSelectedTags({ tags })
      this.handleFilterChanged()
      this.setVisiblePanel(null)
    },
    handleButtonsUpdate(buttons) {
      this.setSubjectButtons(buttons)
    },
    handleSelectSubject(items) {
      const subjects = items.map((item) => {
        return {
          userId: item?.userId ?? null,
          businessUnitId: item?.businessUnitId,
        }
      })
      this.updateSubjectSearchIds({ subjects })
      this.handleFilterChanged()
      this.setVisiblePanel(null)
    },
  },
}
</script>

<style scoped>
.content-wrapper {
  overflow: auto;
  height: 100%;
}

.content {
  background-color: var(--color-white);
  height: 100%;
  overflow: auto;
}

.nowrap {
  flex-wrap: nowrap;
}

.subject-buttons {
  font-family: Lato, sans-serif;
}
</style>
