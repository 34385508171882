<template>
  <div
    class="wrapper"
    :class="{
      'mini-label': miniLabel,
      'has-error': errorMessages && errorMessages.length,
      'has-hint': hint,
      disabled,
    }"
  >
    <label v-if="label && !hideMiniLabel">
      <div class="mask">
        <svg
          width="1128"
          height="8"
          viewBox="0 0 1128 8"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 7.88095C4.16814 3.92624 9.80073 1.5 16 1.5H1128V0H16C9.8995 0 4.31451 2.20715 0 5.86646V7.88095Z"
            fill="white"
          />
        </svg>
      </div>
      <div class="label-text">
        {{ label }}
      </div>
    </label>

    <div class="outer-affix">
      <div
        ref="field"
        class="field"
        data-test="input-wrapper-input"
        @click="handleFieldClick"
      >
        <slot />
        <div v-if="$slots.append" class="field-suffix">
          <slot name="append" />
        </div>
      </div>

      <div v-if="$slots['outer-append']" class="outer-suffix">
        <slot name="outer-append" />
      </div>
    </div>

    <div v-if="hint && errorMessages && !errorMessages.length" class="hint">
      <vs-text type="subdued">{{ hint }}</vs-text>
    </div>

    <div v-if="$slots['bottom-right']" class="float-right">
      <slot name="bottom-right" />
    </div>

    <transition-group name="invalid">
      <div
        v-for="(message, index) in errorMessages"
        :key="index + message"
        class="error-message"
      >
        {{ message }}
      </div>
    </transition-group>
  </div>
</template>

<script>
export const inputWrapperProps = {
  /**
   * Visually communicate to the user that they can't interact with the input
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * Adds a subdued text under the field
   */
  hint: {
    type: String,
    default: null,
  },
  /**
   * Adds the input placeholder and mini label
   */
  label: {
    type: String,
    default: null,
  },
  /**
   * Adds the input placeholder and mini label
   */
  hideMiniLabel: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    default: null,
  },
}

export default {
  props: {
    ...inputWrapperProps,
    /**
     * Adds a error messages under the field
     */
    errorMessages: {
      type: Array[String],
      default: null,
    },
    /**
     * Determines if the label should be a mini label
     */
    miniLabel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  watch: {},
  methods: {
    handleFieldClick(event) {
      this.$emit('on-field-click', event)
      this.$emit('click', event)
    },
  },
}
</script>

<style scoped>
.wrapper {
  position: relative;
  font-family: Lato, sans-serif;
  margin: 0 -1px; /* Offset for the curve and padding */
}

::v-deep input,
::v-deep textarea {
  flex: 1 1 auto;
  min-width: 0;
  display: block;
  width: 100%;
  height: 48px;
  min-height: 48px;
  font-size: 16px;
  line-height: 20px;
  padding: calc(var(--space-base) - 2px) var(--space-base);
  background-color: transparent;
  box-sizing: border-box;
}

::v-deep input:focus,
::v-deep textarea:focus {
  outline: none;
}

::v-deep input[type='number'] {
  text-align: right;
}

/* Field
   ------------------------------------------------------------------------- */

.field {
  position: relative;
  display: flex;
  width: 100%;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey--lighter);
  border-radius: 24px;
  box-sizing: border-box;
  transition: all 200ms;
}

.field:focus-within,
.field:hover {
  border-color: var(--color-blue);
}

.has-error .field {
  border-color: var(--color-red);
  margin-bottom: var(--space-smaller);
}

.has-hint .field {
  margin-bottom: var(--space-smaller);
}

.field-suffix {
  padding-right: var(--space-base);
  display: flex;
  flex: 0;
  align-items: center;
  height: 48px;
}

/* Disabled
   ------------------------------------------------------------------------- */

.disabled {
  pointer-events: none;
}

.disabled .field {
  background-color: var(--color-silver);
  border-color: var(--color-grey--lightest);
  color: var(--color-grey--light);
}

/* Label
   ------------------------------------------------------------------------- */

label {
  position: absolute;
  top: 0;
  left: 9px;
  padding: 0 var(--space-small);
  font-size: 14px;
  color: var(--color-grey);
  line-height: 16px;
  pointer-events: none;
  z-index: 1;
}

.label-text {
  transform: translateY(0);
  opacity: 0;
  transition: all 200ms;
}

.mini-label .label-text {
  opacity: 1;
  transform: translateY(-50%);
}

/* Mask
 *
 * 1. Offset for the border
 * ------------------------------------------------------------------------- */

.mask {
  position: absolute;
  width: 100%;
  top: 0;
  left: -1px; /* 1 */
  overflow: hidden;
  transform: scaleX(0);
  transition: all 200ms;
}

.mini-label .mask {
  transform: scaleX(1);
}

svg {
  display: block;
}

/* Error messages
   ------------------------------------------------------------------------- */

.error-message {
  color: var(--color-red);
  padding: 0 var(--space-base);
  margin-bottom: var(--space-smaller);
}

.error-message:last-of-type {
  margin-bottom: 0;
}

/* Hint
   ------------------------------------------------------------------------- */

.hint {
  padding: 0 var(--space-base);
  margin-bottom: var(--space-smaller);
}

.hint:last-of-type {
  margin-bottom: 0;
}

/* Outer affix
   ------------------------------------------------------------------------- */

.outer-affix {
  display: flex;
  align-items: center;
}

.outer-suffix {
  pointer-events: auto;
  flex: 0;
}

/* Invalid transition
   ------------------------------------------------------------------------- */

.invalid-enter-active {
  animation: shake 800ms cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

.invalid-leave-active {
  position: absolute;
}

.invalid-move {
  transition: transform 2s;
}

.invalid-enter-active,
.invalid-leave-active {
  transform: scale(1);
  transition: all 100ms;
  will-change: transform, opacity;
}

.invalid-enter,
.invalid-leave-to {
  transform: scale(0.8);
  opacity: 0;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
