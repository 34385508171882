export const priorityMap = {
  New: { id: 1, name: 'New', color: 'blue', icon: 'fiber_manual_record' },
  Normal: { id: 0, name: 'Normal', color: '', icon: 'remove' },
  High: { id: 2, name: 'High', color: 'red', icon: 'flag' },
  Deferred: {
    id: 3,
    name: 'Deferred',
    color: 'grey darken-2',
    icon: 'brightness_3',
  },
  Filler: { id: 4, name: 'Filler', color: 'orange', icon: 'snooze' },
}
export const priorities = Object.values(priorityMap)

export const PNG = { extension: 'png', type: 'image/png' }
export const JPG = { extension: 'jpg', type: 'image/jpeg' }
export const JPEG = { extension: 'jpeg', type: 'image/jpeg' }
export const PDF = { extension: 'pdf', type: 'application/pdf' }
export const CSV = {
  extension: 'csv',
  type: 'text/csv',
  alternativeTypes: ['application/vnd.ms-excel'],
}
export const DOCX = {
  extension: 'docx',
  type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}
export const XLSX = {
  extension: 'xlsx',
  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export const AcceptedImageExtensions = [
  PNG.extension,
  JPG.extension,
  JPEG.extension,
]

export const AcceptedFileExtensions = [
  PNG.extension,
  JPG.extension,
  JPEG.extension,
  PDF.extension,
  DOCX.extension,
  XLSX.extension,
  CSV.extension,
]

export const AcceptedFileTypes = [
  PNG.type,
  JPG.type,
  JPEG.type,
  PDF.type,
  DOCX.type,
  XLSX.type,
  CSV.type,
  ...CSV.alternativeTypes,
]

// Size in MB
export const maxFileSize = 25

// In MilliSeconds
export const refreshButtonDebounceDuration = 10000

export const latitudeRange = {
  min: -90,
  max: 90,
}

export const longitudeRange = {
  min: -180,
  max: 180,
}

export const integerRange = {
  min: -2147483648,
  max: 2147483647,
}

export const unitLocationColors = [
  { primary: '#065fd4', secondary: '#377edc' }, // Blue
  { primary: '#d72638', secondary: '#df515f' }, // Red
  { primary: '#ffa500', secondary: '#ffb732' }, // Orange
]

export const kmToMilesConversion = 0.621371
