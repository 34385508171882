import { useHubs } from '@/common/useHubs'
import { useGetAccountEntitlements } from '@/api/accounts'
import { computed } from 'vue'
import { useStore } from 'vuex-composition-helpers'

export const useBusinessUnitPermissions = () => {
  const store = useStore()
  const { currentHubOwner } = useHubs()
  const currentHubOwnerBusinessUnitId = computed(
    () => currentHubOwner.value?.id ?? null
  )
  const { data: hubOwnerEntitlements } = useGetAccountEntitlements(
    currentHubOwnerBusinessUnitId
  )
  const { data: currentOrgEntitlements } = useGetAccountEntitlements()
  const isHubUser = computed<boolean>(
    () => store.getters['permissions/isHostOrganizationUser']
  )
  const isAdmin = computed<boolean>(() => store.getters['permissions/isAdmin'])
  const canSubmitForApproval = computed<boolean>(() =>
    store.getters['permissions/canSubmitForApproval'](
      currentOrgEntitlements.value?.organizationId
    )
  )

  // Entitlements
  const isCurrentHubPro = computed(
    () => hubOwnerEntitlements.value?.hasAccount ?? false
  )
  const isCurrentOrgPro = computed(
    () => currentOrgEntitlements.value?.hasAccount ?? false
  )
  const currentOrgHasInvoicingEntitlement = computed(
    () =>
      currentOrgEntitlements.value?.entitlements.includes('Invoicing') ?? false
  )

  // Guest requesters
  const canCreateNewGuestRequester = computed(
    () =>
      isAdmin.value &&
      !isHubUser.value &&
      !isCurrentHubPro.value &&
      isCurrentOrgPro.value
  )

  const canSendEmailForApproval = computed(
    () =>
      canSubmitForApproval.value &&
      !isHubUser.value &&
      !isCurrentHubPro.value &&
      isCurrentOrgPro.value
  )

  // Locations
  const canShowAllLocationsInLocationPicker = computed(
    () => !isHubUser.value && !isCurrentHubPro.value
  )
  const canCreateLocationOnTheFly = computed(
    () =>
      isAdmin.value &&
      (isHubUser.value || canShowAllLocationsInLocationPicker.value)
  )
  return {
    isCurrentHubPro,
    isCurrentOrgPro,
    canCreateNewGuestRequester,
    canSendEmailForApproval,
    canShowAllLocationsInLocationPicker,
    canCreateLocationOnTheFly,
    currentOrgHasInvoicingEntitlement,
  }
}
