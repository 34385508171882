import { render, staticRenderFns } from "./JobExtensionData.vue?vue&type=template&id=ea2d7b56&scoped=true&"
import script from "./JobExtensionData.vue?vue&type=script&setup=true&lang=ts&"
export * from "./JobExtensionData.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea2d7b56",
  null
  
)

export default component.exports