<template>
  <v-date-picker
    v-model="dateRange"
    class="date-picker"
    no-title
    range
    :min="minDate"
    :max="maxDate"
    show-adjacent-months
    @change="updateDateRange"
  >
    <slot></slot>
  </v-date-picker>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    startDate: {
      type: Object,
      default: moment(),
    },
    endDate: {
      type: Object,
      default: moment(),
    },
    maxRange: {
      type: Number | null,
      default: null,
    },
  },
  data() {
    return {
      dateRange: [],
      dateFormat: 'YYYY-MM-DD',
    }
  },
  computed: {
    hasDateRangeLimit() {
      return this.dateRange?.length === 1 ?? false
    },
    minDate() {
      if (!this.hasDateRangeLimit || this.maxRange === null) return
      const date = moment(this.dateRange[0])
      return date.subtract(this.maxRange, 'day').format(this.dateFormat)
    },
    maxDate() {
      if (!this.hasDateRangeLimit || this.maxRange === null) return
      const date = moment(this.dateRange[0])
      return date.add(this.maxRange, 'day').format(this.dateFormat)
    },
  },
  watch: {
    startDate() {
      this.resetDataFromProp()
    },
    endDate() {
      this.resetDataFromProp()
    },
  },
  created() {
    this.resetDataFromProp()
  },
  methods: {
    resetDataFromProp() {
      if (this.startDate === null && this.endDate === null) {
        this.dateRange = null
      } else {
        this.dateRange = [
          this.startDate.format(this.dateFormat),
          this.endDate.format(this.dateFormat),
        ]
      }
    },
    updateDateRange(newRange) {
      const firstVal = moment(newRange[0])
      const secondVal = moment(newRange[1])

      const start = secondVal.isBefore(firstVal) ? secondVal : firstVal
      const end = secondVal.isAfter(firstVal) ? secondVal : firstVal

      this.dateRange = [
        start.format(this.dateFormat),
        end.format(this.dateFormat),
      ]

      this.$emit('change', {
        start: moment(start).startOf('day'),
        end: moment(end).endOf('day'),
      })
    },
  },
}
</script>

<style scoped>
.date-picker {
  padding: var(--space-small) 0;
  border-radius: var(--space-base);
  border: 1px solid var(--color-grey--lighter);
}

:deep(.v-btn--disabled.accent) {
  background-color: var(--color-blue--lighter) !important;
  color: var(--color-blue--light) !important;
}

:deep(.v-date-picker-table--date .v-btn) {
  width: 24px;
  height: 24px;
}

:deep(.v-picker__body) {
  width: 100% !important;
}

:deep(.v-date-picker-table) {
  height: auto;
}

:deep(.v-date-picker-header__value) {
  font-family: var(--font-display);
}
</style>
