<template>
  <vs-wrapper>
    <vs-text-input
      data-test="text-area-field"
      :label="title"
      :hint="description"
      :max-length="schema.maxLength"
      :min-length="schema.minLength"
      :multiline="true"
      :value="formData"
      :error-messages="errorMessages"
      :disabled="disabled"
      @input="$emit('change', $event)"
    />
  </vs-wrapper>
</template>

<script>
export default {
  name: 'TextAreaField',
  props: {
    errorSchema: Object,
    formData: String,
    params: Object,
    rootSchema: Object,
    schema: Object,
    title: String,
    description: String,
    uiSchema: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    errorMessages() {
      const errors = this.errorSchema?.errors ?? []
      if (this.formData && typeof this.formData !== 'string') {
        errors.push('Text value expected.')
      }
      return errors
    },
  },
}
</script>

<style scoped></style>
