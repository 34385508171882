<template>
  <div>
    <v-sheet class="px-3 primary">
      <v-col class="overflow-y-auto">
        <vs-wrapper space="small">
          <vs-heading type="overline" class="textColor-blue--lighter"
            >Template Fields
          </vs-heading>

          <vs-button
            small
            class="btn-secondary"
            label="Back to form"
            icon="navigate_before"
            @click="$emit('reset-editor-visibility')"
          ></vs-button>

          <vs-wrapper direction="horizontal">
            <add-field-button
              :subject-field-exists="subjectFieldExists"
              :subject-location-field-exists="subjectLocationFieldExists"
              @field-clicked="addField"
            ></add-field-button>
          </vs-wrapper>
        </vs-wrapper>
      </v-col>
    </v-sheet>

    <div class="scroll">
      <v-list-item
        v-for="field in fieldDefinitions"
        :key="field.uniqueKey()"
        :class="{ highlight: field === selected }"
        @click="onFieldClicked(field)"
        @contextmenu="showContextMenu($event, field)"
      >
        <v-list-item-icon>
          <v-icon>
            {{ truncateLabel(getFieldIcon(field)) }}
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title v-text="getFieldLabel(field)"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>
  </div>
</template>

<script>
import VsText from '@/components/vision/VsText'
import * as fb from 'ironsight-form-builder'
import formFieldIcon from '@/utils/forms/form-field-icons'
import formFieldTitle from '@/utils/forms/form-field-title'
import VsWrapper from '@/components/vision/VsWrapper'
import VsHeading from '@/components/vision/VsHeading'
import VsButton from '@/components/vision/VsButton'
import AddFieldButton from '@/components/form-builder/AddFieldButton'
export default {
  name: 'FieldTemplatesTreeView',
  components: { AddFieldButton, VsButton, VsHeading, VsWrapper, VsText },

  props: {
    selected: {
      type: Object,
      default: () => {},
    },
    fieldDefinitions: {
      type: Array,
      default: () => {},
    },
    subjectLocationFieldExists: {
      type: Boolean,
      default: false,
    },
    subjectFieldExists: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getFieldIcon(field) {
      return formFieldIcon(field)
    },
    getFieldLabel(field) {
      return formFieldTitle(field)
    },
    truncateLabel(label) {
      if (label.length > 30) {
        return label.slice(0, 30) + '...'
      }
      return label
    },
    addField(field) {
      this.$emit('add-field', field)
    },
    onFieldClicked(field) {
      this.$emit('click-field', field)
    },
    showContextMenu(e, field) {
      this.$emit('right-click-field', { e, field })
    },
  },
}
</script>

<style scoped>
.highlight {
  background: var(--color-blue--lighter);
}
.scroll {
  overflow-y: scroll !important;
  height: 73vh;
  min-height: 57vh;
  max-height: 73vh;
}
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  background-color: var(--color-blue);
  --text-color: var(--color-blue--lighter);
}
</style>
