export default class FormSubmissionComment {
  constructor(json) {
    this.id = json.id
    this.text = json.comment
    this.createdBy = json.created_by
    this.created = json.created
  }

  id: string
  text: string
  createdBy: string
  created: string
}
