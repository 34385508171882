
import { defineComponent } from 'vue'
import { Prop } from 'vue/types/options'
import VsButton from '@/components/vision/VsButton.vue'

type PrimaryAction = {
  props: { label: string; loading: boolean }
  on: { click(): void }
}

type SecondaryAction = PrimaryAction

export default defineComponent({
  name: 'ActionBar',
  components: { VsButton },

  props: {
    primaryAction: {
      type: Object as Prop<PrimaryAction>,
      required: true,
    },
    secondaryAction: {
      type: Object as Prop<SecondaryAction>,
      default: () => null,
    },
  },
})
