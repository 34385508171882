import { defineStore } from 'pinia'
import { ref, watch } from 'vue'
import { useScreen } from '@/common/useScreen'
import { watchOnce } from '@vueuse/core'

export const useNavStore = defineStore('nav', () => {
  const isNavVisible = ref(true)
  const toggleNav = () => {
    isNavVisible.value = !isNavVisible.value
  }
  const { isMobileView } = useScreen()
  watch(isMobileView, (newVal) => {
    isNavVisible.value = !newVal
  })
  watchOnce(
    isMobileView,
    () => {
      if (isMobileView.value !== undefined && isMobileView.value !== null) {
        isNavVisible.value = !isMobileView.value
      }
    },
    { immediate: true }
  )

  return {
    isNavVisible,
    toggleNav,
  }
})
