import { ComputedRef, onMounted } from 'vue'
import { UnwrapNestedRefs } from 'vue/types/v3-generated'
import { LngLatLike } from 'mapbox-gl'
import { FormStates } from '@/components/locations/on-the-fly-locations/form-states'

export function useMinimapView(
  formData: UnwrapNestedRefs<FormStates>,
  isInMapView: ComputedRef<boolean | undefined>,
  mapCenterPoint: ComputedRef<
    { latitude: number; longitude: number; zoom: number } | undefined
  >
) {
  const onMinimapMoved = (e: LngLatLike) => {
    formData.latitude = parseFloat(
      (e as { lng: number; lat: number }).lat.toFixed(8)
    )
    formData.longitude = parseFloat(
      (e as { lng: number; lat: number }).lng.toFixed(8)
    )
  }
  onMounted(() => {
    if (!isInMapView.value) {
      formData.latitude = mapCenterPoint.value?.latitude ?? 0
      formData.longitude = mapCenterPoint.value?.longitude ?? 0
    }
  })
  return {
    onMinimapMoved,
  }
}
