<template>
  <div ref="autocomplete" class="autocomplete">
    <InputWrapper
      ref="inputWrapper"
      :label="label"
      :disabled="disabled"
      :mini-label="hasMiniLabel"
      :hide-mini-label="hideMiniLabel"
      :error-messages="allErrorMessages"
      :hint="hint"
      @on-field-click="focus"
    >
      <AutocompleteChips
        :value="multiselectValue"
        :items="localItems"
        :item-text="itemText"
        :item-value="itemValue"
        :maximum-chips="multiselectMaximumChips"
        :label="label"
        @input="handleSelectedItem"
        @click:chip="handleChipClick"
      >
        <input
          ref="input"
          :type="type"
          :value="getInputValue"
          :placeholder="inputPlaceholder"
          :disabled="disabled"
          @input="handleInputChange"
          @keyup.delete="handleBackSpace"
          @keydown.tab="close"
          @focus="handleFocus"
          @focusout="handleFocusOut"
          @[determineKeyDown]="$listeners.keydown"
        />
      </AutocompleteChips>

      <template
        v-if="!freeForm && !freeFormCreate && !disabled && !disableClear"
        #append
      >
        <v-icon
          v-if="value && !multiselect"
          small
          data-test="vsautocomplete-clear-option"
          @click.stop="handleClearValue"
        >
          close
        </v-icon>
        <v-icon
          v-else
          tabindex="-1"
          :class="{
            flip: hasFlipAnimation && focused,
          }"
          data-test="vsautocomplete-toggle"
          @click.stop="toggle"
        >
          {{ dropDownIcon }}
        </v-icon>
      </template>
      <template #outer-append>
        <slot name="outer-append" />
      </template>
    </InputWrapper>
    <div v-if="showMenu" ref="popover" class="popover">
      <slot
        :handle-selected-item="handleSelectedItem"
        :local-search-value="localSearchValue"
        :get-input-value="getInputValue"
        :local-items="localItems"
      >
        <AutocompleteMenu
          data-test="vsautocomplete-menu"
          :label="label"
          :not-found-text="notFoundText"
          :empty-state-text="emptyStateText"
          :checkbox-value="multiselectValue"
          :multiselect="multiselect"
          :items="localItems"
          :item-text="itemText"
          :item-value="itemValue"
          :filter-value="localSearchValue"
          :free-form="freeForm"
          :type="type"
          @click="handleSelectedItem"
        >
          <template #prepend-item>
            <slot name="prepend-item" />
          </template>
          <template #append-item>
            <div v-if="freeFormCreate">
              <v-divider class="mb-2" />
              <v-list-item
                v-if="localSearchValue"
                data-test="create-tile"
                @click.stop="
                  () => {
                    handleSelectedItem(localSearchValue)
                  }
                "
              >
                <v-list-item-content>
                  <vs-text>{{ freeFormCreateDescription }}</vs-text>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-else data-test="create-hint" disabled>
                <v-list-item-content>
                  <vs-text
                    ><i
                      >Start typing to create a {{ label.toLowerCase() }}</i
                    ></vs-text
                  >
                </v-list-item-content>
              </v-list-item>
            </div>
            <slot :currentSearchValue="localSearchValue" name="append-item" />
          </template>
        </AutocompleteMenu>
      </slot>
    </div>
  </div>
</template>

<script>
import { inputProps } from '../VsTextInput'
import { sharedOptions } from '../helper'
import InputWrapper from '../InputWrapper'
import AutocompleteChips from './AutocompleteChips'
import AutocompleteMenu from './AutocompleteMenu'
import vClickOutside from 'v-click-outside'
import { getScrollParent } from '@/utils/get-scroll-parent'
import { isEqual, isObject } from 'lodash'
import VsText from '@/components/vision/VsText.vue'

const { bind, unbind } = vClickOutside.directive

/**
 * Autocomplete allows you to quickly select a value from a list of possible
 * options
 */
export default {
  components: {
    VsText,
    InputWrapper,
    AutocompleteChips,
    AutocompleteMenu,
  },
  mixins: [sharedOptions],
  props: {
    ...inputProps,
    /**
     *  The list of options that the user can choose from.
     *
     * @param {Object} items Items object.
     * @param {String} items.text The label of the option.
     * @param {String} items.description The label of the option.
     * @param {Number} items.value The ID of the option.
     * @param {String} items.icon Adds an icon beside the label.
     * @param {String} items.color Adds a color on the icon. If there's no icon, it uses an avatar to visualize the color.
     * @param {Boolean} items.disabled Disables interaction
     * @param {Boolean} items.chip Adds a trailing chip
     * @param {Boolean} item.indent Adds indentation with joining line
     */
    items: {
      type: Array[Object],
      default: null,
    },
    /**
     * Change the property of item's text value
     */
    itemText: {
      type: String,
      default: 'text',
    },
    /**
     * Change the property of item's value
     */
    itemValue: {
      type: String,
      default: 'value',
    },
    /**
     * Returns the value of the field when searching. use the `.sync` modifier
     * to catch it
     */
    searchValue: {
      type: String,
      default: null,
    },
    /**
     * Allows the value of autocomplete to be any string the user can input
     */
    freeForm: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows the value of autocomplete to be any string the user can input.
     * Styles the the free form input option text to imply creation and persists even if other options are available.
     */
    freeFormCreate: {
      type: Boolean,
      default: false,
    },
    /**
     * The autocomplete value
     */
    value: {
      type: [String, Number, Array, Object],
      default: null,
    },
    /**
     * Allows the user to select multiple choices at once
     */
    multiselect: {
      type: Boolean,
      default: false,
    },
    /**
     * Limits the number of chips that display when multiple options are selected
     */
    multiselectMaximumChips: {
      type: Number,
      default: null,
    },
    /**
     * Change the default not found message
     */
    notFoundText: {
      type: String,
      default: null,
    },
    /**
     * Change the default empty message when there are no options to choose from
     */
    emptyStateText: {
      type: String,
      default: null,
    },
    /**
     * Custom error messages from outside the component
     */
    errorMessages: {
      type: Array,
      default: null,
    },
    /**
     * Change the dropdown Icon
     */
    dropDownIcon: {
      type: String,
      default: 'keyboard_arrow_down',
    },
    /**
     * remove the clear button
     */
    disableClear: {
      type: Boolean,
      default: false,
    },
    /**
     * Specify a placeholder to be used instead of label.
     */
    placeholder: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      localSearchValue: null,
      previousSearchValue: null,
      multiselectValue: this.multiselect ? this.value : [],
      focused: false,
      showMenu: false,
      resizeObserver: new ResizeObserver(this.updatePopOverBounds),
      localItems: [],
    }
  },
  computed: {
    getInputValue() {
      if (this.localSearchValue !== null) return this.localSearchValue
      if (this.multiselect) return ''
      if (this.freeForm || this.freeFormCreate) return this.value
      if (this.value || this.value === 0) {
        return this.selectedItem
          ? this.selectedItem[this.itemText]
          : `Unknown ${this.label}`
      }

      return null
    },
    selectedItem() {
      return this.items.find((item) => {
        if (isObject(this.value)) {
          return isEqual(this.value, item[this.itemValue])
        }
        return item && item[this.itemValue] === this.value
      })
    },
    hasMiniLabel() {
      if (this.multiselect) return this.value && !!this.value.length
      return !!this.value || this.value === 0
    },
    inputPlaceholder() {
      return this.multiselect && this.hasMiniLabel
        ? ''
        : this.placeholder ?? this.label
    },
    determineKeyDown() {
      return this.$listeners.keydown ? 'keydown' : null
    },
    hasFlipAnimation() {
      return this.dropDownIcon === 'keyboard_arrow_down'
    },
    freeFormCreateDescription() {
      if (this.freeFormCreate) {
        return `Use "${this.localSearchValue}"`
      }
      return null
    },
  },
  watch: {
    value(newValue) {
      this.multiselect && (this.multiselectValue = newValue)
    },
    // The autocomplete only rerenders if there is new data, as opposed to every polling cycle
    items(newItems) {
      if (!isEqual(newItems, this.localItems)) {
        this.localItems = newItems
      }
    },
    localSearchValue(newValue, oldValue) {
      if (oldValue === null && newValue !== null) {
        this.focus()
      }
    },
    async showMenu(show) {
      if (show) {
        bind(this.$el, { value: this.onClickOutside })
        this.scrollParent.addEventListener('scroll', this.updatePopOverBounds)
        this.resizeObserver.observe(this.scrollParent)
        await this.$nextTick()
        this.resizeObserver.observe(this.$refs.popover)
      } else {
        unbind(this.$el)
        this.scrollParent.removeEventListener(
          'scroll',
          this.updatePopOverBounds
        )
        this.resizeObserver.disconnect()
      }
    },
  },
  mounted() {
    this.scrollParent = getScrollParent(this.$refs.autocomplete)
    this.autofocus && this.$refs.input.focus()
    this.localItems = this.items
    this.resizeObserver.disconnect()
  },
  beforeDestroy() {
    this.scrollParent.removeEventListener('scroll', this.updatePopOverBounds)
  },
  methods: {
    clearSearchValue() {
      this.localSearchValue = null
      this.previousSearchValue = null
    },
    handleSelectedItem(value, focusWhenMultiselect = true) {
      if (this.multiselect) {
        const computedVal = this.multiselectValue.includes(value)
          ? this.handleChipDelete(value)
          : this.handleChipAdd(value)
        this.handleMultiselectChange(computedVal)
        this.clearSearchValue()
        if (focusWhenMultiselect) {
          this.focus()
        }
        return
      }

      this.close()
      this.$emit('input', value)
    },
    handleMultiselectChange(value) {
      this.multiselectValue = value
      this.$emit('input', value)
    },
    handleFocus() {
      this.focus()
    },
    handleFocusOut(e) {
      // return focus back to the input if the click is inside the popover
      if (
        this.showMenu &&
        e.relatedTarget &&
        this.$refs.popover.contains(e.relatedTarget)
      ) {
        this.focus()
      } else if (this.freeForm || this.freeFormCreate) {
        this.handleSelectedItem(e.target.value, !this.multiselect)
      }
    },
    handleClearValue() {
      this.focus()
      this.handleSelectedItem(null)
    },
    handleInputChange(event) {
      this.previousSearchValue = this.localSearchValue
      this.localSearchValue = event.target.value.trim()
      this.$emit('update:searchValue', this.localSearchValue)
    },
    focus() {
      if (this.disabled) return
      this.focused = true
      this.showMenu = true
      this.$refs.input.focus()
    },
    close() {
      this.focused = false
      this.showMenu = false
      this.previousSearchValue = this.localSearchValue
      this.localSearchValue = null
      this.runValidation()
      if (this.freeForm || this.freeFormCreate) {
        this.$emit('input', this.previousSearchValue)
      }
    },
    toggle() {
      this.showMenu ? this.close() : this.focus()
    },
    handleChipDelete(value) {
      return this.multiselectValue.filter((item) => item !== value)
    },
    handleChipAdd(value) {
      return [...this.multiselectValue, value]
    },
    handleChipClick(value) {
      this.$emit('click:chip', value)
    },
    handleBackSpace() {
      if (
        this.multiselect &&
        !this.previousSearchValue &&
        this.multiselectValue.length
      ) {
        const newValue = this.multiselectValue.slice(-1)[0]
        const item = this.items.find(
          (item) => item[this.itemValue] === newValue
        )

        if (newValue && item && !item.disabled) {
          this.handleSelectedItem(newValue)
        }
      }

      !this.localSearchValue && (this.previousSearchValue = '')
    },
    onClickOutside() {
      this.close()
    },
    updatePopOverBounds() {
      if (!this.showMenu || !this.$refs.popover) {
        return
      }
      const popoverBounds = this.$refs.popover.getBoundingClientRect()
      const parentBounds = this.scrollParent.getBoundingClientRect()
      const fieldBounds = this.$refs.input.getBoundingClientRect()
      const inputWrapperBounds =
        this.$refs.inputWrapper.$el.getBoundingClientRect()

      this.$refs.popover.style.width = `${inputWrapperBounds.width}px`
      this.$refs.popover.style.top = `${
        fieldBounds.bottom +
        parseInt(getComputedStyle(this.$refs.popover).marginTop)
      }px`

      if (fieldBounds.bottom + popoverBounds.height < parentBounds.bottom) {
        this.$refs.popover.style.transform = 'initial'
      } else {
        this.$refs.popover.style.transform = `translateY(-${
          popoverBounds.height + fieldBounds.height + 16
        }px)`
      }
    },
  },
}
</script>

<style scoped>
.autocomplete {
  position: relative;
  cursor: text;
}

.popover {
  position: fixed;
  margin-top: 4px;
  z-index: var(--elevation-popover);
  opacity: 0;
  animation: fadeIn 200ms ease forwards;
  transition: transform 100ms ease;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey--lighter);
  border-radius: var(--space-base);
  box-sizing: border-box;
  text-decoration: none;
  color: inherit;
  overflow: hidden;
  box-shadow: var(--shadow-high);
}

.flip {
  transform: rotate(180deg);
}

input {
  height: 40px;
  min-height: 40px;
  padding: var(--space-small);
  padding-right: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
