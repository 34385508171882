<template>
  <div>
    <v-treeview
      v-if="!isLoadingSavedFilters"
      :active.sync="activeSavedFilter"
      class="pb-0"
      activatable
      dense
      :open.sync="treeviewOpen"
      open-on-click
      :items="savedFilterItems"
    >
      <template #prepend="{ item }">
        <v-icon v-if="item.filter" small class="material-icons-outlined mr-2">
          folder
        </v-icon>
      </template>
      <template #label="{ item }">
        <vs-text
          :class="{ 'filter-treeview-title': !item.filter, 'mr-1': true }"
        >
          {{ item.name }}
        </vs-text>
      </template>
      <template #append="{ item }">
        <v-row v-if="item.filter" justify="end" align="center" class="mr-1">
          <p v-if="item.count === null" class="filter-loading-bar mb-0">
            <v-progress-linear
              buffer-value="0"
              color="blue"
              stream
              :height="32"
            />
          </p>
          <vs-text v-else type="caption"> {{ item.count }}</vs-text>
          <v-menu offset-y rounded="xl">
            <template #activator="{ on, attrs }">
              <v-btn
                :class="{
                  'ma-0': true,
                  hidden: item.default,
                  'v-tree-view-menu-pointer': true,
                }"
                small
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon :data-test="item.id + '-more-options'" small>
                  more_horiz
                </v-icon>
              </v-btn>
            </template>

            <v-list dense>
              <v-list-item
                v-if="item.id !== activeSavedFilterId"
                @click="handleEditFilter(item.id)"
              >
                <v-list-item-content>
                  <vs-text>Save</vs-text>
                  <vs-text type="caption">
                    Update with current filter settings
                  </vs-text>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon>save</v-icon>
                </v-list-item-action>
              </v-list-item>

              <v-list-item @click="clickEditFilterName(item.id)">
                <v-list-item-content>
                  <vs-text>Rename</vs-text>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon>edit</v-icon>
                </v-list-item-action>
              </v-list-item>

              <v-list-item @click="handleDeleteFilter(item.id)">
                <v-list-item-content>
                  <vs-text>Delete</vs-text>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon>delete</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
      </template>
    </v-treeview>
    <vs-button
      v-if="shouldShowSaveButton"
      class="pl-1"
      icon="add"
      label="Save as new filter preset"
      type="tertiary"
      small
      data-test="save-as-new-filter-button"
      @click="clickSaveNewFilter"
    />
    <vs-bottom-sheet :is-visible="editingFilterBottomSheetVisible">
      <bottom-sheet-layout
        title="Edit filter set"
        @on-dismiss="dismissEditFilterName"
      >
        <vs-form ref="editForm">
          <vs-text-input
            v-model="newFilterName"
            label="Name"
            required
            data-test="edit-filter-input"
          />
          <vs-button
            data-test="save-new-filter-button"
            label="Save"
            :loading="isSavingSavedFilters"
            full-width
            type="primary"
            @click="handleEditFilterName"
          />
        </vs-form>
      </bottom-sheet-layout>
    </vs-bottom-sheet>
    <vs-bottom-sheet :is-visible="savingFilterBottomSheetVisible">
      <bottom-sheet-layout
        title="Save filter set"
        @on-dismiss="dismissSaveNewFilter"
      >
        <vs-form ref="newForm">
          <vs-text-input
            v-model="newFilterName"
            label="Name"
            required
            data-test="save-new-filter-input"
          />
          <vs-button
            data-test="save-new-filter-button"
            label="Save"
            :loading="isSavingSavedFilters"
            full-width
            type="primary"
            @click="handleSaveNewFilter"
          />
        </vs-form>
      </bottom-sheet-layout>
    </vs-bottom-sheet>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BottomSheetLayout from '@/components/common/BottomSheetLayout'

export default {
  name: 'FormSubmissionSavedFilters',
  components: {
    BottomSheetLayout,
  },
  data() {
    return {
      editingFilterBottomSheetVisible: false,
      editingFilterId: null,
      newFilterName: '',
      savingFilterBottomSheetVisible: false,
      treeviewOpen: ['default-saved-filters'],
    }
  },
  computed: {
    ...mapGetters({
      activeSavedFilterId: 'formSubmissionsFilters/getActiveSavedFilterId',
      isLoadingSavedFilters: 'formSubmissionsFilters/getLoadingSavedFilters',
      savedFilters: 'formSubmissionsFilters/getAllSavedFilters',
      isSavingSavedFilters: 'formSubmissionsFilters/getSavingSavedFilters',
    }),
    activeSavedFilter: {
      get() {
        return this.activeSavedFilterId != null
          ? [this.activeSavedFilterId]
          : []
      },
      set(selected) {
        if (selected.length === 0) return
        const id = selected[0]
        this.$emit('apply-filter', {
          id,
          ...this.savedFilters[id].filter,
        })
      },
    },
    savedFilterItems() {
      return [
        {
          id: 'default-saved-filters',
          name: 'SAVED',
          children: [...Object.values(this.savedFilters)],
        },
      ]
    },
    shouldShowSaveButton() {
      return (
        !this.isLoadingSavedFilters &&
        this.treeviewOpen.length > 0 &&
        this.activeSavedFilterId === null
      )
    },
  },
  methods: {
    ...mapActions({
      deleteFilter: 'formSubmissionsFilters/deleteFilter',
      saveEditFilter: 'formSubmissionsFilters/editFilterWithCurrentFilter',
      saveEditFilterName: 'formSubmissionsFilters/editFilterName',
      saveNewFilter: 'formSubmissionsFilters/saveCurrentFilterSet',
    }),
    clickEditFilterName(id) {
      this.newFilterName = this.savedFilters[id]?.name
      this.editingFilterId = id
      this.editingFilterBottomSheetVisible = true
    },
    clickSaveNewFilter() {
      this.savingFilterBottomSheetVisible = true
    },
    dismissEditFilterName() {
      this.editingFilterBottomSheetVisible = false
      this.newFilterName = ''
      this.editingFilterId = null
    },
    dismissSaveNewFilter() {
      this.savingFilterBottomSheetVisible = false
    },
    async handleDeleteFilter(id) {
      const confirmation = window.confirm(
        'Are you sure you want to delete this filter?'
      )

      if (confirmation) {
        await this.deleteFilter(id)
      }
    },
    async handleEditFilter(id) {
      await this.saveEditFilter(id)
    },
    async handleEditFilterName() {
      if (this.$refs.editForm.isValid()) {
        await this.saveEditFilterName({
          id: this.editingFilterId,
          name: this.newFilterName,
        })

        this.editingFilterBottomSheetVisible = false
        this.newFilterName = ''
        this.editingFilterId = null
      }
    },
    async handleSaveNewFilter() {
      if (this.$refs.newForm.isValid()) {
        await this.saveNewFilter(this.newFilterName)

        this.savingFilterBottomSheetVisible = false
        this.newFilterName = ''
      }
    },
  },
}
</script>

<style scoped>
:deep(.v-treeview-node--active) {
  pointer-events: none;
}

:deep(.v-treeview-node--active i) {
  color: var(--color-blue);
}

:deep(.v-treeview-node--active p) {
  color: var(--color-blue);
}

:deep(.v-treeview-node__level) {
  display: none;
}

:deep(.v-treeview-node__root) {
  padding-right: 0;
  padding-left: 0;
}

:deep(.v-treeview-node__prepend) {
  min-width: 0;
  margin-right: 0;
}

.v-tree-view-menu-pointer {
  pointer-events: auto;
}

:deep(.bottom-drawer h3) {
  font-size: 24px;
}

.hidden {
  visibility: hidden;
}

.filter-treeview-title {
  font-family: var(--font-display);
  font-size: 12px;
  font-weight: bold;
}

.filter-loading-bar {
  height: var(--space-larger);
  width: var(--space-larger);
}

:deep(.v-treeview-node__root.v-treeview-node--active:before),
:deep(.v-treeview-node__root:hover:before) {
  border-radius: var(--space-smaller);
}
</style>
