<template>
  <p class="content">
    <v-tooltip :disabled="!value" :open-delay="300" max-width="250px" top>
      <template #activator="{ on }">
        <span v-on="on"><Truncate :content="value" /></span>
      </template>
      {{ value }}
    </v-tooltip>
  </p>
</template>

<script>
import Truncate from '@/components/common/Truncate'

export default {
  components: { Truncate },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped>
.content {
  max-width: 250px;
  margin: 0;
}
</style>
