"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateFieldDefinition = void 0;
const json_schema_keys_1 = require("../json_schema_keys");
const form_field_definition_1 = require("./form_field_definition");
class DateFieldDefinition extends form_field_definition_1.FormattedValueField {
    constructor() {
        super(...arguments);
        this.dataType = json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_STRING;
        this.allowTimeOfDaySelection = false;
    }
    get format() {
        if (this.allowTimeOfDaySelection) {
            return json_schema_keys_1.JsonSchemaKeys.DATA_FORMAT_DATE_TIME;
        }
        return json_schema_keys_1.JsonSchemaKeys.DATA_FORMAT_DATE;
    }
}
exports.DateFieldDefinition = DateFieldDefinition;
