<template>
  <portal to="app">
    <div
      :class="[{ delay: delayOpen }, 'popover']"
      :style="{ width: `${width}px`, ...positionStyle }"
    >
      <vs-card class="shadow"><slot /></vs-card>
    </div>
  </portal>
</template>

<script>
export default {
  name: 'PopOver',
  props: {
    bounds: {
      type: Object,
      default: null,
    },
    width: {
      type: Number,
      default: 320,
    },
    delayOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    positionStyle() {
      if (this.bounds.top <= window.innerHeight / 2) return this.bottom()
      return this.top()
    },
    xAxisOverflowOffset() {
      const windowWidth = window.innerWidth
      const popoverPosition = this.bounds.left + this.width
      const isOffScreen = windowWidth < popoverPosition
      const offset = windowWidth - popoverPosition
      return isOffScreen ? offset : 0
    },
  },
  methods: {
    bottom() {
      return {
        top: this.bounds.top + this.bounds.height + 'px',
        left: this.bounds.left + 'px',
        transform: `translateX(${this.xAxisOverflowOffset}px)`,
      }
    },
    top() {
      return {
        top: this.bounds.top + 'px',
        left: this.bounds.left + 'px',
        transform: `translateY(-100%) translateX(${this.xAxisOverflowOffset}px)`,
      }
    },
  },
}
</script>

<style scoped>
.popover {
  position: absolute;
  top: 0;
  z-index: var(--elevation-popover);
  width: 100%;
  padding: var(--space-smaller) 0;
  opacity: 0;
  animation: fadeIn 200ms ease forwards;
}

.delay {
  animation-delay: 750ms;
}

.shadow {
  box-shadow: var(--shadow-high);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
