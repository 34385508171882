<template>
  <component
    :is="determineComponent"
    class="base-heading"
    :class="{ [`vs-${type}`]: type }"
  >
    <slot />
  </component>
</template>

<script>
/**
 * Headings are used to name sections on a page. Reserved for short, important
 * text or numbers.
 */
export default {
  props: {
    /**
     * Changes the html tag
     */
    tag: {
      type: String,
      default: null,
    },
    /**
     * Determines the type of heading. Ordered by biggest to smallest.
     */
    type: {
      type: String,
      default: 'heading',
      validator: (val) =>
        [
          'display',
          'headline',
          'title',
          'heading',
          'subhead',
          'overline',
        ].includes(val),
    },
  },
  computed: {
    determineComponent() {
      const type = {
        display: 'h1',
        headline: 'h2',
        title: 'h3',
        heading: 'h4',
        subhead: 'h5',
        overline: 'h6',
      }
      return this.tag || type[this.type]
    },
  },
}
</script>

<style scoped>
.base-heading {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  font-family: var(--font-display);
  font-weight: bold;
  color: var(--color-black);
  white-space: pre-wrap;
}

.vs-display {
  font-size: 48px;
  line-height: 56px;
  font-weight: 900;
  text-transform: uppercase;
}

.vs-headline {
  font-size: 36px;
  line-height: 48px;
  font-weight: 900;
}

.vs-title {
  font-size: 26px;
  line-height: 36px;
}

.vs-heading {
  font-size: 20px;
  line-height: 28px;
}

.vs-subhead {
  font-size: 16px;
  line-height: 24px;
}

.vs-overline {
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
}
</style>
