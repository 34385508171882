import { computed, Ref, ref, watch } from 'vue'
import Axios, { AxiosError } from 'axios'

interface ProblemDetails {
  type: `"https://tools.ietf.org/html/rfc7231#${string}`
  status: number
  title: string
  detail: string
}

export function useDismissibleApiError(
  payload: Ref<AxiosError | undefined | null>
) {
  const showError = ref(false)

  const message = computed(() => {
    if (Axios.isAxiosError(payload.value)) {
      const data: ProblemDetails = payload.value?.response
        ?.data as ProblemDetails
      return data.detail
    }
    return null
  })

  watch(payload, (value) => {
    showError.value = !!value
  })

  return {
    showError,
    message,
  }
}
