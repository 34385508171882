import {
  ApplicationInsights,
  SeverityLevel,
} from '@microsoft/applicationinsights-web'
import {
  formatComponentName,
  generateComponentTrace,
} from '@sentry/vue/esm/vendor/components'

const getRouteName = (to) => {
  return (
    (to.name && to.name.toString()) ||
    (to.matched[0] && to.matched[0].path) ||
    to.path
  )
}

function getCookie(name: string): string | undefined {
  const value = ('; ' + document.cookie)
    .split(`; ${name}=`)
    .pop()
    ?.split(';')
    .shift()
  return value ? decodeURIComponent(value) : undefined
}

export default function install(Vue, { router, logErrors, ...config }) {
  const connectionString = getCookie('ai_cs')

  const appInsights = new ApplicationInsights({
    config: { connectionString, ...config },
  })
  if (connectionString) {
    appInsights.loadAppInsights()
  }

  Object.defineProperty(Vue.prototype, '$appInsights', {
    get: () => appInsights,
  })

  const { errorHandler, warnHandler, silent } = Vue.config

  router.beforeEach((to, from, next) => {
    const name = getRouteName(to)
    appInsights.startTrackPage(name)
    next()
  })

  router.afterEach((to, from) => {
    const name = getRouteName(to)
    const url = location.protocol + '//' + location.host + to.fullPath
    appInsights.stopTrackPage(name, url)
    appInsights.flush()
  })

  router.onError((error) => {
    appInsights.trackException({
      error,
      exception: error,
      severityLevel: SeverityLevel.Error,
    })
  })

  Vue.config.errorHandler = (error, vm, lifecycleHook) => {
    const componentName = formatComponentName(vm)
    const trace = vm ? generateComponentTrace(vm) : ''
    const properties = {
      componentName,
      lifecycleHook,
      trace,
      propsData: vm && vm.$options.propsData,
    }

    // Capture exception in the next event loop, to make sure that all breadcrumbs are recorded in time.
    setTimeout(() => {
      appInsights.trackException(
        {
          error,
          exception: error,
          severityLevel: SeverityLevel.Error,
        },
        properties
      )
    })

    if (typeof errorHandler === 'function') {
      errorHandler.call(Vue, error, vm, lifecycleHook)
    }

    if (logErrors) {
      const hasConsole = typeof console !== 'undefined'
      const message = `Error in ${lifecycleHook}: "${
        error && error.toString()
      }"`

      if (typeof warnHandler === 'function') {
        warnHandler(message, vm, trace)
      } else if (hasConsole && !silent) {
        // eslint-disable-next-line no-console
        console.error(`[Vue warn]: ${message}${trace}`)
      }
    }
  }
}
