import { computed, ComputedRef, onMounted, onUnmounted, watch } from 'vue'
import { UnwrapNestedRefs } from 'vue/types/v3-generated'
import moment from 'moment'
import { useQueryClient } from '@tanstack/vue-query'
import LocationDTO from '@/models/location'
import { FormStates } from '@/components/locations/on-the-fly-locations/form-states'
import { getById, useGetLocationStatusesQuery } from '@/api/locations'
import { useGetLocationTypesQuery } from '@/api/location-types'
import { useFieldView } from '@/components/field-view-next/FieldViewState'
import { useMap } from '@/components/field-view-next/mapbox/context'

export function useMapView(
  formData: UnwrapNestedRefs<FormStates>,
  isInMapView: ComputedRef<boolean | undefined>
) {
  const client = useQueryClient()
  const {
    fieldViewState,
    setSelectedLocation,
    upsertModifiedLocation,
    setEditMode,
  } = useFieldView()
  const { map } = useMap()
  const { data: locTypeQueryData } = useGetLocationTypesQuery({
    includeUnused: true,
  })
  const iconLookup = computed<Record<number, string>>(
    () =>
      locTypeQueryData.value?.reduce(
        (a, b) => ({ ...a, [b.id]: b.iconId }),
        {}
      ) ?? {}
  )
  const { data: locationStatuses } = useGetLocationStatusesQuery()
  const statusLookup = computed<Record<number, string>>(
    () =>
      locationStatuses.value?.reduce(
        (a, b) => ({ ...a, [b.id]: b.hexCode }),
        {}
      ) ?? {}
  )
  const insertLocationIntoMapModifiedLayer = async (id: number) => {
    const newlyAddedLocation = await client.fetchQuery({
      queryKey: ['locations', id],
      queryFn: ({ signal }) => getById(id, signal),
      staleTime: moment.duration(10, 'minutes').asMilliseconds(),
    })
    upsertModifiedLocation(newlyAddedLocation)
  }

  const syncLocationDetailsToMapMarker = () => {
    const marker = {
      location: formData.location,
      colorId: formData.colorId,
      locationTypeId: formData.locationTypeId,
      icon: formData.locationTypeId
        ? iconLookup.value[formData.locationTypeId]
        : null,
      locationStatusColorHexCode: formData.colorId
        ? statusLookup.value[formData.colorId]
        : null,
      latitude: parseFloat(formData.latitude?.toFixed(8) ?? '0'),
      longitude: parseFloat(formData.longitude?.toFixed(8) ?? '0'),
    } as LocationDTO

    setSelectedLocation(marker)
  }

  watch(
    () => [
      fieldViewState.value?.selectedLocation?.latitude,
      fieldViewState.value?.selectedLocation?.longitude,
    ],
    ([newLat, newLng]) => {
      if (
        newLat !== null &&
        newLat !== undefined &&
        newLng !== null &&
        newLng !== undefined
      ) {
        formData.latitude = parseFloat(newLat.toFixed(8))
        formData.longitude = parseFloat(newLng.toFixed(8))
      }
    }
  )
  watch(
    () => [formData.latitude, formData.longitude],
    () => {
      if (isInMapView.value) {
        syncLocationDetailsToMapMarker()
      }
    }
  )

  onMounted(() => {
    if (isInMapView.value && map.value) {
      const { lat, lng } = map.value.getCenter()
      formData.latitude = parseFloat(lat.toFixed(8))
      formData.longitude = parseFloat(lng.toFixed(8))
      setEditMode(true)
      setSelectedLocation({
        latitude: formData.latitude,
        longitude: formData.longitude,
      } as LocationDTO)
    }
  })

  onUnmounted(() => {
    if (isInMapView.value) {
      setEditMode(false)
      setSelectedLocation(null)
    }
  })

  return {
    map,
    insertLocationIntoMapModifiedLayer,
    syncLocationDetailsToMapMarker,
  }
}
