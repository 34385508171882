import { ref, unref, UnwrapNestedRefs } from 'vue'
import { MaybeRef } from '@vueuse/core'
import { currentTimezone } from '@/utils/date-formatting.js'
import { saveFile } from '@/utils/save-file.js'
import { exportInvoiceCsv, exportInvoicePdf } from '@/api/invoice'
import { AxiosError } from 'axios'

export function useBatchCsvExport(
  invoiceId: MaybeRef<UnwrapNestedRefs<string>>
) {
  const saving = ref(false)
  const error = ref<unknown>(null)

  async function exportBatchToCsv() {
    saving.value = true
    error.value = null
    try {
      saveFile(await exportInvoiceCsv(unref(invoiceId), currentTimezone()))
    } catch (e) {
      error.value = e
    }
    saving.value = false
  }

  return { exportBatchToCsv, saving, error }
}

export function useBatchPdfExport(
  invoiceId: MaybeRef<UnwrapNestedRefs<string>>
) {
  const saving = ref(false)
  const error = ref<AxiosError | null>(null)

  async function exportBatchToPdf() {
    saving.value = true
    error.value = null

    try {
      saveFile(await exportInvoicePdf(unref(invoiceId), currentTimezone()))
    } catch (e) {
      if (e instanceof AxiosError) {
        error.value = e
      }
    }
    saving.value = false
  }

  return { exportBatchToPdf, saving, error }
}
