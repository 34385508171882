"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageModel = void 0;
const definitions_model_1 = require("./definitions_model");
const page_ui_schema_1 = require("./page_ui_schema");
const schema_model_1 = require("./schema_model");
class PageModel {
    constructor(pageTitle, uiOrder, definitions) {
        this.schema = new schema_model_1.SchemaModel();
        this.schema.title = pageTitle;
        this.uiSchema = new page_ui_schema_1.PageUiSchema(uiOrder);
        if (definitions.length > 0) {
            this.schema.definitions = new definitions_model_1.DefinitionsModel(definitions);
        }
    }
}
exports.PageModel = PageModel;
