<template>
  <v-menu offset-x>
    <template #activator="{ on, attrs }">
      <vs-button
        v-bind="attrs"
        rounded
        icon="bookmarks"
        label="Add a template field"
        class="btn"
        full-width
        v-on="on"
      >
      </vs-button>
    </template>

    <v-card class="overflow-y-auto" max-height="40em" max-width="40em">
      <vs-heading type="overline" class="pl-4 py-4">Template Fields</vs-heading>
      <v-list-item-group>
        <vs-list-field-item
          v-for="field in items"
          :item-key="field.uniqueKey()"
          :icon="getFieldIcon(field)"
          :label="getFieldLabel(field)"
          :description="field.descriptionOverride"
          @add-field="onFieldClicked(field, field.fieldTypeTitle)"
        ></vs-list-field-item>
      </v-list-item-group>
    </v-card>
  </v-menu>
</template>

<script>
import VsButton from '@/components/vision/VsButton'
import VsMenu from '@/components/vision/VsMenu'
import VsHeading from '@/components/vision/VsHeading'
import VsText from '@/components/vision/VsText'
import VsListFieldItem from '@/components/form-builder/VsListFieldItem'
import formFieldIcon from '@/utils/forms/form-field-icons'
import formFieldTitle from '@/utils/forms/form-field-title'
export default {
  name: 'AddReferenceFieldButton',
  components: { VsListFieldItem, VsText, VsHeading, VsMenu, VsButton },
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  methods: {
    getFieldIcon(field) {
      return formFieldIcon(field)
    },
    getFieldLabel(field) {
      return formFieldTitle(field)
    },
    onFieldClicked(field, fieldTypeTitle) {
      this.$emit('add-reference-field', field)
      this.$emit('field-clicked-name', fieldTypeTitle)
    },
  },
}
</script>

<style scoped>
.btn,
.btn:hover,
.btn:focus {
  background-color: var(--color-blue--lighter);
  --text-color: var(--color-blue);
}
.delete-btn,
.delete-btn:hover,
.delete-btn:focus {
  background-color: var(--color-red--lighter);
  --text-color: var(--color-red);
}
</style>
