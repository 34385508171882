import axios from 'axios'
import { get } from '@/api/base'
import { convertToCamelCase } from '@/models/utils/casing'
import { useQuery } from '@tanstack/vue-query'
import { MaybeRef } from '@vueuse/core'
import { computed, unref } from 'vue'
import moment from 'moment'

const endpoint = '/api/v5/hubs'

export interface HubResponse {
  id: string
  name: string
  organizationName: string
  organizationId: string
}

function getAssignableHubs(organizationId: string, signal?: AbortSignal) {
  const source = axios.CancelToken.source()
  const result = get<HubResponse[]>(
    `${endpoint}/assignable-hubs?organizationId=${organizationId}`,
    {
      cancelToken: source.token,
    }
  ).then((res) => convertToCamelCase(res.data))

  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled by TanStack Query')
  })

  return result as unknown as Promise<HubResponse[]>
}

export function useGetAssignableHubs(
  organizationId: MaybeRef<string | undefined>
) {
  const organizationIdComputed = computed(() => unref(organizationId))
  const queryKey = computed(() => [
    'hubs',
    'assignable-hubs',
    organizationIdComputed.value,
  ])
  const enabled = computed(() => {
    return (
      organizationIdComputed.value !== null &&
      organizationIdComputed.value !== undefined
    )
  })
  return useQuery({
    queryKey,
    queryFn: ({ signal }) =>
      getAssignableHubs(organizationIdComputed.value!, signal),
    staleTime: moment.duration(10, 'minutes').asMilliseconds(),
    enabled,
  })
}
