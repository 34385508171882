/* eslint-disable camelcase */
import JobComment from '@/models/job-comment'
import JobAttachment from '@/models/job_attachment'
import JobSignature from '@/models/job-signature'
import { priorities } from '@/utils/constants'
import moment from 'moment'
import JobActivityLog from '@/models/job_activity_log.js'
import JobFormSubmission from '@/models/job_form_submission'
import Division from '@/models/division'
import Activity from '@/models/activity'
import Company from '@/models/company'
import Project from '@/models/project'
import { UnitNumber } from '@/models/unit_number'
import JobFormAssociation from '@/models/job-form-association'
import JobAssignmentFormRequirement from '@/models/job-assignment-form-requirement'
import { sortBy } from 'lodash'
import { CustomData } from '@/models/types/custom-data'

export const UNKNOWN = 'Unknown'
export const REQUESTED = 'Requested'
export const ASSIGNED_TO_SERVICE_PROVIDER = 'AssignedToServiceProvider'
export const ASSIGNED_TO_POOL = 'AssignedToPool'
export const SCHEDULED = 'Scheduled'
export const PAUSED = 'Paused'
export const ACTIVE = 'Active'
export const COMPLETED = 'Completed'
export const CANCELLED = 'Cancelled'
export const UNCANCELLED = 'UnCancelled'
export const DRAFT = 'Draft'

export enum JobStatus {
  UNKNOWN = 'Unknown',
  REQUESTED = 'Requested',
  ASSIGNED_TO_SERVICE_PROVIDER = 'AssignedToServiceProvider',
  ASSIGNED_TO_POOL = 'AssignedToPool',
  SCHEDULED = 'Scheduled',
  PAUSED = 'Paused',
  ACTIVE = 'Active',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
  UNCANCELLED = 'UnCancelled',
  DRAFT = 'Draft',
}

export const NO_APPROVAL = 'NoApproval'
export const PENDING_APPROVAL = 'PendingApproval'
export const APPROVED = 'Approved'
export const REJECTED = 'Rejected'
export const UNAPPROVED = 'Unapproved'

export enum ApprovalStatus {
  NO_APPROVAL = 'NoApproval',
  PENDING_APPROVAL = 'PendingApproval',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
}

export function createJobFactory({
  'companies/getByOrganizationId': getCompanyById,
  'divisions/getByUuid': getDivisionById,
  'locations/getById': getLocationById,
  'users/getBySid': getUserBySid,
  'activities/getById': getActivityById,
  'unitNumbers/getById': getUnitById,
  'resourceTypes/getById': getResourceTypeById,
  'projects/getById': getProjectById,
}) {
  return function createJob(data) {
    const {
      id,
      uuid,
      active_duration,
      active_time,
      associated_forms: associatedForms = [],
      completed_time,
      cost_center: costCenter,
      created,
      created_by: createdBy,
      followers,
      form_submissions: formSubmissions = [],
      inventory_requirements,
      modified,
      modified_by: modifiedBy,
      priority,
      request_details,
      request_owner,
      resource_assignment,
      total_distance: totalDistance,
      state: status,
      is_approved,
      is_pending_approval,
      approved_by,
      tags,
      assignment_group_id: assignmentGroupId,
      assignment_group_number: assignmentGroupNumber,
      project_id: projectId,
      attachments_storage_id: attachmentsStorageId,
      activity_log,
      owner_id: ownerId,
      hub_id: hubId,
      attachments,
      comments,
      is_archived: isArchived,
      assignment_form_requirements: assignmentFormRequirements = [],
      invoice_id: invoiceId,
      is_approval_rejected: isApprovalRejected,
      approval_rejection_reason: approvalRejectionReason,
      signature,
      extension_schema_id: extensionSchemaId,
      account_id: accountId,
    } = data

    const attributes = {
      // Top-level fields
      id,
      uuid,
      ownerId,
      hubId,
      assignmentGroupId,
      assignmentGroupNumber,
      projectId,
      priorityId: priority,
      approvalStatus: getApprovalStatus(
        is_approved,
        is_pending_approval,
        isApprovalRejected
      ),
      activeDuration: moment.duration(active_duration, 'seconds'),
      activeTime: active_time && moment.utc(active_time).local(),
      approvedBy: approved_by,
      completedTime: completed_time && moment.utc(completed_time).local(),
      created: moment.utc(created).local(),
      modified: moment.utc(modified).local(),
      status,
      createdBy,
      modifiedBy,
      costCenter,
      tags,
      associatedForms: associatedForms.map(
        (form) => new JobFormAssociation(form)
      ),
      formSubmissions: formSubmissions.map(
        (formSubmission) => new JobFormSubmission(formSubmission)
      ),
      followers: followers ?? [],
      attachmentsStorageId,
      comments: comments.map((comment) => new JobComment(comment)),
      // Request owner
      customerSid: request_owner.user_sid,
      // Request details
      startTime:
        request_details.requested_start &&
        moment.utc(request_details.requested_start).local(),
      endTime:
        request_details.requested_end &&
        moment.utc(request_details.requested_end).local(),
      destinationLocationId: request_details.destination_location_id,
      destinationLocation: request_details.destination_location,
      details: request_details.details,
      activityId: request_details.activity_id,
      // Resource assignment
      poolId: resource_assignment?.pool_id ?? null,
      unitId: resource_assignment?.unit_id ?? null,
      totalDistance,
      serviceProviderBusinessUnitId:
        resource_assignment?.service_provider_business_unit_id ?? null,
      unitWorkingStatus: resource_assignment?.status,
      crew: resource_assignment?.operators ?? [],
      operator: (resource_assignment?.operators ?? [])
        .map((sid) => getUserBySid(sid))
        .filter(Boolean),
      // Inventory requirements
      inventory: inventory_requirements.map((inv) => ({
        name: inv.inventory_type_name,
        inventoryTypeId: inv.inventory_type_id,
        inventorySubTypeName: inv.inventory_sub_type_name,
        inventorySubTypeId: inv.inventory_sub_type_id,
        quantity: inv.quantity,
        pickupLocationId: inv.source_location_id,
        pickupLocationName: inv.source_location_name,
        inventoryId: inv.inventory_id,
        position: inv.position,
      })),
      // For backwards compatibility. Remove once multi pickup is fully supported on DH
      sourceLocationId: inventory_requirements.length
        ? inventory_requirements[0].source_location_id
        : null,
      attachments:
        attachments?.map((attachment) => new JobAttachment(attachment)) ?? [],
      isArchived,
      assignmentFormRequirements: sortBy(
        assignmentFormRequirements.map(
          (requirement) => new JobAssignmentFormRequirement(requirement)
        ) ?? [],
        function (o) {
          return o.created
        }
      ),
      invoiceId,
      isApprovalRejected,
      approvalRejectionReason,
      signature: signature && new JobSignature(signature),
      extensionSchemaId,
      accountId,
    }

    const associations = {
      priority: priorities.find((p) => p.id === priority),
      activityLog: new JobActivityLog(activity_log),
    }

    const customData =
      request_details.custom_data &&
      Object.entries(request_details.custom_data).reduce(
        (dict, [field, value]) => {
          dict[field] = value
          return dict
        },
        {}
      )

    const job = new Job(attributes, associations, customData)
    Object.defineProperties(job, {
      division: {
        get: (): Division => job.ownerId && getDivisionById(job.ownerId),
      },
      company: {
        get: (): Company =>
          job.customer && getCompanyById(job.customer?.organizationId),
      },
      companyId: {
        get: (): number | undefined => job.customer?.organizationId,
      },
      serviceProvider: {
        get: (): Company =>
          job.serviceProviderBusinessUnitId &&
          getCompanyById(job.serviceProviderBusinessUnitId),
        /**
         * @deprecated assign serviceProviderBusinessUnitId instead
         */
        set: (value) => (job.serviceProviderBusinessUnitId = value),
      },
      // For backwards compatibility. Remove once multi pickup is fully supported on DH
      sourceLocation: {
        get: () =>
          job.sourceLocationId && getLocationById(job.sourceLocationId),
      },
      pickupLocations: {
        get: () =>
          job.inventory
            ?.map((i) => getLocationById(i.pickupLocationId))
            .filter(Boolean),
      },
      pickupLocationItems: {
        get: () =>
          job?.inventory
            ?.map((i) => ({
              itemName: i.inventorySubTypeName,
              pickUpLocationName:
                getLocationById(i.pickupLocationId)?.name ??
                'Pick up location undecided',
            }))
            .filter(Boolean),
      },
      project: {
        get: (): Project => job.projectId && getProjectById(job.projectId),
      },
      unit: {
        get: (): UnitNumber => job.unitId && getUnitById(job.unitId),
      },
      activity: {
        get: (): Activity => job.activityId && getActivityById(job.activityId),
      },
      resourceType: {
        get: () =>
          job.activity?.resourceTypeId &&
          getResourceTypeById(job.activity?.resourceTypeId),
      },
      resourceTypeId: {
        get: (): number | undefined => job.activity?.resourceTypeId,
      },
      customer: {
        get: () => job.customerSid && getUserBySid(job.customerSid),
      },
    })

    return job
  }
}

function getApprovalStatus(
  is_approved,
  is_pending_approval,
  isApprovalRejected
) {
  if (is_approved) {
    return ApprovalStatus.APPROVED
  }
  if (is_pending_approval) {
    return ApprovalStatus.PENDING_APPROVAL
  }
  if (isApprovalRejected) {
    return ApprovalStatus.REJECTED
  }
  return ApprovalStatus.NO_APPROVAL
}

export default class Job {
  constructor(
    attributes: {
      activeDuration: moment.Duration
      activeTime: moment.Moment | null
      activityId: number
      approvalStatus: ApprovalStatus
      approvedBy: string | null
      associatedForms: any[]
      attachments: JobAttachment[]
      attachmentsStorageId: string
      comments: JobComment[]
      completedTime: moment.Moment | null
      costCenter: string
      created: moment.Moment
      createdBy: string
      crew: string[]
      customerSid: string
      destinationLocationId: number
      destinationLocation: string
      details: string
      endTime: moment.Moment
      followers: string[]
      formSubmissions: JobFormSubmission[]
      hubId: string
      id: number
      inventory: any[]
      modified: moment.Moment
      modifiedBy: string
      operator: any[]
      ownerId: string
      assignmentGroupId: string | null
      assignmentGroupNumber: number | null
      priorityId: number
      projectId: string | null
      serviceProviderBusinessUnitId: string
      startTime: moment.Moment
      status: JobStatus
      tags: string[]
      totalDistance: number
      unitId: number
      unitWorkingStatus: string
      uuid: string
      poolId: string | null
      isArchived: boolean
      assignmentFormRequirements: JobAssignmentFormRequirement[]
      invoiceId: string | null
      isApprovalRejected: boolean
      approvalRejectionReason: string | null
      signature: JobSignature | null
      extensionSchemaId: number | null
      accountId: string
    },
    associations: {
      activityLog: any
      priority: any
    },
    customData: CustomData
  ) {
    this.activeDuration = attributes.activeDuration
    this.activeTime = attributes.activeTime
    this.activityId = attributes.activityId
    this.activityLog = associations.activityLog
    this.approvalStatus = attributes.approvalStatus
    this.approvedBy = attributes.approvedBy
    this.associatedForms = attributes.associatedForms
    this.attachments = attributes.attachments
    this.attachmentsStorageId = attributes.attachmentsStorageId
    this.comments = attributes.comments
    this.completedTime = attributes.completedTime
    this.costCenter = attributes.costCenter
    this.created = attributes.created
    this.createdBy = attributes.createdBy
    this.crew = attributes.crew
    this.extensionSchemaId = attributes.extensionSchemaId
    this.customData = customData
    this.customerSid = attributes.customerSid
    this.destinationLocationId = attributes.destinationLocationId
    this.destinationLocation = attributes.destinationLocation
    this.details = attributes.details
    this.endTime = attributes.endTime
    this.followers = attributes.followers
    this.formSubmissions = attributes.formSubmissions
    this.hubId = attributes.hubId
    this.id = attributes.id
    this.inventory = attributes.inventory
    this.modified = attributes.modified
    this.modifiedBy = attributes.modifiedBy
    this.operator = attributes.operator
    this.ownerId = attributes.ownerId
    this.priority = associations.priority
    this.priorityId = attributes.priorityId
    this.assignmentGroupId = attributes.assignmentGroupId
    this.assignmentGroupNumber = attributes.assignmentGroupNumber
    this.projectId = attributes.projectId
    this.serviceProviderBusinessUnitId =
      attributes.serviceProviderBusinessUnitId
    this.startTime = attributes.startTime
    this.status = attributes.status
    this.tags = attributes.tags
    this.totalDistance = attributes.totalDistance
    this.unitId = attributes.unitId
    this.unitWorkingStatus = attributes.unitWorkingStatus
    this.uuid = attributes.uuid
    this.poolId = attributes.poolId
    this.isArchived = attributes.isArchived
    this.assignmentFormRequirements = attributes.assignmentFormRequirements
    this.invoiceId = attributes.invoiceId
    this.isApprovalRejected = attributes.isApprovalRejected
    this.approvalRejectionReason = attributes.approvalRejectionReason
    this.signature = attributes.signature
    this.accountId = attributes.accountId

    Object.entries(customData).forEach(([field, value]) => {
      Object.defineProperty(this, field, {
        value,
        writable: false,
        enumerable: true,
      })
    })
  }

  activeDuration: moment.Duration
  activeTime: moment.Moment | null
  activity?: Activity
  activityId: number
  activityLog: any
  approvalStatus: ApprovalStatus
  approvedBy: string | null
  associatedForms: any[]
  attachments: JobAttachment[]
  attachmentsStorageId: string
  comments: JobComment[]
  company: any
  companyId?: number | null
  completedTime: moment.Moment | null
  costCenter: string
  created: moment.Moment
  createdBy: string
  crew: string[]
  extensionSchemaId: number | null
  customData: CustomData
  customer: any
  customerSid: string
  destinationLocation: string
  destinationLocationId: number
  details: string
  division: any
  endTime: moment.Moment
  followers: string[]
  formSubmissions: JobFormSubmission[]
  hubId: string
  id: number
  inventory: any[]
  modified: moment.Moment
  modifiedBy: string
  operator: any[]
  ownerId: string
  priority: any
  priorityId: number
  project: any
  assignmentGroupId: string | null
  assignmentGroupNumber: number | null
  projectId: string | null
  resourceType?: any
  resourceTypeId?: number
  serviceProvider: any
  poolId: string | null
  serviceProviderBusinessUnitId: string | null
  // For backwards compatibility. Remove once multi pickup is fully supported on DH
  sourceLocation: any
  sourceLocationId?: string
  startTime: moment.Moment
  status: JobStatus
  tags: string[]
  totalDistance: number
  unit: any
  unitId: number | null
  unitWorkingStatus: string
  uuid: string
  isArchived: boolean
  assignmentFormRequirements: JobAssignmentFormRequirement[]
  invoiceId: string | null
  isApprovalRejected: boolean
  approvalRejectionReason: string | null
  signature: JobSignature | null
  accountId: string

  get estimatedDuration(): moment.Duration {
    return moment.duration(this.endTime.diff(this.startTime))
  }

  get actualDuration(): moment.Duration | null {
    return this.activeTime && this.completedTime
      ? moment.duration(this.completedTime.diff(this.activeTime))
      : null
  }

  get actualActiveDuration(): moment.Duration {
    if (this.actualDuration) {
      return this.activeDuration
    }
    return this.calculatedActiveDuration
  }

  get calculatedActiveDuration(): moment.Duration {
    return this.activityLog?.durationForActiveState ?? moment.duration(0)
  }

  get pausedDuration(): moment.Duration {
    if (this.actualDuration) {
      return moment.duration(
        this.actualDuration.asMilliseconds() -
          this.activeDuration.asMilliseconds(),
        'milliseconds'
      )
    }
    return this.calculatedPausedDuration
  }

  get calculatedPausedDuration(): moment.Duration {
    return this.activityLog?.durationForPausedState ?? moment.duration(0)
  }

  get estimatedCompletedTime(): moment.Moment {
    if (this.currentActiveTime) {
      return moment.max(
        moment(this.currentActiveTime)
          .add(this.estimatedDuration)
          .subtract(this.actualActiveDuration),
        moment(moment.now())
      )
    }
    return moment(this.startTime).add(this.estimatedDuration)
  }

  get currentActiveTime(): moment.Moment | null {
    return this.activityLog.currentActiveTime
  }

  get displayedStartTime(): moment.Moment {
    if (this.completedTime && !this.activeTime) {
      return moment(this.completedTime).subtract(this.estimatedDuration)
    }
    if (this.currentActiveTime && !this.completedTime) {
      return moment(this.currentActiveTime)
    }
    if (this.actualDuration) {
      return moment(this.activeTime)
    }
    return moment(this.startTime)
  }

  get displayedEndTime(): moment.Moment {
    if (this.completedTime) {
      return this.completedTime
    } else if (this.currentActiveTime) {
      return this.estimatedCompletedTime
    } else {
      return moment(this.endTime)
    }
  }

  get jobStatus(): JobStatus | ApprovalStatus {
    return this.approvalStatus !== NO_APPROVAL
      ? this.approvalStatus
      : this.status
  }

  get isApproved(): boolean {
    return this.approvalStatus === APPROVED
  }

  get isPendingApproval(): boolean {
    return this.approvalStatus === PENDING_APPROVAL
  }

  get awaitingSchedule(): boolean {
    return (
      this.status === REQUESTED ||
      this.status === ASSIGNED_TO_SERVICE_PROVIDER ||
      this.status === ASSIGNED_TO_POOL
    )
  }

  get isRequested(): boolean {
    return this.status === REQUESTED
  }

  get isAssignedToServiceProvider(): boolean {
    return this.status === ASSIGNED_TO_SERVICE_PROVIDER
  }

  get isScheduled(): boolean {
    return this.status === SCHEDULED
  }

  get isResourceAssigned(): boolean {
    return !!this.unitId
  }

  get isActive(): boolean {
    return this.status === ACTIVE
  }

  get isPaused(): boolean {
    return this.status === PAUSED
  }

  get isCompleted(): boolean {
    return this.status === COMPLETED
  }

  get isCancelled(): boolean {
    return this.status === CANCELLED
  }

  get isNotCompleteOrCancelled(): boolean {
    return !this.isCancelled && !this.isCompleted
  }

  get canGoActive(): boolean {
    return this.isScheduled || this.isPaused
  }

  get isOverdue(): boolean {
    return (
      (this.awaitingSchedule || this.isScheduled) &&
      this.startTime &&
      this.startTime.isBefore()
    )
  }

  get isLate(): boolean {
    return (
      (this.isActive || this.isPaused) &&
      this.estimatedCompletedTime &&
      this.estimatedCompletedTime.isSameOrBefore()
    )
  }
}
