import axios from 'axios'
import { get, post, put } from '../base'
import { useMutation } from '@tanstack/vue-query'

let source = axios.CancelToken.source()

const unitsEndPoint = '/api/v2/account-data/unit-numbers'

export function getUnitNumbers() {
  source.cancel('Cancel old get unit numbers request')
  source = axios.CancelToken.source()

  return get(unitsEndPoint, {
    cancelToken: source.token,
  })
}

export function createUnitNumber(unitNumber) {
  return post(unitsEndPoint, {
    data: {
      resource_type_id: unitNumber.resourceTypeId,
      name: unitNumber.name,
      division_id: unitNumber.divisionId,
      status_description: unitNumber.statusDescription,
      description: unitNumber.description,
      owner_id: unitNumber.ownerId,
    },
  })
}

export function updateUnitNumber(unitNumber) {
  return put(`${unitsEndPoint}/unit/${unitNumber.id}`, {
    data: {
      id: unitNumber.id,
      resource_type_id: unitNumber.resourceTypeId,
      name: unitNumber.name,
      division_id: unitNumber.divisionId,
      status: unitNumber.status,
      status_description: unitNumber.statusDescription,
      description: unitNumber.description,
      is_visible: unitNumber.isVisible,
      owner_id: unitNumber.ownerId,
    },
  })
}

export function useUpdateUnitNumberMutation() {
  return useMutation({
    mutationFn: (variables) => updateUnitNumber(variables),
  })
}

export function useArchiveUnitNumberMutation() {
  return useMutation({
    mutationFn: (variables) =>
      updateUnitNumber({
        ...variables,
        isVisible: false,
      }),
  })
}

export function useUnarchiveUnitNumberMutation() {
  return useMutation({
    mutationFn: (variables) =>
      updateUnitNumber({
        ...variables,
        isVisible: true,
      }),
  })
}
