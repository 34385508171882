import { flatMap } from 'lodash'
import moment from 'moment'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { TASK_ACTIVE, TASK_PAUSED } from '../../models/unit_number'
import UnitGroup from '../schedule/UnitGroup'
import jobTemplate from './templates/job-card.mustache'
import scheduleGroupTemplate from './templates/schedule-group.mustache'
import shadowTemplate from './templates/shadow.mustache'
import tooltipTemplate from './templates/tooltip.mustache'

const UnitGroupComponent = Vue.extend(UnitGroup)
const MIN_PILL_SIZE_IN_MINUTES = 30

function escape(text) {
  const tagsToReplace = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
  }

  return text.replace(/[&<>]/g, function (tag) {
    return tagsToReplace[tag] || tag
  })
}

export default {
  name: 'DailyScheduleProvider',

  props: {
    jobs: {
      type: Array,
      default: () => [],
    },
    groupings: {
      type: Array,
      default: () => [],
    },
    now: {
      type: moment,
      default: () => moment(),
    },
  },

  data() {
    return {
      cache: new Map(),
      options: {
        xss: {
          // prevent html attributes from being stripped out
          disabled: true,
        },
        align: 'left',
        height: '100%',
        orientation: {
          axis: 'bottom',
          item: 'top',
        },
        editable: false,
        zoomable: false,
        margin: 8,
        stack: false,
        stackSubgroups: false,
        template(item) {
          if (item.template === 'job') {
            return jobTemplate(item)
          }

          return shadowTemplate(item)
        },
        groupTemplate: (group) => {
          if (!group) {
            return
          }
          if (group.template === 'schedule-group') {
            return scheduleGroupTemplate(group.content)
          }

          let component = this.cache.get(group.id)
          if (!component) {
            component = new UnitGroupComponent({
              store: this.$store,
              parent: this.cache.get(group.nestedInGroup) || this,
              propsData: {
                unitNumberId: group.content,
                unitSideSheetUrl: 'sv-unit-overview',
              },
            })

            this.cache.set(group.id, component.$mount())
          }

          return component.$el
        },
        groupHeightMode: 'fixed',
        selectable: true,
        horizontalScroll: true,
        verticalScroll: true,
        tooltip: {
          followMouse: true,
          overflowMethod: 'flip',
          delay: 0,
        },
        zoomMax: moment.duration(8, 'hours').asMilliseconds(),
        zoomMin: moment.duration(8, 'hours').asMilliseconds(),
      },
    }
  },

  render() {
    return this.$scopedSlots.default({
      items: this.items,
      groups: this.groups,
      options: this.options,
    })
  },

  computed: {
    ...mapGetters({
      getUserBySid: 'users/getBySid',
    }),
    items() {
      return flatMap(this.jobs, (job) => {
        const classNames = [
          job.isLate && 'late',
          job.isOverdue && 'overdue',
          job.status.toLowerCase(),
        ]
          .filter(Boolean)
          .join(' ')

        const now = this.now
        let start = job.activeTime || job.startTime
        const duration = job.actualDuration || job.estimatedDuration
        let end = moment(start).add(duration, 'minutes')

        if (job.completedTime) {
          end = job.completedTime

          const realDuration = job.actualDuration
            ? job.actualDuration.asMinutes()
            : job.estimatedDuration.asMinutes()
          const adjustedDuration = Math.max(
            realDuration,
            MIN_PILL_SIZE_IN_MINUTES
          )

          start = moment(end).subtract(adjustedDuration, 'minutes')
        }

        const customer = job.customerSid && this.getUserBySid(job.customerSid)
        const customerName =
          customer && customer.name && customer.name.length > 0
            ? escape(customer.name)
            : null

        const details =
          job.details && job.details.length > 0 ? escape(job.details) : null

        const title = tooltipTemplate({
          details,
          customer: customerName,
        })

        const shadows = []
        const group = `unit-${job.unitId}`
        const id = `job-${job.id}__${group}`
        const subgroup = `sg__${id}`

        if (job.isOverdue) {
          // if the job is overdue we show a shadow where it was schedule to start up until now
          // and push the start up to now
          shadows.push({
            id: `${id}--shadow`,
            className: `shadow ${classNames}`,
            content: `overdue`,
            start: start.clone(),
            end: now,
            group,
            subgroup,
            title,
          })

          start = now
          end = moment(start).add(duration, 'minutes')
        }

        if (job.isLate) {
          // if a job we draw a shadow from the expected end time up to now
          shadows.push({
            id: `${id}--shadow`,
            className: `shadow ${classNames}`,
            template: 'shadow',
            content: `late`,
            start: moment(start).add(duration, 'minutes'),
            end: now,
            group,
            subgroup,
            title,
          })
        }

        const cancelledStyle = job.isCancelled
          ? 'background: var(--color-grey--lightest); opacity: 0.5;'
          : ''

        let progressSegments = []

        if (job.activeTime && (job.isActive || job.isPaused)) {
          progressSegments = job.activityLog.segments
            .filter(
              (segment) =>
                segment.start.status === TASK_ACTIVE ||
                segment.start.status === TASK_PAUSED
            )
            .map((segment) => {
              const percent =
                Math.min(
                  segment.duration.as('milliseconds') /
                    job.estimatedDuration.as('milliseconds'),
                  100
                ) * 100

              return {
                percent,
                status: segment.start.status,
              }
            })
        }

        const currentProgress = Math.min(
          progressSegments.reduce((total, p) => total + p.percent, 0),
          100
        )

        return [
          {
            id: `${id}--actual`,
            className: `actual ${classNames}`,
            template: 'job',
            job,
            start,
            end,
            group,
            subgroup,
            title,
            progressSegments,
            currentProgress,
            showProgressBar: progressSegments.length > 0,
            cancelledStyle,
          },
          ...shadows,
        ]
      })
    },
    groups() {
      return flatMap(this.groupings, (grouping) => {
        const unitsForGroup = grouping.units.map((unit) => ({
          id: `unit-${unit.id}`,
          treeLevel: 1,
          content: unit.id,
        }))

        const topLevelGroup = {
          id: grouping.id,
          template: 'schedule-group',
          content: grouping,
          nestedGroups: unitsForGroup.map((ug) => ug.id),
        }

        return [topLevelGroup, ...unitsForGroup]
      })
    },
  },
}
