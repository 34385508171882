"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.markdownFieldMatcher = void 0;
const markdown_field_definition_1 = require("../../form_fields/markdown_field_definition");
const json_schema_keys_1 = require("../../json_schema_keys");
const markdownFieldMatcher = (uniqueKey, isRequired, properties, uiSchema, fieldDefinitions, next) => {
    const hasMarkdown = json_schema_keys_1.JsonSchemaKeys.MARKDOWN_KEY in properties;
    const hasNoType = properties[json_schema_keys_1.JsonSchemaKeys.TYPE_KEY] === "null";
    if (hasMarkdown && hasNoType) {
        const field = new markdown_field_definition_1.MarkdownFieldDefinition();
        field.markdown = properties[json_schema_keys_1.JsonSchemaKeys.MARKDOWN_KEY];
        return field;
    }
    return next(uniqueKey, isRequired, properties, uiSchema, fieldDefinitions);
};
exports.markdownFieldMatcher = markdownFieldMatcher;
