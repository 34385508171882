import { isEqual, orderBy } from 'lodash'
import { getAll } from '@/api/companies'
import Company from '@/models/company'
import { ORGANIZATION } from '@/models/business-unit'

export const RECEIVE_COMPANIES = 'RECEIVE_COMPANIES'
export const LOADING_COMPANIES = 'LOADING_COMPANIES'
export const LOADED_COMPANIES = 'LOADED_COMPANIES'

const state = () => {
  return {
    all: {},
    loading: false,
  }
}

export const getters = {
  getById: (state) => (id) => state.all[id] || null,
  getByChildHubId: (_state, getters, _rootState, rootGetters) => (id) => {
    const hub = rootGetters['businessUnits/getBusinessUnitById'](id)
    return getters.getByOrganizationId(hub?.ownerId)
  },
  getAllCompanies: (state) => state.all,
  getByOrganizationId: (state) => (id) =>
    Object.values(state.all).find((c) => c.organizationId === id),
  getCompaniesRelatedToBusinessUnit:
    (_state, getters, _rootState, rootGetters) => (businessUnitId) => {
      const context = rootGetters['permissions/getContext']
      const scopes = context.roleAssignments.map((ra) => ra.businessUnitId)
      if (scopes.length === 0) return []

      let companies = getters.getCompaniesForBusinessUnit(businessUnitId)

      if (scopes.includes(businessUnitId)) {
        const partnerBusinessUnitIds = context.lineage
          .filter((bu) => bu.id === businessUnitId)
          .map((bu) => [...bu.partnerIds, ...bu.hostIds])
          .flat()

        partnerBusinessUnitIds.forEach((partnerBusinessUnitId) => {
          const partnerCompanies = getters.getCompaniesForBusinessUnit(
            partnerBusinessUnitId
          )

          companies = companies.concat(
            partnerCompanies.filter((partnerCompany) =>
              companies.every((company) => company.id !== partnerCompany.id)
            )
          )
        })
      }

      return orderBy(companies, [(company) => company.name.toLowerCase()])
    },
  getCompaniesForBusinessUnit:
    (_state, getters, _rootState, rootGetters) => (businessUnitId) => {
      return Object.values(getters.getAllCompanies).filter(
        (company) =>
          company.organizationId ===
            rootGetters['businessUnits/getHierarchyLevel'](
              businessUnitId,
              ORGANIZATION
            ).id || company.hostIds.includes(businessUnitId)
      )
    },
}

export const actions = {
  async fetchCompanies({ commit }) {
    commit('LOADING_COMPANIES')

    await getAll()
      .then((res) => commit('RECEIVE_COMPANIES', res.data))
      .finally(() => {
        commit('LOADED_COMPANIES')
      })
  },
}

export const mutations = {
  LOADING_COMPANIES(state) {
    state.loading = true
  },
  LOADED_COMPANIES(state) {
    state.loading = false
  },
  RECEIVE_COMPANIES(state, data) {
    const receivedItems = {}

    data.forEach((json) => {
      receivedItems[json.id] = new Company(json)
    })

    if (!isEqual(receivedItems, state.all)) {
      state.all = receivedItems
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
