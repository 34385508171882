"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmptyUiSchema = exports.FieldUiSchema = void 0;
const json_schema_keys_1 = require("../../json_schema_keys");
const schema_object_1 = require("../schema_object");
class FieldUiSchema extends schema_object_1.SchemaObject {
    set widgetType(widgetType) {
        this[json_schema_keys_1.JsonSchemaKeys.UI_WIDGET_KEY] = widgetType;
    }
}
exports.FieldUiSchema = FieldUiSchema;
class EmptyUiSchema extends FieldUiSchema {
}
exports.EmptyUiSchema = EmptyUiSchema;
