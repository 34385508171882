<template>
  <form @submit.prevent>
    <vs-wrapper :space="space">
      <template v-if="bannerMessages && bannerMessages.length">
        <vs-alert type="error" data-test="error-banner">
          <p class="ma-0">Please correct the errors below</p>

          <ul v-if="!!bannerMessages" class="mt-2">
            <li
              v-for="(messages, index) in bannerMessages"
              :key="messages + index"
            >
              {{ messages }}
            </li>
          </ul>
        </vs-alert>
      </template>

      <slot />
    </vs-wrapper>

    <vs-snackbar v-model="showSnackbar" type="error">
      Please correct the errors on the form
    </vs-snackbar>
  </form>
</template>

<script>
import VsWrapper from '@/components/vision/VsWrapper.vue'
import VsSnackbar from '@/components/vision/VsSnackbar.vue'
import VsAlert from '@/components/vision/VsAlert.vue'

/**
 * Grab all the validation rules on every children fields and run them after
 * calling`.isValid()`
 */
export default {
  components: {
    VsWrapper,
    VsSnackbar,
    VsAlert,
  },

  props: {
    /**
     * Shows an error message on the input children and/or in a banner along
     * with a snackbar.
     *
     * The object requires a key:value pair where `key` is the name of the input
     * and `value` are the messages
     */
    errorMessages: {
      type: [Object, Boolean],
      default: null,
    },
    /**
     * Determines the spacing between childrens
     */
    space: {
      type: String,
      default: 'base',
      validator: (value) => ['base', 'small', 'large'].includes(value),
    },
  },
  data() {
    return {
      inputs: [],
      showSnackbar: false,
      bannerMessages: null,
    }
  },
  watch: {
    errorMessages(messages) {
      this.renderBannerError(messages)
      this.renderFieldError(messages)
    },
  },
  mounted() {
    if (this.errorMessages) {
      this.renderBannerError(this.errorMessages)
      this.renderFieldError(this.errorMessages)
    }
  },
  methods: {
    register(component) {
      this.inputs.push(component)
    },
    unregister(component) {
      this.inputs = this.inputs.filter((input) => input._uid !== component._uid)
    },
    isValid() {
      return this.inputs.filter((input) => input.runValidation()).length === 0
    },
    renderBannerError(messages) {
      if (!messages) return (this.bannerMessages = null)

      const names = this.inputs.reduce((results, input) => {
        input.name && results.push(input.name)
        return results
      }, [])
      const messageKeys = Object.keys(messages)
      const filteredKeys = messageKeys.filter((key) => !names.includes(key))

      this.bannerMessages = filteredKeys
        .map((key) => messages[key])
        .flat(Infinity)
    },
    renderFieldError(messages) {
      if (!messages) {
        return this.inputs.forEach((input) => input.resetErrorMessages())
      }
      this.showSnackbar = !!Object.keys(messages).length
      this.$nextTick(() => {
        this.inputs.forEach((input) => {
          const message = messages[input.name]
          if (message) {
            const msg = typeof message === 'string' ? [message] : message
            input.addErrorMessages(msg)
          }
        })
      })
    },
  },
  provide() {
    return {
      VsFormRegistration: {
        register: this.register,
        unregister: this.unregister,
      },
    }
  },
}
</script>

<style scoped>
form {
  position: relative;
}

.actions {
  text-align: right;
}

.snackbar {
  position: sticky;
  bottom: 64px;
  margin-top: var(--space-base);
}
</style>
