<template>
  <v-row no-gutters class="flex-column fill-height">
    <v-col class="job-queue-wrapper shrink">
      <v-slide-y-transition>
        <DailyScheduleJobQueue
          v-if="showJobQueue"
          @row-clicked="handleJobSelected"
        />
      </v-slide-y-transition>
    </v-col>

    <v-col>
      <DailySchedule @select="handleJobSelected" />
    </v-col>

    <RightDrawer
      :is-visible="showRightDrawer"
      :is-bottom-drawer-visible="showBottomDrawer"
    >
      <router-view />
      <template #bottom-drawer>
        <router-view name="bottomdrawer" />
      </template>
    </RightDrawer>
  </v-row>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import moment from 'moment'
import { debounce } from 'lodash'
import DailyScheduleJobQueue from '@/components/daily-schedule/DailyScheduleJobQueue'
import DailySchedule from '@/components/daily-schedule/DailySchedule'
import RightDrawer from '@/components/common/RightDrawer'
import { useScheduleFilterStore } from '../components/schedule/ScheduleFilterStore'
import { mapActions as mapPiniaActions } from 'pinia'

export default {
  name: 'DayView',
  components: {
    DailyScheduleJobQueue,
    DailySchedule,
    RightDrawer,
  },
  data() {
    return {
      fetchWindow: {
        start: null,
        end: null,
      },
    }
  },
  computed: {
    ...mapState('dayView', ['range', 'showJobQueue']),
    ...mapGetters({
      shouldNavigate: 'tickets/getShouldNavigate',
    }),
    showRightDrawer() {
      return this.$route.name !== 'day-view'
    },
    showBottomDrawer() {
      return this.$route.name === 'sv-unit-edit-view'
    },
  },
  watch: {
    range(newRange, oldRange) {
      const currentStart =
        this.fetchWindow.start || moment().startOf('day').subtract(2, 'day')
      const currentEnd =
        this.fetchWindow.end || moment().endOf('day').add(2, 'day')

      if (
        newRange.start.isBefore(currentStart) ||
        newRange.end.isAfter(currentEnd)
      ) {
        this.fetchWindow = {
          start: this.range.start.clone().startOf('day').subtract(1, 'day'),
          end: this.range.end.clone().endOf('day').add(1, 'day'),
        }

        this.fetch()
      }
    },
  },
  destroyed() {
    document.title = 'IronSight'
  },
  created() {
    document.title = 'Schedule View - IronSight'
    this.fetch()
    this.setLastViewedSchedule('day')
  },
  beforeDestroy() {
    this.stopPollingJobs()
  },
  methods: {
    ...mapPiniaActions(useScheduleFilterStore, {
      setLastViewedSchedule: 'updateLastViewedSchedule',
    }),
    ...mapActions('jobs', {
      startPollingJobs: 'startPolling',
      stopPollingJobs: 'stopPolling',
    }),
    async handleJobSelected(id) {
      if (this.$route.name === 'sv-ticket-info-panel') {
        await this.pushToScheduleView()
      }
      if (this.shouldNavigate) {
        setTimeout(() => this.pushToJobForm(id), 350)
      }
    },
    pushToJobForm(id) {
      this.$router.push({ name: 'sv-ticket-info-panel', params: { id } })
    },
    async pushToScheduleView() {
      await this.$router.push({ name: 'day-view' })
    },
    fetch: debounce(function () {
      this.startPollingJobs({
        start: this.fetchWindow.start,
        end: this.fetchWindow.end,
      })
    }, 500),
  },
}
</script>

<style>
.vis-label.vis-nested-group.vis-group-level-1 {
  overflow: hidden;
  background-color: #fafafa;
}

.vis-label.vis-nesting-group::before {
  width: 52px;
  height: auto;
  padding: 12px;
  text-align: center;
  vertical-align: top;
  box-sizing: border-box;
}

.vis-labelset .vis-label.vis-nested-group .vis-inner {
  width: 280px;
  padding-left: 0px !important;
}

.vis-labelset .vis-label .vis-inner {
  padding: 0;
}

.vis-label.vis-nesting-group {
  position: relative;
  z-index: var(--elevation-base);
  background-color: var(--color-white);
  box-shadow: var(--shadow-low);
}

.vis-item {
  background: none;
}

.vis-item,
.vis-item.vis-range {
  border: none;
  border-radius: 4px;
}

.vis-timeline {
  border: 0;
  display: flex;
  flex: 1 1 auto;
}

.vis-time-axis.vis-foreground {
  background: black;
}

.vis-time-axis.vis-foreground .vis-text.vis-major {
  color: white;
}

.vis-time-axis .vis-text.vis-minor.vis-even {
  background: white;
}

.vis-time-axis .vis-minor.vis-odd {
  background: #eee;
}

.vis-time-axis .vis-grid.vis-minor {
  border-color: #eee;
}

.vis-time-axis .vis-minor.vis-odd {
  background: #fafafa;
}

.vis-time-axis .vis-minor.vis-even {
  background: #fff;
}

.vis-time-axis .vis-grid.vis-minor {
  border-color: rgba(0, 0, 0, 0);
}

div.vis-tooltip {
  max-width: 300px;
  padding: 0;
  border: 0;
  background: none;
  font-family: inherit;
  font-size: inherit;
  white-space: initial;
}

.job-tooltip {
  padding: 5px 16px;
  background: rgba(97, 97, 97, 0.9);
  color: #fff;
  border-radius: 4px;
  pointer-events: none;
}

.job-tooltip .job-details {
  white-space: pre-line;
}

.job-card {
  overflow: hidden;
}

.vis-item .vis-item-content {
  width: 100%;
  padding: 0px;
}

.vis-item a {
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vis-item.vis-selected {
  background: none;
}

.vis-item.shadow {
  z-index: 0;
}

.vis-item .state.schedule-chip {
  background-color: var(--state-color, #e0e0e0);
  border-color: var(--state-color, #e0e0e0);
}

.new {
  --state-color: #bbdefb;
}

.requested {
  --state-color: #bbdefb;
}

.scheduled {
  --state-color: #c5cae9;
}

.active {
  --state-color: #ffe0b2;
}

.paused {
  --state-color: #fff9c4;
}

.late {
  --state-color: #ffe0b2;
}

.completed {
  --state-color: #c8e6c9;
}

.cancelled {
  --state-color: #ffcdd2;
}

.job-progress {
  width: 100%;
  background-color: #00c853;
}

.progress-bar {
  max-width: 100%;
  height: 100%;
  background-color: var(--bar-color);
}
.progress-bar.active {
  --bar-color: var(--color-green);
}
.progress-bar.paused {
  --bar-color: var(--color-yellow);
}
</style>

<style scoped>
.job-queue-wrapper {
  max-height: 50%;
}
</style>
