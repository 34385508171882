import { GetFormsAccessGrantResponse } from '@/api/forms'

export default class FormAccessGrant {
  constructor(json: GetFormsAccessGrantResponse) {
    this.businessUnitId = json.business_unit_id
    this.roleRestrictions = json.role_restrictions
  }

  businessUnitId: string
  roleRestrictions: string[]
}
