import { get } from './base'
import { useQuery } from '@tanstack/vue-query'
import { computed, Ref, unref } from 'vue'
import moment from 'moment'

const resourceTypeActivitiesEndpoint = '/api/v2/ResourceTypeActivities'

export function getResourceTypeActivities() {
  return get(resourceTypeActivitiesEndpoint)
}

export function useResourceTypeActivitiesQuery() {
  const { data } = useQuery({
    queryKey: ['resourceTypeActivities'],
    queryFn: () => getResourceTypeActivities().then((r) => r.data),
    staleTime: moment.duration(10, 'minutes').asMilliseconds(),
  })

  return {
    data,
  }
}

export function useResourceTypeActivityQuery(id: Ref<number> | number) {
  const { data } = useResourceTypeActivitiesQuery()

  const rta = computed(() => data.value?.find((rta) => rta.id === unref(id)))

  return {
    data: rta,
  }
}
