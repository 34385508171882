"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OnChangeModel = void 0;
const schema_object_1 = require("../schema_object");
class OnChangeModel extends schema_object_1.SchemaObject {
    addIfThenModel(uniqueKey, ifThenModel) {
        var _a;
        (_a = this[uniqueKey]) !== null && _a !== void 0 ? _a : (this[uniqueKey] = new Array());
        this[uniqueKey].push(ifThenModel);
    }
}
exports.OnChangeModel = OnChangeModel;
