"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormButtonsDefinition = void 0;
const form_buttons_property_description_1 = require("../models/field_property_descriptions/form_buttons_property_description");
const form_buttons_ui_schema_1 = require("../models/field_ui_schema/form_buttons_ui_schema");
const text_entry_field_definition_1 = require("./text_entry_field_definition");
class FormButtonsDefinition extends text_entry_field_definition_1.TextEntryFieldDefinition {
    constructor() {
        super(...arguments);
        this.buttonLabels = [];
    }
    propertyDescription() {
        return new form_buttons_property_description_1.FormButtonsPropertyDescription(this);
    }
    uiSchema() {
        return new form_buttons_ui_schema_1.FormButtonsUiSchema();
    }
}
exports.FormButtonsDefinition = FormButtonsDefinition;
