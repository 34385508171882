<template>
  <vs-wrapper>
    <vs-date-picker
      v-if="isDateTimeField"
      can-select-past
      data-test="datetime-picker-field"
      :label="title"
      :hint="description"
      :value="formData"
      :error-messages="errorMessages"
      :disabled="disabled"
      @input="onInput"
    />
    <vs-date-picker
      v-else-if="isDateField"
      can-select-past
      data-test="date-picker-field"
      :enable-time="false"
      :label="title"
      :hint="description"
      :value="formData"
      :error-messages="errorMessages"
      :disabled="disabled"
      @input="onInput"
    />
    <vs-text-input
      v-else
      :label="title"
      data-test="text-input-field"
      :hint="description"
      :max-length="schema.maxLength"
      :min-length="schema.minLength"
      :value="formData"
      :error-messages="errorMessages"
      :disabled="disabled"
      @input="onInput"
    />
  </vs-wrapper>
</template>

<script>
import { isString } from 'lodash'
import { isMoment } from 'moment'

export default {
  name: 'StringField',
  props: {
    errorSchema: Object,
    formData: String,
    params: Object,
    rootSchema: Object,
    schema: Object,
    title: String,
    description: String,
    uiSchema: Object,
    disabled: Boolean,
  },
  computed: {
    errorMessages() {
      const errors = this.errorSchema?.errors ?? []
      if (this.formData && typeof this.formData !== 'string') {
        errors.push('Text value expected.')
      }
      return errors
    },
    isDateField() {
      return this.schema.format === 'date'
    },
    isDateTimeField() {
      return this.schema.format === 'date-time'
    },
  },
  methods: {
    onInput(event) {
      if (this.isDateTimeField && isMoment(event)) {
        event = event.toISOString()
      } else if (this.isDateField) {
        if (isMoment(event)) {
          event = event.toISOString()
        }
        if (isString(event)) {
          event = event.split('T')[0]
        }
      }
      this.$emit('change', event)
    },
  },
}
</script>

<style scoped></style>
