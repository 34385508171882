<template>
  <form-layout
    key="project-selection"
    title="Add to project"
    @on-dismiss="dismiss"
  >
    <vs-wrapper>
      <project-creation-section
        :job-id="jobId"
        @save="onSave"
        @dismiss="dismiss"
      />

      <v-divider class="project-divider" aria-label="or" />

      <projects-list :owner-id="ownerId" @select="onSelect" />
    </vs-wrapper>

    <template v-if="totalPages > 0" #action-bar>
      <vs-pagination v-model="page" :length="totalPages" />
    </template>

    <template #snack-bar-wrapper>
      <vs-snackbar v-model="isSnackbarVisible" type="error" sticky
        >Failed to create the project</vs-snackbar
      >
    </template>
  </form-layout>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import FormLayout from '../common/FormLayout.vue'
import ProjectCreationSection from './ProjectCreationSection.vue'
import ProjectsList from './ProjectsList.vue'

export default {
  components: { ProjectCreationSection, ProjectsList, FormLayout },
  props: {
    jobId: {
      type: Number,
      required: true,
    },
    ownerId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getTotalPages: 'projects/getTotalPages',
      currentPage: 'projects/getCurrentPage',
      projectError: 'projects/getError',
      addJobError: 'projects/getAddJobError',
    }),
    isSnackbarVisible: {
      get() {
        return !!this.projectError
      },
      set(newValue) {
        if (!newValue) {
          this.clearError()
        }
      },
    },
    page: {
      get() {
        return this.currentPage
      },
      set(newValue) {
        this.updatecurrentPage(newValue)
      },
    },
    totalPages() {
      return this.getTotalPages(this.ownerId)
    },
  },
  created() {
    this.fetchProjects()
  },
  methods: {
    ...mapActions({
      createProject: 'projects/createProject',
      addJobToProject: 'projects/addJobToProject',
      fetchProjects: 'projects/fetchProjects',
    }),
    ...mapMutations('projects', {
      clearError: 'CLEAR_ERROR_PROJECT',
      updatecurrentPage: 'UPDATE_CURRENT_PAGE',
    }),
    async onSave(projectName) {
      const projectId = await this.createProject({
        projectName,
        jobId: this.jobId,
        ownerId: this.ownerId,
      })

      if (!this.projectError && !this.addJobError) {
        this.$emit('job-added-success', projectId)
      } else if (this.addJobError) {
        this.$emit('job-added-failure', 'addToNewProjectError')
      }
    },
    async onSelect(projectId) {
      await this.addJobToProject({ projectId, jobId: this.jobId })

      if (!this.projectError && !this.addJobError) {
        this.$emit('job-added-success', projectId)
      } else if (this.addJobError) {
        this.$emit('job-added-failure', 'addToExistingProjectError')
      }
    },
    dismiss() {
      this.$emit('dismiss')
    },
  },
}
</script>

<style scoped>
.project-divider::after {
  content: attr(aria-label);
  font-family: var(--font-regular);
  font-size: 12px;
  text-transform: uppercase;
  position: absolute;

  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 var(--space-smaller);
  background-color: white;
}
.project-divider {
  position: relative;
  margin: var(--space-large) 0 0 0;
  padding: 0 0 var(--space-large) 0;
}
</style>
