<template>
  <vs-wrapper>
    <vs-heading v-if="title" type="subhead">{{ title }}</vs-heading>
    <div v-if="description" class="hint">
      <vs-text v-if="description" type="subdued">{{ description }}</vs-text>
    </div>
    <v-card v-for="group in groups" :key="group.title">
      <v-card-title>{{ group.title }}</v-card-title>
      <v-container>
        <v-row v-for="field in group.fields" :key="field.name" align="center">
          <v-col>
            {{ field.name }}
          </v-col>
          <v-col>
            <v-radio-group @change="onFieldChange(field.name, $event)">
              <v-radio
                v-for="option in field.options"
                :key="option.value"
                :label="option.label"
                :value="option.value"
                :disabled="disabled"
              />
            </v-radio-group>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </vs-wrapper>
</template>

<script>
/**
 * @deprecated since version 6.0 component needs re-write as it breaks the JSON schema patterns
 */
export default {
  name: 'GroupRadioSelectorField',
  props: {
    errorSchema: Object,
    formData: Array,
    params: Object,
    rootSchema: Object,
    schema: Object,
    title: String,
    description: String,
    uiSchema: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    groups() {
      return this.uiSchema['ui:options'].groups
    },
  },
  methods: {
    onFieldChange(fieldName, value) {
      const formData = this.formData ? [...this.formData] : []
      const field = formData.find((item) => item.name === fieldName)

      if (field) {
        field.value = value
      } else {
        formData.push({ name: fieldName, value })
      }

      this.$emit('change', formData)
    },
  },
}
</script>

<style scoped></style>
