import axios from 'axios'
import { get } from './base'
import Dashboard from '@/models/dashboard'
import { computed, unref } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import moment from 'moment'
import { convertToCamelCase } from '@/models/utils/casing'
import { MaybeRef } from '@vueuse/core'

let source = axios.CancelToken.source()

const dashboardsEndPoint = '/api/v2/dashboards'

export function getDashboards(signal?: AbortSignal) {
  source.cancel('Cancel old get dashboards request')
  source = axios.CancelToken.source()

  const dashboards = get<Dashboard[]>(dashboardsEndPoint, {
    cancelToken: source.token,
  }).then((response) => convertToCamelCase(response.data))

  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled by TanStack Query')
  })

  return dashboards
}

export function getMetabaseDashboardById(metabaseDashboardId: number) {
  return get<string>(`${dashboardsEndPoint}/metabase/${metabaseDashboardId}`, {
    cancelToken: source.token,
  })
}

export function useGetDashboardsQuery() {
  const queryKey = computed(() => ['dashboards'])
  return useQuery({
    queryKey,
    queryFn: ({ signal }) => getDashboards(signal),
    staleTime: moment.duration(1, 'day').asMilliseconds(),
  })
}

export function getDashboardById(
  dashboardId: number | string,
  signal?: AbortSignal
) {
  const dashboard = get<string>(`${dashboardsEndPoint}/${dashboardId}`, {
    cancelToken: source.token,
  }).then((response) => response.data)
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled by TanStack Query')
  })
  return dashboard
}

export function useGetDashboardById(
  dashboardId?: MaybeRef<number | null | undefined>
) {
  const computedId = computed(() => unref(dashboardId))
  const queryKey = computed(() => ['dashboards', computedId.value])
  const enabled = computed(() => {
    return computedId.value !== null && computedId.value !== undefined
  })

  return useQuery({
    queryKey,
    queryFn: ({ signal }) => getDashboardById(computedId.value!, signal),
    staleTime: moment.duration(1, 'hours').asMilliseconds(),
    enabled,
  })
}
