import { isEqual, orderBy } from 'lodash'
import Vue from 'vue'
import * as api from '../../api/task'
import Task from '../../models/task'
import errorNotification from './error-notification'
import createItemForm from './item-form'

export const LOADING = 'LOADING'
export const RECEIVE_ITEM = 'RECEIVE_ITEM'
export const RECEIVE_ITEMS = 'RECEIVE_ITEMS'
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const ERROR_LOADING = 'ERROR_LOADING'

const headers = [
  { text: 'Name', value: 'name' },
  { text: 'Resource Types', value: 'resourceTypes' },
  { text: 'Hub', value: 'ownerName' },
]

const modules = {
  errorNotification,
  itemForm: createItemForm(api, Task), // TODO(aaron): seems unused
}

const state = () => ({
  all: {},
  loading: false,
})

export const getters = {
  getHeaders: () => headers,

  getAllItems(state) {
    return state.all
  },

  getById: (state) => (id) => state.all[id] || null,

  isLoadingTable: (state) =>
    state.loading && Object.keys(state.all).length === 0,

  isItemVisible: (state) => (id) => state.all[id] && state.all[id].isActive,

  getItemsToDisplay: (_state, getters) => {
    const allItems = getters.getAllItems

    return Object.values(getters.getSortedItems(allItems)).map((item) => {
      const resourceTypes = item.resourceTypes.map((resourceType) => {
        return resourceType.name
      })

      return {
        ...item,
        ...{ resourceTypes, isArchived: item.isArchived },
      }
    })
  },

  getSortedItems: () => (items) => {
    return orderBy(items, ['isActive', 'id'], ['desc', 'desc'])
  },

  getCustomFieldsSchema: (state) =>
    Object.values(state.all)
      .filter((task) => task && task.jobCustomFieldsSchema)
      .map((task) => task.jobCustomFieldsSchema),
}

export const actions = {
  async fetchTasks({ commit }) {
    commit(LOADING, true)

    return api
      .getAll()
      .then((res) => {
        commit(RECEIVE_ITEMS, res)
        commit(CLEAR_ERROR, res)
      })
      .catch((err) => commit(ERROR_LOADING, err))
  },
}

export const mutations = {
  [RECEIVE_ITEMS](state, { data }) {
    const receivedItems = {}

    data.forEach((json) => {
      if (!('job_custom_fields_schema' in json)) {
        json.job_custom_fields_schema = { schema: {} }
      } else if (!('schema' in json.job_custom_fields_schema)) {
        json.job_custom_fields_schema = {
          schema: json.job_custom_fields_schema,
        }
      }
      receivedItems[json.id] = new Task(json)
    })

    if (!isEqual(receivedItems, state.all)) {
      state.all = receivedItems
    }

    state.loading = false
  },
  [RECEIVE_ITEM](state, item) {
    Vue.set(state.all, item.id, item)
  },
  [LOADING](state, value) {
    state.loading = value
  },
}

export default {
  namespaced: true,
  modules,
  state,
  getters,
  actions,
  mutations,
}
