<template>
  <vs-wrapper space="small">
    <vs-card accent="var(--color-blue--lighter)">
      <div class="d-flex flex-row align-center justify-space-between">
        <vs-text>
          If field is "<b class="emphasis">{{ dto.requiredValue }}</b
          >" go to flow: <b>{{ dto.flowKey }}</b>
          <i>
            {{
              dto.conditionalType === 0
                ? 'immediately'
                : 'after the "next" button is pressed'
            }}
          </i>
        </vs-text>
        <vs-button
          destructive
          type="tertiary"
          icon="delete"
          @click="deleteFlow"
        />
      </div>
    </vs-card>
  </vs-wrapper>
</template>

<script>
import VsCard from '@/components/vision/VsCard'
import VsWrapper from '@/components/vision/VsWrapper'
import VsText from '@/components/vision/VsText'
import VsButton from '@/components/vision/VsButton'
import { MarkdownFieldDefinition } from 'ironsight-form-builder'
export default {
  name: 'GoToFlowItem',
  components: { VsButton, VsText, VsWrapper, VsCard },

  props: {
    field: {
      type: Object,
      default: () => {},
    },
    dto: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    deleteFlow() {
      this.$emit('delete-flow')
    },
  },
}
</script>

<style scoped>
.emphasis {
  color: var(--color-blue);
}
</style>
