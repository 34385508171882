<template>
  <DailyScheduleProvider :jobs="filteredJobs" :groupings="groupings" :now="now">
    <template #default="{ items, groups, options }">
      <div class="timeline">
        <DailyScheduleNavigator :range="range" @rangechanged="onRangeChanged" />

        <DailyScheduleTimeline
          ref="timeline"
          :items="items"
          :groups="groups"
          :options="options"
          :range="range"
          @select="onJobSelected"
          @rangechanged="onRangeChanged"
        />
      </div>
    </template>
  </DailyScheduleProvider>
</template>

<script>
import moment from 'moment'
import { orderBy } from 'lodash'
import { mapState, mapGetters, mapMutations } from 'vuex'
import DailyScheduleProvider from './DailyScheduleProvider'
import DailyScheduleTimeline from './DailyScheduleTimeline'
import DailyScheduleNavigator from './DailyScheduleNavigator'
import { SET_RANGE } from '@/store/modules/day-view'
import { mapState as mapPiniaState, mapGetters as mapPiniaGetters } from 'pinia'
import { useScheduleFilterStore } from '@/components/schedule/ScheduleFilterStore'
import { useJobFilterStore } from '@/components/field-view-next/JobsFilterStore'
import { useGetJobFilterOptions } from '@/api/filter-options'

export default {
  name: 'Schedule',
  components: {
    DailyScheduleTimeline,
    DailyScheduleProvider,
    DailyScheduleNavigator,
  },
  setup() {
    const { data: filterOptions } = useGetJobFilterOptions()
    return { filterOptions }
  },
  data() {
    return {
      now: moment.utc(),
      interval: null,
      updateFrequency: moment.duration(30, 'seconds').asMilliseconds(),
    }
  },
  computed: {
    ...mapState('dayView', ['range']),
    ...mapGetters('jobs', { jobs: 'getNonArchivedJobs' }),
    ...mapGetters('unitNumbers', ['getVisibleUnits']),
    ...mapGetters('resourceTypes', ['getAllResourceTypes']),
    ...mapGetters('divisions', { getDivisionById: 'getById' }),
    ...mapGetters('companies', {
      getCompanyByOrganizationId: 'getByOrganizationId',
    }),
    ...mapGetters('permissions', ['getRoleAssignmentBusinessUnitIds']),
    ...mapPiniaState(useScheduleFilterStore, {
      showOfflineUnits: 'showOfflineUnits',
      showStandbyUnits: 'showStandbyUnits',
      showUnitsWithNoAssignedJobs: 'showUnitsWithNoAssignedJobs',
    }),
    ...mapPiniaState(useJobFilterStore, {
      selectedDivisions: 'selectedDivisions',
      selectedServiceProviders: 'selectedServiceProviders',
      selectedResourceTypes: 'selectedResourceTypes',
    }),
    groupings() {
      const units = Object.values(this.getVisibleUnits)

      const parentGroups = this.getAllResourceTypes.filter((rt) =>
        this.selectedResourceTypes.includes(rt.id)
      )

      return parentGroups.map((group) => {
        const unitsForResourceType = units.filter(
          (u) => u.resourceTypeId === group.id
        )
        const orderedUnits = orderBy(this.filterUnits(unitsForResourceType), [
          'isOffline',
          'isActive',
          'isStandby',
          (r) => this.getDivisionById(r.divisionId)?.name,
          (r) => this.getCompanyByOrganizationId(r.organizationId)?.name,
        ])

        return {
          id: `resourceTypeId-${group.id}`,
          group,
          units: orderedUnits,
          unitsOverview: this.unitsOverview(
            orderedUnits,
            unitsForResourceType.length
          ),
        }
      })
    },
    filteredJobs() {
      const filtered = this.jobs
        .filter((j) => this.selectedDivisions.includes(j.ownerId))
        .filter((j) => this.selectedResourceTypes.includes(j.resourceTypeId))
        .filter((j) => j.isResourceAssigned)

      return orderBy(filtered, [
        'isLate',
        'isOverdue',
        'activeTime',
        'startTime',
      ])
    },
  },
  mounted() {
    let frame
    this.interval = setInterval(() => {
      cancelAnimationFrame(frame)
      frame = requestAnimationFrame(() => {
        // update the value of now so that late and overdue
        // jobs are redrawn to butt up against the rolling timeline marker
        this.now = moment.utc()
      })
    }, this.updateFrequency)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    ...mapMutations('dayView', {
      setRange: SET_RANGE,
    }),
    onJobSelected({ items }) {
      if (!items || !items.length) {
        return
      }
      const [jobItemId] = items
      const [, id] = jobItemId.match(/job-(\d+)/)
      this.$emit('select', id)
    },
    onRangeChanged({ start, end }) {
      this.setRange({ start: moment(start), end: moment(end) })
    },
    filterUnits(units) {
      return units.filter(
        (unit) =>
          (this.showOfflineUnits || !unit.isOffline) &&
          (this.showStandbyUnits || !unit.isStandby) &&
          (this.showUnitsWithNoAssignedJobs ||
            this.jobs.some((j) => j.unitId === unit.id)) &&
          this.selectedDivisions.length &&
          (this.selectedDivisions.some(
            (id) => id === this.getDivisionById(unit.divisionId)?.uuid
          ) ||
            (unit.divisionId === null &&
              this.getRoleAssignmentBusinessUnitIds.some(
                (b) => b === unit.ownerId
              ))) &&
          this.selectedServiceProviders.length &&
          this.selectedServiceProviders.some((id) => id === unit.organizationId)
      )
    },

    unitsOverview(filteredUnits, allUnitsCount) {
      if (allUnitsCount === 0 && filteredUnits.length === 0)
        return 'No resources in this group'

      let overview = ''
      const activeUnitsCount = filteredUnits.filter(
        (unit) => unit.isActive
      ).length
      overview += this.constructUnitOverview(
        overview,
        activeUnitsCount,
        'active'
      )

      if (this.showStandbyUnits) {
        const standbyUnitsCount = filteredUnits.filter(
          (unit) => unit.isStandby
        ).length

        overview += this.constructUnitOverview(
          overview,
          standbyUnitsCount,
          'standby'
        )
      }

      if (this.showOfflineUnits) {
        const offlineUnitsCount = filteredUnits.filter(
          (unit) => unit.isOffline
        ).length

        overview += this.constructUnitOverview(
          overview,
          offlineUnitsCount,
          'offline'
        )
      }

      const hiddenUnitsCount = allUnitsCount - filteredUnits.length

      overview += this.constructUnitOverview(
        overview,
        hiddenUnitsCount,
        'hidden'
      )

      return overview
    },
    constructUnitOverview(currentOverview, count, type) {
      if (count === 0) return ''

      let newOverview = ''

      !!currentOverview && (newOverview += ', ')
      newOverview += `${count} ${type}`

      return newOverview
    },
  },
}
</script>

<style scoped>
.timeline {
  position: relative;
  height: 100%;
}
</style>
