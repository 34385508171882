const APP_INITIALIZED = 'APP_INITIALIZED'
const SET_JOB_DATA_LAST_FETCH = 'SET_JOB_DATA_LAST_FETCH'

export async function initialize({ dispatch, commit }) {
  const requiredConfiguration = [
    dispatch('permissions/fetchAccountEntitlements'),
    dispatch('organizationConfig/load'),
    dispatch('featureFlags/fetchFeatureFlags'),
    dispatch('forms/fetch'), // Determines if forms tab is shown
    dispatch('firebase/initialize'),
  ]

  dispatch('jobs/initialize')
  commit(APP_INITIALIZED)
}

export async function fetchJobData(
  { state, dispatch, commit },
  hardRefresh = false
) {
  if (
    !hardRefresh &&
    state.jobDataLastFetch != null &&
    Date.now() - state.jobDataLastFetch < 300000
  ) {
    return
  }

  await Promise.all([
    dispatch('activities/fetchResourceTypeActivities'),
    dispatch('assets/fetchAssets'),
    dispatch('companies/fetchCompanies'),
    dispatch('costCenters/fetchCostCenters'),
    dispatch('divisions/fetchDivisions'),
    dispatch('resourceTypes/fetchResourceTypes'),
    dispatch('schemas/fetchSchemas'),
    dispatch('services/fetchServices'),
    dispatch('tasks/fetchTasks'),
    dispatch('unitNumbers/fetchUnitNumbers'),
    dispatch('users/fetchAllUsers'),
    dispatch('businessUnits/fetchBusinessUnits'),
  ])

  commit('jobs/JOB_SUPPLEMENTARY_DATA_LOADED')
  commit(SET_JOB_DATA_LAST_FETCH, Date.now())
}
