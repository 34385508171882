import { computed, unref, UnwrapNestedRefs } from 'vue'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { del, get, post, put } from '@/api/base'
import InvoiceDTO, {
  BatchPaymentTerms,
  InvoiceListItem,
  SyncOption,
  SyncTarget,
} from '@/models/invoice'
import { convertTo } from '@/models/utils/casing'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import moment from 'moment'
import { MaybeRef } from '@vueuse/core'
import { PaginatedRequest } from '@/models/search-location-dto'

const invoicesEndPoint = '/api/invoices'

export function getById(id: string, signal?: AbortSignal) {
  const source = axios.CancelToken.source()
  const invoice = get<InvoiceDTO>(`${invoicesEndPoint}/${id}`, {
    cancelToken: source.token,
  }).then((res) => convertTo<InvoiceDTO>(res.data))
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled by TanStack Query')
  })
  return invoice
}

interface SearchBatchesOptions {
  offset?: number
  limit?: number
  query?: string
}

export function search(
  searchOptions?: SearchBatchesOptions | null,
  signal?: AbortSignal
) {
  const source = axios.CancelToken.source()
  const invoice = get<PaginatedRequest<InvoiceListItem>>(
    `${invoicesEndPoint}/`,
    {
      params: {
        offset: searchOptions?.offset,
        limit: searchOptions?.limit,
        query: searchOptions?.query,
      },
      cancelToken: source.token,
    }
  ).then((res) => convertTo<PaginatedRequest<InvoiceListItem>>(res.data))
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled by TanStack Query')
  })
  return invoice
}

function getCustomerNameRecommendationForInvoiceSync(signal?: AbortSignal) {
  const source = axios.CancelToken.source()
  const results = get<string[]>(
    `${invoicesEndPoint}/sync/customer-name-recommendation`
  ).then((res) => res.data as string[])
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled by TanStack Query')
  })
  return results
}

export function useGetCustomerNameRecommendationForInvoiceSync(
  signal?: AbortSignal
) {
  const queryKey = ['invoice', 'sync', 'customer-name-recommendation']
  return useQuery({
    queryKey,
    queryFn: ({ signal }) =>
      getCustomerNameRecommendationForInvoiceSync(signal),
    staleTime: moment.duration(10, 'minutes').asMilliseconds(),
  })
}

function updateInvoiceSyncCustomerName(
  invoiceId: string,
  targetId: string,
  customerName?: string
) {
  let url = `${invoicesEndPoint}/${invoiceId}/sync/customer-name/?targetId=${targetId}`
  if (customerName) {
    url += `&customerName=${customerName}`
  }
  return put(url, {})
}

export function useUpdateInvoiceSyncCustomerNameMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (variables: {
      invoiceId: string
      targetId: string
      customerName?: string
    }) =>
      updateInvoiceSyncCustomerName(
        variables.invoiceId,
        variables.targetId,
        variables.customerName
      ),
    onSuccess: async (_, variables) => {
      await Promise.all([
        queryClient.invalidateQueries([
          'invoice',
          'sync',
          'customer-name-recommendation',
        ]),
        queryClient.invalidateQueries([
          'invoice',
          variables.invoiceId,
          'sync-targets',
        ]),
      ])
    },
  })
}

export function create(
  id: string | null, // If null, the backend will auto populate this field with a random UUID
  invoiceNumber: string | null,
  invoiceDate: moment.Moment,
  paymentTerms: BatchPaymentTerms,
  dueDate: moment.Moment | null,
  jobIds: string[] | null,
  ownerId: string,
  memo: string | null
) {
  return post(`${invoicesEndPoint}/`, {
    data: {
      id,
      invoice_number: invoiceNumber,
      invoice_date: invoiceDate,
      payment_terms: paymentTerms,
      due_date: dueDate,
      job_ids: jobIds,
      owner_id: ownerId,
      memo,
    },
  })
}

export function update(
  id: string,
  invoiceNumber?: string | null,
  invoiceDate?: moment.Moment | null,
  paymentTerms?: BatchPaymentTerms | null,
  dueDate?: moment.Moment | null,
  jobIds?: string[] | null,
  memo?: string | null
) {
  return put(`${invoicesEndPoint}/${id}`, {
    data: {
      invoice_number: invoiceNumber,
      invoice_date: invoiceDate,
      payment_terms: paymentTerms,
      due_date: dueDate,
      job_ids: jobIds,
      memo,
    },
  })
}

export function exportInvoiceCsv(invoiceId: string, timeZone: string) {
  return get(`${invoicesEndPoint}/${invoiceId}/export/csv`, {
    params: {
      TimeZone: timeZone,
    },
    responseType: 'blob',
  })
}

export function exportInvoicePdf(invoiceId: string, timezone: string) {
  return get(`${invoicesEndPoint}/${invoiceId}/export/pdf`, {
    params: {
      Timezone: timezone,
    },
    responseType: 'blob',
  })
}

export function addJobsToInvoice(id: string, jobIds?: string[] | null) {
  return put(`${invoicesEndPoint}/${id}/jobs/add`, {
    data: {
      job_ids: jobIds,
    },
  })
}

export function removeJobsFromInvoice(id: string, jobIds?: string[] | null) {
  return del(`${invoicesEndPoint}/${id}/jobs/delete`, {
    data: {
      job_ids: jobIds,
    },
  })
}

export function removeJobsFromTheirInvoices(jobIds?: string[] | null) {
  return put(`${invoicesEndPoint}/jobs/delete`, {
    data: {
      job_ids: jobIds,
    },
  })
}

export function requestBatchSync(
  invoiceId: string,
  targetId: string,
  customerName?: string
) {
  let url = `/api/invoices/${invoiceId}/sync?targetId=${targetId}`
  if (customerName) {
    url += `&customerName=${customerName}`
  }
  return post(url, {})
}

export function getSyncOptions() {
  const options = get<Array<SyncOption>>(
    `${invoicesEndPoint}/sync-options`
  ).then((res) => convertTo<Array<SyncOption>>(res.data))
  return options
}

export interface InvoicingEntitlementResponse {
  hasEntitlement: boolean
}

export function useInvoiceQuery(
  invoiceId?: MaybeRef<string | null | undefined>
) {
  const computedId = computed(() => unref(invoiceId))
  const queryKey = computed(() => ['invoice', computedId.value])
  const enabled = computed(() => {
    return computedId.value !== null && computedId.value !== undefined
  })
  return useQuery({
    queryKey,
    queryFn: ({ signal }) => getById(computedId.value!, signal),
    staleTime: moment.duration(10, 'minutes').asMilliseconds(),
    enabled,
  })
}

export function useSearchInvoicesQuery(
  query: MaybeRef<UnwrapNestedRefs<SearchBatchesOptions>>
) {
  const queryKey = computed(() => ['invoice', 'search', unref(query)])
  const {
    data,
    error,
    isLoading,
    isFetched,
    isFetching,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey,
    queryFn: ({ signal }) => search(unref(query), signal),
    refetchInterval: moment.duration(30, 'seconds').asMilliseconds(),
    retry: false,
    keepPreviousData: true,
    enabled: true,
  })

  const invoices = computed(() => data.value?.data)

  const totalPages = computed<number | null | undefined>(() => {
    return data?.value?.meta?.totalPages
  })
  const totalItems = computed<number | null | undefined>(() => {
    return data?.value?.meta?.total
  })

  return {
    invoices,
    totalPages,
    totalItems,
    error,
    isLoading,
    isFetched,
    isFetching,
    isRefetching,
    reFetch: refetch,
  }
}

export function useCreateInvoiceMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (variables: {
      id: string | null
      invoiceNumber: string | null
      invoiceDate: moment.Moment
      paymentTerms: BatchPaymentTerms
      dueDate: moment.Moment | null
      jobIds: string[] | null
      ownerId: string
      memo: string | null
    }) =>
      create(
        variables.id,
        variables.invoiceNumber,
        variables.invoiceDate,
        variables.paymentTerms,
        variables.dueDate,
        variables.jobIds,
        variables.ownerId,
        variables.memo
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['invoice'])
    },
  })
}

export function useUpdateInvoiceMutation() {
  const queryClient = useQueryClient()
  return useMutation<
    AxiosResponse,
    AxiosError,
    {
      id: string
      invoiceNumber?: string | null
      invoiceDate?: moment.Moment | null
      paymentTerms: BatchPaymentTerms
      dueDate?: moment.Moment | null
      jobIds?: string[]
      memo?: string | null
    }
  >({
    mutationFn: (variables) =>
      update(
        variables.id,
        variables.invoiceNumber,
        variables.invoiceDate,
        variables.paymentTerms,
        variables.dueDate,
        variables.jobIds,
        variables.memo
      ),
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries(['invoice', variables.id])
    },
  })
}

export function useAddJobsToInvoiceMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (variables: { id: string; jobIds?: string[] }) =>
      addJobsToInvoice(variables.id, variables.jobIds),
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries(['invoice', variables.id])
    },
  })
}

export function useRemoveJobsFromInvoiceMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (variables: { id: string; jobIds?: string[] }) =>
      removeJobsFromInvoice(variables.id, variables.jobIds),
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries(['invoice', variables.id])
    },
  })
}

export function useRemoveJobsFromTheirInvoicesMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (variables: { jobIds?: string[] }) =>
      removeJobsFromTheirInvoices(variables.jobIds),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['invoice', 'search'])
    },
  })
}

export function useRequestBatchSync() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (variables: {
      invoiceId: string
      targetId: string
      customerName?: string
    }) =>
      requestBatchSync(
        variables.invoiceId,
        variables.targetId,
        variables.customerName
      ),
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries([
        'invoice',
        variables.invoiceId,
        'sync-targets',
      ])
    },
  })
}

export function useGetSyncOptionsQuery() {
  const queryKey = computed(() => ['invoice', 'syncOptions'])
  return useQuery({
    queryKey,
    queryFn: () => getSyncOptions(),
    staleTime: moment.duration(60, 'minutes').asMilliseconds(),
  })
}

function getInvoiceSyncTarget(invoiceId: string, signal?: AbortSignal) {
  const source = axios.CancelToken.source()
  const invoice = get<SyncTarget[]>(
    `${invoicesEndPoint}/${invoiceId}/sync-targets`,
    {
      cancelToken: source.token,
    }
  ).then((res) => convertTo<SyncTarget[]>(res.data))
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled by TanStack Query')
  })
  return invoice
}

export function useInvoiceSyncTargetsQuery(
  invoiceId?: MaybeRef<string | null | undefined>
) {
  const computedId = computed(() => unref(invoiceId))
  const queryKey = computed(() => ['invoice', computedId.value, 'sync-targets'])
  const enabled = computed(() => {
    return computedId.value !== null && computedId.value !== undefined
  })
  return useQuery({
    queryKey,
    queryFn: ({ signal }) => getInvoiceSyncTarget(computedId.value!, signal),
    enabled,
    refetchInterval: moment.duration(1, 'minutes').asMilliseconds(),
  })
}
