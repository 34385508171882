import { render, staticRenderFns } from "./TaskItem.vue?vue&type=template&id=5549a760&scoped=true&"
import script from "./TaskItem.vue?vue&type=script&lang=js&"
export * from "./TaskItem.vue?vue&type=script&lang=js&"
import style0 from "./TaskItem.vue?vue&type=style&index=0&id=5549a760&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5549a760",
  null
  
)

export default component.exports