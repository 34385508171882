import moment from 'moment'

export default class JobActivityLogEntry {
  constructor(json) {
    this.status = json.status
    this.occurredAt = moment.utc(json.occurred_at).local()
    this.unitId = json.unit_id
    this.userSid = json.user_sid
    this.eventId = json.event_id
    this.previousEventId = json.previous_event_id
  }
}
