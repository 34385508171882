"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LookupFieldDefinition = void 0;
const lookup_property_description_1 = require("../models/field_property_descriptions/lookup_property_description");
const lookup_ui_schema_1 = require("../models/field_ui_schema/lookup_ui_schema");
const form_field_definition_1 = require("./form_field_definition");
class LookupFieldDefinition extends form_field_definition_1.PrimitiveValueField {
    get dataType() {
        return this.lookupEntity.dataType;
    }
    constructor(lookupEntity) {
        super();
        this.lookupEntity = lookupEntity;
    }
    propertyDescription() {
        return new lookup_property_description_1.LookupPropertyDescription(this);
    }
    uiSchema() {
        return new lookup_ui_schema_1.LookupUiSchema(this.lookupEntity);
    }
}
exports.LookupFieldDefinition = LookupFieldDefinition;
