<template>
  <vs-card :title="title">
    <vs-text v-if="description" type="paragraph">
      {{ description }}
    </vs-text>
    <div class="map-container">
      <div class="map">
        <FieldMap
          ref="map"
          :center="initialCenterPoint"
          :zoom="13"
          mini
          @move="onMapMoved"
        >
          <SinglePointPin :data="data" />
        </FieldMap>
      </div>
    </div>
    <vs-text type="caption"> {{ lat }}, {{ lng }}</vs-text>
    <vs-text v-if="showErrorMessage" class="textColor-red">
      {{ allErrorMessages }}
    </vs-text>
  </vs-card>
</template>

<script>
import FieldMap from '@/components/field-view-next/mapbox/FieldMap.vue'
import SinglePointPin from '@/components/field-view-next/layers/SinglePointPin'
import { mapState } from 'vuex'
import {
  latLngToPoint,
  pointToLatLng,
  pointToMapData,
} from '@/utils/forms/point-helper'

export default {
  name: 'LatLngField',
  components: {
    FieldMap,
    SinglePointPin,
  },
  props: {
    errorSchema: Object,
    formData: String,
    params: Object,
    rootSchema: Object,
    schema: Object,
    title: String,
    description: String,
    uiSchema: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initialCenterPoint: {},
    }
  },
  computed: {
    ...mapState('organizationConfig', ['mapCenterPoint']),
    errorMessages() {
      const errors = this.errorSchema?.errors ?? []
      if (this.formData && typeof this.formData !== 'string') {
        errors.push('Lat Lng value expected.')
      }
      return errors
    },
    latLng() {
      return pointToLatLng(this.formData)
    },
    lat() {
      return this.latLng?.lat ?? this.initialCenterPoint.lat
    },
    lng() {
      return this.latLng?.lng ?? this.initialCenterPoint.lng
    },
    data() {
      return pointToMapData(
        this.formData ?? latLngToPoint(this.initialCenterPoint)
      )
    },
    showErrorMessage() {
      return this.allErrorMessages.length > 0
    },
    allErrorMessages() {
      return (this.errorSchema?.errors ?? []).join('\n')
    },
  },
  created() {
    this.initialCenterPoint = this.formData
      ? pointToLatLng(this.formData)
      : {
          lat: this.mapCenterPoint.latitude,
          lng: this.mapCenterPoint.longitude,
        }
  },
  methods: {
    onMapMoved(latlng) {
      const point = latLngToPoint(latlng)
      this.$emit('change', point)
    },
  },
}
</script>

<style scoped>
.map-container {
  border-radius: var(--space-base);
  overflow: hidden;
  padding-top: 2px;
  padding-bottom: 2px;
}

.map {
  height: 320px;
}
</style>
