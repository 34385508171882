<template>
  <div class="bottom-drawer">
    <Transition name="fade">
      <div v-if="isVisible" class="bottom-drawer-overlay" />
    </Transition>
    <Transition name="slide-bottom">
      <div
        v-if="isVisible"
        :class="[{ 'full-height': fullHeight }, 'bottom-drawer-content']"
      >
        <div class="bottom-drawer-content-wrapper">
          <slot />
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import Transition from '@/components/common/Transition'

export default {
  components: { Transition },

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.bottom-drawer,
.bottom-drawer-overlay,
.bottom-drawer-content {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  overflow: hidden;
}

.bottom-drawer {
  pointer-events: none;
  z-index: var(--elevation-sidesheet);
}

.bottom-drawer-overlay {
  pointer-events: auto;
  background-color: rgba(0, 0, 0, 0.7);
}

.bottom-drawer-content {
  overflow: auto;
  pointer-events: auto;
  top: auto;
  max-height: 96vh;
  background-color: #fff;
  box-shadow: var(--shadow-base);
  border-top-left-radius: var(--space-base);
  border-top-right-radius: var(--space-base);
  overflow: auto;
  display: flex;
}

.bottom-drawer-content-wrapper {
  flex: 1;
}

.full-height {
  height: 96vh;
}
</style>
