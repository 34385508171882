"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnumCondition = exports.RequiredCondition = exports.ConstCondition = exports.Condition = exports.ConditionalType = void 0;
var ConditionalType;
(function (ConditionalType) {
    ConditionalType[ConditionalType["onChange"] = 0] = "onChange";
    ConditionalType[ConditionalType["onSubmit"] = 1] = "onSubmit";
})(ConditionalType || (exports.ConditionalType = ConditionalType = {}));
class Condition {
}
exports.Condition = Condition;
class ConstCondition extends Condition {
    constructor(requiredValue) {
        super();
        this.const = requiredValue;
    }
}
exports.ConstCondition = ConstCondition;
class RequiredCondition extends Condition {
    constructor(requiredValue) {
        super();
        this.requiredField = requiredValue;
    }
}
exports.RequiredCondition = RequiredCondition;
class EnumCondition extends Condition {
    constructor(acceptedValues) {
        super();
        this.enum = acceptedValues;
    }
}
exports.EnumCondition = EnumCondition;
