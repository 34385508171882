<template>
  <InputWrapper
    :label="label"
    :disabled="disabled"
    :mini-label="miniLabel"
    :error-messages="allErrorMessages"
    :hint="hint"
    :hide-mini-label="hideMiniLabel"
    :name="name"
  >
    <div v-if="multiline" ref="textareaWrapper" class="flex">
      <textarea
        ref="input"
        v-bind="attrs"
        :rows="rows"
        :class="{ 'has-rows': rows }"
        @input="handleInput"
        @blur="handleBlur"
        @focus="handleFocus"
        @keydown="handleKeydown"
        @paste="handlePaste"
      />
    </div>
    <input
      v-else
      ref="input"
      :min="minValue"
      :class="inputClass"
      :type="type"
      :step="numberStep"
      :disabled="disabled"
      v-bind="attrs"
      @input="handleInput"
      @blur="handleBlur"
      @focus="handleFocus"
      @paste="handlePaste"
    />

    <template #append>
      <slot name="append" />
    </template>

    <template #outer-append>
      <slot name="outer-append" />
    </template>

    <template v-if="maxLength" #bottom-right>
      <vs-text type="subdued" tag="span">
        <span class="pr-3" :class="{ 'maxed-out': isMaxedOut }">
          {{ charCount }}/{{ maxLength }}
        </span>
      </vs-text>
    </template>
  </InputWrapper>
</template>

<script>
import InputWrapper, { inputWrapperProps } from './InputWrapper'
import { sharedOptions } from './helper'

export const inputProps = {
  ...inputWrapperProps,
  /**
   * Automatically add a validation to check if the input value is empty
   */
  required: {
    type: Boolean,
    default: false,
  },
  /**
   * Array of function where you can add in validation rules
   */
  rules: {
    type: Array,
    default: null,
  },
  /**
   * the value of the input
   */
  value: {
    type: [String, Number],
    default: '',
  },
  type: {
    type: String,
    default: 'text',
    validator: (value) => ['text', 'number'].includes(value),
  },
  errorMessages: {
    type: Array,
    default: null,
  },
  /**
   * Tells the sharedOptions mixin to use shorter error messages when possible
   */
  useShortMessages: {
    type: Boolean,
    default: false,
  },
  maxLength: {
    type: Number,
    default: null,
  },
  minLength: {
    type: Number,
    default: null,
  },
  minValue: {
    type: String,
    default: null,
  },
  /**
   * Focuses the field on mount
   */
  autofocus: {
    type: Boolean,
    default: false,
  },
  rows: {
    type: String,
    default: null,
  },
  numberStep: {
    type: String,
  },
  inputClass: {
    type: String,
  },
}

/**
 * Text input is used in forms that accept short or long answers from users.
 */
export default {
  components: { InputWrapper },
  mixins: [sharedOptions],
  props: {
    ...inputProps,
    /**
     * Changes the text field to a text area
     */
    multiline: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    attrs() {
      return {
        value: this.value,
        placeholder: this.label,
        disabled: this.disabled,
      }
    },
    charCount() {
      return this.value?.length || 0
    },
    isMaxedOut() {
      return this.maxLength && this.charCount > this.maxLength
    },
    miniLabel() {
      if (this.type === 'number') {
        return !!this.value || this.value === 0
      }
      return !!this.value
    },
  },
  updated() {
    this.multiline && this.resizeTextArea()
  },
  mounted() {
    this.multiline && this.resizeTextArea()
    this.autofocus && this.$refs.input.focus()
  },
  methods: {
    handleInput(event) {
      const newValue = event.target.value
      return this.$emit('input', newValue)
    },
    handleBlur(event) {
      this.runValidation()
      const newValue = event.target.value
      return this.$emit('blur', newValue)
    },
    handleFocus(event) {
      return this.$emit('focus', event)
    },
    handleKeydown(event) {
      return this.$emit('keydown', event)
    },
    handlePaste(event) {
      return this.$emit('paste', event)
    },
    resizeTextArea() {
      const target = this.$refs.input
      target.style.height = 'auto'

      if (target.scrollHeight) {
        const newHeight = `${target.scrollHeight}px`
        target.style.height = newHeight
        this.$refs.textareaWrapper.style.height = newHeight
      }
    },
  },
}
</script>

<style scoped>
textarea {
  min-height: 144px;
  resize: none;
}

.has-rows {
  min-height: auto;
}

.maxed-out {
  color: var(--color-red);
}
</style>
