"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DecimalPropertyDescription = void 0;
const json_schema_keys_1 = require("../../json_schema_keys");
const field_property_description_1 = require("./field_property_description");
class DecimalPropertyDescription extends field_property_description_1.DefaultFieldPropertyDescription {
    set minimumValue(minimumValue) {
        this[json_schema_keys_1.JsonSchemaKeys.MINIMUM_KEY] = minimumValue;
    }
    set maximumValue(maximumValue) {
        this[json_schema_keys_1.JsonSchemaKeys.MAXIMUM_KEY] = maximumValue;
    }
    constructor(field) {
        super(field);
        if (field.minimumValue != null) {
            this.minimumValue = field.minimumValue;
        }
        if (field.maximumValue != null) {
            this.maximumValue = field.maximumValue;
        }
    }
}
exports.DecimalPropertyDescription = DecimalPropertyDescription;
