import { CUSTOMER_ROLE, SUPERADMIN_ROLE } from '@/auth/auth.js'
import BaseObject from './base_object'

const emptyUser = {
  id: null,
  name: '',
  roles: [],
  phone: '',
  email: '',
  archived: false,
  company_id: null,
  created: null,
  organization_id: null,
  default_business_unit_id: null,
  scope_business_unit_id: null,
  scopes: [],
  additional_attributes: {},
}

export default class User extends BaseObject {
  roles: string[]
  email: string
  phone: string
  companyId: number | null
  isDisabled: boolean
  created: string | null
  organizationId: string | null
  defaultBusinessUnitId: string | null
  scopeBusinessUnitId: string | null
  scopes: any[]
  additionalAttributes: any

  constructor(json: any = {}) {
    json = { ...emptyUser, ...json }
    super(json)

    this.roles = json.roles
    this.email = json.email
    this.phone = json.phone
    this.companyId = json.company_id
    this.isDisabled = json.archived
    this.created = json.created
    this.organizationId = json.organization_id
    this.defaultBusinessUnitId = json.default_business_unit_id
    this.scopeBusinessUnitId = json.scope_business_unit_id
    this.scopes = json.scopes
    this.additionalAttributes = json.additional_attributes ?? {}
  }

  get displayedRole() {
    let displayRole = ''
    this.roles.forEach((role) => {
      if (role === SUPERADMIN_ROLE) role = 'Admin'
      if (role === CUSTOMER_ROLE) role = 'Requester'
      displayRole = displayRole.concat(role, '/')
    })
    return displayRole.slice(0, -1)
  }
}
