var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.FormLayout,{attrs:{"has-changes":_setup.hasChanges,"is-loading":_setup.isCreatingNewLocation ? false : _setup.isLoading,"is-saving":_setup.isSaving,"title":_setup.title,"label":_setup.archivedChip},on:{"on-save":_setup.submit,"on-dismiss":_setup.dismiss}},[_c(_setup.VsForm,{key:_vm.$route.params.id,ref:"form"},[(_setup.isErrorSaving)?_c(_setup.VsAlert,{attrs:{"type":"error"}},[_vm._v(_vm._s(_setup.errorMessage))]):_vm._e(),_c(_setup.VsTextInput,{attrs:{"value":_setup.formData.location,"disabled":_setup.item ? !_setup.item.canEdit : false,"label":"Name","required":""},on:{"input":_setup.handleUpdateLocationName}}),_c(_setup.LocationAdditionalInfo,{attrs:{"location":{
        ..._setup.formData,
        canEdit: _setup.item ? _setup.item.canEdit : true,
      }},on:{"updated":_setup.handleLocationAdditionalInfoUpdate}}),_c(_setup.VsTextInput,{attrs:{"value":_setup.formData.description,"disabled":_setup.item ? !_setup.item.canEdit : false,"label":"Description","multiline":""},on:{"input":_setup.handleUpdateLocationDescription}}),(_setup.showParentLocationField)?_c(_setup.NewLocationPicker,{attrs:{"disabled":_setup.item ? !_setup.item.canEdit : false,"autofocus":_setup.formData.parentId === null,"value":_setup.formData.parentId ?? undefined,"exclude-location-ids":_setup.isCreatingNewLocation ? [] : [parseInt(_setup.props.id.toString(), 10)],"label":"Parent location"},on:{"input":_setup.onParentChange}}):_c('vs-button',{attrs:{"disabled":_setup.item ? !_setup.item.canEdit : false,"label":"Add parent location","small":"","type":"secondary"},on:{"click":function($event){_setup.parentFlag = true}}}),(_setup.schema && _setup.schema.properties)?_c(_setup.VsWrapper,[_c(_setup.VsHeading,[_vm._v("Details")]),_c('schema-field',{attrs:{"disabled":_setup.item ? !_setup.item.canEdit : false,"error-schema":_setup.errorSchema,"form-data":_setup.formData ? _setup.formData.customData : {},"form-resolver":_setup.contextualizedFormResolver,"params":{ headingType: 'heading' },"root-schema":_setup.schema,"schema":_setup.schema,"ui-schema":_setup.uiSchema},on:{"change":_setup.handleLocationSchemaFieldsUpdate}})],1):_vm._e(),_c(_setup.LocationCoordinates,{attrs:{"location":{
        ..._setup.formData,
        canEdit: _setup.item ? _setup.item.canEdit : true,
      }},on:{"updated":_setup.handleLocationLatLongUpdate}}),(_setup.isHubUser)?_c(_setup.LocationHubAccess,{class:{
        'focus-hubs-selector': _vm.focusHubsSelector,
      },attrs:{"access-grant-required":true,"location-id":_setup.isCreatingNewLocation ? null : parseInt(_setup.props.id.toString(), 10),"parent-location-id":_setup.formData.parentId,"access-business-unit-ids":_setup.formData.accessBusinessUnitIds},on:{"updated":_setup.handleLocationHubAccessUpdate}}):_vm._e(),_c(_setup.LocationAccessAndOwnership,{attrs:{"location":{
        ..._setup.formData,
        canEdit: _setup.item ? _setup.item.canEdit : true,
      }},on:{"updated":_setup.handleLocationAdditionalInfoUpdate}}),(_setup.isCreatingNewLocation)?_c(_setup.LocationInventoryEdit,{attrs:{"inventories":_setup.inventories},on:{"add-inventory":_setup.addInventory,"update-inventory":function($event){return _setup.handleUpdateInventory($event)},"remove-inventory":function($event){return _setup.removeInventory($event)}}}):_vm._e(),(!_setup.isCreatingNewLocation)?_c(_setup.TwoStageButton,{attrs:{"alternate":_setup.formData.isArchived ?? false,"is-loading":_setup.isArchiving || _setup.isUnarchiving,"is-disabled":_setup.isParentLocation,"alternate-button-text":"Unarchive","main-button-text":"Archive","disabled-tooltip-text":"Parent locations cannot be archived"},on:{"click":_setup.handleArchiveLocation}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }