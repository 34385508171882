import { render, staticRenderFns } from "./ResourceJobSection.vue?vue&type=template&id=5b8d3e32&scoped=true&"
import script from "./ResourceJobSection.vue?vue&type=script&setup=true&lang=ts&"
export * from "./ResourceJobSection.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./ResourceJobSection.vue?vue&type=style&index=0&id=5b8d3e32&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b8d3e32",
  null
  
)

export default component.exports