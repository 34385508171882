
import { computed, defineComponent, PropType, watchEffect } from 'vue'
import {
  IntegrationPoolExecutionPlanAxiosError,
  PoolExecutionPlanMetadata,
  PoolExecutionPlanQuery,
  Stage,
  usePoolExecutionPlanQuery,
} from '@/api/pools'
import moment from 'moment'
import PoolStages from '@/components/tickets/PoolStages.vue'
import { AxiosError } from 'axios'

export default defineComponent({
  name: 'AdHocPool',

  components: {
    PoolStages,
  },

  props: {
    hubId: {
      type: String,
      required: true,
    },

    resourceTypeId: {
      type: Number,
      required: true,
    },

    definitionId: {
      type: String,
      default: null,
    },

    itineraries: {
      type: Array as PropType<
        {
          job_id: string | undefined
          startTime: moment.Moment
          endTime: moment.Moment
          extensionData: Record<string, unknown>
          stops: {
            locationId: number | null
            order: number
            coordinates: { latitude: number; longitude: number } | null
            items: {
              inventoryTypeId: number
              inventorySubTypeId: number
              quantity: number
            }[]
          }[]
        }[]
      >,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const query = computed<PoolExecutionPlanQuery>(() => ({
      hub_id: props.hubId,
      resource_type_id: props.resourceTypeId,
      definition_id: props.definitionId,
      itineraries: props.itineraries.map((r) => {
        return {
          job_id: r.job_id,
          start_time: r.startTime.toISOString(),
          end_time: r.endTime.toISOString(),
          extension_data: r.extensionData,
          stops: r.stops.map((s) => {
            return {
              location_id: s.locationId,
              order: s.order,
              items: s.items.map((i) => {
                return {
                  inventory_type_id: i.inventoryTypeId,
                  inventory_sub_type_id: i.inventorySubTypeId,
                  quantity: i.quantity,
                }
              }),
            }
          }),
        }
      }),
    }))

    const { data, error, isLoading } = usePoolExecutionPlanQuery(query)

    const errorMessage = computed<string | null>(() => {
      return (
        (error?.value as AxiosError<IntegrationPoolExecutionPlanAxiosError>)
          ?.response?.data?.detail ?? null
      )
    })

    watchEffect(() => {
      const emitValue = errorMessage.value ? null : data.value
      emit('input', emitValue)
      if (error.value && !errorMessage.value) {
        throw error
      }
    })

    const stages = computed<Stage[] | null>(() => {
      if (errorMessage.value) {
        return null
      }
      return (data.value?.stages.map((s) => ({
        status: 'Suggested',
        stage_number: s.stage_number,
        due_by: null,
        availability: s.recipients.map((r) => ({
          ...r,
          status: 'Pending',
        })),
      })) ?? []) as Stage[]
    })

    const metadata = computed<PoolExecutionPlanMetadata | null>(() => {
      return data.value?.metadata ?? null
    })

    return {
      stages,
      metadata,
      errorMessage,
      isLoading,
    }
  },
})
