"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntegerPropertyDescription = void 0;
const json_schema_keys_1 = require("../../json_schema_keys");
const field_property_description_1 = require("./field_property_description");
class IntegerPropertyDescription extends field_property_description_1.DefaultFieldPropertyDescription {
    set minimumValue(minimumValue) {
        this[json_schema_keys_1.JsonSchemaKeys.MINIMUM_KEY] = Math.trunc(minimumValue);
    }
    set maximumValue(maximumValue) {
        this[json_schema_keys_1.JsonSchemaKeys.MAXIMUM_KEY] = Math.trunc(maximumValue);
    }
    constructor(field) {
        super(field);
        if (field.minimumValue != null) {
            this.minimumValue = field.minimumValue;
        }
        if (field.maximumValue != null) {
            this.maximumValue = field.maximumValue;
        }
    }
}
exports.IntegerPropertyDescription = IntegerPropertyDescription;
