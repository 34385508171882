<template>
  <div>
    <vs-button
      type="tertiary"
      small
      icon="mdi-paperclip"
      label="Photo / File"
      data-test="add-inventory"
      @click="$refs.input.click()"
    />
    <input
      ref="input"
      data-test="file-input"
      type="file"
      title=" "
      :accept="fileExtensionRestrictions()"
      multiple
      class="input-file"
      @change="onFilesSelected"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import VsButton from '@/components/vision/VsButton.vue'
import {
  AcceptedFileExtensions,
  AcceptedFileTypes,
  maxFileSize,
} from '@/utils/constants'
import { getContentTypeForFileExtension } from '@/utils/file-extension-helper'
import JobAttachment from '@/models/job_attachment'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'

export default {
  name: 'AttachmentPicker',
  components: {
    VsButton,
  },
  props: {
    jobUuid: {
      type: String,
      default: null,
    },
    jobIsNew: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      addAttachment: 'jobs/addAttachment',
    }),
    async onFilesSelected(e) {
      try {
        Object.values(e.target.files).forEach((file) => {
          if (file.type === '') {
            const type = getContentTypeForFileExtension(
              file.name.split('.').pop()
            )
            file = new File([file], file.name, { type })
          }

          if (this.isFileValid(file)) {
            const newAttachment = new JobAttachment(
              {
                id: uuidv4(),
                source: null,
                filename: file.name,
                created_by: this.$auth.user.sub,
                created: moment(Date.now()).utc(),
              },
              this.jobIsNew ? file : null
            )

            this.$emit('attachment-added', newAttachment)

            // Do not upload the file if the job is new and has not yet been synced with the server
            if (this.jobIsNew) {
              return
            }

            this.addAttachment({
              jobId: this.jobUuid,
              attachmentId: newAttachment.id,
              file,
            }).then((attachment) => {
              if (attachment) {
                this.$emit('attachment-updated', attachment)
              }
            })
          }
        })

        e.target.value = null
      } catch (e) {
        this.errorUploadingAttachment()
      }
    },
    isFileValid(file) {
      if (!AcceptedFileTypes.includes(file.type)) {
        this.unsupportedFileTypeSelected()
        return false
      } else if (this.convertBinaryBytesToMegaBytes(file.size) > maxFileSize) {
        this.maximumFileSizeExceeded()
        return false
      } else {
        return true
      }
    },
    convertBinaryBytesToMegaBytes(bytes) {
      return bytes / (1024 * 1024)
    },
    unsupportedFileTypeSelected() {
      this.$emit('unsupported-file-selected', 'unsupportedAttachmentType')
    },
    maximumFileSizeExceeded() {
      this.$emit('unsupported-file-selected', 'unsupportedAttachmentSize')
    },
    errorUploadingAttachment() {
      this.$emit('error-uploading-attachment', 'errorUploadingAttachment')
    },
    fileExtensionRestrictions() {
      return AcceptedFileExtensions.map((ext) => '.' + ext).join(', ')
    },
  },
}
</script>

<style scoped>
.input-file {
  display: none;
}
</style>
