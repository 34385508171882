import { get, post } from './base'
import { useQuery } from '@tanstack/vue-query'
import moment from 'moment/moment'
import { computed, Ref, unref } from 'vue'
import axios, { AxiosError } from 'axios'
import { convertToCamelCase } from '@/models/utils/casing'
import qs from 'qs'

export interface PoolExecutionPlanQuery {
  hub_id: string
  resource_type_id: number
  definition_id?: string | null
  itineraries: {
    start_time: string
    end_time: string
    stops: {
      location_id: number | null
      items: {
        inventory_type_id: number
        inventory_sub_type_id: number
        quantity: number
      }[]
    }[]
  }[]
}

export type PoolExecutionPlanMetadata = {
  distance_miles: number
}

export type PoolExecutionPlan = {
  id?: string | undefined
  stages: {
    stage_number: number
    delay: string | null
    recipients: { recipient_id: string; recipient_name?: string }[]
  }[]
  metadata?: PoolExecutionPlanMetadata
}

export function getPoolExecutionPlan(
  query: PoolExecutionPlanQuery
): Promise<PoolExecutionPlan> {
  return post(`/api/v2/pools/plan?hubId=${query.hub_id}`, {
    data: query,
  }).then((r) => r.data)
}

export interface IntegrationPoolExecutionPlanAxiosError extends AxiosError {
  detail?: string
}

export function usePoolExecutionPlanQuery(query: Ref<PoolExecutionPlanQuery>) {
  const { data, error, isLoading } = useQuery({
    queryKey: ['pools', query],
    queryFn: async () => {
      return await getPoolExecutionPlan(query.value)
    },
    cacheTime: moment.duration(5, 'minutes').asMilliseconds(),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 1,
  })

  return { data, error, isLoading }
}

export interface Availability {
  recipient_id: string
  recipient_name: string
  status: string
}

export interface Stage {
  stage_number: number
  status: 'Queued' | 'AvailabilityRequested' | 'UpNext' | 'Suggested'
  due_by: string | null
  availability: Availability[]
  recipients: { recipient_id: string; recipient_name: string }[]
}

interface PoolStatus {
  pool_id: string
  stages: Stage[]
}

export function getPoolStatus(poolId: string): Promise<PoolStatus> {
  return get(`/api/v2/pools/${poolId}`).then((r) => r.data)
}

export function usePoolStatusQuery(poolId: string) {
  return useQuery({
    queryKey: ['pools', poolId],
    queryFn: () => getPoolStatus(poolId),
    refetchInterval: moment.duration(5, 'seconds').asMilliseconds(),
  })
}

interface PoolExclusivityStatus {
  hasExclusivity: boolean
  exclusiveUntil: Date | null
}

export function getPoolExclusivityStatus(poolId: string, signal?: AbortSignal) {
  const poolEndpointSource = axios.CancelToken.source()

  const exclusivityStatus = get<PoolExclusivityStatus>(
    `/api/v2/pools/${poolId}/exclusivity-status`,
    {
      cancelToken: poolEndpointSource.token,
    }
  ).then((r) => convertToCamelCase(r.data))

  signal?.addEventListener('abort', () => {
    poolEndpointSource.cancel('Query was cancelled by TanStack Query')
  })

  return exclusivityStatus
}

export function usePoolExclusivityStatusQuery(poolId: Ref<string>) {
  const computedId = computed(() => unref(poolId))
  const queryKey = computed(() => [
    'pools',
    computedId.value,
    'exclusivity-status',
  ])

  return useQuery({
    queryKey,
    queryFn: ({ signal }) => getPoolExclusivityStatus(computedId.value, signal),
    refetchInterval: moment.duration(30, 'seconds').asMilliseconds(),
  })
}

interface PoolDefinitionOption {
  id: string
  name: string
  type: 'Standard' | 'Broadcast' | 'Integration'
}

export function getAssignablePoolDefinitions(
  hubId: string
): Promise<PoolDefinitionOption[]> {
  return get(`/api/v2/pools?hubId=${hubId}`).then((r) => r.data)
}

interface PoolDefinition {
  id: string
  name: string
  parentId: string
}

export function getPoolDefinition(poolId?: string, signal?: AbortSignal) {
  const poolEndpointSource = axios.CancelToken.source()

  const poolDefinition = get<PoolDefinition>(
    `/api/v2/pools/${poolId}/definition`,
    {
      cancelToken: poolEndpointSource.token,
    }
  ).then((r) => convertToCamelCase(r.data))

  signal?.addEventListener('abort', () => {
    poolEndpointSource.cancel('Query was cancelled by TanStack Query')
  })

  return poolDefinition
}

type MaybeRef<T> = Ref<T> | T

export function usePoolDefinitionQuery(
  poolId?: MaybeRef<string | null | undefined>
) {
  const computedId = computed(() => unref(poolId))
  const queryKey = computed(() => ['pools', computedId.value, 'definition'])
  const enabled = computed(() => {
    return computedId.value !== null && computedId.value !== undefined
  })

  return useQuery({
    queryKey,
    queryFn: ({ signal }) => getPoolDefinition(computedId.value!, signal),
    enabled,
  })
}

export interface EligibleServiceProvider {
  id: string
  name: string
  shortCode: string
}

export function getEligibleServiceProviders(
  hubId: string,
  resourceTypeId: number,
  signal?: AbortSignal
) {
  const poolEndpointSource = axios.CancelToken.source()

  const eligibleServiceProviders = get<EligibleServiceProvider[]>(
    `/api/v2/pools/eligible-service-providers`,
    {
      cancelToken: poolEndpointSource.token,
      params: {
        hubId,
        resourceTypeId,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      },
    }
  ).then((r) => convertToCamelCase(r.data))

  signal?.addEventListener('abort', () => {
    poolEndpointSource.cancel('Query was cancelled by TanStack Query')
  })

  return eligibleServiceProviders.then((providers) => {
    return providers.sort((a, b) =>
      a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
    )
  })
}

export function useEligibleServiceProvidersQuery(
  hubId: Ref<string>,
  resourceTypeId: Ref<number>
) {
  const queryKey = computed(() => [
    'pools',
    hubId.value,
    resourceTypeId.value,
    'service-providers',
  ])

  return useQuery({
    queryKey,
    queryFn: ({ signal }) =>
      getEligibleServiceProviders(hubId.value, resourceTypeId.value, signal),
  })
}
