
import { computed, defineComponent, PropType } from 'vue'
import { PoolExecutionPlanMetadata, Stage } from '@/api/pools'
import PoolStage from '@/components/tickets/PoolStage.vue'

export default defineComponent({
  name: 'PoolStages',

  components: { PoolStage },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    stages: {
      type: Array as PropType<Stage[] | null>,
      default: () => [],
    },
    errorMessage: {
      type: String as PropType<string | null>,
      default: null,
    },
    metadata: {
      type: Object as PropType<PoolExecutionPlanMetadata | null>,
      default: null,
    },
  },

  setup(props, { emit }) {
    function onStageExpired(stageId) {
      emit('stage:expired', stageId)
    }

    const metadataLabel = computed(() => {
      if (props.metadata?.distance_miles) {
        return `~${props.metadata.distance_miles} miles`
      }
      return null
    })

    return {
      onStageExpired,
      metadataLabel,
    }
  },
})
