import * as nearley from 'nearley'
import * as grammarV1 from '@/utils/forms/domain-specific-language/grammar/grammar.js'
import { isObject } from 'lodash'

export function resolveFormDSL(input, version, formContext) {
  switch (version) {
    case 1: {
      const parser = new nearley.Parser(nearley.Grammar.fromCompiled(grammarV1))
      parser.feed(input)
      return processParsedDSLV1(parser.results[0], formContext)
    }
    default:
      throw new Error(
        `Dynamic statement grammar version ${version} is not supported.`
      )
  }
}

function processParsedDSLV1(parsedInput, formContext) {
  if (!isObject(parsedInput)) {
    return parsedInput
  }
  const { name, ...parsedProperties } = parsedInput

  switch (name) {
    // Comparison expressions
    // Returns schema: {} and data: {}
    case '=': {
      const resolvedInput = { schema: {}, data: {} }
      resolvedInput.schema = {
        properties: {
          dynamicComparator: {
            const: processParsedDSLV1(parsedProperties.left, formContext),
          },
        },
      }
      resolvedInput.data = {
        dynamicComparator: parsedProperties.right,
      }
      return resolvedInput
    }
    // Data lookups
    // Returns data as is
    case 'contextLookup': {
      const { path } = parsedProperties
      return formContext.resolveProperty(path, {})
    }
    case 'storeLookup': {
      const { path, ...args } = parsedProperties
      return formContext.resolveProperty(path, args)
    }
    default:
      throw new Error(`Dynamic statement command ${name} is not supported.`)
  }
}
