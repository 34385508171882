/**
 * Takes flattened form data and splits it into appropriate pages and flows
 * @param {Object} rootSchema - Root schema of the entire form
 * @param {Object} formData - Form data for the entire form
 * @returns {{pages: [], flows: {}}}
 */
export function resolveSubmissionData(rootSchema, formData) {
  let pages = []
  const flows = {}

  if (rootSchema.pages) {
    pages = resolvePages(rootSchema.pages, formData)
  }

  if (rootSchema.flows) {
    Object.keys(rootSchema.flows).forEach((flowKey) => {
      let flowPages = []
      if (rootSchema.flows[flowKey].pages) {
        flowPages = resolvePages(rootSchema.flows[flowKey].pages, formData)
      }
      flows[flowKey] = flowPages
    })
  }

  return { pages, flows }
}

function resolvePages(schemaPages, formData) {
  const pages = []

  schemaPages.forEach((page, pageIndex) => {
    let pageObject = {}
    pageObject = resolveProperties(pageObject, page.schema.properties, formData)
    pageObject = resolveDependencies(
      pageObject,
      page.schema.dependencies,
      formData
    )
    pages[pageIndex] = pageObject
  })

  return pages
}

function resolveProperties(pageObject, properties, formData) {
  Object.keys(properties).forEach((propertyKey) => {
    pageObject = resolveProperty(pageObject, propertyKey, formData)
  })

  return pageObject
}

function resolveDependencies(pageObject, dependencies, formData) {
  if (dependencies) {
    for (const [dependencyKey, dependency] of Object.entries(dependencies)) {
      for (const oneOf of dependency.oneOf) {
        for (const propertyKey in oneOf.properties) {
          if (propertyKey !== dependencyKey) {
            pageObject = resolveProperty(pageObject, propertyKey, formData)
          }
          pageObject = resolveDependencies(
            pageObject,
            oneOf.dependencies,
            formData
          )
        }
      }
    }
  }

  return pageObject
}

function resolveProperty(pageObject, propertyKey, formData) {
  if (propertyKey in formData) {
    pageObject[propertyKey] = formData[propertyKey]
  }

  return pageObject
}
