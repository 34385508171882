import moment from 'moment'

export const SET_WEEK = 'SET_WEEK'
export const SET_SELECTED_RESOURCE_TYPE_ID = 'SET_SELECTED_RESOURCE_TYPE_ID'

export default {
  namespaced: true,

  state: () => ({
    week: [moment().startOf('week'), moment().endOf('week')],
    selectedResourceTypeId: null,
  }),

  actions: {
    fetchJobsForWeek({ state, dispatch }) {
      const resourceTypeFilter = {
        resourceTypes: state.selectedResourceTypeId
          ? [state.selectedResourceTypeId]
          : [],
      }
      const [weekStart, weekEnd] = state.week

      dispatch(
        'jobs/refreshJobs',
        {
          start: moment(weekStart),
          end: moment(weekEnd),
          ...resourceTypeFilter,
        },
        { root: true }
      )
    },
  },

  mutations: {
    [SET_WEEK](state, week) {
      state.week = week
    },
    [SET_SELECTED_RESOURCE_TYPE_ID](state, id) {
      state.selectedResourceTypeId = id
    },
  },
}
