import { getInstance } from '@/auth'

const ID_DELIMETER = '|'

export function createGroupId(environment, type, id) {
  return [environment, type, id].join(ID_DELIMETER)
}

export function parseGroupId(groupId) {
  if (!groupId) {
    return null
  }

  const [env, type, id] = groupId.split(ID_DELIMETER)

  return {
    env,
    type,
    id,
  }
}

export function getHostName() {
  const hostname = location.hostname
  const authService = getInstance()

  if (hostname === 'localhost') {
    return 'localhost'
  } else {
    return authService.tenant
  }
}

export function getCurrentUserSid() {
  const authService = getInstance()
  const currentUserSid = authService.user.sub
  return currentUserSid
}
