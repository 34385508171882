<template>
  <vs-wrapper>
    <vs-alert v-if="errorMessage" type="error">
      {{ errorMessage }}
    </vs-alert>
  </vs-wrapper>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { CLEAR_TICKETS_ERROR } from '@/store/modules/tickets'

export default {
  name: 'JobErrorBanner',

  computed: {
    ...mapGetters('tickets', {
      ticketsError: 'getError',
    }),

    errorMessage: {
      get() {
        if (
          this.ticketsError &&
          this.ticketsError.response &&
          this.ticketsError.response.data
        ) {
          return this.ticketsError.response.data.detail || 'Error saving Job'
        }

        return false
      },
      set(newValue) {
        if (newValue === false) {
          this.clearTicketsError()
        }
      },
    },
  },
  methods: {
    ...mapMutations('tickets', {
      clearTicketsError: CLEAR_TICKETS_ERROR,
    }),
  },
}
</script>
