<template>
  <vs-wrapper>
    <vs-heading>
      <v-alert
        v-show="!activeFieldDefinition"
        border="left"
        color="orange"
        type="info"
        text
      >
        No field selected.<br />
        Select a field to edit it here.
      </v-alert>
    </vs-heading>

    <core-field-settings
      v-if="activeFieldDefinition"
      class="mb-3"
      :field="activeFieldDefinition"
      :is-required-toggle-visible="false"
      @modify-field="modifyField"
      @remove-field="removeField"
    ></core-field-settings>

    <specific-field-settings
      v-if="activeFieldDefinition"
      :field="activeFieldDefinition"
      :field-type-name="fieldTypeName"
      @modify-field="modifyField"
      @remove-field="removeField"
    />
  </vs-wrapper>
</template>

<script>
import RelationshipsEditor from '@/components/form-builder/RelationshipsEditor'
import CoreFieldSettings from '@/components/form-builder/field-settings/CoreFieldSettings'
import SpecificFieldSettings from '@/components/form-builder/field-settings/SpecificFieldSettings'
import VsWrapper from '@/components/vision/VsWrapper'
import VsHeading from '@/components/vision/VsHeading'
export default {
  name: 'FieldTemplatesEditor',
  components: {
    VsHeading,
    VsWrapper,
    SpecificFieldSettings,
    CoreFieldSettings,
    RelationshipsEditor,
  },

  props: {
    fieldTypeName: {
      type: String,
      default: '',
    },
    activeFieldDefinition: {
      type: Object,
      default: null,
    },
    activeFlow: {
      type: String,
      default: '#root_schema',
    },
    referenceFields: {
      type: Array,
      default: () => {},
    },
  },

  methods: {
    modifyField(data) {
      this.$emit('modify-template', data)
    },
    removeField(field) {
      this.$emit('remove-template', field)
    },
  },
}
</script>

<style scoped></style>
