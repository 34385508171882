<template>
  <vs-wrapper>
    <VsAutocomplete
      data-test="businessunit-selector-field"
      :items="getBusinessUnits"
      :value="transformedFormData"
      :label="title"
      :error-messages="errorMessages"
      :hint="description"
      :disabled="disabled"
      @input="handleInput"
    >
      <template #default="menuProps">
        <business-unit-menu
          data-test="businessunit-selector-field-menu"
          :items="menuProps.localItems"
          :label="title"
          :filter-value="menuProps.localSearchValue"
          :disabled="disabled"
          @click="menuProps.handleSelectedItem"
        />
      </template>
    </VsAutocomplete>
  </vs-wrapper>
</template>

<script>
import VsAutocomplete from '@/components/vision/Inputs/VsAutocomplete'
import BusinessUnitMenu from '@/components/forms/BusinessUnitMenu'
import BusinessUnitMenuItemsMixin from '@/components/forms/business-unit-menu-items-mixin'

export default {
  name: 'BusinessUnitSelectorField',
  components: {
    VsAutocomplete,
    BusinessUnitMenu,
  },
  mixins: [BusinessUnitMenuItemsMixin],
  props: {
    errorSchema: {
      type: Object,
      default: () => {},
    },
    description: { type: String, default: null },
    businessUnitId: { type: String, default: null },
    params: {
      type: Object,
      default: () => {},
    },
    formData: { type: String, default: null },
    rootSchema: {
      type: Object,
      default: () => {},
    },
    schema: {
      type: Object,
      default: () => {},
    },
    title: { type: String, default: null },
    uiSchema: {
      type: Object,
      default: () => {},
    },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    errorMessages() {
      return this.errorSchema?.errors ?? []
    },
    transformedFormData() {
      if (this.formData) {
        return { userId: null, businessUnitId: this.formData }
      }
      return null
    },
  },
  methods: {
    handleInput(event) {
      let value = event
      if (value && value.businessUnitId) {
        value = value.businessUnitId
      }
      this.$emit('change', value)
    },
  },
}
</script>
<style scoped></style>
