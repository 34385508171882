import fields from '@/components/forms/fields'
import { isValid } from '@/utils/forms/form-validate'
import { isEmpty, isEqual, transform, union } from 'lodash'
import {
  JSON_SCHEMA_CONDITIONALS,
  JSON_SCHEMA_ON_CHANGE,
  JSON_SCHEMA_ON_SUBMIT,
} from '@/utils/forms/form-constants'
import { ConditionalDependencySchema } from '@/utils/forms/models/conditional-dependency-schema'

export function getDefaultRegistry() {
  return fields
}

/**
 * Checks the schema and attempts to return a valid type
 * @param schema - The schema to check
 * @returns {string|*} - The string representation of the schema. Undefined if there is no valid schema
 */
export function getSchemaType(schema) {
  const { type } = schema

  if (!type && (schema.enum || schema.const)) {
    return 'string'
  }
  if (!type && schema.properties) {
    return 'object'
  }

  return type
}

/**
 * Checks the schema and attempts to return a valid widget type
 * @param schema
 * @returns {string|null} - The string representation of the generic widget type. Null if there is no default widget
 *   type
 */
export function getDefaultWidgetType(schema) {
  if (schema.enum || schema.const || schema.oneOf) {
    return 'genericSelector'
  }

  return null
}

/**
 * Checks if the parameter is of type object and not null or an array, as both are 'object' in javascript
 * @param maybeObject - The variable to check
 * @returns {boolean} - true if an object that is not null or an array. false otherwise
 */
export function isObject(maybeObject) {
  return (
    typeof maybeObject === 'object' &&
    maybeObject !== null &&
    !Array.isArray(maybeObject)
  )
}

/**
 * Merge form data with the default data. This will not override anything set in formData and only add defaults if
 * they are missing/have no value in the form data. Arrays are only deeply merged, if form data has array elements
 * present default elements are ignored.
 *
 * @param {Object} formData Current form data
 * @param {Object} defaultData Defaults for the current form
 * @returns {Object} Merged form data
 */
export function mergeFormDataWithDefault(formData, defaultData) {
  if (Array.isArray(formData)) {
    if (!Array.isArray(defaultData)) {
      defaultData = []
    }
    return formData.map((value, index) => {
      if (defaultData[index]) {
        return mergeFormDataWithDefault(formData[index], defaultData[index])
      }
      return value
    })
  } else if (isObject(formData)) {
    const mergedData = Object.assign({}, defaultData)
    return Object.keys(formData).reduce((mergedData, key) => {
      mergedData[key] = mergeFormDataWithDefault(
        formData[key],
        defaultData ? defaultData[key] : {}
      )
      return mergedData
    }, mergedData)
  } else {
    return formData
  }
}

/**
 * Orders the properties by the order defined in order. Any properties not defined in order are appended to the end.
 * @param {Array} properties - property keys to order
 * @param {Array} order - Defined order
 * @returns {Array} - Ordered properties
 */
export function orderProperties(properties, order) {
  if (!Array.isArray(order)) {
    return properties
  }

  return properties.sort((a, b) => {
    if (order.includes(a) && order.includes(b)) {
      return order.indexOf(a) - order.indexOf(b)
    } else if (order.includes(a)) {
      return -1
    } else if (order.includes(b)) {
      return 1
    }
    return 0
  })
}

/**
 * Remove any form data that is from a dependency that is no longer valid
 * @param {Object} schema - Schema for form page
 * @param {Object} formData - Form data object
 * @returns {Object} - Form data with invalid dependencies removed
 */
export function resolveDependentFormDataForSubmission(schema, formData) {
  const validFormData = {}
  const dependencies = schema.dependencies || {}
  const properties = schema.properties

  for (const [propertyKey, value] of Object.entries(formData)) {
    let dependentProperty = null
    const dependenciesToCheck = findDependenciesForProperty(
      dependencies,
      propertyKey
    )
    if (!isEmpty(dependenciesToCheck)) {
      dependentProperty = getValidDependencyForProperty(
        dependenciesToCheck,
        formData
      )
      if (dependentProperty === null) continue
    }

    if (isObject(value)) {
      // Check inside object for dependencies
      validFormData[propertyKey] = resolveDependentFormDataForSubmission(
        dependentProperty === null
          ? properties[propertyKey]
          : dependentProperty.propertySchema,
        value
      )
    } else if (Array.isArray(value) && value.length > 0 && isObject(value[0])) {
      // Check inside object array for dependencies
      validFormData[propertyKey] = []
      for (const objectArrayValue of value) {
        validFormData[propertyKey].push(
          resolveDependentFormDataForSubmission(
            dependentProperty === null
              ? properties[propertyKey].items
              : dependentProperty.propertySchema.items,
            objectArrayValue
          )
        )
      }
    } else {
      validFormData[propertyKey] = value
    }
  }

  if (!isEqual(validFormData, formData)) {
    return resolveDependentFormDataForSubmission(schema, validFormData)
  }
  return validFormData
}

/**
 * Returns the resolved form data object which removes and replaces some fields
 * to better prepare it for AJV validation.
 * Removes any 'empty' data from object and primitive fields for validation.
 * Replaces any empty data within primitive arrays with null for validation.
 * @param {Object} formData - Form data object
 * @returns {Object} - Resolved form data object
 */
export function resolveFormDataForValidation(formData) {
  return resolveNullOrEmptyFormData(formData, true)
}

/**
 * Returns the resolved form data object which removes all empty properties
 * @param {Object} formData - Form data object
 * @returns {Object} - Resolved form data object
 */
export function resolveEmptyFormDataForSubmission(formData) {
  return resolveNullOrEmptyFormData(formData, false)
}

/**
 * Returns a list of actions to take for onSubmit conditionals
 * @param {Object} schema - Page schema
 * @param {Object} formData - Page form data
 * @returns {*[]} - List of actions to take, empty if no actions to take
 */
export function testOnSubmitConditionals(schema, formData) {
  const actions = []

  if (
    JSON_SCHEMA_CONDITIONALS in schema &&
    JSON_SCHEMA_ON_SUBMIT in schema.conditionals
  ) {
    const conditionals = schema.conditionals.onSubmit
    resolveConditionals(conditionals, formData, actions)
  }

  return actions
}

/**
 * Returns a list of actions to take for onChange conditionals
 * @param {Object} schema - Page schema
 * @param {Object} newFormData - Page form data
 * @param {Object} baseFormData - Page form data before onChange
 * @returns {*[]} - List of actions to take, empty if no actions to take
 */
export function testOnChangeConditionals(schema, newFormData, baseFormData) {
  const actions = []
  const changedValues = formDataDifference(newFormData, baseFormData)

  if (
    JSON_SCHEMA_CONDITIONALS in schema &&
    JSON_SCHEMA_ON_CHANGE in schema.conditionals
  ) {
    const conditionals = Object.values(schema.conditionals.onChange).reduce(
      (prev, curr) => prev.concat(curr),
      []
    )
    resolveConditionals(conditionals, changedValues, actions)
  }

  return actions
}

/**
 * Gives the deep difference between two objects
 * @param {Object} newFormData Object to be compared
 * @param {Object} baseFormData Object to be compared with
 * @returns {Object} Return a new object with the difference
 */
function formDataDifference(newFormData, baseFormData) {
  return transform(newFormData, function (result, value, key) {
    if (!isEqual(value, baseFormData[key])) {
      result[key] =
        isObject(value) && isObject(baseFormData[key])
          ? formDataDifference(baseFormData[key], value)
          : value
    }
  })
}

/**
 * Returns a list of actions to take for a type of form conditionals
 * @param {Object} conditionals - Conditionals to check
 * @param {Object} formData - Page form data
 * @param {*[]} actions - List of actions
 * @returns {*[]} - List of actions to take, empty if no actions to take
 */
function resolveConditionals(conditionals, formData, actions) {
  for (const condition of conditionals) {
    if (condition.if.every((ifSchema) => isValid(ifSchema, formData))) {
      for (const action of condition.then) {
        actions.push(action)
      }
    }
  }

  return actions
}

/**
 * Recursively removes and/or replaces empty data within the formData object
 * @param {Object} formData - Current page form data
 * @param {boolean} forValidation - Flag if this is just for a validation pass
 * @returns {Object} - Resolved form data object
 */
function resolveNullOrEmptyFormData(formData, forValidation) {
  const clone = Object.assign({}, formData)
  for (const [propertyKey, property] of Object.entries(clone)) {
    if (Array.isArray(property)) {
      clone[propertyKey] = resolveNullOrEmptyDataForArrayProperty(
        property,
        forValidation
      )
      if (clone[propertyKey].length === 0) {
        delete clone[propertyKey]
      }
    } else if (isObject(property)) {
      clone[propertyKey] = resolveNullOrEmptyFormData(property, forValidation)
      if (isEmpty(clone[propertyKey]) && !forValidation) {
        delete clone[propertyKey]
      }
    } else {
      if (shouldResolveNullOrEmptyProperty(property)) {
        delete clone[propertyKey]
      }
    }
  }
  return clone
}

/**
 * Recursively removes and/or replaces empty data within the provided array property
 * @param {Array} property - Array property
 * @param {boolean} forValidation - Flag if this is just for a validation pass
 * @returns {Array} - Resolved array property
 */
function resolveNullOrEmptyDataForArrayProperty(property, forValidation) {
  const array = []

  property.forEach((element) => {
    if (Array.isArray(element)) {
      array.push([
        resolveNullOrEmptyDataForArrayProperty(element, forValidation),
      ])
    } else if (isObject(element)) {
      const resolvedObject = resolveNullOrEmptyFormData(element, forValidation)
      if (!isEmpty(resolvedObject) || forValidation) {
        array.push(resolvedObject)
      }
    } else {
      const shouldRemove = shouldResolveNullOrEmptyProperty(element)
      if (shouldRemove && forValidation) {
        array.push(null)
      } else if (!shouldRemove) {
        array.push(element)
      }
    }
  })

  return array
}

/**
 * Checks if the primitive property type is considered null or empty
 * @param property - primitive value for a property
 * @returns {boolean} - true if the property should be removed or replaced
 */
function shouldResolveNullOrEmptyProperty(property) {
  return (
    property === null ||
    property === undefined ||
    Number.isNaN(property) ||
    property === ''
  )
}

/**
 * Looks through the provided dependencies to check if the given property key relies on another field
 * @param {Object} dependencies - Dependencies object for the current scope of form data
 * @param {String} propertyKey - String key for current property
 * @returns {Object} - Returns an object containing all possible dependencies for the property key. Otherwise
 * returns an empty object if the property is not a dependent field
 */
function findDependenciesForProperty(dependencies, propertyKey) {
  const dependenciesToTest = []

  for (const [dependencyKey, dependency] of Object.entries(dependencies)) {
    // We currently only support oneOf dependencies for IronSight forms
    for (const oneOf of dependency?.oneOf ?? []) {
      // Get conditional schema and extract property
      const conditionalDependencySchema = generateConditionalSchema(
        oneOf,
        dependencyKey,
        propertyKey
      )

      const propertyIsInCurrentLevel =
        propertyKey in oneOf.properties && propertyKey !== dependencyKey
      if (propertyIsInCurrentLevel) {
        dependenciesToTest.push(conditionalDependencySchema)
      }
      // Check if property is in a nested dependency
      if ('dependencies' in oneOf) {
        const nestedDependencies = findDependenciesForProperty(
          oneOf.dependencies,
          propertyKey
        )
        if (isEmpty(nestedDependencies)) continue

        // Merge current level with nested level for nested dependency validation
        for (const nestedDependency of nestedDependencies) {
          nestedDependency.mergeConditionalSchema(conditionalDependencySchema)
        }
        dependenciesToTest.push(...nestedDependencies)
      }
    }
  }

  return dependenciesToTest
}

/**
 * Formats a schema for validation against the form data given a dependency schema and key.
 * Also provides the property for checking against nested objects or object arrays.
 * @param {Object} dependencySchema - Schema for the dependency
 * @param {String} dependencyKey - Key of the dependency
 * @param {String} propertyKey - Key of the property we are looking for
 * @returns {ConditionalDependencySchema}
 */
function generateConditionalSchema(
  dependencySchema,
  dependencyKey,
  propertyKey
) {
  const conditionalSchema = {
    type: 'object',
    required: [dependencyKey],
    properties: {
      [dependencyKey]: dependencySchema.properties[dependencyKey],
    },
  }
  const propertySchema = dependencySchema.properties[propertyKey]
  return new ConditionalDependencySchema(conditionalSchema, propertySchema)
}

/**
 * Checks if the provided dependencies are valid for the given form data
 * @param {ConditionalDependencySchema[]} dependencies - Contains lists of possible dependency values for each key
 * @param {Object} formData - Current set of form data
 * @returns {ConditionalDependencySchema|null} - Returns the valid dependency if one exists. Otherwise null
 */
function getValidDependencyForProperty(dependencies, formData) {
  for (const dependency of dependencies) {
    if (isValid(dependency.conditionalSchema, formData)) return dependency
  }

  return null
}

export function mergeSchemas(obj1, obj2) {
  const clone = Object.assign({}, obj1)
  return Object.keys(obj2).reduce((clone, key) => {
    const obj1Child = obj1 ? obj1[key] : {}
    const obj2Child = obj2[key]
    if (obj1 && key in obj1 && isObject(obj2Child)) {
      clone[key] = mergeSchemas(obj1Child, obj2Child)
    } else if (
      obj1 &&
      obj2 &&
      Array.isArray(obj1Child) &&
      Array.isArray(obj2Child)
    ) {
      // This operation must create a new array or it will mutate the source
      clone[key] = union(obj1Child, obj2Child)
    } else {
      clone[key] = obj2Child
    }
    return clone
  }, clone)
}
