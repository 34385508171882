import { Organization } from '@/models/business-unit'
import { computed } from 'vue'
import { useStore } from 'vuex-composition-helpers'

export const useCurrentOrg = () => {
  const store = useStore()
  const getBusinessUnitById = store.getters['businessUnits/getBusinessUnitById']
  const userOrgId = store.getters['permissions/getOrganizationId']

  const currentOrg = computed<Organization | null>(() => {
    return getBusinessUnitById(userOrgId) || null
  })

  return { currentOrg }
}
