<template>
  <Page :title="pageTitle" :description="pageDescription" width="fill">
    <v-overlay :value="isPreviewVisible" />

    <vs-snackbar
      v-if="rightClickedField"
      v-model="showSnackbar"
      :type="actionSelected.action === 'delete_field' ? 'error' : 'info'"
    >
      <b>{{ truncateLabel(getFieldLabel(rightClickedField)) }}</b>
      {{ actionSelected.title }}
    </vs-snackbar>

    <v-menu
      v-model="contextMenuVisible"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <template #activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on"></div>
      </template>

      <v-list>
        <template v-for="option in contextItems">
          <v-subheader
            v-if="option.header && rightClickedField"
            :key="option.header"
          >
            {{ getFieldLabel(rightClickedField, true) }}
          </v-subheader>

          <v-list-item
            v-else
            :key="option.value"
            :disabled="duplicateDisabled"
            @click="handleContextAction(option.value, rightClickedField)"
          >
            <v-list-item-action>
              <v-icon :color="option.iconColor">{{ option.icon }}</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title :class="option.color">
                {{ option.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>

    <vs-dialog
      :visible="saveFormDialogVisible"
      title="Save new form"
      @on-close="toggleSaveFormDialog"
    >
      <vs-wrapper>
        <vs-text>
          Before this form can be filled out or attached to jobs, it will need
          to be enabled in the form administrator page.
        </vs-text>
        <vs-text-input
          label="Form name"
          :value="formName"
          required
          @input="setFormName"
        />
        <v-row justify="end">
          <vs-button
            class="mt-3 mr-3 mb-3"
            label="Save"
            :disabled="!formName"
            @click="saveFormToTenant"
          >
          </vs-button>
        </v-row>
      </vs-wrapper>
    </vs-dialog>

    <vs-dialog
      :visible="updateFormDialogVisible"
      title="Confirm Changes"
      @on-close="toggleUpdateFormDialog"
    >
      <vs-wrapper>
        <vs-text>{{ updateSchemaDialogMessage }}</vs-text>
        <v-row justify="end">
          <vs-button
            class="mt-3 mr-3 mb-3"
            label="Save"
            :loading="savingChanges"
            @click="saveChanges"
          >
          </vs-button>
        </v-row>
      </vs-wrapper>
    </vs-dialog>

    <vs-dialog
      :visible="!!saveError"
      title="Save Error Encountered"
      @on-close="saveError = null"
    >
      <vs-wrapper>
        <!-- vs-text uses pre-wrap, hence the syntax. -->
        <vs-text>Unfortunately, saving may not have succeeded.</vs-text>
        <vs-text
          >Check Admin Portal, or refresh IronSight &amp; try creating a new
          <strong>{{
            isEditingCustomFields ? `${activity.name} job` : formName
          }}</strong>
          submission.</vs-text
        >
        <vs-text
          >Click <strong>Export</strong> to download your work-in-progress and
          submit to support@ironsight.app for further assistance.</vs-text
        >
        <v-row justify="end">
          <vs-button
            class="mt-3 mr-3 mb-3"
            label="Okay"
            @click="saveError = null"
          />
        </v-row>
      </vs-wrapper>
    </vs-dialog>

    <v-row class="pt-4">
      <v-col cols="4">
        <v-card class="mx-auto sticky">
          <field-templates-tree-view
            v-if="isEditingFieldDefinitions"
            :selected="selectedFieldDefinition"
            :field-definitions="fieldDefinitions"
            :subject-location-field-exists="canAddSubjectLocationField"
            :subject-field-exists="canAddSubjectField"
            @click-field="updateActiveTemplate"
            @right-click-field="showContextMenu"
            @add-field="addTemplate"
            @reset-editor-visibility="resetToggles"
          >
          </field-templates-tree-view>

          <form-fields-tree-view
            v-else
            :fields-of-page="fieldsOfPage"
            :field-definitions="fieldDefinitions"
            :selected="selectedFormField"
            :active-flow="activeFlow"
            :builder="builder"
            :page-number-selected="selectedPageNumber"
            :subject-location-field-exists="canAddSubjectLocationField"
            :subject-field-exists="canAddSubjectField"
            :edit-reference-fields="editTemplates"
            :is-editing-custom-fields="isEditingCustomFields"
            @click-field="updateActiveFormField"
            @right-click-field="showContextMenu"
            @add-reference-field="addReferenceField"
            @add-field="addFormField"
            @reorder-field="reorderField"
            @change-selected-page="changeSelectedPage"
            @edit-pages-flows="togglePagesAndFlowsSettings"
          >
          </form-fields-tree-view>
        </v-card>
      </v-col>

      <v-col>
        <v-row justify="end">
          <v-col>
            <!-- Custom fields and template fields have some complicated edge
            cases that we'd rather avoid for now. -->
            <vs-button
              v-if="!isEditingCustomFields"
              class="mb-4 ml-auto"
              label="Template Fields"
              type="secondary"
              icon="settings"
              @click="toggleReferenceFieldsEditor"
            />
          </v-col>
          <v-col md="auto">
            <vs-button
              v-if="showSaveButton"
              class="mb-4 ml-auto"
              label="Save"
              :disabled="isSaveButtonDisabled"
              @click="onSaveAction"
            />
          </v-col>
          <v-col md="auto">
            <vs-button
              class="mb-4 ml-auto"
              label="Import"
              @click="importFormJson"
            />
          </v-col>
          <v-col md="auto">
            <vs-button
              class="mb-4 ml-auto"
              label="Export"
              @click="exportFormJson"
            />
          </v-col>
          <v-col md="auto">
            <vs-button class="mb-4 ml-auto" label="Preview" @click="preview" />
          </v-col>
        </v-row>
        <vs-alert v-if="editingForm" class="large-text" type="info">
          Editing: <strong>{{ formName }}</strong>
        </vs-alert>
        <component
          :is="fieldEditorType"
          :active-form-field="activeFormField"
          :active-field-definition="activeTemplateField"
          :selected-field-supports-relationships="
            selectedFieldSupportsRelationships
          "
          :builder="builder"
          :field="activeFormField"
          :active-flow="activeFlow"
          :flow-keys="builder.getFlows()"
          :field-type-name="fieldTypeName"
          @modify-form-field="modifyFormField"
          @remove-form-field="removeFormField"
          @modify-template="modifyTemplate"
          @remove-template="removeTemplate"
          @add-page="addPage"
          @delete-page="deletePage"
          @rename-page-title="renamePageTitle"
          @add-flow="addFlow"
          @delete-flow="deleteFlow"
        ></component>
      </v-col>

      <right-drawer :is-visible="isPreviewVisible">
        <schema-based-form
          :key="formKey"
          :business-unit-id="hubId"
          form-id="preview_form"
          @dismiss="dismiss"
        >
        </schema-based-form>
      </right-drawer>
    </v-row>
  </Page>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import { mapActions, mapGetters } from 'vuex'
import * as Sentry from '@sentry/vue'

import * as fb from 'ironsight-form-builder'
import { ReferenceField } from 'ironsight-form-builder'

import { createForm, updateForm } from '@/api/account-data/manage-forms.ts'
import { getById, updateCustomFields } from '@/api/task.js'

import Task from '@/models/task.js'

import Page from '@/components/common/Page.vue'
import RightDrawer from '@/components/common/RightDrawer.vue'

import SchemaBasedForm from '@/components/forms/SchemaBasedForm.vue'

import VsAlert from '@/components/vision/VsAlert.vue'
import VsButton from '@/components/vision/VsButton.vue'
import VsDialog from '@/components/vision/VsDialog.vue'
import VsHeading from '@/components/vision/VsHeading.vue'
import VsSnackbar from '@/components/vision/VsSnackbar.vue'
import VsText from '@/components/vision/VsText.vue'
import VsTextInput from '@/components/vision/Inputs/VsTextInput.vue'
import VsWrapper from '@/components/vision/VsWrapper.vue'

import CoreFieldSettings from '@/components/form-builder/field-settings/CoreFieldSettings.vue'
import EditPagesAndFlowsSettings from '@/components/form-builder/EditPagesAndFlowsSettings.vue'
import FieldTemplatesEditor from '@/components/form-builder/FieldTemplatesEditor.vue'
import FieldTemplatesTreeView from '@/components/form-builder/FieldTemplatesTreeView.vue'
import FormFieldDefinitionsEditor from '@/components/form-builder/FormFieldDefinitionsEditor.vue'
import FormFieldsTreeView from '@/components/form-builder/FormFieldsTreeView.vue'
import RelationshipsEditor from '@/components/form-builder/RelationshipsEditor.vue'
import SpecificFieldSettings from '@/components/form-builder/field-settings/SpecificFieldSettings.vue'

import formFieldTitle from '@/utils/forms/form-field-title.js'
import { mergeSchemas } from '@/utils/forms/form-resolver.js'

export default {
  name: 'FormBuilder',
  components: {
    VsAlert,
    VsSnackbar,
    FieldTemplatesTreeView,
    FormFieldsTreeView,
    FormFieldDefinitionsEditor,
    VsText,
    VsDialog,
    VsWrapper,
    VsTextInput,
    EditPagesAndFlowsSettings,
    RelationshipsEditor,
    CoreFieldSettings,
    SpecificFieldSettings,
    Page,
    VsButton,
    VsHeading,
    SchemaBasedForm,
    FieldTemplatesEditor,
    RightDrawer,
  },

  data: () => {
    return {
      editingForm: false,
      fieldMenu: false,
      isPreviewVisible: false,
      formKey: 0,
      search: null,
      caseSensitive: false,
      builder: fb.FormBuilder.newForm(),
      activeFormField: null,
      activeTemplateField: null,
      activeFlow: '#root_schema',
      selectedPageNumber: 0,
      activityId: null,
      activity: null,
      formId: null,
      formName: '',
      saveFormDialogVisible: false,
      updateFormDialogVisible: false,
      savingChanges: false,
      saveError: null,
      fieldTypeName: null,
      editPagesAndFlows: false,
      editTemplates: false,
      hubId: '',
      pageTitle: 'Schema Builder',
      pageDescription:
        'Note: this is a new tool.  Please contact support@ironsight.app if you encounter any issues.',
      rightClickedField: null,
      showSnackbar: false,
      contextMenuVisible: false,
      actionSelected: { title: '', action: '' },
      x: 0,
      y: 0,
      contextItems: [
        { header: ' ' },
        {
          title: 'Duplicate Field',
          value: 'duplicate_field',
          icon: 'content_copy',
        },
        {
          title: 'Delete Field',
          value: 'delete_field',
          color: 'delete',
          icon: 'delete',
          iconColor: 'red',
        },
      ],
    }
  },

  computed: {
    isEditingPagesAndFlowsSettings() {
      return !this.activeFormField && this.editPagesAndFlows
    },
    isEditingFieldDefinitions() {
      return this.editTemplates
    },
    fieldEditorType() {
      if (this.isEditingPagesAndFlowsSettings) {
        return EditPagesAndFlowsSettings.name
      } else if (this.isEditingFieldDefinitions) {
        return FieldTemplatesEditor.name
      } else {
        return FormFieldDefinitionsEditor.name
      }
    },
    ...mapGetters({
      isHostOrganizationUser: 'permissions/isHostOrganizationUser',
      hasAccountOrganizationAccess: 'permissions/hasAccountOrganizationAccess',
      canSaveCustomFields: 'permissions/canSaveCustomFieldsSchema',
      getFormById: 'forms/getById',
      getBaseDefinitionsSchema: 'schemas/getJobCustomFieldsSchema',
    }),
    fieldsOfPage() {
      return this.builder.fieldsOfPage(this.selectedPageNumber, this.activeFlow)
    },
    fieldDefinitions() {
      return this.builder.fieldDefinitions()
    },
    duplicateDisabled() {
      return this.rightClickedField instanceof fb.ObjectFieldDefinition
    },
    showSaveButton() {
      return this.isHostOrganizationUser && this.hasAccountOrganizationAccess
    },
    /**
     * When editing custom fields, note that the structure will be wrong because
     * the schema will be stored in a `pages` array.  When saving/exporting,
     * this will be cleaned up.
     *
     * It was less messy to keep it this way than to make the previewer support
     * custom fields.
     */
    structure() {
      return this.builder.build()
    },
    selectedFormField() {
      return this.activeFormField
    },
    selectedFieldDefinition() {
      return this.activeTemplateField
    },
    selectedFieldSupportsRelationships() {
      return [
        fb.GenericSelectorDefinition,
        fb.NumberFieldDefinition,
        fb.EnumStringFieldDefinition,
        fb.BooleanFieldDefinition,
        fb.TextEntryFieldDefinition,
        fb.ReferenceField,
      ].some((supportedField) => this.activeFormField instanceof supportedField)
    },
    canAddSubjectLocationField() {
      return (
        this.builder.fieldKeyExists(
          new fb.SubjectLocationFieldDefinition().uniqueKey()
        ) || this.fieldEditorType === FieldTemplatesEditor.name
      )
    },
    canAddSubjectField() {
      return (
        this.builder.fieldKeyExists(
          new fb.SubjectFieldDefinition().uniqueKey()
        ) || this.fieldEditorType === FieldTemplatesEditor.name
      )
    },
    /**
     * `true` if not editing a Form (which can have flows, pages, etc.).
     */
    isEditingCustomFields() {
      return this.builder.sourceIsCustomFieldSchema === true
    },
    isSaveButtonDisabled() {
      return this.fieldsOfPage.length === 0 || !this.canSaveCustomFields
    },
    updateSchemaDialogMessage() {
      if (this.isEditingCustomFields) {
        return `Saving will change ${this.activity.name}'s custom fields for new and opted-in jobs.\n\nRefresh any previously-open IronSight tabs/windows after saving your changes.`
      } else {
        return 'Changes made will be applied to the form structure.'
      }
    },
  },

  beforeMount() {
    // TODO(aaron): I've tried, can't seem to refactor this setTimeout use and made it worse.
    setTimeout(async () => {
      await this.checkIfEditingFromAdminPortal()
    }, 1000)
    this.createPreviewForm()
  },

  methods: {
    getFieldLabel(field) {
      return formFieldTitle(field)
    },
    truncateLabel(label) {
      if (label.length > 30) {
        return label.slice(0, 30) + '...'
      }
      return label
    },
    handleContextAction(action, field) {
      this.showSnackbar = true
      switch (action) {
        case 'duplicate_field':
          this.actionSelected = { title: 'Duplicated', action }
          if (this.editTemplates) {
            this.addTemplate(field.copy())
          } else {
            this.addFormField(field.copy())
          }
          break
        case 'delete_field':
          this.actionSelected = { title: 'Deleted', action }
          if (this.editTemplates) {
            this.removeTemplate(field)
          } else {
            this.removeFormField(field)
          }
          break
      }
    },
    showContextMenu(field) {
      this.rightClickedField = field.field
      field.e.preventDefault()
      this.contextMenuVisible = false
      this.x = field.e.clientX
      this.y = field.e.clientY
      this.$nextTick(() => {
        this.contextMenuVisible = true
      })
    },
    resetToggles() {
      this.editTemplates = false
      this.editPagesAndFlows = false
      this.activeFormField = null
      this.activeTemplateField = null
    },
    toggleReferenceFieldsEditor() {
      this.resetToggles()
      this.editTemplates = true
    },
    togglePagesAndFlowsSettings() {
      this.resetToggles()
      this.editPagesAndFlows = true
    },
    ...mapActions({
      createPreviewForm: 'forms/createPreviewForm',
      updatePreviewFormStructure: 'forms/updatePreviewFormStructure',
    }),
    addFlow(flowName) {
      this.builder.addEmptyFlow(flowName)
    },
    deleteFlow(flowKey) {
      this.activeFlow =
        this.builder.getFlows()[this.builder.getFlows().indexOf(flowKey) - 1]
      this.selectedPageNumber = 0
      this.builder.deleteFlow(flowKey)
    },
    addPage(flowKey) {
      let lastPageIndex = this.builder.pageCount(flowKey) - 1
      this.builder.insertBlankPageAfter(lastPageIndex++, flowKey)
    },
    deletePage(page) {
      this.builder.deletePage(page.index, page.flowKey)
      const newActivePageIndex =
        page.index === this.selectedPageNumber
          ? page.index === 0
            ? page.index
            : page.index - 1
          : this.selectedPageNumber
      this.activeFlow = page.flowKey
      this.selectedPageNumber = newActivePageIndex
    },
    renamePageTitle(page) {
      this.builder.editPageTitle(page.title, page.index, page.flowKey)
    },
    changeSelectedPage(page) {
      this.activeFlow = page.flowKey
      this.selectedPageNumber = page.index
      this.resetToggles()
    },
    updateActiveFormField(selectedField) {
      this.editPagesAndFlows = false
      this.activeFormField = selectedField
    },
    updateActiveTemplate(selectedField) {
      this.editPagesAndFlows = false
      this.activeTemplateField = selectedField
    },
    addReferenceField(field) {
      const referenceField = new ReferenceField(field)

      // Vue needs properties to exist, even if they are null,
      // for computed to detect changes based on them
      referenceField.titleOverride = null
      referenceField.descriptionOverride = null

      this.builder.addFieldToPage(
        referenceField,
        this.selectedPageNumber,
        this.activeFlow
      )

      const newIndex = this.fieldsOfPage.indexOf(this.activeFormField)
      if (newIndex !== -1 && this.activeFormField) {
        this.builder.reorderField(referenceField, newIndex + 1)
      }

      this.activeFormField = referenceField
    },
    addFormField(field) {
      this.builder.addFieldToPage(
        field,
        this.selectedPageNumber,
        this.activeFlow
      )

      const newIndex = this.fieldsOfPage.indexOf(this.activeFormField)
      if (newIndex !== -1 && this.activeFormField) {
        this.builder.reorderField(field, newIndex + 1)
      }

      this.activeFormField = field
    },
    removeFormField(field) {
      this.builder.removeField(field)
      this.activeFormField = null
    },
    modifyFormField(data) {
      this.activeFormField[data.propertyKey] = data.fieldValue
    },
    addTemplate(field) {
      this.builder.addFieldDefinition(field)

      const newIndex = this.fieldsOfPage.indexOf(this.activeTemplateField)
      if (newIndex !== -1 && this.activeTemplateField) {
        this.builder.reorderField(field, newIndex + 1)
      }

      this.activeTemplateField = field
    },
    removeTemplate(field) {
      this.builder.deleteFieldDefinition(field)
      this.activeTemplateField = null
    },
    modifyTemplate(data) {
      this.activeTemplateField[data.propertyKey] = data.fieldValue
    },
    reorderField(event) {
      const field = event.moved.element
      const newIndex = event.moved.newIndex
      this.builder.reorderField(field, newIndex)
    },
    togglePreview() {
      this.isPreviewVisible = !this.isPreviewVisible
    },
    preview() {
      this.togglePreview()
      this.updatePreviewFormStructure(this.structure)
      this.formKey++
    },
    dismiss() {
      this.togglePreview()
    },
    async importFormJson() {
      try {
        const [fileHandle] = await window.showOpenFilePicker()
        const fileData = await fileHandle.getFile()

        if (fileData.name.indexOf('.json') === -1) {
          throw new Error('Please select a .json file')
        }

        const text = await fileData.text()
        const json = JSON.parse(text)
        this.builder = new fb.FormParser().parse(json)
      } catch (e) {
        const wasAborted = e.toString().indexOf('The user aborted') !== -1
        if (!wasAborted) {
          alert(e)
        }
      }
    },
    exportFormJson() {
      let json
      if (this.isEditingCustomFields) {
        json = JSON.stringify(
          this.builder.buildCustomFieldSchema(this.getBaseDefinitionsSchema)
        )
      } else {
        json = JSON.stringify(this.builder.build())
      }

      const file = new Blob([json], { type: 'text/json' })

      const a = document.createElement('a')
      const url = URL.createObjectURL(file)
      a.href = url
      a.download = 'form.json'
      document.body.appendChild(a)
      a.click()
      setTimeout(function () {
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
      }, 0)
    },
    onSaveAction() {
      if (this.editingForm) {
        return this.toggleUpdateFormDialog()
      } else {
        return this.toggleSaveFormDialog()
      }
    },
    toggleSaveFormDialog() {
      this.saveFormDialogVisible = !this.saveFormDialogVisible
    },
    toggleUpdateFormDialog() {
      this.updateFormDialogVisible = !this.updateFormDialogVisible
    },
    async saveFormToTenant() {
      const id = uuidv4()

      await createForm({
        name: this.formName,
        id,
        owner_id: this.$auth.user.org_id,
        structure: this.builder.build(),
        state: 'Active',
        fillable: false,
      })

      this.$router.push({ name: 'form-definition-form', params: { id } })
    },
    setFormName(name) {
      this.formName = name
    },
    async checkIfEditingFromAdminPortal() {
      this.formId = this.$route.query.formId
      this.activityId = this.$route.query.activityId

      if (this.formId || this.activityId) {
        this.editingForm = true
      }

      if (this.formId) {
        const form = this.getFormById(this.formId)

        try {
          this.builder = new fb.FormParser().parse(form.structure)
          this.formName = form.name
          this.activeFormField = this.fieldsOfPage[0]
          this.preview()
        } catch (e) {
          alert(e)
        }
      } else if (this.activityId) {
        // Trying to avoid using the deprecated Vuex store: make API call instead
        const { data } = await getById(this.activityId)
        const hasNoCustomFieldsSchema =
          Object.keys(data.job_custom_fields_schema ?? {}).length === 0
        const isOlderCustomFieldsSchemaStructure = !(
          'schema' in data.job_custom_fields_schema
        )

        if (hasNoCustomFieldsSchema) {
          data.job_custom_fields_schema = {
            schema: { properties: {} },
            uiSchema: {},
          }
        } else if (isOlderCustomFieldsSchemaStructure) {
          data.job_custom_fields_schema = {
            schema: data.job_custom_fields_schema,
            uiSchema: {},
          }
        }

        this.parseCustomFieldsSchema(data)
      }
    },
    parseCustomFieldsSchema(data) {
      this.activity = new Task(data)
      this.formName = `Custom Fields for ${
        this.activity.name ?? 'Unknown Activity'
      }`

      try {
        // Some custom fields schemas rely on predefined fields in a
        // `custom_field_schemas` DB table. We insert them here so the builder
        // can provide them as reusable fields to the end user. When the schema
        // gets built, these definitions are removed from the built schema to
        // preserve the global behaviour.
        const mergedCustomFieldSchema = mergeSchemas(
          this.activity.jobCustomFieldsSchema,
          this.getBaseDefinitionsSchema
        )

        this.builder = new fb.FormParser().parse(mergedCustomFieldSchema)
        this.activeFormField = this.fieldsOfPage[0]
        this.preview()
      } catch (error) {
        console.error(error)
        const eventId = Sentry.captureException(error, {
          activityId: this.activityId,
          activity: this.activity,
        })
        // @TODO(aaron): Could be a vs-dialog
        alert(
          'Unfortunately, the builder could not load these custom fields. ' +
            'Please contact support@ironsight.app (with a screenshot of this ' +
            'error if possible) for further assistance.\n\n' +
            `Error ID: ${eventId ?? 'Unknown'}\n` +
            `Activity ID: ${this.activityId ?? 'Unknown'}\n` +
            `Activity Name: ${this.activity.name ?? 'Unknown'}`
        )
      }
    },
    async saveChanges() {
      this.savingChanges = true
      this.saveError = null

      try {
        if (this.isEditingCustomFields) {
          const response = await updateCustomFields(
            this.activityId,
            this.builder.buildCustomFieldSchema(this.getBaseDefinitionsSchema)
          )
          this.activity = response.data
        } else {
          await updateForm(this.formId, {
            name: this.formName,
            structure: this.structure,
            notificationsEnabled: false,
          })
        }
      } catch (error) {
        this.saveError = error
        Sentry.captureException(error, {
          activityId: this.activityId,
          formId: this.formId,
        })
      }

      this.savingChanges = false
      this.toggleUpdateFormDialog()
    },
  },
}
</script>

<style scoped>
.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky !important;
  top: 8vh;
}

.large-text {
  font-size: 16px;
}
</style>
