<template>
  <vs-wrapper>
    <vs-text>Unsupported field schema</vs-text>
    <vs-text v-if="schema !== null">
      schema:
      <pre>{{ JSON.stringify(schema, null, 2) }}</pre>
    </vs-text>
    <vs-text v-if="uiSchema !== null">
      uiSchema:
      <pre>{{ JSON.stringify(uiSchema, null, 2) }}</pre>
    </vs-text>
  </vs-wrapper>
</template>

<script>
export default {
  name: 'UnsupportedField',
  props: {
    errorSchema: Object,
    formData: undefined,
    params: Object,
    rootSchema: Object,
    schema: Object,
    title: String,
    uiSchema: Object,
  },
}
</script>
