<template>
  <vs-wrapper>
    <vs-heading v-if="title" type="subhead">{{ title }}</vs-heading>
    <div v-if="description" class="hint">
      <vs-text type="subdued">{{ description }}</vs-text>
    </div>
  </vs-wrapper>
</template>

<script>
export default {
  name: 'NullField',
  props: {
    errorSchema: Object,
    formData: undefined,
    params: Object,
    rootSchema: Object,
    schema: Object,
    title: String,
    description: String,
    uiSchema: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped></style>
