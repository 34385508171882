<template>
  <FormLayout
    :is-loading="formSubmissionLoading && !formSubmission"
    :title="formTitle"
    @on-dismiss="handleDismiss"
  >
    <vs-alert v-if="errorLoadingFormSubmission" type="error">
      There was a problem loading the form. You may not have access to view it.
    </vs-alert>
    <vs-wrapper v-if="formSubmission">
      <form-submission-actions-status
        v-if="hasFollowUpActions && !isJobForm"
        v-bind="assignedTo"
        :uncompleted-actions-count="uncompletedActionsCount"
      />
      <v-row dense align="center">
        <v-col class="shrink">
          <v-icon>calendar_today</v-icon>
        </v-col>
        <v-col>
          <vs-text type="overline">Submitted on</vs-text>
          <vs-text>{{ submittedOn }}</vs-text>
        </v-col>

        <v-col class="shrink">
          <v-icon>account_circle</v-icon>
        </v-col>
        <v-col>
          <vs-text type="overline">Submitted by</vs-text>
          <vs-text>{{ submittedBy }}</vs-text>
        </v-col>
      </v-row>
      <v-row v-if="formSubmission.jobNumber" dense align="center">
        <v-col class="shrink">
          <v-icon>attachment</v-icon>
        </v-col>
        <v-col>
          <vs-text type="overline">Attached to job</vs-text>
          <vs-text>{{ jobNumber }}</vs-text>
        </v-col>
      </v-row>
      <vs-button
        v-if="showCompleteAll"
        data-test="complete-all-form-actions"
        label="Complete All Actions"
        full-width
        :loading="completeAllActionsPending"
        @click="handleCompleteAllFollowUpActions"
      />

      <template v-if="isSubmissionEditable">
        <v-row dense class="pb-3" justify="end" align="center">
          <v-col>
            <vs-button
              label="Edit"
              full-width
              type="secondary"
              @click="handleEditClick"
            />
          </v-col>
        </v-row>
      </template>

      <template v-if="hasFollowUpActions && !isJobForm">
        <v-row
          v-if="canBeAssigned"
          dense
          class="pb-3"
          justify="end"
          align="center"
        >
          <v-col>
            <vs-button
              data-test="assign-form"
              label="Assign to..."
              full-width
              :loading="assignmentPending"
              @click="handleAssignToClick"
            />
          </v-col>
        </v-row>
      </template>

      <v-row justify="center">
        <v-col v-if="allowedToLike && !isJobForm" class="shrink">
          <vs-button
            class="mx-4"
            :pending="addingLikePending"
            :disabled="likedByMe"
            data-test="like-form"
            icon="thumb_up"
            type="secondary"
            @click="likeForm"
          />
          <vs-text class="btn-text">
            {{ likeText }}
          </vs-text>
        </v-col>
        <v-col v-if="!isJobForm" class="shrink">
          <vs-button
            class="mx-4"
            :loading="isPdfDownloading"
            icon="picture_as_pdf"
            data-test="download-form-pdf"
            type="secondary"
            @click="downloadPdf"
          />
          <vs-text class="btn-text">Download<br />PDF</vs-text>
        </v-col>
        <v-col v-if="showViewJobButton" class="shrink">
          <vs-button
            class="mx-4"
            icon="search"
            data-test="view-job-from-form"
            type="secondary"
            @click="pushSearchJobRoute"
          />
          <vs-text class="btn-text">View job</vs-text>
        </v-col>
        <v-col v-if="canBeDeleted" class="shrink">
          <vs-menu
            :items="menuOptions"
            horizontal-position="right"
            :max-height="400"
          >
            <template #activator>
              <vs-button
                class="mx-4"
                data-test="form-menu-options"
                icon="more_horiz"
                type="secondary"
              />
            </template>
          </vs-menu>
          <vs-text class="btn-text">More</vs-text>
        </v-col>
      </v-row>

      <template v-if="hasFollowUpActions && !isJobForm">
        <v-row dense align="center">
          <v-col>
            <vs-heading type="overline">ACTIONS</vs-heading>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-row dense align="center">
              <v-col class="shrink">
                <v-icon>near_me</v-icon>
              </v-col>
              <v-col>
                <vs-text type="overline">ASSIGNED TO</vs-text>
                <vs-text>{{ assignedToLabel }}</vs-text>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="canBeReassigned" class="shrink">
            <vs-button
              label="Reassign"
              type="secondary"
              small
              :loading="assignmentPending"
              data-test="reassign-form"
              @click="handleAssignToClick"
            />
          </v-col>
        </v-row>
        <FollowUpAction
          v-for="data in followUpActions"
          :key="data.id"
          :is-saving="isFollowUpActionSaving(data.id)"
          :action-id="data.id"
          :completed="data.isCompleted"
          :title="data.title"
          :description="data.description"
          :readonly="!canCurrentUserCompleteFollowUpActions"
          @readonly-follow-up-action-clicked="handleReadOnlyFollowUpActionClick"
          @complete-follow-up-action="handleCompleteFollowUpAction"
        />
      </template>

      <vs-heading type="overline">FORM SUBMISSION</vs-heading>

      <submission-field
        :flattened-fields="flattenedFormFields"
        :submission-data="submissionData"
      />

      <template v-if="!isJobForm">
        <form-tags
          data-test="form-tags"
          :value="tags"
          @input="updateTags"
        ></form-tags>

        <vs-heading type="overline">LOG</vs-heading>

        <v-timeline dense align-top class="comments-list text-break">
          <template v-for="itemsForDay in timeLineItemsGroupedByDay">
            <v-timeline-item
              :key="`timeline-date-item-${itemsForDay.date}`"
              hide-dot
            >
              <vs-text>{{ dayDisplayLabel(itemsForDay.date) }}</vs-text>
            </v-timeline-item>
            <TimelineCommentBox
              v-if="isDateToday(itemsForDay.date)"
              :key="`timeline-comment-item-${itemsForDay.date}`"
              :loading="addingCommentPending"
              @submit="handleAddCommentToSubmissionForm"
            />
            <form-submission-timeline-item
              v-for="item in itemsForDay.entries"
              :key="`timeline-item-${item.date}-${item.actionDescription}`"
              :icon="item.icon"
              :date="item.date"
              :action-description="item.actionDescription"
              :user-sid="item.userSid"
              :text="item.text"
            />
          </template>
        </v-timeline>
      </template>
    </vs-wrapper>

    <template #snack-bar-wrapper>
      <vs-snackbar v-model="isSnackbarVisible" :type="snackbar.type" sticky>
        {{ snackbar.messages[snackbar.messageKey] }}
      </vs-snackbar>
    </template>

    <template #bottom-sheet>
      <vs-bottom-sheet
        :is-visible="isBottomSheetVisible"
        :class="{ 'fixed-height': isAssignVisible }"
      >
        <assign-form-submission-to
          v-if="isAssignVisible"
          :business-unit-id="businessUnitId"
          @dismiss="closeAssignBottomSheet"
          @assignee-selected="assignEntity"
        />
        <complete-follow-up-actions-comment
          v-if="isCompletionCommentVisibleForId != null"
          @dismiss="closeCompletionCommentBottomSheet"
          @completeWithComment="handleCompleteWithComment"
        />
      </vs-bottom-sheet>

      <vs-bottom-sheet :is-visible="isEditFormSubmissionVisible" full-height>
        <schema-based-form
          :form-id="formId"
          :form-submission-id="formSubmissionId"
          :business-unit-id="businessUnitId"
          @dismiss="closeEditFormSubmissionBottomSheet"
          @submitted="handleEditedFormSubmission"
        />
      </vs-bottom-sheet>
    </template>
  </FormLayout>
</template>

<script>
import FormLayout from '../common/FormLayout'
import { mapActions, mapGetters, mapState } from 'vuex'
import { formatDateTime } from '@/utils/date-formatting'
import { getFormFields } from '@/utils/field-schema-helper'
import moment from 'moment'
import { groupBy, map, orderBy } from 'lodash'
import FollowUpAction from '@/components/forms/FollowUpAction'
import AssignFormSubmissionTo from '@/components/forms/AssignFromSubmissionTo'
import CompleteFollowUpActionsComment from '@/components/forms/CompleteFollowUpActionsComment'
import FormSubmissionTimelineItem from '@/components/forms/FormSubmissionTimelineItem'
import SchemaBasedForm from '@/components/forms/SchemaBasedForm'
import SubmissionField from '@/components/forms/submission-fields/SubmissionField'
import FormSubmissionActionsStatus from '@/components/forms/FormSubmissionActionsStatus'
import FormTags from '@/components/forms/FormTags'
import { getFormSubmissionPdf } from '@/api/form_submissions'
import { saveFile } from '@/utils/save-file'
import FormSubmission from '@/models/form_submission'
import TimelineCommentBox from '@/components/common/timeline/TimelineCommentBox'

export default {
  name: 'FormSubmission',
  components: {
    TimelineCommentBox,
    SubmissionField,
    AssignFormSubmissionTo,
    FollowUpAction,
    FormLayout,
    CompleteFollowUpActionsComment,
    FormSubmissionTimelineItem,
    FormSubmissionActionsStatus,
    FormTags,
    SchemaBasedForm,
  },
  props: {
    formSubmissionId: {
      type: String,
      required: true,
    },
    parentRouteName: {
      type: String,
      default: '',
      required: false,
    },
    canDelete: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data() {
    return {
      isAssignVisible: false,
      isCompletionCommentVisibleForId: null,
      isEditFormSubmissionVisible: false,
      snackbar: {
        type: 'info',
        timeout: 6000,
        messageKey: null,
        showActions: true,
        completeAll: false,
        messages: {
          notAssigned: 'Form submission must be assigned first',
          failedToAssign: 'Failed to assign form submission',
          failedToCompleteAction: 'Failed to complete action',
          failedToCompleteAllActions: 'Failed to complete all actions',
          failedToSubmitComment: 'Failed to submit comment',
          failedToSubmitLike: 'Failed to like',
          failedToSubmitTag: 'Failed to submit tag',
          assigned: 'Form submission assigned',
          pdfDownloadError: 'Failed to download PDF',
          failedToDelete: 'Failed to delete submission',
        },
      },
      isSnackbarVisible: false,
      isPdfDownloading: false,
    }
  },
  computed: {
    ...mapGetters({
      errorLoadingFormSubmission: 'formSubmissions/getError',
      getUserBySid: 'users/getBySid',
      getCompanyById: 'companies/getById',
      getFormSubmissionById: 'formSubmissions/getById',
      getFormDefinitionById: 'forms/getById',
      getBusinessUnitById: 'businessUnits/getBusinessUnitById',
      formSubmissionIsSaving: 'formSubmissions/isCreatingOrUpdating',
      makeFilterArguments: 'formSubmissionsFilters/makeFilterArguments',
      canAssignForm: 'permissions/canAssignForm',
      canDeleteFormSubmission: 'permissions/canDeleteFormSubmission',
      canCompleteActionWithoutBeingAssigned:
        'permissions/canCompleteActionWithoutBeingAssigned',
      formSubmissionLoading: 'formSubmissions/getLoading',
    }),
    ...mapState('formSubmissions', [
      'assignmentPending',
      'completeAllActionsPending',
      'completeFollowUpPending',
      'addingLikePending',
      'addingCommentPending',
      'error',
    ]),
    formTitle() {
      return !this.formDefinition
        ? 'Form'
        : `${this.formDefinition.name} #${this.formSubmission.number}`
    },
    formSubmission() {
      return this.getFormSubmissionById(this.formSubmissionId)
    },
    submissionData() {
      return this.formSubmission?.submission
    },
    formId() {
      return this.formSubmission?.formId ?? ''
    },
    formDefinition() {
      if (this.formSubmission) {
        return this.getFormDefinitionById(this.formSubmission.formId)
      }
      return null
    },
    isFormEditable() {
      return this.formDefinition?.isEditable() ?? false
    },
    isJobForm() {
      return this.formDefinition?.isJobForm() ?? false
    },
    isSubmissionEditable() {
      return this.isFormEditable
    },
    submittedBy() {
      return this.getUserBySid(this.formSubmission.createdBy)?.name
    },
    jobNumber() {
      return this.formSubmission.jobNumber?.toString() ?? ''
    },
    submittedOn() {
      return formatDateTime(moment.utc(this.formSubmission.created).local())
    },
    flattenedFormFields() {
      if (!this.formSubmission || !this.formDefinition) {
        return {}
      }

      return getFormFields(this.formDefinition.structure)
    },
    followUpActions() {
      return this.formSubmission.followUpActions
    },
    hasFollowUpActions() {
      return this.followUpActions.length > 0
    },
    uncompletedActionsCount() {
      return this.followUpActions.filter((fua) => !fua.isCompleted).length
    },
    hasAnyCompletedActions() {
      return this.followUpActions.some((fua) => fua.isCompleted)
    },
    areAllFollowUpActionsCompleted() {
      return this.uncompletedActionsCount === 0
    },
    canBeAssigned() {
      return this.formSubmission.assignedTo == null && this.canAssignForm
    },
    showViewJobButton() {
      return (
        this.formSubmission.jobNumber != null &&
        this.$route.name === 'view-form-submission'
      )
    },
    canBeDeleted() {
      return (
        !this.isJobForm &&
        this.canDeleteFormSubmission &&
        this.formDefinition?.ownerId === this.$auth.user.org_id &&
        this.canDelete
      )
    },
    menuOptions() {
      return {
        delete: {
          text: 'Delete',
          icon: 'delete',
          onClick: async () => {
            const confirmation = window.confirm(
              'Are you sure you want to delete this form submission?'
            )

            if (confirmation) {
              await this.deleteFormSubmissionAction(this.formSubmissionId)

              if (this.error) {
                this.setSnackbarData({
                  type: 'error',
                  messageKey: 'failedToDelete',
                })
              } else {
                this.fetchFirstFormSubmissionPage(this.makeFilterArguments)
                this.handleDismiss()
              }
            }
          },
        },
      }
    },
    canBeReassigned() {
      return (
        this.formSubmission.assignedTo != null &&
        this.canAssignForm &&
        !this.areAllFollowUpActionsCompleted &&
        !this.hasAnyCompletedActions
      )
    },
    assignedToLabel() {
      const assigneeType = this.assignedTo?.type
      if (assigneeType === FormSubmission.ASSIGNMENT_ENTITY_TYPE_USER) {
        return this.getUserBySid(this.assignedTo.id)?.name
      } else if (
        assigneeType === FormSubmission.ASSIGNMENT_ENTITY_TYPE_BUSINESS_UNIT
      ) {
        return this.getBusinessUnitById(this.assignedTo.id)?.name
      }
      return 'Not assigned yet'
    },
    isCurrentUserTheAssignedUser() {
      return (
        this.assignedTo?.type === 'User' &&
        this.$auth.user.sub === this.formSubmission.assignedTo.id
      )
    },
    canCurrentUserCompleteFollowUpActions() {
      return (
        this.assignedTo !== null &&
        (this.canCompleteActionWithoutBeingAssigned ||
          this.isCurrentUserTheAssignedUser)
      )
    },
    comments() {
      return this.formSubmission.comments
    },
    likes() {
      return this.formSubmission.likes
    },
    tags() {
      return this.formSubmission.tags
    },
    likedByMe() {
      return this.likes.some((like) => like.likedBy === this.$auth.user.sub)
    },
    likeText() {
      if (this.likedByMe) {
        return 'Liked'
      }
      return 'Like'
    },
    allowedToLike() {
      return this.$auth.user.sub !== this.formSubmission.createdBy
    },
    assignedTo() {
      return this.formSubmission.assignedTo
    },
    showCompleteAll() {
      if (this.areAllFollowUpActionsCompleted) {
        return false
      }

      if (
        !this.isJobForm &&
        this.isCurrentUserTheAssignedUser &&
        !this.areAllFollowUpActionsCompleted
      ) {
        return true
      }
      if (
        this.canCompleteActionWithoutBeingAssigned &&
        this.assignedTo !== null
      ) {
        return true
      }

      return false
    },
    timeLineItemsGroupedByDay() {
      const items = [
        ...this.comments.map((comment) => {
          for (const action in this.followUpActions) {
            if (action.isCompleted && action.completedOn === comment.created) {
              return {
                icon: 'comment',
                actionDescription: 'Completion Comment',
                userSid: comment.createdBy,
                date: comment.created,
                text: comment.text,
              }
            }
          }
          return {
            icon: 'comment',
            actionDescription: 'Comment',
            userSid: comment.createdBy,
            date: comment.created,
            text: comment.text,
          }
        }),
        ...this.likes.map((like) => {
          return {
            icon: 'thumb_up_off_alt',
            actionDescription: 'Liked',
            userSid: like.likedBy,
            date: like.occurredAt,
          }
        }),
      ]
      const orderedItems = orderBy(items, (i) => moment.utc(i.date).unix(), [
        'desc',
      ])

      const dayFormat = 'YYYY MM DD'
      let itemsGroupedByDay = groupBy(orderedItems, (i) =>
        moment.utc(i.date).local().format(dayFormat)
      )

      const today = moment().format(dayFormat)
      if (!(today in itemsGroupedByDay)) {
        itemsGroupedByDay = { [today]: {}, ...itemsGroupedByDay }
      }

      const arrayOfGroups = map(itemsGroupedByDay, (v, k) => {
        return { date: k, entries: v }
      })

      return orderBy(arrayOfGroups, (g) => moment(g.date, dayFormat).unix(), [
        'desc',
      ])
    },
    isBottomSheetVisible() {
      return (
        this.isAssignVisible || this.isCompletionCommentVisibleForId != null
      )
    },
    businessUnitId() {
      return this.formSubmission?.ownerId
    },
  },
  watch: {
    formSubmissionIsSaving(_, newValue) {
      if (newValue) {
        this.fetchFormSubmission(this.formSubmissionId)
      }
    },
  },
  async mounted() {
    await this.fetchFormSubmission(this.formSubmissionId)
  },
  methods: {
    ...mapActions('formSubmissions', [
      'fetchFormSubmission',
      'assignFormSubmission',
      'completeFollowUpAction',
      'completeAllFollowUpActions',
      'addCommentToFormSubmission',
      'likeFormSubmission',
      'updateFormSubmissionTags',
      'deleteFormSubmissionAction',
      'fetchFirstFormSubmissionPage',
    ]),
    async pushSearchJobRoute() {
      await this.$router.push({
        name: 'search-ticket-info-panel',
        params: {
          id: this.jobNumber,
          hubId: this.hubId,
        },
      })
    },
    isDateToday(date) {
      const dayFormat = 'YYYY MM DD'
      return date === moment().format(dayFormat)
    },
    setSnackbarData({ type = 'info', messageKey = null }) {
      Object.assign(this.snackbar, { type, messageKey })
      this.isSnackbarVisible = true
    },
    handleDismiss() {
      if (this.parentRouteName) {
        this.$router.push({
          name: this.parentRouteName,
        })
      }
      this.$emit('dismiss')
    },
    isFollowUpActionSaving(id) {
      return this.completeFollowUpPending.includes(id)
    },
    dayDisplayLabel(date) {
      return moment(date, 'YYYY MM DD')
        .calendar({
          sameDay: '[Today] · MMMM D YYYY',
          lastDay: '[Yesterday] · MMMM D YYYY',
          nextWeek: 'MMMM D YYYY',
          lastWeek: 'MMMM D YYYY',
          sameElse: 'MMMM D YYYY',
          nextDay: 'MMMM D YYYY',
        })
        .toUpperCase()
    },
    handleAssignToClick() {
      this.isAssignVisible = true
    },
    handleEditClick() {
      this.isEditFormSubmissionVisible = true
    },
    handleEditedFormSubmission(formSubmissionId) {
      if (this.isJobForm) this.$emit('edited', formSubmissionId)
    },
    closeAssignBottomSheet() {
      this.isAssignVisible = false
    },
    closeCompletionCommentBottomSheet() {
      this.isCompletionCommentVisibleForId = null
    },
    closeEditFormSubmissionBottomSheet() {
      this.isEditFormSubmissionVisible = false
    },
    async assignEntity(assigneeId, assigneeType) {
      this.isAssignVisible = false

      await this.assignFormSubmission({
        id: this.formSubmissionId,
        assigneeId,
        assigneeType,
      })

      if (this.error) {
        this.setSnackbarData({
          type: 'error',
          messageKey: 'failedToAssign',
        })
      } else {
        this.setSnackbarData({
          messageKey: 'assigned',
        })
      }
    },
    handleReadOnlyFollowUpActionClick() {
      if (!this.assignedTo) {
        this.setSnackbarData({
          type: 'error',
          messageKey: 'notAssigned',
        })
      }
    },
    async handleCompleteFollowUpAction(actionId) {
      if (this.followUpActions.filter((fua) => !fua.isCompleted).length === 1) {
        this.isCompletionCommentVisibleForId = actionId
      } else {
        await this.completeAction(actionId, null)
      }
    },
    async handleCompleteAllFollowUpActions() {
      this.isCompletionCommentVisibleForId = true
      this.completeAll = true
    },
    async handleCompleteWithComment(comment) {
      if (this.completeAll === true) {
        this.isCompletionCommentVisibleForId = null
        await this.completeAllActions(comment)
        this.completeAll = false
        return
      }
      const actionId = this.isCompletionCommentVisibleForId
      this.isCompletionCommentVisibleForId = null
      await this.completeAction(actionId, comment)
    },
    async completeAction(actionId, comment) {
      await this.completeFollowUpAction({
        formSubmissionId: this.formSubmissionId,
        followUpActionId: actionId,
        comment,
      })

      if (this.error) {
        this.setSnackbarData({
          type: 'error',
          messageKey: 'failedToCompleteAction',
        })
      }
    },
    async completeAllActions(comment) {
      const actionsGuids = this.followUpActions.map((action) => action.id)
      await this.completeAllFollowUpActions({
        formSubmissionId: this.formSubmissionId,
        actionsGuids,
        comment,
      })

      if (this.error) {
        this.setSnackbarData({
          type: 'error',
          messageKey: 'failedToCompleteAllActions',
        })
      }
    },
    async handleAddCommentToSubmissionForm(comment) {
      await this.addCommentToFormSubmission({
        formSubmissionId: this.formSubmissionId,
        comment,
      })

      if (this.error) {
        this.setSnackbarData({
          type: 'error',
          messageKey: 'failedToSubmitComment',
        })
      }
    },
    checkForMissingKeys(properties, uiOrder) {
      return Object.keys(properties).filter((key) => !(key in uiOrder))
    },
    async downloadPdf() {
      this.isPdfDownloading = true

      try {
        saveFile(await getFormSubmissionPdf(this.formSubmissionId))
      } catch {
        this.setSnackbarData({
          type: 'error',
          messageKey: 'pdfDownloadError',
        })
      }

      this.isPdfDownloading = false
    },
    async likeForm() {
      await this.likeFormSubmission({
        formSubmissionId: this.formSubmissionId,
      })
      if (this.error) {
        this.setSnackbarData({
          type: 'error',
          messageKey: 'failedToSubmitLike',
        })
      }
    },
    async updateTags(tags) {
      await this.updateFormSubmissionTags({
        formSubmissionId: this.formSubmissionId,
        tags,
      })
      if (this.error) {
        this.setSnackbarData({
          type: 'error',
          messageKey: 'failedToSubmitTag',
        })
      }
    },
  },
}
</script>

<style scoped>
.fixed-height >>> .bottom-drawer-content {
  height: 60%;
}

.comments-list {
  margin-left: -28px;
}

.btn-text {
  text-align: center;
  color: var(--color-blue);
  font-weight: bold;
}
</style>
