
import { computed, defineComponent, ref, watchEffect } from 'vue'
import { Stage, usePoolStatusQuery } from '@/api/pools'
import { sortBy } from 'lodash'
import PoolStages from '@/components/tickets/PoolStages.vue'

export default defineComponent({
  name: 'PoolStatus',
  components: { PoolStages },

  props: {
    poolId: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const closed = ref(false)
    const { data, isLoading, refetch } = usePoolStatusQuery(props.poolId)

    const stages = computed<Stage[]>(() =>
      sortBy(data.value?.stages ?? [], 'sequence')
    )

    function onStageExpired() {
      refetch()
    }

    watchEffect(() => {
      if (
        !closed.value &&
        stages.value
          .flatMap((s) => s.availability)
          .every((ar) => ar.status !== 'AwaitingResponse')
      ) {
        closed.value = true
        emit('status:closed')
      }
    })

    return {
      stages,
      isLoading,
      onStageExpired,
    }
  },
})
