import { get, post, put, del } from './base'
import { currentTimezone } from '@/utils/date-formatting.js'
import FormSubmission from '@/models/form_submission'

const formSubmissionV3EndPoint = '/api/v3/FormSubmissions'
const formSubmissionV5Endpoint = '/api/v5/FormSubmissions'

export function getFormSubmission(id) {
  return get(`${formSubmissionV5Endpoint}/${id}`, {})
}

export function getFormSubmissionFilters() {
  return get(`${formSubmissionV3EndPoint}/filters`, {})
}

export function deleteFormSubmissionFilter(id) {
  return del(`${formSubmissionV3EndPoint}/filters/${id}`, {})
}

export function downloadFormSubmissionsCsv({
  startDate,
  endDate,
  includeForms,
  includeLocations,
  includeActions,
  includeTags,
  submittedBy,
  assignedTo,
  subject,
}) {
  return post(`${formSubmissionV3EndPoint}/csv`, {
    data: {
      start_date: startDate,
      end_date: endDate,
      include_forms: includeForms,
      include_locations: includeLocations,
      include_actions: includeActions,
      include_tags: includeTags,
      submitted_by: submittedBy,
      assigned_to: assignedTo,
      subject,
      timezone: currentTimezone(),
    },
    config: {
      responseType: 'blob',
    },
  })
}

export function searchFormSubmissions({
  size,
  after,
  startDate,
  endDate,
  includeForms,
  includeLocations,
  includeActions,
  includeTags,
  submittedBy,
  assignedTo,
  subject,
}) {
  return post(`${formSubmissionV5Endpoint}/search`, {
    data: {
      size,
      after,
      start_date: startDate,
      end_date: endDate,
      include_forms: includeForms,
      include_locations: includeLocations,
      include_actions: includeActions,
      include_tags: includeTags,
      submitted_by: submittedBy,
      assigned_to: assignedTo,
      subject,
    },
  })
}

export function searchFormSubmissionsCount({
  startDate,
  endDate,
  includeForms,
  includeLocations,
  includeActions,
  includeTags,
  submittedBy,
  assignedTo,
  subject,
}) {
  return post(`${formSubmissionV3EndPoint}/search/count`, {
    data: {
      start_date: startDate,
      end_date: endDate,
      include_forms: includeForms,
      include_locations: includeLocations,
      include_actions: includeActions,
      include_tags: includeTags,
      submitted_by: submittedBy,
      assigned_to: assignedTo,
      subject,
    },
  })
}

export function postFormSubmission(formSubmissionRequest) {
  return post(`${formSubmissionV5Endpoint}`, {
    data: formSubmissionRequest,
  })
}

export function postFormSubmissionFilter({ name, filter }) {
  return post(`${formSubmissionV3EndPoint}/filters`, {
    data: {
      name,
      filter,
    },
  })
}

export function deleteFormSubmission(id) {
  return del(`${formSubmissionV3EndPoint}/${id}`)
}

export function putFormSubmission(id, { formData, version }) {
  return put(`${formSubmissionV3EndPoint}/${id}`, {
    maxRedirects: 0,
    withCredentials: true,
    data: {
      fields: formData,
      version,
    },
  })
}

export function putFormSubmissionAssignment(id, assigneeId, assigneeType) {
  const body = {}

  if (assigneeType === FormSubmission.ASSIGNMENT_ENTITY_TYPE_BUSINESS_UNIT) {
    body.business_unit_id = assigneeId
  } else if (assigneeType === FormSubmission.ASSIGNMENT_ENTITY_TYPE_USER) {
    body.user_sid = assigneeId
  }

  return put(`${formSubmissionV3EndPoint}/${id}/assignment`, {
    data: body,
  })
}

export function putFollowUpActionCompletion(
  formSubmissionId,
  followUpActionId,
  { comment, commentUUID, completedAt }
) {
  return put(
    `${formSubmissionV3EndPoint}/${formSubmissionId}/actions/${followUpActionId}`,
    {
      data: {
        comment,
        comment_uuid: commentUUID,
        completed_at: completedAt,
      },
    }
  )
}

export function putAllFollowUpActionsCompletion(
  formSubmissionId,
  { comment, commentUUID, completedAt }
) {
  return put(
    `${formSubmissionV3EndPoint}/${formSubmissionId}/actions/completeAll`,
    {
      maxRedirects: 0,
      withCredentials: true,
      data: {
        comment,
        comment_uuid: commentUUID,
        completed_at: completedAt,
      },
    }
  )
}

export function postFormSubmissionComment(
  formSubmissionId,
  { comment, commentUUID, completedAt }
) {
  return post(`${formSubmissionV3EndPoint}/${formSubmissionId}/comments`, {
    data: {
      comment,
      uuid: commentUUID,
      completed_at: completedAt,
    },
  })
}

export function putFormSubmissionFilter(id, { name, filter }) {
  return put(`${formSubmissionV3EndPoint}/filters/${id}`, {
    data: {
      name,
      filter,
    },
  })
}

export function getFormSubmissionPdf(id) {
  return get(`${formSubmissionV3EndPoint}/${id}/pdf`, {
    params: {
      timezone: currentTimezone(),
    },
    responseType: 'blob',
  })
}

export function putFormSubmissionLike(formSubmissionId, { occurredAt }) {
  return put(`${formSubmissionV3EndPoint}/${formSubmissionId}/like`, {
    data: {
      occurred_at: occurredAt,
    },
  })
}

export function putFormSubmissionTags(formSubmissionId, { tags }) {
  return put(`${formSubmissionV3EndPoint}/${formSubmissionId}/tags`, {
    data: {
      tags,
    },
  })
}
