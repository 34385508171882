<template>
  <div v-if="job.isOverdue">
    <v-tooltip bottom :color="overdueColor">
      <template #activator="{ on }">
        <strong :class="overdueTextColor" v-on="on">{{ dateValue }}</strong>
      </template>
      <span>Overdue</span>
    </v-tooltip>
  </div>

  <div v-else>
    {{ dateValue }}
  </div>
</template>

<script>
import { formatDateTime, formatDurationAsHours } from '@/utils/date-formatting'
import moment from 'moment'

export default {
  name: 'StartTime',
  props: {
    job: {
      type: Object,
      default: null,
    },
  },
  computed: {
    overdueColor() {
      return this.job.isOverdue ? 'orange darken-2' : 'red'
    },
    overdueTextColor() {
      return this.job.isOverdue ? 'orange--text' : 'red--text'
    },
    dateValue() {
      const duration = this.job.estimatedDuration
        ? ` • ${formatDurationAsHours(
            moment.duration(
              moment(this.job.endTime).diff(moment(this.job.startTime))
            )
          )}`
        : ''
      return `${dateFormatter(this.job.startTime)}${duration}`
    },
  },
}

const dateFormatter = (value) => value && formatDateTime(value)
</script>
