<template>
  <vs-wrapper space="small">
    <div class="mb-5 d-flex justify-space-between align-center">
      <vs-heading>{{ field.fieldTypeTitle }} Properties</vs-heading>
      <vs-button
        destructive
        type="secondary"
        label="Delete Field"
        :disabled="hasDependencies"
        :class="{ 'greyed-out': hasDependencies }"
        @click="removeField(field)"
      />
    </div>
    <vs-wrapper v-if="isTitleAndDescriptionVisible" space="small">
      <vs-text-input
        v-model.lazy="field.title"
        :value="field.title"
        label="Title"
        @input="updateTitle"
      />
      <vs-text-input
        v-model.lazy="field.description"
        :value="field.description"
        label="Description"
        @input="updateDescription"
      />
    </vs-wrapper>
    <v-switch
      v-if="!hideRequiredToggle"
      inset
      label="Required"
      :input-value="field.isRequired"
      :value="field.isRequired"
      :disabled="isRequiredToggleDisabled"
      @change="updateFieldIsRequired"
    ></v-switch>
  </vs-wrapper>
</template>

<script>
import VsTextInput from '@/components/vision/Inputs/VsTextInput'
import VsWrapper from '@/components/vision/VsWrapper'
import VsHeading from '@/components/vision/VsHeading'
import VsButton from '@/components/vision/VsButton'
import * as fb from 'ironsight-form-builder'

export default {
  name: 'CoreFieldSettings',
  components: { VsButton, VsHeading, VsTextInput, VsWrapper },

  props: {
    field: {
      type: Object,
      default: () => {},
    },
    builder: {
      type: Object,
      required: false,
      default: () => {},
    },
    isRequiredToggleVisible: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isTitleAndDescriptionVisible() {
      return ![fb.MarkdownFieldDefinition, fb.ReferenceField].some(
        (field) => this.field instanceof field
      )
    },
    hideRequiredToggle() {
      return (
        [
          fb.HeadingFieldDefinition,
          fb.ArrayFieldDefinition,
          fb.MarkdownFieldDefinition,
          fb.ObjectFieldDefinition,
          fb.FormButtonsDefinition,
          fb.ReferenceField,
        ].some((field) => this.field instanceof field) ||
        !this.isRequiredToggleVisible
      )
    },
    isRequiredToggleDisabled() {
      return [
        fb.SubjectFieldDefinition,
        fb.SubjectLocationFieldDefinition,
      ].some((field) => this.field instanceof field)
    },
    hasDependencies() {
      return this.builder
        ? this.builder.immediateConstDependenciesOfField(this.field).length > 0
        : false
    },
  },
  methods: {
    updateTitle(title) {
      this.emitChanges('title', title)
    },
    updateDescription(description) {
      this.emitChanges('description', description)
    },
    updateFieldIsRequired(value) {
      this.emitChanges('isRequired', value !== null)
    },
    removeField() {
      this.$emit('remove-field', this.field)
    },
    emitChanges(propertyKey, value) {
      this.$emit('modify-field', {
        propertyKey,
        fieldValue: value,
        key: this.field.uniqueKey(),
      })
    },
  },
}
</script>

<style scoped>
.greyed-out {
  opacity: 0.5;
  pointer-events: none;
}
</style>
