import { mapGetters } from 'vuex'
import { ORGANIZATION, HUB } from '@/models/business-unit'

export default {
  computed: {
    ...mapGetters({
      getHierarchyLevel: 'businessUnits/getHierarchyLevel',
      getCompanyByOrganizationId: 'companies/getByOrganizationId',
    }),
    businessUnit() {
      return null
    },
    getBusinessUnitPath() {
      const bu = this.businessUnit

      if (bu) {
        if (bu.type === HUB) return bu.name

        const ownerBu = this.getHierarchyLevel(bu.id, ORGANIZATION)

        if (ownerBu && ownerBu.id !== bu.id) {
          if (ownerBu.type === ORGANIZATION) {
            const company = this.getCompanyByOrganizationId(ownerBu.id)

            if (!company) return ''
            return `${company.shortCode} > ${bu.name}`
          }
          return `${ownerBu.name} > ${bu.name}`
        }
        if (bu.type === ORGANIZATION) {
          const company = this.getCompanyByOrganizationId(bu.id)

          if (!company) return ''
          return company.shortCode
        }
        return bu.name
      }
      return ''
    },
  },
}
