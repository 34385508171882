<template>
  <vs-wrapper>
    <v-col class="preview-items">
      <v-row v-if="shouldDisplayPreviewText" class="mb-1">
        <vs-text type="caption">{{ previewItemsText }}</vs-text>
      </v-row>
    </v-col>
  </vs-wrapper>
</template>

<script>
export default {
  name: 'FormSubmissionsFilterSelectionPreview',
  props: {
    items: {
      type: Array[Object],
      default: () => [],
      required: false,
    },
    selected: {
      type: Array[Number],
      default: () => [],
      required: false,
    },
  },
  computed: {
    previewItemsText() {
      const itemsToDisplay = this.selected.slice(0, 3)
      const namesOfItemsToDisplay = itemsToDisplay.map(
        (item) => item.text ?? item.name ?? item
      )
      if (this.totalSelectedItems > 3)
        namesOfItemsToDisplay.push(`+${this.totalSelectedItems - 3} more`)

      return namesOfItemsToDisplay.join(', ')
    },
    shouldDisplayPreviewText() {
      return this.totalSelectedItems > 0
    },
    totalSelectedItems() {
      return this.selected.length
    },
  },
}
</script>

<style scoped>
.preview-items {
  font-size: 12px;
  color: var(--color-grey--light);
  font-family: Lato, sans-serif;
  font-style: italic;
  margin-bottom: -10px;
}
.more-items {
  font-size: 12px;
  color: var(--color-grey--light);
  font-family: Lato, sans-serif;
}
</style>
