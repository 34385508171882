
import { computed, defineComponent, toRef } from 'vue'
import { useBusinessUnitQuery } from '@/api/business-units'

export default defineComponent({
  name: 'JobOwner',

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const divisionId = toRef(props, 'id')
    const divisionQuery = useBusinessUnitQuery(divisionId)

    const hubId = computed(() => divisionQuery.data.value?.owner_id)
    const hubQuery = useBusinessUnitQuery(hubId)

    const organizationId = computed(() => hubQuery.data.value?.owner_id)
    const organizationQuery = useBusinessUnitQuery(organizationId)

    const jobOwner = computed(() =>
      [
        organizationQuery.data.value?.name,
        hubQuery.data.value?.name,
        divisionQuery.data.value?.name,
      ]
        .filter(Boolean)
        .join(' > ')
    )

    return {
      jobOwner,
    }
  },
})
