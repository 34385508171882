<template>
  <v-dialog
    :value="true"
    fullscreen
    hide-overlay
    transition="dialog-top-transition"
  >
    <div class="welcome">
      <div class="form">
        <p class="form-message">
          🤔 We couldn't find an organization for {{ $auth.tenant }}
        </p>

        <form method="post" @submit.prevent="createOrganization">
          <p>Create a new organization</p>

          <input v-model="orgName" type="text" required placeholder="Name" />

          <input type="submit" :value="submitButtonLabel" />
        </form>
      </div>
      <div class="splash">
        <div class="profile">
          <v-avatar class="mr-4" color="grey darken-1" size="48">
            <img :src="$auth.user.picture" :alt="$auth.user.nickname" />
          </v-avatar>
          <div class="account">
            <span>{{ $auth.user.email }}</span>
            <span
              ><v-chip x-small>tenant:{{ $auth.tenant }}</v-chip>
              <router-link to="/logout">Logout</router-link></span
            >
          </div>
        </div>
        <div class="message">
          <p class="loud">Hey {{ $auth.user.nickname }}, Let's get to work</p>
        </div>
      </div>

      <v-dialog v-model="saving" persistent width="600">
        <v-card color="primary" dark>
          <v-card-text>
            👨‍💻 Coming right up
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { createTenant } from '@/api/tenants'

export default {
  name: 'Welcome',

  beforeRouteEnter(to, from, next) {
    return next((vm) => {
      // prevent navigation directly to this route
      if (vm.organizationLoading) {
        vm.$router.push('/')
      }
    })
  },

  data() {
    return {
      orgName: '',
      submitButtonLabel: 'Create',
      saving: false,
      error: null,
    }
  },

  computed: {
    ...mapState('organizationConfig', {
      organizationLoading: (state) => state.loading,
    }),
  },

  methods: {
    async createOrganization() {
      try {
        this.saving = true
        await createTenant({ slug: this.$auth.tenant, name: this.orgName })
        await this.$auth.getTokenSilently({ ignoreCache: true })
        await this.$auth.setUser()

        return this.$router.replace('/')
      } catch (e) {
        this.error = e
      } finally {
        this.saving = false
      }
    },
  },
}
</script>

<style scoped>
.welcome {
  font-family: var(--font-display);
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: 2fr 3fr;
  background-color: var(--color-white);
}

.splash {
  display: flex;
  flex-direction: column;
  background-color: var(--color-black);
  color: var(--color-white);
  padding: 1rem;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: var(--font-regular);
  padding: 4rem;
}

form {
  display: flex;
  flex-direction: column;
}

input[type='text'] {
  font-family: var(--font-display);
  border: 1px solid var(--color-black);
  border-radius: 1em;
  width: 100%;
  padding-left: 0.5em;
  font-size: 2rem;
}

input[type='submit'] {
  font-family: var(--font-display);
  font-weight: 700;
  font-size: 1.5rem;
  padding: 0.5em;
  margin-top: 1rem;
  color: var(--color-white);
  border-radius: 1.5em;
  background-color: var(--color-blue--dark);
  box-shadow: 0 0.2em 0 0 var(--color-blue--light);
  align-self: flex-end;
  transition: background-color 300ms, box-shadow 500ms;
}

input[type='submit']:hover {
  background-color: var(--color-blue);
  box-shadow: 0 0.2em 0 0 var(--color-blue--lighter);
}

.form-message {
  font-size: 48px;
  padding-bottom: 2rem;
}

.profile {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2rem;
  font-weight: 700;
  font-size: 17px;
}

.message {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.loud {
  font-weight: 900;
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
  font-size: 92px;
  line-height: 1;
  margin: 2rem;
}

.account {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
