export default class BaseObject {
  id: null | number = null
  name: string | null = null
  modified: string | null = null

  constructor(json) {
    this.id = json.id
    this.name = json.name
    this.modified = json.modified || null
  }
}
