export interface AssetCondition {
  id: number
  name: string
  selected: boolean
  disabled?: boolean
}

export default class Inventory {
  constructor() {
    this.id = null
    this.inventoryId = null
    this.name = null
    this.assetConditions = []
    this.quantity = null
    this.inventoryTypeId = null
    this.inventorySubTypeId = null
    this.inventorySubTypeName = null
    this.pickupLocationId = null
    this.pickupLocationName = null
  }

  id: string | number | null
  inventoryId: number | null
  name: string | null
  assetConditions: AssetCondition[] | null
  quantity: number | null
  inventoryTypeId: number | null
  inventorySubTypeId: number | null
  inventorySubTypeName: string | null
  pickupLocationId: number | null
  pickupLocationName: string | null
}

/**
 * When explicit ordering of each line item is required.
 */
export class PositionedInventory extends Inventory {
  constructor() {
    super()
    this.position = -1
  }

  position: number
}
