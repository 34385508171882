"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FlowEntryNode = void 0;
const node_1 = require("./node");
class FlowEntryNode extends node_1.NodeBase {
    constructor(flowKey) {
        super();
        this.flowKey = flowKey;
    }
}
exports.FlowEntryNode = FlowEntryNode;
