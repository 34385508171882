import { SnakeCasedPropertiesDeep } from 'type-fest/source/snake-cased-properties-deep'

export interface IJobChange {
  columnName: string
  isImportant: boolean
  originalValue: any
  newValue: any
}

export default class JobChange implements IJobChange {
  columnName: string
  isImportant: boolean
  originalValue: any
  newValue: any

  constructor(json: SnakeCasedPropertiesDeep<IJobChange>) {
    this.columnName = json.column_name
    this.originalValue = json.original_value
    this.newValue = json.new_value
    this.isImportant = json.is_important
  }
}
