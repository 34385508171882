export class InventoryQuantityChangeModel {
  assetConditionId: null | number
  quantityChange: null | number
  constructor() {
    this.assetConditionId = null
    this.quantityChange = 0
  }
}

export default class InventoryAdjustment {
  locationId: null | number
  assetId: null | number
  quantityChanges: Array<InventoryQuantityChangeModel>
  reason: null | string
  constructor() {
    this.locationId = null
    this.assetId = null
    this.quantityChanges = [new InventoryQuantityChangeModel()]
    this.reason = null
  }

  addQuantityChange() {
    this.quantityChanges.push(new InventoryQuantityChangeModel())
  }

  removeQuantityChangeAtIndex(index: number) {
    this.quantityChanges.splice(index, 1)
  }

  resetQuantityChange() {
    this.quantityChanges = [new InventoryQuantityChangeModel()]
  }

  get hasAssetAndLocation(): boolean {
    return this.locationId !== null && this.assetId !== null
  }
}
