<template>
  <v-chip color="red" text-color="white" small :class="className">
    {{ unreadCount }}
  </v-chip>
</template>

<script>
export default {
  name: 'UnreadCount',
  props: {
    unreadCount: {
      type: [String, Number],
      default: null,
    },
    tab: Boolean,
  },
  computed: {
    className() {
      return this.tab
        ? 'tab-unread-messages-count'
        : 'channel-unread-messages-count'
    },
  },
}
</script>

<style>
.channel-unread-messages-count {
  margin-right: 0px;
}

.tab-unread-messages-count {
  font-size: 14px;
}
</style>
