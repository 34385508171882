"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoToFlowArgs = exports.GoToFlowModel = void 0;
const json_schema_keys_1 = require("../../json_schema_keys");
const then_condition_model_1 = require("./then_condition_model");
class GoToFlowModel extends then_condition_model_1.ThenConditionModel {
    constructor(args) {
        super();
        this.type = json_schema_keys_1.JsonSchemaKeys.ACTION_TYPE_GO_TO_FLOW;
        this.name = json_schema_keys_1.JsonSchemaKeys.ACTION_NAME_GO_TO_FLOW;
        this.args = args;
    }
}
exports.GoToFlowModel = GoToFlowModel;
class GoToFlowArgs extends then_condition_model_1.ThenConditionArgs {
    constructor(name, page) {
        super();
        this.name = name;
        this.page = page;
    }
}
exports.GoToFlowArgs = GoToFlowArgs;
