<template>
  <vs-dialog
    confirm-on-close
    :title="title"
    :visible="visible"
    @on-close="$emit('toggle-dialog')"
  >
    <vs-wrapper space="small">
      <vs-text v-if="requiredValueIsSelected">
        If field "<b class="emphasis">{{ getFieldLabel(field) }} </b>" is "<b
          class="emphasis"
          >{{ getDisplayValue(requiredValue) }}</b
        >"
      </vs-text>
      <vs-text v-else>
        If field
        <b class="emphasis">{{ getFieldLabel(field) }}</b> matches the answer
        below
      </vs-text>

      <component
        :is="requiredValueEditorComponent"
        v-bind="currentProperties"
        @change="handleRequiredValueChange"
        @input="handleRequiredValueChange"
      ></component>

      <vs-text
        >The selected fields below will appear if condition is met
      </vs-text>

      <vs-autocomplete
        multiselect
        label="Select dependent fields"
        :items="fieldItems"
        :value="selectedDependentFields"
        @input="handleInput"
      ></vs-autocomplete>

      <vs-text v-if="errors.length > 0" class="error-message"
        >{{ errorMessage }}
      </vs-text>

      <div class="d-flex justify-end">
        <vs-button
          type="secondary"
          label="Cancel"
          class="mr-2"
          @click="$emit('toggle-dialog')"
        ></vs-button>
        <vs-button label="Save" @click="handleSave"></vs-button>
      </div>
    </vs-wrapper>
  </vs-dialog>
</template>

<script>
import VsDialog from '@/components/vision/VsDialog'
import VsWrapper from '@/components/vision/VsWrapper'
import VsText from '@/components/vision/VsText'
import VsAutocomplete from '@/components/vision/Inputs/VsAutocomplete/VsAutocomplete'
import VsButton from '@/components/vision/VsButton'
import { VCheckbox, VSelect } from 'vuetify/lib'
import { ConstDependencyDTO } from 'ironsight-form-builder/dist/models/dependencies/const_dependency_dto'
import formFieldTitle from '@/utils/forms/form-field-title'
import * as fb from 'ironsight-form-builder'

export default {
  name: 'AddDependencyDialog',
  components: {
    VsButton,
    VsAutocomplete,
    VsText,
    VsWrapper,
    VsDialog,
    VCheckbox,
    VSelect,
  },

  props: {
    builder: {
      type: Object,
      default: () => {},
    },
    field: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: 'Add Dependency',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    requiredValueEditorComponent: {
      type: String,
      default: '',
    },
    currentProperties: {
      type: Object,
      default: () => {},
    },
    fieldItems: {
      type: Array,
      default: () => [],
    },
  },

  data: () => {
    return {
      selectedFields: [],
      errors: [],
      requiredValue: undefined,
    }
  },

  computed: {
    selectedDependentFields() {
      return this.selectedFields.map((field) => field.uniqueKey())
    },
    requiredValueIsSelected() {
      return this.requiredValue != null
    },
    fields() {
      return this.builder.fieldsSelectableDependencies(this.field)
    },
    dtoFromFieldInputs() {
      return new ConstDependencyDTO(
        this.field,
        this.requiredValue,
        this.selectedFields
      )
    },
    errorMessage() {
      return this.errors.join('\n')
    },
  },
  methods: {
    getDisplayValue(value) {
      const checkField =
        this.field instanceof fb.ReferenceField
          ? this.field.reference
          : this.field

      if (checkField instanceof fb.BooleanFieldDefinition) {
        if (value === true) {
          if (checkField.isRadio) {
            return 'Yes'
          }
          return 'Checked'
        } else if (value === false) {
          if (checkField.isRadio) {
            return 'No'
          }
          return 'Unchecked'
        }
      }

      return value
    },
    getFieldLabel(field) {
      return formFieldTitle(field)
    },
    handleRequiredValueChange(value) {
      const checkField =
        this.field instanceof fb.ReferenceField
          ? this.field.reference
          : this.field

      if (checkField instanceof fb.BooleanFieldDefinition) {
        this.requiredValue = value
      } else if (checkField instanceof fb.NumberFieldDefinition) {
        this.requiredValue = this.field.allowDecimals
          ? parseFloat(value)
          : parseInt(value)
      } else {
        this.requiredValue = value
      }

      this.$emit('required-value-change', this.requiredValue)
    },
    handleInput(value) {
      this.selectedFields = value
        .map((uniqueKey) =>
          this.fields.find((field) => field.uniqueKey() === uniqueKey)
        )
        .filter((field) => field)
    },
    handleSave() {
      this.errors = this.builder.validateConstDependencyDTO(
        this.dtoFromFieldInputs,
        false
      )

      if (this.errors.length === 0) {
        this.builder.addConstDependencyToField(this.dtoFromFieldInputs)
        this.$emit('toggle-dialog')
      }
    },
  },
}
</script>

<style scoped>
.emphasis {
  color: var(--color-blue);
}

.error-message {
  color: var(--color-red);
  margin-bottom: var(--space-smaller);
}
</style>
