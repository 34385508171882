import { isEqual } from 'lodash'
import { createService, getServices } from '../../api/services'
import Service from '../../models/service'
import { errorMutationHandler } from '../utils'

export const LOADING_SERVICE = 'LOADING_SERVICE'
export const LOADING_SERVICES = 'LOADING_SERVICES'
export const RECEIVE_SERVICE = 'RECEIVE_SERVICE'
export const RECEIVE_SERVICES = 'RECEIVE_SERVICES'
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const ERROR_SERVICES = 'ERROR_SERVICES'

const state = () => {
  return {
    all: {},
    loading: false,
    error: null,
    saving: false,
    newCreatedService: null,
  }
}

const getters = {
  getAllServices: (state) => state.all,
  getById: (state) => (id) => state.all[id] || null,
  getNewService: (state) => state.newCreatedService,
}

const actions = {
  fetchServices({ commit }) {
    commit(LOADING_SERVICES)

    return getServices()
      .then((res) => commit(RECEIVE_SERVICES, res))
      .catch((err) => commit(ERROR_SERVICES, err))
  },

  createService({ commit }, service) {
    commit(LOADING_SERVICE)

    return createService(service)
      .then((res) => commit(RECEIVE_SERVICE, res))
      .catch((err) => commit(ERROR_SERVICES, err))
  },
}

const mutations = {
  [LOADING_SERVICES](state) {
    state.loading = true
  },
  [LOADING_SERVICE](state) {
    state.saving = true
    state.newCreatedService = null
    state.error = null
  },
  [RECEIVE_SERVICES](state, { data }) {
    const receivedServices = {}

    data.forEach((json) => {
      receivedServices[json.id] = new Service(json)
    })

    if (!isEqual(receivedServices, state.all)) {
      state.all = receivedServices
    }

    state.loading = false
    state.error = null
  },
  [RECEIVE_SERVICE](state, { data }) {
    state.all = Object.freeze({ ...state.all, [data.id]: data })
    state.newCreatedService = state.all[data.id]
    state.saving = false
  },
  [ERROR_SERVICES](state, error) {
    state.saving = false
    state.loading = false
    errorMutationHandler(state, error)
  },
  [CLEAR_ERROR](state) {
    state.error = null
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
