var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.FormLayout,{attrs:{"title":"Set Job Time"},on:{"on-dismiss":_setup.dismiss},scopedSlots:_vm._u([{key:"snack-bar-wrapper",fn:function(){return [_c('vs-snackbar',{attrs:{"sticky":"","type":"error"},model:{value:(_setup.data.isSnackbarVisible),callback:function ($$v) {_vm.$set(_setup.data, "isSnackbarVisible", $$v)},expression:"data.isSnackbarVisible"}},[_vm._v(" Failed to update job details ")])]},proxy:true}])},[_c('vs-wrapper',{attrs:{"space":"small"}},[_c('vs-heading',{staticClass:"d-flex heading-overline",attrs:{"type":"overline"}},[_vm._v("Planned ")]),_c('vs-date-picker',{key:`${_setup.props.job.Id}-start-time`,staticClass:"date-picker",attrs:{"can-select-past":true,"rules":[
        () => _setup.showStartTimeWarning && 'Start cannot be after end',
        () => isNaN(_setup.data.startTime.valueOf()) && 'This field is required',
      ],"value":_setup.data.startTime,"data-test":"planned-start-time","label":"Start","required":""},on:{"input":_setup.handleStartTimeChange}}),_c('vs-date-picker',{key:`${_setup.props.job.id}-end-time`,staticClass:"date-picker",attrs:{"can-select-past":true,"rules":[
        () => _setup.showEndTimeWarning && 'End cannot be before start',
        () => isNaN(_setup.data.endTime.valueOf()) && 'This field is required',
      ],"value":_setup.data.endTime,"data-test":"planned-end-time","label":"End","required":""},on:{"input":_setup.handleEndTimeChange}}),_c('vs-text',{staticClass:"duration-line",attrs:{"type":"caption"}},[_vm._v(_vm._s(_setup.plannedDurationLabel)+" ")]),_c('v-spacer'),_c('vs-heading',{staticClass:"heading-overline",attrs:{"type":"overline"}},[_vm._v(_vm._s(_setup.completionLabel)+" ")]),_c('vs-date-picker',{key:`${_setup.props.job.Id}-activated-time`,staticClass:"date-picker",attrs:{"can-select-past":true,"rules":[
        () =>
          _setup.showActivatedTimeWarning && 'Activated cannot be after completed',
        () => isNaN(_setup.data.activeTime.valueOf()) && 'This field is required',
      ],"value":_setup.data.activeTime,"data-test":"actual-active-time","label":"Activated","required":""},on:{"input":_setup.handleActivatedTimeChange}}),_c('vs-date-picker',{key:`${_setup.props.job.id}-completed-time`,staticClass:"date-picker",attrs:{"can-select-past":true,"rules":[
        () =>
          _setup.showCompletedTimeWarning && 'Completed cannot be before activated',
        () => isNaN(_setup.data.completedTime.valueOf()) && 'This field is required',
      ],"value":_setup.data.completedTime,"data-test":"actual-completed-time","label":"Completed","required":""},on:{"input":_setup.handleCompletedTimeChange}}),_c('vs-text',{staticClass:"duration-line",attrs:{"type":"caption"}},[_vm._v(_vm._s(_setup.activeDurationLabel)+" ")]),_c('v-spacer'),_c('v-row',{attrs:{"no-gutters":""}},[_c('vs-button',{attrs:{"disabled":_setup.saveButtonDisabled,"loading":_setup.data.saving,"full-width":"","label":"Save"},on:{"click":_setup.save}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }