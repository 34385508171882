import RoleAssignment from '@/models/role-assignment'
import UserAssociatedBusinessUnit from '@/models/user-associated-business-unit'
export default class CurrentUserContext {
  constructor(json) {
    this.userSid = json.user_sid
    this.name = json.user_details.name
    this.email = json.user_details.email
    this.roleAssignments = json.role_assignments
      ? json.role_assignments.map((ra) => new RoleAssignment(ra))
      : []
    this.lineage = json.lineage
      ? json.lineage.map((bu) => new UserAssociatedBusinessUnit(bu))
      : []
    this.orgManagerId = json.org_manager_id
  }

  userSid: string
  name: string
  email: string
  roleAssignments: RoleAssignment[]
  lineage: UserAssociatedBusinessUnit[]
  orgManagerId: string | null

  get displayedRole(): string {
    const roles = this.roleAssignments.map((ra) => {
      return ra.role.replace(/([a-z])([A-Z])/g, '$1 $2')
    })

    const uniqueRoles = [...new Set(roles)]
    return uniqueRoles.join('/')
  }
}
