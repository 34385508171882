<template>
  <vs-wrapper>
    <vs-heading
      v-if="title"
      data-test="form-button-heading"
      type="subhead"
      :class="{ 'has-error': hasError }"
      >{{ title }}
    </vs-heading>
    <div v-if="description" class="hint">
      <vs-text type="subdued">{{ description }}</vs-text>
    </div>
    <vs-button
      v-for="button in schema.buttons"
      :key="button.value"
      data-test="form-button"
      class="form-button"
      :label="button.label"
      :type="getButtonType(button.value)"
      :icon="getButtonIcon(button.value)"
      :full-width="true"
      :disabled="disabled"
      @click="onChange(button.value)"
    />
    <transition-group name="invalid">
      <div
        v-for="(message, index) in errorMessages"
        :key="index + message"
        class="error-message"
      >
        {{ message }}
      </div>
    </transition-group>
  </vs-wrapper>
</template>

<script>
export default {
  name: 'FormButtonsField',
  props: {
    errorSchema: {
      type: Object,
      default: () => {},
    },
    formData: {
      type: [String, Number],
      default: null,
    },
    params: {
      type: Object,
      default: () => {},
    },
    rootSchema: {
      type: Object,
      default: () => {},
    },
    schema: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    uiSchema: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    errorMessages() {
      const errors = this.errorSchema?.errors ?? []
      if (
        this.formData &&
        typeof this.formData !== 'number' &&
        typeof this.formData !== 'string'
      ) {
        errors.push('Field value expected.')
      }
      return errors
    },
    hasError() {
      return this.errorMessages.length > 0
    },
  },
  methods: {
    getButtonIcon(value) {
      if (this.isButtonSelected(value)) return 'check'
      return ''
    },
    getButtonType(value) {
      if (this.isButtonSelected(value)) return 'primary'
      return 'tertiary'
    },
    isButtonSelected(value) {
      return value === this.formData
    },
    onChange(value) {
      if (this.isButtonSelected(value)) {
        this.$emit('change', null)
      } else {
        this.$emit('change', value)
      }
    },
  },
}
</script>

<style scoped>
/* Invalid transition
   ------------------------------------------------------------------------- */
.invalid-enter-active {
  animation: shake 800ms cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

.invalid-leave-active {
  position: absolute;
}

.invalid-move {
  transition: transform 2s;
}

.invalid-enter-active,
.invalid-leave-active {
  transform: scale(1);
  transition: all 100ms;
  will-change: transform, opacity;
}

.invalid-enter,
.invalid-leave-to {
  transform: scale(0.8);
  opacity: 0;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.has-error {
  color: var(--color-red);
}

.error-message {
  color: var(--color-red);
  padding: 0 var(--space-base);
  margin-bottom: var(--space-smaller);
}

.error-message:last-of-type {
  margin-bottom: 0;
}
</style>
