"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.enumStringFieldMatcher = void 0;
const enum_string_field_definition_1 = require("../../form_fields/enum_string_field_definition");
const json_schema_keys_1 = require("../../json_schema_keys");
const enumStringFieldMatcher = (uniqueKey, isRequired, properties, uiSchema, fieldDefinitions, next) => {
    const isDataTypeString = properties[json_schema_keys_1.JsonSchemaKeys.TYPE_KEY] === json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_STRING;
    const enumOptions = properties[json_schema_keys_1.JsonSchemaKeys.ENUM_KEY];
    if (isDataTypeString && enumOptions) {
        const field = new enum_string_field_definition_1.EnumStringFieldDefinition();
        field.title = properties.title;
        field.description = properties.description;
        field.isRequired = isRequired;
        field.selectableOptions = enumOptions;
        field.isRadio = uiSchema[json_schema_keys_1.JsonSchemaKeys.UI_WIDGET_KEY] === json_schema_keys_1.JsonSchemaKeys.WIDGET_TYPE_RADIO;
        field.defaultValue = properties.default;
        return field;
    }
    return next(uniqueKey, isRequired, properties, uiSchema, fieldDefinitions);
};
exports.enumStringFieldMatcher = enumStringFieldMatcher;
