import axios from 'axios'
import { get } from './base'
import { useQuery } from '@tanstack/vue-query'
import { computed, isRef, unref } from 'vue'
import moment from 'moment'

const endPoint = '/api/v3/schemas'

export function getJobCustomFieldsSchema() {
  const source = axios.CancelToken.source()

  return get(`${endPoint}/job-custom-data`, { cancelToken: source.token })
}

export function getExtensionSchema(extensionSchemaId) {
  const source = axios.CancelToken.source()

  return get(`${endPoint}/activities/versions/${extensionSchemaId}`, {
    cancelToken: source.token,
  })
}

export function getExtensionDataSchema(divisionId, activityId) {
  return get(`${endPoint}/divisions/${divisionId}/activities/${activityId}`)
}

export function useExtensionDataQuery(divisionId, activityId) {
  const enabled = computed(
    () =>
      !!(
        (!isRef(divisionId) || divisionId.value) &&
        (!isRef(activityId) || activityId.value)
      )
  )
  const { data } = useQuery({
    queryKey: ['schemas', activityId, divisionId],
    queryFn: () =>
      getExtensionDataSchema(unref(divisionId), unref(activityId)).then(
        (r) => r.data
      ),
    enabled,
    staleTime: moment.duration(10, 'minutes').asMilliseconds(),
  })

  return {
    data,
  }
}
