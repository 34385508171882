<template>
  <div class="aside">
    <vs-wrapper>
      <vs-button
        icon="chevron_left"
        label="Hide filters"
        class="pl-0"
        type="tertiary"
        small
        data-test="hide-filter"
        @click="$emit('hide-filter-aside')"
      />

      <form-submission-saved-filters @apply-filter="handleApplySavedFilter" />

      <v-divider />

      <filter-aside-date-range
        :start-date="dateRangeStart"
        :end-date="dateRangeEnd"
        data-test="date-filter"
        @change="handleDateRangeUpdate"
      >
        <template #default>
          <v-row dense justify="center" no-gutters class="selection-row">
            <v-col align-self="center" md="auto">
              <vs-text data-test="date-filter-label" center type="subdued">{{
                dateRangeLabel
              }}</vs-text>
            </v-col>
            <v-col v-if="hasDateRange" align-self="center" md="auto">
              <v-btn
                data-test="date-filter-clear"
                icon
                x-small
                @click="clearDateRange"
              >
                <v-icon small color="red">mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </filter-aside-date-range>

      <v-divider />

      <vs-wrapper>
        <form-submissions-filter-aside-section
          title="Business units & users"
          :items="getSubjects"
          :selected="getSubjectSearchIds"
          @show-panel="setVisiblePanel('Subjects')"
        >
          <template #item-preview="previewProps">
            <form-submissions-filter-selection-preview
              :items="previewProps.items"
              :selected="allSelectedSubjects"
            />
          </template>
          <template #toggle>
            <select-all-inverse-filter-items-toggle
              :itemsCount="getSubjects.length"
              :selectedCount="allSelectedSubjects.length"
              @toggle="resetSubjects"
            />
          </template>
        </form-submissions-filter-aside-section>

        <form-submissions-filter-aside-section
          title="Forms"
          :items="formNameOptions"
          :selected="selectedFormIds"
          data-test="form-name-filter"
          @show-panel="setVisiblePanel('Forms')"
        >
          <template #item-preview="previewProps">
            <form-submission-filter-inverse-selection-preview
              :items="previewProps.items"
              :selected="previewProps.selected"
            />
          </template>
          <template #toggle="toggleProps">
            <select-all-inverse-filter-items-toggle
              :itemsCount="toggleProps.items.length"
              :selectedCount="toggleProps.selected.length"
              @toggle="resetForms"
            />
          </template>
        </form-submissions-filter-aside-section>

        <form-submissions-filter-aside-section
          title="Action"
          :items="getFormActions"
          :selected="selectedFormActions"
          data-test="actions-filter"
          @show-panel="setVisiblePanel('Actions')"
        >
          <template #item-preview="previewProps">
            <form-submission-filter-inverse-selection-preview
              :items="previewProps.items"
              :selected="previewProps.selected"
            />
          </template>
          <template #toggle="toggleProps">
            <select-all-inverse-filter-items-toggle
              :itemsCount="toggleProps.items.length"
              :selectedCount="toggleProps.selected.length"
              @toggle="resetActions"
            />
          </template>
        </form-submissions-filter-aside-section>

        <form-submissions-filter-aside-section
          title="Locations"
          :selected="selectedLocations"
          data-test="form-name-filter"
          @show-panel="setVisiblePanel('Locations')"
        >
          <template #item-preview="previewProps">
            <form-submission-filter-inverse-selection-preview
              :selected="previewProps.selected"
              :value-resolver="locationValueResolver"
            />
          </template>
          <template #toggle>
            <select-all-inverse-filter-items-toggle
              :selectedCount="selectedLocations.length"
              @toggle="resetLocations"
            />
          </template>
        </form-submissions-filter-aside-section>
        <form-submissions-filter-aside-section
          title="Tags"
          :items="tagOptions"
          :selected="selectedTags"
          @show-panel="setVisiblePanel('Tags')"
        >
          <template #item-preview="previewProps">
            <form-submissions-filter-selection-preview
              :items="previewProps.items"
              :selected="previewProps.selected"
            />
          </template>
          <template #toggle>
            <select-all-inverse-filter-items-toggle
              :itemsCount="tagOptions.length"
              :selectedCount="selectedTags.length"
              @toggle="resetTags"
            />
          </template>
        </form-submissions-filter-aside-section>
      </vs-wrapper>
    </vs-wrapper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { useQueryClient } from '@tanstack/vue-query'
import { debounce } from 'lodash'
import moment from 'moment'
import { getById } from '@/api/locations'
import FilterAsideDateRange from '@/components/common/FilterAsideDateRange'
import FormSubmissionFilterAsideMixin from '@/components/forms/form-submission-filter-aside-mixin'
import businessUnitMenuItemsMixin from '@/components/forms/business-unit-menu-items-mixin'
import FormSubmissionsFilterAsideSection from '@/components/forms/FormSubmissionsFilterAsideSection'
import SelectAllFilterItemsToggle from '@/components/forms/SelectAllFilterItemsToggle'
import SelectAllInverseFilterItemsToggle from '@/components/forms/SelectAllInverseFilterItemsToggle'
import FormSubmissionSavedFilters from '@/components/forms/FormSubmissionSavedFilters'
import FormSubmissionsFilterSelectionPreview from '@/components/forms/FormSubmissionsFilterSelectionPreview'
import FormSubmissionFilterInverseSelectionPreview from '@/components/forms/FormSubmissionFilterInverseSelectionPreview'

export default {
  name: 'FormSubmissionFilterAside',
  components: {
    FormSubmissionSavedFilters,
    FilterAsideDateRange,
    FormSubmissionsFilterAsideSection,
    SelectAllFilterItemsToggle,
    SelectAllInverseFilterItemsToggle,
    FormSubmissionsFilterSelectionPreview,
    FormSubmissionFilterInverseSelectionPreview,
  },
  setup() {
    const client = useQueryClient()
    return { client }
  },
  mixins: [FormSubmissionFilterAsideMixin, businessUnitMenuItemsMixin],
  computed: {
    ...mapGetters({
      selectedLocations: 'formSubmissionsFilters/getInverseLocations',
      selectedTags: 'formSubmissionsFilters/getTags',
      selectedFormIds: 'formSubmissionsFilters/getInverseFormIds',
      selectedFormActions: 'formSubmissionsFilters/getInverseFormActions',
    }),
    allSelectedSubjects() {
      const allSelectedSubjects = []

      this.getSubjectSearchIds.forEach((selectedSubject) => {
        const selectedSubjectItem = this.getSubjects.find((subject) => {
          return (
            subject.value &&
            subject.value.businessUnitId === selectedSubject.businessUnitId &&
            subject.value.userId === selectedSubject.userId
          )
        })
        if (!selectedSubjectItem) return
        allSelectedSubjects.push(selectedSubjectItem)

        const selectedChildrenSubjectItems = !selectedSubjectItem.parent
          ? this.getSubjects.filter(
              (subject) => subject.parent === selectedSubjectItem.text
            )
          : []
        allSelectedSubjects.push(...selectedChildrenSubjectItems)
      })

      return allSelectedSubjects
    },
  },
  methods: {
    ...mapActions({
      setDateRange: 'formSubmissionsFilters/setDateRange',
      setVisiblePanel: 'formSubmissionsFilters/setVisiblePanel',
      resetDateRange: 'formSubmissionsFilters/resetDateRange',
      resetSubjectSearchIds: 'formSubmissionsFilters/resetSubjectSearchIds',
      resetInverseSelectedFormActions:
        'formSubmissionsFilters/resetInverseFormActions',
      resetInverseSelectedLocations:
        'formSubmissionsFilters/resetInverseLocations',
      resetSelectedTags: 'formSubmissionsFilters/resetTags',
      resetInverseSelectedFormIds: 'formSubmissionsFilters/resetInverseFormIds',
      setInverseSelectedLocations: 'formSubmissionsFilters/setInverseLocations',
      setSavedFilter: 'formSubmissionsFilters/setSavedFilter',
    }),
    ...mapGetters({
      getSelectedTags: 'formSubmissionsFilters/getTags',
    }),
    clearDateRange() {
      this.resetDateRange()
      this.emitFilter()
    },
    handleDateRangeUpdate(range) {
      this.setDateRange({
        start: range.start.startOf('day').toISOString(),
        end: range.end.endOf('day').toISOString(),
      })
      this.emitFilter()
    },
    emitFilter: debounce(function () {
      this.$emit('filter-changed')
    }, 500),
    resetSubjects() {
      this.resetSubjectSearchIds()
      this.emitFilter()
    },
    resetForms() {
      this.resetInverseSelectedFormIds()
      this.emitFilter()
    },
    resetActions() {
      this.resetInverseSelectedFormActions()
      this.emitFilter()
    },
    resetLocations() {
      this.resetInverseSelectedLocations()
      this.emitFilter()
    },
    resetTags() {
      this.resetSelectedTags()
      this.emitFilter()
    },
    handleApplySavedFilter(savedFilter) {
      this.setSavedFilter(savedFilter)
      this.emitFilter()
    },
    locationValueResolver(id) {
      return this.client
        .fetchQuery({
          queryKey: ['locations', id],
          queryFn: ({ signal }) => getById(id, signal),
          staleTime: moment.duration(10, 'minutes').asMilliseconds(),
        })
        .then((location) => {
          return { id: location.id, name: location.location }
        })
    },
  },
}
</script>

<style scoped>
.aside {
  width: 300px;
  height: 100%;
  padding: var(--space-small) var(--space-base);
  background-color: var(--color-white);
  border-right: 1px solid var(--color-grey--lightest);
  position: relative;
  z-index: 1;
  overflow: auto;
}

.selection-row {
  height: 24px;
}
</style>
