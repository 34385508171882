import axios from 'axios'
import { patch, post, put } from '../base'
import { useMutation } from '@tanstack/vue-query'
import LsdLocation from '@/models/location'

let locationsEndpointSource = axios.CancelToken.source()
const locationsEndPoint = '/api/v2/account-data/locations'

export function create(location: Partial<LsdLocation>) {
  locationsEndpointSource.cancel('Cancel old create location request')
  locationsEndpointSource = axios.CancelToken.source()
  return post(locationsEndPoint, {
    cancelToken: locationsEndpointSource.token,
    data: {
      access_business_unit_ids: location.accessBusinessUnitIds,
      color_id: location.colorId,
      description: location.description,
      latitude: location.latitude,
      location: location.location,
      location_type_id: location.locationTypeId,
      longitude: location.longitude,
      owner_id: location.ownerId,
      parent_id: location.parentId,
      radius: location.radius,
      custom_data: location.customData,
      private: location.private,
      inventory: location.inventory?.map((inventoryItem) => ({
        asset_condition_id: inventoryItem.assetConditionId,
        quantity: inventoryItem.quantity,
      })),
    },
  })
}

export function importLocations(request) {
  return post(`${locationsEndPoint}/import`, {
    data: request,
  })
}

export function update(location: Partial<LsdLocation>) {
  locationsEndpointSource.cancel('Cancel old edit location request')
  locationsEndpointSource = axios.CancelToken.source()

  return put(`${locationsEndPoint}/${location.id}`, {
    cancelToken: locationsEndpointSource.token,
    data: {
      access_business_unit_ids: location.accessBusinessUnitIds,
      color_id: location.colorId,
      description: location.description,
      location_type_id: location.locationTypeId,
      latitude: location.latitude,
      location: location.location,
      longitude: location.longitude,
      owner_id: location.ownerId,
      parent_id: location.parentId,
      radius: location.radius,
      custom_data: location.customData,
      is_archived: location.isArchived,
      private: location.private,
    },
  })
}

export function updateLocationDetails(id, statusId, description, customData) {
  return patch(`${locationsEndPoint}/${id}`, {
    data: {
      status_id: statusId,
      description,
      custom_data: customData,
    },
  })
}

export function archive(locationId: number) {
  return put(`${locationsEndPoint}/${locationId}/archive`, {
    cancelToken: locationsEndpointSource.token,
  })
}

export function unarchive(locationId: number) {
  return put(`${locationsEndPoint}/${locationId}/unarchive`, {
    cancelToken: locationsEndpointSource.token,
  })
}

export function useUpdateLocationMutation() {
  return useMutation({
    mutationFn: (variables: Partial<LsdLocation>) => update(variables),
  })
}

export function useCreateLocationMutation() {
  return useMutation({
    mutationFn: (variables: Partial<LsdLocation>) => create(variables),
  })
}

export function useArchiveLocationMutation() {
  return useMutation({
    mutationFn: (variables: number) => archive(variables),
  })
}

export function useUnarchiveLocationMutation() {
  return useMutation({
    mutationFn: (variables: number) => unarchive(variables),
  })
}
