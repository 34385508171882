<template>
  <vs-autocomplete
    ref="autocomplete"
    multiselect
    :disabled="disabled"
    label="Tags"
    empty-state-text="No existing tags yet. Start typing to create one."
    :value="value"
    :items="options"
    :search-value.sync="searchValue"
    @input="handleInput"
    @keydown.enter="handleHotKeys"
    @keydown.tab="handleHotKeys"
  >
    <template v-if="showCustomTagOption" #append-item>
      <v-divider class="mb-2" />
      <v-list-item data-test="create-tile" @click.stop="handleCustomAdd">
        <v-list-item-content>
          <vs-text>Create "{{ searchValue }}" tag</vs-text>
        </v-list-item-content>
      </v-list-item>
    </template>
  </vs-autocomplete>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { debounce } from 'lodash'

export default {
  props: {
    /**
     * Array of selected tags
     */
    value: {
      type: Array[String],
      default: [],
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchValue: '',
    }
  },
  computed: {
    ...mapState({
      tagOptions: (state) => state.formSubmissions.tags,
    }),
    options() {
      const values = new Set([
        ...this.tagOptions.map((tag) => tag.name),
        ...this.value,
      ])
      return [...values].map((tag) => ({ text: tag, value: tag }))
    },
    matchingOption() {
      const matchedOption = this.options.find(
        (tag) => tag.text.toLowerCase() === this.searchValue.toLowerCase()
      )
      return matchedOption?.text
    },
    isSearchAlreadyAdded() {
      const lowerCaseValues = this.value.map((tag) => tag.toLowerCase())
      return lowerCaseValues.includes(this.searchValue.toLowerCase())
    },
    showCustomTagOption() {
      return this.searchValue.length && !this.matchingOption
    },
  },
  mounted() {
    this.fetchTags()
  },
  methods: {
    ...mapActions('formSubmissions', ['fetchTags']),
    handleInput: debounce(function (value) {
      this.$emit('input', value)
    }, 500),
    handleAddMatchingOption() {
      this.handleInput([...this.value, this.matchingOption])
      this.resetField()
    },
    handleCustomAdd() {
      this.handleInput([...this.value, this.searchValue])
      this.resetField()
    },
    handleHotKeys(event) {
      if (this.searchValue.length) {
        event.preventDefault()
        if (this.isSearchAlreadyAdded) {
          this.resetField()
        } else if (this.matchingOption) {
          this.handleAddMatchingOption()
        } else {
          this.handleCustomAdd()
        }
      }
    },
    resetField() {
      this.searchValue = ''
      this.$refs.autocomplete.focus()
      this.$refs.autocomplete.clearSearchValue()
    },
  },
}
</script>
