import {
  deleteFormSubmission,
  getFormSubmission,
  postFormSubmission,
  postFormSubmissionComment,
  putAllFollowUpActionsCompletion,
  putFollowUpActionCompletion,
  putFormSubmission,
  putFormSubmissionAssignment,
  putFormSubmissionLike,
  putFormSubmissionTags,
  searchFormSubmissions,
} from '@/api/form_submissions'
import { getTags } from '@/api/tags'
import { getInstance } from '@/auth'
import FormSubmissionComment from '@/models/form-submission-comment'
import FormSubmissionLike from '@/models/form-submission-like'
import { getFormFields } from '@/utils/field-schema-helper'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import FormSubmission from '../../models/form_submission'

export const LOADING_FORM_SUBMISSION = 'LOADING_FORM_SUBMISSION'
export const RECEIVE_FORM_SUBMISSION = 'RECEIVE_FORM_SUBMISSION'
export const ERROR_LOAD_FORM_SUBMISSION = 'ERROR_LOAD_FORM_SUBMISSION'

export const CLEAR_ERROR_FORM_SUBMISSION = 'CLEAR_ERROR_FORM_SUBMISSION'
export const SAVING_FORM_SUBMISSION = 'SAVING_FORM_SUBMISSION'
export const SAVED_FORM_SUBMISSION = 'SAVED_FORM_SUBMISSION'
export const ERROR_FORM_SUBMISSION = 'ERROR_FORM_SUBMISSION'
export const SET_MEMORY_FIELDS = 'SET_MEMORY_FIELDS'

export const ASSIGN_FORM_SUBMISSION = 'ASSIGN_FORM_SUBMISSION'
export const FORM_SUBMISSION_ASSIGNED = 'FORM_SUBMISSION_ASSIGNED'
export const ASSIGN_FORM_SUBMISSION_FAILED = 'ASSIGN_FORM_SUBMISSION_FAILED'

export const COMPLETE_FOLLOW_UP_ACTION = 'COMPLETE_FOLLOW_UP_ACTION'
export const COMPLETE_FOLLOW_UP_ACTION_SUCCEEDED =
  'COMPLETE_FOLLOW_UP_ACTION_SUCCEEDED'
export const COMPLETE_FOLLOW_UP_ACTION_FAILED =
  'COMPLETE_FOLLOW_UP_ACTION_FAILED'

export const COMPLETE_ALL_FOLLOW_UP_ACTIONS = 'COMPLETE_ALL_FOLLOW_UP_ACTIONS'
export const COMPLETE_ALL_FOLLOW_UP_ACTIONS_SUCCEEDED =
  'COMPLETE_ALL_FOLLOW_UP_ACTIONS_SUCCEEDED'
export const COMPLETE_ALL_FOLLOW_UP_ACTIONS_FAILED =
  'COMPLETE_ALL_FOLLOW_UP_ACTION_FAILED'

export const ADD_COMMENT_TO_FORM_SUBMISSION = 'ADD_COMMENT_TO_FORM_SUBMISSION'
export const ADD_COMMENT_TO_FORM_SUBMISSION_SUBMITTED =
  'ADD_COMMENT_TO_FORM_SUBMISSION_SUBMITTED'
export const ADD_COMMENT_TO_FORM_SUBMISSION_FAILED =
  'ADD_COMMENT_TO_FORM_SUBMISSION_FAILED'

export const LOAD_FORM_SUBMISSION_PAGE = 'LOAD_FORM_SUBMISSION_PAGE'
export const RECEIVE_FORM_SUBMISSION_PAGE = 'RECEIVE_FORM_SUBMISSION_PAGE'
export const RECEIVE_FORM_SUBMISSION_PAGE_META =
  'RECEIVE_FORM_SUBMISSION_PAGE_META'
export const ERROR_LOADING_FORM_SUBMISSION_PAGE =
  'ERROR_LOADING_FORM_SUBMISSION_PAGE'

export const DEFAULT_FORM_SUBMISSION_PAGE_SIZE = 20

const STACK_CONTROL_CLEAR = 'clear'
const STACK_CONTROL_APPEND = 'append'
const STACK_CONTROL_POP = 'pop'
export const ADD_LIKE_TO_FORM_SUBMISSION = 'ADD_LIKE_TO_FORM_SUBMISSION'
export const ADD_LIKE_TO_FORM_SUBMISSION_SUBMITTED =
  'ADD_LIKE_TO_FORM_SUBMISSION_SUBMITTED'
export const ADD_LIKE_TO_FORM_SUBMISSION_FAILED =
  'ADD_LIKE_TO_FORM_SUBMISSION_FAILED'

export const UPDATE_TAGS = 'UPDATE_TAGS'
export const UPDATE_TAGS_SUBMITTED = 'UPDATE_TAGS_SUBMITTED'
export const UPDATE_TAGS_FAILED = 'UPDATE_TAGS_FAILED'

export const LOADING_TAGS = 'LOADING_TAGS'
export const RECEIVE_TAGS = 'RECEIVE_TAGS'

export const UPDATE_SUBMISSION = 'UPDATE_SUBMISSION'
export const UPDATE_SUBMISSION_SUCCEEDED = 'UPDATE_SUBMISSION_SUCCEEDED'
export const UPDATE_SUBMISSION_FAILED = 'UPDATE_SUBMISSION_FAILED'

export const DELETE_SUBMISSION = 'DELETE_SUBMISSION'
export const DELETE_SUBMISSION_SUCCEEDED = 'DELETE_SUBMISSION_SUCCEEDED'
export const DELETE_SUBMISSION_FAILED = 'DELETE_SUBMISSION_FAILED'

const state = () => ({
  loading: false,
  error: null,
  all: {},
  submissionError: null,
  saving: false,
  assignmentPending: false,
  completeAllActionsPending: false,
  addingCommentPending: false,
  addingLikePending: false,
  loadingTags: false,
  updatingTagsPending: false,
  isDeletingSubmission: false,
  completeFollowUpPending: [],
  loadingPage: false,
  pagination: [],
  formFieldMemory: {},
  paginationMeta: {
    formSubmissionPageStack: [],
    cursorAfter: null,
    count: null,
    filter: {
      startDate: null,
      endDate: null,
      includeActions: [],
      includeTags: [],
      includeForms: [],
      includeLocations: [],
      submittedBy: {
        userId: null,
        businessUnitId: null,
      },
      assignedTo: {
        userId: null,
        businessUnitId: null,
      },
      subject: null,
    },
  },
  tags: [],
})

export const getters = {
  getLoading: (state) => state.loading,
  getAll: (state) => state.all,
  getById: (state) => (id) => state.all[id],
  getError: (state) => state.error,
  getSaving: (state) => state.saving,
  getPage: (state) => state.pagination,
  canGotoNextPage: (state) => state.paginationMeta.cursorAfter !== null,
  canGotoPreviousPage: (state) =>
    state.paginationMeta.formSubmissionPageStack.length > 1,
  getCount: (state) => state.paginationMeta.count,
  getPageIsLoading: (state) => state.loadingPage,
  getTags: (state) => state.tags,
  isCreatingOrUpdating: (state) => {
    return (
      state.saving ||
      state.assignmentPending ||
      state.completeFollowUpPending.length !== 0 ||
      state.updatingTagsPending
    )
  },
  getFormFieldMemory: (state) => (id) => (state.formFieldMemory ?? {})[id],
}

async function fetchFormSubmissionPage(
  { commit, dispatch },
  {
    after,
    startDate,
    endDate,
    includeForms,
    includeLocations,
    includeActions,
    includeTags,
    submittedBy,
    assignedTo,
    subject,
    stackAction,
  }
) {
  commit(LOAD_FORM_SUBMISSION_PAGE)

  dispatch('formSubmissionsFilters/fetchAllSavedFilterCountsAsync', null, {
    root: true,
  })
  try {
    const response = await searchFormSubmissions({
      size: DEFAULT_FORM_SUBMISSION_PAGE_SIZE,
      after,
      startDate,
      endDate,
      includeForms,
      includeLocations,
      includeActions,
      includeTags,
      submittedBy,
      assignedTo,
      subject,
    })
    const { data, meta } = response.data
    commit(
      RECEIVE_FORM_SUBMISSION_PAGE,
      data.map((e) => new FormSubmission(e))
    )
    commit(RECEIVE_FORM_SUBMISSION_PAGE_META, {
      meta,
      startDate,
      endDate,
      includeForms,
      includeLocations,
      includeActions,
      includeTags,
      submittedBy,
      assignedTo,
      subject,
      stackAction,
    })
  } catch (error) {
    commit(ERROR_LOADING_FORM_SUBMISSION_PAGE, error)
  }
}

export const actions = {
  async fetchFormSubmission({ commit }, id) {
    commit(LOADING_FORM_SUBMISSION)

    try {
      const { data } = await getFormSubmission(id)
      commit(RECEIVE_FORM_SUBMISSION, new FormSubmission(data))
    } catch (error) {
      commit(ERROR_LOAD_FORM_SUBMISSION, error)
    }
  },

  async deleteFormSubmissionAction({ commit }, id) {
    commit(DELETE_SUBMISSION)

    try {
      await deleteFormSubmission(id)
      commit(DELETE_SUBMISSION_SUCCEEDED, id)
    } catch (error) {
      commit(DELETE_SUBMISSION_FAILED, error)
    }
  },

  async fetchFirstFormSubmissionPage(
    { commit, state, dispatch },
    {
      startDate,
      endDate,
      includeForms,
      includeLocations,
      includeActions,
      includeTags,
      submittedBy,
      assignedTo,
      subject,
    } = {}
  ) {
    await fetchFormSubmissionPage(
      { commit, dispatch },
      {
        after: null,
        startDate,
        endDate,
        includeForms,
        includeLocations,
        includeActions,
        includeTags,
        submittedBy,
        assignedTo,
        subject,
        stackAction: STACK_CONTROL_CLEAR,
      }
    )
  },
  async fetchNextFormSubmissionPage({ commit, state, dispatch }) {
    if (!getters.canGotoNextPage(state)) return

    await fetchFormSubmissionPage(
      { commit, dispatch },
      {
        after: state.paginationMeta.cursorAfter,
        ...state.paginationMeta.filter,
        stackAction: STACK_CONTROL_APPEND,
      }
    )
  },
  async fetchPreviousFormSubmissionPage({ commit, state, dispatch }) {
    if (!getters.canGotoPreviousPage(state)) return

    const after =
      state.paginationMeta.formSubmissionPageStack[
        state.paginationMeta.formSubmissionPageStack.length - 2
      ]

    await fetchFormSubmissionPage(
      { commit, dispatch },
      { after, ...state.paginationMeta.filter, stackAction: STACK_CONTROL_POP }
    )
  },
  async createFormSubmission({ commit }, { form, ownerId, formData }) {
    commit(CLEAR_ERROR_FORM_SUBMISSION)
    commit(SAVING_FORM_SUBMISSION)
    try {
      const uuid = uuidv4()

      await postFormSubmission({
        uuid,
        form_id: form.id,
        owner_id: ownerId,
        created_at: moment.utc().toISOString(),
        fields: formData,
      })

      commit(SAVED_FORM_SUBMISSION)
      commit(SET_MEMORY_FIELDS, { formData, form })
      return uuid
    } catch (err) {
      commit(ERROR_FORM_SUBMISSION, err)
      throw new Error(err)
    }
  },
  async assignFormSubmission({ commit }, { id, assigneeId, assigneeType }) {
    commit(ASSIGN_FORM_SUBMISSION)
    try {
      await putFormSubmissionAssignment(id, assigneeId, assigneeType)
      commit(FORM_SUBMISSION_ASSIGNED, { id, assigneeId, assigneeType })
    } catch (err) {
      commit(ASSIGN_FORM_SUBMISSION_FAILED, err)
    }
  },
  async completeFollowUpAction(
    { commit },
    { formSubmissionId, followUpActionId, comment }
  ) {
    commit(COMPLETE_FOLLOW_UP_ACTION, followUpActionId)
    try {
      const commentUUID = comment ? uuidv4() : null
      const time = moment.utc().toISOString()

      await putFollowUpActionCompletion(formSubmissionId, followUpActionId, {
        comment,
        commentUUID,
        completedAt: time,
      })

      const authService = getInstance()
      const userSid = authService.user.sub

      commit(COMPLETE_FOLLOW_UP_ACTION_SUCCEEDED, {
        formSubmissionId,
        followUpActionId,
        userSid,
        comment,
        commentUUID,
        time,
      })
    } catch (err) {
      commit(COMPLETE_FOLLOW_UP_ACTION_FAILED, { id: followUpActionId, err })
    }
  },
  async completeAllFollowUpActions(
    { commit },
    { formSubmissionId, actionsGuids, comment }
  ) {
    commit(COMPLETE_ALL_FOLLOW_UP_ACTIONS, actionsGuids)
    try {
      const commentUUID = comment ? uuidv4() : null
      const time = moment.utc().toISOString()

      await putAllFollowUpActionsCompletion(formSubmissionId, {
        comment,
        commentUUID,
        completedAt: time,
      })

      const authService = getInstance()
      const userSid = authService.user.sub

      commit(COMPLETE_ALL_FOLLOW_UP_ACTIONS_SUCCEEDED, {
        formSubmissionId,
        actionsGuids,
        userSid,
        comment,
        commentUUID,
        time,
      })
    } catch (err) {
      commit(COMPLETE_ALL_FOLLOW_UP_ACTIONS_FAILED, {
        actionsGuids,
        err,
      })
    }
  },
  async addCommentToFormSubmission({ commit }, { formSubmissionId, comment }) {
    commit(ADD_COMMENT_TO_FORM_SUBMISSION)
    try {
      const commentUUID = uuidv4()
      await postFormSubmissionComment(formSubmissionId, {
        comment,
        commentUUID,
        completedAt: moment.utc().toISOString(),
      })
      const authService = getInstance()
      const userSid = authService.user.sub
      commit(ADD_COMMENT_TO_FORM_SUBMISSION_SUBMITTED, {
        formSubmissionId,
        comment,
        userSid,
        commentUUID,
      })
    } catch (err) {
      commit(ADD_COMMENT_TO_FORM_SUBMISSION_FAILED, err)
    }
  },
  async likeFormSubmission({ commit }, { formSubmissionId }) {
    commit(ADD_LIKE_TO_FORM_SUBMISSION)
    const time = moment.utc().toISOString()
    try {
      await putFormSubmissionLike(formSubmissionId, {
        occurredAt: time,
      })
      const authService = getInstance()
      const userSid = authService.user.sub
      commit(ADD_LIKE_TO_FORM_SUBMISSION_SUBMITTED, {
        formSubmissionId,
        userSid,
        time,
      })
    } catch (err) {
      commit(ADD_LIKE_TO_FORM_SUBMISSION_FAILED, err)
    }
  },
  async updateFormSubmission(
    { commit },
    { formSubmissionId, formData, version, form }
  ) {
    commit(UPDATE_SUBMISSION)
    try {
      await putFormSubmission(formSubmissionId, { formData, version })
      commit(UPDATE_SUBMISSION_SUCCEEDED, {
        formSubmissionId,
        submission: formData,
      })
      commit(SET_MEMORY_FIELDS, { formData, form })
    } catch (err) {
      commit(UPDATE_SUBMISSION_FAILED, err)
      throw new Error(err)
    }
  },
  async updateFormSubmissionTags({ commit }, { formSubmissionId, tags }) {
    commit(UPDATE_TAGS)
    try {
      await putFormSubmissionTags(formSubmissionId, {
        tags,
      })
      commit(UPDATE_TAGS_SUBMITTED, {
        formSubmissionId,
        tags,
      })
    } catch (err) {
      commit(UPDATE_TAGS_FAILED, err)
    }
  },
  async fetchTags({ commit }) {
    commit(LOADING_TAGS)

    const { data } = await getTags()
    commit(RECEIVE_TAGS, { data })
  },
}

export const mutations = {
  [LOADING_FORM_SUBMISSION](state) {
    state.loading = true
    state.error = null
  },
  [RECEIVE_FORM_SUBMISSION](state, formSubmission) {
    state.all = Object.freeze({
      ...state.all,
      [formSubmission.formSubmissionId]: formSubmission,
    })
    state.loading = false
  },
  [LOAD_FORM_SUBMISSION_PAGE](state) {
    state.loadingPage = true
  },
  [RECEIVE_FORM_SUBMISSION_PAGE](state, data) {
    state.pagination = data
    state.loadingPage = false
  },
  [DELETE_SUBMISSION](state) {
    state.isDeletingSubmission = true
    state.error = null
  },
  [DELETE_SUBMISSION_SUCCEEDED](state, id) {
    const all = { ...state.all }
    delete all[id]
    state.all = Object.freeze(all)
    state.isDeletingSubmission = false
  },
  [DELETE_SUBMISSION_FAILED](state, error) {
    state.isDeletingSubmission = false
    state.error = error
  },
  [RECEIVE_FORM_SUBMISSION_PAGE_META](
    state,
    {
      meta,
      startDate,
      endDate,
      includeForms,
      includeLocations,
      includeActions,
      includeTags,
      submittedBy,
      assignedTo,
      subject,
      stackAction,
    }
  ) {
    if (stackAction === STACK_CONTROL_CLEAR) {
      state.paginationMeta.formSubmissionPageStack = [null]
    } else if (stackAction === STACK_CONTROL_APPEND) {
      state.paginationMeta.formSubmissionPageStack.push(
        state.paginationMeta.cursorAfter
      )
    } else if (stackAction === STACK_CONTROL_POP) {
      state.paginationMeta.formSubmissionPageStack.pop()
    }
    state.paginationMeta.cursorAfter = meta.cursor_after
    state.paginationMeta.count = meta.count
    state.paginationMeta.filter.startDate = startDate
    state.paginationMeta.filter.endDate = endDate
    state.paginationMeta.filter.includeForms = includeForms
    state.paginationMeta.filter.includeLocations = includeLocations
    state.paginationMeta.filter.includeActions = includeActions
    state.paginationMeta.filter.includeTags = includeTags
    state.paginationMeta.filter.submittedBy = submittedBy
    state.paginationMeta.filter.assignedTo = assignedTo
    state.paginationMeta.filter.subject = subject
  },
  [ERROR_LOADING_FORM_SUBMISSION_PAGE](state, error) {
    state.loadingPage = false
    state.error = error
  },
  [ERROR_LOAD_FORM_SUBMISSION](state, error) {
    state.loading = false
    state.error = error
    state.saving = false
  },
  [CLEAR_ERROR_FORM_SUBMISSION](state) {
    state.submissionError = null
  },
  [ERROR_FORM_SUBMISSION](state, error) {
    state.submissionError = error
    state.saving = false
  },
  [SAVING_FORM_SUBMISSION](state) {
    state.saving = true
  },
  [SAVED_FORM_SUBMISSION](state) {
    state.saving = false
  },
  [SET_MEMORY_FIELDS](state, { formData, form }) {
    const fields = getFormFields(form.structure)

    for (const key of Object.keys(fields)) {
      if (fields[key].memory) {
        state.formFieldMemory[form.id] ??= {}
        state.formFieldMemory[form.id][key] = formData[key]
      }
    }
  },
  [ASSIGN_FORM_SUBMISSION](state) {
    state.error = null
    state.assignmentPending = true
  },
  [ADD_COMMENT_TO_FORM_SUBMISSION](state) {
    state.error = null
    state.addingCommentPending = true
  },
  [ADD_COMMENT_TO_FORM_SUBMISSION_SUBMITTED](
    state,
    { formSubmissionId, comment, userSid, commentUUID }
  ) {
    const formSubmission = state.all[formSubmissionId]
    const newComment = new FormSubmissionComment({
      id: commentUUID,
      comment,
      created_by: userSid,
      created: moment().toISOString(),
    })
    formSubmission.comments.push(newComment)
    state.all = Object.freeze({
      ...state.all,
      [formSubmissionId]: formSubmission,
    })
    state.addingCommentPending = false
  },
  [ADD_COMMENT_TO_FORM_SUBMISSION_FAILED](state, err) {
    state.error = err
    state.addingCommentPending = false
  },
  [ADD_LIKE_TO_FORM_SUBMISSION](state) {
    state.error = null
    state.addingLikePending = true
  },
  [ADD_LIKE_TO_FORM_SUBMISSION_SUBMITTED](
    state,
    { formSubmissionId, userSid, time }
  ) {
    const formSubmission = state.all[formSubmissionId]
    const newLike = new FormSubmissionLike({
      liked_by: userSid,
      created_at: time,
    })
    formSubmission.likes.push(newLike)
    state.all = Object.freeze({
      ...state.all,
      [formSubmissionId]: formSubmission,
    })
    state.addingLikePending = false
  },
  [ADD_LIKE_TO_FORM_SUBMISSION_FAILED](state, err) {
    state.error = err
    state.addingLikePending = false
  },
  [FORM_SUBMISSION_ASSIGNED](state, { id, assigneeId, assigneeType }) {
    const formSubmission = state.all[id]
    formSubmission.assignedTo = {
      id: assigneeId,
      type: assigneeType,
    }
    state.all = Object.freeze({
      ...state.all,
      [id]: formSubmission,
    })
    state.assignmentPending = false
  },
  [ASSIGN_FORM_SUBMISSION_FAILED](state, err) {
    state.assignmentPending = false
    state.error = err
  },
  [COMPLETE_FOLLOW_UP_ACTION](state, id) {
    state.completeFollowUpPending.splice(-1, 0, id)
  },
  [COMPLETE_FOLLOW_UP_ACTION_SUCCEEDED](
    state,
    { formSubmissionId, followUpActionId, userSid, comment, commentUUID, time }
  ) {
    const index = state.completeFollowUpPending.indexOf(followUpActionId)
    state.completeFollowUpPending.splice(index, 1)

    const formSubmission = state.all[formSubmissionId]
    const followUpAction = formSubmission.followUpActions.find(
      (fua) => fua.id === followUpActionId
    )
    followUpAction.completedBy = userSid

    if (comment) {
      const completionComment = new FormSubmissionComment({
        id: commentUUID,
        comment,
        created_by: userSid,
        created: time,
      })
      formSubmission.comments.splice(-1, 0, completionComment)
    }

    state.all = Object.freeze({
      ...state.all,
      [formSubmissionId]: formSubmission,
    })
  },
  [COMPLETE_FOLLOW_UP_ACTION_FAILED](state, { id, err }) {
    const index = state.completeFollowUpPending.indexOf(id)
    state.completeFollowUpPending.splice(index, 1)
    state.error = err
  },
  [COMPLETE_ALL_FOLLOW_UP_ACTIONS](state, actionsGuids) {
    state.completeFollowUpPending.splice(-1, 0, ...actionsGuids)
    state.completeAllActionsPending = true
  },
  [COMPLETE_ALL_FOLLOW_UP_ACTIONS_SUCCEEDED](
    state,
    { formSubmissionId, actionsGuids, userSid, comment, commentUUID, time }
  ) {
    const formSubmission = state.all[formSubmissionId]

    for (const actionGuid of actionsGuids) {
      const index = state.completeFollowUpPending.indexOf(actionGuid)
      state.completeFollowUpPending.splice(index, 1)

      const followUpAction = formSubmission.followUpActions.find(
        (fua) => fua.id === actionGuid
      )
      followUpAction.completedBy = userSid
    }

    if (comment) {
      const completionComment = new FormSubmissionComment({
        id: commentUUID,
        comment,
        created_by: userSid,
        created: time,
      })
      formSubmission.comments.splice(-1, 0, completionComment)
    }

    state.all = Object.freeze({
      ...state.all,
      [formSubmissionId]: formSubmission,
    })

    state.completeAllActionsPending = false
  },
  [COMPLETE_ALL_FOLLOW_UP_ACTIONS_FAILED](state, { actionsGuids, err }) {
    for (const actionGuid of actionsGuids) {
      const index = state.completeFollowUpPending.indexOf(actionGuid)
      state.completeFollowUpPending.splice(index, 1)
    }

    state.error = err
    state.completeAllActionsPending = true
  },
  [UPDATE_SUBMISSION](state) {
    state.saving = true
  },
  [UPDATE_SUBMISSION_FAILED](state, err) {
    state.saving = false
    state.error = err
  },
  [UPDATE_SUBMISSION_SUCCEEDED](state, { formSubmissionId, submission }) {
    const formSubmission = state.all[formSubmissionId]
    formSubmission.submission = submission
    state.all = Object.freeze({
      ...state.all,
      [formSubmissionId]: formSubmission,
    })
    state.saving = false
  },
  [UPDATE_TAGS](state) {
    state.error = null
    state.updatingTagsPending = true
  },
  [UPDATE_TAGS_SUBMITTED](state, { formSubmissionId, tags }) {
    const formSubmission = state.all[formSubmissionId]
    formSubmission.tags = tags
    state.all = Object.freeze({
      ...state.all,
      [formSubmissionId]: formSubmission,
    })
    state.updatingTagsPending = false
  },
  [UPDATE_TAGS_FAILED](state, err) {
    state.error = err
    state.updatingTagsPending = false
  },
  [LOADING_TAGS](state) {
    state.loadingTags = true
  },
  [RECEIVE_TAGS](state, { data }) {
    state.tags = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
