import { GetJobEventsQueryResponse } from '@/api/jobs'

export class JobEvent {
  constructor(json: GetJobEventsQueryResponse) {
    this.jobId = json.job_id
    this.id = json.event_id
    this.type = json.event_type
    this.timestamp = json.timestamp
    this.metadata = json.metadata
    this.data = json.data
  }

  jobId: string
  id: string
  type: string
  timestamp: Date
  metadata: Record<string, any> | null
  data: Record<string, any> | null
}

export interface LocationVisitedEvent extends JobEvent {
  type: 'LocationVisitedEvent'
  data: {
    resource_id: string
    recorded_by_user_sid: string
    location_id: number
  }
}

export interface TriggerThresholdResolvedEvent extends JobEvent {
  type: 'TriggerThresholdResolvedEvent'
  data: {
    trigger_id: string
    source_id: string
    start: string
    end: string
    current_value: number
    max: number
    min: number
    slope: number
    intercept: number
  }
}

export interface ItemReceivedAtLocationEvent extends JobEvent {
  type: 'ItemReceivedAtLocationEvent'
  data: {
    job_id: string
    location_id: number
    quantity: number
    inventory_sub_type_id: number
    recorded_by_user_sid: string
  }
}

export function isLocationVisitedEvent(
  event: JobEvent
): event is LocationVisitedEvent {
  return event.type === 'LocationVisitedEvent'
}

export function isTriggerThresholdResolvedEvent(
  event: JobEvent
): event is TriggerThresholdResolvedEvent {
  return event.type === 'TriggerThresholdResolvedEvent'
}

export function isItemReceivedAtLocationEvent(
  event: JobEvent
): event is ItemReceivedAtLocationEvent {
  return event.type === 'ItemReceivedAtLocationEvent'
}
