import { cloneDeep, isEqualWith } from 'lodash'
import { BASE_API_ENDPOINT, get } from '@/api/base'
import Vue from 'vue'

export const ARCHIVING = 'ARCHIVING'
export const SAVING = 'SAVING'
export const NEW_SELECTED_ITEM = 'NEW_SELECTED_ITEM'
export const CLONE_SELECTED_ITEM = 'CLONE_SELECTED_ITEM'
export const RECEIVE_ITEM = 'RECEIVE_ITEM'
export const UPDATE_SELECTED_ITEM = 'UPDATE_SELECTED_ITEM'
export const CLEAR_SELECTED_ITEM = 'CLEAR_SELECTED_ITEM'
export const DELETED_ITEM = 'DELETED_ITEM'
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const ERROR_SAVING = 'ERROR_SAVING'

export default function createItemForm(
  { create, update, deleteItem },
  constructor
) {
  return {
    namespaced: false,

    state: () => {
      return {
        selectedItem: null,
        originalItem: null,
        saving: false,
        archiving: false,
      }
    },

    getters: {
      hasChanges: (state) => {
        return !isEqualWith(
          state.selectedItem,
          state.originalItem,
          (_objValue, _othValue, key) => (key === 'isActive' ? true : undefined)
        )
      },
      getSelectedItem: (state) => state.selectedItem,
      isNew: (state) => !state.selectedItem.id,
      isSaving: (state) => state.saving,
      isArchiving: (state) => state.archiving,
      formTitle: (state, getters) => {
        if (!state.selectedItem) {
          return ''
        } else if (!state.selectedItem.id) {
          return 'New item'
        } else if (state.originalItem) {
          const editPrefix = getters.hasChanges ? 'Edit ' : ''
          return `${editPrefix}${state.originalItem.name}`
        }
        return ''
      },
    },

    actions: {
      createDraftItem({ commit, getters }, id) {
        if (!id || id === 'new') {
          commit(NEW_SELECTED_ITEM)
        } else {
          const item = getters.getById(id)
          if (item) commit(CLONE_SELECTED_ITEM, item)
        }
      },
      clearDraftItem({ commit }) {
        commit(NEW_SELECTED_ITEM)
      },
      async save({ commit, state, getters }) {
        commit(SAVING, true)

        const action = getters.isNew ? create : update
        return action(state.selectedItem)
          .then(async (saveResult) => {
            const getEndpoint = saveResult.headers.location
            let newItem = state.selectedItem

            if (saveResult.data && typeof saveResult.data === 'object') {
              newItem = new constructor(saveResult.data)
            } else if (getEndpoint) {
              await get(
                getEndpoint.substring(getEndpoint.indexOf(BASE_API_ENDPOINT))
              ).then((getResult) => {
                newItem = new constructor(getResult.data)
              })
            }

            commit(RECEIVE_ITEM, newItem)
            commit(CLONE_SELECTED_ITEM, newItem)
            commit(CLEAR_ERROR)
          })
          .catch((err) => {
            commit(ERROR_SAVING, err)
          })
          .finally(() => {
            commit(SAVING, false)
            commit(ARCHIVING, false)
          })
      },
      async delete({ commit, state }) {
        if (!state.selectedItem || !state.selectedItem.id) {
          const err = new Error('Could not find record to delete')
          commit(ERROR_SAVING, err)
          return Promise.reject(err)
        }

        return deleteItem(state.selectedItem.id)
          .then(() => commit(DELETED_ITEM, state.selectedItem.id))
          .catch((err) => {
            commit(ERROR_SAVING, err)
            return Promise.reject(err)
          })
      },
    },

    mutations: {
      [NEW_SELECTED_ITEM](state) {
        state.selectedItem = new constructor()
        state.originalItem = cloneDeep(state.selectedItem)
      },
      [CLEAR_SELECTED_ITEM](state) {
        state.selectedItem = null
        state.originalItem = null
      },
      [CLONE_SELECTED_ITEM](state, item) {
        state.selectedItem = cloneDeep(item)
        state.originalItem = cloneDeep(state.selectedItem)
      },
      [UPDATE_SELECTED_ITEM](state, { field, value }) {
        Vue.set(state.selectedItem, field, value)
      },
      [SAVING](state, value) {
        state.saving = value
      },
      [ARCHIVING](state, value) {
        state.archiving = value
      },
    },
  }
}
