"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.objectFieldMatcher = exports.FieldParser = void 0;
const form_field_definition_1 = require("../form_fields/form_field_definition");
const field_matcher_1 = require("./field_matcher");
const array_field_matcher_1 = require("./field_matchers/array_field_matcher");
const boolean_field_matcher_1 = require("./field_matchers/boolean_field_matcher");
const createable_selector_matcher_1 = require("./field_matchers/createable_selector_matcher");
const date_field_matcher_1 = require("./field_matchers/date_field_matcher");
const enum_string_field_matcher_1 = require("./field_matchers/enum_string_field_matcher");
const file_selector_matcher_1 = require("./field_matchers/file_selector_matcher");
const generic_selector_field_matcher_1 = require("./field_matchers/generic_selector_field_matcher");
const heading_field_matcher_1 = require("./field_matchers/heading_field_matcher");
const lat_lng_field_matcher_1 = require("./field_matchers/lat_lng_field_matcher");
const lookup_field_matcher_1 = require("./field_matchers/lookup_field_matcher");
const markdown_field_matcher_1 = require("./field_matchers/markdown_field_matcher");
const number_field_matcher_1 = require("./field_matchers/number_field_matcher");
const subject_field_matcher_1 = require("./field_matchers/subject_field_matcher");
const subject_location_field_matcher_1 = require("./field_matchers/subject_location_field_matcher");
const text_entry_field_matcher_1 = require("./field_matchers/text_entry_field_matcher");
const formButtonsFieldMatcher_1 = require("./field_matchers/formButtonsFieldMatcher");
const object_field_definition_1 = require("../form_fields/object_field_definition");
const json_schema_keys_1 = require("../json_schema_keys");
const dependency_parser_1 = require("./dependency_parser");
const reference_field_matcher_1 = require("./field_matchers/reference_field_matcher");
const const_dependency_dto_1 = require("../models/dependencies/const_dependency_dto");
class FieldParser {
    constructor(matchers = defaultMatchers) {
        this.matchers = matchers;
    }
    buildDispatchers() {
        const dispatchers = [field_matcher_1.matcherTermination];
        for (const matcher of this.matchers.reverse()) {
            const next = dispatchers[dispatchers.length - 1];
            dispatchers.push((uniqueKey, isRequired, properties, uiSchema, fieldDefinitions) => matcher(uniqueKey, isRequired, properties, uiSchema, fieldDefinitions, next));
        }
        return dispatchers.reverse();
    }
    parse(uniqueKey, isRequired, properties, uiSchema, definitions) {
        if (properties === null || properties === undefined) {
            throw new Error(`Field ${uniqueKey} has no properties`);
        }
        const field = this.buildDispatchers()[0](uniqueKey, isRequired, properties, uiSchema !== null && uiSchema !== void 0 ? uiSchema : {}, definitions);
        if (field instanceof form_field_definition_1.FormFieldDefinition) {
            field.setKeyOverride(uniqueKey);
            return field;
        }
        throw new Error(`Field ${uniqueKey} could not be parsed`);
    }
}
exports.FieldParser = FieldParser;
// TODO this lives in this file to avoid a circular dependency from it
// needing FieldParser. There might be better way to avoid it
const objectFieldMatcher = (uniqueKey, isRequired, properties, uiSchema, fieldDefinitions, next) => {
    const isObjectType = properties[json_schema_keys_1.JsonSchemaKeys.TYPE_KEY] === json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_OBJECT;
    const isObjectArray = properties[json_schema_keys_1.JsonSchemaKeys.TYPE_KEY] === json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_ARRAY && properties.items.type === json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_OBJECT;
    if (isObjectType || isObjectArray) {
        const field = new object_field_definition_1.ObjectFieldDefinition();
        field.title = properties.title;
        field.description = properties.description;
        field.isRequired = isRequired;
        field.isArray = isObjectArray;
        field.defaultValue = properties.default;
        const nestedDependencies = isObjectArray ? properties.items.dependencies : properties.dependencies;
        const nestedUiSchema = isObjectArray ? uiSchema.items : uiSchema;
        const nestedProps = isObjectArray ? properties.items.properties : properties.properties;
        const fieldParser = new FieldParser();
        const requiredNestedFields = isObjectArray ? properties.items.required : properties.required;
        const uiOrder = nestedUiSchema[json_schema_keys_1.JsonSchemaKeys.UI_ORDER_KEY];
        for (const nestedKey of uiOrder) {
            var props = nestedProps[nestedKey];
            if (nestedDependencies) {
                for (const oneOfModel of Object.values(nestedDependencies)) {
                    const oneOfArray = oneOfModel.oneOf;
                    for (const oneOfModel of oneOfArray) {
                        const nestedDependencyProps = oneOfModel.properties[nestedKey];
                        if (nestedDependencyProps && !nestedDependencyProps["const"]) {
                            props = { ...props, ...nestedDependencyProps };
                        }
                    }
                }
            }
            const nestedField = fieldParser.parse(nestedKey, requiredNestedFields.includes(nestedKey), props, nestedUiSchema[nestedKey], field.builder.fieldDefinitions());
            field.addField(nestedField);
        }
        if (isObjectArray) {
            field.minimumItems = properties[json_schema_keys_1.JsonSchemaKeys.MIN_ITEMS_KEY];
            field.maximumItems = properties[json_schema_keys_1.JsonSchemaKeys.MAX_ITEMS_KEY];
        }
        if (nestedDependencies) {
            const dependencyParser = new dependency_parser_1.DependencyParser(field.fields);
            const dtos = dependencyParser.parse(nestedDependencies);
            for (const dto of dtos) {
                if (dto instanceof const_dependency_dto_1.ConstDependencyDTO) {
                    field.addConstDependencyToField(dto);
                }
                else {
                    field.addReqDependencyToField(dto);
                }
            }
        }
        return field;
    }
    return next(uniqueKey, isRequired, properties, uiSchema, fieldDefinitions);
};
exports.objectFieldMatcher = objectFieldMatcher;
const defaultMatchers = [
    array_field_matcher_1.arrayFieldMatcher,
    boolean_field_matcher_1.booleanFieldMatcher,
    createable_selector_matcher_1.createableSelectorMatcher,
    date_field_matcher_1.dateFieldMatcher,
    enum_string_field_matcher_1.enumStringFieldMatcher,
    file_selector_matcher_1.fileSelectorFieldMatcher,
    generic_selector_field_matcher_1.genericSelectorFieldMatcher,
    heading_field_matcher_1.headingFieldMatcher,
    lat_lng_field_matcher_1.latLngFieldMatcher,
    lookup_field_matcher_1.lookupFieldMatcher,
    markdown_field_matcher_1.markdownFieldMatcher,
    number_field_matcher_1.numberFieldMatcher,
    reference_field_matcher_1.referenceFieldMatcher,
    exports.objectFieldMatcher,
    subject_field_matcher_1.subjectFieldMatcher,
    subject_location_field_matcher_1.subjectLocationFieldMatcher,
    text_entry_field_matcher_1.textEntryFieldMatcher,
    formButtonsFieldMatcher_1.formButtonsFieldMatcher
];
