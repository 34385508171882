import { debounce } from 'lodash'
import { computed, ShallowRef } from 'vue'

interface FieldViewPosition {
  center?: mapboxgl.LngLatLike
  zoom?: number
}

export const usePersistedFieldViewPosition = (
  map: ShallowRef<mapboxgl.Map | undefined>
) => {
  const defaultPosition = computed<FieldViewPosition>(() => {
    const pos = localStorage.getItem('field-view-position')

    if (pos) return JSON.parse(pos)

    return {
      center: undefined,
      zoom: undefined,
    }
  })

  const saveMapPosition = debounce(() => {
    const center = map.value?.getCenter()
    const zoom = map.value?.getZoom()

    localStorage.setItem(
      'field-view-position',
      JSON.stringify({ center, zoom })
    )
  }, 500)

  return {
    previousPosition: defaultPosition,
    saveMapPosition,
  }
}
