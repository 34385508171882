"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MarkdownFieldDefinition = void 0;
const json_schema_keys_1 = require("../json_schema_keys");
const markdown_property_description_1 = require("../models/field_property_descriptions/markdown_property_description");
const markdown_ui_schema_1 = require("../models/field_ui_schema/markdown_ui_schema");
const form_field_definition_1 = require("./form_field_definition");
class MarkdownFieldDefinition extends form_field_definition_1.PrimitiveValueField {
    constructor() {
        super(...arguments);
        this.markdown = "";
        this.dataType = json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_NULL;
    }
    uiSchema() {
        return new markdown_ui_schema_1.MarkdownUiSchema();
    }
    propertyDescription() {
        return new markdown_property_description_1.MarkdownPropertyDescription(this);
    }
}
exports.MarkdownFieldDefinition = MarkdownFieldDefinition;
