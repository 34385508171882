<template>
  <vs-wrapper>
    <vs-heading v-if="title" type="subhead">{{ title }}</vs-heading>
    <picture>
      <source
        v-for="source in uiOptions.sources"
        :key="source.media"
        :media="source.media"
        :srcset="source.srcset"
      />
      <img
        :src="uiOptions.src"
        :style="{
          height: uiOptions.height,
          maxWidth: uiOptions.maxWidth,
          maxHeight: uiOptions.maxHeight,
          width: uiOptions.width,
        }"
      />
    </picture>
    <div v-if="description" class="hint">
      <vs-text type="subdued">{{ description }}</vs-text>
    </div>
  </vs-wrapper>
</template>

<script>
export default {
  name: 'ImageField',
  props: {
    errorSchema: Object,
    formData: undefined,
    params: Object,
    rootSchema: Object,
    schema: Object,
    title: String,
    description: String,
    uiSchema: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    uiOptions() {
      return this.uiSchema['ui:options'] || {}
    },
  },
}
</script>

<style scoped></style>
