"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NumberFieldDefinition = void 0;
const json_schema_keys_1 = require("../json_schema_keys");
const decimal_property_description_1 = require("../models/field_property_descriptions/decimal_property_description");
const integer_property_description_1 = require("../models/field_property_descriptions/integer_property_description");
const form_field_definition_1 = require("./form_field_definition");
class NumberFieldDefinition extends form_field_definition_1.PrimitiveValueField {
    constructor() {
        super(...arguments);
        this.allowDecimals = false;
    }
    get dataType() {
        return this.allowDecimals ? json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_NUMBER : json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_INTEGER;
    }
    propertyDescription() {
        if (this.allowDecimals) {
            return new decimal_property_description_1.DecimalPropertyDescription(this);
        }
        else {
            return new integer_property_description_1.IntegerPropertyDescription(this);
        }
    }
}
exports.NumberFieldDefinition = NumberFieldDefinition;
