export default class FormCategory {
  constructor(json: Record<string, any>) {
    this.id = json.id
    this.name = json.name
    this.hidden = json.hidden
    this.editable = json.editable
  }

  id: string
  name: string
  hidden: boolean
  editable: boolean
}
