<template>
  <div class="image-container">
    <!-- This is used to solve the issue that the v-zoomer is rendered before the image is fully loaded, -->
    <!-- which makes the zooming not work properly -->
    <img
      v-if="!isImageLoaded"
      :class="['image-attachment']"
      :src="url"
      @load="isImageLoaded = true"
    />
    <v-zoomer
      v-if="isImageLoaded"
      :ref="refKey"
      class="image-zoomer"
      :double-click-to-zoom="false"
      :zooming-elastic="false"
      :limit-translation="false"
      :mouse-wheel-to-zoom="false"
    >
      <img
        :class="['image-attachment', canTapZoomIn ? 'zoom-in' : 'zoom-out']"
        :src="url"
        @dblclick="tapToChangeZoomLevel"
      />
    </v-zoomer>

    <div class="button-group">
      <v-btn
        tile
        elevation="1"
        x-small
        class="zoom-in-out-btn"
        icon
        @click="zoomIn"
      >
        <v-icon color="black">mdi-plus</v-icon>
      </v-btn>
      <v-btn
        tile
        elevation="1"
        x-small
        class="zoom-in-out-btn"
        icon
        @click="zoomOut"
      >
        <v-icon color="black">mdi-minus</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filename: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      zoomLevel: 0,
      maxTapZoomLevel: 2,
      // maxZoomLevel = ceil(sqrt(maxScale))
      // The default maxScale is 5
      maxZoomLevel: 3,
      isImageLoaded: false,
    }
  },

  computed: {
    canTapZoomIn() {
      return this.zoomLevel < this.maxTapZoomLevel
    },
    refKey() {
      return `zoomer-${this.filename}`
    },
  },

  methods: {
    tapToChangeZoomLevel() {
      if (this.canTapZoomIn) {
        this.zoomIn()
      } else {
        this.$refs[this.refKey].reset()
        this.zoomLevel = 0
      }
    },
    zoomIn() {
      this.$refs[this.refKey].zoomIn()
      if (this.zoomLevel < this.maxZoomLevel) this.zoomLevel += 1
    },
    zoomOut() {
      if (this.zoomLevel > 0) this.zoomLevel -= 1

      if (this.zoomLevel === 0) {
        this.$refs[this.refKey].reset()
      } else {
        this.$refs[this.refKey].zoomOut()
      }
    },
  },
}
</script>

<style>
.image-container {
  position: relative;
}

.image-zoomer {
  margin: 8px;
  border-radius: var(--space-base);
}

.image-attachment {
  display: block;
  margin-left: auto;
  margin-right: auto;

  width: 100%;
  height: 100%;
}

.button-group {
  position: absolute;
  top: 8px;
  left: 32px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.zoom-in-out-btn {
  background-color: white;
}

.zoom-in {
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.zoom-out {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}
</style>
