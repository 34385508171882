import { computed, Ref } from 'vue'
import { useInvoiceQuery, useInvoiceSyncTargetsQuery } from '@/api/invoice'
import { isQbdSyncDetails, QbdSyncDetails } from '@/models/invoice'
import moment from 'moment/moment'
import { useQuery } from '@tanstack/vue-query'
import { getAdminPortalConfig } from '@/api/config'
import { useStore } from 'vuex-composition-helpers'

export enum BatchSyncStatus {
  Invalid,
  NoSyncTarget, // No sync target is set up
  AwaitingInitialSync, // Sync target is set but not yet synced
  UpdatesPending, // Synced at least once before, but updates detected and awaiting next sync
  SyncSuccess, // Sync completed successfully
  SyncFailure, // Sync failed
}

export const useBatchSyncStates = (
  batchId: Ref<string | null | undefined>,
  syncTargetId: Ref<string | null | undefined>
) => {
  const { data: batch } = useInvoiceQuery(batchId)
  const { data: batchSyncTargets } = useInvoiceSyncTargetsQuery(batchId)

  const { data: config } = useQuery({
    queryKey: ['config'],
    queryFn: getAdminPortalConfig,
    cacheTime: Infinity,
    staleTime: moment.duration(1, 'days').asMilliseconds(),
  })
  const store = useStore()
  const orgId = computed<string | undefined>(
    () => store.getters['permissions/getOrganizationId']
  )

  const syncTarget = computed(() =>
    batchSyncTargets.value?.find((st) => st.id === syncTargetId.value)
  )

  const lastSyncRunLogLink = computed(() => {
    if (
      syncTarget.value &&
      isQbdSyncDetails(syncTarget.value.details) &&
      orgId.value &&
      config.value
    ) {
      return `${config.value.data.url}/organization/${orgId.value}/history/${syncTarget.value.details.lastRunId}`
    }
    return undefined
  })

  const syncStatus = computed(() => {
    if (!syncTarget.value) {
      return BatchSyncStatus.NoSyncTarget
    }
    if (!isQbdSyncDetails(syncTarget.value.details)) {
      return BatchSyncStatus.AwaitingInitialSync
    }
    if (
      (batch.value?.lastModified &&
        moment(syncTarget.value.details.lastSyncedAt).isBefore(
          batch.value.lastModified
        )) ||
      (syncTarget.value.lastRequestedSyncAt &&
        moment(syncTarget.value.details.lastSyncedAt).isBefore(
          syncTarget.value.lastRequestedSyncAt
        ))
    ) {
      return BatchSyncStatus.UpdatesPending
    } else if (syncTarget.value.details.syncStatus === 'Success') {
      return BatchSyncStatus.SyncSuccess
    } else if (syncTarget.value.details.syncStatus === 'Failed') {
      return BatchSyncStatus.SyncFailure
    }
    return BatchSyncStatus.Invalid
  })

  const syncFailureMessage = computed(() => {
    if (syncStatus.value === BatchSyncStatus.SyncFailure) {
      return (syncTarget.value!.details as QbdSyncDetails).error
    }
  })

  const lastSyncedAt = computed(() => {
    if (syncTarget.value && isQbdSyncDetails(syncTarget.value.details)) {
      return syncTarget.value.details.lastSyncedAt
    }
  })

  return {
    syncTarget,
    lastSyncRunLogLink,
    syncFailureMessage,
    lastSyncedAt,
    syncStatus,
  }
}
