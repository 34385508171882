import { computed, nextTick, Ref, ref, watch, watchEffect } from 'vue'
import { orderBy } from 'lodash'
import { PickerItemType } from '@/components/vision/next/picker-list/models'
import { useObjectAsStack } from '@/components/vision/next/inputs/useObjectAsStack'
import { useGetLocationTypesQuery } from '@/api/location-types'
import {
  SearchLocationsOptions,
  useSearchLocationsQuery,
} from '@/api/locations'
import {
  HeadlessAutoCompleteValueType,
  useAutoCompleteProvider,
  ValueResolver,
} from '@/components/vision/next/inputs/useAutoComplete'

type HeadlessLocationPickerOptions = {
  onNavigate?: () => void
  getScrollPosition?: () => number
  scrollToPosition?: (pos: number) => void
  valueResolver?: ValueResolver
  hubId?: string
  excludeLocationIds?: number[]
  canShowAllLocations?: boolean
  showAllLocations?: Ref<boolean>
} & (
  | {
      multiple?: false
      onValueChanged?: (item?: HeadlessAutoCompleteValueType) => void
      value?: any
    }
  | {
      multiple: true
      onValueChanged?: (items: HeadlessAutoCompleteValueType[]) => void
      value?: any
    }
)

type LocationFilterType = {
  id: number | null
  label: string
}

type State = {
  searchText: string
  parentId: number | null
  parentName: string | null
  scrollPosition: number | null
  locationType: LocationFilterType | null
  hubId?: string
}

export const useHeadlessLocationPicker = (
  options: HeadlessLocationPickerOptions
) => {
  // we create our state (filters, search text, etc) as a stack of
  // allowing us to maintain different states at different hierarchies.
  const { current, pop, push, exportState, importState } =
    useObjectAsStack<State>({
      searchText: '',
      parentId: null,
      parentName: null,
      scrollPosition: null,
      locationType: { id: null, label: 'All' },
      hubId: options.hubId,
    })

  const search = computed(() => current.value.searchText)

  const autoComplete = useAutoCompleteProvider({
    search,
    multiple: options.multiple as any,
    onValueChanged: options.onValueChanged as any,
    value: options.value as any,
    valueResolver: options.valueResolver,
  })

  // when the user makes a selection, we export our state stack here
  // so that if the picker is reopened, we can restore the entire state
  // including the hierarchy.
  const previouslySelectedState = ref<string>()

  const hubId = computed(() => current.value?.hubId)
  const includeOwnedLocations = computed(() => options.showAllLocations?.value)

  const searchOptions = computed<SearchLocationsOptions>(() => ({
    searchText: current?.value.searchText,
    parentId: current.value?.parentId,
    locationTypeId: current.value?.locationType?.id,
    hubId: current.value?.hubId,
    includeOwnedLocations: includeOwnedLocations.value,
  }))

  const {
    data: locations,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetched,
    isInitialLoading,
  } = useSearchLocationsQuery(searchOptions)

  // rather than use the query results directly, we setup a ref
  // and a watcher to store and update the query results.
  // this allows us room to transform the data, as well as modify
  // the items list manually, i.e. clearing out the list as the
  // user navigates.
  const items = ref<Readonly<PickerItemType[]>>([])
  options.excludeLocationIds ??= []

  watchEffect(() => {
    items.value = Object.freeze(
      locations.value?.pages
        .flatMap((p) => p.data)
        .map((l) => ({
          id: l.id,
          title: l.location,
          subTitle: [l.locationTypeName, l.parentName, l.colorName]
            .filter(Boolean)
            .join(' · '),
          childCount: l.childCount,
          expandable: l.childCount > 0,
          selectable: !options.excludeLocationIds?.includes(l.id),
        })) || []
    )
  })

  // if the user is actively searching or changing
  // filters we should request to reset the scroll position
  watch(searchOptions, (newOpts, oldOpts) => {
    if (
      oldOpts.searchText !== newOpts.searchText ||
      oldOpts.locationTypeId !== newOpts.locationTypeId
    ) {
      options.scrollToPosition?.(0)
    }
  })

  const { data: locationTypesResults } = useGetLocationTypesQuery({
    hubId,
    includeOwnedLocations,
  })

  const locationTypes = computed(() => {
    return [
      { id: null, label: 'All' },
      ...orderBy(
        locationTypesResults.value,
        (l) => l.name.toLowerCase(),
        'asc'
      ).map((l) => ({
        id: l.id,
        label: l.name,
      })),
    ]
  })

  const selectLocationType = (locationType: LocationFilterType | null) => {
    current.value.locationType = locationType ?? { id: null, label: 'All' }
  }

  const continueFetching = () => {
    if (hasNextPage?.value && !isFetching.value) {
      fetchNextPage()
    }
  }

  const selectItem = (item: PickerItemType) => {
    if (options.multiple) {
      return autoComplete.toggleValue(item)
    }

    autoComplete.addValue(item)
    current.value.scrollPosition = options.getScrollPosition?.() ?? 0
    previouslySelectedState.value = exportState()
  }

  const navigateIntoItem = (item: PickerItemType) => {
    current.value.scrollPosition = options.getScrollPosition?.() ?? 0

    // push a new state onto our stack, this will update
    // the current pointer with new values, while preserving
    // the old values
    push({
      parentId: item.id as number,
      parentName: item.title,
      searchText: '',
      scrollPosition: 0,
      locationType: current.value.locationType,
      hubId: current.value.hubId,
    })

    // scroll back to the top and clear the items.
    // not clearing the items causes visual glitches
    // if the network or Tanstack is slow.
    options.scrollToPosition?.(0)
    items.value = []

    options.onNavigate?.()
  }

  const navigateBack = () => {
    // pop the existing state, this will update
    // the current pointer with new values
    const { next } = pop()

    nextTick().then(() => {
      options.scrollToPosition?.(next.scrollPosition ?? 0)
    })

    autoComplete.setModified(true)

    options.onNavigate?.()
  }

  const restoreToPreviousState = () => {
    if (!options.multiple && previouslySelectedState.value) {
      const { next } = importState(previouslySelectedState.value)
      nextTick().then(() => {
        options.scrollToPosition?.(next.scrollPosition ?? 0)
      })
    }
  }

  const updateSearch = (newSearch: string) => {
    current.value.searchText = newSearch
  }

  const clear = () => {
    autoComplete.clear()
    previouslySelectedState.value = undefined

    current.value.searchText = ''
    current.value.parentId = null
    current.value.parentName = null
    current.value.scrollPosition = null
    current.value.locationType = { id: null, label: 'All' }

    options.scrollToPosition?.(0)
  }

  const noResultsMessage = computed(() => {
    if (isInitialLoading.value || !isFetched.value || items.value.length > 0) {
      return
    }

    let message = 'No results found for your filters:'

    if (current.value.searchText) {
      message += `\nSearch text of "${current.value.searchText}"`
    }

    if (current.value.locationType?.label) {
      message += `\nLocation type of ${current.value.locationType?.label}`
    }

    if (current.value.parentName) {
      message += `\nWithin location ${current.value.parentName}`
    }

    return message
  })

  const rawSearchText = computed(() => current.value.searchText)
  const locationType = computed(() => current.value.locationType)
  const parentName = computed(() => current.value.parentName)
  const parentId = computed(() => current.value.parentId)

  return {
    locationType,
    parentName,
    parentId,
    items,
    isFetching,
    updateSearch,
    locationTypes,
    selectLocationType,
    selectItem,
    navigateIntoItem,
    navigateBack,
    continueFetching,
    restoreToPreviousState,
    clear,
    noResultsMessage,
    rawSearchText,
    chips: autoComplete.chips,
    inputText: autoComplete.inputText,
    handleBackSpace: autoComplete.handleBackspace,
    addValue: autoComplete.addValue,
    removeValue: autoComplete.removeValue,
    toggleValue: autoComplete.toggleValue,
  }
}
