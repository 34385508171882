<template>
  <div ref="wrapper" class="bottom-sheet-layout-wrapper">
    <div
      ref="top-bar"
      class="top-bar"
      :class="{ 'is-sticky': isTopBarSticked }"
    >
      <v-row no-gutters>
        <v-col>
          <v-row no-gutters>
            <v-col>
              <vs-heading v-if="title" type="title">{{ title }}</vs-heading>
            </v-col>
          </v-row>
        </v-col>

        <v-col class="shrink">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn class="ma-0" icon v-on="on" @click="handleDismiss">
                <v-icon>close</v-icon>
              </v-btn>
            </template>

            Close
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <slot name="top-bar-ext" />
      </v-row>
    </div>

    <div ref="content" class="content" @scroll.passive="refreshBarPositions">
      <slot />
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { isScrolled } from '@/utils/form-layout'

export default {
  name: 'BottomSheetLayout',
  props: {
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isTopBarSticked: false,
      stickyBarObserver: null,
    }
  },
  mounted() {
    document.addEventListener('keyup', this.handleEscKeyPress)
    this.stickyBarObserver = new ResizeObserver(this.refreshBarPositions)
    this.stickyBarObserver.observe(this.$refs.content)
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.handleEscKeyPress)
    this.stickyBarObserver.unobserve(this.$refs.content)
  },
  methods: {
    refreshBarPositions: debounce(function () {
      const content = this.$refs.content
      if (content) {
        this.isTopBarSticked = isScrolled(content.scrollTop)
      }
    }, 20),
    handleDismiss() {
      this.$emit('on-dismiss')
    },
  },
}
</script>

<style scoped>
.bottom-sheet-layout-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.is-sticky {
  background-color: #fff;
  z-index: var(--elevation-sticky-bar);
}

.top-bar.is-sticky {
  padding: var(--space-base) var(--space-large) var(--space-small)
    var(--space-large);
  box-shadow: 0px 4px 4px -4px rgba(0, 0, 0, 0.1),
    0px 16px 16px -16px rgba(0, 0, 0, 0.1);
}

.top-bar {
  position: relative;
  flex: 0 0 auto;
  transition: all 100ms ease;
  padding: var(--space-base) var(--space-large) var(--space-small)
    var(--space-large);
}

.content {
  position: relative;
  flex: 0 1 auto;
  overflow-x: auto;
  overflow-y: overlay;
  padding: var(--space-base) var(--space-large);
}
</style>
