<template>
  <v-tooltip v-if="selectedCounter !== 'None'" top>
    <template #activator="{ on }">
      <v-chip
        data-test="select-all-toggle"
        small
        :color="chipColor"
        v-on="on"
        @click.stop="() => $emit('toggle')"
        >{{ selectedCounter }}
      </v-chip>
    </template>
    Click to reset all
  </v-tooltip>
</template>

<script>
export default {
  name: 'SelectAllFilterItemsToggle',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    selectedCounter() {
      const totalCounterItems = this.items.filter(
        (item) => !('header' in item || 'divider' in item)
      )
      if (this.selected === 0) {
        return 'None'
      } else {
        return `${this.selected} / ${totalCounterItems.length}`
      }
    },
    chipColor() {
      if (this.selected === 0) {
        return 'var(--color-silver)'
      } else {
        return 'var(--color-blue--lighter)'
      }
    },
  },
}
</script>

<style scoped></style>
