<template>
  <transition :name="name" mode="out-in">
    <slot />
  </transition>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: 'pop',
      validator: (value) =>
        ['slide-left', 'slide-right', 'slide-bottom', 'pop', 'fade'].includes(
          value
        ),
    },
  },
}
</script>

<style scoped>
/* Slide */

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  transform: translateX(0);
  transition: all 200ms;
  will-change: transform;
}
.slide-bottom-enter-active,
.slide-bottom-leave-active {
  transform: translateY(0);
  transition: all 200ms;
  will-change: transform;
}

.slide-right-enter,
.slide-right-leave-to {
  transform: translateX(-100%);
}

.slide-left-enter,
.slide-left-leave-to {
  transform: translateX(100%);
}

.slide-bottom-enter,
.slide-bottom-leave-to {
  transform: translateY(100%);
}

/* Pop */

.pop-enter-active,
.pop-leave-active {
  height: 100%;
  transform: scale(1);
  transition: all 100ms;
  will-change: transform, opacity;
}
.pop-enter,
.pop-leave-to {
  transform: scale(0.99);
  opacity: 0;
}

/* Fade */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 200ms;
  will-change: opacity;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
