<template>
  <v-chip :color="actionChipColor">{{ actionChipLabel }}</v-chip>
</template>

<script>
export default {
  name: 'ActionsCell',
  props: {
    actions: {
      type: Array,
      required: true,
    },
  },
  computed: {
    actionChipColor() {
      return this.actions.filter((a) => !a.isCompleted).length === 0
        ? '#c1dec2  '
        : '#ffccbc'
    },
    actionChipLabel() {
      return `${this.actions.filter((a) => a.isCompleted).length} / ${
        this.actions.length
      }`
    },
  },
}
</script>
