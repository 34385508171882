<template>
  <vs-toggle-buttons
    small
    :items="[
      {
        text: 'Day',
        to: { name: 'day-view' },
        active: $route.path.includes('day'),
      },
      {
        text: 'Week',
        to: { name: 'week-view' },
        active: !$route.path.includes('day'),
      },
    ]"
  />
</template>

<script>
export default {
  name: 'WeekViewToggle',
}
</script>

<style></style>
