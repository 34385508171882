"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlwaysEnabledEdge = void 0;
const edge_1 = require("./edge");
class AlwaysEnabledEdge extends edge_1.EdgeBase {
    constructor(source, target) {
        super();
        this.source = source;
        this.target = target;
    }
}
exports.AlwaysEnabledEdge = AlwaysEnabledEdge;
