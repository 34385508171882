import { render, staticRenderFns } from "./FieldViewJobPanelProvision.vue?vue&type=template&id=85ce1be0&scoped=true&"
import script from "./FieldViewJobPanelProvision.vue?vue&type=script&setup=true&lang=ts&"
export * from "./FieldViewJobPanelProvision.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85ce1be0",
  null
  
)

export default component.exports