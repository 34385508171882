export default class RoleAssignment {
  constructor(json) {
    this.businessUnitId = json.business_unit_id
    this.role = json.role
    this.BusinessUnitName = json.business_unit_name
  }

  businessUnitId: string
  role: string
  BusinessUnitName: string
}
