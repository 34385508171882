import moment from 'moment'

export default class JobComment {
  constructor(json) {
    this.id = json.id
    this.comment = json.comment
    this.submittedOn = json.submitted_on
    this.createdById = json.created_by
    this.createdOn = json.created
  }

  id: number
  comment: string
  submittedOn: string
  createdById: string
  createdOn: moment.Moment
}
