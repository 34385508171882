export default class UserAssociatedBusinessUnit {
  constructor(json) {
    this.id = json.id
    this.path = json.path
    this.hostIds = json.host_ids
    this.partnerIds = json.partner_ids
    this.type = json.type
  }

  id: string
  path: string
  hostIds: string[]
  partnerIds: string[]
  type: string
}
