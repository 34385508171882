"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.booleanFieldMatcher = void 0;
const boolean_field_definition_1 = require("../../form_fields/boolean_field_definition");
const json_schema_keys_1 = require("../../json_schema_keys");
const booleanFieldMatcher = (uniqueKey, isRequired, properties, uiSchema, fieldDefinitions, next) => {
    const type = properties[json_schema_keys_1.JsonSchemaKeys.TYPE_KEY];
    if (type === json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_BOOLEAN) {
        const field = new boolean_field_definition_1.BooleanFieldDefinition();
        field.isRadio = uiSchema[json_schema_keys_1.JsonSchemaKeys.UI_WIDGET_KEY] === json_schema_keys_1.JsonSchemaKeys.WIDGET_TYPE_RADIO;
        field.title = properties.title;
        field.description = properties.description;
        field.isRequired = isRequired;
        field.defaultValue = properties.default;
        return field;
    }
    return next(uniqueKey, isRequired, properties, uiSchema, fieldDefinitions);
};
exports.booleanFieldMatcher = booleanFieldMatcher;
