<template>
  <v-row align="center">
    <v-col class="pr-2">
      <vs-autocomplete
        :value="value"
        label="Operator"
        :items="sortedUsers"
        :disabled="disabled"
        hide-mini-label
        item-text="name"
        item-value="id"
        @input="handleOperatorNameInput"
      />
    </v-col>

    <v-col v-if="!disabled && menu.length !== 0" class="shrink">
      <vs-menu :items="menu">
        <template #activator>
          <vs-button icon="more_horiz" type="tertiary" />
        </template>
      </vs-menu>
    </v-col>
  </v-row>
</template>

<script>
import { orderBy } from 'lodash'

export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    canRemoveCrew: {
      type: Boolean,
    },
    canAddCrew: {
      type: Boolean,
    },
    usersList: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    menu() {
      const actions = []
      this.canAddCrew &&
        actions.push({ text: 'Add another operator', onClick: this.handleAdd })

      this.canRemoveCrew &&
        actions.push({
          text: 'Remove operator',
          onClick: this.handleDelete,
          destructive: true,
        })
      return actions
    },
    sortedUsers() {
      return orderBy(this.usersList, [(user) => user.name.toLowerCase()])
    },
  },
  methods: {
    handleOperatorNameInput(userSid) {
      this.$emit('input', {
        index: this.index,
        userSid: userSid || '',
      })
    },
    handleAdd() {
      this.$emit('on-add')
    },
    handleDelete() {
      this.$emit('on-delete', this.index)
    },
  },
}
</script>
