<template>
  <vs-wrapper space="small">
    <vs-autocomplete
      class="mt-2"
      :items="selectableDataTypes"
      label="List type"
      :value="field.itemDataType"
      :required="true"
      @input="changeItemDataType"
    >
    </vs-autocomplete>

    <v-row>
      <v-col>
        <vs-text-input
          v-model.lazy="field.minimumItems"
          label="Minimum items"
          type="number"
          :value="field.minimumItems"
          @input="updateMinimum"
        ></vs-text-input>
      </v-col>

      <v-col>
        <vs-text-input
          v-model.lazy="field.maximumItems"
          label="Maximum items"
          type="number"
          :value="field.maximumItems"
          @input="updateMaximum"
        ></vs-text-input>
      </v-col>
    </v-row>
  </vs-wrapper>
</template>

<script>
import VsWrapper from '@/components/vision/VsWrapper'
import { ArrayItemDataType } from 'ironsight-form-builder/dist/form_fields/array_field_definition'
export default {
  name: 'ArrayFieldSettings',
  components: { VsWrapper },

  props: {
    field: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    selectableDataTypes() {
      return [
        ArrayItemDataType.string,
        ArrayItemDataType.integer,
        ArrayItemDataType.decimal,
        ArrayItemDataType.user,
        ArrayItemDataType.location,
        ArrayItemDataType.businessUnit,
        ArrayItemDataType.company,
      ].map((dataType) => {
        return {
          value: dataType,
          text: this.dataTypeDisplayName(dataType),
        }
      })
    },
  },

  methods: {
    updateMinimum(minimumItems) {
      this.emitChanges(
        'minimumItems',
        minimumItems ? parseInt(minimumItems) : undefined
      )
    },
    updateMaximum(maximumItems) {
      this.emitChanges(
        'maximumItems',
        maximumItems ? parseInt(maximumItems) : undefined
      )
    },
    changeItemDataType(dataType) {
      if (dataType) {
        this.emitChanges('itemDataType', dataType)
      }
    },
    emitChanges(propertyKey, value) {
      this.$emit('modify-field', {
        propertyKey,
        fieldValue: value,
        key: this.field.uniqueKey(),
      })
    },
    dataTypeDisplayName(dataType) {
      switch (dataType) {
        case ArrayItemDataType.string:
          return 'Text'
        case ArrayItemDataType.integer:
          return 'Integer'
        case ArrayItemDataType.decimal:
          return 'Decimal'
        case ArrayItemDataType.businessUnit:
          return 'Business Unit'
        case ArrayItemDataType.company:
          return 'Company'
        case ArrayItemDataType.location:
          return 'Location'
        case ArrayItemDataType.user:
          return 'User'
      }
    },
  },
}
</script>
