"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenericSelectorDefinition = void 0;
const generic_selector_ui_schema_1 = require("../models/field_ui_schema/generic_selector_ui_schema");
const text_entry_field_definition_1 = require("./text_entry_field_definition");
class GenericSelectorDefinition extends text_entry_field_definition_1.TextEntryFieldDefinition {
    constructor() {
        super(...arguments);
        this.optionGroups = [];
    }
    uiSchema() {
        return new generic_selector_ui_schema_1.GenericSelectorUiSchema(this);
    }
}
exports.GenericSelectorDefinition = GenericSelectorDefinition;
