<template>
  <div
    :class="{
      sent: isMessageFromMyself,
      expandable: seenBy.length,
      automated: isMessageAutomated,
    }"
  >
    <v-tooltip
      v-if="senderName && !isMessageFromMyself"
      top
      class="pl-4"
      :disabled="!senderEmail"
    >
      <template #activator="{ on }">
        <span v-on="on">
          <vs-text type="caption" tag="span">{{ senderName }}</vs-text>
        </span>
      </template>
      {{ senderEmail }}
    </v-tooltip>
    <div class="chat-row">
      <div class="chat-bubble" data-test="chat-bubble" @click="handleBubbleTap">
        <vs-text>{{ messageContent }}</vs-text>
      </div>

      <v-tooltip top>
        <template #activator="{ on }">
          <span class="timestamp" v-on="on">
            <vs-text tag="span" type="overline">{{ sentTime }}</vs-text>
          </span>
        </template>
        {{ sentDate }}
      </v-tooltip>
    </div>

    <div v-if="seenBy.length" class="seen" @click="handleBubbleTap">
      <Transition name="pop">
        <vs-text
          v-if="!expandSeenReceipts"
          type="subdued"
          data-test="read-receipts"
        >
          Seen by {{ seenBy[0].name }}
          <template v-if="seenBy.length - 1 > 0">
            + {{ seenBy.length - 1 }}
          </template>
        </vs-text>
        <div v-else>
          <vs-text
            v-for="(receipt, i) in seenBy"
            :key="i"
            type="subdued"
            data-test="read-receipts"
          >
            Seen by <b>{{ receipt.name }}</b> {{ receipt.timestamp }}
          </vs-text>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import Transition from '@/components/common/Transition'

export default {
  components: { Transition },
  props: {
    message: {
      type: Object,
      default: null,
    },
    isMessageFromMyself: Boolean,
    isMessageAutomated: Boolean,
    seenBy: { type: Array, default: () => [] },
  },
  data() {
    return {
      expandSeenReceipts: false,
    }
  },
  computed: {
    ...mapGetters({
      getUserBySid: 'users/getBySid',
    }),
    user() {
      return this.message.from && this.getUserBySid(this.message.from)
    },
    sentTime() {
      return (
        this.message.timestamp &&
        moment(this.message.timestamp.toDate()).format('HH:mm')
      )
    },
    sentDate() {
      return (
        this.message.timestamp &&
        moment(this.message.timestamp.toDate()).calendar(null, {
          sameElse: 'MMM DD, YYYY H:mm',
        })
      )
    },
    senderName() {
      if (!this.message.from || !this.user) return

      let result = ''
      if (this.isMessageAutomated) {
        result += 'On behalf of '
      }
      result += this.user.name || this.user.email
      return result
    },
    senderEmail() {
      return this.user && this.user.email
    },
    messageContent() {
      return this.message.message ? this.message.message : this.message
    },
  },
  methods: {
    handleBubbleTap() {
      this.expandSeenReceipts = !this.expandSeenReceipts
    },
  },
}
</script>

<style scoped>
.chat-row {
  display: flex;
}

.chat-bubble {
  display: inline-block;
  flex: 0 1 auto;
  background-color: var(--color-white);
  padding: var(--space-small) var(--space-base);
  margin-bottom: var(--space-smaller);
  text-align: left;
  border-radius: var(--space-large);
  border-bottom-left-radius: 0;
  box-shadow: var(--shadow-low);
  word-wrap: break-word;
  word-break: break-word;
  transition: all 200ms ease;
}

.expandable .chat-bubble,
.expandable .seen {
  cursor: pointer;
}

.expandable .chat-bubble:hover {
  box-shadow: var(--shadow-high);
}

.timestamp {
  flex: 0;
  align-self: center;
  padding-left: var(--space-small);
}

.sent .chat-row {
  justify-content: flex-end;
}

.sent .chat-bubble {
  order: 2;
  background-color: var(--color-blue--lighter);
  color: var(--color-white);
  border-radius: var(--space-large);
  border-bottom-right-radius: 0;
}

.automated .chat-bubble {
  background-color: var(--color-green);
}

.sent .timestamp {
  order: 1;
  padding-left: 0;
  padding-right: var(--space-base);
}

.sent .seen {
  text-align: right;
}
</style>
