<template>
  <vs-dialog :visible="isVisible" :title="title" @on-close="onClose">
    <vs-wrapper>
      <vs-text>{{ body }}</vs-text>
      <v-row justify="end">
        <v-col class="shrink pr-2"> </v-col>
        <v-col class="shrink">
          <vs-button :label="buttonLabel" type="primary" @click="onClose" />
        </v-col>
      </v-row>
    </vs-wrapper>
  </vs-dialog>
</template>

<script>
export default {
  name: 'SchemaBasedFormDialog',
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: '',
    },
    buttonLabel: {
      type: String,
      default: 'Close',
    },
  },
  methods: {
    onClose() {
      this.$emit('on-close')
    },
  },
}
</script>
