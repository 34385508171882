import axios, { AxiosResponse } from 'axios'
import { get } from './base'

let getAuth0Source = axios.CancelToken.source()
let getFeatureFlagSource = axios.CancelToken.source()
let getOrganizationSource = axios.CancelToken.source()
let getAdminPortalSource = axios.CancelToken.source()

const configEndPoint = '/api/config'

export function getAuth0Config() {
  getAuth0Source.cancel('Cancel old get services request')
  getAuth0Source = axios.CancelToken.source()

  return axios.get(`${configEndPoint}/auth0`, {
    cancelToken: getAuth0Source.token,
  })
}

export function getFeatureFlagConfig() {
  getFeatureFlagSource.cancel('Cancel old get services request')
  getFeatureFlagSource = axios.CancelToken.source()

  return get(`${configEndPoint}/feature-flags`, {
    cancelToken: getFeatureFlagSource.token,
  })
}

export function getOrganizationConfig() {
  getOrganizationSource.cancel('Cancel old get services request')
  getOrganizationSource = axios.CancelToken.source()

  return get(configEndPoint, {
    cancelToken: getOrganizationSource.token,
  })
}

interface AdminPortalConfigResponse {
  url: string
}

export function getAdminPortalConfig(): Promise<
  AxiosResponse<AdminPortalConfigResponse>
> {
  getAdminPortalSource.cancel('Cancel old get admin portal request')
  getAdminPortalSource = axios.CancelToken.source()

  return get(`${configEndPoint}/admin-portal`, {
    cancelToken: getAdminPortalSource.token,
  })
}
