<template>
  <v-timeline-item
    :icon="icon"
    fill-dot
    color="blue lighten-4"
    icon-color="grey darken-1"
  >
    <v-row>
      <v-col>
        <vs-text
          ><b>{{ actionDescription }}</b> by {{ submittedByLabel }}</vs-text
        >
      </v-col>
      <v-col align-self="end" class="shrink">
        <vs-text>{{ submittedTimeLabel }}</vs-text>
      </v-col>
    </v-row>
    <v-row v-if="text" dense class="mt-0">
      <v-col align-self="stretch">
        <vs-text type="caption">{{ text }}</vs-text>
      </v-col>
    </v-row>
  </v-timeline-item>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'FormSubmissionTimeLineItem',
  components: {},

  props: {
    icon: {
      type: String,
      required: true,
    },
    actionDescription: {
      type: String,
      required: true,
    },
    userSid: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      getUserBySid: 'users/getBySid',
    }),
    submittedByLabel() {
      return this.getUserBySid(this.userSid)?.name ?? 'Unknown'
    },
    submittedTimeLabel() {
      return moment.utc(this.date).local().format('HH:mm')
    },
  },
}
</script>

<style scoped></style>
