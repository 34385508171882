import {
  deleteObject,
  getDownloadURL,
  getMetadata,
  getStorage,
  listAll,
  ref,
  uploadBytesResumable,
} from 'firebase/storage'
import { getHostName } from '@/utils/chat.js'
import firebase from 'firebase/compat'
import FullMetadata = firebase.storage.FullMetadata
import { AttachmentMetadata } from '@/models/attachment_metadata'

interface ReturnedAttachmentData {
  name: string
  storageId: string | undefined
  downloadUrl: string
  metadata: FullMetadata | null
}

export async function getAttachments(
  storageId: string
): Promise<ReturnedAttachmentData[]> {
  const files = await listAll(getFirebaseStorageReference(storageId))

  return await Promise.all(
    files.items.map(async (file) => {
      const url: string = await getDownloadURL(file)
      const metadata: FullMetadata = await getMetadata(file)

      return {
        name: file.name,
        storageId: file.parent?.name,
        downloadUrl: url,
        metadata,
      }
    })
  )
}

export function postAttachment(
  storageId: string,
  file: File,
  metadata: AttachmentMetadata
) {
  const storageRef = ref(getFirebaseStorageReference(storageId), file.name)
  return uploadBytesResumable(storageRef, file, metadata)
}

export function deleteAttachment(
  storageId: string,
  filename: string
): Promise<void> {
  const storageRef = ref(getFirebaseStorageReference(storageId), filename)
  return deleteObject(storageRef)
}

function getFirebaseStorageReference(storageId: string): any {
  const storage = getStorage()
  const storageRef = ref(storage, getHostName())
  return ref(storageRef, storageId)
}
