<template>
  <FormLayout
    title="Completion Comment"
    :full-screen="false"
    @on-dismiss="$emit('dismiss')"
  >
    <vs-form ref="form">
      <vs-text-input
        v-model="comment"
        multiline
        label="Completion Comment"
        required
      />
    </vs-form>

    <template #action-bar>
      <vs-button label="Submit" full-width @click="handleSubmit" />
    </template>
  </FormLayout>
</template>

<script>
import FormLayout from '@/components/common/FormLayout'

export default {
  name: 'CompleteFollowUpActionsComment',
  components: {
    FormLayout,
  },
  data() {
    return {
      comment: '',
    }
  },
  methods: {
    handleSubmit() {
      if (this.$refs.form.isValid()) {
        if (this.comment.trim().length !== 0) {
          this.$emit('completeWithComment', this.comment)
        }
      }
    },
  },
}
</script>

<style scoped></style>
