<template>
  <vs-autocomplete
    :value="value"
    :items="filteredCostCenters"
    item-text="labelWithLinkedInfo"
    item-value="label"
    :label="label"
    :rules="rules"
    required
    free-form-create
    :disabled="disabled"
    @input="handleInput"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import { checkSearchResult } from '../../utils/search'
import { isEqual } from 'lodash'

export default {
  name: 'CostCenterField',
  props: {
    value: {
      type: String,
      default: '',
    },
    ticketOwnerId: {
      type: String,
      default: null,
    },
    ticketDestination: {
      type: Number,
      default: null,
    },
    ticketSources: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'Cost center',
    },
    hubId: {
      type: String,
      required: true,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    disabled: Boolean,
  },
  data() {
    return {
      previousCostCenterSuggestions: [],
    }
  },
  computed: {
    ...mapGetters({
      getCostCentersForHub: 'costCenters/getCostCentersForHub',
      getDivisionByUuid: 'divisions/getByUuid',
    }),
    ticketDivision() {
      return this.ticketOwnerId && this.getDivisionByUuid(this.ticketOwnerId)
    },
    suggestedCostCenters() {
      return this.getSuggestedCostCenters()
    },
    filteredCostCenters() {
      const costCentersFiltered = [...this.suggestedCostCenters]

      if (costCentersFiltered.length >= 1) {
        costCentersFiltered.unshift({ header: 'Suggested' })
        costCentersFiltered.push({ header: 'Other' })
      }

      const suggestedLabels = this.suggestedCostCenters.map((c) => c.label)
      const otherCostCenters = this.getCostCentersForHub(this.hubId).filter(
        (costCenter) => !suggestedLabels.includes(costCenter.label)
      )

      return costCentersFiltered.concat(
        otherCostCenters.map((costCenter) => {
          return {
            label: costCenter.label,
            labelWithLinkedInfo:
              costCenter.labelWithLinkedInfo || costCenter.label,
            description: costCenter.description,
          }
        })
      )
    },
  },
  watch: {
    suggestedCostCenters(newValue) {
      if (!isEqual(newValue, this.previousCostCenterSuggestions)) {
        this.previousCostCenterSuggestions = newValue
        if (newValue.length === 1) {
          this.$emit('input', newValue[0].label)
        } else if (newValue.length > 1) {
          this.$emit('input', null)
        }
      }
    },
  },
  created() {
    this.previousCostCenterSuggestions = this.getSuggestedCostCenters()
    if (this.filteredCostCenters.length === 1) {
      this.autoFill('cost-center', this.filteredCostCenters[0].label)
    }
  },
  methods: {
    getSuggestedCostCenters() {
      return this.getCostCentersForHub(this.hubId)
        .filter((costCenter) => {
          if (
            (costCenter.divisionIds.length === 0 &&
              costCenter.locationIds.length === 0) ||
            (!this.ticketDivision &&
              this.ticketSources.length === 0 &&
              !this.ticketDestination)
          ) {
            return false
          } else if (costCenter.divisionIds.length === 0) {
            return (
              this.ticketSources.some((source) =>
                costCenter.locationIds.includes(source)
              ) || costCenter.locationIds.includes(this.ticketDestination)
            )
          } else if (costCenter.locationIds.length === 0) {
            return costCenter.divisionIds.includes(this.ticketDivision?.id)
          } else {
            if (
              !this.ticketDivision ||
              (!this.ticketDestination && this.ticketSources.length === 0)
            )
              return false

            return (
              costCenter.divisionIds.includes(this.ticketDivision?.id) &&
              (this.ticketSources.some((source) =>
                costCenter.locationIds.includes(source)
              ) ||
                costCenter.locationIds.includes(this.ticketDestination))
            )
          }
        })
        .map((costCenter) => {
          const linkedWith = []
          if (costCenter.divisionIds.includes(this.ticketDivision?.id))
            linkedWith.push('division')
          if (
            this.ticketSources.some((source) =>
              costCenter.locationIds.includes(source)
            )
          )
            linkedWith.push('pick up location')
          if (costCenter.locationIds.includes(this.ticketDestination))
            linkedWith.push('destination')

          let linkedWithString = ''
          if (linkedWith.length === 1)
            linkedWithString = `(Linked with ${linkedWith[0]})`
          else
            linkedWithString = `(Linked with ${linkedWith
              .slice(0, -1)
              .join(', ')} and ${linkedWith[linkedWith.length - 1]})`

          return {
            label: costCenter.label,
            labelWithLinkedInfo: `${costCenter.label} ${linkedWithString}`,
            description: costCenter.description,
          }
        })
    },
    searchCostCenters(item, queryText) {
      return (
        checkSearchResult(queryText, item.label) ||
        checkSearchResult(queryText, item.description)
      )
    },
    handleInput(newValue) {
      this.$emit('input', newValue)
    },
    autoFill(field, value) {
      this.$emit('auto-fill', field, value)
    },
  },
}
</script>

<style></style>
