import { isEmpty, orderBy } from 'lodash'
import moment from 'moment'
import {
  addJobToProject,
  createProject,
  editProject,
  getProject,
  getProjectJobs,
  getProjects,
  removeJobFromProject,
} from '../../api/projects'
import Project from '../../models/project'
import { checkSearchResult } from '../../utils/search'
import { errorMutationHandler } from '../utils'

const state = () => ({
  loading: false,
  loadingProjects: false,
  loadingProjectJobs: false,
  projectsFetched: null,
  projectsFinishFetched: null,
  error: null,
  addJobError: null,
  removeJobError: null,
  saving: false,
  all: {},
  page: 1,
  itemsPerPage: 8,
  search: '',
})

export const getters = {
  getLoading: (state) => state.loading,
  getAll: (state) => orderBy(Object.values(state.all), ['created'], ['desc']),
  getById: (state) => (id) => state.all[id],
  getError: (state) => state.error,
  getAddJobError: (state) => state.addJobError,
  getJobsByProjectId: (_state, _getters, rootState) => (id) => {
    return isEmpty(rootState.jobs.all)
      ? []
      : Object.values(rootState.jobs.all).filter((job) => job.projectId === id)
  },
  getCurrentPage: (state) => state.page,
  getSearchedProjects: (state, getters) => (ownerId) =>
    getters
      .getScopedProjects(ownerId)
      .filter((p) => checkSearchResult(state.search, p.displayName)),
  getTotalPages: (state, getters) => (ownerId) =>
    Math.ceil(getters.getSearchedProjects(ownerId).length / state.itemsPerPage),
  getPageForProjectId: (state, getters) => (id) => {
    const project = getters.getById(id)
    const searchedProjects = getters.getSearchedProjects(project.ownerId)

    return Math.ceil(
      (searchedProjects.findIndex((p) => p.id === project.id) + 1) /
        state.itemsPerPage
    )
  },
  getPageProjects: (state, getters) => (ownerId) => {
    return getters
      .getSearchedProjects(ownerId)
      .slice(
        (state.page - 1) * state.itemsPerPage,
        state.page * state.itemsPerPage
      )
  },
  getScopedProjects: (_state, getters) => (ownerId) => {
    // scope to job being passed in
    return getters.getAll.filter((project) => project.ownerId === ownerId)
  },
}

export const actions = {
  async fetchMissingProjectsByIds({ state, commit }, projectIds) {
    if (state.loadingProjects) {
      return
    }

    const uniqueIds = [...new Set(projectIds)]
    const idsToLoad = uniqueIds.filter((id) => id != null && !(id in state.all))

    if (idsToLoad.length > 0) {
      commit('LOAD_PROJECTS', false)
      try {
        const { data } = await getProjects(idsToLoad)
        commit('RECEIVE_PROJECTS', data)
      } catch (err) {
        commit('ERROR_PROJECT', err)
      }
    }
  },
  async fetchProjects({ state, commit }, forceFetch = false) {
    if (
      forceFetch ||
      !state.projectsFetched ||
      moment(Date.now()).diff(state.projectsFetched, 'minutes') >= 5
    ) {
      commit('LOAD_PROJECTS', true)

      try {
        const { data } = await getProjects()
        commit('RECEIVE_PROJECTS', data)
      } catch (err) {
        commit('ERROR_PROJECT', err)
      }
    }
  },
  async fetchProject({ commit }, projectId) {
    commit('LOAD_PROJECT')

    try {
      const { data } = await getProject(projectId)
      commit('RECEIVE_PROJECT', new Project(data))
    } catch (err) {
      commit('ERROR_PROJECT', err)
    }
  },
  async fetchJobsByProjectId({ commit }, projectId) {
    commit('LOAD_PROJECT_JOBS', true)

    try {
      const { data } = await getProjectJobs(projectId)
      commit('jobs/RECEIVE_JOBS_JSON', { data }, { root: true })
      commit('LOAD_PROJECT_JOBS', false)
    } catch (err) {
      commit('ERROR_PROJECT', err)
    }
  },
  async createProject(
    { commit, dispatch },
    { projectName, jobId = null, ownerId }
  ) {
    commit('SAVE_PROJECT')
    try {
      const createProjectResponse = await createProject(projectName, ownerId)
      const projectId = createProjectResponse.data.id
      const getProjectResponse = await getProject(projectId)

      const newProject = new Project(getProjectResponse.data)
      commit('RECEIVE_PROJECT', newProject)

      if (jobId !== null) {
        await dispatch('addJobToProject', { projectId, jobId })
      }

      return projectId
    } catch (err) {
      commit('ERROR_PROJECT', err)
    }
  },
  async updateProject({ commit }, { projectId, projectName }) {
    commit('SAVE_PROJECT')
    try {
      await editProject(projectId, projectName)

      commit('EDIT_PROJECT_NAME', { projectId, projectName })
    } catch (err) {
      commit('ERROR_PROJECT', err)
    }
  },
  async addJobToProject({ commit }, { projectId, jobId }) {
    try {
      commit('jobs/SAVING_JOB', {}, { root: true })
      commit('CLEAR_ADD_JOB_ERROR')
      await addJobToProject(projectId, jobId)
      commit('jobs/ADD_TO_PROJECT', { projectId, id: jobId }, { root: true })
    } catch (err) {
      commit('ADD_JOB_ERROR', err)
      commit('jobs/ERROR_SAVING_JOB', {}, { root: true })
    }
  },
  async removeJob({ commit }, { projectId, jobId }) {
    try {
      commit('jobs/SAVING_JOB', {}, { root: true })
      commit('CLEAR_REMOVE_JOB_ERROR')
      await removeJobFromProject(projectId, jobId)
      commit('jobs/REMOVE_FROM_PROJECT', jobId, { root: true })
    } catch (err) {
      commit('REMOVE_JOB_ERROR', err)
      commit('jobs/ERROR_SAVING_JOB', {}, { root: true })
    }
  },
}

export const mutations = {
  SAVE_PROJECT(state) {
    state.saving = true
  },
  LOAD_PROJECT(state) {
    state.loading = true
  },
  LOAD_PROJECTS(state, fullFetch) {
    if (fullFetch) {
      state.projectsFetched = Date.now()
    }
    state.loadingProjects = true
  },
  LOAD_PROJECT_JOBS(state, loading) {
    state.loadingProjectJobs = loading
  },
  EDIT_PROJECT_NAME(state, { projectId, projectName }) {
    const project = state.all[projectId]

    if (project) {
      project.name = projectName

      state.all = Object.freeze({ ...state.all, [projectId]: project })
    }
    state.saving = false
  },
  RECEIVE_PROJECT(state, project) {
    state.all = Object.freeze({ ...state.all, [project.id]: project })
    state.saving = false
    state.loading = false
  },
  RECEIVE_PROJECTS(state, data) {
    const projects = {}

    data.forEach((json) => {
      projects[json.id] = new Project(json)
    })

    state.all = Object.freeze({ ...state.all, ...projects })
    state.loadingProjects = false
    state.projectsFinishFetched = Date.now()
  },
  ERROR_PROJECT(state, error) {
    errorMutationHandler(state, error)
    state.loadingProjects = false
    state.loadingProjectJobs = false
  },
  CLEAR_ERROR_PROJECT(state) {
    state.error = null
  },
  UPDATE_CURRENT_PAGE(state, page) {
    state.page = page
  },
  UPDATE_SEARCH(state, search) {
    state.search = search
  },
  ADD_JOB_ERROR(state, error) {
    state.addJobError = error
  },
  CLEAR_ADD_JOB_ERROR(state) {
    state.addJobError = null
  },
  REMOVE_JOB_ERROR(state, error) {
    state.removeJobError = error
  },
  CLEAR_REMOVE_JOB_ERROR(state) {
    state.removeJobError = null
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
