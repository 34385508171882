import * as fb from 'ironsight-form-builder'
import { ArrayItemDataType } from 'ironsight-form-builder/dist/form_fields/array_field_definition'

export default function formFieldIcon(field) {
  if (field instanceof fb.ReferenceField) {
    return formFieldIcon(field.reference)
  }

  if (field instanceof fb.ObjectFieldDefinition) {
    return field.isArray ? 'space_dashboard' : 'view_agenda'
  }

  if (field instanceof fb.ArrayFieldDefinition) {
    switch (field.itemDataType) {
      case ArrayItemDataType.user:
        return 'group'
      case ArrayItemDataType.decimal:
        return 'tag'
      case ArrayItemDataType.integer:
        return '123'
      case ArrayItemDataType.company:
        return 'apartment'
      case ArrayItemDataType.businessUnit:
        return 'villa'
      case ArrayItemDataType.location:
        return 'add_location_alt'
      case ArrayItemDataType.string:
        return 'short_text'
    }
  }

  if (field instanceof fb.LookupFieldDefinition) {
    switch (field.lookupEntity.entity) {
      case 'user':
        return 'person'
      case 'company':
        return 'apartment'
      case 'location':
        return 'place'
      case 'business_unit':
        return 'villa'
    }
    return 'question_mark'
  }

  if (field instanceof fb.CreateableSelectorDefinition) {
    return 'arrow_circle_down'
  }

  if (field instanceof fb.DateFieldDefinition) {
    return field.allowTimeOfDaySelection ? 'today' : 'calendar_today'
  }

  if (field instanceof fb.NumberFieldDefinition) {
    return field.allowDecimals ? 'tag' : '123'
  }

  if (field instanceof fb.GenericSelectorDefinition) {
    return 'expand_circle_down'
  }

  if (field instanceof fb.EnumStringFieldDefinition) {
    return 'radio_button_checked'
  }

  if (field instanceof fb.FormButtonsDefinition) {
    return 'check_circle'
  }

  if (field instanceof fb.TextEntryFieldDefinition) {
    if (field.isMultiline) {
      return 'notes'
    }
    return 'short_text'
  }

  if (field instanceof fb.BooleanFieldDefinition) {
    return 'contrast'
  }

  if (field instanceof fb.HeadingFieldDefinition) {
    return 'text_fields'
  }

  if (field instanceof fb.MarkdownFieldDefinition) {
    return 'mode'
  }

  if (field instanceof fb.LatLntSelectorDefinition) {
    return 'place'
  }

  if (field instanceof fb.FileSelectorDefinition) {
    return 'attach_file'
  }

  return 'question_mark'
}
