"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lookupFieldMatcher = void 0;
const lookup_field_definition_1 = require("../../form_fields/lookup_field_definition");
const json_schema_keys_1 = require("../../json_schema_keys");
const lookup_entity_1 = require("../../models/lookup_entity");
const lookupFieldMatcher = (uniqueKey, isRequired, properties, uiSchema, fieldDefinitions, next) => {
    var lookupEntity = null;
    const hasReservedKey = uniqueKey === json_schema_keys_1.JsonSchemaKeys.SUBJECT_FIELD_KEY || uniqueKey === json_schema_keys_1.JsonSchemaKeys.SUBJECT_LOCATION_FIELD_KEY;
    switch (uiSchema[json_schema_keys_1.JsonSchemaKeys.UI_WIDGET_KEY]) {
        case json_schema_keys_1.JsonSchemaKeys.WIDGET_TYPE_BUSINESS_UNIT_SELECTOR:
            lookupEntity = new lookup_entity_1.BusinessUnitLookup();
            break;
        case json_schema_keys_1.JsonSchemaKeys.WIDGET_TYPE_COMPANY_SELECTOR:
            lookupEntity = new lookup_entity_1.CompanyLookup();
            break;
        case json_schema_keys_1.JsonSchemaKeys.WIDGET_TYPE_USER_SELECTOR:
            lookupEntity = new lookup_entity_1.UserLookup();
            break;
        case json_schema_keys_1.JsonSchemaKeys.WIDGET_TYPE_LOCATION_SELECTOR:
            lookupEntity = new lookup_entity_1.LocationLookup();
            break;
    }
    if (lookupEntity !== null && !hasReservedKey) {
        const field = new lookup_field_definition_1.LookupFieldDefinition(lookupEntity);
        field.title = properties.title;
        field.description = properties.description;
        field.isRequired = isRequired;
        field.defaultValue = properties.default;
        return field;
    }
    return next(uniqueKey, isRequired, properties, uiSchema, fieldDefinitions);
};
exports.lookupFieldMatcher = lookupFieldMatcher;
