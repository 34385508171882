import BaseObject from './base_object'

const defaultObject = {
  id: null,
  service_id: null,
  name: '',
  is_visible: true,
}
export default class ResourceType extends BaseObject {
  serviceId: number | null
  isVisible: boolean
  constructor(json) {
    if (!json) {
      json = defaultObject
    }
    super(json)
    this.serviceId = json.service_id
    this.isVisible = json.is_visible
  }

  get isArchived() {
    return !this.isVisible
  }
}
