export default class Project {
  id: number
  name: string
  number: number
  ownerId: string
  created: string
  createdBy: string
  requestedJobCount: number
  assignedToServiceProviderJobCount: number
  scheduledJobCount: number
  activeJobCount: number
  pausedJobCount: number
  completedJobCount: number
  cancelledJobCount: number
  totalJobCount: number

  constructor(json) {
    this.id = json.id
    this.name = json.name
    this.number = json.number
    this.ownerId = json.owner_id
    this.created = json.created
    this.createdBy = json.created_by
    this.requestedJobCount = json.requested_job_count
    this.assignedToServiceProviderJobCount =
      json.assigned_to_service_provider_job_count
    this.scheduledJobCount = json.scheduled_job_count
    this.activeJobCount = json.active_job_count
    this.pausedJobCount = json.paused_job_count
    this.completedJobCount = json.completed_job_count
    this.cancelledJobCount = json.cancelled_job_count
    this.totalJobCount = json.total_job_count
  }

  get displayName(): string {
    return `${this.name} #${this.number}`
  }
}
