import axios from 'axios'
import { get, post } from './base'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'

const getUserSource = axios.CancelToken.source()

const usersEndpoint = '/api/v2/users'

export function getUsers() {
  return get(usersEndpoint, {
    cancelToken: getUserSource.token,
  })
}

export function getUser(sid) {
  return get(`${usersEndpoint}/${sid}`, {
    cancelToken: getUserSource.token,
  })
}

export function getCurrentUserContext() {
  return get(`${usersEndpoint}/current/context`, {
    cancelToken: getUserSource.token,
  })
}

export function useUserQuery(sid) {
  const { data } = useQuery({
    queryKey: ['users', sid],
    queryFn: () => getUser(sid.value).then((r) => r.data),
  })

  return {
    data,
  }
}

function createUser(
  defaultTeamId: string | null,
  name: string,
  email: string,
  phone: string | null,
  roles: string[],
  scopes: string[],
  sendEmailToNewUser: boolean
) {
  return post(usersEndpoint, {
    data: {
      default_team_id: defaultTeamId,
      name: name,
      email: email,
      phone: phone,
      roles: roles,
      scopes: scopes,
      send_email_to_new_user: sendEmailToNewUser,
    },
  })
}

export function useCreateUserMutation(
  onSuccessCallback?: (userSid: string) => void
) {
  return useMutation({
    mutationFn: (variables: {
      defaultTeamId: string | null
      name: string
      email: string
      phone: string | null
      roles: string[]
      scopes: string[]
      sendEmailToNewUser: boolean
    }) =>
      createUser(
        variables.defaultTeamId,
        variables.name,
        variables.email,
        variables.phone,
        variables.roles,
        variables.scopes,
        variables.sendEmailToNewUser
      ).then((r) => r.data),
    onSuccess: (result) => {
      onSuccessCallback?.(result)
    },
  })
}

export function sendInvitationEmailToGuestUser(
  userSid: string,
  companyName: string
) {
  return post(`${usersEndpoint}/guest-user/send-invitation-email`, {
    data: {
      user_sid: userSid,
      company_name: companyName,
    },
  })
}
