import { del, get } from './base'
import { getInstance } from '@/auth'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { MaybeRef } from '@tanstack/vue-query/build/lib/types'
import { computed, unref } from 'vue'
import JobSignature, { SignatureMetadataJson } from '../models/job-signature'
import { v4 as uuidv4 } from 'uuid'

const signatureEndpoint = '/api/v3/jobs'

export function useJobSignatureQuery(
  jobId: MaybeRef<string>,
  signatureId?: MaybeRef<string | undefined>
) {
  const computedJobId = computed(() => unref(jobId))
  const computedSignatureId = computed(() => unref(signatureId))
  const queryKey = computed(() => ['signature', computedSignatureId.value])

  const enabled = computed(() => !!computedSignatureId.value)

  return useQuery({
    queryKey,
    queryFn: async () => {
      const response = await get<any>(
        `${signatureEndpoint}/${computedJobId.value}/line-items/signatures/${computedSignatureId.value}`,
        { responseType: 'blob' }
      )

      return response.data
    },
    enabled,
  })
}

export function useAddSignatureMutation() {
  return useMutation({
    mutationFn: (variables: {
      jobId: string
      signedAt: string
      name: string
      email: string
      signatureMetadata: SignatureMetadataJson
      signatureFile: File
    }) =>
      addSignature(
        variables.jobId,
        variables.signedAt,
        variables.name,
        variables.email,
        variables.signatureMetadata,
        variables.signatureFile
      ),
  })
}

export function useDeleteSignatureMutation() {
  return useMutation({
    mutationFn: (variables: { signatureId: string; jobId: string }) =>
      deleteSignature(variables.signatureId, variables.jobId),
  })
}

async function addSignature(
  jobId: string,
  signedAt: string,
  name: string,
  email: string,
  signatureMetadata: SignatureMetadataJson,
  signatureFile: File
) {
  const formData = new FormData()
  formData.append('jobId', jobId)
  formData.append('Id', uuidv4())
  formData.append('signedAt', signedAt)
  formData.append('name', name)
  formData.append('email', email)
  formData.append('metadata', JSON.stringify(signatureMetadata))
  formData.append('signatureFile', signatureFile)

  const authService = getInstance()
  const token = await authService.getTokenSilently()

  const response = await fetch(
    `${signatureEndpoint}/${jobId}/line-items/signatures`,
    {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )

  if (response.ok) {
    const data = await response.json()
    return new JobSignature(data)
  }
}

async function deleteSignature(signatureId: string, jobId: string) {
  return del(
    `${signatureEndpoint}/${jobId}/line-items/signatures/${signatureId}`
  )
}
