import { get } from './base'
import { useQuery } from '@tanstack/vue-query'
import axios from 'axios'
import { convertToCamelCase } from '@/models/utils/casing'
import _ from 'lodash'

export type OptionDTO = {
  id: any
  label: string
  category: string
}

export interface BaseQuery {
  searchTerm?: string | null
}

type OptionType =
  | 'resource-types'
  | 'divisions'
  | 'resource-owners'
  | 'managed-companies'
  | 'all-hubs'

export function getOptions(
  type: OptionType,
  queryParams: URLSearchParams,
  signal?: AbortSignal
) {
  const source = axios.CancelToken.source()

  const filterOptions = get<OptionDTO[]>(
    `/api/v3/options/${type}?${queryParams.toString()}`,
    {
      cancelToken: source.token,
    }
  ).then((r) => convertToCamelCase(r.data))

  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled by TanStack Query')
  })

  return filterOptions
}

function buildQueryParams<T extends BaseQuery>(obj: T): URLSearchParams {
  return new URLSearchParams(
    Object.keys(obj).reduce(
      (a, b) => ({ ...a, b: obj[b]?.toString() }),
      {}
    ) as Record<string, string>
  )
}

export function useGetResourceTypeOptions(opts?: { onlyInUse: boolean }) {
  const type: OptionType = 'resource-types'
  const queryParams = buildQueryParams(opts ?? {})

  return useQuery({
    queryKey: ['options', type],
    queryFn: ({ signal }) => {
      return getOptions(type, queryParams, signal)
    },
    select: convertToDropdownFormat,
    staleTime: Infinity,
    refetchOnMount: false,
  })
}

export function useGetDivisionOptions() {
  const type: OptionType = 'divisions'
  const queryParams = buildQueryParams({})

  return useQuery({
    queryKey: ['options', type],
    queryFn: ({ signal }) => {
      return getOptions(type, queryParams, signal)
    },
    select: convertToDropdownFormat,
    staleTime: Infinity,
    refetchOnMount: false,
  })
}

export function useGetResourceOwnerOptions() {
  const type: OptionType = 'resource-owners'
  const queryParams = buildQueryParams({})

  return useQuery({
    queryKey: ['options', type],
    queryFn: ({ signal }) => {
      return getOptions(type, queryParams, signal)
    },
    select: convertToDropdownFormat,
    staleTime: Infinity,
    refetchOnMount: false,
  })
}

export function useGetManagedCompaniesOptions() {
  const type: OptionType = 'managed-companies'
  const queryParams = buildQueryParams({})

  return useQuery({
    queryKey: ['options', type],
    queryFn: ({ signal }) => {
      return getOptions(type, queryParams, signal)
    },
    select: convertToDropdownFormat,
    staleTime: Infinity,
    refetchOnMount: false,
  })
}

export function useGetAllHubsOptions() {
  const type: OptionType = 'all-hubs'
  const queryParams = buildQueryParams({})

  return useQuery({
    queryKey: ['options', type],
    queryFn: ({ signal }) => {
      return getOptions(type, queryParams, signal)
    },
    select: convertToDropdownFormat,
    staleTime: Infinity,
    refetchOnMount: false,
  })
}

export type OptionDropdownItem = {
  value: string
  text: string
  icon?: string
  description?: string
}
export type OptionDropdownItemHeader = {
  header: string
}

const convertToDropdownFormat = (data: OptionDTO[]) => {
  return Object.entries(_.groupBy(data, (o) => o.category))
    .flatMap(([category, options]) => [
      category
        ? {
            header: category,
          }
        : null,
      ...options.map((o) => ({
        value: o.id,
        text: o.label,
      })),
    ])
    .filter((a) => a != null)
    .reduce(
      (a, b) => [...a, b!],
      [] as (OptionDropdownItem | OptionDropdownItemHeader)[]
    )
}
