import { InjectionKey, provide, Ref, ref } from 'vue'
import LocationDTO from '@/models/location'
import { injectWithSelf } from '@/utils/injectWithSelf'
import { MapboxGeoJSONFeature } from 'mapbox-gl'
import { UnitNumber } from '@/models/unit_number'

interface FieldViewState {
  hoveredFeature: MapboxGeoJSONFeature | null
  selectedLocation: LocationDTO | null
  selectedResource: UnitNumber | null
  selectedJobNumber: string | number | null
  modifiedLocations: LocationDTO[]
  editMode: boolean
  layerToggles: {
    locations: boolean
    jobs: boolean
    resources: boolean
  }
}

interface FieldViewContext {
  setEditMode: (edit: boolean) => void
  setSelectedLocation: (l: LocationDTO | null) => void
  setSelectedResource: (r: UnitNumber | null) => void
  setSelectedJobNumber: (j: string | number | null) => void
  upsertModifiedLocation: (location: LocationDTO) => void
  setHoveredFeature: (feature: MapboxGeoJSONFeature | null) => void
  toggleLayer: (key: keyof FieldViewState['layerToggles']) => void
  resetDefaultLayers: () => void
  fieldViewState: Ref<FieldViewState>
}

const FieldViewContextKey = Symbol(
  'field-view-context'
) as InjectionKey<FieldViewContext>

export const useCreateFieldViewState = () => {
  const state = ref<FieldViewState>({
    editMode: false,
    hoveredFeature: null,
    selectedLocation: null,
    selectedResource: null,
    selectedJobNumber: null,
    modifiedLocations: [],
    layerToggles: { locations: true, jobs: true, resources: true },
  })

  const setSelectedLocation = (l: LocationDTO | null) => {
    state.value.selectedLocation = l
  }

  const setSelectedResource = (r: UnitNumber | null) => {
    state.value.selectedResource = r
  }

  const setSelectedJobNumber = (j: number | string | null) => {
    state.value.selectedJobNumber = j
  }

  const setEditMode = (edit: boolean) => {
    state.value.editMode = edit
  }

  const upsertModifiedLocation = (location: LocationDTO) => {
    const idx = state.value.modifiedLocations.findIndex(
      (l) => l.id === location.id
    )
    const modifiedLocations = [...state.value.modifiedLocations]

    if (idx !== -1) {
      modifiedLocations[idx] = location
    } else {
      modifiedLocations.push(location)
    }

    state.value.modifiedLocations = modifiedLocations
  }

  const setHoveredFeature = (features: MapboxGeoJSONFeature | null) => {
    state.value.hoveredFeature = features
  }

  const toggleLayer = (key: keyof FieldViewState['layerToggles']) => {
    state.value.layerToggles[key] = !state.value.layerToggles[key]
  }

  const resetDefaultLayers = () => {
    state.value.layerToggles.jobs = true
    state.value.layerToggles.locations = true
  }

  provide(FieldViewContextKey, {
    setEditMode,
    setHoveredFeature,
    setSelectedLocation,
    setSelectedResource,
    setSelectedJobNumber,
    upsertModifiedLocation,
    toggleLayer,
    resetDefaultLayers,
    fieldViewState: state,
  })

  return { state }
}

export const useFieldView = () => {
  // if we do not define default values, if vue tries to "inject" outside of a
  // "provided" context, the component will silently fail to run, causing
  // some weird bugs.
  const defaults: FieldViewContext = {
    setSelectedLocation: (_: LocationDTO | null) => {},
    setSelectedResource: (_: UnitNumber | null) => {},
    setSelectedJobNumber: (_: string | number | null) => {},
    setEditMode: (_: boolean) => {},
    upsertModifiedLocation: (_: LocationDTO) => {},
    setHoveredFeature: (_: MapboxGeoJSONFeature | null) => {},
    toggleLayer: (_: keyof FieldViewState['layerToggles']) => {},
    resetDefaultLayers: () => {},
    fieldViewState: {} as any,
  }

  return injectWithSelf(FieldViewContextKey, () => defaults)
}
