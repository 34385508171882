import { Component, defineComponent, PropType } from 'vue'
import { isObject } from 'lodash'
import { JSONPath } from 'jsonpath-plus'
import { Formatter, JobListItem } from './columns'
import { VTooltip } from 'vuetify/lib'

export default defineComponent({
  functional: true,

  props: {
    value: {
      type: String as PropType<string>,
      required: true,
    },
    component: {
      type: Object as PropType<Component>,
      default: null,
    },
    formatter: {
      type: Function as PropType<Formatter>,
      required: false,
    },
    job: {
      type: Object as PropType<JobListItem>,
      required: true,
    },
  },

  render(createElement, { props }) {
    const value = JSONPath({
      path: props.value as string,
      json: props.job,
      wrap: false,
    })
    const attrs = { 'data-test': 'jobs-table-row-col' }
    const formatter = props.formatter ?? ((x) => x)

    let formattedValue = Array.isArray(value)
      ? value.map((v) => formatter(v, props.job))
      : formatter(value, props.job)

    if ('component' in props && props.component) {
      return createElement('td', { attrs }, [
        createElement(props.component, {
          props: { value: formattedValue, job: props.job },
        }),
      ])
    }

    if (Array.isArray(formattedValue)) {
      const [first, ...rest] = formattedValue
      if (rest.length) {
        return createElement('td', { attrs }, [
          createElement('p', [
            createElement('span', first as string),
            createElement(
              VTooltip,
              {
                attrs: { top: true, 'max-width': '250px' },
                scopedSlots: {
                  activator({ on }) {
                    return createElement('span', { on }, [
                      createElement('strong', `+ ${rest.length} more`),
                    ])
                  },
                },
              },
              rest.flatMap((x) => [
                createElement('span', x as string),
                createElement('br'),
              ])
            ),
          ]),
        ])
      } else {
        formattedValue = first
      }
    }

    if (isObject(formattedValue)) {
      return createElement('td', { attrs }, [
        createElement('pre', JSON.stringify(formattedValue, null, 2)),
      ])
    }

    return createElement('td', { attrs }, formattedValue)
  },
})
