
import { computed, defineComponent } from 'vue'
import { Prop } from 'vue/types/options'
import { priorities, priorityMap } from '@/utils/constants'

type PriorityId = typeof priorityMap[keyof typeof priorityMap]['id']

export default defineComponent({
  name: 'JobPriorityDisplay',

  props: {
    value: {
      type: Number as Prop<PriorityId>,
    },
  },

  setup(props) {
    const priority = computed(() =>
      priorities.find((p) => p.id === props.value)
    )

    return {
      priority,
    }
  },
})
