import moment from 'moment'
import InventoryItem from './inventory-item'

export default class Location {
  // Default null values required because of the item-form.js implementation.
  // Should re-write the location forms to not rely on empty objects.
  id: number | null = null
  name: string | null = null
  description: string | null = null
  location: string | null = null
  colorId: number | null = null
  locationStatusDescription: string | null = null
  locationStatusColorHexCode: string | null = null
  locationTypeId: number | null = null
  locationTypeName: string | null = null
  icon: string | null = null
  radius: number | null = null
  longitude: number | null = null
  latitude: number | null = null
  parentId: number | null = null
  parentName: string | null = null
  canEdit = true
  accessBusinessUnitIds: Array<string> = []
  ownerId: string | null = null
  ownerName: string | null = null
  ownerShortName: string | null = null
  modified: moment.Moment | null = null
  modifiedBy: string | null = null
  created: moment.Moment | null = null
  createdBy: string | null = null
  customData: object | null = null
  isArchived: boolean | null = null
  inventory: InventoryItem[] | null = null
  childCount: number | null = null
  private: boolean | null = null

  constructor(json) {
    if (!json) return

    this.id = json.id
    this.name = json.name
    this.description = json.description
    this.location = json.location
    this.colorId = json.color_id
    this.locationStatusDescription = json.location_status_description
    this.locationStatusColorHexCode = json.location_status_color_hex_code
    this.locationTypeId = json.location_type_id
    this.locationTypeName = json.location_type_name
    this.icon = json.icon
    this.radius = json.radius
    this.longitude = json.longitude
    this.latitude = json.latitude
    this.parentId = json.parent_id
    this.canEdit = json.can_edit
    this.accessBusinessUnitIds = json.access_business_unit_ids
    this.ownerId = json.owner_id
    this.ownerName = json.owner_name
    this.ownerShortName = json.owner_short_name
    this.modified = json.modified
    this.modifiedBy = json.modified_by
    this.created = json.created
    this.createdBy = json.created_by
    this.customData = json.custom_data
    this.isArchived = json.is_archived
    this.inventory = json.inventory?.map((json) => new InventoryItem(json))
    this.childCount = json.child_count
    this.private = json.private
  }
}
