import PDFFormat from '@/models/pdf_format'

export default class Company {
  id: string
  name: string
  shortCode: string
  billingAddress: string
  email: string
  organizationId: string
  hostIds: string[]
  pdfFormats: PDFFormat[]

  constructor(data) {
    this.id = data.id
    this.name = data.name
    this.shortCode = data.short_code
    this.billingAddress = data.billing_address
    this.email = data.email
    this.organizationId = data.organization_id
    this.hostIds = data.host_ids
    this.pdfFormats = data.pdf_formats.map((p) => new PDFFormat(p))
  }
}
