
import moment from 'moment'
import PopOver from '../../common/PopOver.vue'
import { defineComponent } from 'vue'
import { Prop } from 'vue/types/options'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'CellBase',

  components: { PopOver },

  props: {
    start: {
      type: Object as Prop<moment.Moment>,
      required: true,
    },
    end: {
      type: Object as Prop<moment.Moment>,
      required: true,
    },
    color: {
      type: String,
      default: null,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    weekStart: {
      type: Object as Prop<moment.Moment>,
      required: true,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
  },

  data(): {
    hoverTimeoutId?: number
    bounds: { left: number; top: number; height: number } | null
    showPopOver: boolean
  } {
    return { hoverTimeoutId: undefined, bounds: null, showPopOver: false }
  },

  computed: {
    startDay() {
      return this.start.format('ddd').toLowerCase()
    },
    endDay() {
      return this.end.format('ddd').toLowerCase()
    },
    isCrossingFromPreviousWeek() {
      return !this.start.isSame(this.weekStart, 'week')
    },
    isCrossingToNextWeek() {
      return !this.end.isSame(this.weekStart, 'week')
    },
    determineStartDay() {
      return this.isCrossingFromPreviousWeek ? 'sun' : this.startDay
    },
    determineEndDay() {
      return this.isCrossingToNextWeek ? 'sat' : this.endDay
    },
    ...mapGetters('featureFlags', {
      weekViewDoubleHeightJobCard: 'weekViewDoubleHeightJobCard',
    }),
    classStyle() {
      const cssClass = [
        'cell',
        {
          'is-saving': this.isSaving,
          'is-crossing-from-previous-week': this.isCrossingFromPreviousWeek,
          'is-crossing-to-next-week': this.isCrossingToNextWeek,
        },
      ]
      if (this.weekViewDoubleHeightJobCard) {
        cssClass.push('double-height-cell')
        if (this.isCrossingFromPreviousWeek) {
          cssClass.push('is-crossing-from-previous-week-double-height')
        }
        if (this.isCrossingToNextWeek) {
          cssClass.push('is-crossing-to-next-week-dounle-height')
        }
      }
      return cssClass
    },
  },

  methods: {
    startDrag(event: DragEvent) {
      window.clearTimeout(this.hoverTimeoutId)
      this.showPopOver = false
      this.$emit('drag', event)
    },
    handleMouseEnter(event: MouseEvent) {
      const rect = (event.currentTarget as HTMLElement).getBoundingClientRect()
      this.bounds = { left: rect.x, top: rect.y, height: rect.height }

      this.hoverTimeoutId = window.setTimeout(
        () => (this.showPopOver = true),
        750
      )
    },
    handleMouseLeave(_: MouseEvent) {
      window.clearTimeout(this.hoverTimeoutId)
      this.showPopOver = false
    },
  },
})
