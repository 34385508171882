import { mergeSchemas } from '@/utils/forms/form-resolver.js'

export class ConditionalDependencySchema {
  conditionalSchema: object
  propertySchema: object

  constructor(conditionalSchema: object, propertySchema: object) {
    this.conditionalSchema = conditionalSchema
    this.propertySchema = propertySchema
  }

  mergeConditionalSchema(other: ConditionalDependencySchema) {
    this.conditionalSchema = mergeSchemas(
      this.conditionalSchema,
      other.conditionalSchema
    )
  }
}
