import mapboxgl from 'mapbox-gl'

export const resourcePinStyle: Partial<mapboxgl.SymbolLayer> = {
  layout: {
    'symbol-sort-key': [
      'case',
      ['==', ['get', 'Status'], 'Active'],
      1,
      ['==', ['get', 'OnDuty'], 'true'],
      2,
      ['==', ['get', 'Status'], 'Standby'],
      3,
      ['==', ['get', 'Status'], 'Offline'],
      4,
      ['to-number', ['id']],
    ],
    'icon-size': 1,
    'icon-rotate': ['get', 'LastLocationHeading'],
    'icon-allow-overlap': {
      stops: [
        [13, false],
        [14, true],
      ],
    },
    'icon-rotation-alignment': 'map',
    'icon-anchor': 'center',
    'icon-image': [
      'concat',
      'arrow-',
      [
        'case',
        ['==', ['get', 'Status'], 'Offline'],
        'offline-',
        ['==', ['get', 'Status'], 'Active'],
        'active-',
        'standby-',
      ],
      ['case', ['==', ['get', 'OnDuty'], true], 'op', 'noop'],
    ],
    'text-field': ['to-string', ['get', 'Name']],
    'text-font': ['Montserrat Medium', 'Arial Unicode MS Regular'],
    'text-radial-offset': 1.3,
    'text-line-height': 1.1,
    'text-optional': true,
    'text-allow-overlap': false,
    'text-anchor': 'top',
    'text-letter-spacing': 0.1,
    'text-size': {
      stops: [
        [14, 0],
        [15, 11],
        [15.5, 12],
        [17, 14],
      ],
    },
  },
  paint: {
    'text-halo-color': 'hsl(0, 5%, 0%)',
    'text-color': [
      'case',
      ['boolean', ['feature-state', 'selected'], false],
      'rgb(30, 144, 255)',
      'hsl(0, 0%, 95%)',
    ],
    'text-halo-width': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      3,
      2,
    ],
    'text-halo-blur': 0.5,
    'text-opacity': {
      stops: [
        [15.5, 0],
        [16, 1],
      ],
    },
  },
}
