import { isEqual, keyBy } from 'lodash'
import Vue from 'vue'
import * as api from '../../api/divisions'
import Division from '../../models/division'
import errorNotification from './error-notification'

export const LOADING_DIVISIONS = 'LOADING_DIVISIONS'
export const RECEIVE_DIVISIONS = 'RECEIVE_DIVISIONS'
export const RECEIVE_ITEM = 'RECEIVE_ITEM'
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const ERROR_LOADING = 'ERROR_LOADING'

const modules = {
  errorNotification,
}

const state = () => {
  return {
    all: {},
    loading: false,
    error: null,
  }
}

export const getters = {
  getById: (state) => (id) => state.all[id] || null,

  uuidMap: (state) => keyBy(Object.values(state.all), 'uuid'),

  getByUuid: (_state, getters) => (uuid) => getters.uuidMap[uuid],

  getAllDivisions: (state) => state.all,

  getDivisionsWithHub: (_state, getters, _rootState, rootGetters) =>
    Object.values(getters.getAllDivisions).map((d) => {
      const hub = rootGetters['businessUnits/getBusinessUnitById'](d.ownerId)

      return {
        ...d,
        hubName: hub ? hub.name : 'Other',
      }
    }),

  getDivisionsForHub: (state) => (hubId) =>
    Object.values(state.all).filter((division) => division.ownerId === hubId),

  getCustomFieldsSchema: (state) =>
    Object.values(state.all)
      .filter((division) => division && division.jobCustomFieldsSchema)
      .map((division) => division.jobCustomFieldsSchema),
}

export const actions = {
  async fetchDivisions({ commit }) {
    commit(LOADING_DIVISIONS)

    await api
      .getAll()
      .then((res) => {
        commit(RECEIVE_DIVISIONS, res)
        commit(CLEAR_ERROR, res)
      })
      .catch((err) => commit(ERROR_LOADING, err))
  },
}

export const mutations = {
  [LOADING_DIVISIONS](state) {
    state.loading = true
  },
  [RECEIVE_DIVISIONS](state, { data }) {
    const receivedDivisions = {}

    data.forEach((json) => {
      if (
        !('job_custom_fields_schema' in json) ||
        json.job_custom_fields_schema == null
      ) {
        json.job_custom_fields_schema = {}
      } else if (!('schema' in json.job_custom_fields_schema)) {
        json.job_custom_fields_schema = {
          schema: json.job_custom_fields_schema,
        }
      }
      receivedDivisions[json.id] = new Division(json)
    })

    if (!isEqual(receivedDivisions, state.all)) {
      state.all = receivedDivisions
    }

    state.loading = false
  },
  [RECEIVE_ITEM](state, item) {
    Vue.set(state.all, item.id, item)
  },
  [ERROR_LOADING](state, err) {
    state.error = err
    state.loading = false
  },
}

export default {
  namespaced: true,
  modules,
  state,
  getters,
  actions,
  mutations,
}
