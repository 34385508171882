import { injectWithSelf } from '@/utils/injectWithSelf'
import mapboxgl from 'mapbox-gl'
import { InjectionKey, ref, Ref, ShallowRef } from 'vue'
import { RequestInterceptor } from './RequestInterceptor'

export const MapContextKey = Symbol('map-context') as InjectionKey<{
  map: ShallowRef<mapboxgl.Map>
  popup: ShallowRef<mapboxgl.Popup>
  mini: Ref<boolean | undefined>
  addRequestHandler: (key: string, handler: RequestInterceptor) => void
  removeRequestHandler: (key: string) => void
}>

export const useMap = () => {
  return injectWithSelf(MapContextKey, () => ({
    map: ref<mapboxgl.Map>(),
    popup: ref<mapboxgl.Popup>(),
    mini: ref<boolean>(),
    addRequestHandler: (_key: string, _handler: RequestInterceptor) => {},
    removeRequestHandler: (_key: string) => {},
  }))
}
