<template>
  <vs-wrapper>
    <vs-text-input
      input-class="text-left"
      type="number"
      :label="title"
      :hint="description"
      :value="formData"
      :error-messages="errorMessages"
      :disabled="disabled"
      @input="$emit('change', parseInt($event))"
    />
  </vs-wrapper>
</template>

<script>
export default {
  name: 'IntegerField',
  props: {
    errorSchema: Object,
    formData: Number,
    params: Object,
    rootSchema: Object,
    schema: Object,
    title: String,
    description: String,
    uiSchema: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    errorMessages() {
      const errors = this.errorSchema?.errors ?? []
      if (this.formData && typeof this.formData !== 'number') {
        errors.push('Number value expected.')
      }
      return errors
    },
  },
}
</script>

<style scoped></style>
