import { getCurrentInstance, ref, watchEffect, watch } from 'vue'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

export function useJobSideSheetNavigation(
  mainRouteName: string,
  detailRouteName: string
) {
  const { useGetters: useTicketsGetters } = createNamespacedHelpers('tickets')
  const inst = getCurrentInstance()
  const { getShouldNavigate: shouldNavigate } = useTicketsGetters([
    'getShouldNavigate',
  ])
  const selectedJobNumber = ref('')
  const isRightDrawerVisible = ref(false)
  const handleRowClicked = async (item) => {
    if (inst?.proxy.$route.name === detailRouteName) {
      inst?.proxy.$router.push({ name: mainRouteName })
    }

    if (shouldNavigate) {
      setTimeout(() => {
        inst?.proxy.$router.push({
          name: detailRouteName,
          params: { id: item.number },
        })
      }, 350)
    }
  }

  watchEffect(() => {
    isRightDrawerVisible.value = inst?.proxy.$route.name !== mainRouteName
  })

  watch(
    () => inst?.proxy.$route.name,
    () => {
      selectedJobNumber.value = inst?.proxy.$route.params.id || ''
    },
    { immediate: true }
  )

  return {
    selectedJobNumber,
    handleRowClicked,
    isRightDrawerVisible,
  }
}
