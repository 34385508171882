<template>
  <div class="field-wrapper">
    <div v-for="chip in displayedChips" :key="chip" class="field-chips">
      <v-chip
        class="ma-0"
        :close="!isChipsDisabled(chip)"
        :disabled="isChipsDisabled(chip)"
        @click:close="handleInput(chip)"
        @click="handleClick(chip)"
      >
        {{ getChipName(chip) }}
      </v-chip>
    </div>
    <vs-text v-if="numberOverflowChips > 0">
      ...{{ numberOverflowChips }} more selected
    </vs-text>
    <slot />
  </div>
</template>

<script>
import { startCase } from 'lodash'

export default {
  props: {
    /**
     *  The list of options that the user can choose from.
     *
     * @param {Object} items Items object.
     * @param {String} items.text The label of the option.
     * @param {String} items.description The label of the option.
     * @param {Number} items.value The ID of the option.
     * @param {String} items.icon Adds an icon beside the label.
     * @param {String} items.color Adds a color on the icon. If there's no icon, it uses an avatar to visualize the color.
     * @param {Boolean} items.disabled Disables interaction
     */
    items: {
      type: Array[Object],
      default: null,
      required: true,
    },
    /**
     * Change the property of item's text value
     */
    itemText: {
      type: String,
      default: 'text',
    },
    /**
     * Change the property of item's value
     */
    itemValue: {
      type: String,
      default: 'value',
    },
    /**
     * Determines which item from the item array should be rendered as chips
     */
    value: {
      type: Array[Number],
      default: [],
      required: true,
    },
    maximumChips: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    hasMaximumChips() {
      return !!this.maximumChips
    },
    displayedChips() {
      if (this.hasMaximumChips) {
        const displayChips = [...this.value]
        return displayChips.splice(0, this.maximumChips)
      }
      return this.value
    },
    numberOverflowChips() {
      if (this.hasMaximumChips) {
        return this.value.length - this.maximumChips
      }
      return 0
    },
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value)
    },
    handleClick(value) {
      this.$emit('click:chip', value)
    },
    getChipName(value) {
      return this.isChipAvailable(value)
        ? this.getChip(value)[this.itemText]
        : `Unknown ${startCase(
            this.label.slice(-1) === 's' ? this.label.slice(0, -1) : this.label
          )}`
    },
    getChip(value) {
      return this.items.find((item) => item[this.itemValue] === value)
    },
    isChipsDisabled(value) {
      if (this.disabled) return true
      return this.isChipAvailable(value) && this.isChipAvailable(value).disabled
    },
    isChipAvailable(value) {
      return (
        Array.isArray(this.items) &&
        this.items.find((item) => item[this.itemValue] === value)
      )
    },
  },
}
</script>

<style scoped>
.field-wrapper {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: var(--space-smaller) var(--space-small);
  box-sizing: border-box;
}

.field-chips {
  flex: 0;
  padding: var(--space-smaller);
}

.field-chips ~ input {
  padding: 0;
  height: 0px;
  width: auto;
  min-height: 0px;
  min-width: 120px;
  transition: all 200ms 200ms;
}

.field-chips ~ input:focus {
  height: 40px;
  min-height: 40px;
  padding: var(--space-small);
  transition-delay: 0ms;
}
</style>
