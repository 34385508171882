"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.headingFieldMatcher = void 0;
const heading_field_definition_1 = require("../../form_fields/heading_field_definition");
const json_schema_keys_1 = require("../../json_schema_keys");
const headingFieldMatcher = (uniqueKey, isRequired, properties, uiSchema, fieldDefinitions, next) => {
    const isHeading = uiSchema[json_schema_keys_1.JsonSchemaKeys.UI_WIDGET_KEY] === json_schema_keys_1.JsonSchemaKeys.WIDGET_TYPE_HEADING;
    if (isHeading) {
        const field = new heading_field_definition_1.HeadingFieldDefinition();
        field.title = properties.title;
        field.description = properties.description;
        return field;
    }
    return next(uniqueKey, isRequired, properties, uiSchema, fieldDefinitions);
};
exports.headingFieldMatcher = headingFieldMatcher;
