<template>
  <project-creation-form v-if="!showCreateProjectForm" @save="save" />
  <vs-button
    v-else
    label="New project"
    full-width
    @click="toggleCreateProjectForm(false)"
  />
</template>

<script>
import ProjectCreationForm from './ProjectCreationForm.vue'
import VsButton from '@/components/vision/VsButton.vue'
export default {
  components: { VsButton, ProjectCreationForm },

  data() {
    return {
      showCreateProjectForm: true,
    }
  },

  methods: {
    save(projectName) {
      this.$emit('save', projectName)
    },
    toggleCreateProjectForm(show) {
      this.showCreateProjectForm = show
    },
  },
}
</script>
