
import { computed, defineComponent, toRefs } from 'vue'
import { formatDurationAsHours } from '@/utils/date-formatting.js'
import moment from 'moment'

export default defineComponent({
  name: 'DurationDisplay',

  props: {
    start: String,
    end: String,
  },

  setup(props) {
    const { start, end } = toRefs(props)

    const displayValue = computed(() => {
      return formatDurationAsHours(
        moment.duration(moment(end.value).diff(moment(start.value))),
        true
      )
    })

    return { displayValue }
  },
})
