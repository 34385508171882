<template>
  <vs-wrapper space="small">
    <v-radio-group
      row
      mandatory
      label="Choose a number Input: "
      :value="field.allowDecimals"
      @change="toggleNumberType"
    >
      <v-radio label="Integer" :value="false"></v-radio>
      <v-radio label="Decimal" :value="true"></v-radio>
    </v-radio-group>

    <v-row>
      <v-col>
        <vs-text-input
          v-model.lazy="field.minimumValue"
          label="Minimum"
          type="number"
          :value="field.minimumValue"
          @input="updateMinimum"
        ></vs-text-input>
      </v-col>

      <v-col>
        <vs-text-input
          v-model.lazy="field.maximumValue"
          label="Maximum"
          type="number"
          :value="field.maximumValue"
          @input="updateMaximum"
        ></vs-text-input>
      </v-col>
    </v-row>
  </vs-wrapper>
</template>

<script>
import VsWrapper from '@/components/vision/VsWrapper'
import VsTextInput from '@/components/vision/Inputs/VsTextInput'

export default {
  name: 'NumberFieldSettings',
  components: { VsTextInput, VsWrapper },
  props: {
    field: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    isInteger() {
      return !this.field.allowDecimals
    },
  },
  methods: {
    toggleNumberType() {
      this.emitChanges('allowDecimals', !this.field.allowDecimals)
    },
    updateMinimum(minimumValue) {
      if (this.isInteger) {
        this.emitChanges(
          'minimumValue',
          minimumValue ? parseInt(minimumValue) : undefined
        )
      } else {
        this.emitChanges(
          'minimumValue',
          minimumValue ? parseFloat(minimumValue) : undefined
        )
      }
    },

    updateMaximum(maximumValue) {
      if (this.isInteger) {
        this.emitChanges(
          'maximumValue',
          maximumValue ? parseInt(maximumValue) : undefined
        )
      } else {
        this.emitChanges(
          'maximumValue',
          maximumValue ? parseFloat(maximumValue) : undefined
        )
      }
    },
    emitChanges(propertyKey, value) {
      this.$emit('modify-field', {
        propertyKey,
        fieldValue: value,
        key: this.field.uniqueKey(),
      })
    },
  },
}
</script>

<style scoped></style>
