<template>
  <new-location-picker
    :label="title"
    :hint="description"
    :value="formData"
    :error-messages="errorMessages"
    :disabled="disabled"
    @input="$emit('change', $event)"
  />
</template>

<script>
import NewLocationPicker from '@/components/locations/NewLocationPicker.vue'

export default {
  name: 'LocationSelectorField',
  components: { NewLocationPicker },
  props: {
    description: String,
    errorSchema: Object,
    formData: Number,
    businessUnitId: String,
    params: Object,
    rootSchema: Object,
    schema: Object,
    title: String,
    uiSchema: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    errorMessages() {
      const errors = this.errorSchema?.errors ?? []
      if (this.formData && typeof this.formData !== 'number') {
        errors.push('Location value expected.')
      }
      return errors
    },
  },
}
</script>
