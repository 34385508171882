import { render, staticRenderFns } from "./ResourceTypeSelector.vue?vue&type=template&id=bd345980&scoped=true&"
import script from "./ResourceTypeSelector.vue?vue&type=script&setup=true&lang=ts&"
export * from "./ResourceTypeSelector.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./ResourceTypeSelector.vue?vue&type=style&index=0&id=bd345980&prod&scoped=true&lang=css&"
import style1 from "./ResourceTypeSelector.vue?vue&type=style&index=1&id=bd345980&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd345980",
  null
  
)

export default component.exports