<template>
  <v-slide-group
    v-bind="$attrs"
    class="options"
    active-class="option--active"
    :value="value"
    center-active
    @change="change"
  >
    <template #prev>
      <v-btn class="arrow arrow--prev" icon><v-icon>$prev</v-icon></v-btn>
    </template>
    <v-slide-item
      v-for="lt in items"
      :key="lt.id"
      v-slot="{ active, toggle }"
      :value="lt.id"
      class="mx-1"
    >
      <vs-button
        type="secondary"
        small
        shrink
        :class="['option', active ? 'option--active' : '']"
        :label="lt.name"
        @click="toggle"
        >{{ lt.name }}</vs-button
      >
    </v-slide-item>
    <template #next>
      <v-btn class="arrow arrow--next" icon><v-icon>$next</v-icon></v-btn>
    </template>
  </v-slide-group>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { orderBy } from 'lodash'

export default {
  name: 'LocationTypeFilter',

  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapState('locationTypes', {
      ready: ({ loading, all }) =>
        loading !== true && Object.keys(all).length > 0,
      items({ all }) {
        return [
          {
            id: null,
            name: 'All',
          },
          ...orderBy(
            Object.entries(this.options)
              .map(([id, count]) => [all[id], count])
              .filter(([item, count]) => item && count > 0)
              .map(([item, count]) => ({
                id: item.id,
                name: item.name,
                count,
              })),
            'count',
            'desc'
          ),
        ]
      },
    }),
  },

  created() {
    if (!this.ready) {
      this.fetchLocationTypes()
    }
  },

  methods: {
    ...mapActions('locationTypes', ['fetchLocationTypes']),

    change(id) {
      this.$emit('input', id)
    },
  },
}
</script>

<style scoped>
.vs-button.option {
  height: 2rem;
  font-weight: 500;
  font-family: var(--font-regular);
  position: relative;
  color: var(--color-black);
  border: thin solid var(--color-grey--lighter);
  background-color: transparent;
}

.arrow--prev {
  margin-left: 6px;
}
.arrow--next {
  margin-right: 6px;
}

.vs-button.option--active {
  color: var(--color-blue);
}

.option.option--active:before {
  opacity: 0.08;
}

.option:before {
  content: '';
  background-color: currentColor;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: inherit;
}

:deep(.v-slide-group__prev),
:deep(.v-slide-group__next) {
  min-width: initial;
}

:deep(.v-slide-group__prev) {
  margin-left: -6px;
}

:deep(.v-slide-group__next) {
  margin-right: -6px;
}
</style>
