import { getUnitNumbers, updateUnitStatus } from '@/api/unit_numbers'
import { ACTIVE, OFFLINE, STANDBY, UnitNumber } from '@/models/unit_number'
import { orderBy } from 'lodash'
import moment from 'moment'
import { errorMutationHandler } from '../utils'
import ResourceType from '@/models/resource_type'
import Company from '@/models/company'
import { Division } from '@/models/business-unit'

export const UPDATED_UNIT_OPERATOR = 'UPDATED_UNIT_OPERATOR'
export const UPDATING_UNIT_STATUS = 'UPDATING_UNIT_STATUS'
export const UPDATED_UNIT_STATUS = 'UPDATED_UNIT_STATUS'
export const UPDATED_FULL_UNIT_STATUS = 'UPDATED_FULL_UNIT_STATUS'
export const LOADING_UNIT_NUMBERS = 'LOADING_UNIT_NUMBERS'
export const RECEIVE_UNIT_NUMBERS = 'RECEIVE_UNIT_NUMBERS'
export const ERROR_UNIT_NUMBERS = 'ERROR_UNIT_NUMBERS'
export const ERROR_UPDATE_UNIT_STATUS = 'ERROR_UPDATE_UNIT_STATUS'

interface UnitNumbersState {
  all: Record<string, UnitNumber>
  loading: boolean
  saving: boolean
  error: boolean | null
}

export interface UnitListItem extends UnitNumber {
  company: Company
  division: Division
  resourceType: ResourceType
  name: string
}

const state = () => {
  return {
    all: {},
    loading: false,
    saving: false,
    error: null,
  }
}

const getters = {
  getAllUnitNumbers: (state: UnitNumbersState) => state.all,
  getVisibleUnits: (state: UnitNumbersState) => {
    const visibleUnits = {}
    for (const id in state.all) {
      if (state.all[id] && state.all[id].isVisible) {
        visibleUnits[id] = state.all[id]
      }
    }

    return visibleUnits
  },
  getById: (state: UnitNumbersState) => (id) => state.all[id] || null,
  getAssignableUnitsForBusinessUnit:
    (_state: UnitNumbersState, getters, _rootState, rootGetters) =>
    (businessUnitId) => {
      const context = rootGetters['permissions/getContext']
      const scopes = context.roleAssignments.map((ra) => ra.businessUnitId)

      // Short circuit if the users scopes are not loaded yet
      if (scopes.length === 0) return []

      let units: UnitNumber[]

      // If I belong to the business unit, I can assign all my units at the business unit
      // as well as any partner units at the business unit
      if (scopes.includes(businessUnitId)) {
        const partnerBusinessUnitIds = context.lineage
          .filter((bu) => bu.id === businessUnitId)
          .map((bu) => bu.partnerIds)
          .flat()

        units = [
          ...getters.getUnitsForBusinessUnits(partnerBusinessUnitIds),
          ...getters.getUnitsForBusinessUnit(businessUnitId),
        ]

        // If I am partnered to the business unit, I can only assign units partnered to
        // said business unit INSIDE of my direct lineage
      } else {
        const partneredBusinessUnitIds = context.lineage
          .filter((bu) => bu.hostIds.includes(businessUnitId))
          .map((bu) => bu.id)

        units = getters.getUnitsForBusinessUnits(partneredBusinessUnitIds)
      }

      return orderBy(units, [(unit: UnitNumber) => unit.name?.toLowerCase()])
    },
  getUnitsForBusinessUnit:
    (_state: UnitNumbersState, getters) => (businessUnitId) => {
      return Object.values<UnitNumber>(getters.getVisibleUnits).filter(
        (unit: UnitNumber) => unit.ownerId === businessUnitId
      )
    },
  getUnitsForBusinessUnits:
    (_state: UnitNumbersState, getters) => (businessUnitIds) => {
      return Object.values<UnitNumber>(getters.getVisibleUnits).filter(
        (unit: UnitNumber) => businessUnitIds.some((id) => unit.ownerId === id)
      )
    },
  getAssignableUnitsForBusinessUnitAndResourceType:
    (_state: UnitNumbersState, getters) => (businessUnitId, resourceTypeId) => {
      const units = Object.values<UnitNumber>(
        getters.getAssignableUnitsForBusinessUnit(businessUnitId)
      ).filter((unit) => unit.resourceTypeId === resourceTypeId)
      return orderBy(units, [(unit) => unit.name?.toLowerCase()])
    },

  getLoading: (state: UnitNumbersState) => state.loading,
}

const actions = {
  fetchUnitNumbers({ commit }) {
    commit(LOADING_UNIT_NUMBERS)

    return getUnitNumbers()
      .then((res) => commit(RECEIVE_UNIT_NUMBERS, res))
      .catch((error) => commit(ERROR_UNIT_NUMBERS, { error }))
  },
  async setUnitStatus({ commit }, { id, status }) {
    commit(UPDATING_UNIT_STATUS)

    try {
      await updateUnitStatus(id, status)
      commit(UPDATED_UNIT_STATUS, { id, status })
    } catch (error) {
      commit(ERROR_UPDATE_UNIT_STATUS, { error })
    }
  },
  applyOnDutyEvent({ commit }, { unitId, operatorSid, occurredAt, status }) {
    commit(UPDATED_FULL_UNIT_STATUS, { id: unitId, status })

    const operator =
      operatorSid != null && occurredAt != null
        ? {
            userSid: operatorSid,
            occurredAt: moment.utc(occurredAt),
            onDuty: true,
          }
        : null

    commit(UPDATED_UNIT_OPERATOR, { id: unitId, operator })
  },
  applyOffDutyEvent({ commit }, { unitId, operatorSid, occurredAt, status }) {
    commit(UPDATED_FULL_UNIT_STATUS, { id: unitId, status })

    const operator =
      operatorSid != null && occurredAt != null
        ? {
            userSid: operatorSid,
            occurredAt: moment.utc(occurredAt),
            onDuty: false,
          }
        : null

    commit(UPDATED_UNIT_OPERATOR, { id: unitId, operator })
  },
  applyStatusUpdateEvent({ commit }, { unitId, status }) {
    commit(UPDATED_FULL_UNIT_STATUS, { id: unitId, status })
  },
}

const mutations = {
  [LOADING_UNIT_NUMBERS](state) {
    state.loading = true
  },
  [RECEIVE_UNIT_NUMBERS](state, { data }) {
    const receivedUnitNumbers = {}

    data.forEach((json) => {
      receivedUnitNumbers[json.id] = new UnitNumber(json)
    })

    state.all = Object.freeze(receivedUnitNumbers)
    state.loading = false
    state.error = null
  },
  [UPDATING_UNIT_STATUS](state) {
    state.saving = true
  },
  [UPDATED_UNIT_STATUS](state, { id, status }) {
    state.saving = false
    const unit = state.all[id]
    unit.status =
      status === OFFLINE ? OFFLINE : unit.isActive ? ACTIVE : STANDBY
    unit.isOffline = status === OFFLINE

    state.all = Object.freeze({ ...state.all, [id]: unit })

    state.error = null
  },
  [ERROR_UNIT_NUMBERS](state, { error }) {
    state.loading = false
    errorMutationHandler(state, error)
  },
  [ERROR_UPDATE_UNIT_STATUS](state, { error }) {
    state.saving = false
    errorMutationHandler(state, error)
  },
  [UPDATED_UNIT_OPERATOR](state, { id, operator }) {
    const unit = state.all[id]
    unit.operator = operator

    state.all = Object.freeze({ ...state.all, [id]: unit })
  },
  [UPDATED_FULL_UNIT_STATUS](state, { id, status }) {
    const unit = state.all[id]
    unit.isActive = status === ACTIVE
    unit.isOffline = status === OFFLINE
    unit.status = status

    state.all = Object.freeze({ ...state.all, [id]: unit })
  },
}

const pushHandlers = {
  GoOnDuty: 'applyOnDutyEvent',
  GoOffDuty: 'applyOffDutyEvent',
  UnitStatusUpdated: 'applyStatusUpdateEvent',
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  pushHandlers,
}
