<template>
  <div class="wrapper">
    <div class="body">
      <vs-heading>Form Builder</vs-heading>
      <vs-button label="Open Form" @click="openForm"></vs-button>
      <textarea ref="textArea" readonly class="input" />
    </div>
    <RightDrawer is-visible>
      <SchemaBasedForm :key="formKey" form-id="preview_form"></SchemaBasedForm>
    </RightDrawer>
  </div>
</template>

<script>
import SchemaBasedForm from '@/components/forms/SchemaBasedForm'
import VsHeading from '@/components/vision/VsHeading'
import { mapActions } from 'vuex'
import RightDrawer from '@/components/common/RightDrawer'
import VsButton from '@/components/vision/VsButton'

export default {
  name: 'FormPreview',
  components: {
    VsButton,
    VsHeading,
    SchemaBasedForm,
    RightDrawer,
  },
  data: () => {
    return {
      formKey: 0,
    }
  },
  beforeMount() {
    this.createPreviewForm()
  },
  methods: {
    ...mapActions({
      createPreviewForm: 'forms/createPreviewForm',
      updatePreviewFormStructure: 'forms/updatePreviewFormStructure',
    }),
    async openForm() {
      try {
        const [fileHandle] = await window.showOpenFilePicker()

        let lastModified = null

        setInterval(async () => {
          const fileData = await fileHandle.getFile()

          console.log(fileData)

          if (lastModified !== fileData.lastModified) {
            lastModified = fileData.lastModified
            this.$refs.textArea.value = await fileData.text()

            this.updatePreviewFormStructure(
              JSON.parse(this.$refs.textArea.value)
            )
            this.formKey += this.formKey + 1
          }
        }, 500)
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style scoped>
.body {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 80px;
  padding: 20px;
  right: 500px;
  display: flex;
  flex-direction: column;
}

.input {
  flex-grow: 5;
  border: #3c3d3f solid;
}
</style>
