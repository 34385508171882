import { render, staticRenderFns } from "./NewFormSelection.vue?vue&type=template&id=7b51f271&scoped=true&"
import script from "./NewFormSelection.vue?vue&type=script&setup=true&lang=ts&"
export * from "./NewFormSelection.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./NewFormSelection.vue?vue&type=style&index=0&id=7b51f271&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b51f271",
  null
  
)

export default component.exports