"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FlowsModel = void 0;
const flow_model_1 = require("./flow_model");
const schema_object_1 = require("./../schema_object");
class FlowsModel extends schema_object_1.SchemaObject {
    addFlowIfNotExists(flow, flowKey) {
        if (!this[flowKey]) {
            this[flowKey] = flow;
        }
    }
    getFlow(flowKey) {
        const flow = this[flowKey];
        if (flow instanceof flow_model_1.FlowModel) {
            return flow;
        }
        throw new Error(`Flow ${flowKey} does not exist`);
    }
}
exports.FlowsModel = FlowsModel;
