<template>
  <v-alert
    :value="true"
    :color="$options.barColor(type)"
    :class="['vs-alert', `vs-${type}`]"
  >
    <slot />
  </v-alert>
</template>

<script>
const bannerType = { ERROR: 'error', INFO: 'info', WARN: 'warn' }

export default {
  props: {
    type: {
      type: String,
      default: bannerType.INFO,
      validator: (value) => Object.values(bannerType).includes(value),
    },
  },
  barColor(type) {
    switch (type) {
      case bannerType.ERROR:
        return 'color-red--lighter'
      case bannerType.WARN:
        return 'color-yellow--lighter'
      default:
        return 'color-blue--lighter'
    }
  },
}
</script>

<style scoped>
.vs-alert {
  --textColor: var(--color-white);
  --border-color: var(--color-blue--light);

  border: 1px solid var(--border-color);
  border-radius: var(--space-base);
  padding: var(--space-base);
  font-family: var(--font-regular);
  font-size: 14px;
  line-height: 16px;
  word-wrap: break-word;
  word-break: break-word;
}

.vs-alert,
.vs-alert .vs-snack-close {
  color: var(--textColor);
}

.vs-alert.vs-warn {
  --textColor: var(--color-grey--dark);
  --border-color: var(--color-yellow--dark);
}

.vs-alert.vs-info {
  --textColor: var(--color-blue--dark);
}

.vs-alert.vs-error {
  --textColor: var(--color-red--dark);
  --border-color: var(--color-red--light);
}
</style>
