<template>
  <div>
    <dt v-if="activity">{{ activity.taskName }}</dt>
    <dd v-if="resourceType">{{ resourceType.name }}</dd>
    <dd v-if="unit">{{ unit.name }}</dd>
  </div>
</template>

<script>
export default {
  name: 'Assignment',
  props: {
    activity: {
      type: Object,
      default: () => {},
    },
    resourceType: {
      type: Object,
      default: () => {},
    },
    unit: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
