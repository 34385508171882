<template>
  <v-dialog :value="true" width="480" persistent scrollable>
    <v-card>
      <v-app-bar dense color="primary" dark clipped-right>
        <v-toolbar-title>New chat group</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click.stop="handleClose">
          <v-icon>close</v-icon>
        </v-btn>
      </v-app-bar>

      <div class="content-container">
        <div class="search-bar">
          <v-text-field
            v-model="search"
            class="px-4 pb-4"
            prepend-icon="search"
            single-line
            hide-details
            label="Search"
          />
          <v-divider />
        </div>

        <p
          v-if="!searchItems || searchItems.length === 0"
          class="text-body-1 px-4 py-2"
        >
          No results for <span class="font-italic">{{ search }}</span>
        </p>

        <v-list v-else>
          <v-list-item
            v-for="item in searchItems"
            :key="item.id"
            @click="item.selected = !item.selected"
          >
            <v-list-item-action>
              <v-checkbox :value="item.selected"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <span>{{ item.name }}</span>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
      <v-divider />
      <v-card-actions>
        <v-btn
          block
          color="primary"
          :disabled="!isAnySelected"
          @click.stop="handleCreate"
        >
          Create Chat Group
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { checkSearchResult } from '../../utils/search'

export default {
  name: 'NewChatDialog',
  props: {
    selectionArray: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      items: this.selectionArray.map((s) =>
        Object.assign({}, s, { selected: false })
      ),
      search: '',
    }
  },
  computed: {
    searchItems() {
      return this.items.filter((i) => checkSearchResult(this.search, i.name))
    },
    isAnySelected() {
      return this.items.some((i) => i.selected)
    },
  },
  methods: {
    handleCreate() {
      const selected = this.items.filter((i) => i.selected)
      this.$emit('create-group', { selected })
      this.handleClose()
    },
    handleClose() {
      this.$emit('close-dialog')
    },
  },
}
</script>

<style scoped>
.search-bar {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.content-container {
  height: 480px;
  overflow: auto;
}
</style>
