import { ORGANIZATION } from '@/models/business-unit'
import { flattenDeep, groupBy, sortBy } from 'lodash'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      getAllOrganizations: 'businessUnits/getAllOrganizations',
      getAllDivisions: 'businessUnits/getAllDivisions',
      getAllTeams: 'businessUnits/getAllTeams',
      getUsers: 'users/getNonDisabledUsers',
      getAllHubs: 'businessUnits/getAllHubs',
      getBusinessUnitById: 'businessUnits/getBusinessUnitById',
      getHierarchyLevel: 'businessUnits/getHierarchyLevel',
      isBusinessUnitsLoading: 'businessUnits/getLoading',
      isUsersLoading: 'users/getLoading',
      getCompanyByOrganizationId: 'companies/getByOrganizationId',
      getCompaniesForBusinessUnit: 'companies/getCompaniesForBusinessUnit',
      currentOrganizationId: 'permissions/getOrganizationId',
    }),
    getSubjects() {
      if (this.isBusinessUnitMenuLoading) return []
      return this.subjects(true, true)
    },
    getBusinessUnits() {
      if (this.isBusinessUnitMenuLoading) return []
      return this.subjects(false, true)
    },
    getSubjectsWithoutDivisions() {
      if (this.isBusinessUnitMenuLoading) return []
      return this.subjects(true, false)
    },
    isBusinessUnitMenuLoading() {
      return this.isBusinessUnitsLoading || this.isUsersLoading
    },
  },
  methods: {
    buildChildSubjects(ownerId, parent, showTeamsAndUsers, showDivisions) {
      const divisions = this.buildDivisionSubjects(ownerId, parent)
      const subjects = []

      if (showDivisions) {
        subjects.push(...divisions)
      }

      if (showTeamsAndUsers) {
        let teams = []

        for (const division of divisions) {
          teams.push(...this.buildTeamSubjects(division.id, parent))
        }
        teams.push(...this.buildTeamSubjects(ownerId, parent))
        teams = sortBy(teams, (d) => d.text.toLowerCase())
        subjects.push(...teams)

        let users = this.buildUserSubjects(ownerId, parent)
        users = sortBy(users, (d) => d.text.toLowerCase())
        subjects.push(...users)
      }

      return subjects
    },
    buildDivisionSubjects(ownerId, parent) {
      const divisionsByOwnerId = groupBy(
        Object.values(this.getAllDivisions),
        (d) => d.ownerId
      )

      return sortBy(
        divisionsByOwnerId[ownerId]?.map((division) => {
          return {
            id: division.id,
            parent,
            value: { userId: null, businessUnitId: division.id },
            text: division.name,
            description: this.getBusinessUnitById(division.ownerId).name,
            additionalKeywords: this.getFullCompanyName(division.ownerId),
            icon: 'hive',
            indent: true,
          }
        }),
        (d) => d.text.toLowerCase()
      )
    },
    buildOrganizationSubjects(
      organization,
      hubsByOwnerId,
      showTeamsAndUsers,
      showDivisions,
      renderedTitles
    ) {
      const subjects = []

      if (
        organization.id === this.currentOrganizationId ||
        organization.id in hubsByOwnerId
      ) {
        if (!renderedTitles.includes('suggested')) {
          subjects.push({
            text: 'SUGGESTED',
            header: true,
          })
          renderedTitles.push('suggested')
        }
      } else {
        if (!renderedTitles.includes('other')) {
          subjects.push({
            text: 'OTHER COMPANIES',
            header: true,
          })
          renderedTitles.push('other')
        }
      }

      if (organization.id in hubsByOwnerId) {
        const multiHub = hubsByOwnerId[organization.id].length > 1

        hubsByOwnerId[organization.id].forEach((hub) => {
          subjects.push({
            id: multiHub ? hub.id : organization.id,
            text: hub.name,
            value: { userId: null, businessUnitId: hub.id },
            additionalKeywords: this.formatDescription(organization.id),
            icon: 'villa',
          })

          subjects.push(
            ...this.buildChildSubjects(
              hub.id,
              organization.name,
              showTeamsAndUsers,
              showDivisions
            )
          )
        })
      } else {
        subjects.push({
          id: organization.id,
          text: organization.name,
          value: { userId: null, businessUnitId: organization.id },
          additionalKeywords: this.formatDescription(organization.id),
          icon: 'villa',
        })

        subjects.push(
          ...this.buildChildSubjects(
            organization.id,
            organization.name,
            showTeamsAndUsers,
            showDivisions
          )
        )
      }

      return subjects
    },
    buildTeamSubjects(ownerId, parent) {
      const teamsByOwnerId = groupBy(
        Object.values(this.getAllTeams),
        (t) => t.ownerId
      )

      return sortBy(
        teamsByOwnerId[ownerId]?.map((team) => {
          return {
            id: team.id,
            parent,
            value: { userId: null, businessUnitId: team.id },
            text: team.name,
            description: this.formatDescription(team.ownerId),
            additionalKeywords: this.getFullCompanyName(team.ownerId),
            icon: 'group',
            indent: true,
          }
        }),
        (d) => d.text.toLowerCase()
      )
    },
    buildUserSubjects(scopeId, parent) {
      return sortBy(
        this.getUsers
          .filter((u) => u.scopes.includes(scopeId))
          .map((user) => {
            return {
              id: user.id,
              parent,
              value: { userId: user.id, businessUnitId: null },
              text: user.name,
              description: this.formatDescription(
                user.defaultBusinessUnitId ?? user.organizationId
              ),
              additionalKeywords: this.getFullCompanyName(
                user.defaultBusinessUnitId ?? user.organizationId
              ),
              icon: 'account_circle',
              indent: true,
            }
          }),
        (user) => user.text.toLowerCase()
      )
    },
    subjects(showTeamsAndUsers, showDivisions) {
      const renderedTitles = []

      let organizations = this.getAllOrganizations
      let hubs = this.getAllHubs

      if (this.businessUnitId) {
        const relatedOrganizationIds = this.getCompaniesForBusinessUnit(
          this.businessUnitId
        ).map((company) => company.organizationId)

        organizations = organizations.filter((org) =>
          relatedOrganizationIds.includes(org.id)
        )
        hubs = hubs.filter((hub) => hub.id === this.businessUnitId)
      }

      const hubsByOwnerId = groupBy(hubs, (h) => h.ownerId)

      organizations = sortBy(
        organizations,
        // Order:
        // 1. The current user's organization.
        // 2. Organizations that have hubs.
        // 3. All other organizations.
        [
          (org) => (org.id === this.currentOrganizationId ? 0 : 1),
          (org) => (org.id in hubsByOwnerId ? 0 : 1),
          (org) => org.name.toLowerCase(),
        ]
      )

      return flattenDeep(
        organizations.map((org) =>
          this.buildOrganizationSubjects(
            org,
            hubsByOwnerId,
            showTeamsAndUsers,
            showDivisions,
            renderedTitles
          )
        )
      )
    },
    formatDescription(businessUnitId) {
      const businessUnit = this.getBusinessUnitById(businessUnitId)
      if (businessUnit) {
        const ownerBu = this.getHierarchyLevel(businessUnit.id, ORGANIZATION)
        if (ownerBu && ownerBu.id !== businessUnit.id) {
          const company = this.getCompanyByOrganizationId(ownerBu.id)
          return `${company?.shortCode} > ${businessUnit.name}`
        }
        if (businessUnit.type === ORGANIZATION) {
          const company = this.getCompanyByOrganizationId(businessUnit.id)
          return company?.shortCode
        }
        return businessUnit.name
      }
      return ''
    },
    getFullCompanyName(businessUnitId) {
      const businessUnit = this.getBusinessUnitById(businessUnitId)
      if (businessUnit) {
        const ownerBu = this.getHierarchyLevel(businessUnit.id, ORGANIZATION)
        if (ownerBu && ownerBu.id !== businessUnit.id) {
          return ownerBu.name
        }
        return businessUnit.name
      }
      return ''
    },
  },
}
