import ArrayField from './ArrayField'
import BooleanField from './BooleanField'
import CompanySelectorField from './CompanySelectorField'
import CreatableSelectorField from './CreatableSelectorField'
import FormButtonsField from './FormButtonsField'
import GenericSelectorField from './GenericSelectorField'
import GroupRadioSelectorField from './GroupRadioSelectorField'
import GroupSelectorField from './GroupSelectorField'
import HeadingField from './HeadingField'
import ImageField from './ImageField'
import IntegerField from './IntegerField'
import LocationSelectorField from './LocationSelectorField'
import MarkdownField from './MarkdownField'
import NullField from './NullField'
import NumberField from './NumberField'
import ObjectField from './ObjectField'
import RadioField from './RadioField'
import SchemaField from './SchemaField'
import StringField from './StringField'
import TextAreaField from './TextAreaField'
import UnsupportedField from './UnsupportedField'
import UserSelectorField from './UserSelectorField'
import LatLngField from './LatLngField'
import BusinessUnitSelectorField from '@/components/forms/fields/BusinessUnitSelectorField'
import FileAttachmentField from '@/components/forms/fields/FileAttachmentField'
import InventorySelectorField from '@/components/forms/fields/InventorySelectorField'

export default {
  ArrayField,
  BooleanField,
  CompanySelectorField,
  CreatableSelectorField,
  FormButtonsField,
  GenericSelectorField,
  GroupRadioSelectorField,
  GroupSelectorField,
  HeadingField,
  ImageField,
  IntegerField,
  LatLngField,
  LocationSelectorField,
  MarkdownField,
  NullField,
  NumberField,
  ObjectField,
  RadioField,
  SchemaField,
  StringField,
  BusinessUnitSelectorField,
  TextAreaField,
  UnsupportedField,
  UserSelectorField,
  FileAttachmentField,
  InventorySelectorField
}
