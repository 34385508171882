import { PriceBookItemQueryResponse } from '@/api/types/priceBookTypes'

export enum PriceBookItemType {
  Product,
  Service,
}

export default class PriceBookItem {
  id: string
  name: string
  type: PriceBookItemType
  defaultRate: number | null
  description: string | null

  constructor(json: PriceBookItemQueryResponse) {
    this.id = json.id
    this.name = json.name
    this.type = PriceBookItemType[json.type as keyof typeof PriceBookItemType]
    this.defaultRate = json.default_rate
    this.description = json.description
  }
}
