export enum ConnectionStatus {
  Pending,
  Accepted,
  Rejected,
}

export class Client {
  id: string
  name: string
  ownerId: string
  ownerName: string
  connectionStatus: ConnectionStatus | null
  canManageConnections: boolean

  constructor(json) {
    this.id = json.id
    this.name = json.name
    this.ownerId = json.owner_id
    this.ownerName = json.owner_name
    this.connectionStatus = json.connection_status
    this.canManageConnections = json.can_manage_connections
  }
}
