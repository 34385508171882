import { ref } from 'vue'

export function usePagination() {
  const currentPage = ref(1)

  function updatePage(page) {
    currentPage.value = page
  }

  return {
    currentPage,
    updatePage,
  }
}
