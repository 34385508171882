export const actionTypes = {
  SEND_NOTIFICATION: 'SEND_NOTIFICATION',
  CLONE: 'CLONE',
  STATUS_CHANGE: 'STATUS_CHANGE',
  ADD_TO_PROJECT: 'ADD_TO_PROJECT',
  REMOVE_FROM_PROJECT: 'REMOVE_FROM_PROJECT',
  VIEW_PROJECT: 'VIEW_PROJECT',
  DOWNLOAD_PDF: 'DOWNLOAD_PDF',
  FOCUS_RESOURCE: 'FOCUS_RESOURCE',
  ARCHIVE: 'ARCHIVE',
  UNARCHIVE: 'UNARCHIVE',
  ADD_TO_BATCH: 'ADD_TO_BATCH',
  VIEW_BATCH: 'VIEW_BATCH',
  REMOVE_FROM_BATCH: 'REMOVE_FROM_BATCH',
}
