import { toPath } from 'lodash'
import Ajv from 'ajv'

const ajv = createAjvInstance()

export function validateFormData(schema, formData) {
  ajv.validate(schema, formData)
  const errors = ajv.errors
  return toErrorSchema(errors)
}

export function isValid(schema, formData) {
  ajv.validate(schema, formData)
  return ajv.errors === null
}

function createAjvInstance() {
  // From https://github.com/rjsf-team/react-jsonschema-form/blob/master/packages/core/src/validate.js
  // The ajv instance options used in the react form package
  return new Ajv({
    errorDataPath: 'property',
    allErrors: true,
    multipleOfPrecision: 8,
    schemaId: 'auto',
    unknownFormats: 'ignore',
  })
}

/**
 * Transform the error output from AJV from a list to an Object that can be passed through with the schema
 */
function toErrorSchema(errors) {
  if (!Array.isArray(errors) || errors.length === 0) {
    return {}
  }

  return errors.reduce((errorSchema, error) => {
    const { dataPath, message } = error
    const path = toPath(dataPath)
    let parent = errorSchema

    if (path.length > 0 && path[0] === '') {
      path.splice(0, 1)
    }

    for (const route of path.slice(0)) {
      if (!(route in parent)) {
        parent[route] = {}
      }
      parent = parent[route]
    }

    /**
     * Return only the first error. This is done to prevent 'n' number of errors displaying when there is a validation
     * issue with object array dependencies.
     * Returned as an array of strings, as both the vs and vuetify components take an array of strings as the error-messages argument
     */
    if (!Array.isArray(parent.errors)) {
      parent.errors = [beautifyErrorMessage(message)]
    }

    return errorSchema
  }, {})
}

/**
 * Changes the text of some AJV produced error messages to be more suited for our use case
 * @param {string} message - The original AJV string message
 * @returns {string} - The formatted string message
 */
function beautifyErrorMessage(message) {
  switch (message) {
    case 'is a required property':
      return 'This field is required'
    default:
      return 'This ' + message
  }
}
