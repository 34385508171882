import axios from 'axios'
import { get } from './base'
import { convertToCamelCase } from '@/models/utils/casing'
import { useInfiniteQuery, useQuery } from '@tanstack/vue-query'
import {
  PaginatedRequest,
  SearchResultDTO,
} from '@/models/fv-search-result-dto'
import { watch, ref, Ref, unref, computed } from 'vue'
import { useDebounceFn } from '@vueuse/core'
import { FeatureCollection } from 'geojson'

export interface SearchOptions {
  category: 'locations' | 'resources' | 'jobs'
  searchTerm: string
}

interface PageParams {
  limit: number
  offset: number
}

export function search(
  options: SearchOptions,
  pageParams: PageParams,
  signal?: AbortSignal
) {
  const source = axios.CancelToken.source()

  const queryParams = new URLSearchParams()

  if (options.searchTerm) {
    queryParams.append('searchTerm', options.searchTerm)
  }

  if (options.category) {
    queryParams.append('category', options.category)
  }

  if (pageParams.limit) {
    queryParams.append('limit', pageParams.limit.toString())
  }

  if (pageParams.offset) {
    queryParams.append('offset', pageParams.offset.toString())
  }

  const results = get<PaginatedRequest<SearchResultDTO>>(
    `/api/v3/field-view/search?${queryParams.toString()}`,
    {
      cancelToken: source.token,
    }
  ).then((response) => convertToCamelCase(response.data))

  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled by TanStack Query')
  })

  return results
}

const initialPageParam = { limit: 50, offset: 0 }

export function useFVSearchQuery(options: Ref<SearchOptions>) {
  const { queryKey } = useDebouncedQueryKeyBuilder(options)
  const enabled = computed(() => !!options.value.category)

  const query = useInfiniteQuery({
    queryKey,
    queryFn: ({ signal, pageParam }) => {
      return search(unref(options), pageParam || initialPageParam, signal)
    },
    enabled,
    keepPreviousData: true,
    getNextPageParam: (previousPage) => {
      if (previousPage.meta.currentPage < previousPage.meta.totalPages) {
        return {
          limit: previousPage.meta.pageSize,
          offset: previousPage.meta.currentPage * previousPage.meta.pageSize,
        }
      }
    },
    // We want this query to never go stale, so it never re-fetches.
    // Otherwise we risk some UI jank if the query returns data from the cache,
    // but refetches in the background. If the locations on the server have
    // changed, location items may get bumped around in the list. The
    // user also may have paginated a hundred times, and we don't want to
    // re-execute all of those requests if we dont have to.
    //
    // The cache will be cleared by default 5 minutes after all components
    // using this query unmount.
    staleTime: Infinity,
    refetchOnMount: false,
  })

  return query
}

function useDebouncedQueryKeyBuilder(options: Ref<SearchOptions>) {
  const queryKey = ref<any[]>(['fv-search', unref(options)])

  const updateQueryKey = (newOptionsValue: SearchOptions) => {
    queryKey.value = ['fv-search', newOptionsValue]
  }

  const debouncedUpdate = useDebounceFn(updateQueryKey, 200)

  watch(options, (newOptions, oldOptions) => {
    // We only want to debounce when typing in the searchText, i.e., when the searchText is changed
    // However, there's an exception to this: If we click a location to navigate to its children's view,
    // the searchText is clear, but we don't want to debounce in this case
    if (
      newOptions.searchTerm !== oldOptions.searchTerm &&
      newOptions.category === oldOptions.category
    ) {
      debouncedUpdate(newOptions)
    } else {
      updateQueryKey(newOptions)
    }
  })

  return { queryKey }
}

type MaybeRef<T> = Ref<T> | T

export function useGetJobGeoJson(
  jobNumber: MaybeRef<string | number>,
  options?: { enabled: MaybeRef<boolean> }
) {
  const endpoint = computed(
    () => `/api/v3/field-view/geojson/jobs/${unref(jobNumber)}`
  )
  const queryKey = computed(() => ['geojson-jobs', unref(jobNumber)])

  const enabled = computed(() => {
    if (options && 'enabled' in options) {
      return unref(options.enabled)
    }
    return true
  })

  const { data } = useQuery({
    enabled,
    queryKey,
    queryFn: ({ signal }) => {
      const source = axios.CancelToken.source()
      const results = get<FeatureCollection>(endpoint.value, {
        cancelToken: source.token,
      }).then((response) => response.data)

      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by TanStack Query')
      })

      return results
    },
    cacheTime: 0,
    staleTime: 0,
  })

  return { data: data as Ref<FeatureCollection> }
}
