"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnumStringFieldDefinition = void 0;
const enum_property_description_1 = require("../models/field_property_descriptions/enum_property_description");
const field_ui_schema_1 = require("../models/field_ui_schema/field_ui_schema");
const radio_ui_schema_1 = require("../models/field_ui_schema/radio_ui_schema");
const text_entry_field_definition_1 = require("./text_entry_field_definition");
class EnumStringFieldDefinition extends text_entry_field_definition_1.TextEntryFieldDefinition {
    constructor() {
        super(...arguments);
        this.selectableOptions = [];
        this.isRadio = false;
    }
    propertyDescription() {
        return new enum_property_description_1.EnumPropertyDescription(this);
    }
    uiSchema() {
        if (this.isRadio) {
            return new radio_ui_schema_1.RadioUiSchema();
        }
        return new field_ui_schema_1.EmptyUiSchema();
    }
}
exports.EnumStringFieldDefinition = EnumStringFieldDefinition;
