<template>
  <div
    role="progressbar"
    aria-valuemin="0"
    aria-valuemax="100"
    :aria-valuenow="currentProgress"
    class="progress-bar-container"
  >
    <div
      v-for="(segment, index) in progressSegments"
      :key="index"
      :class="['progress-bar', segment.status]"
      :style="{
        '--progress': `${segment.percent}%`,
      }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    segments: {
      type: Array,
      required: true,
    },
    totalDuration: {
      type: Object,
      required: true,
    },
  },

  computed: {
    progressSegments() {
      return this.segments.map((segment) => {
        const percent =
          Math.min(
            segment.duration.as('milliseconds') /
              this.totalDuration.as('milliseconds'),
            100
          ) * 100

        return {
          percent,
          status: segment.start.status,
        }
      })
    },
    currentProgress() {
      return Math.min(
        this.progressSegments.reduce((total, p) => total + p.percent, 0),
        100
      )
    },
  },
}
</script>

<style>
.progress-bar-container {
  display: flex;
  width: 100%;
  bottom: 0;
  left: 0;
  position: absolute;
  height: var(--space-smaller);
  background-color: var(--color-silver);
}

.active {
  --bar-color: var(--color-green);
}

.paused {
  --bar-color: var(--color-yellow);
}

.progress-bar {
  flex-basis: var(--progress);
  height: 100%;
  background-color: var(--bar-color);
  max-width: 100%;
}
</style>
