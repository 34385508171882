<template>
  <vs-wrapper>
    <vs-text>Change the start and end to...</vs-text>
    <v-row no-gutters>
      <v-col>
        <vs-date-picker
          data-test="overwrite-start-time-picker"
          :value="startTime"
          label="Start"
          can-select-past
          required
          :rules="[() => isEndTimeBeforeStart && 'start cannot be after end']"
          @input="setStartTime"
        />
      </v-col>
      <v-col class="pl-2">
        <vs-date-picker
          data-test="overwrite-end-time-picker"
          :value="endTime"
          label="End"
          can-select-past
          required
          @input="setEndTime"
        />
      </v-col>
    </v-row>
    <vs-text v-if="hasEmptyFields" type="subdued">
      Selected jobs have different values
    </vs-text>
  </vs-wrapper>
</template>

<script>
import moment from 'moment'
import VsWrapper from '@/components/vision/VsWrapper.vue'
import VsDatePicker from '@/components/vision/Inputs/VsDatePicker.vue'
import VsText from '@/components/vision/VsText.vue'

export default {
  name: 'OverwriteScheduleDialog',
  components: { VsText, VsDatePicker, VsWrapper },

  props: {
    saving: {
      type: Boolean,
      default: false,
    },
    startTime: {
      type: Object,
      default: null,
    },
    endTime: {
      type: Object,
      default: null,
    },
  },
  computed: {
    isEndTimeBeforeStart() {
      return moment(this.endTime).isBefore(moment(this.startTime))
    },
    hasEmptyFields() {
      return !this.startTime || !this.endTime
    },
    startEndDuration() {
      return moment.duration(this.endTime.diff(this.startTime)).asHours()
    },
  },

  methods: {
    setStartTime(value) {
      if (value.isValid()) {
        if (this.endTime && !this.isEndTimeBeforeStart) {
          this.setEndTime(moment(value).add(this.startEndDuration, 'h'))
        }
        this.$emit('update-start-time', value)
      }
    },
    setEndTime(value) {
      if (value.isValid()) {
        this.$emit('update-end-time', value)
      }
    },
  },
}
</script>
