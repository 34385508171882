
import { computed, defineComponent, watch } from 'vue'

import {
  useAcceptJobMutation,
  useDeclineJobMutation,
  useJobQuery,
} from '@/api/jobs'
import { useResourceTypeActivityQuery } from '@/api/resource_type_activities'
import { useDismissibleApiError } from '@/components/jobs/dismissible-api-error'

import JobAttachment from '@/models/job_attachment'
import { ASSIGNED_TO_POOL, JobStatus } from '@/models/job'
import { priorityMap } from '@/utils/constants'

import FormLayout from '@/components/common/FormLayout.vue'
import Attachments from '@/components/tickets/Attachments.vue'
import JobOwner from '@/components/jobs/fields/JobOwner.vue'
import JobStops from '@/components/jobs/fields/JobStops.vue'
import JobFollowers from '@/components/jobs/fields/JobFollowers.vue'
import PersonName from '@/components/jobs/fields/PersonName.vue'
import JobExtensionData from '@/components/jobs/fields/JobExtensionData.vue'
import JobTagsDisplay from '@/components/jobs/fields/JobTagsDisplay.vue'
import JobBreadcrumbs from '@/components/jobs/sections/JobBreadcrumbs.vue'
import JobHeaderLabel from '@/components/jobs/sections/JobHeaderLabel.vue'
import JobScheduleSection from '@/components/jobs/sections/JobScheduleSection.vue'
import JobRequirementSection from './sections/JobRequirementSection.vue'
import AvailabilityStatus from '@/components/jobs/sections/AvailabilityStatus.vue'
import ReadonlyField from '@/components/jobs/fields/ReadonlyField.vue'
import ActionBar from '@/components/jobs/sections/ActionBar.vue'
import ErrorBanner from '@/components/jobs/sections/ErrorBanner.vue'

export default defineComponent({
  name: 'ClaimJob',

  components: {
    ErrorBanner,
    ActionBar,
    ReadonlyField,
    JobRequirementSection,
    JobScheduleSection,
    JobHeaderLabel,
    JobBreadcrumbs,
    JobExtensionData,
    Attachments,
    FormLayout,
    JobOwner,
    JobStops,
    JobFollowers,
    JobTagsDisplay,
    PersonName,
    AvailabilityStatus,
  },

  props: {
    number: {
      type: Number,
      required: true,
    },
  },

  setup(props, { emit }) {
    const {
      data: jobData,
      isLoading,
      error: fetchError,
    } = useJobQuery(props.number)
    const {
      accept,
      isLoading: isAccepting,
      error: acceptMutationError,
    } = useAcceptJobMutation()
    const {
      decline,
      isLoading: isDeclining,
      error: declineMutationError,
    } = useDeclineJobMutation()

    const job = computed(() => {
      const data = jobData.value ?? {
        number: props.number,
        state: JobStatus.ASSIGNED_TO_POOL,
        priority: priorityMap.Normal.id,
        request_owner: {},
        request_details: {},
        resource_assignment: {},
        tags: [],
        followers: [],
        inventory_requirements: [],
        attachments: [],
      }
      return {
        uuid: data.uuid,
        assignmentGroupNumber: data.assignment_group_number,
        attachments: data.attachments.map((a) => new JobAttachment(a)),
        attachmentsStorageId: data.attachments_storage_id,
        costCenter: data.cost_center,
        customData: data.request_details.custom_data,
        extensionSchemaId: data.extension_schema_id,
        destinationLocationId: data.request_details.destination_location_id,
        destinationLocation: data.request_details.destination_location,
        details: data.request_details.details,
        divisionId: data.request_owner.owner_id,
        followers: data.followers,
        inventory: data.inventory_requirements,
        number: data.id,
        poolId: data.resource_assignment.pool_id,
        priority: data.priority,
        projectId: data.project_id,
        requestedEnd: data.request_details.requested_end,
        requestedStart: data.request_details.requested_start,
        requesterSid: data.request_owner.user_sid,
        rtaId: data.request_details.activity_id,
        status: data.state,
        tags: data.tags,
      }
    })

    const jobRtaId = computed(() => jobData.value?.request_details.activity_id)
    const { data: resourceTypeActivity } =
      useResourceTypeActivityQuery(jobRtaId)
    const activityId = computed(() => resourceTypeActivity.value?.activity_id)
    const resourceTypeName = computed(
      () => resourceTypeActivity.value?.resource_type_name
    )
    const title = computed(() => resourceTypeActivity.value?.activity_name)

    const errorMessage = computed(() => {
      if (!fetchError.value) {
        return null
      }
      return fetchError.value.response?.data?.detail
    })

    watch(job, (newValue) => {
      // 1. claim job
      // 2. re-fetch latest version of the job
      // 3. watch for status change (indicating the fetch completed)
      // 4. emit "claimed" event
      // 5. parent component Ticket.vue will update to show editable form
      //    instead of the claim ui
      if (newValue.status !== ASSIGNED_TO_POOL) {
        emit('claimed')
      }
    })

    const { showError: showClaimError, message: claimErrorMessage } =
      useDismissibleApiError(acceptMutationError || declineMutationError)

    const dismiss = () => {
      emit('dismiss')
    }

    const acceptJob = computed(() => {
      return {
        props: {
          label: 'Available',
          loading: isAccepting.value,
        },
        on: {
          click: async () => await accept(job.value.number),
        },
      }
    })

    const declineJob = computed(() => {
      return {
        props: {
          label: 'Decline',
          loading: isDeclining.value,
        },
        on: {
          click: async () => await decline(job.value.number),
        },
      }
    })

    return {
      showClaimError,
      claimErrorMessage,
      errorMessage,
      isLoading,
      title,
      job,
      activityId,
      dismiss,
      primaryAction: acceptJob,
      secondaryAction: declineJob,
      resourceTypeName,
    }
  },
})
