import { render, staticRenderFns } from "./ProjectBreadcrumbs.vue?vue&type=template&id=2dc2ef52&scoped=true&"
import script from "./ProjectBreadcrumbs.vue?vue&type=script&lang=js&"
export * from "./ProjectBreadcrumbs.vue?vue&type=script&lang=js&"
import style0 from "./ProjectBreadcrumbs.vue?vue&type=style&index=0&id=2dc2ef52&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dc2ef52",
  null
  
)

export default component.exports