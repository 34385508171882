"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createableSelectorMatcher = void 0;
const createable_selector_definition_1 = require("../../form_fields/createable_selector_definition");
const json_schema_keys_1 = require("../../json_schema_keys");
const createableSelectorMatcher = (uniqueKey, isRequired, properties, uiSchema, fieldDefinitions, next) => {
    const isDataTypeString = properties[json_schema_keys_1.JsonSchemaKeys.TYPE_KEY] === json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_STRING;
    const isCreateableWidget = uiSchema[json_schema_keys_1.JsonSchemaKeys.UI_WIDGET_KEY] === json_schema_keys_1.JsonSchemaKeys.WIDGET_TYPE_CREATABLE_SELECTOR;
    if (isDataTypeString && isCreateableWidget) {
        const field = new createable_selector_definition_1.CreateableSelectorDefinition();
        field.title = properties.title;
        field.description = properties.description;
        field.isRequired = isRequired;
        field.selectableOptions = uiSchema[json_schema_keys_1.JsonSchemaKeys.UI_OPTIONS_KEY][json_schema_keys_1.JsonSchemaKeys.CHOICES_KEY];
        field.defaultValue = properties.default;
        return field;
    }
    return next(uniqueKey, isRequired, properties, uiSchema, fieldDefinitions);
};
exports.createableSelectorMatcher = createableSelectorMatcher;
