<template>
  <icon-two-line-cell
    :primary-text="getFormName"
    :secondary-text="getFormCategoryName"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import IconTwoLineCell from '@/components/forms/cells/IconTwoLineCell'

export default {
  name: 'FormCell',
  components: { IconTwoLineCell },
  props: {
    formId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getFormById: 'forms/getById',
    }),
    form() {
      return this.getFormById(this.formId)
    },
    getFormName() {
      return this.form?.name ?? 'Unknown form'
    },
    getFormCategoryName() {
      return this.form?.category?.name ?? ''
    },
  },
}
</script>
