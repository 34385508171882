import { Banner } from '../../bannerStore'
import Component from './banner.vue'

const banner: Banner = {
  id: 'september_19_2024_sales_event_banner',
  created: new Date('09-05-2024'),
  expiry: new Date('09-19-2024'),
  target: ({ user }) => {
    // this banner should target users who are Admins or Coordinators
    // within service provider organizations.
    // we can determine this by finding if the user has a role assignment
    // to an org, rather than a hub.
    const organizations = user.lineage
      .filter((l) => l.type === 'Organization')
      .map((o) => o.id)
    const roleAssignments = user.roleAssignments.filter(
      (ra) => ra.role === 'Admin' || ra.role === 'Coordinator'
    )

    return roleAssignments.some((ra) =>
      organizations.includes(ra.businessUnitId)
    )
  },
  component: () => Component,
}

export default banner
