<template>
  <vs-button
    :label="getJobNumber"
    type="tertiary"
    @click.stop="pushSearchJobRoute"
  />
</template>

<script>
import IconTwoLineCell from '@/components/forms/cells/IconTwoLineCell'

export default {
  name: 'JobCell',
  components: { IconTwoLineCell },
  props: {
    jobNumber: {
      required: true,
    },
  },
  computed: {
    getJobNumber() {
      return this.jobNumber.toString()
    }
  },

  methods: {
    async pushSearchJobRoute() {
      await this.$router.push({
        name: 'search-ticket-info-panel',
        params: { 
          id: this.jobNumber,
        },
      })
    },
  },
}
</script>
