export const SET_SHOULD_NAVIGATE = 'SET_SHOULD_NAVIGATE'
export const CLEAR_TICKETS_ERROR = 'CLEAR_TICKETS_ERROR'
export const ERROR_TICKETS = 'ERROR_TICKETS'

const state = () => {
  return {
    error: null,
    shouldNavigate: true,
  }
}

const getters = {
  getError: (state) => state.error,
  getShouldNavigate: (state) => state.shouldNavigate,
}

const actions = {}

export const mutations = {
  [ERROR_TICKETS](state, error) {
    state.error = error
  },
  [CLEAR_TICKETS_ERROR](state) {
    state.error = null
  },
  [SET_SHOULD_NAVIGATE](state, { navigate }) {
    state.shouldNavigate = navigate
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
