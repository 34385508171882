import { render, staticRenderFns } from "./FollowUpAction.vue?vue&type=template&id=8fed57d2&scoped=true&"
import script from "./FollowUpAction.vue?vue&type=script&lang=js&"
export * from "./FollowUpAction.vue?vue&type=script&lang=js&"
import style0 from "./FollowUpAction.vue?vue&type=style&index=0&id=8fed57d2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8fed57d2",
  null
  
)

export default component.exports