"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchemaNode = void 0;
const node_1 = require("./node");
class SchemaNode extends node_1.NodeBase {
    constructor(fields = []) {
        super();
        this.fields = fields;
    }
    // Adds a field to the end of the field list.
    // Or, optionally pass an index to insert it at.
    addField(field, index) {
        if (index != null) {
            this.fields.splice(index, 0, field);
        }
        else {
            this.fields.push(field);
        }
    }
    addFields(fields) {
        this.fields.push(...fields);
    }
    // Removes a field and returns the index it used to be at
    // Returns -1 if the field is not present on this node
    removeField(field) {
        if (!this.fields.includes(field)) {
            return -1;
        }
        const index = this.fields.indexOf(field);
        this.fields.splice(this.fields.indexOf(field), 1);
        return index;
    }
    replaceField(original, replacement) {
        const index = this.removeField(original);
        this.addField(replacement, index);
    }
    requiredFields() {
        return this.fields.filter((f) => f.isRequired).map((f) => f.uniqueKey());
    }
}
exports.SchemaNode = SchemaNode;
