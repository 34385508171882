<template>
  <v-tooltip right>
    <template #activator="{ on }">
      <v-icon small :color="value.color" v-on="on">
        {{ value.icon }}
      </v-icon>
    </template>
    <span>{{ value.name }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'Priority',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
