<template>
  <vs-wrapper v-if="formSubmission" space="small">
    <v-row>
      <v-col>
        <vs-heading data-test="job-form-title" type="overline">{{
          formTitle
        }}</vs-heading>

        <vs-text data-test="job-form-creation-description" type="caption"
          >Submitted by {{ creatorName }} {{ createdAtFormatted }}</vs-text
        >
      </v-col>

      <v-col v-if="canEdit" class="shrink">
        <vs-button
          data-test="inline-view-job-form-button"
          label="Edit"
          type="secondary"
          small
          @click="editFormSubmission"
        />
      </v-col>
    </v-row>

    <submission-field
      v-if="submissionData"
      :flattened-fields="flattenedFormFields"
      :submission-data="submissionData"
    />
  </vs-wrapper>
</template>

<script>
import SubmissionField from '@/components/forms/submission-fields/SubmissionField'
import { getFormFields } from '@/utils/field-schema-helper'
import { mapActions, mapGetters } from 'vuex'

import moment from 'moment'

export default {
  name: 'JobInlineForm',
  components: {
    SubmissionField,
  },

  props: {
    formSubmissionId: {
      type: String,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapGetters({
      getFormSubmissionById: 'formSubmissions/getById',
      getFormDefinitionById: 'forms/getById',
      getUserBySid: 'users/getBySid',
    }),
    creatorName() {
      const creator = this.getUserBySid(this.formSubmission.createdBy)

      return creator ? creator.name : 'Unknown User'
    },
    createdAtFormatted() {
      if (!this.formSubmission.created) return ''

      return `on ${moment
        .utc(this.formSubmission.created)
        .local()
        .format('MMM DD, HH:mm')}`
    },
    formTitle() {
      return this.formDefinition?.name
    },
    formSubmission() {
      return this.getFormSubmissionById(this.formSubmissionId)
    },
    formDefinition() {
      if (this.formSubmission) {
        return this.getFormDefinitionById(this.formSubmission.formId)
      }
      return null
    },
    submissionData() {
      return this.formSubmission?.submission
    },
    flattenedFormFields() {
      if (!this.formSubmission || !this.formDefinition) {
        return {}
      }

      return getFormFields(this.formDefinition.structure)
    },
  },

  async mounted() {
    await this.fetchFormSubmission(this.formSubmissionId)
  },

  methods: {
    ...mapActions('formSubmissions', ['fetchFormSubmission']),
    editFormSubmission() {
      this.$emit('edit-form-submission')
    },
  },
}
</script>

<style></style>
