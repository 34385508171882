<template>
  <vs-wrapper>
    <vs-alert type="info">
      <v-icon color="blue lighten-1" class="icon-fix"
        >check_box_outlined</v-icon
      >
      <b>{{ actionsRequiredStatusLabel }}</b>
      {{ actionsRequiredAssignedLabel }}
    </vs-alert>
  </vs-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import FormSubmission from '@/models/form_submission'

export default {
  name: 'FormSubmissionActionsStatus',
  props: {
    uncompletedActionsCount: {
      type: Number,
      require: true,
    },
    type: {
      type: String,
      require: true,
    },
    id: {
      type: [Number, String],
      require: true,
    },
  },
  computed: {
    ...mapGetters({
      getUserBySid: 'users/getBySid',
      getBusinessUnitById: 'businessUnits/getBusinessUnitById',
    }),
    actionsRequiredStatusLabel() {
      if (this.uncompletedActionsCount === 0) {
        return 'Actioned by '
      }
      const actionsLabel =
        this.uncompletedActionsCount === 1 ? 'Action' : 'Actions'
      return `${this.uncompletedActionsCount} ${actionsLabel} required. `
    },
    actionsRequiredAssignedLabel() {
      if (!(this.id && this.type)) {
        return 'Not assigned yet'
      }

      let assignedTo

      if (this.type === FormSubmission.ASSIGNMENT_ENTITY_TYPE_BUSINESS_UNIT) {
        assignedTo =
          this.getBusinessUnitById(this.id)?.name ?? 'Unknown Assignee'
      }

      if (this.type === FormSubmission.ASSIGNMENT_ENTITY_TYPE_USER) {
        assignedTo = this.getUserBySid(this.id)?.name ?? 'Unknown User'
      }

      if (this.uncompletedActionsCount === 0) {
        return assignedTo
      } else {
        return `Assigned to ${assignedTo}`
      }
    },
  },
}
</script>
<style scoped>
.icon-fix {
  display: inline-block;
  width: 32px;
}
</style>
