import { createAuth0Client } from '@auth0/auth0-spa-js'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { throttle } from 'lodash'
import Vue from 'vue'
import Vuex from 'vuex'
import { getAuth0Config } from '../api/config'
import featureFlags from '../store/modules/feature-flags'

export const DISPATCHER_ROLE = 'Dispatcher'
export const SUPERADMIN_ROLE = 'Superadmin'
export const CUSTOMER_ROLE = 'Customer'
export const OPERATOR_ROLE = 'Operator'
export const MEMBER_ROLE = 'Member'
export const FORM_REVIEWER_ROLE = 'Form Reviewer'
export const DEVELOPER_ROLE = 'Developer'
export const ANALYTICS_VIEWER_ROLE = 'Analytics Viewer'
export const WORKFLOW_MANAGER_ROLE = 'Workflow Manager'

const AUTH_TIMEOUT = 60000

let instance

/** Returns the current instance of the SDK */
export const getInstance = () => instance

export const getInstanceAsync = () => {
  return new Promise((resolve, reject) => {
    if (instance && !instance.loading) {
      resolve(instance)
      return
    }

    const timeout = setTimeout(() => {
      if (unwatch) {
        unwatch()
      }
      reject(new Error('Timeout waiting for auth.'))
    }, AUTH_TIMEOUT)

    const unwatch = instance.$watch('loading', (loading) => {
      if (loading === false) {
        clearTimeout(timeout)
        resolve(instance)
      }
    })
  })
}

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  onRedirectCallback,
  redirectUri = window.location.origin,
}) => {
  if (instance) return instance

  Vue.use(Vuex)
  const store = new Vuex.Store({
    modules: { featureFlags },
  })

  // The 'instance' is simply a Vue object
  instance = new Vue({
    store,
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        roles: [],
        companyId: null,
        isGlobalAdmin: false,
        auth0Client: null,
        error: null,
      }
    },
    computed: {
      isDeveloper() {
        return this.roles.includes(DEVELOPER_ROLE)
      },
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      try {
        this.config = await getAuth0Config().then((result) => result.data)

        // Create a new instance of the SDK client using members of the given options object
        this.auth0Client = await createAuth0Client({
          domain: this.config.domain,
          clientId: this.config.client_id,
          cacheLocation: 'localstorage',
          useRefreshTokens: false,
          authorizationParams: {
            audience: this.config.audience,
            redirect_uri: redirectUri,
          },
        })
        // If the user is returning to the app after authentication..
        if (
          window.location.search.includes('error') ||
          (window.location.search.includes('code=') &&
            window.location.search.includes('state='))
        ) {
          // handle the redirect and retrieve tokens
          const { appState } = await this.auth0Client.handleRedirectCallback()

          this.error = null

          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)
          onRedirectCallback(appState)
        }
      } catch (e) {
        this.error = e
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        )
      } finally {
        await this.setUser()
        this.loading = false
      }
    },
    methods: {
      async setUser() {
        // Initialize our internal authentication state
        this.isAuthenticated = await this.auth0Client.isAuthenticated()
        this.user = await this.auth0Client.getUser()
        if (this.user) {
          const customClaims = Object.fromEntries(
            Object.entries(this.user)
              .filter(([k, _]) =>
                k.startsWith(this.config.custom_claims_namespace)
              )
              .map(([k, v]) => [
                k.replace(`${this.config.custom_claims_namespace}/`, ''),
                v,
              ])
          )
          this.user = { ...this.user, ...customClaims }
        }
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect(o) {
        return this.auth0Client.loginWithRedirect(o)
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o)
      },
      /**
       * Returns the access token. If the token is invalid or missing, a new one is retrieved.
       * If the user has been inactive or has not logged in for a certain time period, getTokenSilently
       * will fail, at which point, if we do not return any access token, the next round of polling
       * will be unauthorized which triggers the session expired message prompting a logout.
       */
      async getTokenSilently(o) {
        try {
          const accessToken = await this.auth0Client.getTokenSilently(o)
          this._storeAccessToken(accessToken)

          return accessToken
        } catch (e) {
          // interaction_required is thrown when a global admin needs to select a hub from the portal
          if (e.error === 'interaction_required') {
            return
          }

          // login_required is thrown once the "Require log in after" time has elapsed for the user
          if (e.error === 'login_required') {
            await this.loginWithRedirect({
              appState: { targetUrl: window.location.pathname },
              authorizationParams: { prompt: 'login' },
            })
          }
        }
      },
      /** Logs the user out and removes their session on the authorization server */
      logout(o) {
        localStorage.removeItem('previousRoute')
        return this.auth0Client.logout({
          logoutParams: {
            returnTo: window.location.origin,
            ...o,
          },
        })
      },
      requestPasswordReset() {
        // eslint-disable-next-line camelcase
        const { domain, client_id } = this.auth0Client.options

        return axios.post(`https://${domain}/dbconnections/change_password`, {
          // eslint-disable-next-line camelcase
          client_id,
          email: this.user.email,
          connection: 'migrate-mysql',
        })
      },
      hasRole(role) {
        return this.roles.includes(role)
      },
      _storeAccessToken: throttle(function (accessToken) {
        const decoded = jwtDecode(accessToken)
        this.roles = decoded[`${this.config.custom_claims_namespace}/roles`]
        this.companyId =
          decoded[`${this.config.custom_claims_namespace}/company`]
        this.tenant = decoded[`${this.config.custom_claims_namespace}/env`]
        this.organizationId =
          decoded[`${this.config.custom_claims_namespace}/org_id`]
        this.isGlobalAdmin =
          decoded[`${this.config.custom_claims_namespace}/global_admin`] !==
          undefined
      }, 5000),
    },
  })

  return instance
}

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options)
  },
}
