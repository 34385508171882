import axios from 'axios'
import Vue from 'vue'

export function errorMutationHandler(state, error) {
  Vue.set(state, 'loading', false)
  Vue.set(state, 'saving', false)

  if (axios.isCancel(error)) {
    console.debug('Request cancelled', error.message)
  } else {
    console.error(error.message)
    Vue.set(state, 'error', error)
  }
}
