<template>
  <vs-wrapper space="small">
    <v-checkbox
      mandatory
      :input-value="field.isArray"
      label="Field group list"
      @change="toggleIsArray"
    ></v-checkbox>

    <v-row v-if="field.isArray">
      <v-col>
        <vs-text-input
          v-model.lazy="field.minimumItems"
          label="Minimum items"
          type="number"
          :value="field.minimumItems"
          @input="updateMinimum"
        ></vs-text-input>
      </v-col>

      <v-col>
        <vs-text-input
          v-model.lazy="field.maximumItems"
          label="Maximum items"
          type="number"
          :value="field.maximumItems"
          @input="updateMaximum"
        ></vs-text-input>
      </v-col>
    </v-row>

    <vs-text type="overline" class="mt-2">Fields</vs-text>
    <v-expansion-panels focusable>
      <draggable
        :list="field.fields"
        class="flex-grow-1 flex-shrink-0"
        handle=".handle"
        @change="reorderField"
      >
        <v-expansion-panel
          v-for="nestedField in field.fields"
          :key="nestedField.guid.toString()"
        >
          <v-expansion-panel-header>
            <v-row align="center">
              <v-col cols="auto">
                <v-icon class="handle">mdi-drag-horizontal</v-icon>
                {{ getFieldLabel(nestedField) }}
              </v-col>
              <v-spacer></v-spacer>
              <v-col v-if="hasDependencies(nestedField)" cols="auto">
                <v-row align="center">
                  <v-icon>link</v-icon>
                  <vs-text class="pl-2 pr-4"
                    >{{ numberOfDependencies(nestedField) }}
                  </vs-text>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <vs-card accent="var(--color-blue--lighter)" class="mt-2">
              <relationships-editor
                :builder="field.builder"
                :field="nestedField"
                :show-flows="false"
              />

              <core-field-settings
                :field="nestedField"
                :builder="field.builder"
                @modify-field="modifyField"
                @remove-field="removeField"
              />

              <specific-field-settings
                :field="nestedField"
                @modify-field="modifyField"
                @remove-field="removeField"
              />
            </vs-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </draggable>
    </v-expansion-panels>

    <add-field-button
      class="mt-3"
      :subject-field-exists="true"
      :subject-location-field-exists="true"
      @field-clicked="addField"
      @field-clicked-name="getFieldType"
    ></add-field-button>
  </vs-wrapper>
</template>

<script>
import VsWrapper from '@/components/vision/VsWrapper'
import AddFieldButton from '../AddFieldButton.vue'
import CoreFieldSettings from '@/components/form-builder/field-settings/CoreFieldSettings'
import VsTextInput from '@/components/vision/Inputs/VsTextInput'
import VsCard from '@/components/vision/VsCard'
import VsText from '@/components/vision/VsText'
import formFieldTitle from '@/utils/forms/form-field-title'
import RelationshipsEditor from '@/components/form-builder/RelationshipsEditor.vue'
import draggable from 'vuedraggable'

export default {
  name: 'ObjectFieldSettings',
  components: {
    RelationshipsEditor,
    VsText,
    VsCard,
    VsTextInput,
    VsWrapper,
    AddFieldButton,
    CoreFieldSettings,
    draggable,
    SpecificFieldSettings: () =>
      import('@/components/form-builder/field-settings/SpecificFieldSettings'),
  },

  props: {
    field: {
      type: Object,
      default: () => {},
    },
  },
  data: () => {
    return {
      fieldTypeName: null,
    }
  },
  methods: {
    getFieldType(value) {
      this.fieldTypeName = value
    },
    getFieldLabel(field) {
      return formFieldTitle(field)
    },
    reorderField(event) {
      const field = event.moved.element
      const newIndex = event.moved.newIndex
      this.field.builder.reorderField(field, newIndex)
    },
    modifyField(data) {
      const nestedField = this.field.fields.find(
        (f) => f.uniqueKey() === data.key
      )
      nestedField[data.propertyKey] = data.fieldValue
    },
    removeField(nestedField) {
      this.field.removeField(nestedField)
    },
    addField(nestedField) {
      this.field.addField(nestedField)
    },
    hasDependencies(nestedField) {
      return this.field.builder
        ? this.field.builder.immediateConstDependenciesOfField(nestedField)
            .length > 0
        : false
    },
    numberOfDependencies(nestedField) {
      return this.field.builder.immediateConstDependenciesOfField(nestedField)
        .length
    },
    toggleIsArray() {
      this.emitChanges('isArray', !this.field.isArray)
    },
    updateMinimum(minimumItems) {
      this.emitChanges('minimumItems', parseInt(minimumItems))
    },
    updateMaximum(maximumItems) {
      this.emitChanges('maximumItems', parseInt(maximumItems))
    },
    emitChanges(propertyKey, value) {
      this.$emit('modify-field', {
        propertyKey,
        fieldValue: value,
        key: this.field.uniqueKey(),
      })
    },
  },
}
</script>
