var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_setup.isHubUser)?_c('div',[_c('v-radio-group',{attrs:{"mandatory":""},on:{"change":_setup.onOwnerRadioChanged},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c(_setup.VsHeading,{attrs:{"type":"subhead"}},[_vm._v("Is the location owned by one of your clients? ")])]},proxy:true}],null,false,669625154),model:{value:(_setup.isLocationOwnedByCurrentOrganizationToggle),callback:function ($$v) {_setup.isLocationOwnedByCurrentOrganizationToggle=$$v},expression:"isLocationOwnedByCurrentOrganizationToggle"}},[_c('v-radio',{attrs:{"label":"Yes","value":true}}),_c('v-radio',{attrs:{"label":"No","value":false}})],1),(!_setup.isLocationOwnedByCurrentOrganizationToggle)?_c(_setup.VsWrapper,[_c('v-checkbox',{staticClass:"mt-0",attrs:{"label":"Private","hint":"Only your company and your clients see this location","persistent-hint":""},on:{"change":_setup.onPrivateChanged},model:{value:(_setup.privateToggle),callback:function ($$v) {_setup.privateToggle=$$v},expression:"privateToggle"}}),_c(_setup.VsAutocomplete,{staticClass:"mt-2",attrs:{"items":_setup.locationHubOptions,"disabled":_setup.disabled,"item-text":"name","item-value":"id","label":"Which hubs do you use this location with?","value":_vm.location.accessBusinessUnitIds,"multiselect":""},on:{"input":function($event){return _setup.handleLocationUpdate({
          field: 'accessBusinessUnitIds',
          value: $event,
        })}}})],1):_c(_setup.VsWrapper,[_c(_setup.VsAutocomplete,{attrs:{"items":_setup.locationOrganizationOptions,"disabled":_setup.disabled,"item-text":"name","item-value":"id","value":_vm.location.ownerId,"label":"Client"},on:{"input":_setup.onClientChanged}}),(_vm.location.ownerId)?_c(_setup.VsAutocomplete,{attrs:{"items":_setup.locationHubOptionsFilteredByOwnerId,"disabled":_setup.disabled,"item-text":"name","item-value":"id","label":_setup.availableInTheChosenClientLabel,"multiselect":"","value":_vm.location.accessBusinessUnitIds},on:{"input":function($event){return _setup.handleLocationUpdate({
          field: 'accessBusinessUnitIds',
          value: $event,
        })}}}):_vm._e()],1)],1):_c('div',[(_setup.managedCompanies)?_c(_setup.VsAutocomplete,{attrs:{"items":_setup.managedCompanies,"value":_vm.location.ownerId,"disabled":_setup.disabled,"label":"Company","placeholder":"Select a company","required":""},on:{"input":function($event){return _setup.handleLocationUpdate({ field: 'ownerId', value: $event })}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }