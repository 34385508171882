"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.latLngFieldMatcher = void 0;
const lat_lng_selector_definition_1 = require("../../form_fields/lat_lng_selector_definition");
const json_schema_keys_1 = require("../../json_schema_keys");
const latLngFieldMatcher = (uniqueKey, isRequired, properties, uiSchema, fieldDefinitions, next) => {
    const isPointFormat = properties[json_schema_keys_1.JsonSchemaKeys.FORMAT_KEY] === json_schema_keys_1.JsonSchemaKeys.DATA_FORMAT_POINT;
    if (isPointFormat) {
        const field = new lat_lng_selector_definition_1.LatLntSelectorDefinition();
        field.title = properties.title;
        field.description = properties.description;
        field.isRequired = isRequired;
        field.defaultValue = properties.default;
        return field;
    }
    return next(uniqueKey, isRequired, properties, uiSchema, fieldDefinitions);
};
exports.latLngFieldMatcher = latLngFieldMatcher;
