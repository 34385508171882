import axios from 'axios'
import { get } from './base'
import LocationType from '@/models/location-type'
import { convertToCamelCase } from '@/models/utils/casing'
import { useQuery } from '@tanstack/vue-query'
import moment from 'moment'
import { computed, Ref, unref } from 'vue'

type MaybeRef<T> = Ref<T> | T

const locationsTypeEndpoint = '/api/v2/locationTypes'

export function getLocationTypes(
  hubId?: string,
  includeOwnedLocations?: boolean,
  includeUnused?: boolean,
  signal?: AbortSignal
) {
  const source = axios.CancelToken.source()

  let url = locationsTypeEndpoint
  const queryParams = new URLSearchParams()

  if (hubId) queryParams.append('hubId', hubId)
  if (includeOwnedLocations) {
    queryParams.append(
      'includeOwnedLocations',
      includeOwnedLocations?.toString()
    )
  }
  if (includeUnused) {
    queryParams.append('includeUnused', includeUnused?.toString())
  }

  if (queryParams.toString()) url += `?${queryParams.toString()}`

  const locationTypes = get<LocationType[]>(url, {
    cancelToken: source.token,
  }).then((response) =>
    convertToCamelCase(response.data, [
      { key: 'custom_fields_schema', onlyDeep: true },
    ])
  )

  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled by TanStack Query')
  })

  return locationTypes
}

export function useGetLocationTypesQuery(options?: {
  hubId?: MaybeRef<string | undefined>
  includeOwnedLocations?: MaybeRef<boolean | undefined>
  includeUnused?: MaybeRef<boolean>
}) {
  const hubId = computed(() => unref(options?.hubId))
  const includeOwnedLocations = computed(() =>
    unref(options?.includeOwnedLocations)
  )
  const includeUnused = computed(() => unref(options?.includeUnused))

  const queryKey = computed(() => [
    'location-types',
    {
      hubId: hubId.value,
      includeOwnedLocations: includeOwnedLocations.value,
      includeUnused: includeUnused.value,
    },
  ])

  /*
   * location types will be permanently cached,
   * but can be 'refreshed' once per day
   */
  return useQuery({
    queryKey,
    queryFn: ({ signal }) =>
      getLocationTypes(
        hubId.value,
        includeOwnedLocations.value,
        includeUnused.value,
        signal
      ),
    staleTime: moment.duration(1, 'day').asMilliseconds(),
    cacheTime: Infinity,
  })
}
