import { getFirebaseToken } from '@/api/tokens'
import { initializeApp } from 'firebase/app'
import { getAuth, signInWithCustomToken } from 'firebase/auth'

export const FIREBASE_INITIALIZED = 'FIREBASE_INITIALIZED'
export const FIREBASE_CONFIG_LOADED = 'FIREBASE_CONFIG_LOADED'
export const FIREBASE_SIGNED_IN = 'FIREBASE_SIGNED_IN'
export const FIREBASE_INITIALIZATION_ERROR = 'FIREBASE_INITIALIZATION_ERROR'

const state = {
  initialized: false,
  config: null,
  signedIn: false,
  error: null,
}

const actions = {
  async initialize({ dispatch, commit, state }) {
    try {
      const config = await fetch('/api/config/firebase').then((response) =>
        response.json()
      )
      commit(FIREBASE_CONFIG_LOADED, config)

      initializeApp(state.config)

      commit(FIREBASE_INITIALIZED)

      const { data: token } = await getFirebaseToken()
      const auth = getAuth()
      await signInWithCustomToken(auth, token)

      commit(FIREBASE_SIGNED_IN)

      // Calling initialize here ensures that firebase messaging uses the default
      // firebase app as initialized above. If we do this later, the notifications
      // module will fail because it cant find the default firebase app
      return Promise.all([
        dispatch('notifications/initialize', null, { root: true }),
      ])
    } catch (e) {
      commit(FIREBASE_INITIALIZATION_ERROR, e)
    }
  },
}

const mutations = {
  [FIREBASE_INITIALIZED](state) {
    state.initialized = true
  },
  [FIREBASE_CONFIG_LOADED](state, config) {
    state.config = config
  },
  [FIREBASE_SIGNED_IN](state) {
    state.signedIn = true
  },
  [FIREBASE_INITIALIZATION_ERROR](state, error) {
    state.error = error
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
