"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.textEntryFieldMatcher = void 0;
const text_entry_field_definition_1 = require("../../form_fields/text_entry_field_definition");
const json_schema_keys_1 = require("../../json_schema_keys");
const textEntryFieldMatcher = (uniqueKey, isRequired, properties, uiSchema, fieldDefinitions, next) => {
    const isDataTypeString = properties[json_schema_keys_1.JsonSchemaKeys.TYPE_KEY] === json_schema_keys_1.JsonSchemaKeys.DATA_TYPE_STRING;
    const isMultilineWidget = uiSchema[json_schema_keys_1.JsonSchemaKeys.UI_WIDGET_KEY] === json_schema_keys_1.JsonSchemaKeys.WIDGET_TYPE_TEXT_AREA;
    const hasNoSpecifiedWidget = !uiSchema[json_schema_keys_1.JsonSchemaKeys.UI_WIDGET_KEY];
    const hasNoSpecifiedFormat = !properties[json_schema_keys_1.JsonSchemaKeys.FORMAT_KEY];
    const hasNoEnumProperty = !properties[json_schema_keys_1.JsonSchemaKeys.ENUM_KEY];
    if (hasNoEnumProperty && hasNoSpecifiedFormat && isDataTypeString && (isMultilineWidget || hasNoSpecifiedWidget)) {
        const field = new text_entry_field_definition_1.TextEntryFieldDefinition();
        field.title = properties.title;
        field.description = properties.description;
        field.isRequired = isRequired;
        field.isMultiline = isMultilineWidget;
        field.defaultValue = properties.default;
        return field;
    }
    return next(uniqueKey, isRequired, properties, uiSchema, fieldDefinitions);
};
exports.textEntryFieldMatcher = textEntryFieldMatcher;
