import {
  addDoc,
  collection,
  doc,
  DocumentData,
  Firestore,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy as firebaseOrderBy,
  query,
  QuerySnapshot,
  setDoc,
  startAt,
  Timestamp,
  updateDoc,
  where,
} from 'firebase/firestore'
import { useGetters, useState } from 'vuex-composition-helpers'
import { defineStore } from 'pinia'
import { isEmpty, isEqual, keyBy, orderBy, uniqBy } from 'lodash'
import { computed, onMounted, ref, watch } from 'vue'
import {
  createGroupId,
  getHostName,
  parseGroupId,
  getCurrentUserSid,
} from '@/utils/chat'
import { Message, Channel, Channels, Messages } from './models'
import { checkSearchResult } from '@/utils/search'
import { useJobFilterStore } from '@/components/field-view-next/JobsFilterStore'

const AUDIO_NOTIFICATION = new Audio(require('@/assets/polite_alert.mp3'))
const PAGINATION_SIZE = 10 // Must be large enough to cause a scroll to see first message

export const useChatStore = defineStore(
  'chat',
  () => {
    const channels = ref<Channels>({})
    const messages = ref<Messages>({})
    const searchText = ref<string>('')

    const loading = ref<boolean>(false)

    const jobAudibleAlert = ref<boolean>(false)
    const chatAudibleAlert = ref<boolean>(false)

    const filters = useJobFilterStore()

    let firestore: Firestore

    const initialized = ref<boolean>(false)
    const { initialized: firebaseInitialized, signedIn: firebaseSignedIn } =
      useState('firebase', ['initialized', 'signedIn'])

    const firebaseReady = computed(
      () => firebaseInitialized.value && firebaseSignedIn.value
    )

    const { getVisibleUnits, getById: getUnitById } = useGetters(
      'unitNumbers',
      ['getVisibleUnits', 'getById']
    )
    const { getBySid: getUserById } = useGetters('users', ['getBySid'])
    const { getByUuid } = useGetters('divisions', ['getByUuid'])
    const { getRoleAssignmentBusinessUnitIds } = useGetters('permissions', [
      'getRoleAssignmentBusinessUnitIds',
    ])

    const resourceTypes = computed(() => filters.selectedResourceTypes)
    const divisions = computed(() =>
      filters.selectedDivisions.map((d) => getByUuid.value(d)?.id)
    )
    const companies = computed(() =>
      filters.selectedServiceProviders.filter((sp) => sp != null)
    )

    const initialize = () => {
      if (!firebaseReady.value || initialized.value) {
        return
      }

      const currentUserSid = getCurrentUserSid()

      try {
        firestore = getFirestore()
      } catch (e) {
        console.error(e)
      }

      loading.value = true

      const chatGroupQuery = query(
        collection(firestore, 'groups'),
        where('participants', 'array-contains', currentUserSid),
        where('subdomain', '==', getHostName())
      )

      getDocs(chatGroupQuery).then((groupsQuerySnapshot) => {
        storeChannelsFromSnapshot(groupsQuerySnapshot)

        onSnapshot(chatGroupQuery, storeChannelsFromSnapshot)
      })

      initialized.value = true
    }

    watch(firebaseReady, () => {
      initialize()
    })

    watch(channels, (newChannels, oldChannels) => {
      let hasNewMessage = false

      if (isEmpty(oldChannels)) {
        return
      }

      Object.keys(newChannels)
        .filter((key) =>
          filteredChannels.value.some((c) => c.documentId === key)
        )
        .forEach((key) => {
          if (hasNewMessage) {
            return
          }

          const newChannel = newChannels[key]
          const oldChannel = oldChannels[key]

          if (
            newChannel?.recent?.timestamp?.seconds !==
              oldChannel?.recent?.timestamp?.seconds &&
            newChannel.recent?.from !== getCurrentUserSid()
          ) {
            hasNewMessage = true
          }
        })

      if (hasNewMessage && chatAudibleAlert.value) {
        AUDIO_NOTIFICATION.play()
      }
    })

    const storeChannelsFromSnapshot = async (
      groupsQuerySnapshot: QuerySnapshot<DocumentData>
    ) => {
      const newChannels = groupsQuerySnapshot.docs.map(
        (doc) => new Channel(doc.data(), doc.id)
      )

      const channelsNeedingSubscriptions = newChannels.filter((c) => {
        return !channels.value[c.documentId!]
      })

      channels.value = keyBy(
        orderBy(
          newChannels,
          (item: Channel) =>
            (item.recent && item.recent.timestamp.seconds) || -1,
          ['desc']
        ),
        'documentId'
      )

      await Promise.all(
        channelsNeedingSubscriptions.map((c) =>
          fetchAndSubscribeToChannel(c.documentId!)
        )
      )

      Object.keys(channels.value).forEach((channelId) => {
        determineUnreadCountsForChannel(channelId)
      })

      loading.value = false
    }

    const createNewChatGroup = (group: Channel, unitId?: any) => {
      if (unitId) {
        const unitDocumentId = createGroupId(getHostName(), 'unit', unitId)
        const unitChatGroupRef = doc(firestore, 'groups', unitDocumentId)

        getDoc(unitChatGroupRef).then((querySnapshot) => {
          if (!querySnapshot.exists()) {
            setDoc(unitChatGroupRef, group)
          } else {
            const participants = querySnapshot.data().participants
            if (!participants.includes(group.participants[0])) {
              participants.push(group.participants[0])
              updateDoc(unitChatGroupRef, { participants })
            }
          }
        })
      } else {
        const chatGroupRef = collection(firestore, 'groups')
        addDoc(chatGroupRef, group)
      }
    }

    // real channels represents channels that firestore knows about.
    // they've been created, and probably have messages/participants in them.
    const getRealChannels = computed(() => {
      if (!searchText.value) {
        return sortedChannels.value
      }

      return sortedChannels.value.filter((channel) => {
        const docInfo = parseGroupId(channel.documentId)
        if (docInfo.type === 'unit') {
          const unit = getUnitById.value(docInfo.id)

          return unit && checkSearchResult(searchText.value, unit.name)
        } else {
          const participants = channel.participants.map(
            (p) => getUserById.value(p)?.name
          )
          return participants.find(
            (p) => p && checkSearchResult(searchText.value, p)
          )
        }
      })
    })

    // fake channels exist as place holders. They represent channels that could
    // be created, i.e. the opportunity to chat with units, but there is no real
    // channel because you haven't chatted with them yet.
    const getFakeChannels = computed(() => {
      const unitsWithChat = Object.values(channels.value)
        .filter(
          (channel: any) => parseGroupId(channel.documentId).type === 'unit'
        )
        .map((channel: any) => parseGroupId(channel.documentId).id)

      const unitsWithoutChat = Object.values(getVisibleUnits.value).filter(
        (unit: any) => !unitsWithChat.includes(unit.id.toString())
      )

      const searchedChannels = searchText.value
        ? unitsWithoutChat.filter((unit: any) =>
            checkSearchResult(searchText.value, unit.name)
          )
        : unitsWithoutChat

      return searchedChannels.map((unit: any) => {
        return {
          documentId: createGroupId(getHostName(), 'unit', unit.id),
          participants: [] as any[],
          unreadCount: 0,
          unsubscribed: true,
        } as unknown as Channel
      })
    })

    const sortedChannels = computed(() => {
      return Object.values(channels.value).sort((a, b) => {
        if (a.unreadCount !== 0 && b.unreadCount !== 0) {
          if (a.recent && b.recent) {
            return b.recent.timestamp.seconds > a.recent.timestamp.seconds
              ? 1
              : -1
          }

          return 0
        } else {
          return (b.unreadCount ?? 0) - (a.unreadCount ?? 0)
        }
      })
    })

    const searchedChannels = computed(() => {
      return [...getRealChannels.value, ...getFakeChannels.value]
    })

    const filteredChannels = computed(() =>
      searchedChannels.value.filter((channel) => {
        const docInfo = parseGroupId(channel.documentId)
        if (docInfo.type === 'unit') {
          const unit = getUnitById.value(docInfo.id)

          return (
            unit &&
            resourceTypeMatches(resourceTypes.value, unit) &&
            divisionMatches(
              divisions.value,
              unit,
              getRoleAssignmentBusinessUnitIds.value
            ) &&
            companyMatches(companies.value, unit)
          )
        }
        return true
      })
    )

    const fetchAndSubscribeToChannel = (channelId: string) => {
      const baseQuery = query(
        collection(firestore, 'groups', channelId, 'messages'),
        firebaseOrderBy('timestamp', 'desc'),
        limit(PAGINATION_SIZE)
      )

      return getDocs(baseQuery).then((querySnapshot) => {
        storeMessages(channelId, querySnapshot)
        onSnapshot(baseQuery, (snapshot) => storeMessages(channelId, snapshot))
      })
    }

    const storeMessages = (
      channelId: string,
      querySnapshot: QuerySnapshot<DocumentData>
    ) => {
      if (!messages.value[channelId]) {
        messages.value[channelId] = []
      }

      const oldMessages = [...messages.value[channelId]]
      const newMessages = querySnapshot.docs.map(
        (doc) => new Message(doc.data(), doc.id)
      )

      const updatedList = uniqBy([...newMessages, ...oldMessages], 'id')
      if (!isEqual(updatedList, messages.value)) {
        messages.value = {
          ...messages.value,
          [channelId]: orderBy(updatedList, (m) => m?.timestamp?.seconds ?? -1),
        }
      }

      determineUnreadCountsForChannel(channelId)
    }

    const determineUnreadCountsForChannel = (channelId: string) => {
      const currentUserSid = getCurrentUserSid()
      const currentChatRead = channels.value[channelId]?.read
      const mostRecentRead =
        currentChatRead && currentChatRead[currentUserSid]
          ? currentChatRead[currentUserSid]
          : new Timestamp(0, 0)

      channels.value[channelId].unreadCount = messages.value[channelId]?.filter(
        (m) => m.timestamp > mostRecentRead && m.from !== currentUserSid
      ).length
    }

    const fetchPreviousMessages = (channelId: string) => {
      const earliestMessage = getEarliestMessage(channelId)
      const currentEarliestMessageTimestamp = earliestMessage?.timestamp

      const chatGroupQuery = query(
        collection(firestore, 'groups', channelId, 'messages'),
        firebaseOrderBy('timestamp', 'desc'),
        startAt(currentEarliestMessageTimestamp),
        limit(PAGINATION_SIZE)
      )

      getDocs(chatGroupQuery).then((querySnapshot) => {
        storeMessages(channelId, querySnapshot)
      })
    }

    const getEarliestMessage = (channelId: string) => {
      return orderBy(
        messages.value[channelId],
        (m) => m.timestamp.seconds,
        'asc'
      )[0]
    }

    const updateChatAudibleAlert = (newValue: boolean) => {
      chatAudibleAlert.value = newValue
    }

    const updateJobAudibleAlert = (newValue: boolean) => {
      jobAudibleAlert.value = newValue
    }

    const sendChatMessage = async (channelId: string, newMessage: any) => {
      const messageRef = collection(firestore, 'groups', channelId, 'messages')
      try {
        await addDoc(messageRef, newMessage)
      } catch (e) {
        console.error(e)
      }

      channelRead(channelId)
    }

    const channelRead = (channelId: string) => {
      const currentUserSid = getCurrentUserSid()

      const lastRead =
        channels.value[channelId]?.read[currentUserSid]?.toDate() ??
        new Timestamp(0, 0)
      const recent = channels.value[channelId]?.recent

      if (recent && lastRead && lastRead < recent?.timestamp.toDate()) {
        const channelRef = doc(firestore, 'groups', channelId)
        updateDoc(channelRef, { [`read.${currentUserSid}`]: Timestamp.now() })
      }

      resetUnreadCount(channelId)
    }

    const resetUnreadCount = (channelId: string) => {
      if (channels.value[channelId]) {
        channels.value[channelId].unreadCount = 0
      }
    }

    onMounted(() => {
      initialize()
    })

    return {
      channels,
      messages,
      loading,
      initialize,
      createNewChatGroup,
      filteredChannels,
      searchedChannels,
      getFakeChannels,
      getRealChannels,
      searchText,
      chatAudibleAlert,
      jobAudibleAlert,
      updateChatAudibleAlert,
      updateJobAudibleAlert,
      fetchPreviousMessages,
      sendChatMessage,
      channelRead,
    }
  },
  { persist: { paths: ['chatAudibleAlert', 'jobAudibleAlert'] } }
)

const resourceTypeMatches = (resourceTypes: number[] | null, unit: any) => {
  return (
    resourceTypes === null ||
    resourceTypes.length === 0 ||
    resourceTypes.includes(unit.resourceTypeId)
  )
}

const divisionMatches = (
  divisionIds: any[] | undefined,
  unit: any,
  roleAssignmentBusinessUnits: any[]
) => {
  return (
    divisionIds === null ||
    divisionIds === undefined ||
    divisionIds?.length === 0 ||
    divisionIds?.includes(unit.divisionId) ||
    (unit.divisionId === null &&
      roleAssignmentBusinessUnits.some((b) => b === unit.ownerId))
  )
}

const companyMatches = (companyIds: any[] | undefined, unit: any) => {
  return (
    companyIds === null ||
    companyIds === undefined ||
    companyIds?.length === 0 ||
    companyIds?.includes(unit.organizationId)
  )
}
